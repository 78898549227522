.main-prefix-page {
    // padding: 20px 30px;
    height: 93%;

    .custom-loader ._loading_overlay_overlay {
        justify-content: center;
        align-items: center;
    }

    .prefix-page-header {
        padding: 3px 25px !important;
        height: 6% !important;
        display: flex;
    }
    .prefix-page-row {
        margin: 0 !important;
        padding: 3px 15px !important;
        height: 94%;

        .prifix-col {
            padding: 0 !important;
            margin: 0 !important;
            height: 100%;

            &.prefix-col-left {
                max-width: 20% !important;
                height: 100%;
            }

            &.prefix-col-right {
                max-width: 80% !important;
                height: 100%;
            }
            &.prefix-location-right {
                max-width: 100% !important;
                height: 100%;
            }

            .project-list-div {
                padding: 10px;
                height: 100%;

                .list-table {
                    border: 1px solid #ccc;
                    height: 100%;
                    overflow: hidden;
                    width: 100%;
                    max-height: 100%;

                    .prifix-table {

                        th,
                        td {
                            padding: 4px 8px !important;
                            border: 0.5px solid #eee !important;
                        }

                        th {
                            background-color: #f2f8fb;
                            font-weight: 800;
                        }
                    }

                    .expand-table {
                        th {
                            font-weight: 800;
                        }

                        th,
                        td {
                            font-size: 0.7rem;
                        }
                    }
                }

                .mapped-input-labels {
                    min-width: 20%;
                    margin-bottom: 0;
                    font-size: 13px;
                }

                .select-project-details {
                    height: 6%;
                    border: 1px solid #ccc;
                    padding: 10px;
                    display: flex;

                    .prefix-production-details {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: 100%;
                        height: 100%;

                        .row-div {
                            height: 100%;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .prefix-list {
                    // margin-top: 10px;
                    height: 94%;
                    overflow: auto;
                    border: 1px solid #ccc;
                    padding: 10px;

                    .prefix-heading {
                        padding: 10px 0 10px;

                        h5 {
                            margin: 0 !important;
                        }
                    }

                    .prefix-title-div {
                        border: 1px solid #ccc;
                        padding: 10px 0;
                        .btn-special-char {
                            padding: 3px 10px;
                            min-height: auto;
                            border-radius: 3px;
                        }
                    }

                    .prefix-list-row {
                        margin: 0 !important;
                        padding: 5px;

                        .select-custom-input {
                            width: 100%;
                        }
                    }

                    .px-setting-div {
                        display: flex;
                        align-items: center;

                        h6 {
                            min-width: 20%;
                            margin: 0 !important;
                        }

                        span {
                            width: 80%;

                            input {
                                width: 100%;
                                padding: 0 5px !important;
                            }
                        }
                    }
                }
            }
        }

    }
}

/* Basic Rules */
.switch input {
    display: none;
}

.switch {
    display: inline-block;
    width: 50px;
    height: 20px;
    margin: 0;
    position: relative;
}

/* Style Wired */
.slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    box-shadow: 0 0 0 2px #777, 0 0 4px #777;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #777;
    border-radius: 30px;
    transform: translateX(-30px);
    transition: .4s;
}

input:checked+.slider:before {
    transform: translateX(30px);
    background: limeGreen;
}

input:checked+.slider {
    box-shadow: 0 0 0 2px limeGreen, 0 0 2px limeGreen;
}

/* Style Flat */
.switch.flat .slider {
    box-shadow: none;
}

.switch.flat .slider:before {
    background: #FFF;
}

.switch.flat input:checked+.slider:before {
    background: white;
}

.switch.flat input:checked+.slider {
    background: limeGreen;
}

@media (max-width: 1366px) {
    .mapped-input-labels {
        min-width: 24% !important;
    }
  }