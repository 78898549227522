#header {
  position: absolute;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

/*jp*/
#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
  height: 85vh;
}

.pro-main-display {
  padding: 0 20px;
}

.up-arrow {
  text-align: end;
  margin: 1% 0;
}

.pro-arrow {
  font-size: 25px !important;
  background: #A9A9A9;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  height: 50px;
  width: 50px;
}

.masters-table-height {
  margin: 2% 0 0 0;
}

.pro-main-display .masters-material-table {
  height: calc(100vh - 240px) !important;
.customer-table-body {
  height: 88% !important;
  max-height: 88% !important;
}

  .customer-table-paper {
    height: 100%;
  }
}

.masters-material-table {
  height: calc(100vh - 240px) !important;
.customer-table-body {
  height: 88% !important;
  max-height: 88% !important;
}

  .customer-table-paper {
    height: 100%;
  }
}

.scroll-top-div {
  position: absolute;
  right: 2%;
  bottom: 4%;
}

@media screen and (min-width: 1020px) {
  .masters-table-height {
    max-height: calc(100vh - 310px) !important;
  }
}
@media screen and (max-width: 1366px) {
  .masters-material-table {
    height: calc(100vh - 170px) !important;
    .customer-table-body {
      height: 82% !important;
      max-height: 82% !important;
    }
  }
  
}

/*jp*/

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 15px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-size: 13px;
  //font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}

#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: auto;
  }
}

.margin-not-collpased {
  margin-left: 220px;
}

.margin-not-collpased-admin {
  margin-left: 180px;
  width: calc(100% - 180px) !important;
}

.margin-collpased {
  margin-left: 100px;
}

.spinner-background {
  background-color: white;
  width: 400px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin-bottom: 0 !important;
  }
}

.spinner {
  height: 630px;
}

.custom-textfield #outlined-read-only-input {
  background-color: #eee;
}

.confirm-delete-modal {
  left: 36%;
  top: 40%;
}
.confirm-delete-modal .modal-dialog {
  max-width: 35%;
  height: 35%;
}

.profile-img {
  border-radius: 50%;
}

/*Customer List css starts here*/

.editCustomerFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}

.addCustomerFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.editCustomerFloatingInput .form-control:disabled, .form-control[readonly] {
  background-color: #e3e7ed !important;
  opacity: 1;
}
/*Customer List css end*/

/*Project Sub Activity css starts here*/
.masters-material-table.projectSubActivityTypeTable{height: calc(100vh - 240px) !important;}
.masters-material-table.projectSubActivityTypeTable .MuiTableContainer-root {
  height: calc(100vh - 270px) !important;
}
@media screen and (min-width:1000px) and (max-width:1600px){
  .masters-material-table.projectSubActivityTypeTable .MuiTableContainer-root {
    height: calc(100vh - 245px) !important;
  }
}
.projectSubActivityDeleteModal.modal .modal-dialog {transform: none; display: flex;
  align-items: center;
  margin: 0 auto;
}
.projectSubActivityFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.projectSubActivityFloatingInputEdit .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.projectSubActivityTypeTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(244, 246, 248, 1) !important; 
}
.projectSubActivityTypeTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.projectSubActivityTypeTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(255, 255, 255, 255) !important; 
}
.projectSubActivityTypeTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.projectSubActivityTypeTable .MuiTableRow-root:hover td{background-color: rgba(204, 204, 204, 255) !important;}
/*Project Sub Activity css end*/

/*Input/Output Formats css starts here*/
.masters-material-table.inputOutputFormatsTypeTable{height: calc(100vh - 240px) !important;}
.masters-material-table.inputOutputFormatsTypeTable .MuiTableContainer-root {
  height: calc(100vh - 270px) !important;
}
@media screen and (min-width:1000px) and (max-width:1600px){
  .masters-material-table.inputOutputFormatsTypeTable .MuiTableContainer-root {
    height: calc(100vh - 245px) !important;
  }
}
.inputOutputFormatsDeleteModal.modal .modal-dialog { transform: none; display: flex; align-items: center;
  margin: 0 auto;
}
.inputOutputFormatFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.inputOutputFormatFloatingInputEdit .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.inputOutputFormatsTypeTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(244, 246, 248, 1) !important; 
}
.inputOutputFormatsTypeTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.inputOutputFormatsTypeTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(255, 255, 255, 255) !important; 
}
.inputOutputFormatsTypeTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.inputOutputFormatsTypeTable .MuiTableRow-root:hover td{background-color: rgba(204, 204, 204, 255) !important;}
/*Input/Output Formats css end*/

/*Item Status css starts here*/
.masters-material-table.itemStatusTable{height: calc(100vh - 240px) !important;}
.masters-material-table.itemStatusTable .MuiTableContainer-root {
  height: calc(100vh - 270px) !important;
}
@media screen and (min-width:1000px) and (max-width:1600px){
  .masters-material-table.itemStatusTable .MuiTableContainer-root {
    height: calc(100vh - 245px) !important;
  }
}
.itemStatusDeleteModal.modal .modal-dialog {transform: none; display: flex; align-items: center; 
  margin: 0 auto;
}
.inputStatusFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.itemStatusTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(244, 246, 248, 1) !important; 
}
.itemStatusTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.itemStatusTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(255, 255, 255, 255) !important; 
}
.itemStatusTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.itemStatusTable .MuiTableRow-root:hover td{background-color: rgba(204, 204, 204, 255) !important;}
/*Item Status css end*/

/*Generic Activity css starts here*/
.genericActivityDeleteModal.modal .modal-dialog {transform: none; display: flex; align-items: center;
  margin: 0 auto;
}
.genericActivityFloatingInputEdit .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important; background: #fff !important;
  font-size: 14px !important;
}
.genericActivityFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.masters-material-table.genericActivityListTable{height: calc(100vh - 240px) !important;}
.masters-material-table.genericActivityListTable .MuiTableContainer-root {
  height: calc(100vh - 270px) !important;
}
@media screen and (min-width:1000px) and (max-width:1600px){
  .masters-material-table.genericActivityListTable .MuiTableContainer-root {
    height: calc(100vh - 245px) !important;
  }
}
.genericActivityListTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(244, 246, 248, 1) !important; 
}
.genericActivityListTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.genericActivityListTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(255, 255, 255, 255) !important; 
}
.genericActivityListTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.genericActivityListTable .MuiTableRow-root:hover td{background-color: rgba(204, 204, 204, 255) !important;}
/*Generic Activity css end*/

/*Communication Mode css starts here*/
.communicationModeDeleteModal.modal .modal-dialog {transform: none; display: flex; align-items: center;
  margin: 0 auto;
}
.communicationModeFloatingInputEdit .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important; background: #fff !important;
  font-size: 14px !important;
}
.communicationModeFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.masters-material-table.communicationModeListTable{height: calc(100vh - 240px) !important;}
.masters-material-table.communicationModeListTable .MuiTableContainer-root {
  height: calc(100vh - 270px) !important;
}
@media screen and (min-width:1000px) and (max-width:1600px){
  .masters-material-table.communicationModeListTable .MuiTableContainer-root {
    height: calc(100vh - 245px) !important;
  }
}
.communicationModeListTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(244, 246, 248, 1) !important; 
}
.communicationModeListTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.communicationModeListTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(255, 255, 255, 255) !important; 
}
.communicationModeListTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.communicationModeListTable .MuiTableRow-root:hover td{background-color: rgba(204, 204, 204, 255) !important;}
/*Communication Mode css end*/

/*Customer Feedback Type css starts here*/
.customerFeedbackTypeDeleteModal.modal .modal-dialog {transform: none; display: flex; align-items: center;
  margin: 0 auto;
}
.customerFeedbackTypeFloatingInputEdit .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important; background: #fff !important;
  font-size: 14px !important;
}
.customerFeedbackTypeFloatingInput .float-select > label {display: block !important; position: absolute; top: -10px !important; left: 5px !important;
  background: #fff !important;
  font-size: 14px !important;
}
.masters-material-table.customerFeedbackTypeListTable{height: calc(100vh - 240px) !important;}
.masters-material-table.customerFeedbackTypeListTable .MuiTableContainer-root {
  height: calc(100vh - 270px) !important;
}
@media screen and (min-width:1000px) and (max-width:1600px){
  .masters-material-table.customerFeedbackTypeListTable .MuiTableContainer-root {
    height: calc(100vh - 245px) !important;
  }
}
.customerFeedbackTypeListTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(244, 246, 248, 1) !important; 
}
.customerFeedbackTypeListTable tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.customerFeedbackTypeListTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) {
  background-color: rgba(255, 255, 255, 255) !important; 
}
.customerFeedbackTypeListTable tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover {
  background-color: rgba(204, 204, 204, 255) !important; 
}
.customerFeedbackTypeListTable .MuiTableRow-root:hover td{background-color: rgba(204, 204, 204, 255) !important;}
/*Create Customer Feedback css end*/

