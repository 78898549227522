.snomed-container {
  height: 100%;
  border: 1px solid #cdd4e0;
  padding: 15px !important;
}
.snomed-header-div {
  height: 4%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.snomed-tabledata-section {
  height: 96%;
  margin: 0;
  font-size: 1rem;
}

.snomed-table-left,
.snomed-table-right {
  height: 100%;
  margin: 0;
  padding: 0;
}
.search-filter-row {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}
.snomed-lat-img {
  width: 2%;
  cursor: pointer;
  border: 1px solid #ccc;
}
/* .result-row {
  align-items: flex-start !important;
} */
.search-col {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 5px !important;
}
.search-col input {
  height: 20px;
}

.btn-custom-seacrh {
  background: #bfd4f1 !important;
  color: #1c273c !important;
  font-weight: 700;
  border-radius: 2px;
  width: 100%;
  padding: 2px;
  /* font-size: 11px; */
  min-height: 50px;
}
.default-search-btn {
  border: 1px solid #000;
}

.input-search {
  align-items: center;
  padding: 2px 4px;
}
.input-search input {
  width: 100%;
}
.input-clear {
  padding: 1px 4px;
  background: #bfd4f1 !important;
  border-radius: 2px;
  height: 20px;
  cursor: pointer;
}
.custom-table {
  height: 43%;
}
.note-div {
  height: 4%;
}
.custom-table .bootstrap-table-div {
  position: relative;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #cdd4e0;
  width: 100%;
}
.custom-table .table-bordered {
  border: 0px solid #cdd4e0 !important;
}
.custom-table .react-bootstrap-table {
  margin-bottom: 0px !important;
  min-height: 210px;
}
.custom-table .react-bootstrap-table .table {
  margin-bottom: 0px !important;
}
.custom-table .react-bootstrap-table .table th {
  top: -1px !important;
}

.table-header {
  height: 10%;
}

.bottom-table .bootstrap-table-div {
  margin-bottom: 0px !important;
}
.table-one .bootstrap-table-div {
  margin-bottom: 0px !important;
}

.custom-table #pageDropDown,
.custom-table .react-bootstrap-table-pagination-list {
  display: none;
}

.custom-table .form-control {
  height: 22px !important;
  padding: 2px 5px !important;
}
.search-text-input {
  height: 100% !important;
  padding: 2px 5px !important;
  width: 100%;
}

.selected-row td {
  background-color: #a5cff1;
}

.custom-table .react-bootstrap-table .react-bootstrap-table-pagination {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.custom-table .react-bootstrap-table-pagination .col-lg-6 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

.note-text {
  font-size: 10px;
  color: darkred;
  margin-bottom: 0px;
}
.pagination-div button {
  /* font-size: 12px; */
  border: none;
  height: 100%;
}
.pagination-div {
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0 !important;
  left: 0 !important;
  background-color: #fff;
  z-index: 2;
  padding: 4px 10px;
}

.pagination-div .search-label {
  margin-bottom: 0px !important;
  width: 100% !important;
}

.pagination-search {
  width: 100%;
}
.filter-search-input {
  width: 100%;
  border: 1px solid #cdd4e0 !important;
  padding: 0px 5px;
}

.custom-table .table-bordered td,
.custom-table .table-bordered th {
  height: 18px !important;
  padding: 1px 8px;
}
.custom-table th {
  background-color: #bfd4f1 !important;
  vertical-align: middle !important;
}

.table-one {
  margin-top: 27px;
}
.search-header {
  padding: 0px 5px;
}

@media (max-width: 1366px) {
  .snomed-tabledata-section {
    font-size: 0.75rem;
  }
  .snomed-main-div {
    padding-top: 12px !important;
  }
  .table-one {
    margin-top: 12px !important;
  }
  .custom-table .react-bootstrap-table {
    margin-bottom: 0px !important;
    min-height: 190px;
  }
  .snomed-header {
    font-size: 16px !important;
  }
  .snomed-container {
    padding: 10px !important;
  }
  .table-one {
    margin-top: 12px;
  }
}

@media (max-width: 1200px) {
  .btn-custom-seacrh {
    padding: 5px !important;
    /* font-size: 10px; */
  }
  .result-header {
    font-size: 10px;
  }
  .search-header {
    /* font-size: 10px; */
    padding: 0px 3px;
  }
}
