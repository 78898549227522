.internet-error {
  height: 40px;
  background: #5e41fc;
  margin-top: 0;
  font-size: 15px;
}

.internet-error p {
  font-size: 20px;
  line-height: 40px;
  color: #fff;
  margin: 0;
}
