.masters-material-table.incidentTypeTable {
  height: 100% !important;
}

.masters-material-table.incidentTypeTable .MuiTableContainer-root {
  height: calc(100vh - 315px) !important;
}

.masters-material-table.incidentTypeTable .MuiBox-root.css-10gei56 {
  min-height: 55px !important;
}

.masters-material-table.incidentTypeTable .css-10gei56 {
  display: block !important;
}

.incidentTypeMainContent .row-sm>div {
  padding-left: 0px !important;
}

.incidentTypeMainContent h4 {
  display: flex;
  align-items: center;
}

.incidentTypeMainText {
  height: 100% !important;
}

.incidentTypeText .col-lg-2 {
  padding-left: 0px !important;
}

.incidentTypeText .col-lg-6 {
  padding-left: 0px !important;
}