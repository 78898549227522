.pro-left-padding {
  padding-left: 0;
  padding-right: 2px !important;
}

.pro-right-padding {
  padding-right: 0;
}

.unspsc-version-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 3px 3%;
  min-height: 30px;
  &.donload-btn-section {
    justify-content: space-between !important;
  }
  .unspsc-text {
    min-width: 23%;
  }
}

.pro-end-padding {
  padding-right: 12px;
}

.unspsc-border {
  border-top: 2px solid #a82f7c;
  border-right: 2px solid #a82f7c;
  border-left: 5px solid #a82f7c;
  border-bottom: 2px solid #a82f7c;
  margin: 0;
  width: 100%;
}

.unspsc-border {
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .col-lg-6 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.define-search,
.select-pro,
.ladder-input,
.other-search,
.unspsc-sea {
  padding: 0;
}

.unspsc-input {
  padding: 0px 5px !important;
}

.define-search {
  height: 100%;
  border-width: 5px 10px;
  border-color: #a0a0a0;
  border-style: solid;
  overflow-y: auto;
  max-width: 45% !important;
  min-width: 45% !important;
}

.latest-search {
  height: 100%;
  background: #f5d0a9;
  overflow-y: auto;
}

.main-row {
  justify-content: center;
  min-height: 6%;
}
.Unspc-custom-table {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  height: 63% !important;
}
.segment-select {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  min-height: 5%;
}
.Unspc-custom-table .bootstrap-table-div {
  position: relative;
  background: #fff;
  min-height: 93%;
  max-height: 93%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #cdd4e0;
  width: 100%;
}
.segments-input-div {
  min-height: 60%;
}
.category-input-div {
  min-height: 40%;
}

.unspc-search-btn {
  // background-color: #f4f4f4;
  border: none;
  padding: 0;
  cursor: pointer;
  min-height: 0;
  :focus {
    border: none;
    outline: none !important;
  }
}

.form-control[readonly] {
  background-color: #fff;
}

.Unspc-custom-table .table-bordered td,
.Unspc-custom-table .table-bordered th {
  height: 17px !important;
  padding: 1px 8px !important;
  vertical-align: middle;
}
.Unspc-custom-table th {
  background-color: #bfd4f1 !important;
  vertical-align: middle !important;
  border-top-width: 0px !important;
}
.Unspc-custom-table .table-bordered {
  border: 0px solid #cdd4e0 !important;
}

.Unspc-custom-table .react-bootstrap-table .table {
  margin-bottom: 0px !important;
}

.hyphen-input1 {
  display: flex;
  align-items: center;
}

.check-col,
.main-row,
.check-label,
.main-version,
.hyphen-input,
.dot-div,
.dot-label,
.page-row,
.pagin-row,
.left-col,
.right-col,
.text-div {
  display: flex;
}

.unspsc-text {
  color: #000;
  margin-bottom: 0;
}

.unspsc-select {
  width: 100%;
  background: #f9eadb;
  border: 0;
}

.favicity-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.favicity-select,
.unspsc-input,
.pro-table-head,
.table-container thead tr .ladder-input {
  position: relative;
}

.favicity-select {
  display: block;
  width: 100%;
  overflow: hidden;
  border: 1px solid #eee;
  margin-bottom: 0 !important;
}

.favicity-select::after {
  content: " ";
  right: -11px;
  top: 0;
  width: 40px;
  height: 100%;
  display: block;
  background: url("../../assets/icons/drop-icon.png") no-repeat center center;
  pointer-events: none;
}

.favicity-select::after,
.vert-line,
.horiz-line {
  position: absolute;
}

.favicity-select select {
  padding: 0 0 0px 5px;
}

.favicity-select select,
.pro-icon,
.direction-img,
.pro-img {
  cursor: pointer;
}

.pro-icon {
  color: #a6c3ea;
}

.favicity-select select::-ms-expand {
  display: none;
}

.favicity-select :-moz-any(select) {
  width: 110%;
}

.favicity-select.ie9 select {
  width: 110%;
}

.favicity-select select:focus {
  outline: 1px dotted #a9a9a9;
}

.check-col {
  align-items: center;
}

.unspsc-check {
  background: #0075ff !important;
  height: 18px;
  width: 18px;
}

.check-label {
  align-items: end !important;
}

.check-label,
.version-text,
.radio-text,
.unspsc-head,
.dot-label {
  font-weight: 600;
}

.main-version {
  padding: 0;
}

.page-row,
.pagin-row,
.left-col,
.right-col,
.main-version {
  align-items: center;
}

.lat-img-row {
  width: 100%;
  cursor: pointer;
}
.lat-img {
  width: 10%;
  cursor: pointer;
}

.lat-img-row,
.hyphen-input {
  flex-wrap: unset;
}

.hyphen-input {
  margin: 0 !important;
}

.span-cat {
  background: #a0a0a0;
  color: #000;
  font-size: 0.95rem;
  font-weight: 600;
}

.unspscMainContent .unspsc-input,
.ladder-input {
  height: 1.3rem !important;
}

.radio-sect,
.unspsc-row {
  display: flex;
  align-items: center;
}

.unspsc-head {
  margin: 3px 0 0 15px;
}

.dot-div {
  justify-content: flex-start;
  align-items: center;
  padding-right: 2%;
}

.dot-label {
  align-items: end !important;
  margin: 0px !important;
}
.favicity-select select,
.dot-label,
.Unspc-custom-table .table-bordered td,
.Unspc-custom-table .table-bordered th,
.unspsc-text,
.unspsc-select option,
.pro-icon,
.unspsc-input,
.most-pro,
.pro-table-head,
.ladder-input,
.pro-table-body,
.commodity-head,
.favicity-select,
.version-text,
.default-search,
.category-text,
.search-box-div,
.check-label,
.pagination-div {
  font-size: 0.85rem;
}

.dot-display {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 0.2rem;
}

.dot-danger {
  background: #af2906;
}

.dot-success {
  background: #3b9903;
}

.dot-class {
  background: #2e4aab;
}

.dot-commodity {
  background: #838383;
}

.extra-row {
  position: relative;
  display: flex;
  align-items: center;
  border-top: 2px solid #a0a0a0;
  padding: 0.2% 0;
  z-index: 1;
}

.definaion-div {
  height: 70%;
  overflow: auto;
}
.segmment-section {
  height: 30%;
  background-color: #f5d0a9;
  overflow: auto;
}

.table-div {
  margin: 0 0 0 15px;
  width: 97%;
}

.fixed-header {
  width: 100%;
  table-layout: fixed;
}

.fixed-header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: calc(200px - 72px);
  background: #fff;
}

.fixed-header thead tr {
  display: block;
}

.fixed-header thead {
  background: #eff0f2;
  color: #000;
}

.table-container {
  max-height: 290px;
  overflow-y: auto;
  border: 1px solid #bed3ef;
}

.table-container table {
  border-collapse: collapse;
  width: 100%;
}

.pro-table-head {
  font-weight: 300;
  padding: 0 10px;
}

.pro-table-head,
.pro-table-body {
  min-width: 130px;
}

.pro-table-body {
  height: 20px !important;
}

.table-container th {
  border-right: 1px solid #ccc;
}

.table-container thead tr {
  background: #f0f1f3;
  color: #000;
  display: block;
}

.table-container tbody {
  display: block;
  height: 180px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.table-container tbody tr {
  background: #fff;
  border-bottom: 1px solid #eee;
}

.focused-select,
.table-pagination {
  background: #d3e1f1;
}

.table-pagination {
  padding: 0 5px;
}

.pagination-text {
  margin: 0 10px;
  color: #000 !important;
}

.pagination-search {
  width: 50px;
  text-align: center;
  padding: 0 1px;
}

.unspscMainContent .pagination-search,
.unspsc-sea,
.other-search {
  height: 1.3rem !important;
}

.direction-img {
  height: 13px;
}

.count-label {
  color: #fb3922;
  float: right;
}

.text-div {
  position: relative;
  flex-direction: column;
  padding: 2% 10px;
  width: 100%;
  z-index: 1;
}

.ladder-input {
  max-width: 100%;
  width: auto;
}

.vert-line {
  height: 12px;
  width: 1px;
  background: #000;
}

.horiz-line {
  width: 12px;
  border-top: 1px solid #000;
}

.danger-head,
.danger-search {
  color: #d20000;
}

.danger-vert-line {
  left: 2.5%;
  top: 30%;
}

.danger-horiz-line {
  left: 2.7%;
  top: 40%;
}

.success-head,
.success-search {
  color: #3b9903;
}

.success-search {
  margin: 7px 0 0 3.6%;
}

.success-vert-line {
  left: 6%;
  top: 50%;
}

.success-horiz-line {
  left: 6.2%;
  top: 59%;
}

.Class-head,
.Class-search {
  color: #2e4aab;
}

.Class-search {
  margin: 7px 0 0 7%;
}

.Class-vert-line {
  left: 9.5%;
  top: 70%;
}

.Class-horiz-line {
  left: 9.6%;
  top: 79%;
}

.commodity-head,
.default-search,
.category-text {
  color: #000;
}

.default-search {
  margin: 7px 0 0 10.4%;
}

.pro-search {
  width: 100%;
}

.input-part {
  position: relative;
  display: flex;
  padding: 5px 10px;
  width: 100%;
}
.segment-name {
  width: 15% !important;
}
.hyphen-symbol {
  position: relative;
  padding: 0px 5px;
}

.left-col,
.right-col {
  justify-content: space-evenly;
}

.cat-defination {
  border-bottom: 5px solid #a0a0a0;
  // min-height: 80vh;
  max-width: 55% !important;
  height: 100%;
}
.search-box-div {
  padding: 0.1% 0 1%;
  min-height: 15%;
}
.search-result-div {
  min-height: 7%;
}

/* Tooltip Screens */
.category-text {
  position: relative;
}

.unspsc-input {
  width: 100%;
}

.unspsc-screens {
  background-color: transparent;
  height: 25px !important;
}
.unspsc-tooltip {
  background: #f5f5f5;
  border-radius: 0;
  // z-index: 99999;
  width: 6% !important;
  height: 25px !important;
  display: flex;
  align-items: center;
}
.unsp-cateory-fields {
  position: relative;
}

.pagination-icon {
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  width: 9%;
  padding: 0 3px;
}
.unspsc-page-input {
  text-align: center;
  width: 50px !important;
  height: 100%;
}
.pag-prev,
.pag-next {
  cursor: pointer;
}

/* Tooltip Screens */

/* Media Query Tooltip Screens */
@media only screen and (max-width: 1366px) {
  .definaion-div {
    height: 60% !important;
  }
  .segmment-section {
    height: 40% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .favicity-select select,
  .dot-label,
  .Unspc-custom-table .table-bordered td,
  .Unspc-custom-table .table-bordered th,
  .unspsc-text,
  .unspsc-select option,
  .pro-icon,
  .unspsc-input,
  .most-pro,
  .pro-table-head,
  .ladder-input,
  .pro-table-body,
  .commodity-head,
  .favicity-select,
  .version-text,
  .default-search,
  .category-text,
  .search-box-div,
  .check-label,
  .pagination-div {
    font-size: 0.65rem !important;
  }
  .unspsc-check {
    height: 12px !important;
    width: 12px !important;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .az-header {
    height: 50px !important;
  }
  .unspsc-version-section {
    .unspsc-text {
      min-width: 28%;
    }
  }
  .favicity-select select,
  .dot-label,
  .Unspc-custom-table .table-bordered td,
  .Unspc-custom-table .table-bordered th,
  .unspsc-text,
  .unspsc-select option,
  .pro-icon,
  .unspsc-input,
  .most-pro,
  .pro-table-head,
  .ladder-input,
  .pro-table-body,
  .commodity-head,
  .favicity-select,
  .version-text,
  .default-search,
  .category-text,
  .search-box-div,
  .check-label,
  .pagination-div {
    font-size: 0.7rem;
  }
  .span-cat {
    font-size: 0.85rem;
  }
  .unspsc-version-section {
    min-height: 20px;
  }
  .text-div {
    padding: 0.6% 10px;
  }
  .az-header-menu .nav-item.active > .nav-link::before {
    bottom: -14px !important;
  }
  .extra-row {
    padding: 3px 0;
  }
  .search-box-div {
    padding: 0 !important;
  }
  .input-part {
    padding: 5px 10px;
  }
  .ladder-input,
  .category-text,
  .unspsc-input {
    height: 18px !important;
  }
  .Unspc-custom-table .table-bordered td,
  .Unspc-custom-table .table-bordered th {
    height: 15px !important;
  }
  .favicity-select {
    height: 18px;
  }
  .unspsc-head {
    margin: 3px 0 0 0px;
  }

  .table-div {
    margin: 0 0 0 16px;
    width: 96%;
  }

  .pagination-search {
    width: 28px;
  }

  .ladder-input,
  .unspsc-sea {
    max-width: 100%;
    width: auto;
  }

  .danger-vert-line {
    // left: 2.5%;
    top: 23%;
  }
  .danger-horiz-line {
    top: 35% !important;
  }
  .success-vert-line {
    top: 48%;
  }
  .success-horiz-line {
    top: 60%;
  }
  .Class-vert-line {
    top: 73%;
  }
  .Class-horiz-line {
    top: 85%;
  }
  .pro-search {
    width: 98.6%;
  }

  .pro-end-padding {
    padding-right: 9px;
  }

  .input-part {
    width: 100% !important;
  }

  .unspsc-sea {
    width: 99%;
  }

  .unspsc-check {
    width: 13px;
  }

  .unspsc-close-modal {
    left: 650px !important;
    top: 710px !important;
  }

  .table-container tbody {
    height: 80px !important;
  }

  .dot-display {
    width: 10px;
    height: 10px;
  }
  /* when we change resolution from system setting */
}

@media only screen and (min-width: 1441px) and (max-width: 1832px) {
  .pagination-search {
    width: 35px;
  }

  .table-div {
    margin: 0 0 0 11px;
    width: 97%;
  }

  .other-search,
  .ladder-input,
  .unspsc-sea {
    max-width: 100%;
  }

  .input-part {
    width: 100% !important;
  }

  .danger-horiz-line {
    left: 10px;
  }

  // .success-search {
  //   margin: 7px 0 0 25px;
  // }

  .default-search {
    width: 86%;
  }

  .unspsc-check {
    width: 13px;
  }

  .ladder-input {
    margin-right: 2px;
    width: auto;
  }

  .pro-end-padding {
    padding-right: 8px;
  }
}

/* Media Query Tooltip Screens */

/* styles.css */
@keyframes blink {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.version-text {
  animation: blink 1.5s infinite;
  color: #ec4a0f;
}

.add-unspsc-modal .modal-dialog {
  max-width: 100%;
  z-index: 99999;
}
.add-unspsc-modal .modal-content {
  height: 100%;
}

.unspsc-modal-data {
  padding: 0.5% 1%;
  height: 100%;
}

.unspsc-btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
}

.unspsc-close-modal {
  left: 908px;
  top: 820px;
}

.modal-btn {
  padding: 3px 15px;
  min-height: 100%;
}
.unspscMainContent .favicity-select select,
.dot-label,
.Unspc-custom-table .table-bordered td,
.Unspc-custom-table .table-bordered th,
.unspsc-text,
.unspsc-select option,
.pro-icon,
.unspsc-input,
.most-pro,
.pro-table-head,
.ladder-input,
.pro-table-body,
.commodity-head,
.favicity-select,
.version-text,
.default-search,
.category-text,
.search-box-div,
.check-label,
.pagination-div {
  font-size: 0.75rem !important;
}
