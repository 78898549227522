/*
* Azia v1.0.0 (https://www.bootstrapdash.com/demo/azia/v1.0/)
* Copyright 2019 BootstrapDash
* Licensed under BootstrapDash License
*
*
* ------ Table Of Contents -------
*
*   1. FONTS IMPORT
*   2. BOOTSTRAP FRAMEWORK
*   3. BOOTSTRAP OVERRIDES
*      3.1  Accordion
*      3.2  Alerts
*      3.3  Badge
*      3.4  Breadcrumb
*      3.5  Buttons
*      3.6  Cards
*      3.7  Carousel
*      3.8  Dropdown
*      3.9  Forms
*      3.10 Grid
*      3.11 Input Group
*      3.12 Modal
*      3.13 Nav
*      3.14 Pagination
*      3.15 Popover
*      3.16 Progress
*      3.17 Table
*      3.18 Toast
*      3.19 Tooltip
*   4. CUSTOM ELEMENT STYLES
*      4.1  Accordion
*      4.2  Buttons
*      4.3  Charts
*      4.4  Forms
*      4.5  Image
*      4.6  List
*      4.7  Modal
*      4.8  Nav
*   5. CUSTOM VENDOR STYLES
*      5.1  DataTables
*      5.2  DatePicker
*      5.3  DateTimePicker
*      5.4  jQuery Steps
*      5.5  jQVMap
*      5.6  Morris
*      5.7  Parsley
*      5.8  Quill
*      5.9  RangSlider
*      5.10 Scrollbar
*      5.11 Select2
*      5.12 Spectrum
*   6. PAGE LAYOUT STYLES
*      6.1  Content
*      6.2  Footer
*      6.3  Global
*      6.4  Header
*      6.5  Iconbar
*      6.6  Navbar
*      6.7  Navbar Variant
*      6.8  Sidebar
*   7. TEMPLATE STYLES
*      7.1  Demo
*      7.2  Calendar
*      7.3  Chat
*      7.4  Contacts
*      7.5  Invoice
*      7.6  Mail
*      7.7  Profile
*      7.8  Signin
*      7.9  Signup
*      7.10 Error
*   8. DASHBOARD STYLES
*      8.1  Dashboard One
*      8.2  Dashboard Two
*      8.3  Dashboard Three
*      8.4  Dashboard Four
*      8.5  Dashboard Five
*      8.6  Dashboard Six
*      8.7  Dashboard Seven
*      8.8  Dashboard Eight
*      8.9  Dashboard Nine
*      8.10 Dashboard Ten
*   9. UTILITIES/HELPER CLASSES
*      9.1  Background
*      9.2  Border
*      9.3  Height
*      9.4  Margin
*      9.5  Misc
*      9.6  Padding
*      9.7  Position
*      9.8  Typography
*      9.9  Width
*
*/

/* ############### FONTS IMPORT ############### */

// @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
// @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
// @import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800");

/* ############### BOOTSTRAP FRAMEWORK ############### */

/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
  --blue: #3366ff;
  --indigo: #5b47fb;
  --purple: #6f42c1;
  --pink: #f10075;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #3bb001;
  --teal: #00cccc;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #7987a1;
  --gray-dark: #3b4863;
  --primary: #3366ff;
  --secondary: #7987a1;
  --success: #3bb001;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f4f5f8;
  --dark: #3b4863;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #031b4e;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3366ff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0039e6;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #7987a1;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

// button,
// [type="button"],
// [type="reset"],
// [type="submit"] {
//   -webkit-appearance: button;
// }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  //-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.1875rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.53125rem;
}

h4,
.h4 {
  font-size: 1.3125rem;
}

h5,
.h5 {
  font-size: 1.09375rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #7987a1;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #cdd4e0;
  border-radius: 3px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #7987a1;
}

code {
  font-size: 87.5%;
  color: #f10075;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #1c273c;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #1c273c;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1366px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1330px;
  }
}

@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1420px;
  }
}

@media (min-width: 1600px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1560px;
  }
}

@media (min-width: 1680px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1620px;
  }
}

@media (min-width: 1920px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1860px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #031b4e;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #cdd4e0;
  //white-space: nowrap;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #cdd4e0;
}

.table tbody + tbody {
  border-top: 2px solid #cdd4e0;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 0px solid #cdd4e0;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #cdd4e0;
  white-space: nowrap;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #031b4e;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6d4ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95afff;
}

.table-hover .table-primary:hover {
  background-color: #adc1ff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #adc1ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9dde5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b9c1ce;
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cacfdb;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c8e9b8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #99d67b;
}

.table-hover .table-success:hover {
  background-color: #b9e3a5;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b9e3a5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f9fafb;
}

.table-hover .table-light:hover {
  background-color: #ededf3;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ededf3;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c8ccd3;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #99a0ae;
}

.table-hover .table-dark:hover {
  background-color: #babfc8;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #babfc8;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #3b4863;
  border-color: #49597b;
}

.table .thead-light th {
  color: #596882;
  background-color: #e3e7ed;
  border-color: #cdd4e0;
}

.table-dark {
  color: #fff;
  background-color: #3b4863;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #49597b;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #596882;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cdd4e0;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #596882;
}

.form-control:focus {
  color: #596882;
  background-color: #fff;
  border-color: #b3c6ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.form-control::placeholder {
  color: #7987a1;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e3e7ed;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #596882;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #031b4e;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #7987a1;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #3bb001;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(59, 176, 1, 0.9);
  border-radius: 3px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
input.parsley-success ~ .valid-feedback,
textarea.parsley-success ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
input.parsley-success ~ .valid-tooltip,
textarea.parsley-success ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid,
input.form-control.parsley-success,
textarea.form-control.parsley-success {
  border-color: #3bb001;
  padding-right: calc(1.5em + 0.75rem);
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
input.form-control.parsley-success:focus,
textarea.form-control.parsley-success:focus {
  border-color: #3bb001;
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid,
textarea.form-control.parsley-success {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
  border-color: #3bb001;
  padding-right: calc(0.75em + 2.3125rem);
  ////background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
  //   no-repeat right 0.75rem center/8px 10px,
  //  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
  //   #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
  //   calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus,
input.custom-select.parsley-success:focus,
textarea.custom-select.parsley-success:focus {
  border-color: #3bb001;
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label,
input.form-check-input.parsley-success ~ .form-check-label,
textarea.form-check-input.parsley-success ~ .form-check-label {
  color: #3bb001;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
input.form-check-input.parsley-success ~ .valid-feedback,
textarea.form-check-input.parsley-success ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
input.form-check-input.parsley-success ~ .valid-tooltip,
textarea.form-check-input.parsley-success ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label,
input.custom-control-input.parsley-success ~ .custom-control-label,
textarea.custom-control-input.parsley-success ~ .custom-control-label {
  color: #3bb001;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before,
input.custom-control-input.parsley-success ~ .custom-control-label::before,
textarea.custom-control-input.parsley-success ~ .custom-control-label::before {
  border-color: #3bb001;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
input.custom-control-input.parsley-success:checked
  ~ .custom-control-label::before,
textarea.custom-control-input.parsley-success:checked
  ~ .custom-control-label::before {
  border-color: #4ce301;
  background-color: #4ce301;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
input.custom-control-input.parsley-success:focus
  ~ .custom-control-label::before,
textarea.custom-control-input.parsley-success:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
input.custom-control-input.parsley-success:focus:not(:checked)
  ~ .custom-control-label::before,
textarea.custom-control-input.parsley-success:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #3bb001;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label,
input.custom-file-input.parsley-success ~ .custom-file-label,
textarea.custom-file-input.parsley-success ~ .custom-file-label {
  border-color: #3bb001;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label,
input.custom-file-input.parsley-success:focus ~ .custom-file-label,
textarea.custom-file-input.parsley-success:focus ~ .custom-file-label {
  border-color: #3bb001;
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 3px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
input.parsley-error ~ .invalid-feedback,
textarea.parsley-error ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
input.parsley-error ~ .invalid-tooltip,
textarea.parsley-error ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
input.form-control.parsley-error,
textarea.form-control.parsley-error {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
input.form-control.parsley-error:focus,
textarea.form-control.parsley-error:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid,
textarea.form-control.parsley-error {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  // //background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
  //     no-repeat right 0.75rem center/8px 10px,
  //   url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
  //     #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
  //     calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus,
input.custom-select.parsley-error:focus,
textarea.custom-select.parsley-error:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label,
input.form-check-input.parsley-error ~ .form-check-label,
textarea.form-check-input.parsley-error ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
input.form-check-input.parsley-error ~ .invalid-feedback,
textarea.form-check-input.parsley-error ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
input.form-check-input.parsley-error ~ .invalid-tooltip,
textarea.form-check-input.parsley-error ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label,
input.custom-control-input.parsley-error ~ .custom-control-label,
textarea.custom-control-input.parsley-error ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before,
input.custom-control-input.parsley-error ~ .custom-control-label::before,
textarea.custom-control-input.parsley-error ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
input.custom-control-input.parsley-error:checked
  ~ .custom-control-label::before,
textarea.custom-control-input.parsley-error:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
input.custom-control-input.parsley-error:focus ~ .custom-control-label::before,
textarea.custom-control-input.parsley-error:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
input.custom-control-input.parsley-error:focus:not(:checked)
  ~ .custom-control-label::before,
textarea.custom-control-input.parsley-error:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label,
input.custom-file-input.parsley-error ~ .custom-file-label,
textarea.custom-file-input.parsley-error ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label,
input.custom-file-input.parsley-error:focus ~ .custom-file-label,
textarea.custom-file-input.parsley-error:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn,
.sp-container button {
  display: inline-block;
  font-weight: 400;
  color: #031b4e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn,
  .sp-container button {
    transition: none;
  }
}

.btn:hover,
.sp-container button:hover {
  color: #031b4e;
  text-decoration: none;
}

.btn:focus,
.sp-container button:focus,
.btn.focus,
.sp-container button.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled,
.sp-container button.disabled,
.btn:disabled,
.sp-container button:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled),
.sp-container button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3366ff;
  border-color: #3366ff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0d49ff;
  border-color: #0040ff;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0d49ff;
  border-color: #0040ff;
  box-shadow: 0 0 0 0.2rem rgba(82, 125, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #3366ff;
  border-color: #3366ff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0040ff;
  border-color: #003df2;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 125, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #64738f;
  border-color: #5f6d88;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #64738f;
  border-color: #5f6d88;
  box-shadow: 0 0 0 0.2rem rgba(141, 153, 175, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f6d88;
  border-color: #5a6780;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 153, 175, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #3bb001;
  border-color: #3bb001;
}

.btn-success:hover {
  color: #fff;
  background-color: #2e8a01;
  border-color: #2a7d01;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #2e8a01;
  border-color: #2a7d01;
  box-shadow: 0 0 0 0.2rem rgba(88, 188, 39, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #3bb001;
  border-color: #3bb001;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2a7d01;
  border-color: #267101;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 188, 39, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #1c273c;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #1c273c;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #1c273c;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(221, 170, 15, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #1c273c;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #1c273c;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 170, 15, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #1c273c;
  background-color: #f4f5f8;
  border-color: #f4f5f8;
}

.btn-light:hover {
  color: #1c273c;
  background-color: #dde0e9;
  border-color: #d5d9e4;
}

.btn-light:focus,
.btn-light.focus {
  color: #1c273c;
  background-color: #dde0e9;
  border-color: #d5d9e4;
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #1c273c;
  background-color: #f4f5f8;
  border-color: #f4f5f8;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #1c273c;
  background-color: #d5d9e4;
  border-color: #cdd2df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #3b4863;
  border-color: #3b4863;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2d374b;
  border-color: #283143;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #2d374b;
  border-color: #283143;
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #3b4863;
  border-color: #3b4863;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #283143;
  border-color: #232b3b;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-outline-primary {
  color: #3366ff;
  border-color: #3366ff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3366ff;
  border-color: #3366ff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #3366ff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3366ff;
  border-color: #3366ff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.5);
}

.btn-outline-secondary {
  color: #7987a1;
  border-color: #7987a1;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #7987a1;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #7987a1;
  border-color: #7987a1;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}

.btn-outline-success {
  color: #3bb001;
  border-color: #3bb001;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #3bb001;
  border-color: #3bb001;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #3bb001;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #3bb001;
  border-color: #3bb001;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #1c273c;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #1c273c;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f4f5f8;
  border-color: #f4f5f8;
}

.btn-outline-light:hover {
  color: #1c273c;
  background-color: #f4f5f8;
  border-color: #f4f5f8;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f4f5f8;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #1c273c;
  background-color: #f4f5f8;
  border-color: #f4f5f8;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.btn-outline-dark {
  color: #3b4863;
  border-color: #3b4863;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #3b4863;
  border-color: #3b4863;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #3b4863;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #3b4863;
  border-color: #3b4863;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3366ff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0039e6;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #7987a1;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn,
.sp-container .btn-group-lg > button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn,
.sp-container .btn-group-sm > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #031b4e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e3e7ed;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1c273c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #141c2b;
  text-decoration: none;
  background-color: #f4f5f8;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3366ff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #7987a1;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #7987a1;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #1c273c;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.sp-container .btn-group > button,
.btn-group-vertical > .btn,
.sp-container .btn-group-vertical > button {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.sp-container .btn-group > button:hover,
.btn-group-vertical > .btn:hover,
.sp-container .btn-group-vertical > button:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.sp-container .btn-group > button:focus,
.btn-group > .btn:active,
.sp-container .btn-group > button:active,
.btn-group > .btn.active,
.sp-container .btn-group > button.active,
.btn-group-vertical > .btn:focus,
.sp-container .btn-group-vertical > button:focus,
.btn-group-vertical > .btn:active,
.sp-container .btn-group-vertical > button:active,
.btn-group-vertical > .btn.active,
.sp-container .btn-group-vertical > button.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.sp-container .btn-group > button:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group > button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.sp-container .btn-group > .btn-group:not(:last-child) > button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.sp-container .btn-group > button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.sp-container .btn-group > .btn-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.sp-container .btn-group-sm > button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.sp-container .btn-group-lg > button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.sp-container .btn-group-vertical > button,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.sp-container .btn-group-vertical > button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.sp-container
  .btn-group-vertical
  > button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.sp-container .btn-group-vertical > .btn-group:not(:last-child) > button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.sp-container .btn-group-vertical > button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.sp-container .btn-group-vertical > .btn-group:not(:first-child) > button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.sp-container .btn-group-toggle > button,
.btn-group-toggle > .btn-group > .btn,
.sp-container .btn-group-toggle > .btn-group > button {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.sp-container .btn-group-toggle > button input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.sp-container .btn-group-toggle > button input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.sp-container .btn-group-toggle > .btn-group > button input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.sp-container .btn-group-toggle > .btn-group > button input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .sp-container button,
.sp-container .input-group-prepend button,
.input-group-append .btn,
.input-group-append .sp-container button,
.sp-container .input-group-append button {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .sp-container button:focus,
.sp-container .input-group-prepend button:focus,
.input-group-append .btn:focus,
.input-group-append .sp-container button:focus,
.sp-container .input-group-append button:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .sp-container button + .btn,
.sp-container .input-group-prepend button + .btn,
.input-group-prepend .sp-container .btn + button,
.sp-container .input-group-prepend .btn + button,
.input-group-prepend .sp-container button + button,
.sp-container .input-group-prepend button + button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .sp-container button + .input-group-text,
.sp-container .input-group-prepend button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .sp-container .input-group-text + button,
.sp-container .input-group-prepend .input-group-text + button,
.input-group-append .btn + .btn,
.input-group-append .sp-container button + .btn,
.sp-container .input-group-append button + .btn,
.input-group-append .sp-container .btn + button,
.sp-container .input-group-append .btn + button,
.input-group-append .sp-container button + button,
.sp-container .input-group-append button + button,
.input-group-append .btn + .input-group-text,
.input-group-append .sp-container button + .input-group-text,
.sp-container .input-group-append button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .sp-container .input-group-text + button,
.sp-container .input-group-append .input-group-text + button {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #596882;
  text-align: center;
  white-space: nowrap;
  background-color: #e3e7ed;
  border: 1px solid #cdd4e0;
  border-radius: 3px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.sp-container .input-group-lg > .input-group-prepend > button,
.input-group-lg > .input-group-append > .btn,
.sp-container .input-group-lg > .input-group-append > button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.sp-container .input-group-sm > .input-group-prepend > button,
.input-group-sm > .input-group-append > .btn,
.sp-container .input-group-sm > .input-group-append > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.sp-container .input-group > .input-group-prepend > button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.sp-container .input-group > .input-group-append:not(:last-child) > button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.sp-container
  .input-group
  > .input-group-append:last-child
  > button:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.sp-container .input-group > .input-group-append > button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.sp-container .input-group > .input-group-prepend:not(:first-child) > button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.sp-container
  .input-group
  > .input-group-prepend:first-child
  > button:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3366ff;
  background-color: #3366ff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b3c6ff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #e6ecff;
  border-color: #e6ecff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #7987a1;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e3e7ed;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #97a3b9 solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

// .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
// }

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #3366ff;
  background-color: #3366ff;
}

// .custom-checkbox
//   .custom-control-input:indeterminate
//   ~ .custom-control-label::after {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
// }

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(51, 102, 255, 0.5);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(51, 102, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

// .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
// }

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(51, 102, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #97a3b9;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(51, 102, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #596882;
  vertical-align: middle;
  background: #fff;
  // url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
  // no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #cdd4e0;
  border-radius: 3px;
  appearance: none;
}

.custom-select:focus {
  border-color: #b3c6ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #596882;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #7987a1;
  background-color: #e3e7ed;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #596882;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76562rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #b3c6ff;
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e3e7ed;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #596882;
  background-color: #fff;
  border: 1px solid #cdd4e0;
  border-radius: 3px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #596882;
  content: "Browse";
  background-color: #e3e7ed;
  border-left: inherit;
  border-radius: 0 3px 3px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3366ff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #e6ecff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #cdd4e0;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3366ff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #e6ecff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #cdd4e0;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3366ff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #e6ecff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #cdd4e0;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #cdd4e0;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #97a3b9;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #97a3b9;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #97a3b9;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #7987a1;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #cdd4e0;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e3e7ed #e3e7ed #cdd4e0;
}

.nav-tabs .nav-link.disabled {
  color: #7987a1;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #596882;
  background-color: #fff;
  border-color: #cdd4e0 #cdd4e0 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3366ff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

// .navbar-light .navbar-toggler-icon {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
// }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

// .navbar-dark .navbar-toggler-icon {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
// }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 2px 2px 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 2px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e3e7ed;
  border-radius: 3px;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #7987a1;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #7987a1;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 3px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3366ff;
  background-color: #fff;
  border: 1px solid #cdd4e0;
}

.page-link:hover {
  z-index: 2;
  color: #0039e6;
  text-decoration: none;
  background-color: #e3e7ed;
  border-color: #cdd4e0;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3366ff;
  border-color: #3366ff;
}

.page-item.disabled .page-link {
  color: #7987a1;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #cdd4e0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge,
.sp-container button .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3366ff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #0040ff;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #7987a1;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #5f6d88;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(121, 135, 161, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #3bb001;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #2a7d01;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #1c273c;
  background-color: #ffc107;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #1c273c;
  background-color: #d39e00;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #1c273c;
  background-color: #f4f5f8;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #1c273c;
  background-color: #d5d9e4;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #3b4863;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #283143;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e3e7ed;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 3px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1b3585;
  background-color: #d6e0ff;
  border-color: #c6d4ff;
}

.alert-primary hr {
  border-top-color: #adc1ff;
}

.alert-primary .alert-link {
  color: #12245b;
}

.alert-secondary {
  color: #3f4654;
  background-color: #e4e7ec;
  border-color: #d9dde5;
}

.alert-secondary hr {
  border-top-color: #cacfdb;
}

.alert-secondary .alert-link {
  color: #292e37;
}

.alert-success {
  color: #1f5c01;
  background-color: #d8efcc;
  border-color: #c8e9b8;
}

.alert-success hr {
  border-top-color: #b9e3a5;
}

.alert-success .alert-link {
  color: #0e2a00;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #7f7f81;
  background-color: #fdfdfe;
  border-color: #fcfcfd;
}

.alert-light hr {
  border-top-color: #ededf3;
}

.alert-light .alert-link {
  color: #666667;
}

.alert-dark {
  color: #1f2533;
  background-color: #d8dae0;
  border-color: #c8ccd3;
}

.alert-dark hr {
  border-top-color: #babfc8;
}

.alert-dark .alert-link {
  color: #0c0e13;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e3e7ed;
  border-radius: 3px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3366ff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 3px;
}

.list-group-item-action {
  width: 100%;
  color: #596882;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #596882;
  text-decoration: none;
  background-color: #f4f5f8;
}

.list-group-item-action:active {
  color: #031b4e;
  background-color: #e3e7ed;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #7987a1;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3366ff;
  border-color: #3366ff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1b3585;
  background-color: #c6d4ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #1b3585;
  background-color: #adc1ff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1b3585;
  border-color: #1b3585;
}

.list-group-item-secondary {
  color: #3f4654;
  background-color: #d9dde5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #3f4654;
  background-color: #cacfdb;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3f4654;
  border-color: #3f4654;
}

.list-group-item-success {
  color: #1f5c01;
  background-color: #c8e9b8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1f5c01;
  background-color: #b9e3a5;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1f5c01;
  border-color: #1f5c01;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #7f7f81;
  background-color: #fcfcfd;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7f7f81;
  background-color: #ededf3;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f81;
  border-color: #7f7f81;
}

.list-group-item-dark {
  color: #1f2533;
  background-color: #c8ccd3;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1f2533;
  background-color: #babfc8;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2533;
  border-color: #1f2533;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #7987a1;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 1%;
  left: 1%;
  z-index: 1050;
  display: none;
  width: 98%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  padding-bottom: 20px;
}
.session-timeout-modal .modal-dialog {
  left: 35%;
  max-width: 30%;
}
.forgot-passowrd-modal .modal-dialog {
  left: 35%;
  max-width: 30%;
}

.modal-dialog {
  position: relative;
  width: auto;
  height: 100%;
  // margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  max-height: 100%;
  min-height: max-content;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #cdd4e0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #cdd4e0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    // margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #031b4e;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

// .carousel-control-prev-icon {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
// }

// .carousel-control-next-icon {
//   //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
// }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3366ff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0040ff !important;
}

.bg-secondary {
  background-color: #7987a1 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5f6d88 !important;
}

.bg-success {
  background-color: #3bb001 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2a7d01 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f4f5f8 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d5d9e4 !important;
}

.bg-dark {
  background-color: #3b4863 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #283143 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #cdd4e0 !important;
}

.border-top {
  border-top: 1px solid #cdd4e0 !important;
}

.border-right {
  border-right: 1px solid #cdd4e0 !important;
}

.border-bottom {
  border-bottom: 1px solid #cdd4e0 !important;
}

.border-left {
  border-left: 1px solid #cdd4e0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3366ff !important;
}

.border-secondary {
  border-color: #7987a1 !important;
}

.border-success {
  border-color: #3bb001 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f4f5f8 !important;
}

.border-dark {
  border-color: #3b4863 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 3px !important;
}

.rounded-top {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.rounded-right {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 1920px !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 1920px !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3366ff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #0039e6 !important;
}

.text-secondary {
  color: #7987a1 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #556179 !important;
}

.text-success {
  color: #3bb001 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #226401 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f4f5f8 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #c5cbda !important;
}

.text-dark {
  color: #3b4863 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1e2533 !important;
}

.text-body {
  color: #031b4e !important;
}

.text-muted {
  color: #7987a1 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #97a3b9;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #cdd4e0 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #cdd4e0;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #cdd4e0;
  }
}

/* ########## BOOTSTRAP OVERRIDES ########## */

/* ###### 3.1 Accordion ###### */

.accordion .card {
  border-width: 0;
}

.accordion .card + .card {
  margin-top: 1px;
}

.accordion .card-header {
  padding: 0;
  background-color: transparent;
  font-weight: 500;
}

.accordion .card-header a {
  display: block;
  padding: 14px 20px;
  color: #1c273c;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  background-color: #f4f5f8;
}

.accordion .card-header a:hover,
.accordion .card-header a:focus {
  color: #1c273c;
}

.accordion .card-header a.collapsed {
  color: #596882;
  background-color: #e3e7ed;
  border-bottom-color: transparent;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
  color: #1c273c;
  background-color: #cdd4e0;
}

.accordion .card-body {
  padding: 0 20px 20px;
  background-color: #f4f5f8;
  transition: none;
}

.accordion-indigo .card {
  border-color: #5b47fb;
}

.accordion-indigo .card-header a,
.accordion-indigo .card-header a:hover,
.accordion-indigo .card-header a:focus {
  color: #5b47fb;
}

.accordion-indigo .card-header a.collapsed {
  background-color: #5b47fb;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.accordion-indigo .card-header a.collapsed:hover,
.accordion-indigo .card-header a.collapsed:focus {
  background-color: #452efa;
  color: #fff;
}

.accordion-blue .card {
  border-color: #3366ff;
}

.accordion-blue .card-header a,
.accordion-blue .card-header a:hover,
.accordion-blue .card-header a:focus {
  color: #3366ff;
}

.accordion-blue .card-header a.collapsed {
  background-color: #3366ff;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.accordion-blue .card-header a.collapsed:hover,
.accordion-blue .card-header a.collapsed:focus {
  background-color: #1a53ff;
  color: #fff;
}

.accordion-dark .card {
  border-color: #1c273c;
}

.accordion-dark .card-header a,
.accordion-dark .card-header a:hover,
.accordion-dark .card-header a:focus {
  color: #1c273c;
}

.accordion-dark .card-header a.collapsed {
  background-color: #1c273c;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.accordion-dark .card-header a.collapsed:hover,
.accordion-dark .card-header a.collapsed:focus {
  background-color: #141c2b;
  color: #fff;
}

.accordion-gray .card {
  border-color: #7987a1;
}

.accordion-gray .card-header a,
.accordion-gray .card-header a:hover,
.accordion-gray .card-header a:focus {
  color: #7987a1;
}

.accordion-gray .card-header a.collapsed {
  background-color: #7987a1;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.accordion-gray .card-header a.collapsed:hover,
.accordion-gray .card-header a.collapsed:focus {
  background-color: #6a7a96;
  color: #fff;
}

/* ###### 3.2 Alerts ###### */

.alert {
  border-width: 0;
  padding: 12px 15px;
  border-radius: 0;
}

.alert .close {
  text-shadow: none;
  opacity: 0.2;
}

.alert strong {
  font-weight: 600;
}

[class*="alert-outline-"] {
  border: 1px solid transparent;
}

.alert-outline-success {
  border-color: #65fe19;
  color: #3bb001;
}

.alert-outline-info {
  border-color: #1ab6cf;
  color: #17a2b8;
}

.alert-outline-warning {
  border-color: #ffdb6d;
  color: #d39e00;
}

.alert-outline-danger {
  border-color: #eb8c95;
  color: #bd2130;
}

[class*="alert-solid-"] {
  border-width: 0;
  color: #fff;
}

.alert-solid-success {
  background-color: #3bb001;
}

.alert-solid-info {
  background-color: #17a2b8;
}

.alert-solid-warning {
  background-color: #ffc107;
}

.alert-solid-danger {
  background-color: #dc3545;
}

/* ###### 3.3 Badge ###### */

.badge {
  font-size: 10px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 3px 5px 4px;
}

.badge-light {
  background-color: #cdd4e0;
}

.badge-pill {
  padding-left: 8px;
  padding-right: 8px;
}

/* ###### 3.4 Breadcrumb ###### */

.breadcrumb {
  background-color: #cdd4e0;
}

.breadcrumb-style1,
.breadcrumb-style2,
.breadcrumb-style3 {
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}

.breadcrumb-style1 .breadcrumb-item,
.breadcrumb-style2 .breadcrumb-item,
.breadcrumb-style3 .breadcrumb-item {
  font-size: 11px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
  color: #1c273c;
}

.breadcrumb-style1 .breadcrumb-item a:hover,
.breadcrumb-style1 .breadcrumb-item a:focus,
.breadcrumb-style2 .breadcrumb-item a:hover,
.breadcrumb-style2 .breadcrumb-item a:focus,
.breadcrumb-style3 .breadcrumb-item a:hover,
.breadcrumb-style3 .breadcrumb-item a:focus {
  color: #5b47fb;
}

.breadcrumb-style1 .breadcrumb-item.active,
.breadcrumb-style2 .breadcrumb-item.active,
.breadcrumb-style3 .breadcrumb-item.active {
  color: #5b47fb;
}

.breadcrumb-style1 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  color: #97a3b9;
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  width: 16px;
  text-align: center;
  font-family: "Ionicons";
}

.breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
  content: "\f3d1";
}

.breadcrumb-style3 .breadcrumb-item + .breadcrumb-item::before {
  content: "\f287";
}

/* ###### 3.5 Buttons ###### */

.btn,
.sp-container button {
  border-width: 0;
  line-height: 1.538;
  padding: 8px 20px;
  border-radius: 0;
  transition: none;
  min-height: 38px;
}

.btn:active,
.sp-container button:active,
.btn:focus,
.sp-container button:focus {
  box-shadow: none;
}

.btn-light {
  color: #1c273c;
  background-color: #cdd4e0;
  border-color: #bdc6d6;
}

.btn-light:hover {
  color: #1c273c;
  background-color: #b5c0d1;
  border-color: #9eabc3;
}

.btn-light:focus,
.btn-light.focus {
  color: #1c273c;
  background-color: #b5c0d1;
  border-color: #9eabc3;
  box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #1c273c;
  background-color: #cdd4e0;
  border-color: #bdc6d6;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #1c273c;
  background-color: #aeb9cc;
  border-color: #96a5be;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #5b47fb;
  border-color: #452efa;
}

.btn-indigo:hover {
  color: #fff;
  background-color: #3a22fa;
  border-color: #1f05f0;
}

.btn-indigo:focus,
.btn-indigo.focus {
  color: #fff;
  background-color: #3a22fa;
  border-color: #1f05f0;
  box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
  color: #fff;
  background-color: #5b47fb;
  border-color: #452efa;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #2f15fa;
  border-color: #1e05e4;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #6f42c1;
  border-color: #643ab0;
}

.btn-purple:hover {
  color: #fff;
  background-color: #5e37a6;
  border-color: #4e2d89;
}

.btn-purple:focus,
.btn-purple.focus {
  color: #fff;
  background-color: #5e37a6;
  border-color: #4e2d89;
  box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #6f42c1;
  border-color: #643ab0;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #59339d;
  border-color: #482a80;
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #f10075;
  border-color: #d80069;
}

.btn-pink:hover {
  color: #fff;
  background-color: #cb0062;
  border-color: #a50050;
}

.btn-pink:focus,
.btn-pink.focus {
  color: #fff;
  background-color: #cb0062;
  border-color: #a50050;
  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #f10075;
  border-color: #d80069;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #be005c;
  border-color: #98004a;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-gray-500 {
  color: #1c273c;
  background-color: #97a3b9;
  border-color: #8896af;
}

.btn-gray-500:hover {
  color: #fff;
  background-color: #808faa;
  border-color: #697b9a;
}

.btn-gray-500:focus,
.btn-gray-500.focus {
  color: #fff;
  background-color: #808faa;
  border-color: #697b9a;
  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
  color: #1c273c;
  background-color: #97a3b9;
  border-color: #8896af;
}

.btn-gray-500:not(:disabled):not(.disabled):active,
.btn-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #7988a4;
  border-color: #637493;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus,
.btn-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-700 {
  color: #fff;
  background-color: #596882;
  border-color: #4f5c73;
}

.btn-gray-700:hover {
  color: #fff;
  background-color: #49566b;
  border-color: #3a4455;
}

.btn-gray-700:focus,
.btn-gray-700.focus {
  color: #fff;
  background-color: #49566b;
  border-color: #3a4455;
  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
  color: #fff;
  background-color: #596882;
  border-color: #4f5c73;
}

.btn-gray-700:not(:disabled):not(.disabled):active,
.btn-gray-700:not(:disabled):not(.disabled).active,
.show > .btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #445064;
  border-color: #353e4d;
}

.btn-gray-700:not(:disabled):not(.disabled):active:focus,
.btn-gray-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

[class*="btn-outline-"] {
  padding: 0.4rem 1rem;
  line-height: 1.657;
  border-width: 1px;
  background-color: transparent;
}

[class*="btn-outline-"]:hover,
[class*="btn-outline-"]:focus {
  color: #fff;
}

.btn-outline-indigo {
  border-color: #5b47fb;
  color: #5b47fb;
}

.btn-outline-indigo:hover,
.btn-outline-indigo:focus {
  background-color: #5b47fb;
}

.btn-outline-light {
  border-color: #97a3b9;
  color: #97a3b9;
}

.btn-outline-light:hover,
.btn-outline-light:focus {
  background-color: #97a3b9;
}

.card {
  border-radius: 0;
}

.card.text-white .card-title,
.card.tx-white .card-title {
  color: #fff;
}

.card-header,
.card-footer {
  position: relative;
  border-color: #cdd4e0;
  padding-left: 15px;
  padding-right: 15px;
}

.card-header {
  border-bottom: 0;
}

.card-header:first-child {
  border-radius: 0;
}

.card-header-tab {
  border-bottom: 0;
  padding: 0;
}

.card-title {
  font-weight: 700;
  color: #1c273c;
}

/* ###### 3.7 Carousel ###### */

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: none;
  display: block;
  width: auto;
  height: auto;
}

.carousel-control-prev-icon svg,
.carousel-control-next-icon svg {
  width: 48px;
  height: 48px;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.carousel-indicators.carousel-indicaors-dot li {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.carousel-indicators.carousel-indicaors-dot li.active {
  width: 11px;
  height: 11px;
  border-top-width: 7.5px;
  border-bottom-width: 7.5px;
}

@media (max-width: 767px) {
  .testimonial-carpusel {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .testimonial-carpusel .carousel-inner {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.testimonial-carpusel .carousel-control-prev,
.testimonial-carpusel .carousel-control-next {
  color: #000;
}

@media (max-width: 767px) {
  .testimonial-carpusel .carousel-control-prev,
  .testimonial-carpusel .carousel-control-next {
    position: static;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

/* ###### 3.8 Dropdown ###### */

.dropdown-menu {
  padding: 0;
  border-width: 2px;
  border-radius: 0;
}

.dropdown-item {
  padding: 8px 15px;
  font-size: 13px;
}

.dropdown-header {
  padding-left: 15px;
  padding-right: 15px;
}

.form-control {
  height: 38px;
  border-radius: 0;
}

.form-control:focus {
  border-color: #97a3b9;
  box-shadow: none;
}

.custom-file,
.custom-file-input,
.custom-file-label {
  height: 38px;
}

.custom-file-label,
.custom-file-label::after {
  line-height: 1.8;
  border-radius: 0;
}

.custom-file-label::after {
  height: auto;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #596882;
}

/* ###### 3.10 Grid ###### */

.row-sm {
  margin-left: 0px;
  margin-right: 0px;
}

.row-sm > div {
  padding-left: 10px;
  padding-right: 10px;
}

.row-xs {
  margin-left: -5px;
  margin-right: -5px;
}

.row-xs > div {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 576px) {
  .row-xs--sm {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-xs--sm > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row-sm--sm {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row-sm--sm > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row--sm {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row--sm > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 768px) {
  .row-xs--md {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-xs--md > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row-sm--md {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row-sm--md > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row--md {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row--md > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 992px) {
  .row-xs--lg {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-xs--lg > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row-sm--lg {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row-sm--lg > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row--lg {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row--lg > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .row-xs--xl {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-xs--xl > div {
    padding-left: 5px;
    padding-right: 5px;
  }
  .row-sm--xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .row-sm--xl > div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row--xl {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row--xl > div {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* ###### 3.11 Input Group ###### */

.input-group-prepend,
.input-group-append {
  border-radius: 0;
}

.input-group-text {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  border-radius: 0;
}

/* ###### 3.12 Modal ###### */

.modal-backdrop {
  background-color: #0c1019;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-content {
  border-radius: 0;
  border-width: 0;
}

.modal-content .close {
  font-size: 28px;
  padding: 0;
  margin: 0;
  line-height: 0.5;
}

.modal-header {
  align-items: center;
  padding: 15px;
}

@media (min-width: 576px) {
  .modal-header {
    padding: 15px 20px;
  }
}

@media (min-width: 992px) {
  .modal-header {
    padding: 20px;
  }
}

@media (min-width: 1200px) {
  .modal-header {
    padding: 20px 25px;
  }
}

.modal-header .modal-title {
  margin-bottom: 0;
}

.modal-title {
  font-size: 18px;
  font-weight: 700;
  color: #1c273c;
  line-height: 1;
}

.modal-body {
  padding: 25px;
}

/* ###### 3.13 Nav ###### */

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
  outline: none;
}

.nav-pills .nav-link {
  color: #596882;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
  color: #1c273c;
}

.nav-pills .nav-link.active {
  border-radius: 2px;
  background-color: #5b47fb;
}

.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
  color: #fff;
}

.nav-dark .nav-link {
  color: #97a3b9;
}

.nav-dark .nav-link:hover,
.nav-dark .nav-link:focus {
  color: #fff;
}

.nav-tabs {
  border-bottom-width: 0;
}

.nav-tabs .nav-link {
  background-color: rgba(255, 255, 255, 0.3);
  border-width: 0;
  border-radius: 0;
  padding: 10px 15px;
  line-height: 1.428;
  color: #596882;
}

@media (min-width: 768px) {
  .nav-tabs .nav-link {
    padding: 10px 20px;
  }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.5);
  color: #1c273c;
}

.nav-tabs .nav-link + .nav-link {
  margin-left: 3px;
}

.nav-tabs .nav-link.active {
  background-color: #fff;
  color: #1c273c;
  font-weight: 500;
  letter-spacing: -0.1px;
}

/* ###### 3.14 Pagination ###### */

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 0;
}

.page-item + .page-item {
  margin-left: 1px;
}

.page-item.active .page-link {
  background-color: #5b47fb;
}

.page-link {
  color: #596882;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 38px;
  border-width: 0;
  background-color: #e3e7ed;
  margin-left: 0;
  font-weight: 500;
}

.page-link i {
  font-size: 18px;
  line-height: 0;
}

.page-link .typcn {
  margin-top: 1rem;
}

.page-link:hover,
.page-link:focus {
  color: #1c273c;
  background-color: #cdd4e0;
}

.pagination-dark .page-item.active .page-link {
  background-color: #5b47fb;
  color: #fff;
}

.pagination-dark .page-link {
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.8);
}

.pagination-dark .page-link:hover,
.pagination-dark .page-link:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}

.pagination-primary .page-item.active .page-link {
  background-color: #3366ff;
}

.pagination-success .page-item.active .page-link {
  background-color: #3bb001;
}

.pagination-circled .page-item + .page-item {
  margin-left: 5px;
}

.pagination-circled .page-item:first-child .page-link,
.pagination-circled .page-item:last-child .page-link {
  border-radius: 100%;
}

.pagination-circled .page-link {
  padding: 0;
  width: 40px;
  border-radius: 100%;
}

/* ###### 3.15 Popover ###### */

.popover {
  font-size: 12px;
  padding: 0;
  border-radius: 0;
}

.popover-header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #3b4863;
  letter-spacing: 0.5px;
  padding: 12px 15px;
  background-color: #fff;
  border-color: rgba(28, 39, 60, 0.2);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.popover-header::before {
  display: none !important;
}

.popover-body {
  padding: 15px;
}

.popover-body p:last-child {
  margin-bottom: 0;
}

.popover-head-primary .popover-header {
  color: #fff;
  background-color: #5b47fb;
}

.popover-head-primary.bs-popover-top .arrow,
.popover-head-primary.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: -7px;
}

.popover-head-primary.bs-popover-left .arrow,
.popover-head-primary.bs-popover-auto[x-placement^="left"] .arrow {
  right: -7px;
}

.popover-head-primary.bs-popover-right .arrow,
.popover-head-primary.bs-popover-auto[x-placement^="right"] .arrow {
  left: -7px;
}

.popover-head-primary.bs-popover-bottom .arrow::after,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #5b47fb;
}

.popover-head-secondary .popover-header {
  color: #fff;
  background-color: #3366ff;
}

.popover-head-secondary.bs-popover-top .arrow,
.popover-head-secondary.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: -7px;
}

.popover-head-secondary.bs-popover-left .arrow,
.popover-head-secondary.bs-popover-auto[x-placement^="left"] .arrow {
  right: -7px;
}

.popover-head-secondary.bs-popover-right .arrow,
.popover-head-secondary.bs-popover-auto[x-placement^="right"] .arrow {
  left: -7px;
}

.popover-head-secondary.bs-popover-bottom .arrow::after,
.popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #3366ff;
}

.popover-head-primary,
.popover-head-primary .popover-header,
.popover-head-secondary,
.popover-head-secondary .popover-header {
  border: 0;
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body {
  border: 1px solid rgba(28, 39, 60, 0.2);
  border-top-width: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.popover-head-primary.bs-popover-bottom .arrow::before,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-secondary.bs-popover-bottom .arrow::before,
.popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  display: none;
}

.popover-primary {
  background-color: #5b47fb;
  border-width: 0;
  padding: 15px;
}

.popover-primary .popover-header {
  background-color: transparent;
  border-bottom-width: 0;
  padding: 0 0 15px;
  color: #fff;
}

.popover-primary .popover-body {
  padding: 0;
  color: rgba(255, 255, 255, 0.75);
}

.popover-primary .arrow::before {
  display: none;
}

.popover-primary.bs-popover-top .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #5b47fb;
}

.popover-primary.bs-popover-bottom .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #5b47fb;
}

.popover-primary.bs-popover-left .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #5b47fb;
}

.popover-primary.bs-popover-right .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #5b47fb;
}

.popover-secondary {
  background-color: #3366ff;
  border-width: 0;
  padding: 15px;
}

.popover-secondary .popover-header {
  background-color: transparent;
  border-bottom-width: 0;
  padding: 0 0 15px;
  color: #fff;
}

.popover-secondary .popover-body {
  padding: 0;
  color: rgba(255, 255, 255, 0.75);
}

.popover-secondary .arrow::before {
  display: none;
}

.popover-secondary.bs-popover-top .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #3366ff;
}

.popover-secondary.bs-popover-bottom .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #3366ff;
}

.popover-secondary.bs-popover-left .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #3366ff;
}

.popover-secondary.bs-popover-right .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #3366ff;
}

/* ###### 3.16 Progress ###### */

.progress {
  height: auto;
}

.progress,
.progress-bar {
  border-radius: 0;
}

.progress-bar {
  height: 10px;
  font-size: 11px;
}

.progress-bar-xs {
  height: 5px;
}

.progress-bar-sm {
  height: 8px;
}

.progress-bar-lg {
  height: 15px;
}

/* ###### 3.17 Table ###### */

.table {
  color: #3b4863;
}

.table thead th,
.table thead td {
  //color: #7987a1;
  font-weight: 800;
  font-size: 11px;
  letter-spacing: 0.5px;
  //text-transform: uppercase;
  border-bottom-width: 1px;
  border-top-width: 0;
  padding: 0 8px 5px;
}

.table tbody tr {
  background-color: rgba(255, 255, 255, 0.5);
}

.table tbody tr th {
  font-weight: 500;
}
.table th {
  padding: 9px 15px;
  //line-height: 1.2;
  position: sticky;
  background-color: #f2f8fb !important;
  top: 0px !important; /* Don't forget this, required for the stickiness */
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.table td {
  padding: 9px 8px;
  line-height: 1;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(151, 163, 185, 0.1);
}

.table-bordered thead th,
.table-bordered thead td {
  border-top-width: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: rgba(255, 255, 255, 0.5);
}

/* ###### 3.18 Toast ###### */

.toast {
  background-color: rgba(255, 255, 255, 0.95);
  border-color: rgba(28, 39, 60, 0.16);
  box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
  border-radius: 3px;
}

.toast-header {
  border-bottom-color: rgba(205, 212, 224, 0.4);
}

.toast-header .close {
  font-weight: 300;
  color: #97a3b9;
}

/* ###### 3.19 Tooltip ###### */

.tooltip {
  top: 0;
}

.tooltip-inner {
  font-size: 12px;
  border-radius: 2px;
}

.tooltip-primary .tooltip-inner {
  background-color: #5b47fb;
  color: #fff;
}

.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #5b47fb;
}

.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #5b47fb;
}

.tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #5b47fb;
}

.tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #5b47fb;
}

.tooltip-secondary .tooltip-inner {
  background-color: #3366ff;
  color: #fff;
}

.tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #3366ff;
}

.tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #3366ff;
}

.tooltip-secondary.bs-tooltip-left .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #3366ff;
}

.tooltip-secondary.bs-tooltip-right .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #3366ff;
}

/* Bootstrap Progress */

.progress {
  border-radius: 3px;
  height: 8px;
}

.progress .progress-bar {
  border-radius: 3px;
}

.progress.progress-sm {
  height: 0.375rem;
}

.progress.progress-md {
  height: 8px;
}

.progress.progress-lg {
  height: 15px;
}

.progress.progress-xl {
  height: 18px;
}

/* ############### CUSTOM ELEMENT STYLES ############### */

/* ###### 4.1 Accordion ###### */

.az-accordion .accordion-item {
  background-color: #f4f5f8;
  border: 1px solid #dee2ef;
  position: relative;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-accordion .accordion-item {
    transition: none;
  }
}

.az-accordion .accordion-item:hover,
.az-accordion .accordion-item:focus {
  cursor: pointer;
  background-color: #f1f2f6;
  border-color: #bcc4de;
  z-index: 4;
}

.az-accordion .accordion-item.active {
  background-color: #fff;
  z-index: 5;
  border-color: #bcc4de;
}

.az-accordion .accordion-item.active .accordion-title {
  color: #3366ff;
}

.az-accordion .accordion-item.active .accordion-body {
  display: block;
}

.az-accordion .accordion-item + .accordion-item {
  margin-top: -1px;
}

.az-accordion .accordion-header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.az-accordion .accordion-title {
  font-weight: 700;
  color: #031b4e;
  margin: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-accordion .accordion-title {
    transition: none;
  }
}

.az-accordion .accordion-body {
  display: none;
  padding: 0 20px 20px;
}

/* Accordions */

.accordion .card {
  margin-bottom: 0.75rem;
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
  border-radius: 0.25rem;
}

.accordion .card .card-header {
  background-color: transparent;
  border: none;
  padding: 0;
}

.accordion .card .card-header * {
  font-size: 1rem;
}

.accordion .card .card-header a {
  display: block;
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  position: relative;
  transition: color 0.5s ease;
  padding-right: 0.5rem;
}

@media (prefers-reduced-motion: reduce) {
  .accordion .card .card-header a {
    transition: none;
  }
}

.accordion .card .card-header a:before {
  font-family: "typicons";
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 18px;
  display: block;
}

.accordion .card .card-header a[aria-expanded="true"] {
  color: #3366ff;
}

.accordion .card .card-header a[aria-expanded="true"]:before {
  content: "\e0bd";
}

.accordion .card .card-header a[aria-expanded="false"]:before {
  content: "\e0cf";
}

.accordion .card .card-body {
  font-size: 14px;
  padding: 0 2rem 2rem 2rem;
  line-height: 1.5;
}

.accordion .card .card-body i {
  font-size: 1.25rem;
}

.accordion.accordion-bordered {
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}

.accordion.accordion-bordered .card {
  margin: 0 2rem;
  border-top: 1px solid #cdd4e0;
  box-shadow: none;
  border-radius: 0;
}

.accordion.accordion-bordered .card .card-header,
.accordion.accordion-bordered .card .card-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion.accordion-bordered .card .card-header a:before {
  color: #dc3545;
}

.accordion.accordion-bordered .card .card-header a[aria-expanded="true"] {
  color: inherit;
}

.accordion.accordion-bordered
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\F0062";
}

.accordion.accordion-bordered
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\F004A";
}

.accordion.accordion-bordered .card:first-child {
  border-top: 0;
}

.accordion.accordion-filled .card {
  padding: 0;
}

.accordion.accordion-filled .card .card-header {
  padding: 0;
}

.accordion.accordion-filled .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  transition: all 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .accordion.accordion-filled .card .card-header a {
    transition: none;
  }
}

.accordion.accordion-filled .card .card-header a:before {
  top: 40%;
  right: 40px;
}

.accordion.accordion-filled .card .card-header a[aria-expanded="true"] {
  background: theme-gradient-color(info);
  color: #fff;
}

.accordion.accordion-filled .card .card-header a[aria-expanded="true"]:before {
  content: "\F0143";
  color: #fff;
}

.accordion.accordion-filled .card .card-header a[aria-expanded="false"]:before {
  content: "\f0140";
}

.accordion.accordion-filled .card .card-body {
  padding: 0 2rem 2rem 2rem;
  background: theme-gradient-color(info);
  color: #fff;
}

.accordion.accordion-solid-header .card {
  padding: 0;
}

.accordion.accordion-solid-header .card .card-header {
  padding: 0;
}

.accordion.accordion-solid-header .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  transition: all 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .accordion.accordion-solid-header .card .card-header a {
    transition: none;
  }
}

.accordion.accordion-solid-header .card .card-header a:before {
  top: 40%;
  right: 40px;
}

.accordion.accordion-solid-header .card .card-header a[aria-expanded="true"] {
  background: theme-gradient-color(primary);
  color: #fff;
}

.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\F0143";
  color: #fff;
}

.accordion.accordion-solid-header
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f0140";
}

.accordion.accordion-solid-header .card .card-body {
  padding: 2rem;
}

.accordion.accordion-solid-content .card {
  padding: 0;
}

.accordion.accordion-solid-content .card .card-header {
  padding: 0;
}

.accordion.accordion-solid-content .card .card-header a {
  padding: 2rem 4.5rem 2rem 2rem;
  transition: all 0.2s linear;
}

@media (prefers-reduced-motion: reduce) {
  .accordion.accordion-solid-content .card .card-header a {
    transition: none;
  }
}

.accordion.accordion-solid-content .card .card-header a:before {
  top: 40%;
  right: 40px;
}

.accordion.accordion-solid-content .card .card-header a[aria-expanded="true"] {
  color: inherit;
}

.accordion.accordion-solid-content
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\F0143";
}

.accordion.accordion-solid-content
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f0140";
}

.accordion.accordion-solid-content .card .card-body {
  padding: 2rem;
  background: theme-gradient-color(success);
  color: #fff;
}

.accordion.accordion-multi-colored .card .card-header,
.accordion.accordion-multi-colored .card .card-body {
  background: transparent;
  color: #fff;
}

.accordion.accordion-multi-colored .card:nth-child(1) {
  background: theme-gradient-color(primary);
}

.accordion.accordion-multi-colored .card:nth-child(2) {
  background: theme-gradient-color(success);
}

.accordion.accordion-multi-colored .card:nth-child(3) {
  background: theme-gradient-color(danger);
}

.accordion.accordion-multi-colored .card:nth-child(4) {
  background: theme-gradient-color(warning);
}

.accordion.accordion-multi-colored .card:nth-child(5) {
  background: theme-gradient-color(info);
}

.accordion.accordion-multi-colored .card .card-header a[aria-expanded="true"] {
  color: inherit;
}

.accordion.accordion-multi-colored
  .card
  .card-header
  a[aria-expanded="true"]:before {
  content: "\F0143";
}

.accordion.accordion-multi-colored
  .card
  .card-header
  a[aria-expanded="false"]:before {
  content: "\f0140";
}

/* ###### 4.2 Buttons  ###### */

.btn-az-primary {
  color: #fff;
  background-color: #5b47fb;
  border-color: #452efa;
}

.btn-az-primary:hover {
  color: #fff;
  background-color: #3a22fa;
  border-color: #1f05f0;
}

.btn-az-primary:focus,
.btn-az-primary.focus {
  color: #fff;
  background-color: #3a22fa;
  border-color: #1f05f0;
  box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
}

.btn-az-primary.disabled,
.btn-az-primary:disabled {
  color: #fff;
  background-color: #5b47fb;
  border-color: #452efa;
}

.btn-az-primary:not(:disabled):not(.disabled):active,
.btn-az-primary:not(:disabled):not(.disabled).active,
.show > .btn-az-primary.dropdown-toggle {
  color: #fff;
  background-color: #2f15fa;
  border-color: #1e05e4;
}

.btn-az-primary:not(:disabled):not(.disabled):active:focus,
.btn-az-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-az-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
}

.btn-az-secondary {
  color: #fff;
  background-color: #3366ff;
  border-color: #1a53ff;
}

.btn-az-secondary:hover {
  color: #fff;
  background-color: #0d49ff;
  border-color: #0039e6;
}

.btn-az-secondary:focus,
.btn-az-secondary.focus {
  color: #fff;
  background-color: #0d49ff;
  border-color: #0039e6;
  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-az-secondary.disabled,
.btn-az-secondary:disabled {
  color: #fff;
  background-color: #3366ff;
  border-color: #1a53ff;
}

.btn-az-secondary:not(:disabled):not(.disabled):active,
.btn-az-secondary:not(:disabled):not(.disabled).active,
.show > .btn-az-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0040ff;
  border-color: #0036d9;
}

.btn-az-secondary:not(:disabled):not(.disabled):active:focus,
.btn-az-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-az-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
}

.btn-rounded {
  border-radius: 50px;
}

.btn-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

.btn-with-icon i {
  line-height: 0;
  font-size: 20px;
  margin-right: 5px;
}

.btn-with-icon i.typcn {
  line-height: 0.9;
}

.btn-with-icon i.fas {
  font-size: 15px;
}

.btn-with-icon i.icon {
  font-size: 18px;
}

.btn-icon {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.btn-icon i {
  line-height: 0;
  font-size: 24px;
}

.btn-icon i.typcn {
  line-height: 0.95;
}

/* ###### 4.3 Charts  ###### */

.az-donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.az-donut-chart .slice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.az-donut-chart .chart-center {
  position: absolute;
  border-radius: 50%;
}

.az-donut-chart .chart-center span {
  display: block;
  text-align: center;
}

/* ###### 4.4 Forms   ###### */

/* Checkbox */

.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 1;
  margin-bottom: 0;
}

.ckbox span {
  padding-left: 15px;
}

.ckbox span:empty {
  float: left;
}

.ckbox span:before,
.ckbox span:after {
  line-height: 18px;
  position: absolute;
}

.ckbox span:before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #97a3b9;
  top: 1px;
  left: 0;
}

.ckbox span:after {
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  content: "";
  //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: 55% 45%;
  background-color: #3366ff;
  line-height: 17px;
  display: none;
}

.ckbox span:empty {
  padding-left: 0;
  width: 3px;
}

.ckbox input[type="checkbox"] {
  opacity: 0;
  margin: 0;
}

.ckbox input[type="checkbox"]:checked + span:after {
  display: block;
}

.ckbox input[type="checkbox"][disabled] + span,
.ckbox input[type="checkbox"][disabled] + span:before,
.ckbox input[type="checkbox"][disabled] + span:after {
  opacity: 0.75;
}

.ckbox-inline {
  display: inline-block;
}

/* Radio Box */

.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;
}

.rdiobox span {
  padding-left: 13px;
}

.rdiobox span:before,
.rdiobox span:after {
  line-height: 18px;
  position: absolute;
}

.rdiobox span:before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #97a3b9;
  border-radius: 50px;
  top: 2px;
  left: 0;
}

.rdiobox span:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50px;
  top: 7px;
  left: 5px;
  display: none;
}

.rdiobox span:empty {
  padding-left: 0;
  width: 16px;
  display: block;
}

.rdiobox input[type="radio"] {
  opacity: 0;
  margin: 0;
}

.rdiobox input[type="radio"]:checked + span:before {
  border-color: transparent;
  background-color: #3366ff;
}

.rdiobox input[type="radio"]:checked + span:after {
  display: block;
}

.rdiobox input[type="radio"][disabled] + span,
.rdiobox input[type="radio"][disabled] + span:before,
.rdiobox input[type="radio"][disabled] + span:after {
  opacity: 0.75;
}

.rdiobox-inline {
  display: inline-block;
}

.form-group-rdiobox {
  display: flex;
  align-items: center;
}

.form-group-rdiobox .rdiobox {
  margin-bottom: 0;
}

.form-group-rdiobox .rdiobox + .rdiobox {
  margin-left: 30px;
}

.form-group-rdiobox .rdiobox span {
  padding-left: 0;
}

.az-toggle {
  width: 60px;
  height: 25px;
  background-color: #b4bdce;
  padding: 2px;
  position: relative;
  overflow: hidden;
}

.az-toggle span {
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: 3px;
  display: block;
  width: 20px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-toggle span {
    transition: none;
  }
}

.az-toggle span::before,
.az-toggle span::after {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #fff;
  top: 2px;
  line-height: 1.38;
}

.az-toggle span::before {
  content: "on";
  left: -25px;
}

.az-toggle span::after {
  content: "off";
  right: -29px;
}

.az-toggle.on {
  background-color: #5b47fb;
}

.az-toggle.on span {
  left: 37px;
}

.az-toggle-secondary.on {
  background-color: #3366ff;
}

.az-toggle-success.on {
  background-color: #3bb001;
}

.az-toggle-dark.on {
  background-color: #1c273c;
}

.az-form-group {
  padding: 12px 15px;
  border: 1px solid #cdd4e0;
}

.az-form-group.focus {
  border-color: #b4bdce;
  box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
}

.az-form-group .form-label {
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: 400;
  color: #97a3b9;
}

.az-form-group .form-control {
  padding: 0;
  border-width: 0;
  height: 25px;
  color: #1c273c;
  font-weight: 500;
}

.az-form-group .form-control:focus {
  box-shadow: none;
}

/* ###### 4.5 Image   ###### */

.az-img-user {
  display: block;
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.az-img-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.az-img-user::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 3px;
  width: 6px;
  height: 6px;
  background-color: #97a3b9;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
}

.az-img-user.online::after {
  background-color: #3bb001;
}

.az-avatar {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  background-color: #596882;
}

.az-avatar::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 3px;
  width: 6px;
  height: 6px;
  background-color: #97a3b9;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
}

.az-avatar.online::after {
  background-color: #3bb001;
}

.avatar-xs {
  width: 24px;
  height: 24px;
  font-size: 11px;
}

.avatar-xs::after {
  width: 5px;
  height: 5px;
}

.avatar-sm {
  width: 32px;
  height: 32px;
  font-size: 14px;
}

.avatar-sm::after {
  width: 7px;
  height: 7px;
}

.avatar-md {
  width: 48px;
  height: 48px;
  font-size: 24px;
}

.avatar-md::after {
  width: 9px;
  height: 9px;
  right: 2px;
  bottom: 2px;
}

.avatar-lg {
  width: 64px;
  height: 64px;
  font-size: 28px;
}

.avatar-lg::after {
  width: 10px;
  height: 10px;
  bottom: 3px;
  right: 4px;
}

.avatar-xl {
  width: 72px;
  height: 72px;
  font-size: 36px;
}

.avatar-xl::after {
  width: 11px;
  height: 11px;
  bottom: 4px;
  right: 5px;
  box-shadow: 0 0 0 2.5px #fff;
}

.avatar-xxl {
  width: 100px;
  height: 100px;
  font-size: 56px;
}

.avatar-xxl::after {
  width: 13px;
  height: 13px;
  bottom: 6px;
  right: 7px;
  box-shadow: 0 0 0 3px #fff;
}

/* ###### 4.6 List   ###### */

.az-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.az-list-item + .az-list-item {
  border-top: 1px solid #e3e7ed;
}

.az-list-item:last-child {
  padding-bottom: 0;
}

.az-list-item h6 {
  margin-bottom: 2px;
  font-weight: 600;
}

.az-list-item > div:first-child h6 {
  color: #1c273c;
}

.az-list-item > div span {
  color: #7987a1;
  font-size: 12px;
}

.az-list-item > div:last-child {
  text-align: right;
}

.az-list-item > div:last-child h6 {
  line-height: 1;
}

/* ###### 4.7 Modal   ###### */

.modal.animated .modal-dialog {
  transform: translate(0, 0);
}

.modal.effect-scale .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
  transform: translateX(0);
  opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal.effect-newspaper.show ~ .modal-backdrop,
.modal.effect-newspaper .modal-dialog {
  transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

.modal.effect-fall {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
  transition: all 0.3s ease-in;
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

.modal.effect-flip-horizontal {
  perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
  transform: rotateY(0deg);
  opacity: 1;
}

.modal.effect-flip-vertical {
  perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-sign {
  perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-bottom {
  perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-left {
  perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

.modal.effect-just-me .modal-dialog {
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-just-me .modal-content {
  background-color: transparent;
}

.modal.effect-just-me .close {
  text-shadow: none;
  color: #fff;
}

.modal.effect-just-me .modal-header {
  background-color: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  padding-left: 0;
  padding-right: 0;
}

.modal.effect-just-me .modal-header h6 {
  color: #fff;
  font-weight: 500;
}

.modal.effect-just-me .modal-body {
  color: rgba(255, 255, 255, 0.8);
  padding-left: 0;
  padding-right: 0;
}

.modal.effect-just-me .modal-body h6 {
  color: #fff;
}

.modal.effect-just-me .modal-footer {
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  border-top-color: rgba(255, 255, 255, 0.1);
}

.modal.effect-just-me.show ~ .modal-backdrop {
  opacity: 0.96;
}

.modal.effect-just-me.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

/* ###### 4.8 Nav   ###### */

@media (min-width: 768px) {
  .az-nav {
    align-items: center;
  }
}

.az-nav .nav-link {
  display: block;
  color: #596882;
  padding: 0;
  position: relative;
  line-height: normal;
}

.az-nav .nav-link:hover,
.az-nav .nav-link:focus {
  color: #1c273c;
}

.az-nav .nav-link + .nav-link {
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px dotted #97a3b9;
}

@media (min-width: 768px) {
  .az-nav .nav-link + .nav-link {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px dotted #97a3b9;
  }
}

.az-nav .nav-link.active {
  color: #5b47fb;
}

.az-nav-column {
  flex-direction: column;
}

.az-nav-column .nav-link {
  padding: 0;
  height: 38px;
  color: #1c273c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.az-nav-column .nav-link i {
  font-size: 24px;
  line-height: 0;
  width: 24px;
  margin-right: 12px;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-nav-column .nav-link i {
    transition: none;
  }
}

.az-nav-column .nav-link i:not([class*=" tx-"]) {
  color: #7987a1;
}

.az-nav-column .nav-link i.typcn {
  line-height: 0.9;
}

.az-nav-column .nav-link span {
  font-weight: 400;
  font-size: 11px;
  color: #97a3b9;
  margin-left: auto;
}

.az-nav-column .nav-link:hover,
.az-nav-column .nav-link:focus {
  color: #1c273c;
}

.az-nav-column .nav-link:hover i:not([class*=" tx-"]),
.az-nav-column .nav-link:focus i:not([class*=" tx-"]) {
  color: #1c273c;
}

.az-nav-column .nav-link.active {
  position: relative;
}

.az-nav-column .nav-link.active::before {
  content: "";
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: -28px;
  width: 3px;
  background-color: #5b47fb;
  border-radius: 3px;
  display: none;
}

.az-nav-column .nav-link.active,
.az-nav-column .nav-link.active:hover,
.az-nav-column .nav-link.active:focus {
  color: #5b47fb;
}

.az-nav-column .nav-link.active i,
.az-nav-column .nav-link.active:hover i,
.az-nav-column .nav-link.active:focus i {
  color: #5b47fb;
}

.az-nav-column .nav-link + .nav-link {
  border-top: 1px dotted #b4bdce;
}

.az-nav-column.sm .nav-link {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 10px 0;
}

.az-nav-column.sm .nav-link i {
  font-size: 21px;
}

.az-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.az-nav-dark .nav-link:hover,
.az-nav-dark .nav-link:focus {
  color: #fff;
}

.az-nav-dark .nav-link + .nav-link {
  border-color: #596882;
}

.az-nav-dark .nav-link.active {
  color: #5b47fb;
}

.az-nav-colored-bg .nav-link + .nav-link {
  border-color: rgba(255, 255, 255, 0.4);
}

.az-nav-colored-bg .nav-link.active {
  color: #fff;
}

.az-nav-line {
  position: relative;
}

.az-nav-line .nav-link {
  padding: 0;
  color: #596882;
  position: relative;
}

.az-nav-line .nav-link:hover,
.az-nav-line .nav-link:focus {
  color: #1c273c;
}

.az-nav-line .nav-link + .nav-link {
  margin-top: 15px;
}

@media (min-width: 768px) {
  .az-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 30px;
  }
}

.az-nav-line .nav-link.active {
  color: #1c273c;
}

.az-nav-line .nav-link.active::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -20px;
  width: 2px;
  background-color: #1c273c;
}

@media (min-width: 768px) {
  .az-nav-line .nav-link.active::before {
    top: auto;
    bottom: -20px;
    left: 0;
    right: 0;
    height: 2px;
    width: auto;
  }
}

.az-nav-line.az-nav-dark .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.az-nav-line.az-nav-dark .nav-link:hover,
.az-nav-line.az-nav-dark .nav-link:focus {
  color: #fff;
}

.az-nav-line.az-nav-dark .nav-link.active {
  color: #fff;
}

.az-nav-line.az-nav-dark .nav-link.active::before {
  background-color: #fff;
}

.az-nav-tabs {
  padding: 15px 15px 0;
  background-color: #cdd4e0;
}

.az-nav-tabs .lSSlideOuter {
  position: relative;
  padding-left: 32px;
  padding-right: 35px;
}

.az-nav-tabs .lSSlideWrapper {
  overflow: visible;
}

.az-nav-tabs .lSAction > a {
  display: block;
  height: 40px;
  top: 16px;
  opacity: 1;
  background-color: #b4bdce;
  background-image: none;
}

.az-nav-tabs .lSAction > a:hover,
.az-nav-tabs .lSAction > a:focus {
  background-color: #a5afc4;
}

.az-nav-tabs .lSAction > a::before {
  font-family: "Ionicons";
  font-size: 18px;
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.az-nav-tabs .lSAction > a.lSPrev {
  left: -32px;
}

.az-nav-tabs .lSAction > a.lSPrev::before {
  content: "\f3cf";
}

.az-nav-tabs .lSAction > a.lSNext {
  right: -35px;
}

.az-nav-tabs .lSAction > a.lSNext::before {
  content: "\f3d1";
}

.az-nav-tabs .lSAction > a.disabled {
  background-color: #e3e7ed;
  color: #fff;
}

.az-nav-tabs .lightSlider {
  display: flex;
}

.az-nav-tabs .tab-item {
  flex-shrink: 0;
  display: block;
  float: none;
  min-width: 150px;
}

.az-nav-tabs .tab-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  line-height: 1.428;
  color: #596882;
  white-space: nowrap;
  background-color: #e3e7ed;
}

.az-nav-tabs .tab-link:hover,
.az-nav-tabs .tab-link:focus {
  background-color: #f4f5f8;
}

.az-nav-tabs .tab-link.active {
  background-color: #fff;
  color: #1c273c;
  font-weight: 500;
}

.az-tab-pane {
  display: none;
}

.az-tab-pane.active {
  display: block;
}

/* ############### CUSTOM VENDOR STYLES ############### */

/* ###### 5.1 Datatables ###### */

table.dataTable {
  border: 1px solid #cdd4e0;
}

table.dataTable.no-footer {
  border-bottom-color: #cdd4e0;
}

table.dataTable .btn,
table.dataTable .sp-container button,
.sp-container table.dataTable button {
  padding: 0.1rem 1rem;
  vertical-align: top;
  min-height: auto;
}

table.dataTable thead th,
table.dataTable thead td {
  border-top-width: 0;
  border-bottom-width: 0;
  font-weight: 700;
  font-size: 12px;
  color: #1c273c;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none;
  background-color: #eceff3;
  position: relative;
}

table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
  font-family: "Ionicons";
  font-size: 11px;
  position: absolute;
  line-height: 0;
  top: 50%;
  right: 10px;
}

table.dataTable thead .sorting_asc::after {
  content: "\f3d8";
}

table.dataTable thead .sorting_desc::after {
  content: "\f3d0";
}

table.dataTable thead .sorting {
  background-image: none;
  position: relative;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting::after {
  font-family: "Ionicons";
  font-size: 11px;
  position: absolute;
  line-height: 0;
  right: 10px;
}

table.dataTable thead .sorting::before {
  content: "\f3d8";
  top: 40%;
}

table.dataTable thead .sorting::after {
  content: "\f3d0";
  top: 60%;
}

table.dataTable tbody td.sorting_1 {
  background-color: #f3f4f7;
}

table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child::before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child::before {
  top: 9.5px;
  left: 7px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  box-shadow: none;
  background-color: #cdd4e0;
  font-size: 14px;
  font-weight: 700;
  color: #97a3b9;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: block;
}

table.dataTable > tbody > tr.child span.dtr-title {
  font-weight: 500;
}

.dataTables_wrapper .dataTables_length {
  text-align: left;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_length {
    float: left;
  }
}

.dataTables_wrapper .dataTables_length label {
  display: block;
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_length .select2-container--default {
  margin-right: 5px;
}

.dataTables_wrapper
  .dataTables_length
  .select2-container--default
  .select2-selection--single {
  height: 32px;
  border-color: #cdd4e0;
  border-radius: 3px;
}

.dataTables_wrapper
  .dataTables_length
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 32px;
}

.dataTables_wrapper
  .dataTables_length
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 30px;
}

.dataTables_wrapper
  .dataTables_length
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-top-color: #97a3b9;
}

.dataTables_wrapper .dataTables_filter {
  text-align: left;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_filter {
    float: right;
    margin-top: 0;
  }
}

.dataTables_wrapper .dataTables_filter label {
  display: block;
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0;
  border: 1px solid #cdd4e0;
  padding: 5px 10px;
  line-height: 1.539;
  color: #031b4e;
  border-radius: 3px;
  width: 100%;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_filter input {
    width: auto;
  }
}

.dataTables_wrapper .dataTables_filter input::placeholder {
  color: #97a3b9;
}

.dataTables_wrapper .dataTables_info {
  margin-top: 15px;
  padding: 5px 0;
  text-align: left;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_info {
    float: left;
  }
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0;
  margin-top: 15px;
  text-align: left;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_paginate {
    float: right;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: #e3e7ed;
  padding: 5px 10px;
  margin: 0;
  border: 0;
  border-radius: 1px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    transition: none;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button + .paginate_button {
  margin-left: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
  background-color: #eceff3;
  color: #cdd4e0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  margin-right: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  margin-left: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  border: 0;
  background-image: none;
  background-color: #cdd4e0;
  color: #031b4e !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  border: 0;
  background-image: none;
  background-color: #5b47fb;
  color: #fff !important;
}

/* ###### 5.2 Datepicker ###### */

.ui-datepicker {
  background-color: #fff;
  border: 1px solid rgba(28, 39, 60, 0.2);
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  margin: 1px 0 0;
  display: none;
  width: auto !important;
  z-index: 5 !important;
}

.ui-datepicker .ui-datepicker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: #031b4e;
  padding: 0 0 5px;
  letter-spacing: 1px;
  border: 0;
  background-color: transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  text-indent: -99999px;
  color: #7987a1;
  top: 3px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
  font-size: 16px;
  font-family: "Ionicons";
  position: absolute;
  top: -2px;
  text-indent: 0;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
  color: #3b4863;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  order: 3;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  right: 5px;
  content: "\f3d1";
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  left: 5px;
  content: "\f3cf";
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
  color: #596882;
  cursor: pointer;
  top: 1px;
  border: 0;
  background-color: transparent;
}

.ui-datepicker .ui-datepicker-title {
  color: #3b4863;
  font-weight: 700;
  font-size: 13px;
}

.ui-datepicker .ui-datepicker-calendar {
  margin: 0;
  background-color: transparent;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ui-datepicker .ui-datepicker-calendar th {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 6px 9px;
  color: #3b4863;
}

@media (max-width: 320px) {
  .ui-datepicker .ui-datepicker-calendar th {
    padding: 4px 0;
    letter-spacing: normal;
  }
}

.ui-datepicker .ui-datepicker-calendar td {
  border: 1px solid #fff;
  padding: 0;
  background-color: #fff;
  text-align: right;
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
  border-right: 0;
}

.ui-datepicker
  .ui-datepicker-calendar
  td.ui-datepicker-other-month
  .ui-state-default {
  color: #ccc;
}

.ui-datepicker .ui-datepicker-calendar td span,
.ui-datepicker .ui-datepicker-calendar td a {
  transition: all 0.2s ease-in-out;
  padding: 5px;
  color: #031b4e;
  padding: 6px 10px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  border: 0;
  border-radius: 1px;
}

@media (prefers-reduced-motion: reduce) {
  .ui-datepicker .ui-datepicker-calendar td span,
  .ui-datepicker .ui-datepicker-calendar td a {
    transition: none;
  }
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
  background-color: #fff;
  color: #031b4e;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus {
  background-color: #3366ff;
  color: #fff;
}

.ui-datepicker-multi .ui-datepicker-group {
  padding-right: 15px;
  width: auto;
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
  margin: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
  left: 10px;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
  right: 10px;
}

.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0;
}

.ui-datepicker-multi .ui-datepicker-group-last {
  padding-right: 0;
}

.ui-datepicker-inline {
  border-color: #cdd4e0;
}

@media (min-width: 576px) {
  .ui-datepicker-inline {
    max-width: 270px;
  }
}

/* ###### 5.3 Datetimepicker ###### */

/* Amaze UI Datetimepicker */

.datetimepicker {
  box-shadow: none;
  border-radius: 0;
  border-width: 1px;
  border-color: #cdd4e0;
  min-width: 270px;
  padding: 0 10px 10px;
}

.datetimepicker::before {
  display: none;
}

.datetimepicker table {
  width: 100%;
}

.datetimepicker table th.prev,
.datetimepicker table th.next,
.datetimepicker table th.switch {
  background-color: #fff;
  color: #031b4e;
}

.datetimepicker table th.prev,
.datetimepicker table th.next {
  position: relative;
}

.datetimepicker table th.prev span,
.datetimepicker table th.next span {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.datetimepicker table th.prev span::before,
.datetimepicker table th.next span::before {
  font-family: "Ionicons";
  position: absolute;
  top: 50%;
  font-size: 16px;
  color: #1c273c;
  line-height: 0;
}

.datetimepicker table th.prev:hover,
.datetimepicker table th.prev:focus,
.datetimepicker table th.next:hover,
.datetimepicker table th.next:focus {
  background-color: #fff;
}

.datetimepicker table th.prev:hover span::before,
.datetimepicker table th.prev:focus span::before,
.datetimepicker table th.next:hover span::before,
.datetimepicker table th.next:focus span::before {
  color: #3366ff;
}

.datetimepicker table th.prev span::before {
  content: "\f3cf";
  left: 0;
}

.datetimepicker table th.next span::before {
  content: "\f3d1";
  right: 0;
}

.datetimepicker table th.switch {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1px;
  color: #1c273c;
}

.datetimepicker table th.switch:hover,
.datetimepicker table th.switch:focus {
  background-color: #fff;
  color: #3366ff;
}

.datetimepicker table td {
  font-size: 0.875rem;
  text-align: center;
}

.datetimepicker table td.old,
.datetimepicker table td.new {
  color: #97a3b9;
}

.datetimepicker table td.active,
.datetimepicker table td.active:hover,
.datetimepicker table td.active:focus {
  background-color: #3366ff;
  color: #fff;
}

.datetimepicker table span.active,
.datetimepicker table span.active:hover,
.datetimepicker table span.active:focus {
  background-color: #3366ff;
  color: #fff;
}

.datetimepicker .datetimepicker-days table thead tr:first-child th {
  height: 38px;
}

.datetimepicker .datetimepicker-days table thead tr:last-child th {
  height: 25px;
  text-transform: uppercase;
  color: #596882;
  font-weight: 700;
  font-size: 11px;
}

/* jQuery Simple Datetimepicker */

.az-datetimepicker {
  box-shadow: none;
  border-radius: 0;
  border-color: #cdd4e0;
  margin-top: 2px;
}

.az-datetimepicker > .datepicker_header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.az-datetimepicker > .datepicker_header > .icon-home {
  display: none;
}

.az-datetimepicker > .datepicker_header a:nth-child(2),
.az-datetimepicker > .datepicker_header a:last-child {
  display: block;
  position: relative;
  text-indent: -999999px;
}

.az-datetimepicker > .datepicker_header a:nth-child(2)::before,
.az-datetimepicker > .datepicker_header a:last-child::before {
  font-family: "Ionicons";
  position: absolute;
  top: 50%;
  line-height: 0;
  text-indent: 0;
  font-size: 13px;
}

.az-datetimepicker > .datepicker_header a:nth-child(2)::before {
  content: "\f3cf";
  left: 0;
}

.az-datetimepicker > .datepicker_header a:last-child::before {
  content: "\f3d1";
  right: 0;
}

.az-datetimepicker > .datepicker_header span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
}

.az-datetimepicker > .datepicker_inner_container {
  border-radius: 0;
  box-shadow: none;
  border-width: 0;
  display: flex;
  height: 225px;
  margin: 0;
  background-color: #fff;
}

.az-datetimepicker > .datepicker_inner_container::after {
  display: none;
}

.az-datetimepicker > .datepicker_inner_container > .datepicker_calendar {
  width: 230px;
  border-width: 0;
  border-radius: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 5px;
  float: none;
  margin: 0;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_calendar
  > table {
  width: 100%;
  height: 100%;
}

.az-datetimepicker > .datepicker_inner_container > .datepicker_calendar th {
  font-size: 11px;
  text-transform: uppercase;
  color: #1c273c;
  font-weight: 700;
  text-align: center;
  padding: 8px 5px;
}

.az-datetimepicker > .datepicker_inner_container > .datepicker_calendar td {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  padding: 5px;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_calendar
  td.day_another_month {
  color: #97a3b9;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_calendar
  td.hover {
  background-color: #e3e7ed;
  cursor: pointer;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_calendar
  td.active {
  background-color: #3366ff;
  color: #fff;
}

.az-datetimepicker > .datepicker_inner_container > .datepicker_timelist {
  float: none;
  width: 70px;
  height: 100% !important;
  border-left: 1px solid #b4bdce;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item {
  padding: 5px 8px;
  margin: 0 0 0 1px;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item:hover,
.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item:focus {
  background-color: #e3e7ed;
}

.az-datetimepicker
  > .datepicker_inner_container
  > .datepicker_timelist
  > div.timelist_item.active {
  background-color: #3366ff;
  color: #fff;
}

/* Picker JS */

.picker-title {
  font-size: 15px;
  font-weight: 500;
}

.picker-cell__header {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.picker-item {
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.picker-picked {
  font-weight: 500;
  color: #3366ff;
}

/* ###### 5.4 jQuery Steps ###### */

/***** DEFAULT STYLE WIZARD *****/

.wizard {
  border: 1px solid #e3e7ed;
  background-color: #fff;
}

.wizard > .steps {
  padding: 20px;
}

@media (min-width: 768px) {
  .wizard > .steps {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard > .steps {
    padding: 30px;
  }
}

.wizard > .steps > ul {
  padding: 0;
  margin-bottom: 0;
  display: flex;
}

.wizard > .steps > ul li {
  float: none;
  display: block;
  width: auto;
}

.wizard > .steps > ul li .current-info {
  display: none;
}

.wizard > .steps > ul li .title {
  margin-left: 5px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps > ul li .title {
    transition: none;
  }
}

@media (min-width: 576px) {
  .wizard > .steps > ul li .title {
    display: none;
    margin-left: 10px;
  }
}

@media (min-width: 768px) {
  .wizard > .steps > ul li .title {
    display: inline-block;
  }
}

.wizard > .steps > ul li + li {
  margin-left: 5px;
}

@media (min-width: 576px) {
  .wizard > .steps > ul li + li {
    margin-left: 20px;
  }
}

@media (min-width: 992px) {
  .wizard > .steps > ul li + li {
    margin-left: 30px;
  }
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  color: #1c273c;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .wizard > .steps a,
  .wizard > .steps a:hover,
  .wizard > .steps a:active {
    justify-content: flex-start;
  }
}

.wizard > .steps a .number,
.wizard > .steps a:hover .number,
.wizard > .steps a:active .number {
  flex-shrink: 0;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #fff;
  display: block;
  text-align: center;
  line-height: 2;
  width: 30px;
  height: 30px;
  background-color: #b4bdce;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .wizard > .steps a .number,
  .wizard > .steps a:hover .number,
  .wizard > .steps a:active .number {
    transition: none;
  }
}

@media (min-width: 576px) {
  .wizard > .steps a .number,
  .wizard > .steps a:hover .number,
  .wizard > .steps a:active .number {
    font-size: 18px;
    font-weight: 600;
    line-height: 2.1;
    width: 40px;
    height: 40px;
  }
}

.wizard > .steps .disabled {
  display: none;
}

@media (min-width: 576px) {
  .wizard > .steps .disabled {
    display: block;
  }
}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  color: #97a3b9;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  color: #5b47fb;
}

.wizard > .steps .current a .title,
.wizard > .steps .current a:hover .title,
.wizard > .steps .current a:active .title {
  display: inline-block;
}

.wizard > .steps .current a .number,
.wizard > .steps .current a:hover .number,
.wizard > .steps .current a:active .number {
  background-color: #5b47fb;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  color: #00cccc;
}

.wizard > .steps .done a .title,
.wizard > .steps .done a:hover .title,
.wizard > .steps .done a:active .title {
  display: none;
}

@media (min-width: 768px) {
  .wizard > .steps .done a .title,
  .wizard > .steps .done a:hover .title,
  .wizard > .steps .done a:active .title {
    display: inline-block;
  }
}

.wizard > .steps .done a .number,
.wizard > .steps .done a:hover .number,
.wizard > .steps .done a:active .number {
  background-color: #00cccc;
}

.wizard > .content {
  border-top: 1px solid #e3e7ed;
  border-bottom: 1px solid #e3e7ed;
  min-height: 150px;
  padding: 20px;
}

@media (min-width: 768px) {
  .wizard > .content {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard > .content {
    padding: 30px;
  }
}

.wizard > .content > .title {
  font-size: 18px;
  color: #1c273c;
  font-weight: 700;
  margin-bottom: 5px;
  display: none;
}

.wizard > .content > .title.current {
  display: block;
}

.wizard > .content > .body {
  float: none;
  position: static;
  width: auto;
  height: auto;
}

.wizard > .content > .body input.parsley-error {
  border-color: #dc3545;
}

.wizard > .content > .body input.parsley-error + ul {
  list-style: none !important;
}

.wizard > .actions {
  padding: 20px;
}

@media (min-width: 768px) {
  .wizard > .actions {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard > .actions {
    padding: 30px;
  }
}

.wizard > .actions > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.wizard > .actions > ul > li:last-child a {
  background-color: #00cccc;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  display: block;
  background-color: #5b47fb;
  padding: 9px 25px;
  line-height: 1.573;
  color: #fff;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background-color: #97a3b9;
}

.wizard.vertical > .steps {
  padding: 20px;
}

@media (min-width: 576px) {
  .wizard.vertical > .steps {
    float: left;
    width: 20%;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .steps {
    width: 15%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps {
    padding: 25px;
    width: 30%;
  }
}

@media (min-width: 576px) {
  .wizard.vertical > .steps ul {
    flex-direction: column;
  }
}

.wizard.vertical > .steps ul li + li {
  margin-left: 10px;
}

@media (min-width: 576px) {
  .wizard.vertical > .steps ul li + li {
    margin-top: 10px;
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .steps ul li + li {
    margin-top: 20px;
  }
}

.wizard.vertical > .steps ul li .title {
  display: none;
}

@media (min-width: 992px) {
  .wizard.vertical > .steps ul li .title {
    display: block;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps a {
    justify-content: flex-start;
  }
}

.wizard.vertical > .steps .current a .title {
  display: inline-block;
}

@media (min-width: 576px) {
  .wizard.vertical > .steps .current a .title {
    display: none;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .steps .current a .title {
    display: inline-block;
  }
}

.wizard.vertical > .content {
  margin: 0;
  padding: 20px;
}

@media (min-width: 576px) {
  .wizard.vertical > .content {
    border-top-width: 0;
    border-bottom-width: 0;
    width: 80%;
    float: right;
    border-left: 1px solid #cdd4e0;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .content {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .content {
    width: 70%;
    padding: 25px;
  }
}

.wizard.vertical > .actions {
  padding: 20px;
}

@media (min-width: 576px) {
  .wizard.vertical > .actions {
    width: 80%;
    float: right;
    border-left: 1px solid #cdd4e0;
  }
}

@media (min-width: 768px) {
  .wizard.vertical > .actions {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical > .actions {
    width: 70%;
    padding: 25px;
  }
}

.wizard.vertical > .actions ul {
  float: none;
  margin: 0;
  padding: 0;
}

/****** EQUAL COLUMN WIDTH STEP INDICATOR *****/

.step-equal-width > .steps > ul {
  display: flex;
}

.step-equal-width > .steps > ul > li {
  flex: 1;
  width: auto;
  float: none;
}

/****** EQUAL COLUMN WIDTH STEP INDICATOR *****/

.step-equal-width > .steps > ul {
  display: flex;
}

.step-equal-width > .steps > ul > li {
  flex: 1;
  width: auto;
  float: none;
}

/***** CUSTOM STYLES *****/

.wizard-style-1 > .steps > ul a,
.wizard-style-1 > .steps > ul a:hover,
.wizard-style-1 > .steps > ul a:active {
  padding: 0;
  height: 50px;
}

.wizard-style-1 > .steps > ul a .number,
.wizard-style-1 > .steps > ul a:hover .number,
.wizard-style-1 > .steps > ul a:active .number {
  width: 50px;
  height: 100%;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  color: #7987a1;
  background-color: #cdd4e0;
  border-radius: 0;
}

.wizard-style-1 > .steps > ul a .title,
.wizard-style-1 > .steps > ul a:hover .title,
.wizard-style-1 > .steps > ul a:active .title {
  margin-right: 20px;
  margin-left: 20px;
}

.wizard-style-1 > .steps > ul .current a .number,
.wizard-style-1 > .steps > ul .current a:hover .number,
.wizard-style-1 > .steps > ul .current a:active .number {
  background-color: #452efa;
  color: #fff;
}

.wizard-style-1 > .steps > ul .done a .number,
.wizard-style-1 > .steps > ul .done a:hover .number,
.wizard-style-1 > .steps > ul .done a:active .number {
  background-color: #643ab0;
  color: #fff;
}

.wizard-style-2 > .steps > ul a,
.wizard-style-2 > .steps > ul a:hover,
.wizard-style-2 > .steps > ul a:active {
  padding: 0;
  height: 50px;
  border-radius: 50px;
}

.wizard-style-2 > .steps > ul a .number,
.wizard-style-2 > .steps > ul a:hover .number,
.wizard-style-2 > .steps > ul a:active .number {
  width: 50px;
  height: 100%;
  border: 2px solid #e3e7ed;
  font-size: 18px;
  font-weight: bold;
  color: #7987a1;
  background-color: #fff;
}

.wizard-style-2 > .steps > ul a .title,
.wizard-style-2 > .steps > ul a:hover .title,
.wizard-style-2 > .steps > ul a:active .title {
  margin-right: 20px;
}

.wizard-style-2 > .steps > ul .current a .number,
.wizard-style-2 > .steps > ul .current a:hover .number,
.wizard-style-2 > .steps > ul .current a:active .number {
  border-color: #5b47fb;
  color: #5b47fb;
}

.wizard-style-2 > .steps > ul .done a .number,
.wizard-style-2 > .steps > ul .done a:hover .number,
.wizard-style-2 > .steps > ul .done a:active .number {
  border-color: #6f42c1;
  color: #6f42c1;
}

/* ###### 5.6 Morris ###### */

.morris-hover.morris-default-style {
  border-radius: 0;
}

/* ###### 5.7 Parsley ###### */

input.parsley-error:focus,
textarea.parsley-error:focus {
  box-shadow: none !important;
}

input.parsley-success:focus,
textarea.parsley-success:focus {
  box-shadow: none !important;
}

.parsley-checkbox.parsley-error {
  display: inline-block;
  padding: 10px;
  border: 1px solid #dc3545;
}

.parsley-checkbox.parsley-success {
  display: inline-block;
  padding: 10px;
  border: 1px solid #3bb001;
}

.parsley-errors-list {
  color: #dc3545;
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin-bottom: 0;
}

.parsley-errors-list li {
  margin: 5px 0 0;
}

/***** CUSTOM STYLED ERROR MESSAGE *****/

.parsley-style-1 .parsley-input.parsley-error .form-control {
  background-color: #fef9f9;
  border-color: #dc3545;
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.parsley-style-1 .parsley-input.parsley-success .form-control {
  border-color: #3bb001;
}

.parsley-style-1 .parsley-checkbox.parsley-error {
  border: 1px solid #dc3545;
  background-color: #fef9f9;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.parsley-style-1
  .parsley-select.parsley-error
  .select2-container--default
  .select2-selection--single {
  background-color: #fef9f9;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.parsley-style-1 .parsley-errors-list.filled {
  background-color: #dc3545;
  color: #fff;
  padding: 7px 10px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.parsley-style-1 .parsley-errors-list.filled li:first-child {
  margin-top: 0;
}

/* ###### 5.5 jQVMap ###### */

.jqvmap-label {
  padding: 2px 8px;
  background-color: rgba(17, 17, 17, 0.9);
  border-radius: 2px;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  font-size: 20px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.jqvmap-zoomout {
  top: 32px;
}

/* Setup basic CSS for Label */

.jqvmap-pin {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  cursor: default;
  pointer-events: none;
}

/* Hide Whichever Labels you want */

#jqvmap1_ri_pin,
#jqvmap1_dc_pin,
#jqvmap1_de_pin,
#jqvmap1_md_pin {
  display: none;
}

/* Reposition Labels that are not quite right ( labels are centered in shape, and sometimes need tweaking ) */

#jqvmap1_ak_pin {
  margin-top: -2%;
}

#jqvmap1_ca_pin {
  margin-left: -2%;
}

#jqvmap1_ct_pin {
  margin-top: -0.25%;
  margin-left: -0.25%;
}

#jqvmap1_fl_pin {
  margin-left: 5%;
}

#jqvmap1_id_pin {
  margin-top: 3%;
  margin-left: -1%;
}

#jqvmap1_ky_pin {
  margin-left: 2%;
}

#jqvmap1_la_pin {
  margin-left: -2%;
}

#jqvmap1_mi_pin {
  margin-top: 4%;
  margin-left: 3%;
}

#jqvmap1_ma_pin {
  margin-top: -0.25%;
}

#jqvmap1_mn_pin {
  margin-top: 2%;
  margin-left: -2%;
}

#jqvmap1_nh_pin {
  margin-top: 1%;
  margin-left: -0.25%;
}

#jqvmap1_nj_pin {
  margin-top: 1%;
}

#jqvmap1_ok_pin {
  margin-left: 2%;
}

#jqvmap1_va_pin {
  margin-left: 2%;
}

#jqvmap1_wv_pin {
  margin-left: -1%;
  margin-top: 1%;
}

/* Add responsibe support to resize labels for difference screen sizes */

@media only screen and (min-width: 320px) {
  .jqvmap-pin {
    font-size: 6px;
  }
}

@media only screen and (min-width: 480px) {
  .jqvmap-pin {
    font-size: 8px;
  }
}

@media only screen and (min-width: 640px) {
  .jqvmap-pin {
    font-size: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .jqvmap-pin {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .jqvmap-pin {
    font-size: 14px;
  }
}

/* ###### 5.8 Quill ###### */

.ql-wrapper {
  display: flex;
  flex-direction: column;
}

.ql-wrapper-modal .ql-snow.ql-toolbar {
  border-width: 0;
  border-bottom-width: 1px;
}

@media (min-width: 576px) {
  .ql-wrapper-modal .ql-snow.ql-toolbar {
    padding: 15px 20px;
  }
}

.ql-wrapper-modal .ql-snow.ql-container {
  border-width: 0;
}

.ql-wrapper-modal .ql-snow .ql-editor {
  padding: 20px;
}

.ql-bubble.ql-container {
  border: 2px solid #e3e7ed;
}

.ql-bubble.ql-container:not(.ql-disabled) a::before {
  background-color: #1c273c;
  border-radius: 2px;
}

.ql-bubble .ql-tooltip {
  background-color: #1c273c;
  border-radius: 2px;
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  border-top-color: #1c273c;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom-color: #1c273c;
}

.ql-bubble .ql-toolbar {
  padding: 0 8px 8px;
}

.ql-bubble .ql-toolbar button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8896af;
  outline: none;
}

.ql-bubble .ql-toolbar button i {
  font-size: 24px;
  line-height: 0;
  display: inline-block;
}

.ql-bubble .ql-toolbar button i.la-bold,
.ql-bubble .ql-toolbar button i.la-underline {
  width: 21px;
}

.ql-bubble .ql-toolbar button .ql-fill {
  fill: #8896af;
}

.ql-bubble .ql-toolbar button .ql-stroke {
  stroke: #8896af;
}

.ql-bubble .ql-toolbar button.ql-active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.08);
}

.ql-bubble .ql-toolbar .ql-formats {
  margin: 8px 8px 0 0;
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
  margin-left: 0;
}

@media (min-width: 576px) {
  .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 0;
  }
  .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-left: 0;
  }
}

.ql-snow.ql-container {
  border-color: #e3e7ed;
  border-width: 2px;
}

.ql-snow.ql-toolbar {
  border-width: 2px;
  border-color: #e3e7ed;
  border-bottom-width: 1px;
  padding: 5px 10px 10px;
}

@media (min-width: 576px) {
  .ql-snow.ql-toolbar {
    padding: 15px;
  }
}

.ql-snow.ql-toolbar .ql-picker-label {
  border-color: #b4bdce;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  background-color: #e3e7ed;
  border-color: transparent;
  color: #1c273c;
  font-weight: 500;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
  stroke: #031b4e;
}

.ql-snow.ql-toolbar button {
  border: 1px solid transparent;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #031b4e;
  outline: none;
}

.ql-snow.ql-toolbar button svg {
  width: 20px;
  float: none;
}

.ql-snow.ql-toolbar button i {
  font-size: 24px;
  line-height: 0;
  display: inline-block;
}

.ql-snow.ql-toolbar button i.la-bold,
.ql-snow.ql-toolbar button i.la-underline {
  width: 21px;
}

.ql-snow.ql-toolbar button .ql-stroke {
  stroke-width: 2px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus {
  border-color: #b4bdce;
  color: #063598;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #7987a1;
}

.ql-snow.ql-toolbar button.ql-active {
  background-color: #e3e7ed;
  color: #3b4863;
  border-color: transparent;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: #3b4863;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
  stroke: #3b4863;
}

.ql-snow.ql-toolbar .ql-formats {
  margin-top: 5px;
  margin-right: 5px;
}

.ql-snow .ql-formats button + button {
  margin: 0 0 0 2px;
}

.ql-snow .ql-picker {
  display: block;
  border-width: 0;
  height: 32px;
  color: #596882;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 3px;
}

.ql-snow .ql-picker:hover .ql-picker-label,
.ql-snow .ql-picker:focus .ql-picker-label {
  color: #1c273c;
}

.ql-snow .ql-picker:hover .ql-picker-label .ql-stroke,
.ql-snow .ql-picker:focus .ql-picker-label .ql-stroke {
  stroke: #031b4e;
}

.ql-snow .ql-picker.ql-header {
  width: 120px;
}

.ql-snow .ql-picker-label {
  display: block;
  padding-left: 10px;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  border: 1px solid #cdd4e0;
  background-color: #fff;
  outline: none;
}

.ql-snow .ql-picker-label::before {
  line-height: 2.25;
  font-weight: 400;
}

.ql-snow .ql-stroke {
  stroke: #031b4e;
  stroke-linecap: square;
  stroke-linejoin: miter;
  stroke-width: 1;
}

.ql-snow .ql-editor {
  padding: 20px;
  color: #3b4863;
}

/* ###### 5.9 RangeSlider ###### */

.irs-min,
.irs-max {
  font-family: "Roboto", sans-serif;
  border-radius: 0;
}

.irs-from,
.irs-to,
.irs-single {
  font-family: "Roboto", sans-serif;
  background-color: #5b47fb;
  border-radius: 0;
}

.irs-bar {
  background-image: none;
  background-color: #5b47fb;
}

.irs-bar-edge {
  background-image: none;
  background-color: #5b47fb;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.irs-line-left {
  background-image: none;
  background-color: #cdd4e0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.irs-line-mid {
  background-image: none;
  background-color: #cdd4e0;
}

.irs-line-right {
  background-image: none;
  background-color: #cdd4e0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.irs-from::after,
.irs-to::after,
.irs-single::after {
  border-top-color: #5b47fb;
}

.irs-slider {
  background-image: none;
}

.irs-slider:before {
  content: "";
  width: 2px;
  height: 18px;
  position: absolute;
  background-color: #5b47fb;
  left: 50%;
  margin-left: -1px;
}

.irs-slider:hover:before,
.irs-slider:focus:before {
  background-color: #260bfa;
}

/* #################### COLOR VARIANT SKINS #################### */

.irs-primary .irs-bar,
.irs-primary .irs-bar-edge,
.irs-primary .irs-slider::before {
  background-color: #3366ff;
}

.irs-primary .irs-from,
.irs-primary .irs-to,
.irs-primary .irs-single {
  background-color: #3366ff;
}

.irs-primary .irs-from::after,
.irs-primary .irs-to::after,
.irs-primary .irs-single::after {
  border-top-color: #3366ff;
}

.irs-success .irs-bar,
.irs-success .irs-bar-edge,
.irs-success .irs-slider::before {
  background-color: #3bb001;
}

.irs-success .irs-from,
.irs-success .irs-to,
.irs-success .irs-single {
  background-color: #3bb001;
}

.irs-success .irs-from::after,
.irs-success .irs-to::after,
.irs-success .irs-single::after {
  border-top-color: #3bb001;
}

.irs-dark .irs-bar,
.irs-dark .irs-bar-edge,
.irs-dark .irs-slider::before {
  background-color: #3b4863;
}

.irs-dark .irs-from,
.irs-dark .irs-to,
.irs-dark .irs-single {
  background-color: #3b4863;
}

.irs-dark .irs-from::after,
.irs-dark .irs-to::after,
.irs-dark .irs-single::after {
  border-top-color: #3b4863;
}

/* #################### MODERN SKIN #################### */

.irs-modern .irs-line-left,
.irs-modern .irs-line-mid,
.irs-modern .irs-line-right {
  height: 6px;
  box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-bar,
.irs-modern .irs-bar-edge {
  height: 6px;
  box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-slider {
  top: 19px;
  background-color: #fff;
  border: 1px solid #5b47fb;
  border-radius: 0;
}

.irs-modern .irs-slider::before {
  width: 4px;
  height: 9px;
  background-color: #fff;
  border-left: 1px solid #5b47fb;
  border-right: 1px solid #5b47fb;
  margin-left: -2px;
  top: 3px;
}

.irs-modern.irs-primary .irs-slider {
  border-color: #3366ff;
}

.irs-modern.irs-primary .irs-slider::before {
  border-color: #3366ff;
}

.irs-modern.irs-success .irs-slider {
  border-color: #3bb001;
}

.irs-modern.irs-success .irs-slider::before {
  border-color: #3bb001;
}

.irs-modern.irs-dark .irs-slider {
  border-color: #1c273c;
}

.irs-modern.irs-dark .irs-slider::before {
  border-color: #1c273c;
}

/* #################### OUTLINE SKIN #################### */

.irs-outline .irs-line {
  border: 1px solid #5b47fb;
  height: 10px;
  border-radius: 5px;
}

.irs-outline .irs-line-left,
.irs-outline .irs-line-mid,
.irs-outline .irs-line-right {
  height: 10px;
  background-color: transparent;
}

.irs-outline .irs-bar,
.irs-outline .irs-bar-edge {
  height: 10px;
}

.irs-outline .irs-bar-edge {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.irs-outline .irs-slider {
  top: 21px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #5b47fb;
  border-radius: 100%;
}

.irs-outline .irs-slider::before {
  margin-left: -4px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.irs-outline .irs-slider:hover::before,
.irs-outline .irs-slider:focus::before {
  background-color: #452efa;
}

.irs-outline.irs-primary .irs-line,
.irs-outline.irs-primary .irs-slider {
  border-color: #3366ff;
}

.irs-outline.irs-primary .irs-line:hover::before,
.irs-outline.irs-primary .irs-line:focus::before,
.irs-outline.irs-primary .irs-slider:hover::before,
.irs-outline.irs-primary .irs-slider:focus::before {
  background-color: #1a53ff;
}

.irs-outline.irs-success .irs-line,
.irs-outline.irs-success .irs-slider {
  border-color: #3bb001;
}

.irs-outline.irs-success .irs-line:hover::before,
.irs-outline.irs-success .irs-line:focus::before,
.irs-outline.irs-success .irs-slider:hover::before,
.irs-outline.irs-success .irs-slider:focus::before {
  background-color: #339701;
}

.irs-outline.irs-dark .irs-line,
.irs-outline.irs-dark .irs-slider {
  border-color: #3b4863;
}

.irs-outline.irs-dark .irs-line:hover::before,
.irs-outline.irs-dark .irs-line:focus::before,
.irs-outline.irs-dark .irs-slider:hover::before,
.irs-outline.irs-dark .irs-slider:focus::before {
  background-color: #313c53;
}

/* ###### 5.10 Perfect Scrollbar ###### */

.ps {
  overflow: hidden;
}

.ps > .ps__rail-y {
  width: 5px;
  background-color: rgba(28, 39, 60, 0.04);
  z-index: 10;
  position: absolute;
  left: auto !important;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .ps > .ps__rail-y {
    transition: none;
  }
}

.ps > .ps__rail-y > .ps__thumb-y {
  position: absolute;
  border-radius: 0;
  width: 2px;
  left: 2px;
  background-color: #97a3b9;
}

.ps.ps--active-y:hover > .ps__rail-y,
.ps.ps--active-y:focus > .ps__rail-y {
  opacity: 1;
}

/* ###### 5.11 Select2 ###### */

.select2-results__option {
  border-radius: 0;
  margin-bottom: 1px;
  font-size: 13px;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border-color: #cdd4e0;
  border-radius: 3px;
  height: 38px;
  outline: none;
  border-radius: 0;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #596882;
  line-height: calc(1.5em + 0.75rem + 2px)-0.1rem;
  padding-left: 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #7987a1;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  width: 30px;
  height: 38px;
  line-height: calc(1.5em + 0.75rem + 2px);
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  margin-top: -3px;
}

.select2-container--default .select2-selection--multiple {
  background-color: #fff;
  border-color: #cdd4e0;
  border-radius: 0;
  min-height: 38px;
  outline: none;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 4px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  position: relative;
  margin-top: 5px;
  margin-right: 4px;
  padding: 3px 10px 3px 20px;
  border-color: transparent;
  border-radius: 0;
  background-color: #5b47fb;
  color: #fff;
  line-height: 1.45;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff;
  opacity: 0.5;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 7px;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: #cdd4e0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #cdd4e0;
  border-radius: 0;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: white;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5b47fb;
}

.select2-container--default .select2-results > .select2-results__options {
  margin: 4px;
}

.select2-container--default .select2-search--inline .select2-search__field {
  margin-top: 7px;
  line-height: 26px;
  padding-left: 7px;
  opacity: 1;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice {
  padding-left: 10px;
  background-color: #97a3b9;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--open .select2-selection--single,
.select2-container--open .select2-selection--multiple {
  background-color: #fff;
  border-color: #cdd4e0;
}

.select2-container--open .select2-dropdown--above {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  top: 0;
}

.select2-dropdown {
  border-color: #cdd4e0;
  z-index: 200;
}

.select2-search--dropdown {
  padding-bottom: 0;
}

.select2-results__option {
  padding: 6px 10px;
  font-size: 0.875rem;
}

.has-success .select2-container--default .select2-selection--single,
.parsley-select.parsley-success
  .select2-container--default
  .select2-selection--single {
  border-color: #3bb001;
}

.has-warning .select2-container--default .select2-selection--single {
  border-color: #ffc107;
}

.has-danger .select2-container--default .select2-selection--single,
.parsley-select.parsley-error
  .select2-container--default
  .select2-selection--single {
  border-color: #dc3545;
}

.select2-xs + .select2-container {
  font-size: 12px;
}

.select2-dropdown-xs .select2-results__option {
  font-size: 12px;
}

.select2-sm + .select2-container {
  font-size: 14px;
}

.select2-dropdown-sm .select2-results__option {
  font-size: 14px;
}

.select2-bd-0 + .select2-container--default .select2-selection--single {
  border-width: 0;
}

.bg-gray + .select2-container--default .select2-selection--single {
  background-color: #455473;
}

.bg-gray
  + .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #cdd4e0;
}

/* ###### 5.12 Spectrum ###### */

.sp-container {
  background-color: #fff;
  border-color: #cdd4e0;
  z-index: 200;
}

.sp-container button {
  border: 0;
  padding: 8px 15px;
  background-image: none;
  background-color: #cdd4e0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  text-shadow: none;
  text-transform: capitalize;
  border-radius: 2px;
}

.sp-container button:hover,
.sp-container button:focus {
  border: 0;
  background-image: none;
  background-color: #97a3b9;
  text-shadow: none;
}

.sp-button-container .sp-cancel {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12px;
  text-transform: capitalize;
  color: #7987a1 !important;
}

.sp-button-container .sp-cancel:hover,
.sp-button-container .sp-cancel:focus {
  color: #3b4863 !important;
  text-decoration: none;
}

.sp-picker-container {
  border-left: 0;
}

.sp-replacer {
  border-color: #cdd4e0;
  background-color: #fff;
}

.sp-replacer:hover,
.sp-replacer:focus {
  border-color: #b4bdce;
}

.sp-replacer.sp-active {
  border-color: #7987a1;
}

.sp-dd {
  text-indent: -99999px;
  position: relative;
  width: 10px;
}

.sp-dd::before {
  content: "\f280";
  font-family: "Ionicons";
  font-size: 15px;
  color: #97a3b9;
  position: absolute;
  text-indent: 0;
  left: 0;
  z-index: 10;
}

.sp-preview {
  border: 0;
}

.sp-dragger {
  background-color: transparent;
}

.sp-choose {
  background-color: #cdd4e0;
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: #3b4863;
}

/* ############### PAGE LAYOUT STYLES ############### */

/* ###### 6.1 Content  ###### */

@media (max-width: 575px) {
  .az-content-left-show {
    overflow: hidden;
  }
}

@media (max-width: 991.98px) {
  .az-content-left-show .az-content-left {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 800;
    border-right: 1px solid #cdd4e0;
    box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
    overflow-y: auto;
  }
}

@media (max-width: 575px) {
  .az-content-left-show .az-content-body {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .az-content-body-show .az-header .az-header-menu-icon {
    display: none;
  }
  .az-content-body-show .az-header .az-header-left .az-header-arrow {
    display: block;
  }
  .az-content-body-show .az-content-left {
    display: none;
  }
  .az-content-body-show .az-content-body {
    display: block;
  }
}

@media (min-width: 992px) {
  .az-content {
    padding: 30px 0 0 0;
  }
}

.az-content .container,
.az-content .container-fluid,
.az-content .container-sm,
.az-content .container-md,
.az-content .container-lg,
.az-content .container-xl {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .az-content .container {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .az-content .container {
    max-width: none;
  }
}

.az-content-app {
  padding-top: 20px;
}

@media (min-width: 992px) {
  .az-content-app {
    height: calc(100vh - 104px);
    padding-top: 30px;
  }
}

.az-content-app .container,
.az-content-app .container-fluid,
.az-content-app .container-sm,
.az-content-app .container-md,
.az-content-app .container-lg,
.az-content-app .container-xl {
  height: 100%;
  padding: 0;
}

.az-content-left {
  width: 100%;
  position: relative;
  padding: 0 20px 20px;
  flex-shrink: 0;
  // display: none;
}

@media (min-width: 576px) {
  .az-content-left {
    width: 280px;
  }
}

@media (min-width: 992px) {
  .az-content-left {
    display: block;
    padding: 0;
  }
}

.az-content-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .az-content-header {
    display: none;
  }
}

.az-content-header .az-logo {
  flex: 1;
  text-align: center;
}

.gop-logo-div {
  display: flex;
  align-items: center;
}
.gop-vesrion {
  font-size: 11px !important;
  font-weight: 700;
  cursor: pointer;
  z-index: 1050;
  margin-top: 12px;
  color: #5b47fb;
}
.content-display {
  display: flex;
  // flex-wrap: wrap;
  margin-bottom: 10px;
}

.gop-vesrion .table-bordered th,
.table-bordered td {
  white-space: pre-wrap;
}

.version-modal .modal-dialog {
  max-width: 70% !important;
  left: 15%;
  top: 20%;
}
.version-modal .react-bootstrap-table {
  overflow: auto;
}

.az-content-body {
  flex: 1;
  width: 100%;
}

.az-content-right {
  padding-left: 25px;
  margin-left: 25px;
  width: 200px;
  display: none;
}

@media (min-width: 992px) {
  .az-content-right {
    display: block;
  }
}

.az-content-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #97a3b9;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 10px 0;
}

.az-content-breadcrumb span {
  position: relative;
}

.az-content-breadcrumb span + span::before {
  content: "\f3d1";
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 300;
  display: inline;
  margin: 0 5px;
  color: #b4bdce;
  line-height: 0;
  position: relative;
  top: 1px;
}

.az-content-breadcrumb span:last-child {
  color: #596882;
}

.az-content-title {
  color: #1c273c;
  font-weight: 500;
  font-size: 32px;
  text-indent: -1px;
  line-height: 1;
  position: relative;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .az-content-title {
    margin-bottom: 40px;
  }
}

.az-content-label,
.card-table-two .card-title,
.card-dashboard-eight .card-title {
  color: #1c273c;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

.az-content-label-sm {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #97a3b9;
  letter-spacing: 0.5px;
}

.az-content-text {
  font-size: 12px;
  line-height: 1.4;
  display: block;
  color: #7987a1;
}

/* ###### 6.2 Footer  ###### */

.az-footer {
  background-color: #fff;
  border-top: 1px solid #cdd4e0;
  margin-top: auto;
  display: none;
}

@media (min-width: 992px) {
  .az-footer {
    display: block;
  }
}

.az-footer .container,
.az-footer .container-fluid,
.az-footer .container-sm,
.az-footer .container-md,
.az-footer .container-lg,
.az-footer .container-xl {
  font-size: 12px;
  padding: 10px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7987a1;
}

@media (min-width: 992px) {
  .az-footer .container {
    padding: 10px 0 0;
  }
}

.az-footer .container-fluid,
.az-footer .container-sm,
.az-footer .container-md,
.az-footer .container-lg,
.az-footer .container-xl {
  padding-left: 20px;
  padding-right: 20px;
}

.az-footer-app .container,
.az-footer-app .container-fluid,
.az-footer-app .container-sm,
.az-footer-app .container-md,
.az-footer-app .container-lg,
.az-footer-app .container-xl {
  border-top-width: 0;
}

/* ###### 6.3 Global  ###### */

body {
  font-family: "Roboto", sans-serif;
}

.az-body,
.az-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.az-body::before {
  content: "az";
  position: fixed;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 0.5;
  opacity: 0.02;
  z-index: -1;
  display: none;
}

.az-table-reference {
  margin-top: 40px;
  background-color: #fff;
}

.az-table-reference > thead > tr > th,
.az-table-reference > thead > tr > td,
.az-table-reference > tbody > tr > th,
.az-table-reference > tbody > tr > td {
  padding: 8px 10px;
  border: 1px solid #cdd4e0;
  font-size: 13px;
}

.az-table-reference > thead > tr > th,
.az-table-reference > thead > tr > td {
  background-color: #f4f5f8;
  color: #7987a1;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 0;
}

/* ###### 6.4 Header  ###### */

@media (max-width: 991.98px) {
  .az-header-menu-show {
    overflow: hidden;
  }
  .az-header-menu-show .az-header-menu {
    border-right: 1px solid rgba(28, 39, 60, 0.08);
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
    transform: translateX(0);
  }
  .az-header-menu-show .az-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }
}

.az-header {
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
  border-bottom: 1px solid rgba(28, 39, 60, 0.12);
}

@media (min-width: 992px) {
  .az-header {
    position: relative;
    z-index: 100;
    // height: 64px;
  }
}

.az-header > .container,
.az-header > .container-fluid,
.az-header > .container-sm,
.az-header > .container-md,
.az-header > .container-lg,
.az-header > .container-xl {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 992px) {
  .az-header > .container,
  .az-header > .container-fluid,
  .az-header > .container-sm,
  .az-header > .container-md,
  .az-header > .container-lg,
  .az-header > .container-xl {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 992px) {
  .az-header > .container {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .az-header > .container {
    max-width: none;
  }
}

.az-header .az-header-left .az-logo,
.az-header .az-header-left .gop-vesrion {
  display: none;
}

@media (min-width: 992px) {
  .az-header .az-header-left .az-logo,
  .az-header .az-header-left .gop-vesrion {
    display: flex;
  }
}

.az-header-left {
  display: flex;
  align-items: center;
}

.az-header-left .az-header-arrow {
  display: none;
  position: relative;
  top: -2px;
}

.az-logo {
  font-weight: 700;
  font-size: 28px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  color: #5b47fb;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  position: relative;
  align-items: flex-end;
}

.az-logo:hover,
.az-logo:focus {
  color: #5b47fb;
}

.az-logo i {
  display: block;
  line-height: 0.9;
  margin-right: 8px;
  font-size: 40px;
}

.az-logo i::before {
  width: auto;
  height: auto;
}

.az-header-menu-icon {
  margin-right: 10px;
  width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .az-header-menu-icon {
    margin-right: 25px;
  }
}

.az-header-menu-icon span {
  display: block;
  position: relative;
  width: 15px;
  height: 2px;
  background-color: #1c273c;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-header-menu-icon span {
    transition: none;
  }
}

.az-header-menu-icon span::before,
.az-header-menu-icon span::after {
  content: "";
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: #1c273c;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-header-menu-icon span::before,
  .az-header-menu-icon span::after {
    transition: none;
  }
}

.az-header-menu-icon span::before {
  top: -6px;
}

.az-header-menu-icon span::after {
  bottom: -6px;
}

.az-header-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  background-color: #fff;
  overflow-y: auto;
  z-index: 1000;
  transform: translateX(-240px);
  transition: all 0.35s;
}

@media (prefers-reduced-motion: reduce) {
  .az-header-menu {
    transition: none;
  }
}

@media (min-width: 992px) {
  .az-header-menu {
    background-color: transparent;
    border-right: 0;
    position: static;
    width: auto;
    overflow-y: visible;
    transform: none;
    z-index: auto;
  }
}

.az-header-menu > .nav {
  flex-direction: column;
  padding: 20px;
}

@media (min-width: 992px) {
  .az-header-menu > .nav {
    padding: 0;
    flex-direction: row;
    align-items: center;
  }
}

.az-header-menu .nav-item::before {
  content: "";
  position: absolute;
  top: 67px;
  margin-left: 25px;
  width: 15px;
  height: 15px;
  border-top: 2px solid rgba(28, 39, 60, 0.12);
  border-left: 2px solid rgba(28, 39, 60, 0.12);
  background-color: #fff;
  transform: rotate(45deg);
  z-index: 100;
  display: none;
}

.az-header-menu .nav-item + .nav-item {
  margin-top: 10px;
}

@media (min-width: 992px) {
  .az-header-menu .nav-item + .nav-item {
    margin-top: 0;
    margin-left: 30px;
  }
}

.az-header-menu .nav-item > .nav-link {
  display: flex;
  align-items: center;
  position: relative;
  color: #031b4e;
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-header-menu .nav-item > .nav-link {
    transition: none;
  }
}

@media (min-width: 992px) {
  .az-header-menu .nav-item > .nav-link {
    font-size: 0.875rem;
  }
}

.az-header-menu .nav-item > .nav-link:hover {
  color: #5b47fb;
}

.az-header-menu .nav-item > .nav-link:hover i {
  color: #5b47fb;
}

.az-header-menu .nav-item > .nav-link.with-sub::after {
  content: "\f3d0";
  display: inline-block;
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
  margin-top: 3px;
  opacity: 0.5;
}

@media (min-width: 992px) {
  .az-header-menu .nav-item > .nav-link.with-sub::after {
    margin-left: 5px;
  }
}

.az-header-menu .nav-item > .nav-link .typcn {
  font-size: 20px;
  line-height: 0.95;
  margin-right: 15px;
  width: 16px;
}

@media (min-width: 992px) {
  .az-header-menu .nav-item > .nav-link .typcn {
    font-size: 18px;
    margin-right: 7px;
  }
}

.az-header-menu .nav-item > .nav-link .typcn::before {
  width: auto;
}

.az-header-menu .nav-item.active > .nav-link {
  color: #5b47fb;
  position: relative;
}

.az-header-menu .nav-item.active > .nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: -20px;
  width: 2px;
  background-color: #5b47fb;
  border-radius: 3px;
}

@media (min-width: 992px) {
  .az-header-menu .nav-item.active > .nav-link::before {
    top: auto;
    bottom: -10px;
    left: 0;
    right: 0;
    width: auto;
    height: 2px;
  }
}

@media (min-width: 992px) {
  .az-header-menu .nav-item.show::before {
    display: block;
  }
}

.az-header-menu .nav-item.show .az-menu-sub {
  display: block;
}

.az-header-menu-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid rgba(28, 39, 60, 0.05);
}

@media (min-width: 992px) {
  .az-header-menu-header {
    display: none;
  }
}

.az-header-menu-header .close {
  font-weight: 300;
  font-size: 28px;
}

.az-menu-sub {
  font-size: 13px;
  margin-left: 32px;
  margin-top: 12px;
  display: none;
}

@media (min-width: 992px) {
  .az-menu-sub {
    position: absolute;
    top: 74px;
    background-color: #fff;
    padding: 15px 20px;
    border: 2px solid rgba(28, 39, 60, 0.12);
    min-width: 200px;
    margin-left: 0;
    margin-top: 0;
  }
}

.az-menu-sub .nav {
  flex-direction: column;
}

.az-menu-sub .nav-link {
  padding: 0;
  display: block;
  color: #596882;
}

.az-menu-sub .nav-link + .nav-link {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px dotted #e3e7ed;
}

.az-menu-sub .nav-link:hover,
.az-menu-sub .nav-link:focus {
  color: #5b47fb;
}

.az-menu-sub .nav-link.active {
  color: #5b47fb;
  font-weight: 500;
}

@media (min-width: 992px) {
  .az-menu-sub .nav-link.active {
    font-weight: 400;
  }
}

.az-menu-sub-mega {
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 0;
  border: 0;
}

.az-menu-sub-mega .container {
  padding: 0;
}

@media (max-width: 991.98px) {
  .az-menu-sub-mega .container {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .az-menu-sub-mega .container {
    display: flex;
    background-color: #fff;
    border: 2px solid rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .az-menu-sub-mega .container > div {
    display: flex;
    padding: 20px;
    flex: 1;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .az-menu-sub-mega .container > div {
    padding: 20px 25px;
  }
}

.az-menu-sub-mega .container > div + div {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .az-menu-sub-mega .container > div + div {
    margin-top: 0;
    border-left: 1px solid rgba(28, 39, 60, 0.05);
  }
}

.az-menu-sub-mega .nav {
  min-width: 120px;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .az-menu-sub-mega .nav {
    min-width: 140px;
  }
}

.az-menu-sub-mega .nav + .nav {
  border-top: 1px dotted #e3e7ed;
  margin-top: 6px;
  padding-top: 6px;
}

@media (min-width: 992px) {
  .az-menu-sub-mega .nav + .nav {
    margin-left: 20px;
    margin-top: 31px;
    padding-top: 0;
    border-top: 0;
  }
}

@media (min-width: 1200px) {
  .az-menu-sub-mega .nav + .nav {
    margin-left: 25px;
  }
}

.az-menu-sub-mega .nav > span {
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #1c273c;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .az-menu-sub-mega .nav > span {
    font-size: 12px;
  }
}

.az-menu-sub-mega .nav > span:first-of-type {
  margin-top: 0;
}

.az-header-center {
  flex: 1;
  margin: 0 50px;
  position: relative;
  display: none;
}

@media (min-width: 992px) {
  .az-header-center {
    display: block;
  }
}

.az-header-center .form-control {
  border-color: #e3e7ed;
  border-width: 2px;
  background-color: #fcfcfc;
  transition: none;
  height: 40px;
}

.az-header-center .form-control:focus {
  background-color: #fff;
  border-color: #b4bdce;
  box-shadow: none !important;
}

.az-header-center .form-control:focus + .btn,
.az-header-center .sp-container .form-control:focus + button,
.sp-container .az-header-center .form-control:focus + button {
  color: #7987a1;
}

.az-header-center .btn,
.az-header-center .sp-container button,
.sp-container .az-header-center button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  height: 40px;
  color: #b4bdce;
  transition: none;
  font-size: 16px;
  padding-right: 13px;
}

.az-header-center .btn:hover,
.az-header-center .sp-container button:hover,
.sp-container .az-header-center button:hover,
.az-header-center .btn:focus,
.az-header-center .sp-container button:focus,
.sp-container .az-header-center button:focus {
  color: #1c273c;
}

.az-header-right {
  display: flex;
  align-items: center;
}

.az-header-right .btn-social {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #97a3b9;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-header-right .btn-social {
    transition: none;
  }
}

.az-header-right .btn-social:hover,
.az-header-right .btn-social:focus {
  color: #596882;
}

.az-header-right .btn-social i {
  font-size: 18px;
  line-height: 0;
}

.az-header-right .btn-social + .btn-social {
  margin-left: 10px;
}

.az-header-right .btn-buy {
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 11px;
  font-weight: 500;
  background-color: #5b47fb;
  color: #fff;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}

@media (prefers-reduced-motion: reduce) {
  .az-header-right .btn-buy {
    transition: none;
  }
}

.az-header-right .btn-buy:hover,
.az-header-right .btn-buy:focus {
  background-color: #452efa;
}

.az-header-search-link {
  font-size: 18px;
  color: #596882;
  line-height: 1;
  margin-right: 15px;
}

.az-header-arrow {
  font-size: 27px;
  color: #1c273c;
  display: inline-block;
  line-height: 0;
}

.az-header-arrow i {
  line-height: 0;
}

.az-header-arrow:hover,
.az-header-arrow:focus {
  color: #596882;
}

.az-header-message,
.az-header-notification {
  position: relative;
}

.az-header-message > a,
.az-header-notification > a {
  display: block;
  font-size: 24px;
  color: #1c273c;
  position: relative;
  line-height: 0.9;
  outline: none;
}

.az-header-message > a.new::before,
.az-header-notification > a.new::before {
  content: "";
  position: absolute;
  top: -2px;
  right: 2px;
  width: 7px;
  height: 7px;
  background-color: #dc3545;
  border-radius: 100%;
}

.az-header-notification {
  margin-left: 15px;
}

.az-header-notification > a::after {
  content: "";
  position: absolute;
  top: 47px;
  left: 50%;
  margin-left: -7px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: #cdd4e0;
  border-left-color: #cdd4e0;
  transform: rotate(45deg);
  background-color: #fff;
  z-index: 901;
  display: none;
}

@media (min-width: 992px) {
  .az-header-notification > a::after {
    bottom: -36px;
  }
}

.az-header-notification .dropdown-menu {
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 20px;
  border-width: 0;
  margin-top: 0;
  z-index: 900;
  border-radius: 0;
}

@media (min-width: 576px) {
  .az-header-notification .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 20px 20px 15px;
    border-color: #cdd4e0;
    border-width: 2px;
  }
}

.az-header-notification .dropdown-footer {
  text-align: center;
  padding-top: 10px;
  font-size: 13px;
  border-top: 1px dotted #b4bdce;
}

@media (min-width: 576px) {
  .az-header-notification.show > a::after {
    display: block;
  }
}

.az-header-notification.show .dropdown-menu {
  display: block;
}

.az-notification-title {
  font-size: 18px;
  font-weight: 700;
  color: #1c273c;
  margin-bottom: 5px;
  line-height: 1;
}

.az-notification-text {
  font-size: 13px;
  margin-bottom: 15px;
}

.az-notification-list .media {
  padding: 10px 0;
  position: relative;
}

.az-notification-list .media + .media {
  border-top: 1px dotted #b4bdce;
}

.az-notification-list .media.new {
  color: #1c273c;
}

.az-notification-list .media:hover,
.az-notification-list .media:focus {
  cursor: pointer;
  z-index: 1;
}

.az-notification-list .media:hover::before,
.az-notification-list .media:focus::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -20px;
  bottom: -1px;
  right: -20px;
  background-color: #f4f5f8;
  border-top: 1px solid #cdd4e0;
  border-bottom: 1px solid #cdd4e0;
  z-index: -1;
}

.az-notification-list .media-body {
  margin-left: 15px;
  font-size: 13px;
}

.az-notification-list .media-body p {
  margin-bottom: 5px;
  line-height: 1.3;
}

.az-notification-list .media-body strong {
  font-weight: 500;
}

.az-notification-list .media-body span {
  display: block;
  font-size: 11px;
  color: #97a3b9;
}

.az-profile-menu {
  position: relative;
  margin-left: 15px;
}

.az-profile-menu > .az-img-user {
  outline: none;
  width: 32px;
  height: 32px;
}

.az-profile-menu > .az-img-user::before {
  content: "";
  position: absolute;
  bottom: -33px;
  left: 50%;
  margin-left: -7px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: #cdd4e0;
  border-left-color: #cdd4e0;
  transform: rotate(45deg);
  background-color: #fff;
  z-index: 901;
  display: none;
}

.az-profile-menu > .az-img-user::after {
  display: none;
}

.az-profile-menu .dropdown-menu {
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 20px 5px;
  border-width: 0;
  margin-top: 0;
  z-index: 900;
  border-radius: 0;
}

@media (min-width: 576px) {
  .az-profile-menu .dropdown-menu {
    padding-top: 20px;
    position: absolute;
    top: 56px;
    left: auto;
    right: -10px;
    bottom: auto;
    width: 230px;
    border-color: #cdd4e0;
    border-width: 2px;
  }
}

.az-profile-menu .dropdown-item {
  position: relative;
  padding: 0;
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #596882;
}

.az-profile-menu .dropdown-item i {
  font-size: 15px;
  margin-right: 10px;
  width: 24px;
  text-align: center;
}

.az-profile-menu .dropdown-item i.typcn {
  line-height: 0.9;
}

.az-profile-menu .dropdown-item:hover,
.az-profile-menu .dropdown-item:focus {
  background-color: transparent;
  color: #5b47fb;
}

.az-profile-menu .dropdown-item + .dropdown-item {
  border-top: 1px dotted #b4bdce;
}

.az-profile-menu .dropdown-item:last-child {
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .az-profile-menu.show .az-img-user::before {
    display: block;
  }
}

.az-profile-menu.show .dropdown-menu {
  display: block;
}

.az-header-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.az-header-profile .az-img-user {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .az-header-profile .az-img-user {
    width: 80px;
    height: 80px;
  }
}

.az-header-profile .az-img-user::after {
  display: none;
}

.az-header-profile h6 {
  font-size: 24px;
  font-weight: 700;
  color: #1c273c;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .az-header-profile h6 {
    font-size: 18px;
  }
}

.az-header-profile span {
  display: block;
  font-size: 13px;
}

.az-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* ###### 6.5 Iconbar  ###### */

@media (max-width: 991.98px) {
  .az-iconbar-show .az-iconbar {
    display: flex;
  }
  .az-iconbar-show .az-iconbar-aside {
    display: block;
  }
}

.az-iconbar {
  width: 64px;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #fff;
  z-index: 500;
}

@media (min-width: 992px) {
  .az-iconbar {
    display: flex;
  }
}

.az-iconbar .nav {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
}

.az-iconbar .nav-link {
  position: relative;
  padding: 0;
  color: #031b4e;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.az-iconbar .nav-link i {
  font-size: 28px;
}

.az-iconbar .nav-link i.typcn {
  line-height: 1;
}

.az-iconbar .nav-link i.typcn::before {
  width: auto;
}

.az-iconbar .nav-link i.typcn-device-laptop,
.az-iconbar .nav-link i.typcn-th-large-outline {
  font-size: 25px;
}

.az-iconbar .nav-link i.fa-desktop {
  font-size: 23px;
}

.az-iconbar .nav-link:hover,
.az-iconbar .nav-link:focus {
  color: #596882;
}

.az-iconbar .nav-link.active {
  color: #5b47fb;
}

.az-iconbar .nav-link + .nav-link {
  margin-top: 10px;
}

.az-iconbar-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 0.9;
  color: #1c273c;
  flex-shrink: 0;
  margin-bottom: 20px;
  position: relative;
}

.az-iconbar-logo::after {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 20px;
  height: 3px;
  width: 24px;
  background-color: #5b47fb;
  border-radius: 2px;
}

.az-iconbar-logo:hover,
.az-iconbar-logo:focus {
  color: #1c273c;
}

.az-iconbar-bottom {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.az-iconbar-help {
  line-height: 0;
  font-size: 22px;
  color: #7987a1;
  margin-bottom: 20px;
}

.az-iconbar-help:hover,
.az-iconbar-help:focus {
  color: #1c273c;
}

.az-iconbar-aside {
  width: 230px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #b4bdce;
  position: fixed;
  top: 0;
  left: 64px;
  overflow-y: auto;
  z-index: 600;
  display: none;
}

@media (min-width: 1200px) {
  .az-iconbar-aside {
    border-right: 0;
    z-index: 500;
  }
}

@media (min-width: 992px) {
  .az-iconbar-aside.show {
    display: block;
  }
}

@media (min-width: 1200px) {
  .az-iconbar-aside.show + .az-content {
    margin-left: 294px;
  }
}

.az-iconbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-left: 0;
}

.az-iconbar-header .az-logo {
  line-height: 1;
}

.az-iconbar-toggle-menu {
  display: block;
  position: relative;
  line-height: 0;
  color: #1c273c;
  font-size: 28px;
  opacity: 0.5;
}

.az-iconbar-toggle-menu:hover,
.az-iconbar-toggle-menu:focus {
  opacity: 1;
  color: #1c273c;
}

.az-iconbar-toggle-menu i:first-child {
  display: none;
}

@media (min-width: 1200px) {
  .az-iconbar-toggle-menu {
    font-size: 24px;
  }
  .az-iconbar-toggle-menu::before {
    content: "";
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: -1px;
    border-left: 2px solid #1c273c;
  }
  .az-iconbar-toggle-menu i:first-child {
    display: block;
  }
  .az-iconbar-toggle-menu i:last-child {
    display: none;
  }
}

.az-iconbar-body {
  padding: 20px;
  padding-left: 0;
  height: calc(100vh - 68px);
}

.az-iconbar-body .nav {
  flex-direction: column;
  border-left: 2px solid #e3e7ed;
  padding-top: 10px;
  padding-left: 20px;
}

.az-iconbar-body .nav-item {
  position: relative;
  display: block;
}

.az-iconbar-body .nav-item + .nav-item {
  margin-top: -1px;
  border-top: 1px dotted #cdd4e0;
}

.az-iconbar-body .nav-item.active .nav-link {
  color: #5b47fb;
}

.az-iconbar-body .nav-item.active .nav-link i {
  color: #5b47fb;
}

.az-iconbar-body .nav-item.show .nav-sub {
  display: block;
}

.az-iconbar-body .nav-link {
  position: relative;
  color: #031b4e;
  font-size: 13px;
  padding: 0;
  display: flex;
  align-items: center;
  height: 38px;
}

.az-iconbar-body .nav-link i {
  color: #97a3b9;
  font-size: 16px;
  font-weight: 400;
  line-height: 0;
  margin-right: 10px;
}

.az-iconbar-body .nav-link i.typcn {
  line-height: 0.9;
}

.az-iconbar-body .nav-link i.typcn::before {
  width: auto;
}

.az-iconbar-body .nav-link:hover,
.az-iconbar-body .nav-link:focus {
  color: #5b47fb;
}

.az-iconbar-body .nav-link:hover i,
.az-iconbar-body .nav-link:focus i {
  color: #5b47fb;
}

.az-iconbar-body .nav-link.with-sub {
  justify-content: space-between;
}

.az-iconbar-body .nav-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-left: auto;
  color: #97a3b9;
}

@media (min-width: 992px) {
  .az-iconbar-body .nav-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

.az-iconbar-body .nav-sub {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px dotted #cdd4e0;
  display: none;
}

.az-iconbar-body .nav-sub .nav-sub-item {
  position: relative;
  display: block;
  margin-left: 15px;
}

.az-iconbar-body .nav-sub .nav-sub-item + .nav-sub-item {
  border-top: 1px dotted #cdd4e0;
}

.az-iconbar-body .nav-sub .nav-sub-item.active > .nav-sub-link {
  color: #5b47fb;
}

.az-iconbar-body .nav-sub .nav-sub-link {
  display: flex;
  align-items: center;
  height: 36px;
  color: #031b4e;
}

.az-iconbar-body .nav-sub .nav-sub-link:hover,
.az-iconbar-body .nav-sub .nav-sub-link:focus {
  color: #5b47fb;
}

.az-iconbar-title {
  font-size: 20px;
  font-weight: 700;
  color: #1c273c;
  margin-bottom: 10px;
  line-height: 1;
}

.az-iconbar-text {
  display: block;
  font-size: 12px;
  color: #7987a1;
  margin-bottom: 12px;
}

.az-iconbar-pane {
  margin-bottom: 20px;
  display: none;
}

.az-iconbar-pane.show {
  display: block;
}

/* ###### 6.6 Navbar  ###### */

@media (max-width: 991.98px) {
  .az-navbar-show {
    overflow: hidden;
  }
  .az-navbar-show .az-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }
  .az-navbar-show .az-navbar {
    transform: translateX(0);
    visibility: visible;
  }
}

.az-navbar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 900;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}

@media (prefers-reduced-motion: reduce) {
  .az-navbar-backdrop {
    transition: none;
  }
}

.az-navbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 1000;
  visibility: hidden;
  transform: translateX(-240px);
  transition: all 0.4s;
}

@media (prefers-reduced-motion: reduce) {
  .az-navbar {
    transition: none;
  }
}

@media (min-width: 992px) {
  .az-navbar {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    width: auto;
    padding: 0;
    border-right: 0;
    overflow-y: inherit;
    display: block;
    border-bottom: 1px solid rgba(28, 39, 60, 0.12);
    visibility: visible;
    transform: none;
    z-index: auto;
  }
}

.az-navbar > .container,
.az-navbar > .container-fluid,
.az-navbar > .container-sm,
.az-navbar > .container-md,
.az-navbar > .container-lg,
.az-navbar > .container-xl {
  display: flex;
  flex-direction: column;
}

@media (max-width: 991.98px) {
  .az-navbar > .container,
  .az-navbar > .container-fluid,
  .az-navbar > .container-sm,
  .az-navbar > .container-md,
  .az-navbar > .container-lg,
  .az-navbar > .container-xl {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .az-navbar > .container,
  .az-navbar > .container-fluid,
  .az-navbar > .container-sm,
  .az-navbar > .container-md,
  .az-navbar > .container-lg,
  .az-navbar > .container-xl {
    flex-direction: row;
    align-items: center;
    height: 48px;
  }
}

@media (min-width: 992px) {
  .az-navbar > .container {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .az-navbar > .container-fluid,
  .az-navbar > .container-sm,
  .az-navbar > .container-md,
  .az-navbar > .container-lg,
  .az-navbar > .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.az-navbar .nav {
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .az-navbar .nav {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
  }
}

.az-navbar .nav-label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #7987a1;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .az-navbar .nav-label {
    display: none;
  }
}

.az-navbar .nav-item {
  position: relative;
}

.az-navbar .nav-item + .nav-item {
  border-top: 1px dotted #e3e7ed;
}

@media (min-width: 992px) {
  .az-navbar .nav-item + .nav-item {
    border-top: 0;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px dotted #e3e7ed;
  }
}

@media (min-width: 1200px) {
  .az-navbar .nav-item + .nav-item {
    margin-left: 20px;
    padding-left: 20px;
  }
}

.az-navbar .nav-item.active .nav-link {
  color: #5b47fb;
}

@media (min-width: 992px) {
  .az-navbar .nav-item.show .nav-link::before {
    display: block;
  }
}

.az-navbar .nav-item.show > .nav-sub {
  display: block;
}

.az-navbar .nav-item-mega {
  position: static;
}

.az-navbar .nav-link {
  padding: 0;
  color: #1c273c;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  outline: none;
}

@media (min-width: 992px) {
  .az-navbar .nav-link {
    height: auto;
    font-size: 14px;
  }
}

.az-navbar .nav-link i {
  font-size: 26px;
  margin-right: 10px;
}

@media (min-width: 992px) {
  .az-navbar .nav-link i {
    margin-right: 8px;
    font-size: 22px;
  }
}

.az-navbar .nav-link i.typcn {
  line-height: 1;
  width: 20px;
}

@media (min-width: 992px) {
  .az-navbar .nav-link i.typcn {
    line-height: 0.9;
    width: auto;
  }
}

.az-navbar .nav-link i.typcn::before {
  width: auto;
}

.az-navbar .nav-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-left: auto;
  color: #97a3b9;
}

@media (min-width: 992px) {
  .az-navbar .nav-link.with-sub::after {
    margin-left: 4px;
  }
}

.az-navbar .nav-link.with-sub::before {
  content: "";
  position: absolute;
  top: 39px;
  left: 50%;
  margin-left: -7px;
  width: 15px;
  height: 15px;
  border: 2px solid transparent;
  border-top-color: rgba(28, 39, 60, 0.12);
  border-left-color: rgba(28, 39, 60, 0.12);
  transform: rotate(45deg);
  background-color: #fff;
  z-index: 901;
  display: none;
}

.az-navbar .nav-sub {
  display: none;
  border-top: 1px dotted #e3e7ed;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub {
    position: absolute;
    top: 44.5px;
    left: 0;
    width: 200px;
    background-color: #fff;
    border: 2px solid rgba(28, 39, 60, 0.12);
    padding: 8px 20px;
    z-index: 900;
  }
}

.az-navbar .nav-sub-item {
  position: relative;
  display: block;
  margin-left: 30px;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-item {
    margin-left: 0;
  }
}

.az-navbar .nav-sub-item + .nav-sub-item {
  border-top: 1px dotted #e3e7ed;
}

.az-navbar .nav-sub-item > .nav-sub {
  left: 176px;
  top: -10px;
  display: none;
}

@media (max-width: 991.98px) {
  .az-navbar .nav-sub-item > .nav-sub > .nav-sub-item {
    margin-left: 37px;
  }
  .az-navbar .nav-sub-item > .nav-sub > .nav-sub-item > .nav-sub-link {
    padding-left: 10px;
  }
}

.az-navbar .nav-sub-item.show > .nav-sub {
  display: block;
}

.az-navbar .nav-sub-item.active > .nav-sub-link {
  color: #5b47fb;
}

.az-navbar .nav-sub-link {
  position: relative;
  font-size: 0.875rem;
  color: #596882;
  display: flex;
  align-items: center;
  height: 34px;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-link {
    font-size: 0.8125rem;
    height: 36px;
  }
}

.az-navbar .nav-sub-link:hover,
.az-navbar .nav-sub-link:focus {
  color: #5b47fb;
  outline: none;
}

.az-navbar .nav-sub-link.with-sub {
  justify-content: space-between;
}

.az-navbar .nav-sub-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-left: auto;
  color: #97a3b9;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-link.with-sub::after {
    margin-left: 4px;
    content: "\f3d1";
    font-size: 12px;
  }
}

.az-navbar .nav-sub-mega {
  top: 57px;
  left: 0;
  right: 0;
  background-color: transparent;
  padding: 7px 0;
  border: 0;
  width: auto;
  border-top: 1px dotted #e3e7ed;
  pointer-events: none;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-mega {
    padding: 0 25px;
    border-top: 0;
  }
}

.az-navbar .nav-sub-mega .container,
.az-navbar .nav-sub-mega .container-fluid,
.az-navbar .nav-sub-mega .container-sm,
.az-navbar .nav-sub-mega .container-md,
.az-navbar .nav-sub-mega .container-lg,
.az-navbar .nav-sub-mega .container-xl {
  padding: 0;
  pointer-events: auto;
}

@media (max-width: 991.98px) {
  .az-navbar .nav-sub-mega .container,
  .az-navbar .nav-sub-mega .container-fluid,
  .az-navbar .nav-sub-mega .container-sm,
  .az-navbar .nav-sub-mega .container-md,
  .az-navbar .nav-sub-mega .container-lg,
  .az-navbar .nav-sub-mega .container-xl {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-mega .container,
  .az-navbar .nav-sub-mega .container-fluid,
  .az-navbar .nav-sub-mega .container-sm,
  .az-navbar .nav-sub-mega .container-md,
  .az-navbar .nav-sub-mega .container-lg,
  .az-navbar .nav-sub-mega .container-xl {
    display: flex;
    background-color: #fff;
    border: 2px solid rgba(28, 39, 60, 0.12);
  }
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-mega .container > div,
  .az-navbar .nav-sub-mega .container-fluid > div,
  .az-navbar .nav-sub-mega .container-sm > div,
  .az-navbar .nav-sub-mega .container-md > div,
  .az-navbar .nav-sub-mega .container-lg > div,
  .az-navbar .nav-sub-mega .container-xl > div {
    display: flex;
    padding: 20px;
    flex: 1;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .az-navbar .nav-sub-mega .container > div,
  .az-navbar .nav-sub-mega .container-fluid > div,
  .az-navbar .nav-sub-mega .container-sm > div,
  .az-navbar .nav-sub-mega .container-md > div,
  .az-navbar .nav-sub-mega .container-lg > div,
  .az-navbar .nav-sub-mega .container-xl > div {
    padding: 20px 25px;
  }
}

.az-navbar .nav-sub-mega .container > div + div,
.az-navbar .nav-sub-mega .container-fluid > div + div,
.az-navbar .nav-sub-mega .container-sm > div + div,
.az-navbar .nav-sub-mega .container-md > div + div,
.az-navbar .nav-sub-mega .container-lg > div + div,
.az-navbar .nav-sub-mega .container-xl > div + div {
  margin-top: 25px;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-mega .container > div + div,
  .az-navbar .nav-sub-mega .container-fluid > div + div,
  .az-navbar .nav-sub-mega .container-sm > div + div,
  .az-navbar .nav-sub-mega .container-md > div + div,
  .az-navbar .nav-sub-mega .container-lg > div + div,
  .az-navbar .nav-sub-mega .container-xl > div + div {
    margin-top: 0;
    border-left: 1px solid rgba(28, 39, 60, 0.05);
  }
}

.az-navbar .nav-sub-mega .nav {
  min-width: 120px;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  margin: 0;
}

@media (min-width: 1200px) {
  .az-navbar .nav-sub-mega .nav {
    min-width: 140px;
  }
}

@media (max-width: 991.98px) {
  .az-navbar .nav-sub-mega .nav + .nav .nav-sub-item:first-child {
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px dotted #e3e7ed;
  }
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-mega .nav + .nav {
    margin-left: 20px;
    margin-top: 31px;
    padding-top: 0;
    border-top: 0;
  }
}

@media (min-width: 1200px) {
  .az-navbar .nav-sub-mega .nav + .nav {
    margin-left: 25px;
  }
}

.az-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #1c273c;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  margin-top: 30px;
  margin-left: 30px;
}

@media (min-width: 992px) {
  .az-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
    margin-bottom: 15px;
    margin-left: 0;
  }
}

.az-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
  margin-top: 0;
}

.az-navbar .nav-sub-mega .nav-sub-item + .nav-sub-item {
  padding-top: 7px;
  margin-top: 7px;
}

.az-navbar .nav-sub-mega .nav-sub-link {
  height: auto;
}

.az-navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #e3e7ed;
  height: 60px;
}

@media (min-width: 992px) {
  .az-navbar-header {
    display: none;
  }
}

.az-navbar-search {
  position: relative;
  padding: 0 20px;
  border-bottom: 1px solid #e3e7ed;
}

@media (min-width: 992px) {
  .az-navbar-search {
    display: none;
  }
}

.az-navbar-search .form-control {
  border-width: 0;
  padding: 0;
}

.az-navbar-search .form-control:focus {
  box-shadow: none !important;
  border-color: #cdd4e0;
}

.az-navbar-search .btn,
.az-navbar-search .sp-container button,
.sp-container .az-navbar-search button {
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 1;
}

/* ###### 6.7 Navbar Variant  ###### */

.az-navbar-two .nav-item.active {
  position: relative;
}

.az-navbar-two .nav-item.active::before {
  content: "";
  position: absolute;
}

@media (min-width: 992px) {
  .az-navbar-two .nav-item.active::before {
    top: 33px;
    left: 0;
    right: 12px;
    bottom: auto;
    border-bottom: 2px solid #5b47fb;
    z-index: 5;
  }
}

.az-navbar-two .nav-item.show .nav-link::before {
  display: none;
}

.az-navbar-two .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.az-navbar-two .nav-sub {
  border-width: 1px;
  top: 35px;
  border-top-width: 0;
}

.az-navbar-two .nav-sub-item > .nav-sub {
  left: 178px;
  top: -8px;
  border-top-width: 1px;
}

.az-navbar-two .nav-sub-mega {
  top: 48px;
}

.az-navbar-three .nav-item {
  display: block;
}

.az-navbar-three .nav-item + .nav-item {
  border-left-width: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  .az-navbar-three .nav-item + .nav-item {
    margin-left: 25px;
  }
}

.az-navbar-three .nav-item.show .nav-link::before {
  display: none;
}

.az-navbar-three .nav-item.active .nav-link {
  color: #5b47fb;
  font-weight: 700;
}

.az-navbar-three .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  color: #3b4863;
  position: relative;
}

@media (min-width: 992px) {
  .az-navbar-three .nav-link {
    display: block;
  }
}

.az-navbar-three .nav-link:hover,
.az-navbar-three .nav-link:focus {
  color: #1c273c;
}

@media (min-width: 992px) {
  .az-navbar-three .nav-link i {
    display: none;
  }
}

.az-navbar-three .nav-sub {
  top: 35px;
  border-width: 0;
  padding-bottom: 5px;
}

@media (min-width: 992px) {
  .az-navbar-three .nav-sub {
    box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
    padding-bottom: 8px;
  }
}

.az-navbar-three .nav-sub-item + .nav-sub-item {
  border-top: 0;
}

.az-navbar-three .nav-sub-item .nav-sub {
  top: -8px;
  left: 182px;
}

.az-navbar-three .nav-sub-link {
  height: 30px;
}

@media (min-width: 992px) {
  .az-navbar-three .nav-sub-link {
    height: 36px;
  }
}

/* ###### 6.8 Sidebar  ###### */

@media (max-width: 991.98px) {
  .az-sidebar-show {
    overflow: hidden;
  }
  .az-sidebar-show .az-sidebar {
    display: flex;
    overflow-y: auto;
    transform: translateX(0);
    visibility: visible;
  }
  .az-sidebar-show .az-content {
    transform: translateX(240px);
  }
  .az-sidebar-show .az-navbar-backdrop {
    visibility: visible;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .az-sidebar-hide .az-sidebar {
    display: none;
  }
  .az-sidebar-hide .az-sidebar-sticky + .az-content {
    margin-left: 0;
  }
}

.az-body-sidebar {
  display: block;
}

@media (min-width: 992px) {
  .az-body-sidebar {
    display: flex;
    flex-direction: row;
  }
}

.az-body-sidebar .az-content {
  flex: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .az-body-sidebar .az-header .container-fluid,
  .az-body-sidebar .az-header .container-sm,
  .az-body-sidebar .az-header .container-md,
  .az-body-sidebar .az-header .container-lg,
  .az-body-sidebar .az-header .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.az-body-sidebar .az-content-header {
  height: auto;
  padding: 20px;
  margin-bottom: 0;
}

.az-body-sidebar .az-content-body {
  padding: 0 20px 20px;
}

.az-body-sidebar .az-footer {
  margin-top: auto;
}

.az-sidebar {
  width: 240px;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 0 solid #cdd4e0;
  transform: translateX(-240px);
  visibility: hidden;
  transition: all 0.4s;
}

@media (prefers-reduced-motion: reduce) {
  .az-sidebar {
    transition: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .az-sidebar {
    width: 220px;
  }
}

@media (max-width: 991.98px) {
  .az-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #fff;
  }
}

@media (min-width: 992px) {
  .az-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 1050;
    border-right-width: 1px;
    visibility: visible;
  }
}

.az-sidebar-sticky {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.az-sidebar-sticky .az-sidebar-body {
  height: calc(100vh - 122px);
  position: relative;
}

@media (min-width: 992px) {
  .az-sidebar-sticky + .az-content {
    margin-left: 220px;
  }
}

@media (min-width: 1200px) {
  .az-sidebar-sticky + .az-content {
    margin-left: 240px;
  }
}

.az-sidebar-header {
  flex-shrink: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.az-sidebar-loggedin {
  padding: 10px 20px;
  display: flex;
  flex-shrink: 0;
}

.az-sidebar-loggedin .az-img-user {
  width: 36px;
  height: 36px;
}

.az-sidebar-loggedin .media-body {
  margin-left: 12px;
}

.az-sidebar-loggedin .media-body h6 {
  font-weight: 700;
  font-size: 14px;
  color: #1c273c;
  margin-bottom: 2px;
}

.az-sidebar-loggedin .media-body span {
  display: block;
  color: #7987a1;
}

.az-sidebar-body {
  padding: 20px;
}

@media (min-width: 1200px) {
  .az-sidebar-body {
    padding: 20px;
  }
}

.az-sidebar-body .nav {
  flex-direction: column;
}

.az-sidebar-body .nav-label {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #7987a1;
  padding-bottom: 5px;
  border-bottom: 1px dotted #cdd4e0;
}

.az-sidebar-body .nav-item {
  position: relative;
  display: block;
}

.az-sidebar-body .nav-item.active .nav-link {
  color: #5b47fb;
}

.az-sidebar-body .nav-item.show > .nav-sub {
  display: block;
}

.az-sidebar-body .nav-item + .nav-item {
  margin-top: -1px;
}

.az-sidebar-body .nav-item + .nav-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 30px;
  right: 0;
  border-top: 1px dotted #cdd4e0;
}

.az-sidebar-body .nav-link {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  color: #1c273c;
  padding-bottom: 2px;
}

.az-sidebar-body .nav-link i {
  font-size: 24px;
  line-height: 0;
  margin-right: 5px;
  width: 24px;
}

.az-sidebar-body .nav-link i.typcn {
  line-height: 0.9;
}

.az-sidebar-body .nav-link i.typcn::before {
  width: auto;
}

.az-sidebar-body .nav-link.with-sub {
  position: relative;
}

.az-sidebar-body .nav-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 0;
  color: #cdd4e0;
}

.az-sidebar-body .nav-sub {
  list-style: none;
  padding: 0;
  display: none;
  padding: 0 0 10px 21px;
  margin: 0 0 0 7px;
  border-left: 2px solid #e3e7ed;
}

.az-sidebar-body .nav-sub-item .nav-sub {
  padding: 0 0 0 12px;
  border-left-width: 1px;
  margin-left: 0;
}

.az-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
  height: auto;
  padding: 7px 0;
}

.az-sidebar-body .nav-sub-item.active > .nav-sub-link {
  color: #5b47fb;
}

.az-sidebar-body .nav-sub-item.show .nav-sub {
  display: block;
}

.az-sidebar-body .nav-sub-link {
  height: 34px;
  display: flex;
  align-items: center;
  color: #031b4e;
  font-size: 13px;
}

.az-sidebar-body .nav-sub-link:hover,
.az-sidebar-body .nav-sub-link:focus {
  color: #5b47fb;
}

.az-sidebar-body .nav-sub-link.with-sub {
  justify-content: space-between;
}

.az-sidebar-body .nav-sub-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-left: auto;
  color: #cdd4e0;
}

@media (min-width: 992px) {
  .az-sidebar-body .nav-sub-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

.az-sidebar-body .nav-sub-link + .nav-sub-link {
  border-top: 1px dotted #cdd4e0;
}

/* ############### TEMPLATE STYLES ############### */

/* ###### 7.1 Demo  ###### */

.az-home-slider {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 600px;
  max-height: 1800px;
  overflow: hidden;
  background-color: #1904be;
}

@media (min-width: 576px) {
  .az-home-slider {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .az-home-slider {
    height: 100vh;
    max-height: 780px;
  }
}

@media (min-width: 1440px) {
  .az-home-slider {
    max-height: 900px;
  }
}

@media (min-width: 1680px) {
  .az-home-slider {
    max-height: 1050px;
  }
}

@media (min-width: 1920px) {
  .az-home-slider {
    max-height: 1200px;
  }
}

@media (min-width: 2560px) {
  .az-home-slider {
    max-height: 1600px;
  }
}

@media (min-width: 2880px) {
  .az-home-slider {
    max-height: 1800px;
  }
}

.az-home-slider .chart-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 3;
  display: flex;
  align-items: flex-end;
  opacity: 0.2;
}

@media (min-width: 768px) {
  .az-home-slider .chart-wrapper {
    opacity: 1;
  }
}

.az-home-slider .chart-wrapper .flot-chart {
  width: 100%;
  height: 400px;
}

@media (min-width: 992px) {
  .az-home-slider .chart-wrapper .flot-chart {
    height: 500px;
  }
}

.az-home-slider .card-columns {
  column-gap: 5px;
  column-count: 3;
  width: 500px;
  transform: rotate(37deg);
  position: absolute;
  top: 9%;
  left: 24%;
  z-index: 4;
  opacity: 0.25;
}

@media (min-width: 576px) {
  .az-home-slider .card-columns {
    width: 120%;
    left: 16%;
    transform: rotate(44deg);
  }
}

@media (min-width: 768px) {
  .az-home-slider .card-columns {
    transform: rotate(45deg);
    width: 100%;
    top: 0;
    left: auto;
    right: -45%;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .az-home-slider .card-columns {
    width: 80%;
    top: -10%;
    right: -30%;
    column-gap: 10px;
  }
}

@media (min-width: 1200px) {
  .az-home-slider .card-columns {
    width: 70%;
    right: -15%;
    top: -15%;
  }
}

@media (min-width: 1300px) {
  .az-home-slider .card-columns {
    right: -5%;
  }
}

.az-home-slider .card-columns .card {
  position: relative;
  border-width: 0;
  background-color: transparent;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .az-home-slider .card-columns .card {
    margin-bottom: 10px;
  }
}

.az-home-content {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 5;
  color: rgba(255, 255, 255, 0.7);
  right: 20px;
}

@media (min-width: 576px) {
  .az-home-content {
    top: 40px;
    left: 40px;
    right: auto;
    width: 400px;
  }
}

@media (min-width: 768px) {
  .az-home-content {
    width: 410px;
  }
}

.az-home-content .az-logo {
  font-size: 30px;
  font-weight: 700;
  display: flex;
  line-height: 1;
  color: #fff;
  margin-bottom: 5px;
  text-indent: -1px;
  color: #fff;
  letter-spacing: -0.5px;
}

@media (min-width: 576px) {
  .az-home-content .az-logo {
    font-size: 36px;
    letter-spacing: -1px;
  }
}

.az-home-content h5 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .az-home-content h5 {
    font-size: 15px;
  }
}

.az-home-content .logo-group {
  margin-bottom: 60px;
  display: flex;
}

.az-home-content .logo-group i {
  font-weight: 400;
  font-size: 32px;
  line-height: 0.2;
}

.az-home-content .logo-group i + i {
  margin-left: 10px;
}

.az-home-content h1 {
  color: #fff;
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .az-home-content h1 {
    font-size: 2.03125rem;
  }
}

.az-home-content p:first-of-type {
  font-size: 14px;
  line-height: 1.7;
  margin-bottom: 25px;
}

.az-home-content p:last-of-type {
  display: flex;
  margin-bottom: 100px;
}

.az-home-content p:last-of-type .btn,
.az-home-content p:last-of-type .sp-container button,
.sp-container .az-home-content p:last-of-type button {
  flex: 1;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  min-height: inherit;
  letter-spacing: 1px;
  padding: 10px 15px;
}

@media (min-width: 768px) {
  .az-home-content p:last-of-type .btn,
  .az-home-content p:last-of-type .sp-container button,
  .sp-container .az-home-content p:last-of-type button {
    padding: 10px 25px;
    flex: none;
  }
}

@media (min-width: 992px) {
  .az-home-content p:last-of-type .btn,
  .az-home-content p:last-of-type .sp-container button,
  .sp-container .az-home-content p:last-of-type button {
    flex: 1;
  }
}

.az-home-content p:last-of-type .btn + .btn,
.az-home-content p:last-of-type .sp-container button + .btn,
.sp-container .az-home-content p:last-of-type button + .btn,
.az-home-content p:last-of-type .sp-container .btn + button,
.sp-container .az-home-content p:last-of-type .btn + button,
.az-home-content p:last-of-type .sp-container button + button,
.sp-container .az-home-content p:last-of-type button + button {
  margin-left: 5px;
}

.az-home-content nav:last-child {
  display: flex;
}

.az-home-content nav:last-child a {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
}

.az-home-content nav:last-child a:hover,
.az-home-content nav:last-child a:focus {
  color: #fff;
}

.az-home-content nav:last-child a + a {
  margin-left: 8px;
}

.az-content-choose-demo {
  position: relative;
  z-index: 5;
  background-color: #0f0373;
  color: rgba(255, 255, 255, 0.5);
  padding: 0;
}

.az-content-choose-demo .container {
  display: block;
  padding: 20px;
}

@media (min-width: 576px) {
  .az-content-choose-demo .container {
    padding: 40px;
  }
}

@media (min-width: 992px) {
  .az-content-choose-demo .container {
    padding: 100px 40px;
  }
}

@media (min-width: 1200px) {
  .az-content-choose-demo .container {
    padding: 100px 0;
  }
}

.az-content-choose-demo .title-label {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  color: #5b47fb;
  margin-bottom: 3px;
}

@media (min-width: 768px) {
  .az-content-choose-demo .title-label {
    font-size: 11px;
  }
}

.az-content-choose-demo .title {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.5px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .az-content-choose-demo .title {
    font-size: 30px;
  }
}

.az-content-choose-demo .title-text {
  font-weight: 300;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .az-content-choose-demo .title-text {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .az-content-choose-demo .title-text {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .az-content-choose-demo .title-text {
    margin-bottom: 60px;
  }
}

.az-content-choose-demo .card {
  border-width: 0;
  margin-bottom: 10px;
  height: 200px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 576px) {
  .az-content-choose-demo .card {
    height: 155px;
  }
}

@media (min-width: 768px) {
  .az-content-choose-demo .card {
    height: 215px;
    margin-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .az-content-choose-demo .card {
    height: 255px;
  }
}

.az-content-choose-demo .card img {
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-content-choose-demo .card img {
    transition: none;
  }
}

.az-content-choose-demo .card figure {
  margin-bottom: 0;
}

.az-content-choose-demo .card figure:hover img,
.az-content-choose-demo .card figure:focus img {
  transform: scale(1.75, 1.75);
}

.az-content-choose-demo .card figure:hover figcaption,
.az-content-choose-demo .card figure:focus figcaption {
  opacity: 1;
}

.az-content-choose-demo .card figcaption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(25, 4, 190, 0.75);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-content-choose-demo .card figcaption {
    transition: none;
  }
}

.az-content-choose-demo .card figcaption .btn,
.az-content-choose-demo .card figcaption .sp-container button,
.sp-container .az-content-choose-demo .card figcaption button {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 11px;
  font-weight: 500;
  min-height: inherit;
  padding: 10px 20px;
  border-radius: 2px;
}

.az-content-choose-demo .card.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c273c;
  background-color: #e3e7ed;
}

.az-content-choose-demo .card.coming-soon .typcn {
  line-height: 0.9;
  font-size: 48px;
  margin-bottom: 10px;
}

.az-content-choose-demo .card.coming-soon h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 1px;
}

.az-content-choose-demo .az-content-label,
.az-content-choose-demo .card-table-two .card-title,
.card-table-two .az-content-choose-demo .card-title,
.az-content-choose-demo .card-dashboard-eight .card-title,
.card-dashboard-eight .az-content-choose-demo .card-title {
  color: #7987a1;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.az-content-choose-demo .az-content-title {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  .az-content-choose-demo .az-content-title {
    font-size: 17px;
  }
}

.az-footer-demo {
  background-color: #0c025b;
  color: rgba(255, 255, 255, 0.4);
  height: 60px;
}

.az-footer-demo .container {
  border-top-width: 0;
}

.az-footer-demo a {
  color: rgba(255, 255, 255, 0.5);
}

.az-footer-demo a:hover,
.az-footer-demo a:focus {
  color: #fff;
}

.btn-icon-list {
  display: flex;
  align-items: center;
}

.btn-icon-list .btn + .btn,
.btn-icon-list .sp-container button + .btn,
.sp-container .btn-icon-list button + .btn,
.btn-icon-list .sp-container .btn + button,
.sp-container .btn-icon-list .btn + button,
.btn-icon-list .sp-container button + button,
.sp-container .btn-icon-list button + button {
  margin-left: 5px;
}

.az-icon-group {
  text-align: center;
  padding: 20px;
  color: #3b4863;
}

.az-icon-group i {
  line-height: 1;
  font-size: 24px;
}

@media (min-width: 992px) {
  .az-icon-group i {
    font-size: 32px;
  }
}

.modal-content-demo .modal-body h6 {
  color: #1c273c;
  font-size: 15px;
  margin-bottom: 15px;
}

.modal-content-demo .modal-body p {
  margin-bottom: 0;
}

.tooltip-static-demo {
  text-align: center;
  background-color: #e3e7ed;
  padding: 20px;
}

.tooltip-static-demo .tooltip {
  z-index: 0;
  opacity: 1;
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.tooltip-static-demo .bs-tooltip-top .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="top"] .arrow,
.tooltip-static-demo .bs-tooltip-bottom .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  left: 50%;
  margin-left: -2px;
}

.tooltip-static-demo .bs-tooltip-left,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="left"],
.tooltip-static-demo .bs-tooltip-right,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="right"] {
  margin-top: 5px;
}

.tooltip-static-demo .bs-tooltip-left .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="left"] .arrow,
.tooltip-static-demo .bs-tooltip-right .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="right"] .arrow {
  top: 50%;
  margin-top: -3px;
}

.popover-static-demo {
  background-color: #e3e7ed;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

.popover-static-demo .popover {
  z-index: 0;
  opacity: 1;
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.popover-static-demo .bs-popover-top .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="top"] .arrow,
.popover-static-demo .bs-popover-bottom .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="bottom"] .arrow {
  left: 50%;
  margin-left: -5px;
}

.popover-static-demo .bs-popover-left,
.popover-static-demo .bs-popover-auto[x-placement^="left"],
.popover-static-demo .bs-popover-right,
.popover-static-demo .bs-popover-auto[x-placement^="right"] {
  margin-top: 5px;
}

.popover-static-demo .bs-popover-left .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="left"] .arrow,
.popover-static-demo .bs-popover-right .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="right"] .arrow {
  top: 50%;
  margin-top: -5px;
}

.az-toggle-group-demo {
  display: flex;
}

.az-toggle-group-demo .az-toggle + .az-toggle {
  margin-left: 10px;
}

.az-dropdown-form-demo .static-dropdown {
  padding: 20px;
  background-color: #e3e7ed;
  display: inline-flex;
  justify-content: center;
}

@media (min-width: 576px) {
  .az-dropdown-form-demo .static-dropdown {
    padding: 40px;
  }
}

.az-dropdown-form-demo .static-dropdown .dropdown-menu {
  display: block;
  position: static;
  float: none;
}

.az-dropdown-form-demo .dropdown-menu {
  padding: 20px;
}

@media (min-width: 576px) {
  .az-dropdown-form-demo .dropdown-menu {
    width: 300px;
    padding: 30px 25px;
  }
}

.az-dropdown-form-demo .dropdown-title {
  font-size: 20px;
  font-weight: 700;
  color: #1c273c;
  margin-bottom: 5px;
}

.ql-wrapper-demo {
  background-color: #fff;
}

@media (min-width: 1200px) {
  .ql-wrapper-demo {
    width: 70%;
  }
}

.ql-wrapper-demo .ql-container {
  height: 250px;
}

.ql-wrapper-demo .ql-editor {
  height: 100%;
  overflow-y: auto;
}

.ql-scrolling-demo {
  background-color: #fff;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  border: 2px solid #e3e7ed;
  position: relative;
}

@media (min-width: 768px) {
  .ql-scrolling-demo {
    padding-left: 15px;
  }
}

.ql-scrolling-demo .ql-container {
  border-width: 0;
  height: auto;
  min-height: 100%;
  padding: 30px;
}

@media (min-width: 768px) {
  .ql-scrolling-demo .ql-container {
    padding: 50px;
    padding-left: 35px;
  }
}

.ql-scrolling-demo .ql-container .ql-editor {
  font-size: 16px;
  overflow-y: visible;
  color: #3b4863;
}

.morris-wrapper-demo {
  height: 200px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 576px) {
  .morris-wrapper-demo {
    height: 300px;
  }
}

.morris-donut-wrapper-demo {
  height: 200px;
}

@media (min-width: 576px) {
  .morris-donut-wrapper-demo {
    height: 250px;
  }
}

.chartjs-wrapper-demo {
  height: 250px;
}

@media (max-width: 330px) {
  .chartjs-wrapper-demo {
    width: 290px;
  }
}

@media (min-width: 992px) {
  .chartjs-wrapper-demo {
    height: 300px;
  }
}

.chartist-wrapper-demo {
  height: 200px;
}

@media (min-width: 768px) {
  .chartist-wrapper-demo {
    height: 300px;
  }
}

.az-content-left-components {
  border-right: 1px solid #e3e7ed;
  width: 200px;
}

.az-content-left-components .component-item label {
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.az-content-left-components .component-item label ~ label {
  margin-top: 30px;
}

.az-content-left-components .component-item .nav-link {
  padding: 0;
  display: block;
  font-size: 13px;
  color: #596882;
}

.az-content-left-components .component-item .nav-link + .nav-link {
  margin-top: 5px;
}

.az-content-left-components .component-item .nav-link:hover {
  color: #5b47fb;
}

.az-content-left-components .component-item .nav-link.active {
  color: #5b47fb;
  font-weight: 500;
}

.az-breadcrumbs {
  padding: 0;
  background-color: transparent;
  margin-bottom: 0;
}

.az-breadcrumbs .breadcrumb-item {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.az-breadcrumbs .breadcrumb-item a {
  color: #031b4e;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-breadcrumbs .breadcrumb-item a {
    transition: none;
  }
}

.az-breadcrumbs .breadcrumb-item a:hover {
  color: #97a3b9;
}

.az-breadcrumbs .breadcrumb-item.active {
  color: #3366ff;
}

.az-breadcrumbs .breadcrumb-item + .breadcrumb-item {
  padding-left: 8px;
}

.az-breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
  color: #97a3b9;
  padding-right: 8px;
  font-weight: 400;
}

.az-page-header {
  position: relative;
  padding: 30px 0 40px;
  border-bottom: 1px solid #dee2ef;
  margin-bottom: 40px;
}

.az-page-title {
  font-weight: 700;
  font-size: 40px;
  font-family: "Raleway", sans-serif;
  margin-bottom: 15px;
  line-height: 1;
  letter-spacing: -1.5px;
}

.az-page-text {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 30px;
}

.az-page-content-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.az-page-content-list li {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
}

.az-page-content-list li + li {
  margin-top: 5px;
}

.az-page-content-list li i {
  margin-right: 10px;
  font-size: 10px;
  line-height: 0;
  color: #97a3b9;
  text-indent: 1px;
}

.az-page-label {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: -0.2px;
}

.az-syntax-wrapper {
  position: relative;
}

.az-syntax-wrapper .badge {
  padding: 4px 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9px;
  letter-spacing: normal;
  border-radius: 1px;
}

.az-syntax-wrapper pre {
  border-color: #cdd4e0;
  background-color: #fff;
  padding: 15px;
  font-size: 13px;
}

.az-syntax-wrapper code[class*="language-"],
.az-syntax-wrapper pre[class*="language-"] {
  font-family: "Roboto Mono", monospace;
}

.demo-avatar-group {
  display: flex;
  align-items: center;
}

.demo-avatar-group .az-img-user,
.demo-avatar-group .az-avatar {
  flex-shrink: 0;
}

.demo-avatar-group .az-img-user + .az-img-user,
.demo-avatar-group .az-img-user + .az-avatar,
.demo-avatar-group .az-avatar + .az-img-user,
.demo-avatar-group .az-avatar + .az-avatar {
  margin-left: 5px;
}

@media (min-width: 576px) {
  .demo-avatar-group .az-img-user + .az-img-user,
  .demo-avatar-group .az-img-user + .az-avatar,
  .demo-avatar-group .az-avatar + .az-img-user,
  .demo-avatar-group .az-avatar + .az-avatar {
    margin-left: 10px;
  }
}

.demo-static-toast .toast {
  opacity: 1;
}

/* ###### 7.2 Calendar  ###### */

@media (min-width: 992px) {
  .az-content-calendar {
    padding: 25px 0;
  }
}

.az-content-calendar .container,
.az-content-calendar .container-fluid,
.az-content-calendar .container-sm,
.az-content-calendar .container-md,
.az-content-calendar .container-lg,
.az-content-calendar .container-xl {
  padding: 20px 0 0;
}

@media (min-width: 992px) {
  .az-content-calendar .container,
  .az-content-calendar .container-fluid,
  .az-content-calendar .container-sm,
  .az-content-calendar .container-md,
  .az-content-calendar .container-lg,
  .az-content-calendar .container-xl {
    padding-top: 0;
  }
}

@media (min-width: 576px) {
  .az-content-left-calendar {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .az-content-left-calendar {
    width: 230px;
  }
}

.az-datepicker .ui-datepicker-inline {
  margin-top: 0;
}

.az-datepicker .ui-datepicker .ui-datepicker-title {
  color: #5b47fb;
  letter-spacing: -0.2px;
}

.az-datepicker .ui-datepicker .ui-datepicker-calendar {
  width: 100%;
}

.az-datepicker .ui-datepicker .ui-datepicker-calendar th {
  padding: 5px;
  font-size: 10px;
  color: #1c273c;
}

.az-datepicker .ui-datepicker .ui-datepicker-calendar th,
.az-datepicker .ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
}

.az-datepicker
  .ui-datepicker
  .ui-datepicker-calendar
  th.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month)
  a,
.az-datepicker
  .ui-datepicker
  .ui-datepicker-calendar
  td.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month)
  a {
  color: #dc3545;
}

.az-datepicker .ui-datepicker .ui-datepicker-calendar td {
  padding: 0 1px;
}

.az-datepicker .ui-datepicker .ui-datepicker-calendar td span,
.az-datepicker .ui-datepicker .ui-datepicker-calendar td a {
  padding: 4px 5px;
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: #596882;
}

.az-datepicker .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
  background-color: #5b47fb;
  color: #fff;
  border-radius: 2px;
}

.az-nav-calendar-event .nav-link {
  font-size: 13px;
  position: relative;
}

.az-nav-calendar-event .nav-link i {
  font-size: 21px;
  position: relative;
  margin-right: 10px;
  top: -0.5px;
}

.az-nav-calendar-event .nav-link.exclude {
  color: #7987a1;
}

.az-nav-calendar-event .nav-link.exclude i {
  color: #7987a1;
}

.az-nav-calendar-event .nav-link.exclude::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px solid #7987a1;
}

@media (min-width: 992px) {
  .az-content-body-calendar {
    padding-left: 25px;
  }
}

.az-calendar {
  border-color: #cdd4e0;
}

.az-calendar .fc-content,
.az-calendar .fc-divider,
.az-calendar .fc-list-heading td,
.az-calendar .fc-list-view,
.az-calendar .fc-popover,
.az-calendar .fc-row,
.az-calendar tbody,
.az-calendar td,
.az-calendar th,
.az-calendar thead {
  border-color: #cdd4e0;
}

.az-calendar .fc-header-toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px;
}

@media (min-width: 992px) {
  .az-calendar .fc-header-toolbar {
    padding: 0;
    margin-bottom: 24px;
  }
}

.az-calendar .fc-header-toolbar h2 {
  margin-bottom: 0;
  color: #1c273c;
  font-size: 20px;
  font-weight: 700;
}

@media (min-width: 576px) {
  .az-calendar .fc-header-toolbar h2 {
    font-size: 22px;
  }
}

.az-calendar .fc-header-toolbar .fc-right {
  order: 3;
}

.az-calendar .fc-header-toolbar .fc-clear {
  display: none;
}

@media (max-width: 767.98px) {
  .az-calendar .fc-header-toolbar .fc-center {
    order: 1;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .az-calendar .fc-header-toolbar .fc-left {
    order: 2;
    flex: 0 0 50%;
  }
  .az-calendar .fc-header-toolbar .fc-right {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
  }
}

.az-calendar .fc-header-toolbar button {
  height: 38px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cdd4e0;
  box-shadow: none;
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: #1c273c;
  font-size: 13px;
  text-transform: capitalize;
  outline: none;
  text-shadow: none;
  border-radius: 0;
}

.az-calendar .fc-header-toolbar button:hover,
.az-calendar .fc-header-toolbar button:focus {
  background-color: #e3e7ed;
}

.az-calendar .fc-header-toolbar button.fc-prev-button,
.az-calendar .fc-header-toolbar button.fc-next-button {
  padding: 0;
  width: 38px;
  justify-content: center;
}

.az-calendar .fc-header-toolbar button.fc-prev-button span,
.az-calendar .fc-header-toolbar button.fc-next-button span {
  margin: 0;
}

.az-calendar .fc-header-toolbar button.fc-state-active {
  background-color: #e3e7ed;
}

.az-calendar .fc-header-toolbar button.fc-today-button {
  color: #fff;
  background-color: #3366ff;
  border-color: #1a53ff;
}

.az-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
  border-color: #cdd4e0;
  background-color: #f4f5f8;
  color: #97a3b9;
  cursor: default;
}

@media (max-width: 575px) {
  .az-calendar .fc-header-toolbar button.fc-prev-button,
  .az-calendar .fc-header-toolbar button.fc-next-button {
    width: 32px;
    height: 32px;
  }
  .az-calendar .fc-header-toolbar button.fc-today-button {
    height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11px;
  }
  .az-calendar .fc-header-toolbar button.fc-month-button,
  .az-calendar .fc-header-toolbar button.fc-agendaWeek-button,
  .az-calendar .fc-header-toolbar button.fc-agendaDay-button,
  .az-calendar .fc-header-toolbar button.fc-listWeek-button,
  .az-calendar .fc-header-toolbar button.fc-listMonth-button {
    text-indent: -9999px;
    width: 32px;
    height: 32px;
    position: relative;
    color: transparent;
  }
  .az-calendar .fc-header-toolbar button.fc-month-button::before,
  .az-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
  .az-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
  .az-calendar .fc-header-toolbar button.fc-listWeek-button::before,
  .az-calendar .fc-header-toolbar button.fc-listMonth-button::before {
    position: absolute;
    top: 6px;
    left: 10px;
    z-index: 100;
    display: inline-block;
    text-indent: 0;
    font-size: 12px;
    font-weight: 700;
    color: #031b4e;
  }
  .az-calendar .fc-header-toolbar button.fc-month-button::before {
    content: "M";
  }
  .az-calendar .fc-header-toolbar button.fc-agendaWeek-button::before {
    content: "W";
  }
  .az-calendar .fc-header-toolbar button.fc-agendaDay-button::before {
    content: "D";
  }
  .az-calendar .fc-header-toolbar button.fc-listWeek-button::before,
  .az-calendar .fc-header-toolbar button.fc-listMonth-button::before {
    content: "L";
    left: 11px;
  }
}

.az-calendar .fc-icon-left-single-arrow,
.az-calendar .fc-icon-right-single-arrow {
  top: 0;
}

.az-calendar .fc-icon-left-single-arrow::after,
.az-calendar .fc-icon-right-single-arrow::after {
  font-family: "Ionicons";
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

@media (min-width: 992px) {
  .az-calendar .fc-icon-left-single-arrow::after,
  .az-calendar .fc-icon-right-single-arrow::after {
    line-height: 0.75;
  }
}

.az-calendar .fc-icon-left-single-arrow::after {
  content: "\f3cf";
}

.az-calendar .fc-icon-right-single-arrow::after {
  content: "\f3d1";
}

.az-calendar .fc-head-container .fc-day-header {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 0;
  color: #1c273c;
}

@media (min-width: 576px) {
  .az-calendar .fc-head-container .fc-day-header {
    font-size: 12px;
  }
}

.az-calendar .fc-view > table {
  background-color: #fff;
}

.az-calendar .fc-view > table > thead th,
.az-calendar .fc-view > table > thead td {
  border-color: #cdd4e0;
}

@media (max-width: 991.98px) {
  .az-calendar .fc-view > table > thead th:first-child,
  .az-calendar .fc-view > table > thead td:first-child {
    border-left-width: 0;
  }
  .az-calendar .fc-view > table > thead th:last-child,
  .az-calendar .fc-view > table > thead td:last-child {
    border-right-width: 0;
  }
}

.az-calendar .fc-view > table > tbody > tr > td {
  border-color: #cdd4e0;
}

@media (max-width: 991.98px) {
  .az-calendar .fc-view > table > tbody > tr > td:first-child {
    border-left-width: 0;
  }
  .az-calendar .fc-view > table > tbody > tr > td:last-child {
    border-right-width: 0;
  }
}

.az-calendar .fc-view .fc-other-month {
  background-color: #fcfcfc;
}

.az-calendar .fc-view .fc-day-number {
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  color: #596882;
  display: inline-block;
  padding: 5px 8px;
  position: relative;
  transition: all 0.2s ease-in-out;
  margin: 2px 2px 0 0;
  min-width: 20px;
  text-align: center;
}

@media (prefers-reduced-motion: reduce) {
  .az-calendar .fc-view .fc-day-number {
    transition: none;
  }
}

.az-calendar .fc-view .fc-day-number:hover,
.az-calendar .fc-view .fc-day-number:focus {
  color: #1c273c;
  background-color: #f4f5f8;
}

.az-calendar .fc-view .fc-scroller {
  height: auto !important;
  overflow: visible !important;
}

.az-calendar .fc-view.fc-agenda-view .fc-day-header > a > span {
  display: block;
}

.az-calendar .fc-view.fc-agenda-view .fc-day-header > a > span:first-child {
  color: #7987a1;
  font-size: 8px;
  font-weight: 400;
  line-height: 1.2;
}

@media (min-width: 576px) {
  .az-calendar .fc-view.fc-agenda-view .fc-day-header > a > span:first-child {
    font-size: 11px;
    font-weight: 600;
  }
}

.az-calendar .fc-view.fc-agenda-view .fc-day-header > a > span:last-child {
  font-weight: 700;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1;
}

@media (min-width: 576px) {
  .az-calendar .fc-view.fc-agenda-view .fc-day-header > a > span:last-child {
    font-size: 25px;
  }
}

.az-calendar .fc-view.fc-agenda-view .fc-day-header.fc-today > a {
  color: #5b47fb;
}

.az-calendar .fc-view.fc-listMonth-view,
.az-calendar .fc-view.fc-listWeek-view {
  border-width: 0;
}

.az-calendar .fc-view.fc-listMonth-view .fc-scroller,
.az-calendar .fc-view.fc-listWeek-view .fc-scroller {
  padding: 0 20px 20px;
}

@media (min-width: 992px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-scroller,
  .az-calendar .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0;
  }
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-table,
.az-calendar .fc-view.fc-listWeek-view .fc-list-table {
  border-collapse: separate;
  display: block;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-table > tbody,
.az-calendar .fc-view.fc-listWeek-view .fc-list-table > tbody {
  display: flex;
  flex-wrap: wrap;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-heading,
.az-calendar .fc-view.fc-listWeek-view .fc-list-heading {
  flex: 0 0 20%;
  max-width: 20%;
  margin-top: 15px;
}

@media (min-width: 576px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 768px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 12%;
    max-width: 12%;
  }
}

@media (min-width: 992px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 1200px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 8%;
    max-width: 8%;
  }
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child,
.az-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child {
  margin-top: 0;
}

.az-calendar
  .fc-view.fc-listMonth-view
  .fc-list-heading:first-child
  + .fc-list-item,
.az-calendar
  .fc-view.fc-listWeek-view
  .fc-list-heading:first-child
  + .fc-list-item {
  margin-top: 0;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-heading td,
.az-calendar .fc-view.fc-listWeek-view .fc-list-heading td {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-heading-main,
.az-calendar .fc-view.fc-listWeek-view .fc-list-heading-main {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  color: #7987a1;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.az-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
  display: block;
  font-size: 30px;
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: -1px;
  color: #1c273c;
  line-height: 1;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now,
.az-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now {
  color: #5b47fb;
}

.az-calendar
  .fc-view.fc-listMonth-view
  .fc-list-heading-main.now
  span:last-child,
.az-calendar
  .fc-view.fc-listWeek-view
  .fc-list-heading-main.now
  span:last-child {
  color: #5b47fb;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item {
  flex: 0 0 calc(80% - 5px);
  max-width: calc(80% - 5px);
  display: flex;
  flex-direction: column;
  border-left: 4px solid transparent;
  background-color: #fff;
  margin-top: 15px;
}

@media (min-width: 576px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(85% - 5px);
    max-width: calc(85% - 5px);
  }
}

@media (min-width: 768px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(88% - 5px);
    max-width: calc(88% - 5px);
  }
}

@media (min-width: 992px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(90% - 5px);
    max-width: calc(90% - 5px);
  }
}

@media (min-width: 1200px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(92% - 5px);
    max-width: calc(92% - 5px);
  }
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item:hover,
.az-calendar .fc-view.fc-listMonth-view .fc-list-item:focus,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item:hover,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item:focus {
  cursor: pointer;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item:hover td,
.az-calendar .fc-view.fc-listMonth-view .fc-list-item:focus td,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item:hover td,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item:focus td {
  background-color: transparent;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item > td,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item > td {
  border-width: 0;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item > td.fc-list-item-time,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item > td.fc-list-item-time {
  border-top: 1px solid #e3e7ed;
  border-right: 1px solid #e3e7ed;
  box-sizing: border-box;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item > td.fc-list-item-title,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item > td.fc-list-item-title {
  border-bottom: 1px solid #e3e7ed;
  border-right: 1px solid #e3e7ed;
  box-sizing: border-box;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
  margin-left: 20%;
}

@media (min-width: 576px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 15%;
  }
}

@media (min-width: 768px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 12%;
  }
}

@media (min-width: 992px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 10%;
  }
}

@media (min-width: 1200px) {
  .az-calendar .fc-view.fc-listMonth-view .fc-list-item + .fc-list-item,
  .az-calendar .fc-view.fc-listWeek-view .fc-list-item + .fc-list-item {
    margin-left: 8%;
  }
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item-marker,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item-marker {
  display: none;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
  padding: 12px 15px 2px;
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
  padding: 0 15px 12px;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item-title a {
  display: block;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 5px;
  color: #1c273c;
}

.az-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.az-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
  font-size: 13px;
  line-height: 1.375;
  display: block;
}

.az-calendar td.fc-today {
  background-color: #f4f5f8;
}

.az-calendar td.fc-today .fc-day-number,
.az-calendar td.fc-today .fc-day-number:hover,
.az-calendar td.fc-today .fc-day-number:focus {
  background-color: #5b47fb;
  color: #fff;
  border-radius: 1px;
}

.az-calendar .fc-axis {
  font-size: 11px;
}

@media (min-width: 576px) {
  .az-calendar .fc-axis {
    font-size: 13px;
  }
}

.az-calendar .fc-event {
  border-width: 0;
  border-top-width: 5px;
  border-radius: 0;
}

@media (min-width: 576px) {
  .az-calendar .fc-event {
    border-top-width: 0;
    border-left-width: 2px;
    padding: 3px 4px;
  }
}

.az-calendar .fc-event.fc-day-grid-event > div {
  display: none;
}

@media (min-width: 576px) {
  .az-calendar .fc-event.fc-day-grid-event > div {
    display: block;
  }
}

.az-calendar .fc-event.fc-day-grid-event > div .fc-time {
  font-weight: 500;
}

.az-calendar .fc-event.fc-day-grid-event > div .fc-desc {
  display: none;
}

.az-calendar .fc-time-grid-event {
  padding: 5px;
  border-left-width: 0;
  border-top-width: 2px;
}

.az-calendar .fc-time-grid-event .fc-time {
  font-size: 12px;
}

.az-calendar .fc-time-grid-event .fc-title {
  font-size: 14px;
  font-weight: 700;
  color: #1c273c;
  margin-bottom: 10px;
}

.az-calendar .fc-time-grid-event .fc-desc {
  font-size: 13px;
  line-height: 1.3;
  color: rgba(28, 39, 60, 0.8);
}

.az-calendar .fc-agendaWeek-view .fc-time-grid-event > div {
  display: none;
}

@media (min-width: 576px) {
  .az-calendar .fc-agendaWeek-view .fc-time-grid-event > div {
    display: block;
  }
}

.az-calendar .fc-agendaWeek-view .fc-time-grid-event > div .fc-time {
  font-size: 11px;
}

.az-calendar .fc-agendaWeek-view .fc-time-grid-event > div .fc-title {
  font-size: 13px;
}

.az-calendar .fc-agendaWeek-view .fc-time-grid-event > div .fc-desc {
  display: none;
}

@media (min-width: 992px) {
  .az-calendar .fc-agendaWeek-view .fc-time-grid-event > div .fc-desc {
    display: block;
  }
}

.az-calendar .fc-list-empty {
  padding: 20px 0;
  background-color: #fff;
  border: 1px solid #cdd4e0;
}

.az-modal-calendar-schedule .modal-content {
  border-width: 0;
  position: relative;
}

.az-modal-calendar-schedule .modal-content > .close {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 28px;
  font-weight: 700;
  color: #7987a1;
  text-shadow: none;
}

.az-modal-calendar-schedule .modal-header {
  padding: 20px 25px;
}

.az-modal-calendar-schedule .modal-body {
  padding: 30px 25px;
}

.az-modal-calendar-schedule .form-group-date {
  display: flex;
  align-items: center;
  border: 1px solid #cdd4e0;
  background-color: #f4f5f8;
  padding: 10px 5px;
  color: #1c273c;
  font-size: 13px;
  font-weight: 600;
}

.az-modal-calendar-schedule .form-group-date > i {
  font-size: 32px;
  line-height: 0;
  margin-right: 10px;
  opacity: 0.5;
}

.az-modal-calendar-schedule .select2-container {
  width: 100% !important;
}

.az-modal-calendar-event .modal-content {
  border-width: 0;
  position: relative;
  background-color: transparent;
}

.az-modal-calendar-event .modal-content > .close {
  font-size: 18px;
  text-shadow: none;
}

.az-modal-calendar-event .modal-header {
  position: relative;
  padding: 20px;
  border-bottom-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 576px) {
  .az-modal-calendar-event .modal-header {
    padding: 20px 25px;
  }
}

.az-modal-calendar-event .nav-modal-event {
  align-items: center;
}

.az-modal-calendar-event .nav-modal-event .nav-link {
  padding: 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 21px;
  line-height: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-modal-calendar-event .nav-modal-event .nav-link {
    transition: none;
  }
}

.az-modal-calendar-event .nav-modal-event .nav-link:hover,
.az-modal-calendar-event .nav-modal-event .nav-link:focus {
  color: #fff;
}

.az-modal-calendar-event .nav-modal-event .nav-link + .nav-link {
  margin-left: 15px;
}

.az-modal-calendar-event .nav-modal-event .nav-link:last-child {
  font-size: 24px;
}

.az-modal-calendar-event .event-title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1;
}

.az-modal-calendar-event .modal-body {
  padding: 20px;
  background-color: #fff;
}

@media (min-width: 576px) {
  .az-modal-calendar-event .modal-body {
    padding: 30px;
  }
}

.az-modal-calendar-event .event-start-date,
.az-modal-calendar-event .event-end-date {
  color: #1c273c;
  font-weight: 500;
}

.az-modal-calendar-event .event-desc {
  margin-top: 5px;
}

.az-modal-calendar-event .event-desc:empty {
  margin-bottom: 5px;
}

/* ###### 7.3 Chat  ###### */

@media (max-width: 991.98px) {
  .az-content-body-show .az-header-menu-icon {
    display: none;
  }
  .az-content-body-show .az-header-left .az-header-arrow {
    display: block;
  }
  .az-content-body-show .az-content-left-chat {
    display: none;
  }
  .az-content-body-show .az-content-body-chat {
    display: flex;
  }
}

.az-content-left-chat {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
}

@media (min-width: 992px) {
  .az-content-left-chat {
    width: 320px;
    border-right: 1px solid #cdd4e0;
  }
}

.az-nav-line-chat {
  border-bottom: 1px solid #cdd4e0;
  padding-bottom: 5px;
  flex-shrink: 0;
  flex-direction: row;
  padding-left: 20px;
}

@media (min-width: 992px) {
  .az-nav-line-chat {
    padding-left: 0;
  }
}

.az-nav-line-chat .nav-link {
  font-size: 13px;
  font-weight: 500;
  color: #3b4863;
  text-transform: uppercase;
}

.az-nav-line-chat .nav-link:hover,
.az-nav-line-chat .nav-link:focus,
.az-nav-line-chat .nav-link:active {
  outline: none;
}

.az-nav-line-chat .nav-link + .nav-link {
  margin-top: 0;
  margin-left: 30px;
}

.az-nav-line-chat .nav-link.active {
  color: #5b47fb;
}

.az-nav-line-chat .nav-link.active::before {
  top: auto;
  left: 0;
  right: 0;
  width: auto;
  bottom: -6px;
  background-color: #5b47fb;
}

.az-chat-contacts-wrapper {
  padding: 10px 20px;
  flex-shrink: 0;
  border-bottom: 1px solid #cdd4e0;
  overflow: hidden;
}

@media (min-width: 992px) {
  .az-chat-contacts-wrapper {
    padding: 10px 15px 10px 0;
  }
}

.az-chat-contacts-wrapper .az-content-label,
.az-chat-contacts-wrapper .card-table-two .card-title,
.card-table-two .az-chat-contacts-wrapper .card-title,
.az-chat-contacts-wrapper .card-dashboard-eight .card-title,
.card-dashboard-eight .az-chat-contacts-wrapper .card-title {
  margin-bottom: 15px;
  line-height: 1;
}

.az-chat-contacts-wrapper .lSSlideOuter,
.az-chat-contacts-wrapper .lSSlideWrapper {
  overflow: visible;
}

.az-chat-contacts {
  padding-left: 20px;
  display: flex;
}

.az-chat-contacts .lslide {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: none;
}

.az-chat-contacts small {
  font-size: 11px;
  width: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 3px;
  text-align: center;
}

.az-chat-contacts-more {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #cdd4e0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

@media (min-width: 992px) {
  .az-chat-list {
    height: calc(100% - 134px);
    position: relative;
  }
}

.az-chat-list .media {
  padding: 12px 20px;
  border: 1px solid transparent;
  position: relative;
}

@media (min-width: 992px) {
  .az-chat-list .media {
    padding: 12px 15px;
  }
}

.az-chat-list .media .az-img-user {
  flex-shrink: 0;
  top: 3px;
}

.az-chat-list .media::after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 1px;
  background-color: #3366ff;
  display: none;
}

.az-chat-list .media + .media {
  margin-top: -1px;
}

.az-chat-list .media + .media::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 65px;
  right: 0;
  border-top: 1px solid #e3e7ed;
}

.az-chat-list .media.new {
  background-color: #fff;
}

.az-chat-list .media.new .az-img-user span {
  display: flex;
}

.az-chat-list .media.new .media-contact-name span:first-child {
  font-weight: 700;
  color: #1c273c;
}

.az-chat-list .media.new .media-body p {
  color: #031b4e;
}

.az-chat-list .media:hover,
.az-chat-list .media:focus {
  cursor: pointer;
  background-color: #fcfcfc;
  border-top-color: #e3e7ed;
  border-bottom-color: #e3e7ed;
  z-index: 1;
}

.az-chat-list .media:hover:first-child,
.az-chat-list .media:focus:first-child {
  border-top-color: transparent;
}

.az-chat-list .media.selected {
  background-color: #f4f5f8;
  border-top-color: #e3e7ed;
  border-bottom-color: #e3e7ed;
  z-index: 2;
}

.az-chat-list .media.selected:first-child {
  border-top-color: transparent;
}

.az-chat-list .media.selected::after {
  display: block;
}

.az-chat-list .media.selected .media-contact-name span:first-child {
  color: #1c273c;
}

.az-chat-list .media.selected .media-body p {
  color: #031b4e;
}

.az-chat-list .az-img-user span {
  position: absolute;
  top: 0;
  left: -2px;
  width: 14px;
  height: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  background-color: #dc3545;
  box-shadow: 0 0 0 2px #fff;
}

.az-chat-list .media-body {
  margin-left: 15px;
}

.az-chat-list .media-body p {
  font-size: 13px;
  margin-bottom: 0;
  color: #7987a1;
  line-height: 1.35;
}

.az-chat-list .media-contact-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.az-chat-list .media-contact-name span:first-child {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #1c273c;
}

.az-chat-list .media-contact-name span:last-child {
  display: block;
  font-size: 11px;
  color: #97a3b9;
}

.az-content-body-chat {
  flex-direction: column;
  display: none;
}

@media (min-width: 992px) {
  .az-content-body-chat {
    display: flex;
  }
}

.az-chat-header {
  flex-shrink: 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px 12px;
  border-bottom: 1px solid #cdd4e0;
}

.az-chat-header .nav {
  margin-left: auto;
  align-items: center;
}

.az-chat-header .nav-link {
  padding: 0;
  color: #cdd4e0;
  font-size: 20px;
  display: none;
}

.az-chat-header .nav-link:first-child {
  display: block;
}

@media (min-width: 576px) {
  .az-chat-header .nav-link {
    display: block;
  }
  .az-chat-header .nav-link:first-child {
    display: none;
  }
}

.az-chat-header .nav-link i {
  line-height: 0;
}

.az-chat-header .nav-link .typcn {
  line-height: 0.9;
}

.az-chat-header .nav-link .typcn::before {
  width: auto;
}

.az-chat-header .nav-link:hover,
.az-chat-header .nav-link:focus {
  color: #5b47fb;
}

.az-chat-header .nav-link + .nav-link {
  margin-left: 15px;
}

.az-chat-msg-name {
  margin-left: 15px;
}

.az-chat-msg-name h6 {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
  color: #1c273c;
}

.az-chat-msg-name small {
  font-size: 12px;
  color: #7987a1;
}

.az-chat-body {
  position: relative;
  padding-bottom: 50px;
}

@media (min-width: 992px) {
  .az-chat-body {
    padding-bottom: 0;
    height: calc(100% - 105px);
    overflow: hidden;
  }
}

.az-chat-body .content-inner {
  padding: 20px;
}

.az-chat-body .media + .media {
  margin-top: 20px;
}

.az-chat-body .media.flex-row-reverse .media-body {
  margin-left: 0;
  margin-right: 20px;
  align-items: flex-end;
}

@media (min-width: 576px) {
  .az-chat-body .media.flex-row-reverse .media-body {
    margin-left: 55px;
  }
}

.az-chat-body .media.flex-row-reverse .az-msg-wrapper {
  background-color: #5b47fb;
  color: #fff;
}

.az-chat-body .media-body {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 576px) {
  .az-chat-body .media-body {
    margin-right: 55px;
  }
}

.az-chat-body .media-body > div:last-child {
  font-size: 11px;
  color: #7987a1;
  display: flex;
  align-items: center;
}

.az-chat-time {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.az-chat-time:first-of-type {
  margin-top: 0;
}

.az-chat-time span {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.az-msg-wrapper {
  padding: 10px 15px;
  background-color: #cdd4e0;
  font-size: 13px;
  margin-bottom: 5px;
  border-radius: 2px;
  display: inline-block;
}

.az-chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-top: 1px solid #cdd4e0;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 992px) {
  .az-chat-footer {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  }
}

.az-chat-footer .nav {
  align-items: center;
}

.az-chat-footer .nav-link {
  padding: 0;
  color: #97a3b9;
  font-size: 16px;
  display: none;
}

.az-chat-footer .nav-link + .nav-link {
  margin-left: 10px;
}

.az-chat-footer .nav-link:last-child {
  display: block;
  margin-left: 0;
}

@media (min-width: 576px) {
  .az-chat-footer .nav-link {
    display: block;
  }
  .az-chat-footer .nav-link:last-child {
    display: none;
  }
}

.az-chat-footer .form-control {
  flex: 1;
  margin: 0 10px;
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
}

@media (min-width: 576px) {
  .az-chat-footer .form-control {
    margin-left: 20px;
  }
}

@media (min-width: 768px) {
  .az-chat-footer .form-control {
    margin: 0 20px;
  }
}

.az-chat-footer .form-control:hover,
.az-chat-footer .form-control:focus {
  box-shadow: none;
}

.az-msg-send {
  font-size: 20px;
  color: #3366ff;
}

/* ###### 7.4 Contacts  ###### */

@media (min-width: 768px) and (max-width: 991.98px) {
  .az-content-body-show .az-header-contacts .az-header-menu-icon {
    display: flex;
  }
  .az-content-body-show .az-content-left-contacts {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .az-content-contacts {
    height: calc(100vh - 60px);
  }
}

.az-content-left-contacts {
  width: 100%;
  display: block;
  padding: 0;
}

@media (min-width: 768px) {
  .az-content-left-contacts {
    width: 300px;
    border-right: 1px solid #cdd4e0;
  }
}

.az-content-left-contacts .az-content-breadcrumb,
.az-content-left-contacts .az-content-title {
  padding-left: 20px;
}

@media (min-width: 992px) {
  .az-content-left-contacts .az-content-breadcrumb,
  .az-content-left-contacts .az-content-title {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .az-contacts-list {
    height: calc(100% - 102px);
    position: relative;
  }
}

.az-contact-label {
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 12px;
  color: #97a3b9;
  position: relative;
}

@media (min-width: 992px) {
  .az-contact-label {
    padding-left: 0;
  }
}

.az-contact-label::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  border-bottom: 1px solid #cdd4e0;
  z-index: 5;
}

.az-contact-item {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-right-width: 0;
  display: flex;
  position: relative;
  cursor: pointer;
}

@media (min-width: 992px) {
  .az-contact-item {
    padding: 10px 15px;
  }
}

.az-contact-item + .az-contact-item {
  margin-top: -1px;
  border-top-color: transparent;
}

.az-contact-item + .az-contact-item::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 65px;
  right: 0;
  border-top: 1px solid #e3e7ed;
}

.az-contact-item:hover,
.az-contact-item:focus {
  background-color: #f4f5f8;
  border-top-color: #e3e7ed;
  border-bottom-color: #e3e7ed;
}

.az-contact-item.selected {
  z-index: 1;
  border-left-color: #5b47fb;
  border-top-color: #e3e7ed;
  border-bottom-color: #e3e7ed;
  background-color: #f4f5f8;
}

.az-contact-body {
  flex: 1;
  margin-left: 15px;
}

.az-contact-body h6 {
  color: #1c273c;
  font-weight: 500;
  margin-bottom: 2px;
}

.az-contact-body span {
  font-size: 13px;
  color: #7987a1;
}

.az-contact-star {
  font-size: 16px;
  color: #97a3b9;
}

.az-contact-star:hover,
.az-contact-star:focus {
  color: #7987a1;
}

.az-contact-star.active {
  color: #ffc107;
}

.az-content-body-contacts {
  display: none;
}

@media (min-width: 768px) {
  .az-content-body-contacts {
    display: block;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .az-content-body-contacts {
    overflow: visible;
  }
}

.az-contact-info-header {
  padding-top: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cdd4e0;
  position: relative;
}

@media (min-width: 576px) {
  .az-contact-info-header {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .az-contact-info-header {
    padding-left: 30px;
    padding-bottom: 25px;
  }
}

.az-contact-info-header .media {
  display: block;
}

@media (min-width: 992px) {
  .az-contact-info-header .media {
    display: flex;
    align-items: center;
  }
}

.az-contact-info-header .az-img-user {
  width: 100px;
  height: 100px;
}

.az-contact-info-header .az-img-user::after {
  display: none;
}

.az-contact-info-header .az-img-user a {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #7987a1;
  color: #fff;
  font-size: 18px;
  line-height: 0.9;
  box-shadow: 0 0 0 2px #fff;
  border-radius: 100%;
}

.az-contact-info-header .az-img-user a:hover,
.az-contact-info-header .az-img-user a:focus {
  background-color: #3366ff;
}

.az-contact-info-header .media-body {
  margin-top: 30px;
}

@media (min-width: 992px) {
  .az-contact-info-header .media-body {
    margin-top: 0;
    margin-left: 30px;
  }
}

.az-contact-info-header .media-body h4 {
  color: #1c273c;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 1;
}

.az-contact-info-header .media-body p {
  color: #031b4e;
  margin-bottom: 15px;
}

.az-contact-info-header .media-body .nav-link {
  padding: 0;
  display: flex;
  align-items: center;
  color: #7987a1;
  font-size: 13px;
}

@media (min-width: 576px) {
  .az-contact-info-header .media-body .nav-link {
    font-size: 0.875rem;
  }
}

.az-contact-info-header .media-body .nav-link i {
  font-size: 21px;
  line-height: 0;
  margin-right: 5px;
}

@media (min-width: 576px) {
  .az-contact-info-header .media-body .nav-link i {
    margin-right: 7px;
  }
}

.az-contact-info-header .media-body .nav-link i.typcn {
  line-height: 0.9;
}

.az-contact-info-header .media-body .nav-link i.typcn::before {
  width: auto;
}

.az-contact-info-header .media-body .nav-link:hover,
.az-contact-info-header .media-body .nav-link:focus {
  color: #3366ff;
}

.az-contact-info-header .media-body .nav-link + .nav-link {
  margin-left: 10px;
}

@media (min-width: 576px) {
  .az-contact-info-header .media-body .nav-link + .nav-link {
    margin-left: 25px;
  }
}

.az-contact-action {
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
}

@media (min-width: 992px) {
  .az-contact-action {
    right: 0;
  }
}

.az-contact-action a {
  display: flex;
  align-items: center;
  color: #7987a1;
  font-size: 12px;
}

.az-contact-action a:hover,
.az-contact-action a:focus {
  color: #3366ff;
}

.az-contact-action a i {
  font-size: 21px;
  line-height: 0.7;
  margin-right: 5px;
}

.az-contact-action a + a {
  margin-left: 15px;
}

.az-contact-info-body {
  padding-left: 20px;
}

@media (min-width: 992px) {
  .az-contact-info-body {
    padding-left: 30px;
    height: calc(100% - 126px);
    position: relative;
  }
}

.az-contact-info-body .media-list {
  padding: 20px 0;
}

@media (min-width: 992px) {
  .az-contact-info-body .media-list {
    padding: 25px 0;
  }
}

.az-contact-info-body .media {
  align-items: center;
  position: relative;
}

.az-contact-info-body .media + .media {
  margin-top: 30px;
}

.az-contact-info-body .media + .media::before {
  content: "";
  position: absolute;
  top: -15px;
  left: 130px;
  right: 0;
  border-top: 1px dotted #cdd4e0;
}

.az-contact-info-body .media:last-child {
  margin-bottom: 15px;
}

.az-contact-info-body .media-icon {
  font-size: 32px;
  color: #97a3b9;
  line-height: 1;
  width: 40px;
  text-align: center;
}

@media (min-width: 992px) {
  .az-contact-info-body .media-icon {
    width: 100px;
  }
}

.az-contact-info-body .media-body {
  margin-left: 30px;
}

@media (min-width: 992px) {
  .az-contact-info-body .media-body {
    display: flex;
  }
}

@media (min-width: 992px) {
  .az-contact-info-body .media-body > div {
    flex: 1;
  }
}

.az-contact-info-body .media-body > div + div {
  margin-top: 15px;
}

@media (min-width: 992px) {
  .az-contact-info-body .media-body > div + div {
    margin-top: 0;
  }
}

.az-contact-info-body .media-body label {
  color: #7987a1;
  margin-bottom: 0;
  display: block;
  font-size: 13px;
}

.az-contact-info-body .media-body span {
  display: block;
  color: #1c273c;
  font-weight: 500;
}

/* ###### 7.5 Invoice  ###### */

.az-content-left-invoice {
  display: block;
  padding: 0;
}

@media (max-width: 991.98px) {
  .az-content-left-invoice {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .az-content-left-invoice {
    border-right: 1px solid #cdd4e0;
  }
}

.az-content-left-invoice .az-content-breadcrumb,
.az-content-left-invoice .az-content-title {
  padding-left: 20px;
}

@media (min-width: 992px) {
  .az-content-left-invoice .az-content-breadcrumb,
  .az-content-left-invoice .az-content-title {
    padding-left: 0;
  }
}

.az-invoice-list {
  position: relative;
  border-top: 1px solid #cdd4e0;
}

@media (min-width: 992px) {
  .az-invoice-list {
    height: calc(100% - 65px);
  }
}

.az-invoice-list .media {
  align-items: center;
  border: 1px solid transparent;
  border-right-width: 0;
  padding: 10px 20px;
  position: relative;
}

@media (min-width: 992px) {
  .az-invoice-list .media {
    padding: 10px 15px;
  }
}

.az-invoice-list .media:hover,
.az-invoice-list .media:focus {
  cursor: pointer;
  background-color: #f4f5f8;
}

.az-invoice-list .media + .media {
  margin-top: -1px;
}

.az-invoice-list .media + .media::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 55px;
  right: 0;
  border-top: 1px dotted #cdd4e0;
}

.az-invoice-list .media-icon {
  width: 24px;
  text-align: center;
  font-size: 32px;
  color: #97a3b9;
}

.az-invoice-list .media-body {
  margin-left: 15px;
}

.az-invoice-list .media-body h6 {
  font-weight: 500;
  color: #1c273c;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.az-invoice-list .media-body h6 span:last-child {
  color: #5b47fb;
}

.az-invoice-list .media-body div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.az-invoice-list .media-body p {
  margin-bottom: 0;
  font-size: 11px;
  font-family: Arial, sans-serif;
  color: #3b4863;
}

.az-invoice-list .media-body p span {
  color: #7987a1;
}

.az-invoice-list .selected {
  background-color: #f4f5f8;
  border-top-color: #cdd4e0;
  border-bottom-color: #cdd4e0;
  border-left-color: #5b47fb;
}

.az-invoice-list .selected:first-child {
  border-top-color: transparent;
}

.az-content-body-invoice {
  position: relative;
  display: none;
}

@media (min-width: 992px) {
  .az-content-body-invoice {
    display: block;
  }
}

.card-invoice {
  background-color: transparent;
  border-width: 0;
}

.card-invoice .card-body {
  padding: 20px;
}

@media (min-width: 992px) {
  .card-invoice .card-body {
    padding: 0 50px 50px;
  }
}

@media (min-width: 768px) {
  .invoice-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.invoice-title {
  margin-bottom: 0;
  text-transform: uppercase;
  color: #cdd4e0;
  font-weight: 700;
}

.billed-from {
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .billed-from {
    margin-top: 0;
  }
}

.billed-from h6 {
  color: #1c273c;
  font-size: 14px;
  font-weight: 700;
}

.billed-from p {
  font-size: 13px;
  margin-bottom: 0;
}

.billed-to h6 {
  color: #1c273c;
  font-size: 14px;
  font-weight: 700;
}

.billed-to p {
  font-size: 13px;
  margin-bottom: 0;
}

.invoice-info-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 0;
  font-size: 13px;
}

.invoice-info-row + .invoice-info-row {
  border-top: 1px dotted #cdd4e0;
}

.invoice-info-row span:first-child {
  color: #596882;
}

.table-invoice thead > tr > th,
.table-invoice thead > tr > td {
  font-size: 11px;
  font-weight: 700;
  color: #7987a1;
}

.table-invoice tbody > tr > th:first-child,
.table-invoice tbody > tr > td:first-child {
  color: #1c273c;
  font-weight: 500;
}

.table-invoice tbody > tr > th:first-child .invoice-notes,
.table-invoice tbody > tr > td:first-child .invoice-notes {
  margin-right: 20px;
}

.table-invoice tbody > tr > th:first-child .invoice-notes .section-label-sm,
.table-invoice tbody > tr > td:first-child .invoice-notes .section-label-sm {
  font-weight: 600;
}

.table-invoice tbody > tr > th:first-child .invoice-notes p,
.table-invoice tbody > tr > td:first-child .invoice-notes p {
  font-size: 13px;
  font-weight: 400;
  color: #031b4e;
}

/* ###### 7.6 Mail  ###### */

.az-content-mail .container,
.az-content-mail .container-fluid,
.az-content-mail .container-sm,
.az-content-mail .container-md,
.az-content-mail .container-lg,
.az-content-mail .container-xl {
  padding: 20px 0 0;
}

@media (min-width: 992px) {
  .az-content-mail .container,
  .az-content-mail .container-fluid,
  .az-content-mail .container-sm,
  .az-content-mail .container-md,
  .az-content-mail .container-lg,
  .az-content-mail .container-xl {
    padding: 0;
  }
}

@media (min-width: 576px) {
  .az-content-left-mail {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .az-content-left-mail {
    width: 230px;
    display: block;
    padding: 0;
    border-right: 1px solid #cdd4e0;
  }
}

.az-content-left-mail .btn-compose {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  padding: 0 20px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}

@media (min-width: 992px) {
  .az-content-left-mail .btn-compose {
    margin-right: 25px;
  }
}

.az-mail-menu {
  position: relative;
  padding-right: 0;
}

@media (min-width: 992px) {
  .az-mail-menu {
    padding-right: 25px;
  }
}

.az-mail-menu .nav-link {
  height: 38px;
}

@media (min-width: 992px) {
  .az-mail-menu .nav-link {
    font-size: 13px;
  }
}

.az-mail-menu .nav-link i {
  font-size: 22px;
}

.az-mail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}

@media (min-width: 992px) {
  .az-mail-header {
    padding: 0 0 0 25px;
    margin-bottom: 25px;
  }
}

.az-mail-header > div:first-child p {
  font-size: 13px;
  margin-bottom: 0;
}

.az-mail-header > div:last-child {
  display: none;
}

@media (min-width: 768px) {
  .az-mail-header > div:last-child {
    display: flex;
    align-items: center;
  }
}

.az-mail-header > div:last-child > span {
  font-size: 12px;
  margin-right: 10px;
}

.az-mail-header .btn-group .btn,
.az-mail-header .btn-group .sp-container button,
.sp-container .az-mail-header .btn-group button {
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  line-height: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  border-color: #cdd4e0;
  background-color: #fff;
}

.az-mail-header .btn-group .btn:hover,
.az-mail-header .btn-group .sp-container button:hover,
.sp-container .az-mail-header .btn-group button:hover,
.az-mail-header .btn-group .btn:focus,
.az-mail-header .btn-group .sp-container button:focus,
.sp-container .az-mail-header .btn-group button:focus {
  color: #1c273c;
  background-color: #f4f5f8;
}

.az-mail-header .btn-group .btn.disabled,
.az-mail-header .btn-group .sp-container button.disabled,
.sp-container .az-mail-header .btn-group button.disabled {
  background-color: #fff;
  color: #cdd4e0;
  border-color: #cdd4e0;
  z-index: 0;
}

.az-mail-header .btn-group .btn.disabled:focus,
.az-mail-header .btn-group .sp-container button.disabled:focus,
.sp-container .az-mail-header .btn-group button.disabled:focus,
.az-mail-header .btn-group .btn.disabled:active,
.az-mail-header .btn-group .sp-container button.disabled:active,
.sp-container .az-mail-header .btn-group button.disabled:active {
  box-shadow: none;
}

.az-mail-header .btn-group .btn + .btn,
.az-mail-header .btn-group .sp-container button + .btn,
.sp-container .az-mail-header .btn-group button + .btn,
.az-mail-header .btn-group .sp-container .btn + button,
.sp-container .az-mail-header .btn-group .btn + button,
.az-mail-header .btn-group .sp-container button + button,
.sp-container .az-mail-header .btn-group button + button {
  margin-left: -2px;
}

.az-mail-options {
  padding: 5px 10px 5px 20px;
  border: 1px solid #cdd4e0;
  border-left-width: 0;
  border-right-width: 0;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .az-mail-options {
    padding-left: 25px;
    display: flex;
    justify-content: space-between;
  }
}

.az-mail-options .btn,
.az-mail-options .sp-container button,
.sp-container .az-mail-options button {
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: transparent;
}

.az-mail-options .btn:hover,
.az-mail-options .sp-container button:hover,
.sp-container .az-mail-options button:hover,
.az-mail-options .btn:focus,
.az-mail-options .sp-container button:focus,
.sp-container .az-mail-options button:focus {
  background-color: #e3e7ed;
}

.az-mail-options .btn i,
.az-mail-options .sp-container button i,
.sp-container .az-mail-options button i {
  line-height: 0;
}

.az-mail-options .btn i.typcn,
.az-mail-options .sp-container button i.typcn,
.sp-container .az-mail-options button i.typcn {
  line-height: 0.75;
}

.az-mail-options .btn.disabled,
.az-mail-options .sp-container button.disabled,
.sp-container .az-mail-options button.disabled {
  background-color: transparent;
  color: #7987a1;
}

.az-mail-list {
  border-top: 1px solid #e3e7ed;
}

@media (min-width: 992px) {
  .az-mail-list {
    border-top-width: 0;
  }
}

.az-mail-item {
  padding: 10px 15px;
  border-top: 1px solid #e3e7ed;
  border-bottom: 1px solid #e3e7ed;
  background-color: #fcfcfc;
  position: relative;
  display: flex;
  align-items: center;
  width: 1920px;
}

@media (min-width: 576px) {
  .az-mail-item {
    padding: 10px 20px;
  }
}

@media (min-width: 992px) {
  .az-mail-item {
    width: auto;
    padding: 10px 25px;
  }
}

.az-mail-item + .az-mail-item {
  margin-top: -1px;
}

.az-mail-item:first-child {
  border-top-width: 0;
}

.az-mail-item .az-img-user,
.az-mail-item .az-avatar {
  flex-shrink: 0;
  margin-right: 15px;
}

.az-mail-item .az-img-user::after,
.az-mail-item .az-avatar::after {
  display: none;
}

.az-mail-item:hover,
.az-mail-item:focus {
  background-color: #f4f5f8;
}

.az-mail-item.unread {
  background-color: #fff;
}

.az-mail-item.selected {
  background-color: white;
}

.az-mail-checkbox {
  margin-right: 15px;
  display: none;
}

@media (min-width: 992px) {
  .az-mail-checkbox {
    display: block;
  }
}

.az-mail-star {
  margin-right: 15px;
  font-size: 18px;
  line-height: 0.9;
  color: #cdd4e0;
  position: absolute;
  bottom: 10px;
  right: 0;
}

@media (min-width: 992px) {
  .az-mail-star {
    position: relative;
    bottom: auto;
    top: 2px;
  }
}

.az-mail-star.active {
  color: #ffc107;
}

.az-mail-body {
  width: calc(100% - 80px);
  cursor: pointer;
}

@media (min-width: 992px) {
  .az-mail-body {
    max-width: 460px;
    margin-right: 15px;
    flex: 1;
  }
}

@media (min-width: 1200px) {
  .az-mail-body {
    max-width: 640px;
  }
}

.az-mail-from {
  font-size: 13px;
}

@media (min-width: 576px) {
  .az-mail-subject {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}

.az-mail-subject strong {
  font-weight: 700;
  font-size: 14px;
  color: #1c273c;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

@media (min-width: 576px) {
  .az-mail-subject strong {
    display: inline;
    width: auto;
    white-space: normal;
    text-overflow: inherit;
    overflow: visible;
  }
}

.az-mail-subject span {
  font-size: 13px;
  color: #7987a1;
  display: none;
}

@media (min-width: 576px) {
  .az-mail-subject span {
    display: inline;
  }
}

.az-mail-attachment {
  margin-right: 15px;
  font-size: 21px;
  line-height: 0.9;
  display: none;
}

@media (min-width: 992px) {
  .az-mail-attachment {
    display: block;
  }
}

.az-mail-date {
  font-size: 11px;
  position: absolute;
  top: 12px;
  right: 15px;
  color: #97a3b9;
  margin-left: auto;
}

@media (min-width: 992px) {
  .az-mail-date {
    position: static;
    font-size: 13px;
  }
}

.az-mail-compose {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 39, 60, 0.5);
  z-index: 1000;
  display: none;
}

.az-mail-compose > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .az-mail-compose .container {
    padding: 0;
  }
}

.az-mail-compose-box {
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.2);
  border-radius: 3px;
}

.az-mail-compose-header {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c273c;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

@media (min-width: 992px) {
  .az-mail-compose-header {
    padding: 20px 25px;
  }
}

.az-mail-compose-header .nav-link {
  color: rgba(255, 255, 255, 0.3);
  font-size: 14px;
  line-height: 1;
  padding: 0;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-mail-compose-header .nav-link {
    transition: none;
  }
}

.az-mail-compose-header .nav-link:hover,
.az-mail-compose-header .nav-link:focus {
  color: #fff;
}

.az-mail-compose-header .nav-link + .nav-link {
  margin-left: 15px;
}

.az-mail-compose-header .nav-link:nth-child(2) {
  display: none;
}

@media (min-width: 768px) {
  .az-mail-compose-header .nav-link:nth-child(2) {
    display: block;
  }
}

.az-mail-compose-body {
  background-color: #fff;
  padding: 20px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

@media (min-width: 992px) {
  .az-mail-compose-body {
    padding: 25px;
  }
}

.az-mail-compose-body .form-group {
  display: flex;
  align-items: center;
}

.az-mail-compose-body .form-group > div {
  flex: 1;
  margin-left: 10px;
}

.az-mail-compose-body .form-group .form-label {
  margin: 0;
  color: #1c273c;
}

.az-mail-compose-body .form-group .form-control {
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.az-mail-compose-body .form-group .form-control:focus {
  box-shadow: none !important;
}

.az-mail-compose-body .form-group + .form-group {
  border-top: 1px dotted #cdd4e0;
  padding-top: 1rem;
}

.az-mail-compose-body .form-group:last-child {
  display: block;
}

@media (min-width: 576px) {
  .az-mail-compose-body .form-group:last-child {
    display: flex;
    justify-content: space-between;
  }
}

.az-mail-compose-body .form-group:last-child .btn,
.az-mail-compose-body .form-group:last-child .sp-container button,
.sp-container .az-mail-compose-body .form-group:last-child button {
  width: 100%;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 576px) {
  .az-mail-compose-body .form-group:last-child .btn,
  .az-mail-compose-body .form-group:last-child .sp-container button,
  .sp-container .az-mail-compose-body .form-group:last-child button {
    width: auto;
    margin-top: 0;
  }
}

.az-mail-compose-body .form-group .nav-link {
  padding: 0;
  font-size: 18px;
  line-height: 0;
  color: #031b4e;
  position: relative;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-mail-compose-body .form-group .nav-link {
    transition: none;
  }
}

.az-mail-compose-body .form-group .nav-link:hover,
.az-mail-compose-body .form-group .nav-link:focus {
  color: #1c273c;
}

.az-mail-compose-body .form-group .nav-link + .nav-link {
  margin-left: 15px;
}

.az-mail-compose-compress,
.az-mail-compose-minimize {
  top: auto;
  left: auto;
  bottom: 0;
  right: 30px;
  width: 560px;
  height: auto;
  background-color: transparent;
}

.az-mail-compose-compress .container,
.az-mail-compose-minimize .container {
  max-width: none;
  padding: 0;
}

.az-mail-compose-minimize .az-mail-compose-body {
  display: none;
}

/* ###### 7.7 Mail Two  ###### */

.az-mail-two .az-content {
  display: flex;
  flex-direction: column;
}

.az-mail-two .az-header {
  width: 100%;
  border-bottom: 1px solid #cdd4e0;
}

.az-mail-two .az-footer {
  width: 100%;
}

.az-mail-two .az-header-menu-icon {
  margin-right: 0;
}

.az-mail-two .az-content-body {
  display: flex;
  padding: 0;
}

.az-mail-left {
  background-color: #f9f9f9;
  width: 240px;
  border-right: 1px solid #b4bdce;
  padding: 20px;
  display: none;
}

@media (min-width: 1200px) {
  .az-mail-left {
    display: block;
  }
}

.az-mail-left .btn-compose {
  display: block;
  margin-bottom: 20px;
}

.az-mail-left .az-mail-menu {
  padding-right: 0;
}

.az-mail-content {
  background-color: #fcfcfc;
  flex: 1;
  max-width: 1920px - 480px;
}

.az-mail-content .az-mail-header {
  margin-bottom: 0;
  padding: 20px;
}

.az-mail-content .az-mail-body {
  max-width: 590px;
}

/* ###### 7.8 Profile  ###### */

.az-content-profile {
  flex: 1;
}

@media (max-width: 991.98px) {
  .az-content-profile .container,
  .az-content-profile .container-fluid,
  .az-content-profile .container-sm,
  .az-content-profile .container-md,
  .az-content-profile .container-lg,
  .az-content-profile .container-xl {
    display: block;
  }
}

.az-content-left-profile {
  padding-left: 0;
  padding-right: 0;
  display: block;
  border-bottom: 1px solid #cdd4e0;
  padding-bottom: 25px;
  width: auto;
}

@media (min-width: 992px) {
  .az-content-left-profile {
    width: 270px;
    padding-right: 20px;
    padding-bottom: 0;
    border-right: 1px solid #cdd4e0;
    border-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .az-content-left-profile {
    padding-right: 25px;
  }
}

.az-profile-overview .az-img-user {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.az-profile-overview .az-img-user::after {
  display: none;
}

.az-profile-overview .btn-icon-list .btn,
.az-profile-overview .btn-icon-list .sp-container button,
.sp-container .az-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.az-profile-name {
  color: #1c273c;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
}

.az-profile-name-text {
  color: #7987a1;
  font-size: 13px;
  margin-bottom: 0;
}

.az-profile-bio {
  font-size: 13px;
  margin-bottom: 20px;
}

.az-profile-social-list .media {
  align-items: center;
}

.az-profile-social-list .media + .media {
  margin-top: 20px;
}

.az-profile-social-list .media-icon {
  font-size: 34px;
  width: 30px;
  line-height: 0;
}

.az-profile-social-list .media-body {
  margin-left: 20px;
}

.az-profile-social-list .media-body span {
  display: block;
  font-size: 12px;
}

.az-profile-social-list .media-body a {
  font-size: 13px;
}

.az-content-body-profile .nav {
  flex-direction: column;
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #cdd4e0;
}

@media (min-width: 576px) {
  .az-content-body-profile .nav {
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .az-content-body-profile .nav {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .az-content-body-profile .nav {
    padding-left: 25px;
  }
}

.az-content-body-profile .az-nav-line .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #3b4863;
}

.az-content-body-profile .az-nav-line .nav-link.active {
  font-weight: 700;
  color: #5b47fb;
}

.az-content-body-profile .az-nav-line .nav-link.active::before {
  background-color: #5b47fb;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .az-content-body-profile .az-nav-line .nav-link.active::before {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    height: 2px;
  }
}

@media (min-width: 576px) {
  .az-content-body-profile .az-nav-line .nav-link.active::before {
    bottom: -11px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .az-content-body-profile .az-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

.az-profile-body {
  padding: 15px 0 0;
}

@media (min-width: 576px) {
  .az-profile-body {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .az-profile-body {
    padding: 25px 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .az-profile-body {
    padding-left: 25px;
  }
}

.az-profile-view-chart {
  position: relative;
  width: calc(100% - 10px);
  height: 200px;
}

@media (min-width: 375px) {
  .az-profile-view-chart {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .az-profile-view-chart {
    height: 250px;
  }
}

.az-profile-view-info {
  position: absolute;
  top: 0;
  left: 0;
}

.az-profile-view-info h6 {
  font-size: 32px;
  font-weight: 500;
  color: #1c273c;
  margin-bottom: 0;
}

.az-profile-view-info span {
  font-size: 12px;
  color: #f10075;
  margin-left: 5px;
}

.az-profile-view-info p {
  font-size: 13px;
  margin-bottom: 0;
}

.az-traffic-detail-item > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 7px;
}

.az-traffic-detail-item > div:first-child > span:first-child {
  color: #7987a1;
}

.az-traffic-detail-item > div:first-child > span:last-child {
  font-size: 11px;
  font-weight: 700;
  color: #1c273c;
}

.az-traffic-detail-item > div:first-child > span:last-child span {
  color: #7987a1;
  font-weight: 400;
}

.az-traffic-detail-item + .az-traffic-detail-item {
  margin-top: 25px;
}

.az-traffic-detail-item .progress {
  height: 8px;
}

.az-profile-work-list .media + .media {
  margin-top: 25px;
}

.az-profile-work-list .media-logo {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: #fff;
  font-size: 21px;
  position: relative;
  top: 2px;
  border-radius: 100%;
}

.az-profile-work-list .media-body {
  margin-left: 20px;
}

.az-profile-work-list .media-body h6 {
  color: #1c273c;
  font-weight: 500;
  margin-bottom: 2px;
}

.az-profile-work-list .media-body span {
  display: block;
  margin-bottom: 5px;
}

.az-profile-work-list .media-body p {
  margin-bottom: 0;
  font-size: 12px;
  color: #7987a1;
}

.az-profile-contact-list .media {
  align-items: center;
}

.az-profile-contact-list .media + .media {
  margin-top: 25px;
}

.az-profile-contact-list .media-icon {
  line-height: 0;
  font-size: 36px;
  width: 35px;
  text-align: center;
}

.az-profile-contact-list .media-body {
  margin-left: 25px;
}

.az-profile-contact-list .media-body span {
  font-size: 12px;
  color: #7987a1;
  display: block;
  line-height: 1.3;
}

.az-profile-contact-list .media-body div {
  font-weight: 500;
  color: #1c273c;
}

/* ###### 7.9 Signin  ###### */

.az-signin-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.az-card-signin {
  height: 560px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 480px) {
  .az-card-signin {
    border: 1px solid #cdd4e0;
    width: 360px;
    padding: 30px 35px;
    margin-top: 35px;
  }
}

.az-signin-header h2 {
  font-weight: 500;
  color: #5b47fb;
  letter-spacing: -1px;
}

.az-signin-header h4 {
  font-weight: 400;
  color: #1c273c;
  margin-bottom: 25px;
}

.az-signin-header label {
  color: #7987a1;
}

.az-signin-header .form-control {
  color: #1c273c;
  font-weight: 500;
  border-width: 2px;
  border-color: #cdd4e0;
}

.az-signin-header .form-control:focus {
  border-color: #b4bdce;
  box-shadow: none;
}

.az-signin-header .form-control::placeholder {
  font-weight: 400;
  color: #97a3b9;
}

.az-signin-header .btn,
.az-signin-header .sp-container button,
.sp-container .az-signin-header button {
  margin-top: 25px;
}

.az-signin-footer p {
  color: #7987a1;
}

.az-signin-footer p:first-child {
  margin-bottom: 5px;
}

.az-signin-footer p:last-child {
  margin-bottom: 0;
}

.az-signin-footer a {
  color: #1c273c;
  font-weight: 700;
}

.az-signin-footer a:hover,
.az-signin-footer a:focus {
  color: #5b47fb;
}

/* ###### 7.10 Signup  ###### */

.az-signup-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(244, 245, 248, 0.2);
}

@media (min-width: 992px) {
  .az-signup-wrapper {
    justify-content: flex-end;
  }
}

.az-column-signup-left {
  flex: 1;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
}

@media (min-width: 576px) {
  .az-column-signup-left {
    padding: 40px;
  }
}

@media (min-width: 992px) {
  .az-column-signup-left {
    display: flex;
  }
}

.az-column-signup-left > div {
  max-width: 500px;
}

.az-column-signup-left .typcn {
  font-size: 80px;
  margin-bottom: 50px;
}

.az-column-signup-left .typcn::before {
  width: auto;
}

.az-column-signup-left .az-logo {
  font-size: 40px;
  line-height: 1;
}

.az-column-signup-left h5 {
  color: #1c273c;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 25px;
}

.az-column-signup-left p {
  line-height: 1.7;
  margin-bottom: 25px;
}

.az-column-signup-left .btn,
.az-column-signup-left .sp-container button,
.sp-container .az-column-signup-left button {
  border-width: 2px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 700;
  text-transform: uppercase;
  height: 38px;
}

.az-column-signup {
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .az-column-signup {
    padding: 40px;
    width: 500px;
  }
}

@media (min-width: 992px) {
  .az-column-signup {
    border-left: 1px solid #cdd4e0;
  }
}

.az-signup-header h2 {
  font-weight: 500;
  color: #5b47fb;
  letter-spacing: -1px;
}

.az-signup-header h4 {
  font-size: 20px;
  font-weight: 400;
  color: #1c273c;
  margin-bottom: 25px;
}

.az-signup-header label {
  color: #7987a1;
}

.az-signup-header .form-control {
  color: #1c273c;
  font-weight: 500;
  border-width: 2px;
  border-color: #cdd4e0;
}

.az-signup-header .form-control:focus {
  border-color: #b4bdce;
  box-shadow: none;
}

.az-signup-header .form-control::placeholder {
  font-weight: 400;
  color: #97a3b9;
}

.az-signup-header > .btn,
.sp-container .az-signup-header > button {
  margin-top: 25px;
}

.az-signup-header .row {
  margin-top: 20px;
}

.az-signup-header .row > div .btn i,
.az-signup-header .row > div .sp-container button i,
.sp-container .az-signup-header .row > div button i {
  font-size: 15px;
  line-height: 0;
  margin-right: 5px;
}

.az-signup-header .row > div:first-child .btn,
.az-signup-header .row > div:first-child .sp-container button,
.sp-container .az-signup-header .row > div:first-child button {
  background-color: #4267b2;
  color: #fff;
}

.az-signup-header .row > div:first-child .btn:hover,
.az-signup-header .row > div:first-child .sp-container button:hover,
.sp-container .az-signup-header .row > div:first-child button:hover,
.az-signup-header .row > div:first-child .btn:focus,
.az-signup-header .row > div:first-child .sp-container button:focus,
.sp-container .az-signup-header .row > div:first-child button:focus {
  background-color: #375694;
  color: #fff;
}

.az-signup-footer p {
  color: #7987a1;
  margin-bottom: 0;
}

.az-signup-footer a {
  color: #1c273c;
  font-weight: 700;
}

.az-signup-footer a:hover,
.az-signup-footer a:focus {
  color: #5b47fb;
}

/* ###### 7.11 Error  ###### */

.az-error-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  text-align: center;
}

.az-error-wrapper h1 {
  line-height: 1;
  font-size: 150px;
  font-weight: 700;
  color: #1c273c;
}

.az-error-wrapper h2 {
  font-weight: 500;
  color: #3b4863;
  letter-spacing: -0.5px;
  margin-bottom: 15px;
}

.az-error-wrapper h6 {
  margin-bottom: 40px;
  font-size: 14px;
}

.az-error-wrapper .btn,
.az-error-wrapper .sp-container button,
.sp-container .az-error-wrapper button {
  border-width: 2px;
  font-weight: 700;
}

.board-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.board-wrapper .board-portlet {
  background: #eae2ec;
  margin: 0px 15px;
  padding: 22px 20px;
  min-width: 345px;
  width: 345px;
  border-radius: 4px;
}

.board-wrapper .portlet-heading {
  font-weight: 500;
  margin-bottom: 5px;
}

.board-wrapper .task-number {
  color: #7987a1;
}

.board-wrapper .portlet-card-list {
  padding-left: 0;
  list-style: none;
  min-height: 70px;
}

.board-wrapper .portlet-card {
  width: 100%;
  border-radius: 4px;
  padding: 20px 20px 20px 20px;
  background: #fff;
  display: grid;
  grid-template-rows: 5;
  grid-template-columns: 2;
  border-radius: 6px;
  position: relative;
  margin-bottom: 15px;
  cursor: grab;
}

.board-wrapper .portlet-card .progress {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  border-radius: 6px 6px 0px 0px;
  height: 4px;
}

.board-wrapper .portlet-card .progress .progress-bar {
  border-radius: 0px;
}

.board-wrapper .portlet-card .task-date {
  margin-bottom: 5px;
  color: #7987a1;
  grid-row-start: 1;
  grid-column-start: 1;
}

.board-wrapper .portlet-card .action-dropdown {
  grid-row-start: 1;
  grid-column-start: 2;
  margin-left: auto;
}

.board-wrapper .portlet-card .action-dropdown .dropdown-toggle {
  border: none;
  background: inherit;
}

.board-wrapper .portlet-card .action-dropdown .dropdown-toggle i {
  font-size: 20px;
  line-height: 20px;
}

.board-wrapper .portlet-card .action-dropdown .dropdown-toggle i:before {
  margin-right: -10px;
}

.board-wrapper .portlet-card .action-dropdown .dropdown-toggle:after {
  display: none;
}

.board-wrapper .portlet-card .action-dropdown .dropdown-menu-right {
  left: 0 !important;
}

.board-wrapper .portlet-card .task-title {
  font-weight: 500;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 16px;
  margin-bottom: 15px;
}

.board-wrapper .portlet-card .image-grouped {
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 3;
}

.board-wrapper .portlet-card .portlet-image {
  width: 100%;
  display: block;
  grid-row-start: 4;
  grid-column-start: 1;
  grid-column-end: 3;
  margin-top: 5px;
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
}

.board-wrapper .portlet-card .badge {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row-start: 5;
  grid-column-start: 1;
  grid-column-end: 2;
  margin-top: 20px;
}

.board-wrapper .portlet-card .due-date {
  grid-row-start: 5;
  grid-column-start: 2;
  grid-column-end: 3;
  margin-bottom: 0;
  text-align: right;
  margin-top: 20px;
  color: #7987a1;
  font-weight: 500;
}

.board-wrapper .add-portlet {
  background: #fff;
  border-radius: 6px;
  padding: 25px 10px;
  text-align: center;
  color: #7987a1;
  font-weight: 500;
  border: none;
  width: 100%;
  transition-duration: 0.4s;
  transition-property: "background";
}

.board-wrapper .add-portlet:hover {
  background: #f2f2f2;
}

@media (max-width: 768px) {
  .board-wrapper {
    overflow-x: auto;
  }
  .board-wrapper .board-portlet {
    min-width: 100%;
    width: 100%;
  }
}

.kanban-toolbar .btn,
.kanban-toolbar .sp-container button,
.sp-container .kanban-toolbar button {
  margin-left: 10px;
}

.wrapper .private-btn {
  background-color: #eae2ec;
}

.tickets-tab-switch {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  margin-right: -10px;
}

.tickets-tab-switch .nav-item {
  display: block;
  width: 33.33%;
  padding: 0 10px;
}

@media (max-width: 767.98px) {
  .tickets-tab-switch .nav-item {
    width: 100%;
  }
}

.tickets-tab-switch .nav-item .nav-link {
  display: flex;
  align-items: center;
  background: #eee7ef;
  padding: 20px;
  text-align: left;
  font-weight: 600;
  border: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-property: "background", "color";
}

.tickets-tab-switch .nav-item .nav-link .badge {
  margin-left: auto;
  background: #3366ff;
  color: #fff;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-property: "background", "color";
}

.tickets-tab-switch .nav-item .nav-link.active {
  background: #3366ff;
  border-radius: 4px;
  color: #fff;
  border: none;
}

.tickets-tab-switch .nav-item .nav-link.active .badge {
  background: #ffc107;
  color: #fff;
}

.tickets-date-group {
  display: flex;
  align-items: center;
  color: #7987a1;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 500;
}

.tickets-date-group i {
  margin-right: 10px;
  font-size: 20px;
}

.tickets-card {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #cdd4e0;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
  color: #031b4e;
  transition-duration: 0.3s;
  transition-property: "background";
}

.tickets-card .tickets-details {
  margin-right: auto;
}

.tickets-card .tickets-details .wrapper {
  display: flex;
  align-items: center;
}

.tickets-card .tickets-details .wrapper span {
  margin-left: 10px;
}

.tickets-card .tickets-details .wrapper span:first-child {
  margin-left: 0;
}

.tickets-card .tickets-details .wrapper span i {
  margin-right: 5px;
  font-size: 15px;
}

.tickets-card .tickets-details h5 {
  margin-bottom: 0;
}

.tickets-card .tickets-details .badge {
  margin-left: 15px;
}

.tickets-card .tickets-details .assignee-avatar {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-left: 10px;
}

.tickets-card .ticket-float {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  .tickets-card .ticket-float {
    margin-top: 20px;
  }
}

.tickets-card .ticket-float img,
.tickets-card .ticket-float .category-icon {
  margin-right: 10px;
}

.tickets-card .ticket-float .category-icon {
  font-size: 22px;
  color: #7987a1;
}

.tickets-card:hover {
  text-decoration: none;
  color: inherit;
  background: #fafafa;
}

@media (max-width: 567px) {
  .tickets-card {
    padding: 15px 0;
  }
}

.product-nav-wrapper {
  display: flex;
  align-items: center;
}

.product-nav-wrapper .product-filter-nav {
  list-style: none;
  margin-bottom: 30px;
}

.product-nav-wrapper .product-filter-nav li {
  display: inline-block;
}

.product-nav-wrapper .product-filter-nav li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  color: #031b4e;
}

.product-nav-wrapper .product-filter-nav li.active a {
  color: #3366ff;
}

.product-nav-wrapper .product-filter-options {
  margin-left: auto;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.product-nav-wrapper .product-filter-options .account-user-info {
  list-style: none;
  margin-bottom: 30px;
}

.product-nav-wrapper .product-filter-options .account-user-info li {
  display: inline-block;
}

.product-nav-wrapper .product-filter-options .account-user-info li a {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #031b4e;
  border-right: 1px solid #cdd4e0;
}

.product-nav-wrapper
  .product-filter-options
  .account-user-info
  li:last-child
  a {
  border-right: none;
}

.product-nav-wrapper .product-filter-options .account-user-info {
  list-style: none;
  margin-bottom: 30px;
}

.product-nav-wrapper .product-filter-options .account-user-info li {
  display: inline-block;
}

.product-nav-wrapper .product-filter-options .account-user-info li a {
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 1;
  color: #031b4e;
  border-right: 1px solid #cdd4e0;
  text-decoration: none;
}

.product-nav-wrapper
  .product-filter-options
  .account-user-info
  li:last-child
  a {
  border-right: none;
}

.product-nav-wrapper .product-filter-options .account-user-link {
  list-style: none;
  margin-bottom: 30px;
}

.product-nav-wrapper .product-filter-options .account-user-link li {
  display: inline-block;
}

.product-nav-wrapper .product-filter-options .account-user-link li a {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  color: #031b4e;
  text-decoration: none;
}

.product-nav-wrapper .product-filter-options .account-user-link li a:after {
  content: "/";
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.product-nav-wrapper
  .product-filter-options
  .account-user-link
  li:last-child
  a:after {
  display: none;
  margin-left: 0px;
}

.product-nav-wrapper .product-filter-options .account-user-actions {
  list-style: none;
  margin-bottom: 30px;
}

.product-nav-wrapper .product-filter-options .account-user-actions li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
}

.product-nav-wrapper .product-filter-options .account-user-actions li .badge {
  position: absolute;
  top: -12px;
  right: -7px;
}

.product-nav-wrapper .product-filter-options .account-user-actions li a {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  color: #031b4e;
  text-decoration: none;
}

.product-nav-wrapper
  .product-filter-options
  .account-user-actions
  li:last-child {
  margin-right: 0;
}

.product-item-wrapper {
  margin-bottom: -15px;
}

.product-item-wrapper .product-item {
  box-shadow: none;
  margin-bottom: 20px;
}

.product-item-wrapper .product-item .card {
  box-shadow: none;
  border: 1px solid #cdd4e0;
}

.product-item-wrapper .product-item .card-body {
  padding: 20px 25px;
  display: grid;
  position: relative;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
  grid-template-areas: "product-image product-image" "product-title product-price" "product-variation product-actual-price" "product-description product-description";
}

.product-item-wrapper .product-item .card-body .action-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 35px 40px;
  display: flex;
  align-items: center;
  z-index: 2;
}

.product-item-wrapper .product-item .card-body .action-holder .sale-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.product-item-wrapper .product-item .card-body .action-holder .favorite-button {
  margin-left: auto;
  color: #fff;
  font-size: 22px;
}

.product-item-wrapper .product-item .card-body .product-img-outer {
  width: 100%;
  grid-area: product-image;
  overflow: hidden;
  margin-bottom: 10px;
}

.product-item-wrapper
  .product-item
  .card-body
  .product-img-outer
  .product_image {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  transform: scale(1);
  z-index: 1;
  border-radius: 0px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-timing-function: "ease";
  transition-property: "transform", "border-radius", "box-shadow";
}

.product-item-wrapper
  .product-item
  .card-body
  .product-img-outer:hover
  .product_image {
  transform: scale(1.3);
  border-radius: 4px;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.58);
  overflow: hidden;
}

.product-item-wrapper .product-item .card-body .product-title {
  grid-area: product-title;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.product-item-wrapper .product-item .card-body .product-price {
  grid-area: product-price;
  font-size: 19px;
  font-weight: 500;
  text-align: right;
  margin-bottom: 10px;
}

.product-item-wrapper .product-item .card-body .product-actual-price {
  grid-area: product-actual-price;
  font-size: 15px;
  color: gray;
  text-align: right;
  text-decoration: line-through;
}

.product-item-wrapper .product-item .card-body .product-variation {
  grid-area: product-variation;
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
  padding-bottom: 0;
}

.product-item-wrapper .product-item .card-body .product-variation li {
  display: inline-block;
  border: 1px solid #cdd4e0;
  padding: 0px 15px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  transition-duration: 0.3s;
  transition-property: "background";
}

.product-item-wrapper .product-item .card-body .product-variation li a {
  color: inherit;
  text-decoration: none;
}

.product-item-wrapper .product-item .card-body .product-variation li:hover {
  background: #7987a1;
}

.product-item-wrapper .product-item .card-body .product-description {
  grid-area: product-description;
  line-height: 1.71;
  font-size: 0.875rem;
}

.project-list-showcase {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}

.project-list-showcase .project-grid {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 40px;
  overflow: hidden;
}

.project-list-showcase .project-grid .img-holder {
  width: 100%;
  height: 140px;
  border-radius: 4px 4px 0 0;
}

.project-list-showcase .project-grid .img-holder.img-bg-1 {
  //background: url("../../img/samples/300x300/1.jpg") no-repeat center;
  background-size: cover;
}

.project-list-showcase .project-grid .img-holder.img-bg-2 {
  //background: url("../../img/samples/300x300/2.jpg") no-repeat center;
  background-size: cover;
}

.project-list-showcase .project-grid .img-holder.img-bg-3 {
  //background: url("../../img/samples/300x300/3.jpg") no-repeat center;
  background-size: cover;
}

.project-list-showcase .project-grid .project-grid-inner {
  border: 1px solid #cdd4e0;
  border-radius: 0 0 4px 4px;
  padding: 30px 25px 30px 25px;
}

.project-list-showcase .project-grid .project-title {
  margin-bottom: 0;
  font-weight: 500;
}

.project-list-showcase .project-grid .project-location {
  margin-bottom: 0;
  font-weight: 500;
  color: #042467;
  margin-bottom: 15px;
}

.project-list-showcase .project-grid .action-tags {
  font-weight: 500;
  color: #031b4e;
}

.nav-pills.pricing-nav-pills-outline {
  justify-content: center;
}

.nav-pills.pricing-nav-pills-outline .nav-link {
  background-color: #fff;
  border: 1px solid #ececec;
  padding: 17px 26px;
  border-radius: 0;
}

.nav-pills.pricing-nav-pills-outline .nav-link:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.nav-pills.pricing-nav-pills-outline .nav-link:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.nav-pills.pricing-nav-pills-outline .nav-item :first-child:last-child {
  border-radius: 0;
}

.nav-pills.pricing-nav-pills-outline .nav-item:first-child .nav-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.nav-pills.pricing-nav-pills-outline .nav-item:last-child .nav-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.nav-pills.pricing-nav-pills-outline .show > .nav-link,
.nav-pills.pricing-nav-pills-outline .nav-link.active {
  background-color: #fff;
  border-color: #3bb001;
  color: #3bb001;
}

.nav-pills.pricing-nav-pills-boxed {
  justify-content: center;
}

.nav-pills.pricing-nav-pills-boxed .nav-link {
  border-style: solid;
  border-color: #f7f8fb;
  border-width: 6px 0;
  background-color: #f7f8fb;
  padding: 17px 26px;
  border-radius: 0;
}

.nav-pills.pricing-nav-pills-boxed .nav-link:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.nav-pills.pricing-nav-pills-boxed .nav-link:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.nav-pills.pricing-nav-pills-boxed .nav-item :first-child:last-child {
  border-radius: 0;
}

.nav-pills.pricing-nav-pills-boxed .nav-item:first-child .nav-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left-width: 10px;
}

.nav-pills.pricing-nav-pills-boxed .nav-item:last-child .nav-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right-width: 10px;
}

.nav-pills.pricing-nav-pills-boxed .show > .nav-link,
.nav-pills.pricing-nav-pills-boxed .nav-link.active {
  background-color: #fff;
  color: #000;
}

.nav-pills.pricing-nav-pills-switch {
  justify-content: center;
}

.nav-pills.pricing-nav-pills-switch .nav-link {
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 17px 30px;
  border-radius: 0;
}

.nav-pills.pricing-nav-pills-switch .nav-link:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.nav-pills.pricing-nav-pills-switch .nav-link:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.nav-pills.pricing-nav-pills-switch .nav-link:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.nav-pills.pricing-nav-pills-switch .nav-link:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.nav-pills.pricing-nav-pills-switch .nav-item :first-child:last-child {
  border-radius: 0;
}

.nav-pills.pricing-nav-pills-switch .nav-item:first-child .nav-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.nav-pills.pricing-nav-pills-switch .nav-item:last-child .nav-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.nav-pills.pricing-nav-pills-switch .show > .nav-link,
.nav-pills.pricing-nav-pills-switch .nav-link.active {
  background-color: #ededed;
  color: #000;
}

.pricing-card-stack {
  padding-top: 75px;
}

.pricing-card-stack .featured {
  z-index: 2;
}

@media (min-width: 991px) {
  .pricing-card-stack .featured {
    transform: scale(1.25);
  }
  .pricing-card-stack .featured .card-body {
    transform: scale(calc(1 / 1.25));
  }
}

.tab-pane.active {
  animation: slide-down 0.5s ease-out;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

ul.list-custom {
  list-style: none;
}

ul.list-custom li {
  position: relative;
}

ul.list-custom li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2335ac80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 16 16 12 12 8'%3E%3C/polyline%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: -35px;
  top: 2px;
}

ul.list-checked {
  list-style: none;
}

ul.list-checked li {
  position: relative;
  margin-bottom: 14px;
}

ul.list-checked li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  //background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fe3f3e' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check-circle'%3E%3Cpath d='M22 11.08V12a10 10 0 1 1-5.93-9.14'%3E%3C/path%3E%3Cpolyline points='22 4 12 14.01 9 11.01'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: -35px;
  top: 2px;
}

ul.list-arrow {
  list-style: none;
}

ul.list-arrow li {
  position: relative;
  margin-bottom: 14px;
}

ul.list-arrow li::before {
  content: "";
  display: inline-block;
  height: 20px;
  width: 20px;
  //background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2335ac80' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 16 16 12 12 8'%3E%3C/polyline%3E%3Cline x1='8' y1='12' x2='16' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: -35px;
  top: 2px;
}

ul.list-ckeck-soft {
  list-style: none;
}

ul.list-ckeck-soft li {
  position: relative;
  margin-bottom: 14px;
}

ul.list-ckeck-soft li::before {
  content: "";
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #9ceacd;
  position: absolute;
  left: -35px;
  top: 2px;
}

ul.list-ckeck-soft li::after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 6px;
  border: 2px solid transparent;
  border-color: transparent transparent #35ac80 #35ac80;
  position: absolute;
  left: -29px;
  top: 9px;
  transform: rotate(-45deg);
}

.card-shadow {
  box-shadow: 0 2px 30px 0 rgba(207, 207, 207, 0.5);
}

.mb-40px,
.my-40px {
  margin-bottom: 40px !important;
}

.mb-30px,
.my-30px {
  margin-bottom: 30px !important;
}

.avatar {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: calc(25px * 0.75);
  line-height: 1;
  border-radius: 3px;
  overflow: hidden;
}

.avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.avatar-text {
  background-color: #3366ff;
  color: #fff;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.avatar-rounded {
  border-radius: 50%;
}

.avatar-square {
  border-radius: 0;
}

.p-40px {
  padding: 40px !important;
}

.footer-nav .nav-link {
  color: #4e5d78;
  line-height: 1.69;
  padding-top: 0;
  padding-bottom: 0;
}

.feature-icon {
  display: inline-flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.feature-icon-lg {
  width: 60px;
  height: 60px;
}

.landing-sass-header-content {
  border-bottom-left-radius: 400px;
  padding-bottom: 260px;
  margin-bottom: -260px;
}

.landing-sass-header-img-wrapper {
  margin-bottom: 85px;
}

.lead-landing-section {
  padding-top: 105px;
  padding-bottom: 110px;
}

.landing-sass-testimonial-carousel .carousel-control-next,
.landing-sass-testimonial-carousel .carousel-control-prev {
  position: static;
  width: auto;
}

.landing-sass-testimonial-carousel .carousel-control-prev {
  margin-right: 16px;
}

.landing-business-media-list .list-item-number {
  display: inline-flex;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  background-color: #17a2b8;
  border-radius: 0.25rem;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
}

.media-icon {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #cdd4e0;
  color: #17a2b8;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.media-icon img {
  width: 16px;
  height: 16px;
}

.landing-app-header {
  background-color: #1565d8;
  //background-image: url("../../img/app-landing-page/bg.svg");
  background-repeat: no-repeat;
  background-position: 75% 50%;
}

.landing-app-header-content {
  position: relative;
  overflow: hidden;
  padding-top: 110px;
}

.landing-app-header-content::after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  height: 50%;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewY(-5.7deg) translateY(100%);
  transform-origin: left top;
}

/* ############### DASHBOARD STYLES ############### */

/* ###### 8.1 Dashboard One  ###### */

.az-content-dashboard {
  padding-top: 20px;
}

@media (min-width: 992px) {
  .az-content-dashboard {
    padding-top: 30px;
  }
}

.az-dashboard-one-title {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .az-dashboard-one-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .az-dashboard-one-title {
    margin-bottom: 30px;
  }
}

.az-dashboard-title {
  font-size: 22px;
  font-weight: 700;
  color: #1c273c;
  letter-spacing: -1px;
  margin-bottom: 3px;
}

.az-dashboard-text {
  font-size: 13px;
  margin-bottom: 0;
}

.az-dashboard-date {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

@media (min-width: 576px) {
  .az-dashboard-date {
    margin-top: 0;
  }
}

.az-dashboard-date .date {
  display: flex;
}

.az-dashboard-date .date > div:first-child {
  font-size: 32px;
  font-family: Arial, sans-serif;
  font-weight: 500;
  color: #1c273c;
  line-height: 0.9;
}

@media (min-width: 768px) {
  .az-dashboard-date .date > div:first-child {
    font-size: 36px;
  }
}

.az-dashboard-date .date > div:last-child {
  margin-left: 5px;
}

.az-dashboard-date .date > div:last-child span {
  display: block;
  color: #1c273c;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

@media (min-width: 768px) {
  .az-dashboard-date .date > div:last-child span {
    font-size: 13px;
  }
}

.az-dashboard-date .date > div:last-child span:last-child {
  color: #7987a1;
  font-weight: 400;
}

.az-dashboard-date > i {
  font-size: 21px;
  line-height: 0;
  display: block;
  margin: 0 15px;
  color: #97a3b9;
}

@media (min-width: 768px) {
  .az-dashboard-date > i {
    margin: 0 20px;
    font-size: 24px;
  }
}

.az-dashboard-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cdd4e0;
  //margin-bottom: 20px;
}

.az-dashboard-nav .nav-link {
  font-size: 12px;
  padding: 5px 10px 8px;
  display: flex;
  align-items: center;
  line-height: 1;
}

@media (min-width: 576px) {
  .az-dashboard-nav .nav-link {
    font-size: 0.875rem;
  }
}

@media (min-width: 992px) {
  .az-dashboard-nav .nav-link {
    padding: 5px 15px 10px;
  }
}

.az-dashboard-nav .nav-link:hover,
.az-dashboard-nav .nav-link:focus {
  color: #5b47fb;
}

.az-dashboard-nav .nav-link + .nav-link {
  border-left: 1px solid #cdd4e0;
}

.az-dashboard-nav .nav:first-child .nav-link {
  color: #1c273c;
  display: none;
}

@media (min-width: 576px) {
  .az-dashboard-nav .nav:first-child .nav-link {
    display: block;
  }
}

.az-dashboard-nav .nav:first-child .nav-link:hover,
.az-dashboard-nav .nav:first-child .nav-link:focus {
  color: #5b47fb;
}

.az-dashboard-nav .nav:first-child .nav-link.active {
  color: #5b47fb;
}

.az-dashboard-nav .nav:first-child .nav-link:first-child {
  padding-left: 0;
}

.az-dashboard-nav .nav:first-child .nav-link:first-child,
.az-dashboard-nav .nav:first-child .nav-link:last-child {
  display: block;
}

.az-dashboard-nav .nav:last-child .nav-link {
  color: #596882;
  display: none;
}

@media (min-width: 768px) {
  .az-dashboard-nav .nav:last-child .nav-link {
    display: block;
  }
}

.az-dashboard-nav .nav:last-child .nav-link:hover,
.az-dashboard-nav .nav:last-child .nav-link:focus {
  color: #5b47fb;
}

.az-dashboard-nav .nav:last-child .nav-link i {
  font-size: 16px;
  margin-right: 7px;
  line-height: 0;
}

.az-dashboard-nav .nav:last-child .nav-link:last-child {
  padding-right: 0;
  display: block;
}

.az-dashboard-nav .nav:last-child .nav-link:last-child i {
  margin-right: 0;
}

// @media (min-width: 768px) {
//   .az-dashboard-nav .nav:last-child .nav-link:last-child {
//     //display: none;
//   }
// }

.card-dashboard-one {
  position: relative;
  border-color: #cdd4e0;
}

.card-dashboard-one .card-header {
  padding: 20px;
  background-color: transparent;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.card-dashboard-one .card-header .card-title {
  color: #1c273c;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 3px;
}

.card-dashboard-one .card-header .card-text {
  margin-bottom: 0;
}

.card-dashboard-one .card-header .btn-group {
  margin-top: 15px;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-header .btn-group {
    margin-top: 0;
  }
}

.card-dashboard-one .card-header .btn-group .btn,
.card-dashboard-one .card-header .btn-group .sp-container button,
.sp-container .card-dashboard-one .card-header .btn-group button {
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  min-height: inherit;
  background-color: #fff;
  color: #7987a1;
  border: 1px solid #cdd4e0;
}

.card-dashboard-one .card-header .btn-group .btn:hover,
.card-dashboard-one .card-header .btn-group .sp-container button:hover,
.sp-container .card-dashboard-one .card-header .btn-group button:hover,
.card-dashboard-one .card-header .btn-group .btn:focus,
.card-dashboard-one .card-header .btn-group .sp-container button:focus,
.sp-container .card-dashboard-one .card-header .btn-group button:focus {
  background-color: #f4f5f8;
  border-color: #cdd4e0;
}

.card-dashboard-one .card-header .btn-group .btn + .btn,
.card-dashboard-one .card-header .btn-group .sp-container button + .btn,
.sp-container .card-dashboard-one .card-header .btn-group button + .btn,
.card-dashboard-one .card-header .btn-group .sp-container .btn + button,
.sp-container .card-dashboard-one .card-header .btn-group .btn + button,
.card-dashboard-one .card-header .btn-group .sp-container button + button,
.sp-container .card-dashboard-one .card-header .btn-group button + button {
  margin-left: -1px;
}

.card-dashboard-one .card-header .btn-group .btn.active,
.card-dashboard-one .card-header .btn-group .sp-container button.active,
.sp-container .card-dashboard-one .card-header .btn-group button.active {
  color: #1c273c;
  background-color: #e3e7ed;
  border-color: #cdd4e0;
}

.card-dashboard-one .card-body {
  padding: 10px 0 20px;
  position: relative;
  overflow: hidden;
}

.card-dashboard-one .card-body .flot-chart-wrapper {
  position: relative;
  margin-left: -28px;
  margin-right: -20px;
}

.card-dashboard-one .card-body .flot-chart {
  width: 100%;
  height: 150px;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body .flot-chart {
    height: 275px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-dashboard-one .card-body .flot-chart {
    height: 252px;
  }
}

.card-dashboard-one .card-body .flot-chart .flot-y-axis > div {
  transform: translateX(50px);
  text-shadow: 1px 1px rgba(255, 255, 255, 0.75);
  color: #031b4e;
  font-weight: 700;
  font-size: 11px;
}

.card-dashboard-one .card-body .flot-chart .flot-x-axis > div {
  color: #97a3b9;
  font-weight: 500;
  font-size: 11px;
}

.card-dashboard-one .card-body-top {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top {
    // position: absolute;
    top: 0;
    left: 20px;
    z-index: 5;
    padding-left: 0;
  }
}

.card-dashboard-one .card-body-top > div {
  flex-basis: 50%;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top > div {
    flex-basis: auto;
  }
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top > div + div {
    margin-left: 30px;
  }
}

.card-dashboard-one .card-body-top label {
  font-size: 12px;
  margin-bottom: 3px;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top label {
    font-size: 0.875rem;
  }
}

.card-dashboard-one .card-body-top h2 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
}

@media (min-width: 576px) {
  .card-dashboard-one .card-body-top h2 {
    font-size: 24px;
    letter-spacing: -0.5px;
  }
}

.card-dashboard-two {
  height: 100%;
  border-radius: 0;
  border-color: #cdd4e0;
  background-color: rgba(255, 255, 255, 0.5);
}

.card-dashboard-two .card-header {
  background-color: transparent;
  padding: 20px 20px 0;
}

.card-dashboard-two .card-header h6 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  display: flex;
  align-items: baseline;
  margin-bottom: 2px;
  line-height: 1;
  letter-spacing: -1px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-dashboard-two .card-header h6 {
    font-size: 24px;
  }
}

.card-dashboard-two .card-header h6 i {
  margin-left: 10px;
  font-size: 24px;
}

.card-dashboard-two .card-header h6 small {
  font-size: 12px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-left: 5px;
  color: #7987a1;
  letter-spacing: normal;
}

.card-dashboard-two .card-header p {
  margin-bottom: 0;
  font-size: 13px;
}

.card-dashboard-two .card-body {
  padding: 0;
  overflow: hidden;
}

.card-dashboard-two .chart-wrapper {
  position: relative;
  margin: 0 -10px -10px;
}

.card-dashboard-two .flot-chart {
  width: 100%;
  height: 100px;
}

.card-dashboard-three {
  height: 150px;
  border-radius: 0;
  border-color: #cdd4e0;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .card-dashboard-three {
    height: 170px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-three {
    height: 100%;
  }
}

.card-dashboard-three .card-header {
  background-color: transparent;
  padding: 20px;
  position: absolute;
  max-width: 50%;
}

.card-dashboard-three .card-header h6 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
  line-height: 1;
  letter-spacing: -1px;
}

@media (min-width: 1200px) {
  .card-dashboard-three .card-header h6 {
    font-size: 32px;
  }
}

.card-dashboard-three .card-header h6 small {
  font-size: 12px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-left: 5px;
  letter-spacing: normal;
}

.card-dashboard-three .card-header p {
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1c273c;
}

.card-dashboard-three .card-header > small {
  font-size: 11px;
  display: none;
  color: #7987a1;
}

@media (min-width: 768px) {
  .card-dashboard-three .card-header > small {
    display: block;
  }
}

.card-dashboard-three .card-body {
  padding: 0 0 0 20px;
  display: flex;
  justify-content: flex-end;
}

.card-dashboard-three .chart {
  width: 70%;
  height: 150px;
}

@media (min-width: 375px) {
  .card-dashboard-three .chart {
    width: auto;
  }
}

@media (min-width: 768px) {
  .card-dashboard-three .chart {
    height: 170px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-three .chart {
    height: 200px;
    width: 80%;
  }
}

.card-dashboard-pageviews {
  border-color: #cdd4e0;
  border-radius: 0;
  padding: 20px;
}

.card-dashboard-pageviews .card-header {
  background-color: transparent;
  padding: 0 0 10px;
}

.card-dashboard-pageviews .card-title {
  font-weight: 700;
  font-size: 14px;
  color: #1c273c;
  margin-bottom: 5px;
}

.card-dashboard-pageviews .card-text {
  font-size: 13px;
  margin-bottom: 0;
}

.card-dashboard-pageviews .card-body {
  padding: 0;
}

.card-dashboard-four {
  border-radius: 0;
  border-color: #cdd4e0;
  padding: 20px;
  height: 100%;
}

.card-dashboard-four .card-header {
  padding: 0 0 20px;
  background-color: transparent;
}

.card-dashboard-four .card-title {
  font-weight: 700;
  font-size: 14px;
  color: #1c273c;
  margin-bottom: 0;
}

.card-dashboard-four .card-body {
  padding: 0;
}

@media (min-width: 992px) {
  .card-dashboard-four .card-body {
    padding: 0 20px;
  }
}

.card-dashboard-four .chart {
  width: calc(1920px - 80px);
  height: 200px;
}

@media (min-width: 375px) {
  .card-dashboard-four .chart {
    width: auto;
  }
}

@media (min-width: 992px) {
  .card-dashboard-four .chart {
    height: 85%;
  }
}

.card-dashboard-five {
  border-color: #cdd4e0;
  padding: 20px;
}

.card-dashboard-five .card-header {
  padding: 0;
  background-color: transparent;
}

.card-dashboard-five .card-title {
  font-weight: 700;
  font-size: 14px;
  color: #1c273c;
  margin-bottom: 5px;
}

.card-dashboard-five .card-text {
  display: block;
  font-size: 12px;
}

.card-dashboard-five .card-body {
  padding: 20px 0 5px;
}

.card-dashboard-five .card-body label {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}

.card-dashboard-five .card-body h4 {
  color: #1c273c;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 0;
}

.card-dashboard-five .card-chart {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-dashboard-five .card-chart {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.card-table-one {
  padding: 20px 20px 10px;
  border-color: #cdd4e0;
}

.card-table-one .card-title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.card-table-one .table-responsive {
  width: calc(1920px - 80px);
}

@media (min-width: 576px) {
  .card-table-one .table-responsive {
    width: 100%;
  }
}

.card-table-one .table {
  margin-bottom: 0;
}

.card-table-one .table thead tr > th,
.card-table-one .table thead tr > td,
.card-table-one .table tbody tr > th,
.card-table-one .table tbody tr > td {
  vertical-align: middle;
  white-space: nowrap;
  padding-right: 2px;
}

@media (min-width: 768px) {
  .card-table-one .table thead tr > th,
  .card-table-one .table thead tr > td,
  .card-table-one .table tbody tr > th,
  .card-table-one .table tbody tr > td {
    padding-left: 0;
    padding-right: 0;
  }
}

.card-table-one .table thead tr > th:nth-child(3),
.card-table-one .table thead tr > th:nth-child(4),
.card-table-one .table thead tr > th:nth-child(5),
.card-table-one .table thead tr > td:nth-child(3),
.card-table-one .table thead tr > td:nth-child(4),
.card-table-one .table thead tr > td:nth-child(5),
.card-table-one .table tbody tr > th:nth-child(3),
.card-table-one .table tbody tr > th:nth-child(4),
.card-table-one .table tbody tr > th:nth-child(5),
.card-table-one .table tbody tr > td:nth-child(3),
.card-table-one .table tbody tr > td:nth-child(4),
.card-table-one .table tbody tr > td:nth-child(5) {
  text-align: right;
}

.card-table-one .table thead tr > th strong,
.card-table-one .table thead tr > td strong,
.card-table-one .table tbody tr > th strong,
.card-table-one .table tbody tr > td strong {
  color: #1c273c;
}

.card-table-one .flag-icon {
  font-size: 20px;
  line-height: 1;
  border-radius: 100%;
}

/* ###### 8.2 Dashboard Two  ###### */

.az-content-dashboard-two {
  background-color: #fdfdfd;
  transition: all 0.4s;
}

@media (prefers-reduced-motion: reduce) {
  .az-content-dashboard-two {
    transition: none;
  }
}

.az-content-dashboard-two .az-content-title {
  letter-spacing: -0.5px;
}

.az-content-dashboard-two .az-content-body {
  padding: 0 20px;
}

@media (min-width: 992px) {
  .az-content-dashboard-two .az-content-body {
    padding: 0 20px 20px;
  }
}

.az-content-dashboard-two .az-header-menu-icon {
  margin-right: 0;
}

.az-dashboard-header-right {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 576px) {
  .az-dashboard-header-right {
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .az-dashboard-header-right {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .az-dashboard-header-right > div {
    text-align: right;
  }
}

.az-dashboard-header-right > div label {
  display: block;
  margin-bottom: 5px;
}

.az-dashboard-header-right > div h5 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  margin-bottom: 0;
  line-height: 1;
}

.az-dashboard-header-right > div:first-child {
  flex-basis: 100%;
}

.az-dashboard-header-right > div + div {
  margin-top: 10px;
}

@media (max-width: 575.98px) {
  .az-dashboard-header-right > div + div {
    flex-basis: 50%;
  }
}

@media (min-width: 576px) {
  .az-dashboard-header-right > div + div {
    margin-left: 25px;
    margin-top: 0;
    flex-shrink: 0;
  }
}

@media (min-width: 992px) {
  .az-dashboard-header-right > div + div {
    margin-left: 50px;
  }
}

.az-star {
  display: flex;
  align-items: center;
}

.az-star i {
  font-size: 20px;
  line-height: 0;
  color: #97a3b9;
  display: inline-block;
}

.az-star i.typcn {
  line-height: 0.9;
}

.az-star i.active {
  color: #ffc107;
}

.az-star span {
  font-size: 13px;
  display: inline-block;
  margin-left: 5px;
}

.card-dashboard-seven {
  border-color: #cdd4e0;
  margin-bottom: 20px;
  width: 1920px;
  border-left-width: 0;
  border-right-width: 0;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
}

@media (min-width: 576px) {
  .card-dashboard-seven {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    width: auto;
    border-left-width: 1px;
    border-right-width: 1px;
  }
}

.card-dashboard-seven .card-header {
  background-color: transparent;
  padding: 20px;
  border-bottom: 1px solid #cdd4e0;
  background-color: #f4f5f8;
}

@media (min-width: 576px) {
  .card-dashboard-seven .card-header {
    padding: 20px;
  }
}

.card-dashboard-seven .card-header .media {
  align-items: center;
}

.card-dashboard-seven .card-header .media > div:first-child {
  line-height: 0;
  font-size: 16px;
  margin-right: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 34px;
  background-color: #97a3b9;
}

@media (min-width: 576px) {
  .card-dashboard-seven .card-header .media > div:first-child {
    width: 34px;
    font-size: 24px;
    margin-right: 8px;
  }
}

.card-dashboard-seven .card-header .media-body {
  margin-top: 3px;
}

.card-dashboard-seven .card-header label {
  margin-bottom: 0;
  display: block;
  line-height: 1;
  font-size: 11px;
  color: #97a3b9;
}

.card-dashboard-seven .card-header .date {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.card-dashboard-seven .card-header .date span {
  display: block;
}

.card-dashboard-seven .card-header .date a {
  margin-left: 5px;
  font-size: 14px;
  color: #97a3b9;
  display: block;
}

.card-dashboard-seven .card-header .date a i {
  line-height: 0;
  position: relative;
  top: 1px;
}

.card-dashboard-seven .card-body {
  padding: 20px;
}

.card-dashboard-seven .card-body .row > div + div {
  position: relative;
}

.card-dashboard-seven .card-body .row > div + div::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1px;
  bottom: 0;
  border-left: 1px dotted #cdd4e0;
  display: none;
}

@media (min-width: 992px) {
  .card-dashboard-seven .card-body .row > div + div::before {
    display: block;
  }
}

.card-dashboard-seven .card-body .az-content-label,
.card-dashboard-seven .card-body .card-table-two .card-title,
.card-table-two .card-dashboard-seven .card-body .card-title,
.card-dashboard-seven .card-body .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-seven .card-body .card-title {
  text-transform: none;
  color: #031b4e;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.card-dashboard-seven .card-body h2 {
  color: #1c273c;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -1px;
  margin-bottom: 5px;
  line-height: 1;
}

@media (min-width: 576px) {
  .card-dashboard-seven .card-body h2 {
    font-size: 32px;
  }
}

.card-dashboard-seven .card-body h2 span {
  font-weight: 400;
  color: #031b4e;
  letter-spacing: normal;
}

.card-dashboard-seven .card-body .desc {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

.card-dashboard-seven .card-body .desc > i {
  line-height: 0;
  font-size: 18px;
}

.card-dashboard-seven .card-body .desc span {
  display: block;
  line-height: 1;
  margin-left: 5px;
}

.card-dashboard-seven .card-body .desc span strong {
  color: #1c273c;
}

.card-dashboard-seven .card-body .desc.up > i {
  color: #3bb001;
}

.card-dashboard-seven .card-body .desc.down > i {
  color: #dc3545;
}

.card-dashboard-six {
  border-color: #cdd4e0;
  padding: 20px;
}

@media (min-width: 992px) {
  .card-dashboard-six {
    height: 100%;
  }
}

.card-dashboard-six .card-header {
  padding: 0;
  background-color: transparent;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-six .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .card-dashboard-six .card-header {
    margin-bottom: 30px;
  }
}

.card-dashboard-six .az-content-label,
.card-dashboard-six .card-table-two .card-title,
.card-table-two .card-dashboard-six .card-title,
.card-dashboard-six .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-six .card-title {
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.card-dashboard-six .chart-legend {
  margin-top: 20px;
  display: flex;
}

@media (min-width: 576px) {
  .card-dashboard-six .chart-legend {
    display: block;
    margin-top: 0;
  }
}

.card-dashboard-six .chart-legend > div {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
}

.card-dashboard-six .chart-legend > div + div {
  margin-top: 2px;
}

.card-dashboard-six .chart-legend > div span:last-child {
  width: 8px;
  height: 8px;
  margin-left: 5px;
}

@media (min-width: 576px) {
  .card-dashboard-six .chart-legend > div span:last-child {
    margin-left: 10px;
    width: 20px;
  }
}

.card-dashboard-six .chart-legend > div span:first-child {
  width: 100px;
  text-align: right;
}

.card-dashboard-map-one {
  border-color: #cdd4e0;
  padding: 20px;
}

@media (min-width: 992px) {
  .card-dashboard-map-one {
    height: 100%;
  }
}

.card-dashboard-map-one .az-content-label,
.card-dashboard-map-one .card-table-two .card-title,
.card-table-two .card-dashboard-map-one .card-title,
.card-dashboard-map-one .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-map-one .card-title {
  font-size: 0.875rem;
}

.card-dashboard-map-one .vmap-wrapper {
  height: 200px;
}

@media (min-width: 576px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 300px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 260px;
  }
}

.card-table-two {
  border-color: #cdd4e0;
  padding: 20px;
}

.card-table-two .card-title {
  font-size: 13px;
  margin-bottom: 5px;
}

.card-table-two .table-responsive {
  width: calc(1920px - 62px);
}

@media (min-width: 576px) {
  .card-table-two .table-responsive {
    width: 100%;
  }
}

.table-dashboard-two thead > tr > th,
.table-dashboard-two thead > tr > td,
.table-dashboard-two tbody > tr > th,
.table-dashboard-two tbody > tr > td {
  white-space: nowrap;
}

.table-dashboard-two tbody > tr > th:not(:first-child),
.table-dashboard-two tbody > tr > td:not(:first-child) {
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  font-size: 13px;
}

.card-dashboard-eight {
  border-color: #cdd4e0;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
}

@media (min-width: 768px) {
  .card-dashboard-eight {
    height: 100%;
  }
}

.card-dashboard-eight .card-title {
  font-size: 13px;
  margin-bottom: 5px;
}

.card-dashboard-eight .list-group-item {
  padding: 10px 0;
  border-left-width: 0;
  border-right-width: 0;
  border-style: dotted;
  border-color: #cdd4e0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.card-dashboard-eight .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-dashboard-eight .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-dashboard-eight .list-group-item p {
  margin-bottom: 0;
  margin-left: 10px;
}

.card-dashboard-eight .list-group-item span {
  display: block;
  margin-left: auto;
  font-weight: 600;
  font-family: "Archivo", Arial, sans-serif;
  color: #1c273c;
}

.card-dashboard-eight .flag-icon {
  font-size: 24px;
  line-height: 1;
  border-radius: 100%;
}

/* ###### 8.3 Dashboard Three  ###### */

.az-content-dashboard-three {
  position: relative;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fdfdfd;
}

@media (min-width: 992px) {
  .az-content-dashboard-three {
    border-left: 1px solid #cdd4e0;
    margin-left: 64px;
  }
}

.az-header-dashboard-three {
  height: auto;
}

.az-header-dashboard-three .container-fluid,
.az-header-dashboard-three .container-sm,
.az-header-dashboard-three .container-md,
.az-header-dashboard-three .container-lg,
.az-header-dashboard-three .container-xl {
  padding: 15px 20px;
}

@media (min-width: 992px) {
  .az-header-dashboard-three .az-header-left {
    display: none;
  }
}

.az-header-dashboard-three .az-header-center {
  margin-left: 0;
}

.az-header-dashboard-three .az-header-right {
  margin-left: auto;
}

.az-content-body-dashboard-three {
  padding: 20px;
  position: relative;
  width: 100%;
}

.az-content-body-dashboard-three .az-content-title {
  letter-spacing: -0.5px;
}

.card-dashboard-nine {
  border-color: #cdd4e0;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  padding: 20px 20px 15px;
}

.card-dashboard-nine .card-header {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  padding: 0;
  background-color: transparent;
  z-index: 5;
}

.card-dashboard-nine .card-header h1 {
  font-size: 32px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 8px;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #1c273c;
}

.card-dashboard-nine .az-content-label,
.card-dashboard-nine .card-table-two .card-title,
.card-table-two .card-dashboard-nine .card-title,
.card-dashboard-nine .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-nine .card-title {
  font-size: 13px;
  margin-bottom: 10px;
  color: #031b4e;
}

.card-dashboard-nine .card-title {
  font-weight: 600;
}

.card-dashboard-nine .az-content-text {
  color: #7987a1;
}

.card-dashboard-nine .bar-chart {
  position: relative;
  width: auto;
  height: 200px;
}

@media (min-width: 992px) {
  .card-dashboard-nine .bar-chart {
    height: 210px;
  }
}

.card-dashboard-ten {
  border-width: 0;
  padding: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-ten {
    width: calc(50% - 11px);
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .card-dashboard-ten {
    width: auto;
    display: block;
  }
}

.card-dashboard-ten + .card-dashboard-ten {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-ten + .card-dashboard-ten {
    margin-top: 0;
    margin-left: 18px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-ten + .card-dashboard-ten {
    margin-left: 0;
    margin-top: 20px;
  }
}

.card-dashboard-ten .az-content-label,
.card-dashboard-ten .card-table-two .card-title,
.card-table-two .card-dashboard-ten .card-title,
.card-dashboard-ten .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-ten .card-title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1.05;
  color: #fff;
}

.card-dashboard-ten .card-body {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-dashboard-ten .card-body > div {
  flex: 1;
}

@media (min-width: 992px) {
  .card-dashboard-ten .card-body > div {
    flex: none;
  }
}

@media (min-width: 1360px) {
  .card-dashboard-ten .card-body > div:first-child {
    flex-basis: 60%;
  }
  .card-dashboard-ten .card-body > div:last-child {
    flex-basis: 40%;
  }
}

.card-dashboard-ten .card-body > div label {
  font-size: 13px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.75);
}

.card-dashboard-ten .card-body > div h6 {
  margin-bottom: 2px;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 0.975;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 992px) {
  .card-dashboard-ten .card-body > div h6 {
    font-size: 24px;
  }
}

.card-dashboard-ten .card-body > div h6 span {
  font-size: 13px;
}

.card-dashboard-ten .card-body > div h6 .percent {
  font-size: 20px;
}

.card-dashboard-eleven {
  border-color: #cdd4e0;
  position: relative;
  overflow: hidden;
}

.card-dashboard-eleven .card-header {
  padding: 20px;
  background-color: transparent;
  position: relative;
  z-index: 200;
}

.card-dashboard-eleven .az-content-label,
.card-dashboard-eleven .card-table-two .card-title,
.card-table-two .card-dashboard-eleven .card-title,
.card-dashboard-eleven .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-eleven .card-title {
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
  color: #3b4863;
  line-height: 1.4;
}

.card-dashboard-eleven .card-text {
  color: #7987a1;
}

.card-dashboard-eleven .card-body {
  padding: 0 0 5px;
  margin-left: 20px;
  position: relative;
  z-index: 200;
}

.card-dashboard-eleven .flot-chart {
  width: 100%;
  height: 250px;
}

.card-dashboard-eleven .card-footer {
  z-index: 200;
  background-color: transparent;
  padding: 0;
  border-width: 0;
}

@media (min-width: 768px) {
  .card-dashboard-eleven .card-footer {
    display: flex;
    align-items: stretch;
  }
}

.card-dashboard-eleven .card-footer > div {
  flex: 1;
  padding: 15px 20px;
  border-top: 1px solid #cdd4e0;
  position: relative;
}

@media (min-width: 768px) {
  .card-dashboard-eleven .card-footer > div + div {
    border-left: 1px solid #cdd4e0;
  }
}

.card-dashboard-eleven .card-footer label {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #596882;
  display: block;
}

.card-dashboard-eleven .card-footer h6 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  line-height: 0.7;
  letter-spacing: -1px;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}

.card-dashboard-eleven .card-footer h6 span {
  margin-left: 5px;
  font-size: 11px;
  font-weight: 500;
  display: block;
  padding: 4px 6px;
  color: #fff;
  line-height: 0.8;
  position: relative;
  bottom: 3px;
  letter-spacing: normal;
}

.card-dashboard-eleven .card-footer h6 span.up {
  background-color: #3bb001;
}

.card-dashboard-eleven .card-footer h6 span.down {
  background-color: #dc3545;
}

.card-dashboard-eleven .card-footer small {
  display: block;
  font-size: 13px;
  color: #596882;
}

.card-dashboard-eleven .jqvmap {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 300px;
}

@media (min-width: 992px) {
  .card-dashboard-eleven .jqvmap {
    height: 80%;
  }
}

/* ###### 8.4 Dashboard Four  ###### */

.az-navbar-dashboard-four {
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

@media (min-width: 992px) {
  .az-navbar-dashboard-four .container-fluid,
  .az-navbar-dashboard-four .container-sm,
  .az-navbar-dashboard-four .container-md,
  .az-navbar-dashboard-four .container-lg,
  .az-navbar-dashboard-four .container-xl {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.az-content-dashboard-four {
  padding: 20px;
}

@media (min-width: 992px) {
  .az-content-dashboard-four {
    flex: 1;
    padding: 30px 25px;
  }
}

.az-content-dashboard-four ~ .az-footer .container,
.az-content-dashboard-four ~ .az-footer .container-fluid,
.az-content-dashboard-four ~ .az-footer .container-sm,
.az-content-dashboard-four ~ .az-footer .container-md,
.az-content-dashboard-four ~ .az-footer .container-lg,
.az-content-dashboard-four ~ .az-footer .container-xl {
  padding-left: 25px;
  padding-right: 25px;
}

.az-content-dashboard-four > .media-dashboard {
  display: block;
}

@media (min-width: 1200px) {
  .az-content-dashboard-four > .media-dashboard {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
}

.az-content-dashboard-four > .media-dashboard .media-aside {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .az-content-dashboard-four > .media-dashboard .media-aside {
    margin-top: 0;
    display: block;
    width: 320px;
  }
}

@media (min-width: 1200px) {
  .az-content-dashboard-four > .media-dashboard > .media-body {
    padding: 0 20px 0 0;
  }
}

.az-content-dashboard-four .az-content-header {
  height: auto;
  margin-bottom: 20px;
  align-items: flex-start;
  padding: 0;
}

@media (min-width: 768px) {
  .az-content-dashboard-four .az-content-header {
    display: flex;
  }
}

.az-content-header-right {
  display: none;
}

@media (min-width: 992px) {
  .az-content-header-right {
    display: flex;
    align-items: center;
  }
}

.az-content-header-right .media label {
  margin-bottom: 2px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #97a3b9;
}

.az-content-header-right .media h6 {
  color: #1c273c;
  margin-bottom: 0;
}

.az-content-header-right .media + .media {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #cdd4e0;
}

.az-content-header-right .btn:first-of-type,
.az-content-header-right .sp-container button:first-of-type,
.sp-container .az-content-header-right button:first-of-type {
  margin-left: 30px;
}

.az-content-header-right .btn + .btn,
.az-content-header-right .sp-container button + .btn,
.sp-container .az-content-header-right button + .btn,
.az-content-header-right .sp-container .btn + button,
.sp-container .az-content-header-right .btn + button,
.az-content-header-right .sp-container button + button,
.sp-container .az-content-header-right button + button {
  margin-left: 5px;
}

.card-dashboard-calendar {
  border-color: #cdd4e0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-calendar .card-title {
  color: #1c273c;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.card-dashboard-calendar .card-body {
  padding: 0;
}

.card-dashboard-calendar .ui-datepicker-inline {
  border-width: 0;
  max-width: none;
  padding: 0;
  margin: 0;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header {
  border-bottom: 1px solid #cdd4e0;
  padding: 10px;
  margin-bottom: 5px;
}

.card-dashboard-calendar
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next::before,
.card-dashboard-calendar
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev::before {
  top: 8px;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-month {
  color: #5b47fb;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
  width: calc(100% - 20px);
}

@media (min-width: 576px) {
  .card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
    width: 100%;
  }
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar th {
  font-size: 10px;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar th,
.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td a {
  display: block;
  width: 34px;
  height: 34px;
  font-size: 12px;
  font-family: "Archivo", sans-serif;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-dashboard-calendar
  .ui-datepicker
  .ui-datepicker-calendar
  .ui-datepicker-today
  a {
  background-color: transparent;
  border: 2px solid #3366ff;
  color: #3366ff;
  font-weight: 700;
}

.card-dashboard-twelve {
  border-color: #cdd4e0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-twelve .card-header {
  background-color: transparent;
  border-bottom: 1px solid #e3e7ed;
  padding: 20px;
}

.card-dashboard-twelve .card-title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
}

.card-dashboard-twelve .card-title span {
  font-weight: 400;
  color: #031b4e;
  text-transform: capitalize;
}

.card-dashboard-twelve .sales-overview {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media (min-width: 992px) {
  .card-dashboard-twelve .sales-overview {
    margin-bottom: 10px;
    flex-wrap: nowrap;
  }
}

.card-dashboard-twelve .sales-overview .media {
  align-items: flex-end;
  flex-shrink: 0;
  flex-basis: calc(50% - 10px);
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview .media {
    flex-basis: auto;
  }
}

.card-dashboard-twelve .sales-overview .media + .media {
  margin-left: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview .media + .media {
    margin-left: 40px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .sales-overview .media + .media {
    margin-left: 50px;
  }
}

@media (max-width: 575.98px) {
  .card-dashboard-twelve .sales-overview .media:nth-child(3) {
    margin-left: 0;
    margin-top: 15px;
  }
}

.card-dashboard-twelve .sales-overview .media:last-child {
  display: none;
}

@media (min-width: 768px) {
  .card-dashboard-twelve .sales-overview .media:last-child {
    flex: 1;
    display: block;
  }
}

.card-dashboard-twelve .sales-overview .media-icon {
  width: 45px;
  height: 45px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -5px;
  margin-right: 15px;
  border-radius: 100%;
  display: none;
}

.card-dashboard-twelve .sales-overview .media-icon .typcn {
  line-height: 0.9;
  font-size: 24px;
}

.card-dashboard-twelve .sales-overview label {
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #031b4e;
  margin-bottom: 8px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview label {
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.card-dashboard-twelve .sales-overview h4 {
  font-size: 20px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  color: #1c273c;
  line-height: 1;
  letter-spacing: -1px;
  margin-bottom: 3px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview h4 {
    font-size: 28px;
  }
}

.card-dashboard-twelve .sales-overview h4 span {
  color: #031b4e;
  font-weight: 400;
}

.card-dashboard-twelve .sales-overview .media-body > span {
  display: block;
  font-size: 10px;
  color: #97a3b9;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .sales-overview .media-body > span {
    font-size: 11px;
  }
}

.card-dashboard-twelve .sales-overview .media-body > span strong {
  font-weight: 500;
  color: #031b4e;
}

.card-dashboard-twelve .sales-overview p {
  font-size: 11px;
  margin-bottom: 0;
  color: #97a3b9;
}

.card-dashboard-twelve .card-body {
  position: relative;
  padding: 20px 5px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .card-body {
    padding: 20px;
  }
}

.card-dashboard-twelve .chart-legend {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .chart-legend {
    top: 65px;
  }
}

.card-dashboard-twelve .chart-legend div {
  display: flex;
  align-items: center;
}

.card-dashboard-twelve .chart-legend div + div {
  margin-left: 15px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .chart-legend div + div {
    margin-left: 30px;
  }
}

.card-dashboard-twelve .chart-legend span {
  width: 10px;
  height: 4px;
  display: block;
  margin-right: 5px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .chart-legend span {
    width: 40px;
    margin-right: 10px;
  }
}

.card-dashboard-twelve .chart-wrapper {
  position: relative;
  margin-left: -13px;
  margin-right: -13px;
}

@media (min-width: 576px) {
  .card-dashboard-twelve .chart-wrapper {
    margin-left: -10px;
    margin-right: -15px;
  }
}

.card-dashboard-twelve .flot-chart {
  width: 100%;
  height: 200px;
  position: relative;
}

@media (min-width: 768px) {
  .card-dashboard-twelve .flot-chart {
    height: 250px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .flot-chart {
    height: 300px;
  }
}

.card-dashboard-twelve .flot-chart .flot-x-axis > div span:first-child {
  display: block;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  font-size: 10px;
  color: #7987a1;
}

.card-dashboard-twelve .flot-chart .flot-x-axis > div span:last-child {
  display: block;
  font-size: 16px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  line-height: 1;
}

.az-media-date h1 {
  font-size: 42px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  line-height: 0.95;
}

.az-media-date .media-body {
  margin-left: 5px;
}

.az-media-date p {
  color: #1c273c;
  font-weight: 700;
  margin-bottom: 0;
  text-transform: uppercase;
}

.az-media-date span {
  display: block;
  line-height: 1.2;
}

.card-dashboard-thirteen {
  border-color: #cdd4e0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-thirteen .az-content-label,
.card-dashboard-thirteen .card-table-two .card-title,
.card-table-two .card-dashboard-thirteen .card-title,
.card-dashboard-thirteen .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-thirteen .card-title {
  font-size: 13px;
}

.card-dashboard-thirteen .media {
  margin-bottom: 15px;
}

.card-dashboard-thirteen .media-icon {
  font-size: 45px;
  line-height: 1;
  margin-right: 10px;
  display: flex;
}

.card-dashboard-thirteen .media-icon i {
  line-height: 0;
}

.card-dashboard-thirteen .media-body h6 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  letter-spacing: -0.5px;
  margin-bottom: 2px;
}

.card-dashboard-thirteen .media-body h6 span {
  font-weight: 400;
  color: #031b4e;
}

.card-dashboard-thirteen .media-body label {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: block;
  letter-spacing: 0.5px;
}

.card-dashboard-events {
  border-color: #cdd4e0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-events .card-header {
  padding: 20px;
  background-color: transparent;
}

.card-dashboard-events .card-title {
  color: #7987a1;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.card-dashboard-events .card-subtitle {
  color: #1c273c;
  font-weight: 400;
  font-size: 18px;
}

.card-dashboard-events .card-body {
  padding: 0;
}

.card-dashboard-events .list-group-item {
  padding: 15px 20px 15px 40px;
  border-left-width: 0;
  border-right-width: 0;
  position: relative;
}

.card-dashboard-events .list-group-item:first-child,
.card-dashboard-events .list-group-item:last-child {
  border-radius: 0;
}

.card-dashboard-events .list-group-item label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  color: #596882;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}

.card-dashboard-events .list-group-item label span {
  color: #97a3b9;
  font-weight: 500;
}

.card-dashboard-events .list-group-item h6 {
  font-size: 14px;
  font-weight: 700;
  color: #1c273c;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.card-dashboard-events .list-group-item p {
  margin-bottom: 5px;
}

.card-dashboard-events .list-group-item p strong {
  font-weight: 500;
}

.card-dashboard-events .list-group-item small {
  font-size: 11px;
}

.card-dashboard-events .list-group-item small span {
  font-weight: 700;
  text-transform: uppercase;
}

.card-dashboard-events .event-indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 21px;
  left: 20px;
  border-radius: 100%;
}

.card-dashboard-events .event-user {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.card-dashboard-events .event-user .az-img-user {
  width: 28px;
  height: 28px;
}

.card-dashboard-events .event-user .az-img-user::after {
  display: none;
}

.card-dashboard-events .event-user .az-img-user + .az-img-user {
  margin-left: -6px;
}

.card-dashboard-events .event-user a:last-child {
  margin-left: 10px;
  color: #7987a1;
  font-weight: 500;
  font-size: 11px;
}

.card-dashboard-events .event-user a:last-child:hover,
.card-dashboard-events .event-user a:last-child:focus {
  color: #596882;
}

.card-dashboard-fourteen {
  border-color: #cdd4e0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
  padding: 20px;
}

@media (min-width: 768px) {
  .card-dashboard-fourteen {
    height: 100%;
  }
}

.card-dashboard-fourteen .az-content-label,
.card-dashboard-fourteen .card-table-two .card-title,
.card-table-two .card-dashboard-fourteen .card-title,
.card-dashboard-fourteen .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-fourteen .card-title {
  font-size: 0.875rem;
  margin-bottom: 20px;
}

.card-dashboard-fourteen .az-content-label span,
.card-dashboard-fourteen .card-table-two .card-title span,
.card-table-two .card-dashboard-fourteen .card-title span,
.card-dashboard-fourteen .card-dashboard-eight .card-title span,
.card-dashboard-eight .card-dashboard-fourteen .card-title span {
  font-weight: 400;
  color: #97a3b9;
  text-transform: none;
}

.card-dashboard-fourteen .card-body {
  padding: 0;
  min-height: 100px;
}

.card-dashboard-fourteen .card-body h1 {
  color: #1c273c;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 0;
}

.card-dashboard-fourteen .card-body h1 span {
  color: #97a3b9;
  letter-spacing: normal;
  font-weight: 400;
  margin-right: 5px;
}

.card-dashboard-fourteen .card-body h4 {
  color: #1c273c;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0;
  letter-spacing: -0.5px;
}

.card-dashboard-fourteen .card-body .progress {
  margin-bottom: 2px;
}

.card-dashboard-fourteen .card-body small {
  font-size: 11px;
  color: #97a3b9;
}

.card-dashboard-fourteen .sparkline-wrapper canvas {
  width: 100% !important;
}

.card-dashboard-fourteen .card-footer {
  background-color: transparent;
  padding: 0;
  margin-top: 15px;
  border-top-width: 0;
}

.card-dashboard-fourteen .card-footer h6 {
  color: #1c273c;
  font-size: 20px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0;
  letter-spacing: -0.5px;
}

.card-dashboard-fourteen .card-footer label {
  display: block;
  margin-bottom: 0;
  color: #97a3b9;
  font-size: 11px;
}

@media (min-width: 576px) {
  .card-dashboard-fourteen .card-footer label {
    font-size: 0.875rem;
  }
}

/* ###### 8.5 Dashboard Five  ###### */

.az-body-sidebar.az-light .az-sidebar {
  background-color: #f4f5f8;
  border-right-color: #cdd4e0;
  box-shadow: 2px 0 3px rgba(28, 39, 60, 0.04);
}

.az-content-dashboard-five {
  background-color: #eeeff4;
  transition: all 0.4s;
}

@media (prefers-reduced-motion: reduce) {
  .az-content-dashboard-five {
    transition: none;
  }
}

.az-content-dashboard-five .az-header {
  background-color: #fcfcfc;
  box-shadow: 0 2px 3px rgba(28, 39, 60, 0.05);
}

.az-content-dashboard-five .az-header-menu-icon {
  margin-right: 0;
}

.az-content-dashboard-five .az-header-center .form-control {
  border-color: #cdd4e0;
}

.az-content-dashboard-five .az-content-header {
  padding: 20px 20px 0;
}

@media (min-width: 1200px) {
  .az-content-dashboard-five .az-content-header {
    padding: 25px 25px 0;
  }
}

.az-content-dashboard-five .az-content-body {
  padding: 20px;
}

.az-content-dashboard-five .card {
  background-color: #fcfcfc;
  border-color: #cdd4e0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.az-content-dashboard-five .card-header {
  padding: 20px 20px 0;
  background-color: transparent;
}

.az-content-dashboard-five .card-text {
  font-size: 12px;
  line-height: 1.4;
  color: #97a3b9;
  margin-bottom: 0;
}

.card-dashboard-fifteen {
  padding: 20px 20px 0;
  overflow: hidden;
}

.card-dashboard-fifteen h1 {
  font-size: 40px;
  font-weight: 500;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  margin-bottom: 0;
  line-height: 1;
}

.card-dashboard-fifteen h1 span {
  display: inline;
  font-size: 18px;
  color: #7987a1;
}

.card-dashboard-fifteen label {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.card-dashboard-fifteen span {
  color: #97a3b9;
  display: block;
  font-size: 12px;
}

.card-dashboard-fifteen .chart-wrapper {
  position: relative;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: -10px;
}

.card-dashboard-fifteen .flot-chart {
  width: 100%;
  height: 127px;
  position: relative;
  margin-top: 20px;
}

.card-dashboard-fifteen .flot-chart .flot-x-axis > div:nth-child(4) {
  color: #1c273c !important;
}

.table-talk-time {
  margin-bottom: 0;
}

.table-talk-time thead > tr th,
.table-talk-time thead > tr td,
.table-talk-time tbody > tr th,
.table-talk-time tbody > tr td {
  padding: 7px 10px;
}

.table-talk-time thead > tr th:first-child,
.table-talk-time thead > tr td:first-child,
.table-talk-time tbody > tr th:first-child,
.table-talk-time tbody > tr td:first-child {
  padding-left: 20px;
}

.card-dashboard-sixteen .az-img-user::after {
  display: none;
}

.card-dashboard-sixteen .table tbody > tr {
  background-color: transparent;
}

.card-dashboard-sixteen .table tbody > tr th,
.card-dashboard-sixteen .table tbody > tr td {
  padding: 10px 10px 10px 0;
  vertical-align: middle;
  white-space: nowrap;
}

.card-dashboard-sixteen .table tbody > tr th:first-child,
.card-dashboard-sixteen .table tbody > tr td:first-child {
  width: 10%;
}

.card-dashboard-sixteen .table tbody > tr th:nth-child(2),
.card-dashboard-sixteen .table tbody > tr td:nth-child(2) {
  width: 60%;
}

.card-dashboard-sixteen .table tbody > tr th:last-child,
.card-dashboard-sixteen .table tbody > tr td:last-child {
  width: 30%;
}

.dashboard-five-stacked-chart {
  height: 200px;
}

@media (min-width: 768px) {
  .dashboard-five-stacked-chart {
    height: 240px;
  }
}

@media (min-width: 847px) {
  .dashboard-five-stacked-chart {
    height: 260px;
  }
}

@media (min-width: 992px) {
  .dashboard-five-stacked-chart {
    height: 240px;
  }
}

@media (min-width: 1067px) {
  .dashboard-five-stacked-chart {
    height: 260px;
  }
}

/* ###### 8.6 Dashboard Six  ###### */

.az-light .az-iconbar {
  background-color: #e3e7ed;
}

.az-light .az-iconbar-aside {
  background-color: #e3e7ed;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .az-light .az-iconbar-aside.show + .az-content {
    box-shadow: none;
  }
}

.az-light .az-iconbar-body .nav {
  border-left-color: #cdd4e0;
}

.az-light .az-iconbar-body .nav-item + .nav-item {
  border-top-color: #bdc6d6;
}

.az-light .az-iconbar-body .nav-sub {
  border-top-color: #bdc6d6;
}

.az-light .az-iconbar-body .nav-sub-item + .nav-sub-item {
  border-top-color: #bdc6d6;
}

.az-content-dashboard-six {
  border-left-color: #cdd4e0;
  box-shadow: 0 0 7px rgba(28, 39, 60, 0.08);
  position: relative;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #f4f5f8;
}

@media (min-width: 992px) {
  .az-content-dashboard-six {
    border-left: 1px solid #cdd4e0;
    margin-left: 64px;
    z-index: 550;
  }
}

.az-header-dashboard-six {
  background-color: #fff;
  border-bottom: 1px solid #cdd4e0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .az-header-dashboard-six .az-header-center {
    margin-left: 5px;
  }
}

@media (min-width: 1200px) {
  .az-header-dashboard-six .az-header-center {
    margin-left: 0;
  }
}

.az-header-dashboard-six .az-header-menu-icon {
  margin-right: 0;
}

@media (min-width: 992px) {
  .az-header-dashboard-six .az-header-menu-icon {
    display: none;
  }
}

.az-content-body-dashboard-six {
  padding: 25px;
}

.az-content-body-dashboard-six .az-content-title {
  letter-spacing: -0.8px;
}

.az-content-body-dashboard-six .card {
  border-color: #cdd4e0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.06);
}

.az-content-body-dashboard-six .card-header {
  background-color: transparent;
  padding: 20px 20px 0;
}

.az-content-body-dashboard-six .card-title {
  text-transform: uppercase;
  font-weight: 700;
}

.card-dashboard-donut .card-body {
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-dashboard-donut .az-donut-chart.chart1 {
  width: 140px;
  height: 140px;
  background: #cdd4e0;
}

.card-dashboard-donut .az-donut-chart.chart1 .slice.one {
  clip: rect(0 140px 70px 0);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: #6f42c1;
}

.card-dashboard-donut .az-donut-chart.chart1 .slice.two {
  clip: rect(0 70px 140px 0);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  background: #6f42c1;
}

.card-dashboard-donut .az-donut-chart.chart1 .chart-center {
  top: 10px;
  left: 10px;
  width: 120px;
  height: 120px;
  background: #fff;
}

.card-dashboard-donut .az-donut-chart.chart1 .chart-center span {
  font-size: 40px;
  line-height: 120px;
  color: #6f42c1;
}

.card-dashboard-donut .az-donut-chart.chart1 .chart-center span:after {
  content: "75%";
}

.card-dashboard-donut .az-donut-chart.chart2 {
  width: 140px;
  height: 140px;
  background: #cdd4e0;
}

.card-dashboard-donut .az-donut-chart.chart2 .slice.one {
  clip: rect(0 140px 70px 0);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: #3366ff;
}

.card-dashboard-donut .az-donut-chart.chart2 .slice.two {
  clip: rect(0 70px 140px 0);
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
  background: #3366ff;
}

.card-dashboard-donut .az-donut-chart.chart2 .chart-center {
  top: 10px;
  left: 10px;
  width: 120px;
  height: 120px;
  background: #fff;
}

.card-dashboard-donut .az-donut-chart.chart2 .chart-center span {
  font-size: 40px;
  line-height: 120px;
  color: #3366ff;
}

.card-dashboard-donut .az-donut-chart.chart2 .chart-center span:after {
  content: "68%";
}

.card-dashboard-donut .az-donut-chart.chart1 .chart-center span,
.card-dashboard-donut .az-donut-chart.chart2 .chart-center span {
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 32px;
}

.card-dashboard-balance {
  position: relative;
  overflow: hidden;
}

@media (min-width: 576px) {
  .card-dashboard-balance {
    min-height: 260px;
    height: 100%;
  }
}

.card-dashboard-balance .card-body {
  padding: 20px;
  position: relative;
  z-index: 5;
}

@media (min-width: 576px) {
  .card-dashboard-balance .card-body {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.card-dashboard-balance .fab {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 48px;
  color: #3366ff;
  z-index: 5;
  background-color: #fff;
}

.card-dashboard-balance .az-content-label,
.card-dashboard-balance .card-table-two .card-title,
.card-table-two .card-dashboard-balance .card-title,
.card-dashboard-balance .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-balance .card-title {
  color: #7987a1;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

@media (min-width: 576px) {
  .card-dashboard-balance .az-content-label,
  .card-dashboard-balance .card-table-two .card-title,
  .card-table-two .card-dashboard-balance .card-title,
  .card-dashboard-balance .card-dashboard-eight .card-title,
  .card-dashboard-eight .card-dashboard-balance .card-title {
    font-size: 11px;
  }
}

.card-dashboard-balance .balance-amount {
  color: #1c273c;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-balance .balance-amount {
    font-size: 36px;
  }
}

.card-dashboard-balance .balance-amount span {
  color: #97a3b9;
  font-weight: 400;
  letter-spacing: normal;
}

.card-dashboard-balance .account-number {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-balance .account-number {
    font-size: 28px;
  }
}

.card-dashboard-balance .account-number span {
  display: flex;
  align-items: center;
  line-height: 1;
}

.card-dashboard-balance .account-number span + span {
  margin-left: 20px;
}

@media (min-width: 576px) {
  .card-dashboard-balance .account-number span + span {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .card-dashboard-balance .account-number span + span {
    margin-left: 50px;
  }
}

.card-dashboard-balance .account-number span i {
  width: 5px;
  height: 5px;
  background-color: #1c273c;
  border-radius: 100%;
}

@media (min-width: 576px) {
  .card-dashboard-balance .account-number span i {
    width: 10px;
    height: 10px;
  }
}

.card-dashboard-balance .account-number span i + i {
  margin-left: 5px;
}

.card-dashboard-balance .account-name {
  color: #1c273c;
  font-weight: 400;
  margin-bottom: 0;
}

.card-dashboard-balance .chart-wrapper {
  position: absolute;
  top: 20px;
  bottom: 10px;
  left: -10px;
  right: -10px;
  opacity: 0.3;
  z-index: 4;
}

.card-dashboard-balance .flot-chart {
  width: 100%;
  height: 100%;
}

@media (min-width: 1200px) {
  .card-dashboard-ratio {
    height: 350px;
  }
}

.card-dashboard-ratio .card-body {
  display: flex;
  align-items: center;
}

.card-dashboard-ratio .card-body + .card-body {
  padding-top: 0px;
}

.card-dashboard-ratio .card-body > div:first-child {
  width: 140px;
  display: none;
  align-items: center;
  justify-content: center;
}

@media (min-width: 576px) {
  .card-dashboard-ratio .card-body > div:first-child {
    display: flex;
  }
}

.card-dashboard-ratio .card-body > div:last-child {
  flex: 1;
}

@media (min-width: 576px) {
  .card-dashboard-ratio .card-body > div:last-child {
    margin-left: 30px;
  }
}

.card-dashboard-ratio .card-body h5 {
  font-size: 28px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  letter-spacing: -1px;
  margin-bottom: 2px;
}

.card-dashboard-ratio .card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cdd4e0;
  font-size: 140px;
}

.card-dashboard-ratio .card-icon .typcn {
  line-height: 1;
}

.card-dashboard-ratio .card-icon .typcn::before {
  width: auto;
}

.card-dashboard-finance {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (min-width: 992px) {
  .card-dashboard-finance {
    min-height: 172px;
  }
}

@media (min-width: 1200px) {
  .card-dashboard-finance {
    min-height: 165px;
  }
}

.card-dashboard-finance .card-title {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .card-dashboard-finance .card-title {
    margin-bottom: auto;
  }
}

.card-dashboard-finance h2 {
  line-height: 1;
  color: #1c273c;
  font-weight: 600;
  font-size: 30px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: -1px;
  margin-top: 10px;
  margin-bottom: 2px;
}

.card-dashboard-finance h2 span {
  letter-spacing: normal;
  color: #97a3b9;
  font-weight: 400;
  margin-right: 5px;
}

.card-dashboard-table-six .card-title {
  padding: 15px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #cdd4e0;
}

.card-dashboard-table-six .table-responsive {
  width: calc(1920px - 42px);
}

@media (min-width: 992px) {
  .card-dashboard-table-six .table-responsive {
    width: 100%;
  }
}

.card-dashboard-table-six .table {
  margin-bottom: 0;
}

.card-dashboard-table-six .table thead tr th {
  white-space: nowrap;
}

.card-dashboard-table-six .table thead tr:first-child {
  background-color: #e3e7ed;
}

.card-dashboard-table-six .table thead tr:first-child th {
  padding: 10px 12px;
  line-height: 1;
}

.card-dashboard-table-six .table thead tr:first-child th:last-child {
  border-left: 1px solid #cdd4e0;
}

.card-dashboard-table-six .table thead tr:last-child th {
  width: 13%;
  padding: 10px 12px;
  line-height: 1;
  text-align: right;
}

.card-dashboard-table-six .table thead tr:last-child th:first-child {
  width: 22%;
}

.card-dashboard-table-six .table thead tr:last-child th:nth-child(2) {
  border-left: 1px solid #cdd4e0;
}

.card-dashboard-table-six .table thead tr:last-child th:nth-child(6) {
  border-left: 1px solid #cdd4e0;
}

.card-dashboard-table-six .table tbody tr td {
  text-align: right;
  white-space: nowrap;
}

.card-dashboard-table-six .table tbody tr td:first-child {
  text-align: left;
}

.card-dashboard-table-six .table tbody tr td:nth-child(2) {
  border-left: 1px solid #cdd4e0;
}

.card-dashboard-table-six .table tbody tr td:nth-child(6) {
  border-left: 1px solid #cdd4e0;
}

.card-dashboard-table-six .table tbody tr:last-child td {
  font-weight: 500;
  color: #1c273c;
}

/* ###### 8.7 Dashboard Seven  ###### */

.az-minimal {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
}

@media (min-width: 1200px) {
  .az-minimal {
    padding: 10px 0 0;
  }
}

.az-minimal .az-navbar-three .nav-item.active::before {
  bottom: -3px;
}

.az-minimal .az-navbar-three .nav-item.active .nav-link {
  font-weight: 700;
}

.az-minimal .az-navbar-three .nav-link {
  font-size: 13px;
}

.az-minimal.az-navbar-show .az-header {
  z-index: auto;
}

.az-content-title-minimal {
  color: #1c273c;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 8px;
}

.az-content-title-minimal span {
  font-weight: 700;
}

.az-content-text-minimal {
  font-weight: 400;
  color: #596882;
  margin-bottom: 20px;
}

.media-dashboard-one {
  display: block;
}

@media (min-width: 992px) {
  .media-dashboard-one {
    display: flex;
    align-items: stretch;
  }
}

.media-dashboard-one .media-right {
  width: 100%;
  flex-shrink: 0;
}

@media (min-width: 992px) {
  .media-dashboard-one .media-right {
    width: 280px;
  }
}

.media-dashboard-one .media-body {
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .media-dashboard-one .media-body {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.card-minimal-one {
  border-color: #cdd4e0;
  min-height: 320px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.card-minimal-one .card-body {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 5;
}

.card-minimal-one .card-body > label {
  font-weight: 700;
  font-size: 14px;
  color: #5b47fb;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
  line-height: 1;
}

.card-minimal-one .card-body > label span {
  font-weight: 500;
  color: #596882;
}

.card-minimal-one .card-body > small {
  font-size: 12px;
  display: block;
  color: #97a3b9;
}

.card-minimal-one .card-body > h6 {
  color: #1c273c;
  font-size: 36px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: -2px;
  display: flex;
  line-height: 1;
  align-items: flex-end;
  margin: 15px 0;
}

.card-minimal-one .card-body > h6 span {
  font-weight: 400;
  margin-right: 3px;
  color: #031b4e;
}

.card-minimal-one .value-info {
  display: flex;
  align-items: center;
}

.card-minimal-one .value-info + .value-info {
  margin-top: 5px;
}

.card-minimal-one .value-info p {
  margin-bottom: 0;
  color: #1c273c;
  font-weight: 700;
  font-family: "Archivo", sans-serif;
  font-size: 14px;
  letter-spacing: -0.35px;
}

.card-minimal-one .value-info p span {
  letter-spacing: normal;
  margin-right: 2px;
  color: #031b4e;
}

.card-minimal-one .value-info label {
  display: block;
  margin-bottom: 0;
  font-weight: 700;
  width: 40px;
  position: relative;
  top: -2px;
}

.card-minimal-one .open-info {
  display: flex;
  align-items: flex-start;
  margin-top: auto;
  margin-bottom: 25px;
}

.card-minimal-one .open-info > div + div {
  margin-left: 25px;
}

.card-minimal-one .open-info label {
  font-size: 12px;
  margin-bottom: 2px;
  color: #7f8da9;
}

.card-minimal-one .open-info h6 {
  margin-bottom: 0;
  color: #1c273c;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 13px;
}

.card-minimal-one .open-info h6 span {
  font-weight: 400;
}

.card-minimal-one .chart-wrapper {
  position: absolute;
  left: -9px;
  right: -8px;
  bottom: -13px;
}

.card-minimal-one .chart-wrapper .flot-x-axis > div {
  transform: translate(20px, -23px);
}

.card-minimal-one .flot-chart {
  width: 100%;
  height: 250px;
  position: relative;
}

.card-minimal-two {
  height: 100%;
  border-color: #cdd4e0;
  background-color: transparent;
  overflow: hidden;
}

.card-minimal-two .card-header {
  background-color: transparent;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cdd4e0;
}

@media (min-width: 576px) {
  .card-minimal-two .card-header {
    display: flex;
    padding: 15px 20px;
  }
}

.card-minimal-two .card-header > .nav .nav-link {
  padding: 8px 18px;
  font-weight: 500;
  background-color: #e3e7ed;
}

@media (min-width: 992px) {
  .card-minimal-two .card-header > .nav .nav-link {
    padding: 8px 20px;
  }
}

@media (min-width: 1200px) {
  .card-minimal-two .card-header > .nav .nav-link {
    padding: 8px 30px;
  }
}

.card-minimal-two .card-header > .nav .nav-link + .nav-link {
  margin-left: 2px;
}

.card-minimal-two .card-header > .nav .nav-link:hover,
.card-minimal-two .card-header > .nav .nav-link:focus {
  background-color: #cdd4e0;
}

.card-minimal-two .card-header > .nav .nav-link.active {
  background-color: #5b47fb;
  color: #fff;
}

.card-minimal-two .card-header-right {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

@media (min-width: 576px) {
  .card-minimal-two .card-header-right {
    margin-top: 0;
  }
}

.card-minimal-two .card-header-right .nav {
  justify-content: flex-end;
  width: 100%;
}

.card-minimal-two .card-header-right .nav-link {
  padding: 5px 10px;
  text-transform: capitalize;
}

@media (min-width: 576px) {
  .card-minimal-two .card-header-right .nav-link {
    padding: 8px 10px;
  }
}

.card-minimal-two .card-header-right .nav-link + .nav-link {
  margin-left: 2px;
}

.card-minimal-two .card-header-right .nav-link span:first-child {
  display: none;
}

@media (min-width: 768px) {
  .card-minimal-two .card-header-right .nav-link span:first-child {
    display: inline;
  }
}

.card-minimal-two .card-header-right .nav-link span:last-child {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .card-minimal-two .card-header-right .nav-link span:last-child {
    display: none;
  }
}

.card-minimal-two .card-header-right .nav-link:hover,
.card-minimal-two .card-header-right .nav-link:focus {
  background-color: #e3e7ed;
}

.card-minimal-two .card-header-right .nav-link.active {
  background-color: #97a3b9;
  color: #fff;
}

.card-minimal-two .card-body {
  position: relative;
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .card-minimal-two .card-body {
    padding: 0 20px;
  }
}

.card-minimal-two .flot-wrapper {
  position: relative;
  bottom: -14px;
  margin-right: -9px;
  margin-left: -31px;
  margin-top: -25px;
}

@media (min-width: 992px) {
  .card-minimal-two .flot-wrapper {
    margin-right: -9px;
    margin-left: 10px;
    margin-top: 0;
  }
}

.card-minimal-two .flot-wrapper .flot-x-axis > div {
  transform: translate(22px, -23px);
}

.card-minimal-two .flot-wrapper .flot-y-axis > div {
  transform: translateX(-10px);
}

.card-minimal-two .flot-chart {
  width: 100%;
  height: 250px;
  position: relative;
}

@media (min-width: 992px) {
  .card-minimal-two .flot-chart {
    height: 300px;
  }
}

.card-minimal-two .more-info {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 576px) {
  .card-minimal-two .more-info {
    right: 20px;
    top: 30px;
  }
}

@media (min-width: 992px) {
  .card-minimal-two .more-info {
    right: 30px;
    top: 50px;
  }
}

.card-minimal-two .more-info div {
  display: flex;
  align-items: flex-start;
  font-size: 13px;
}

.card-minimal-two .more-info span:first-child {
  width: 80px;
  text-align: right;
  color: #7987a1;
  display: block;
  line-height: 1.35;
}

.card-minimal-two .more-info span:last-child {
  margin-left: 15px;
  color: #1c273c;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
}

.card-minimal-two .card-footer {
  padding: 20px;
  position: relative;
  z-index: 2;
  overflow-y: hidden;
  width: calc(1920px - 40px);
  margin-bottom: -8px;
}

@media (min-width: 992px) {
  .card-minimal-two .card-footer {
    width: auto;
  }
}

.card-minimal-two .card-footer .media-icon {
  color: #f7931a;
  font-size: 42px;
  line-height: 0;
}

.card-minimal-two .card-footer .media-body {
  margin-left: 20px;
  margin-bottom: 10px;
}

.card-minimal-two .card-footer .row {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
}

.card-minimal-two .card-footer .row > div {
  flex-shrink: 0;
}

.card-minimal-two .card-footer label {
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.5px;
  color: #7987a1;
}

.card-minimal-two .card-footer p {
  margin-bottom: 0;
  color: #1c273c;
  font-weight: 700;
  font-size: 16px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-minimal-three {
  border-color: #cdd4e0;
  width: calc(1920px - 38px);
}

@media (min-width: 992px) {
  .card-minimal-three {
    width: 634px;
  }
}

@media (min-width: 1200px) {
  .card-minimal-three {
    width: 753px;
  }
}

.card-minimal-three .card-header {
  border-bottom: 1px solid #cdd4e0;
  background-color: transparent;
  padding: 20px 20px 0;
}

.card-minimal-three .card-header .az-content-text {
  margin-bottom: 20px;
}

.card-minimal-three .az-nav-tabs {
  padding: 0;
  background-color: transparent;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 3px;
}

.card-minimal-three .az-nav-tabs .lSAction > a {
  background-color: #cdd4e0;
}

.card-minimal-three .az-nav-tabs .lSAction > a::before {
  top: -4px;
}

.card-minimal-three .az-nav-tabs .lSAction > a.lSPrev {
  left: -35px;
}

.card-minimal-three .az-nav-tabs .tab-item {
  min-width: 203px;
}

@media (min-width: 576px) {
  .card-minimal-three .az-nav-tabs .tab-item {
    min-width: 150px;
  }
}

.card-minimal-three .az-nav-tabs .tab-link {
  background-color: #e3e7ed;
  font-weight: 500;
  align-items: flex-start;
  padding-top: 8px;
}

.card-minimal-three .az-nav-tabs .tab-link:hover {
  background-color: #cdd4e0;
}

.card-minimal-three .az-nav-tabs .tab-link.active,
.card-minimal-three .az-nav-tabs .tab-link.active:hover,
.card-minimal-three .az-nav-tabs .tab-link.active:focus {
  background-color: #596882;
  color: #fff;
}

.card-minimal-three .card-body {
  padding: 0;
}

.card-minimal-three .tab-pane-header {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #cdd4e0;
}

@media (min-width: 576px) {
  .card-minimal-three .tab-pane-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.card-minimal-three .tab-pane-header .az-content-label,
.card-minimal-three .tab-pane-header .card-table-two .card-title,
.card-table-two .card-minimal-three .tab-pane-header .card-title,
.card-minimal-three .tab-pane-header .card-dashboard-eight .card-title,
.card-dashboard-eight .card-minimal-three .tab-pane-header .card-title {
  margin-bottom: 3px;
}

.card-minimal-three .tab-pane-header > div:last-child {
  display: flex;
  margin-top: 15px;
}

.card-minimal-three .tab-pane-header > div:last-child a {
  flex: 1;
}

.card-minimal-three .tab-pane-header > div:last-child a + a {
  margin-left: 10px;
}

.card-minimal-three .tab-pane-body {
  padding: 20px;
}

.card-minimal-three .tab-pane-body .table thead tr th,
.card-minimal-three .tab-pane-body .table thead tr td,
.card-minimal-three .tab-pane-body .table tbody tr th,
.card-minimal-three .tab-pane-body .table tbody tr td {
  white-space: nowrap;
}

.card-minimal-three .no-transactions-panel {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-minimal-three .no-transactions-panel .icon-wrapper {
  width: 80px;
  height: 80px;
  background-color: #e3e7ed;
  color: #97a3b9;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 100%;
}

.card-minimal-three .no-transactions-panel .icon-wrapper i {
  line-height: 0;
}

.card-minimal-three .no-transactions-panel h6 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  color: #1c273c;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.card-minimal-three .table tbody tr td {
  vertical-align: middle;
}

.card-minimal-three .table tbody tr td span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.card-minimal-three .table tbody tr td:last-child {
  font-weight: 500;
  color: #1c273c;
}

.card-crypto-portfolio {
  width: 100%;
  border-color: #cdd4e0;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .card-crypto-portfolio {
    width: calc(33.33333% - 13px);
    margin-top: 0;
  }
}

.card-crypto-portfolio .card-title {
  text-transform: uppercase;
}

.card-crypto-portfolio .table {
  margin-bottom: 0;
}

.card-crypto-portfolio .table thead tr th,
.card-crypto-portfolio .table thead tr td,
.card-crypto-portfolio .table tbody tr th,
.card-crypto-portfolio .table tbody tr td {
  padding: 10px 0;
  text-align: right;
  white-space: nowrap;
}

.card-crypto-portfolio .table thead tr th:first-child,
.card-crypto-portfolio .table thead tr td:first-child,
.card-crypto-portfolio .table tbody tr th:first-child,
.card-crypto-portfolio .table tbody tr td:first-child {
  text-align: left;
}

.card-crypto-portfolio .table thead tr th {
  font-size: 10px;
}

.card-crypto-portfolio .table tbody tr td:first-child {
  font-weight: 500;
  color: #1c273c;
}

.card-minimal-four {
  border-color: #cdd4e0;
}

.card-minimal-four .card-header {
  background-color: transparent;
  border-bottom: 1px solid #cdd4e0;
}

.card-minimal-four .card-header h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 2px;
  color: #1c273c;
}

.card-minimal-four .card-header p {
  margin-bottom: 0;
  white-space: nowrap;
}

.card-minimal-four .card-header p span {
  color: #dc3545;
}

.card-minimal-four .card-header .media {
  align-items: center;
}

.card-minimal-four .card-header .media-body {
  margin-left: 12px;
}

.card-minimal-four .coin-logo {
  width: 42px;
  height: 42px;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.card-minimal-four .coin-logo i {
  line-height: 0;
}

.card-minimal-four .card-body {
  padding: 0;
  overflow: hidden;
  position: relative;
  background-color: #fcfcfc;
}

.card-minimal-four .card-body-top {
  top: 15px;
  left: 10px;
  position: absolute;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 10;
}

.card-minimal-four .card-body-top a {
  color: #1c273c;
  font-weight: 700;
  width: 20px;
  text-align: right;
  display: inline-block;
}

.card-minimal-four .card-body-top a:hover,
.card-minimal-four .card-body-top a:focus {
  color: #3366ff;
}

.card-minimal-four .card-body-top div + div {
  margin-top: 5px;
}

.card-minimal-four .chart-wrapper {
  position: relative;
  margin-left: -20px;
  margin-right: -9px;
  margin-bottom: -17px;
}

.card-minimal-four .flot-chart {
  width: 100%;
  height: 150px;
}

.card-minimal-four .card-footer {
  background-color: transparent;
  border-top-color: #cdd4e0;
  padding: 0;
}

.card-minimal-four .card-footer .nav-link {
  flex: 1;
  display: block;
  text-align: center;
  background-color: #e3e7ed;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.card-minimal-four .card-footer .nav-link:hover,
.card-minimal-four .card-footer .nav-link:focus {
  background-color: #eeeff4;
}

.card-minimal-four .card-footer .nav-link span {
  display: block;
}

.card-minimal-four .card-footer .nav-link span:first-child {
  color: #97a3b9;
  font-weight: 700;
  font-size: 11px;
}

.card-minimal-four .card-footer .nav-link span:last-child {
  color: #031b4e;
  font-weight: 500;
  font-size: 11px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-minimal-four .card-footer .nav-link + .nav-link {
  border-left: 1px solid #cdd4e0;
}

.card-minimal-four .card-footer .nav-link.active {
  background-color: #fcfcfc;
}

.card-minimal-four .card-footer .nav-link.active::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  border-top: 1px solid #f7f7f7;
  z-index: 5;
}

.card-minimal-four .card-footer .nav-link.active span:first-child {
  color: #1c273c;
}

/* ###### 8.8 Dashboard Eight  ###### */

.az-dashboard-eight {
  background-color: #dde2e9;
}

.az-header-primary {
  background-color: #0040ff;
}

.az-header-primary .az-header-center .form-control {
  background-color: #0033cc;
  border-width: 0;
  height: 38px;
  border-radius: 2px;
  color: #fff;
}

.az-header-primary .az-header-center .form-control::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.az-header-primary .az-header-center .form-control:focus + .btn,
.az-header-primary .az-header-center .sp-container .form-control:focus + button,
.sp-container
  .az-header-primary
  .az-header-center
  .form-control:focus
  + button {
  color: #fff;
}

.az-header-primary .az-header-center .btn,
.az-header-primary .az-header-center .sp-container button,
.sp-container .az-header-primary .az-header-center button {
  font-size: 14px;
}

.az-header-primary .az-header-center .btn:hover,
.az-header-primary .az-header-center .sp-container button:hover,
.sp-container .az-header-primary .az-header-center button:hover,
.az-header-primary .az-header-center .btn:focus,
.az-header-primary .az-header-center .sp-container button:focus,
.sp-container .az-header-primary .az-header-center button:focus {
  color: #fff;
}

.az-header-primary .az-logo {
  color: #fff;
  font-weight: 600;
  letter-spacing: -1.2px;
}

.az-header-primary .az-logo span {
  color: rgba(255, 255, 255, 0.65);
}

.az-header-primary .az-header-menu-icon span,
.az-header-primary .az-header-menu-icon span::before,
.az-header-primary .az-header-menu-icon span::after {
  background-color: rgba(255, 255, 255, 0.65);
}

.az-header-primary .az-header-message > a,
.az-header-primary .az-header-notification > a {
  color: rgba(255, 255, 255, 0.75);
}

.az-header-primary .az-header-notification > a::after {
  display: none;
}

.az-header-primary .az-header-notification .dropdown-menu,
.az-header-primary .az-profile-menu .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
}

@media (min-width: 576px) {
  .az-header-primary .az-header-notification .dropdown-menu {
    top: 43px;
  }
}

@media (min-width: 992px) {
  .az-header-primary .az-header-notification .dropdown-menu {
    top: 45px;
  }
}

@media (min-width: 576px) {
  .az-header-primary .az-profile-menu .dropdown-menu {
    top: 46px;
  }
}

@media (min-width: 992px) {
  .az-header-primary .az-profile-menu .dropdown-menu {
    top: 48px;
  }
}

.az-header-primary .az-profile-menu.show .az-img-user::before {
  display: none;
}

.az-navbar-dashboard-eight {
  background-color: #f9f9f9;
  border-bottom-width: 0;
}

.az-navbar-dashboard-eight .az-navbar-header {
  background-color: #0040ff;
  border-bottom-width: 0;
  color: #fff;
}

.az-navbar-dashboard-eight .az-navbar-header .az-logo {
  color: #fff;
}

.az-navbar-dashboard-eight .nav-item.active::before {
  border-bottom-color: #1a53ff;
}

.az-navbar-dashboard-eight .nav-item.active .nav-link {
  color: #1a53ff;
}

.az-navbar-dashboard-eight .nav-link {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
}

.az-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
  background-color: #f9f9f9;
  border-width: 0;
}

@media (min-width: 992px) {
  .az-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega) {
    box-shadow: 0 0 5px rgba(28, 39, 60, 0.12);
  }
}

.az-navbar-dashboard-eight .nav-sub:not(.nav-sub-mega)::before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  height: 5px;
  background-color: #f9f9f9;
  z-index: 4;
}

.az-navbar-dashboard-eight .nav-sub-item > .nav-sub {
  left: 180px;
}

.az-navbar-dashboard-eight .nav-sub-item > .nav-sub::before {
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: auto;
  width: 5px;
  height: auto;
}

.az-navbar-dashboard-eight .nav-sub-item.active .nav-sub-link {
  color: #3366ff;
}

.az-navbar-dashboard-eight .nav-sub-link:hover {
  color: #3366ff;
}

.az-navbar-dashboard-eight .nav-sub-mega .container,
.az-navbar-dashboard-eight .nav-sub-mega .container-fluid,
.az-navbar-dashboard-eight .nav-sub-mega .container-sm,
.az-navbar-dashboard-eight .nav-sub-mega .container-md,
.az-navbar-dashboard-eight .nav-sub-mega .container-lg,
.az-navbar-dashboard-eight .nav-sub-mega .container-xl {
  background-color: #f9f9f9;
  border-width: 0;
}

@media (min-width: 992px) {
  .az-navbar-dashboard-eight .nav-sub-mega .container,
  .az-navbar-dashboard-eight .nav-sub-mega .container-fluid,
  .az-navbar-dashboard-eight .nav-sub-mega .container-sm,
  .az-navbar-dashboard-eight .nav-sub-mega .container-md,
  .az-navbar-dashboard-eight .nav-sub-mega .container-lg,
  .az-navbar-dashboard-eight .nav-sub-mega .container-xl {
    box-shadow: 0 5px 3px -3px rgba(28, 39, 60, 0.12);
  }
}

.az-content-dashboard-eight {
  padding-top: 25px;
}

@media (max-width: 575px) {
  .az-content-dashboard-eight .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.az-content-dashboard-eight .az-content-title {
  letter-spacing: -0.8px;
}

.az-content-dashboard-eight .card {
  border-width: 0;
  border-radius: 2px;
  box-shadow: 0 0 5px rgba(28, 39, 60, 0.1);
}

.az-content-dashboard-eight .card-title {
  font-weight: 500;
}

.card-dashboard-seventeen {
  position: relative;
}

.card-dashboard-seventeen .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 180px;
  position: relative;
  z-index: 10;
}

.card-dashboard-seventeen .card-body h4 {
  margin-bottom: 0;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
}

.card-dashboard-seventeen .card-body span {
  font-size: 11px;
}

.card-dashboard-seventeen .card-title {
  line-height: 1.3;
}

.card-dashboard-seventeen.bg-primary-dark {
  background-color: #0033cc;
}

.card-dashboard-seventeen .chart-wrapper {
  position: absolute;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.card-dashboard-seventeen .flot-chart {
  width: 100%;
  height: 180px;
}

.card-dashboard-eighteen {
  display: block;
  padding: 20px;
}

.card-dashboard-eighteen .card-title {
  font-weight: 700;
  text-transform: uppercase;
}

.card-dashboard-eighteen .card-body {
  padding: 20px 0 34px;
  align-items: flex-start;
}

.card-dashboard-eighteen .card-body h6 {
  color: #1c273c;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  font-size: 21px;
  letter-spacing: -0.8px;
  margin-bottom: 2px;
  position: relative;
  padding-left: 23px;
}

.card-dashboard-eighteen .card-body h6 span {
  font-weight: 400;
  color: #97a3b9;
  letter-spacing: normal;
}

.card-dashboard-eighteen .card-body h6::before {
  content: "";
  position: absolute;
  display: block;
  top: calc(50% - 8px);
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 4px solid transparent;
}

.card-dashboard-eighteen .card-body h6.dot-primary::before {
  border-color: #3366ff;
}

.card-dashboard-eighteen .card-body h6.dot-purple::before {
  border-color: #6f42c1;
}

.card-dashboard-eighteen .card-body h6.dot-teal::before {
  border-color: #00cccc;
}

.card-dashboard-eighteen .card-body h6.dot-dark-blue::before {
  border-color: #0033cc;
}

.card-dashboard-eighteen .card-body label {
  color: #7987a1;
  margin-bottom: 0;
  display: block;
}

.card-dashboard-eighteen .chartjs-wrapper {
  height: 309px;
  position: relative;
  margin-left: -10px;
  margin-right: -5px;
}

.card-dashboard-nineteen {
  overflow: hidden;
  position: relative;
  background-color: #f9f9f9;
}

.card-dashboard-nineteen .card-header {
  padding: 20px 20px 0;
  background-color: transparent;
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .card-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
}

.card-dashboard-nineteen .card-header .row {
  display: block;
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .card-header .row {
    display: flex;
  }
}

.card-dashboard-nineteen .card-header .row > div {
  max-width: none;
}

.card-dashboard-nineteen .card-header .row > div + div {
  margin-top: 20px;
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .card-header .row > div + div {
    margin-top: 0;
  }
}

.card-dashboard-nineteen .card-header h4 {
  font-size: 24px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  letter-spacing: -1px;
  color: #1c273c;
  margin-bottom: 10px;
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .card-header h4 {
    font-size: 30px;
  }
}

.card-dashboard-nineteen .card-header h4 span {
  font-weight: 400;
  letter-spacing: normal;
  color: #97a3b9;
}

.card-dashboard-nineteen .card-header .az-content-label,
.card-dashboard-nineteen .card-header .card-table-two .card-title,
.card-table-two .card-dashboard-nineteen .card-header .card-title,
.card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
  text-transform: none;
  line-height: 1.3;
  margin-bottom: 5px;
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .card-dashboard-nineteen .card-header .az-content-label,
  .card-dashboard-nineteen .card-header .card-table-two .card-title,
  .card-table-two .card-dashboard-nineteen .card-header .card-title,
  .card-dashboard-nineteen .card-header .card-dashboard-eight .card-title,
  .card-dashboard-eight .card-dashboard-nineteen .card-header .card-title {
    font-size: 14px;
  }
}

.card-dashboard-nineteen .card-header p {
  font-size: 11px;
  color: #7987a1;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .card-header p {
    font-size: 12px;
  }
}

.card-dashboard-nineteen .card-header .btn,
.card-dashboard-nineteen .card-header .sp-container button,
.sp-container .card-dashboard-nineteen .card-header button {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.5px;
  padding: 5px 20px;
  min-height: inherit;
  border-width: 2px;
}

.card-dashboard-nineteen .card-title {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 15px;
  color: #0040ff;
}

.card-dashboard-nineteen .chart-legend {
  margin-top: 22px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .card-dashboard-nineteen .chart-legend {
    margin-left: 70px;
  }
}

.card-dashboard-nineteen .chart-legend > div {
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .chart-legend > div {
    letter-spacing: 1px;
  }
}

.card-dashboard-nineteen .chart-legend > div::before {
  content: "";
  position: relative;
  top: 1.5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.card-dashboard-nineteen .chart-legend > div:first-child::before {
  background-color: #05478f;
}

.card-dashboard-nineteen .chart-legend > div:nth-child(2)::before {
  background-color: #2f93fe;
}

.card-dashboard-nineteen .chart-legend > div:last-child::before {
  background-color: #bbdafc;
}

.card-dashboard-nineteen .chart-legend > div + div {
  margin-left: 10px;
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .chart-legend > div + div {
    margin-left: 30px;
  }
}

.card-dashboard-nineteen .card-body {
  padding: 0;
  position: relative;
}

@media (min-width: 375px) and (max-width: 575px) {
  .card-dashboard-nineteen .card-body {
    padding-top: 170px;
  }
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .card-body {
    padding-top: 130px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-nineteen .card-body {
    padding-top: 95px;
  }
}

.card-dashboard-nineteen .flot-chart-wrapper {
  position: relative;
  margin: -30px -8px -16px -47px;
}

@media (min-width: 375px) {
  .card-dashboard-nineteen .flot-chart-wrapper {
    margin-top: 0;
  }
}

.card-dashboard-nineteen .flot-chart {
  width: 100%;
  height: 250px;
}

@media (min-width: 576px) {
  .card-dashboard-nineteen .flot-chart {
    height: 314px;
  }
}

.card-dashboard-nineteen .flot-chart .flot-x-axis {
  transform: translate(18px, -25px);
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.5;
}

.card-dashboard-nineteen .flot-chart .flot-y-axis {
  transform: translate(66px, -13px);
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.5px;
}

.card-dashboard-twenty .card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card-dashboard-twenty .chartjs-wrapper {
  width: 100%;
  height: 230px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-dashboard-twenty .chartjs-wrapper {
    margin-top: auto;
  }
}

.card-dashboard-twenty .expansion-value {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: -0.5px;
  margin-bottom: 5px;
  margin-top: auto;
}

.card-dashboard-twenty .expansion-value strong:first-child {
  color: #1c273c;
}

.card-dashboard-twenty .expansion-value strong:last-child {
  color: #7987a1;
}

.card-dashboard-twenty .progress {
  margin-bottom: 3px;
  height: 4px;
}

.card-dashboard-twenty .expansion-label {
  display: flex;
  justify-content: space-between;
}

.card-dashboard-twenty .expansion-label span {
  font-weight: 400;
  font-size: 11px;
  color: #97a3b9;
}

.card-dashboard-progress .progress-legend {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.card-dashboard-progress .progress-legend li {
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  padding-left: 15px;
  line-height: 0.95;
}

.card-dashboard-progress .progress-legend li::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 8px;
  height: 8px;
}

.card-dashboard-progress .progress-legend li:first-child::before {
  background-color: #3366ff;
}

.card-dashboard-progress .progress-legend li:last-child::before {
  background-color: #00cccc;
}

.card-dashboard-progress .progress-legend li + li {
  margin-left: 20px;
}

.card-dashboard-progress .media {
  display: block;
}

@media (min-width: 576px) {
  .card-dashboard-progress .media {
    display: flex;
    align-items: center;
  }
}

.card-dashboard-progress .media > label {
  width: 50px;
  display: block;
  margin-bottom: 0;
}

.card-dashboard-progress .media + .media {
  margin-top: 15px;
}

.card-dashboard-progress .media-body {
  margin-top: 5px;
}

@media (min-width: 576px) {
  .card-dashboard-progress .media-body {
    margin-top: 0;
    margin-left: 15px;
  }
}

.card-dashboard-progress .progress {
  background-color: #e3e7ed;
}

.card-dashboard-progress .progress-bar {
  height: 25px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
}

.card-dashboard-progress .progress-bar + .progress-bar {
  margin-left: 1px;
}

/* ###### 8.9 Dashboard Nine  ###### */

.az-body-dashboard-nine {
  background-color: #cdd4e0;
}

@media (min-width: 992px) {
  .az-body-dashboard-nine .az-header .container-fluid,
  .az-body-dashboard-nine .az-header .container-sm,
  .az-body-dashboard-nine .az-header .container-md,
  .az-body-dashboard-nine .az-header .container-lg,
  .az-body-dashboard-nine .az-header .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .az-body-dashboard-nine.az-sidebar-hide .az-header-dashboard-nine {
    left: 0;
  }
}

.az-sidebar-indigo-dark {
  background-color: #4130c5;
  border-right-width: 0;
}

.az-sidebar-indigo-dark .az-logo {
  color: #fff;
  letter-spacing: -1.4px;
}

.az-sidebar-indigo-dark .az-sidebar-loggedin .media-body h6 {
  color: #fff;
  font-weight: 500;
}

.az-sidebar-indigo-dark .az-sidebar-loggedin .media-body span {
  color: rgba(255, 255, 255, 0.5);
}

.az-sidebar-indigo-dark .az-img-user::after {
  box-shadow: none;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-label {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.35);
  font-weight: 400;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-item + .nav-item {
  margin-top: -1px;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-item + .nav-item::before {
  border-top-color: rgba(255, 255, 255, 0.12);
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active {
  position: relative;
  z-index: 5;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -20px;
  right: -20px;
  background-color: #33269c;
  height: 41px;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active .nav-link,
.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active .nav-link:hover,
.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active .nav-link:focus {
  font-weight: 500;
  color: #fff;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active .nav-link i,
.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active .nav-link:hover i,
.az-sidebar-indigo-dark .az-sidebar-body .nav-item.active .nav-link:focus i {
  color: #fff;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-link {
  color: rgba(255, 255, 255, 0.45);
  font-weight: 400;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-link i {
  color: rgba(255, 255, 255, 0.75);
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-link.with-sub::after {
  opacity: 0.3;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-link:hover,
.az-sidebar-indigo-dark .az-sidebar-body .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-sub {
  border-left-color: rgba(255, 255, 255, 0.1);
  padding-left: 0;
  padding-bottom: 0;
  position: relative;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-sub::before {
  content: "";
  position: absolute;
  top: 0;
  left: -32px;
  right: -20px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 4;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-sub-item.active .nav-sub-link {
  color: #fff;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-sub-link {
  padding-left: 21px;
  color: rgba(255, 255, 255, 0.4);
  border-top-color: rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 5;
}

.az-sidebar-indigo-dark .az-sidebar-body .nav-sub-link:hover,
.az-sidebar-indigo-dark .az-sidebar-body .nav-sub-link:focus {
  color: #fff;
}

.az-header-dashboard-nine {
  background-color: #fff;
}

@media (min-width: 992px) {
  .az-header-dashboard-nine {
    position: fixed;
    top: 0;
    left: 220px;
    right: 0;
    box-shadow: 0 0 15px rgba(28, 39, 60, 0.1);
  }
}

@media (min-width: 1200px) {
  .az-header-dashboard-nine {
    left: 240px;
  }
}

.az-header-dashboard-nine .az-header-menu-icon {
  margin-right: 0;
}

.az-content-dashboard-nine {
  position: relative;
}

@media (min-width: 992px) {
  .az-content-dashboard-nine {
    margin-top: 64px;
  }
}

.az-content-dashboard-nine .az-content-header {
  display: block;
  padding: 20px;
  background-color: #ebedf2;
}

@media (min-width: 992px) {
  .az-content-dashboard-nine .az-content-header {
    padding: 20px 20px 15px;
  }
}

@media (max-width: 991.98px) {
  .az-content-dashboard-nine .az-content-header .nav-wrapper {
    overflow: hidden;
    width: 100%;
    height: 20px;
  }
}

.az-content-dashboard-nine .az-content-header .nav {
  justify-content: flex-start;
}

@media (max-width: 991.98px) {
  .az-content-dashboard-nine .az-content-header .nav {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(1920px - 20px);
  }
}

.az-content-dashboard-nine .az-content-header .nav-link {
  color: #596882;
  font-weight: 500;
}

@media (max-width: 991.98px) {
  .az-content-dashboard-nine .az-content-header .nav-link {
    white-space: nowrap;
    padding-bottom: 10px;
  }
}

.az-content-dashboard-nine .az-content-header .nav-link:hover,
.az-content-dashboard-nine .az-content-header .nav-link:focus {
  color: #1c273c;
}

.az-content-dashboard-nine .az-content-header .nav-link.active {
  color: #2f15fa;
}

.az-content-dashboard-nine .az-content-header .nav-link.active::before {
  top: 34px;
  bottom: auto;
  background-color: #2f15fa;
}

.az-content-dashboard-nine .az-content-header .nav-link + .nav-link {
  margin-top: 0;
  margin-left: 25px;
}

@media (min-width: 992px) {
  .az-content-dashboard-nine .az-content-header .nav-link:nth-child(5),
  .az-content-dashboard-nine .az-content-header .nav-link:last-child {
    font-weight: 400;
  }
}

@media (min-width: 992px) {
  .az-content-dashboard-nine .az-content-header .nav-link:nth-child(5) {
    margin-left: auto;
  }
}

.az-content-dashboard-nine .az-content-header .nav-link:last-child {
  position: relative;
}

.az-content-dashboard-nine .az-content-header .nav-link:last-child::after {
  content: "";
  position: relative;
  display: inline-block;
  width: 25px;
}

@media (min-width: 768px) {
  .az-content-dashboard-nine .az-content-header .nav-link:last-child::after {
    display: none;
  }
}

.az-content-dashboard-nine .az-content-header-top {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .az-content-dashboard-nine .az-content-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.az-content-dashboard-nine .az-content-title {
  letter-spacing: -0.7px;
  font-size: 24px;
}

.az-content-dashboard-nine .az-content-body {
  padding: 20px;
}

.az-content-dashboard-nine .card {
  border-width: 0;
  box-shadow: 0 0 10px rgba(28, 39, 60, 0.1);
}

.card-dashboard-twentyone {
  position: relative;
}

.card-dashboard-twentyone .card-body {
  background-color: #f4f5f8;
  padding: 20px;
  height: 100%;
}

.card-dashboard-twentyone .card-body .az-content-label,
.card-dashboard-twentyone .card-body .card-table-two .card-title,
.card-table-two .card-dashboard-twentyone .card-body .card-title,
.card-dashboard-twentyone .card-body .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-twentyone .card-body .card-title {
  margin-bottom: 8px;
  text-transform: capitalize;
  font-size: 14px;
}

@media (min-width: 768px) {
  .card-dashboard-twentyone .list-group {
    margin-bottom: 10px;
  }
}

.card-dashboard-twentyone .list-group-item {
  padding: 8px 0;
  border-width: 0;
  border-right-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  background-color: transparent;
}

.card-dashboard-twentyone .list-group-item span {
  flex-basis: 50%;
  max-width: 50%;
}

.card-dashboard-twentyone .list-group-item span:last-of-type {
  text-align: right;
  color: #1c273c;
  font-size: 12px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.card-dashboard-twentyone .list-group-item .progress {
  margin-top: 5px;
  margin-bottom: 0;
  flex-basis: 100%;
  max-width: 100%;
  height: 5px;
  background-color: #b4bdce;
}

.card-dashboard-twentyone .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-dashboard-twentyone .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-dashboard-twentyone .vmap-wrapper {
  width: 100%;
  height: 180px;
}

@media (min-width: 576px) {
  .card-dashboard-twentyone .vmap-wrapper {
    height: 250px;
  }
}

@media (min-width: 768px) {
  .card-dashboard-twentyone .vmap-wrapper {
    height: 100%;
  }
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
  width: 24px;
  height: 24px;
  left: 15px;
  border-radius: 100%;
  background-color: #1c273c;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.2;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin:hover,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin:focus,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout:hover,
.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout:focus {
  opacity: 0.8;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomin {
  top: 15px;
}

.card-dashboard-twentyone .vmap-wrapper .jqvmap-zoomout {
  top: 45px;
}

.card-dashboard-twentytwo {
  background-color: #fff;
  position: relative;
  height: 120px;
}

.card-dashboard-twentytwo .media {
  padding: 20px;
  position: relative;
  z-index: 5;
}

.card-dashboard-twentytwo .media-icon {
  width: 45px;
  height: 45px;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-dashboard-twentytwo .media-icon i {
  font-size: 24px;
  line-height: 0;
}

.card-dashboard-twentytwo .media-icon i.typcn {
  line-height: 0.9;
}

.card-dashboard-twentytwo .media-body {
  margin-left: 15px;
  padding-top: 5px;
}

.card-dashboard-twentytwo .media-body h6 {
  margin-bottom: 5px;
  line-height: 0.7;
  color: #1c273c;
  font-size: 26px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: -0.5px;
  display: flex;
  align-items: flex-end;
}

.card-dashboard-twentytwo .media-body h6 > small {
  color: #fff;
  padding: 2px 3px;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.1;
  margin-left: 5px;
  letter-spacing: normal;
}

.card-dashboard-twentytwo .media-body h6 > small.up {
  background-color: #3bb001;
}

.card-dashboard-twentytwo .media-body h6 > small.down {
  background-color: #dc3545;
}

.card-dashboard-twentytwo .media-body > span {
  display: block;
}

.card-dashboard-twentytwo .media-body > small {
  display: block;
  font-size: 11px;
  color: #97a3b9;
}

.card-dashboard-twentytwo .media-body > small strong {
  font-weight: 500;
}

.card-dashboard-twentytwo .chart-wrapper {
  position: absolute;
  right: -9px;
  left: -9px;
  opacity: 0.3;
}

.card-dashboard-twentytwo .flot-chart {
  width: 100%;
  height: 120px;
}

.card-dashboard-twentythree {
  background-color: #f4f5f8;
}

.card-dashboard-twentythree .az-donut-chart.chart1 {
  width: 130px;
  height: 130px;
  background: #b4bdce;
}

.card-dashboard-twentythree .az-donut-chart.chart1 .slice.one {
  clip: rect(0 130px 65px 0);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: #5b47fb;
}

.card-dashboard-twentythree .az-donut-chart.chart1 .slice.two {
  clip: rect(0 65px 130px 0);
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
  background: #5b47fb;
}

.card-dashboard-twentythree .az-donut-chart.chart1 .chart-center {
  top: 15px;
  left: 15px;
  width: 100px;
  height: 100px;
  background: #f4f5f8;
}

.card-dashboard-twentythree .az-donut-chart.chart1 .chart-center span {
  font-size: 40px;
  line-height: 100px;
  color: #5b47fb;
}

.card-dashboard-twentythree .az-donut-chart.chart1 .chart-center span:after {
  content: "65%";
}

.card-dashboard-twentythree .az-donut-chart.chart1 .chart-center span {
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 32px;
}

.card-dashboard-twentythree label {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.5px;
}

.card-dashboard-twentythree label span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
}

.card-dashboard-twentythree h5 {
  font-size: 24px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  letter-spacing: -1px;
}

.card-dashboard-audience-metrics {
  background-color: #f4f5f8;
  position: relative;
  overflow: hidden;
  height: 250px;
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics {
    height: 270px;
  }
}

.card-dashboard-audience-metrics .card-header {
  padding: 20px 20px 0;
  background-color: #f4f5f8;
  position: relative;
  z-index: 10;
}

.card-dashboard-audience-metrics .card-title {
  font-size: 14px;
  margin-bottom: 5px;
}

.card-dashboard-audience-metrics .chart-wrapper {
  position: absolute;
  right: 0;
  bottom: -20px;
  left: 0;
  padding: 5px 5px 0;
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .chart-wrapper {
    padding-bottom: 10px;
    bottom: 10px;
  }
}

.card-dashboard-audience-metrics .flot-chart {
  width: 100%;
  height: 180px;
}

.card-dashboard-audience-metrics .flot-chart .flot-x-axis > div {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  color: #7987a1;
  display: none;
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .flot-chart .flot-x-axis > div {
    display: block;
  }
}

.card-dashboard-audience-metrics
  .flot-chart
  .flot-x-axis
  > div
  span:last-child {
  padding-left: 2px;
  font-weight: 700;
  color: #1c273c;
}

.card-dashboard-audience-metrics .card-body {
  background-color: #f4f5f8;
  display: flex;
  padding: 0 20px 20px;
  flex: none;
  position: relative;
  z-index: 10;
}

.card-dashboard-audience-metrics .card-body > div + div {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #b4bdce;
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .card-body > div + div {
    margin-left: 20px;
    padding-left: 20px;
  }
}

.card-dashboard-audience-metrics .card-body h4 {
  font-weight: 700;
  font-size: 17px;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  letter-spacing: -0.5px;
  margin-bottom: 3px;
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .card-body h4 {
    font-size: 21px;
  }
}

.card-dashboard-audience-metrics .card-body label {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 11px;
}

@media (min-width: 576px) {
  .card-dashboard-audience-metrics .card-body label {
    font-size: 0.875rem;
  }
}

.card-dashboard-audience-metrics .card-body label span {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-right: 5px;
  border-radius: 100%;
}

/* ###### 8.10 Dashboard Ten  ###### */

.bg-primary-dark {
  background-color: #0033cc;
}

.az-iconbar-primary {
  background-color: #0040ff;
  padding-top: 0;
}

.az-iconbar-primary .az-img-user::after {
  box-shadow: none;
}

.az-iconbar-primary .az-iconbar-logo {
  background-color: #0039e6;
  color: #fff;
  height: 64px;
}

.az-iconbar-primary .az-iconbar-logo::after {
  display: none;
}

.az-iconbar-primary .nav {
  justify-content: center;
}

.az-iconbar-primary .nav-link {
  width: 42px;
  height: 42px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .az-iconbar-primary .nav-link {
    transition: none;
  }
}

.az-iconbar-primary .nav-link:hover,
.az-iconbar-primary .nav-link:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.az-iconbar-primary .nav-link.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.az-iconbar-primary .az-iconbar-help {
  color: rgba(255, 255, 255, 0.5);
}

.az-iconbar-primary .az-iconbar-help:hover,
.az-iconbar-primary .az-iconbar-help:focus {
  color: #fff;
}

.az-iconbar-aside-primary {
  width: 250px;
  background-color: #f4f5f8;
  box-shadow: 0 0 16px rgba(28, 39, 60, 0.08);
  border-right: 1px solid #cdd4e0;
}

.az-iconbar-aside-primary .az-iconbar-header {
  padding: 18px 25px;
  background-color: #fff;
  border-bottom: 1px solid #e3e7ed;
}

.az-iconbar-aside-primary .az-iconbar-body {
  padding: 20px 25px;
}

.az-iconbar-aside-primary .az-iconbar-body .nav {
  padding-top: 0;
  padding-left: 0;
  border-left-width: 0;
}

.az-iconbar-aside-primary .az-iconbar-body .nav-item + .nav-item {
  border-top-color: #cdd4e0;
}

.az-iconbar-aside-primary .az-iconbar-body .nav-sub {
  border-top-color: #cdd4e0;
}

.az-iconbar-aside-primary .az-iconbar-body .nav-sub-item + .nav-sub-item {
  border-top-color: #cdd4e0;
}

.az-iconbar-aside-primary .az-iconbar-body .nav-link:hover,
.az-iconbar-aside-primary .az-iconbar-body .nav-link:focus {
  color: #3366ff;
}

.az-iconbar-aside-primary .az-iconbar-body .nav-link.active {
  color: #3366ff;
}

.az-iconbar-aside-primary .az-iconbar-toggle-menu {
  display: none;
}

@media (min-width: 992px) {
  .az-iconbar-aside-primary.show + .az-content {
    margin-left: 64px;
  }
}

.az-content-dashboard-ten {
  padding: 0;
  min-height: 100vh;
  background-color: #e3e7ed;
}

@media (min-width: 992px) {
  .az-content-dashboard-ten {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 64px;
  }
}

.az-content-dashboard-ten .az-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

@media (min-width: 992px) {
  .az-content-dashboard-ten .az-header {
    left: 64px;
  }
}

@media (min-width: 992px) {
  .az-content-dashboard-ten .az-header .container-fluid,
  .az-content-dashboard-ten .az-header .container-sm,
  .az-content-dashboard-ten .az-header .container-md,
  .az-content-dashboard-ten .az-header .container-lg,
  .az-content-dashboard-ten .az-header .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.az-content-dashboard-ten .az-header-center {
  margin-left: 0;
}

.az-content-dashboard-ten .az-content-body {
  margin-top: 64px;
}

@media (min-width: 1200px) {
  .az-content-dashboard-ten .az-content-body {
    display: flex;
  }
}

.az-content-dashboard-ten .az-content-body-left {
  flex: 1;
  padding: 20px;
}

.az-content-dashboard-ten .az-content-body-right {
  background-color: #fcfcfc;
  padding: 20px;
  display: none;
}

@media (min-width: 1200px) {
  .az-content-dashboard-ten .az-content-body-right {
    display: block;
    width: 280px;
    box-shadow: 0 0 6px rgba(28, 39, 60, 0.1);
  }
}

@media (min-width: 1300px) {
  .az-content-dashboard-ten .az-content-body-right {
    width: 300px;
  }
}

.az-content-dashboard-ten .az-content-title {
  letter-spacing: -0.8px;
}

.az-content-dashboard-ten .card {
  border-width: 0;
  box-shadow: 0 0 6px rgba(28, 39, 60, 0.12);
}

.az-content-dashboard-ten .az-footer {
  margin-top: auto;
}

.card-dashboard-twentyfour .card-header {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
}

.card-dashboard-twentyfour .card-title {
  font-size: 14px;
  margin-bottom: 0;
}

.card-dashboard-twentyfour .card-body {
  padding: 15px 20px 20px;
}

.card-dashboard-twentyfour .card-body-top {
  display: flex;
  margin-bottom: 20px;
}

.card-dashboard-twentyfour .card-body-top h6 {
  color: #1c273c;
  font-size: 18px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0;
  letter-spacing: -0.75px;
}

@media (min-width: 576px) {
  .card-dashboard-twentyfour .card-body-top h6 {
    font-size: 22px;
  }
}

.card-dashboard-twentyfour .card-body-top h6 small {
  font-weight: 600;
}

.card-dashboard-twentyfour .card-body-top h6 span {
  color: #97a3b9;
  font-weight: 400;
  letter-spacing: normal;
}

.card-dashboard-twentyfour .card-body-top label {
  display: block;
  margin-bottom: 0;
  color: #7987a1;
  font-size: 11px;
}

@media (min-width: 576px) {
  .card-dashboard-twentyfour .card-body-top label {
    font-size: 0.875rem;
  }
}

.card-dashboard-twentyfour .card-body-top > div + div {
  margin-left: 30px;
}

.card-dashboard-twentyfour .az-content-label,
.card-dashboard-twentyfour .card-table-two .card-title,
.card-table-two .card-dashboard-twentyfour .card-title,
.card-dashboard-twentyfour .card-dashboard-eight .card-title,
.card-dashboard-eight .card-dashboard-twentyfour .card-title {
  font-size: 10px;
  color: #031b4e;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.card-dashboard-twentyfour .chart-legend {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 0.6;
}

.card-dashboard-twentyfour .chart-legend span {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 5px;
}

.card-dashboard-twentyfour .chart-legend > div {
  display: flex;
  align-items: center;
}

.card-dashboard-twentyfour .chart-legend > div + div {
  margin-left: 20px;
}

.card-dashboard-twentyfour .chart-wrapper {
  position: relative;
  margin-right: -10px;
}

.card-dashboard-twentyfour .flot-chart {
  width: 100%;
  height: 130px;
}

.card-dashboard-twentyfive .card-title {
  font-size: 14px;
  margin-bottom: 15px;
}

.card-dashboard-twentyfive .col + .col {
  border-left: 1px solid #cdd4e0;
}

.card-dashboard-twentyfive .card-label {
  display: block;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
  color: #97a3b9;
  white-space: nowrap;
}

.card-dashboard-twentyfive .card-value {
  font-size: 22px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  margin-bottom: 0;
  letter-spacing: -1px;
}

.card-dashboard-twentyfive .chart-wrapper {
  position: relative;
  margin: 0 -8px -5px;
}

.card-dashboard-twentyfive .flot-chart {
  width: 100%;
  height: 35px;
}

.card-dashboard-twentysix .card-header {
  padding: 15px 15px 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-dashboard-twentysix .card-title {
  margin-bottom: 0;
  text-transform: uppercase;
}

.card-dashboard-twentysix .chart-legend {
  display: flex;
  align-items: center;
}

.card-dashboard-twentysix .chart-legend > div {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.card-dashboard-twentysix .chart-legend > div + div {
  margin-left: 15px;
}

.card-dashboard-twentysix .chart-legend span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 2px;
}

.card-dashboard-twentysix .card-body {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.card-dashboard-twentysix .card-body h6 {
  margin-bottom: 0;
  color: #1c273c;
  font-size: 22px;
  font-weight: 600;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: -0.5px;
  line-height: 1;
}

.card-dashboard-twentysix .card-body h6 span {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -1px;
}

.card-dashboard-twentysix .card-body label {
  font-size: 12px;
  margin-bottom: 0;
  color: #97a3b9;
}

.card-dashboard-twentysix .chart-wrapper {
  position: relative;
  margin: -30px -18px -15px -18px;
}

.card-dashboard-twentysix .flot-chart {
  width: 100%;
  height: 120px;
}

.card-dashboard-twentysix .flot-chart .flot-x-axis > div {
  font-size: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
  transform: translateY(-20px);
}

.card-dashboard-twentysix .flot-chart .flot-y-axis > div {
  font-size: 10px;
  transform: translateX(33px);
  color: rgba(28, 39, 60, 0.5);
}

.card-dashboard-twentysix.card-dark-one .card-title,
.card-dashboard-twentysix.card-dark-two .card-title {
  color: #fff;
}

.card-dashboard-twentysix.card-dark-one .card-body h6,
.card-dashboard-twentysix.card-dark-two .card-body h6 {
  color: #fff;
}

.card-dashboard-twentysix.card-dark-one .card-body h6 span,
.card-dashboard-twentysix.card-dark-two .card-body h6 span {
  color: rgba(255, 255, 255, 0.5);
}

.card-dashboard-twentysix.card-dark-one .card-body label,
.card-dashboard-twentysix.card-dark-two .card-body label {
  color: rgba(255, 255, 255, 0.7);
}

.card-dashboard-twentysix.card-dark-one .flot-chart .flot-y-axis > div,
.card-dashboard-twentysix.card-dark-two .flot-chart .flot-y-axis > div {
  color: rgba(255, 255, 255, 0.2);
}

.card-dashboard-twentysix.card-dark-one {
  background-color: #0039e6;
  background-image: linear-gradient(to bottom, #1f05f0 0%, #0039e6 100%);
  background-repeat: repeat-x;
}

.card-dashboard-twentysix.card-dark-two {
  background-color: #0040ff;
  background-image: linear-gradient(to bottom, #0a47ff 0%, #3366ff 100%);
  background-repeat: repeat-x;
}

.az-rating-value {
  font-size: 40px;
  font-weight: 400;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
  margin-bottom: 0;
  letter-spacing: -0.5px;
  line-height: 0.7;
}

.az-rating-label {
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.az-media-list-reviews .media + .media {
  margin-top: 25px;
}

.az-media-list-reviews .az-img-user {
  width: 32px;
  height: 32px;
}

.az-media-list-reviews .az-img-user::after {
  display: none;
}

.az-media-list-reviews .media-body {
  margin-left: 15px;
}

.az-media-list-reviews .media-body h6 {
  margin-bottom: 2px;
  line-height: 1;
}

.az-media-list-reviews .media-body small {
  display: inline-block;
  font-size: 12px;
  color: #97a3b9;
  line-height: 1;
}

.az-star-group {
  display: flex;
  align-items: center;
}

.az-star-group span:last-child {
  display: block;
  font-weight: 500;
  font-size: 11px;
  margin-left: 5px;
  color: #7987a1;
}

.az-star-item {
  color: #3366ff;
  font-size: 14px;
  position: relative;
}

.az-star-item + .az-star-item {
  margin-left: 3px;
}

.az-media-list-activity .media + .media {
  margin-top: 25px;
}

.az-media-list-activity .media-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
}

.az-media-list-activity .media-icon i {
  font-size: 21px;
  line-height: 0;
}

.az-media-list-activity .media-icon i.typcn {
  line-height: 0.9;
}

.az-media-list-activity .media-body {
  margin-left: 15px;
}

.az-media-list-activity .media-body h6 {
  margin-bottom: 2px;
  color: #1c273c;
}

.az-media-list-activity .media-body span {
  display: block;
  font-size: 11px;
  color: #97a3b9;
}

.az-media-list-activity .media-right {
  font-size: 11px;
  color: #97a3b9;
}

/* ############### UTILITIES/HELPER CLASSES ############### */

/* ###### 9.1 Background  ###### */

.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: #f4f5f8;
}

.bg-gray-200 {
  background-color: #e3e7ed;
}

.bg-gray-300 {
  background-color: #cdd4e0;
}

.bg-gray-400 {
  background-color: #b4bdce;
}

.bg-gray-500 {
  background-color: #97a3b9;
}

.bg-gray-600 {
  background-color: #7987a1;
}

.bg-gray-700 {
  background-color: #596882;
}

.bg-gray-800 {
  background-color: #3b4863;
}

.bg-gray-900 {
  background-color: #1c273c;
}

.bg-white-1 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
  background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
  background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
  background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
  background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
  background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
  background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
  background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.bg-indigo {
  background-color: #5b47fb;
}

.bg-purple {
  background-color: #6f42c1;
}

.bg-pink {
  background-color: #f10075;
}

.bg-orange {
  background-color: #fd7e14;
}

.bg-teal {
  background-color: #00cccc;
}

.bg-purple-dark {
  background-color: #59339d;
}

/* ###### 9.2 Border   ###### */

.bd-transparent {
  border-color: transparent;
}

.bd {
  border: 1px solid #cdd4e0;
}

.bd-t {
  border-top: 1px solid #cdd4e0;
}

.bd-r {
  border-right: 1px solid #cdd4e0;
}

.bd-b {
  border-bottom: 1px solid #cdd4e0;
}

.bd-l {
  border-left: 1px solid #cdd4e0;
}

.bd-y {
  border-top: 1px solid #cdd4e0;
  border-bottom: 1px solid #cdd4e0;
}

.bd-x {
  border-left: 1px solid #cdd4e0;
  border-right: 1px solid #cdd4e0;
}

.bd-0 {
  border-width: 0;
}

.bd-1 {
  border-width: 1px;
}

.bd-2 {
  border-width: 2px;
}

.bd-3 {
  border-width: 3px;
}

.bd-4 {
  border-width: 4px;
}

.bd-5 {
  border-width: 5px;
}

.bd-t-0 {
  border-top: 0;
}

.bd-r-0 {
  border-right: 0;
}

.bd-b-0 {
  border-bottom: 0;
}

.bd-l-0 {
  border-left: 0;
}

.bd-t-0-f {
  border-top: 0 !important;
}

.bd-r-0-f {
  border-right: 0 !important;
}

.bd-b-0-f {
  border-bottom: 0 !important;
}

.bd-l-0-f {
  border-left: 0 !important;
}

.bd-y-0 {
  border-top-width: 0;
  border-bottom-width: 0;
}

.bd-y-1 {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.bd-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.bd-x-1 {
  border-left-width: 1px;
  border-right-width: 1px;
}

.bd-primary {
  border-color: #3366ff;
}

.bd-success {
  border-color: #3bb001;
}

.bd-warning {
  border-color: #ffc107;
}

.bd-danger {
  border-color: #dc3545;
}

.bd-info {
  border-color: #17a2b8;
}

.bd-white {
  border-color: #fff;
}

.bd-gray-100 {
  border-color: #f4f5f8;
}

.bd-gray-200 {
  border-color: #e3e7ed;
}

.bd-gray-300 {
  border-color: #cdd4e0;
}

.bd-gray-400 {
  border-color: #b4bdce;
}

.bd-gray-500 {
  border-color: #97a3b9;
}

.bd-gray-600 {
  border-color: #7987a1;
}

.bd-gray-700 {
  border-color: #596882;
}

.bd-gray-800 {
  border-color: #3b4863;
}

.bd-gray-900 {
  border-color: #1c273c;
}

.bd-white-1 {
  border-color: rgba(255, 255, 255, 0.1);
}

.bd-white-2 {
  border-color: rgba(255, 255, 255, 0.2);
}

.bd-white-3 {
  border-color: rgba(255, 255, 255, 0.3);
}

.bd-white-4 {
  border-color: rgba(255, 255, 255, 0.4);
}

.bd-white-5 {
  border-color: rgba(255, 255, 255, 0.5);
}

.bd-white-6 {
  border-color: rgba(255, 255, 255, 0.6);
}

.bd-white-7 {
  border-color: rgba(255, 255, 255, 0.7);
}

.bd-white-8 {
  border-color: rgba(255, 255, 255, 0.8);
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-30 {
  border-radius: 30px;
}

.rounded-40 {
  border-radius: 40px;
}

.rounded-50 {
  border-radius: 50px;
}

.bd-dashed {
  border-style: dashed;
}

.bd-dotted {
  border-style: dotted;
}

@media (min-width: 480px) {
  .bd-xs {
    border: 1px solid #cdd4e0;
  }
  .bd-xs-t {
    border-top: 1px solid #cdd4e0;
  }
  .bd-xs-r {
    border-right: 1px solid #cdd4e0;
  }
  .bd-xs-b {
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-xs-l {
    border-left: 1px solid #cdd4e0;
  }
  .bd-xs-y {
    border-top: 1px solid #cdd4e0;
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-xs-x {
    border-left: 1px solid #cdd4e0;
    border-right: 1px solid #cdd4e0;
  }
}

@media (min-width: 576px) {
  .bd-sm {
    border: 1px solid #cdd4e0;
  }
  .bd-sm-t {
    border-top: 1px solid #cdd4e0;
  }
  .bd-sm-r {
    border-right: 1px solid #cdd4e0;
  }
  .bd-sm-b {
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-sm-l {
    border-left: 1px solid #cdd4e0;
  }
  .bd-sm-y {
    border-top: 1px solid #cdd4e0;
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-sm-x {
    border-left: 1px solid #cdd4e0;
    border-right: 1px solid #cdd4e0;
  }
}

@media (min-width: 768px) {
  .bd-md {
    border: 1px solid #cdd4e0;
  }
  .bd-md-t {
    border-top: 1px solid #cdd4e0;
  }
  .bd-md-r {
    border-right: 1px solid #cdd4e0;
  }
  .bd-md-b {
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-md-l {
    border-left: 1px solid #cdd4e0;
  }
  .bd-md-y {
    border-top: 1px solid #cdd4e0;
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-md-x {
    border-left: 1px solid #cdd4e0;
    border-right: 1px solid #cdd4e0;
  }
}

@media (min-width: 992px) {
  .bd-lg {
    border: 1px solid #cdd4e0;
  }
  .bd-lg-t {
    border-top: 1px solid #cdd4e0;
  }
  .bd-lg-r {
    border-right: 1px solid #cdd4e0;
  }
  .bd-lg-b {
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-lg-l {
    border-left: 1px solid #cdd4e0;
  }
  .bd-lg-y {
    border-top: 1px solid #cdd4e0;
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-lg-x {
    border-left: 1px solid #cdd4e0;
    border-right: 1px solid #cdd4e0;
  }
}

@media (min-width: 1200px) {
  .bd-xl {
    border: 1px solid #cdd4e0;
  }
  .bd-xl-t {
    border-top: 1px solid #cdd4e0;
  }
  .bd-xl-r {
    border-right: 1px solid #cdd4e0;
  }
  .bd-xl-b {
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-xl-l {
    border-left: 1px solid #cdd4e0;
  }
  .bd-xl-y {
    border-top: 1px solid #cdd4e0;
    border-bottom: 1px solid #cdd4e0;
  }
  .bd-xl-x {
    border-left: 1px solid #cdd4e0;
    border-right: 1px solid #cdd4e0;
  }
}

/* ###### 9.3 Height   ###### */

.ht-5 {
  height: 5px;
}

.ht-5p {
  height: 5%;
}

.mx-ht-5p {
  max-height: 5%;
}

.mn-ht-5p {
  min-height: 5%;
}

.ht-5-f {
  height: 5px !important;
}

.ht-5p-f {
  height: 5% !important;
}

.ht-10 {
  height: 10px;
}

.ht-10p {
  height: 10%;
}

.mx-ht-10p {
  max-height: 10%;
}

.mn-ht-10p {
  min-height: 10%;
}

.ht-10-f {
  height: 10px !important;
}

.ht-10p-f {
  height: 10% !important;
}

.ht-15 {
  height: 15px;
}

.ht-15p {
  height: 15%;
}

.mx-ht-15p {
  max-height: 15%;
}

.mn-ht-15p {
  min-height: 15%;
}

.ht-15-f {
  height: 15px !important;
}

.ht-15p-f {
  height: 15% !important;
}

.ht-20 {
  height: 20px;
}

.ht-20p {
  height: 20%;
}

.mx-ht-20p {
  max-height: 20%;
}

.mn-ht-20p {
  min-height: 20%;
}

.ht-20-f {
  height: 20px !important;
}

.ht-20p-f {
  height: 20% !important;
}

.ht-25 {
  height: 25px;
}

.ht-25p {
  height: 25%;
}

.mx-ht-25p {
  max-height: 25%;
}

.mn-ht-25p {
  min-height: 25%;
}

.ht-25-f {
  height: 25px !important;
}

.ht-25p-f {
  height: 25% !important;
}

.ht-30 {
  height: 30px;
}

.ht-30p {
  height: 30%;
}

.mx-ht-30p {
  max-height: 30%;
}

.mn-ht-30p {
  min-height: 30%;
}

.ht-30-f {
  height: 30px !important;
}

.ht-30p-f {
  height: 30% !important;
}

.ht-35 {
  height: 35px;
}

.ht-35p {
  height: 35%;
}

.mx-ht-35p {
  max-height: 35%;
}

.mn-ht-35p {
  min-height: 35%;
}

.ht-35-f {
  height: 35px !important;
}

.ht-35p-f {
  height: 35% !important;
}

.ht-40 {
  height: 40px;
}

.ht-40p {
  height: 40%;
}

.mx-ht-40p {
  max-height: 40%;
}

.mn-ht-40p {
  min-height: 40%;
}

.ht-40-f {
  height: 40px !important;
}

.ht-40p-f {
  height: 40% !important;
}

.ht-45 {
  height: 45px;
}

.ht-45p {
  height: 45%;
}

.mx-ht-45p {
  max-height: 45%;
}

.mn-ht-45p {
  min-height: 45%;
}

.ht-45-f {
  height: 45px !important;
}

.ht-45p-f {
  height: 45% !important;
}

.ht-50 {
  height: 50px;
}

.ht-50p {
  height: 50%;
}

.mx-ht-50p {
  max-height: 50%;
}

.mn-ht-50p {
  min-height: 50%;
}

.ht-50-f {
  height: 50px !important;
}

.ht-50p-f {
  height: 50% !important;
}

.ht-55 {
  height: 55px;
}

.ht-55p {
  height: 55%;
}

.mx-ht-55p {
  max-height: 55%;
}

.mn-ht-55p {
  min-height: 55%;
}

.ht-55-f {
  height: 55px !important;
}

.ht-55p-f {
  height: 55% !important;
}

.ht-60 {
  height: 60px;
}

.ht-60p {
  height: 60%;
}

.mx-ht-60p {
  max-height: 60%;
}

.mn-ht-60p {
  min-height: 60%;
}

.ht-60-f {
  height: 60px !important;
}

.ht-60p-f {
  height: 60% !important;
}

.ht-65 {
  height: 65px;
}

.ht-65p {
  height: 65%;
}

.mx-ht-65p {
  max-height: 65%;
}

.mn-ht-65p {
  min-height: 65%;
}

.ht-65-f {
  height: 65px !important;
}

.ht-65p-f {
  height: 65% !important;
}

.ht-70 {
  height: 70px;
}

.ht-70p {
  height: 70%;
}

.mx-ht-70p {
  max-height: 70%;
}

.mn-ht-70p {
  min-height: 70%;
}

.ht-70-f {
  height: 70px !important;
}

.ht-70p-f {
  height: 70% !important;
}

.ht-75 {
  height: 75px;
}

.ht-75p {
  height: 75%;
}

.mx-ht-75p {
  max-height: 75%;
}

.mn-ht-75p {
  min-height: 75%;
}

.ht-75-f {
  height: 75px !important;
}

.ht-75p-f {
  height: 75% !important;
}

.ht-80 {
  height: 80px;
}

.ht-80p {
  height: 80%;
}

.mx-ht-80p {
  max-height: 80%;
}

.mn-ht-80p {
  min-height: 80%;
}

.ht-80-f {
  height: 80px !important;
}

.ht-80p-f {
  height: 80% !important;
}

.ht-85 {
  height: 85px;
}

.ht-85p {
  height: 85%;
}

.mx-ht-85p {
  max-height: 85%;
}

.mn-ht-85p {
  min-height: 85%;
}

.ht-85-f {
  height: 85px !important;
}

.ht-85p-f {
  height: 85% !important;
}

.ht-90 {
  height: 90px;
}

.ht-90p {
  height: 90%;
}

.mx-ht-90p {
  max-height: 90%;
}

.mn-ht-90p {
  min-height: 90%;
}

.ht-90-f {
  height: 90px !important;
}

.ht-90p-f {
  height: 90% !important;
}

.ht-95 {
  height: 95px;
}

.ht-95p {
  height: 95%;
}

.mx-ht-95p {
  max-height: 95%;
}

.mn-ht-95p {
  min-height: 95%;
}

.ht-95-f {
  height: 95px !important;
}

.ht-95p-f {
  height: 95% !important;
}

.ht-100 {
  height: 100px;
}

.ht-100p {
  height: 100%;
}

.mx-ht-100p {
  max-height: 100%;
}

.mn-ht-100p {
  min-height: 100%;
}

.ht-100-f {
  height: 100px !important;
}

.ht-100p-f {
  height: 100% !important;
}

.ht-150 {
  height: 150px;
}

.ht-200 {
  height: 200px;
}

.ht-250 {
  height: 250px;
}

.ht-300 {
  height: 300px;
}

.ht-350 {
  height: 350px;
}

.ht-400 {
  height: 400px;
}

.ht-450 {
  height: 450px;
}

.ht-500 {
  height: 500px;
}

.ht-550 {
  height: 550px;
}

.ht-600 {
  height: 600px;
}

.ht-650 {
  height: 650px;
}

.ht-700 {
  height: 700px;
}

.ht-750 {
  height: 750px;
}

.ht-800 {
  height: 800px;
}

.ht-100v {
  height: 100vh;
}

.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-4 {
  height: 4px;
}

.ht-6 {
  height: 6px;
}

.ht-7 {
  height: 7px;
}

.ht-8 {
  height: 8px;
}

.ht-9 {
  height: 9px;
}

.ht-auto {
  height: auto;
}

.ht-16 {
  height: 16px;
}

.ht-32 {
  height: 32px;
}

.ht-36 {
  height: 36px;
}

.ht-48 {
  height: 48px;
}

.ht-64 {
  height: 64px;
}

.ht-72 {
  height: 72px;
}

.ht-115 {
  height: 115px;
}

.ht-120 {
  height: 120px;
}

.ht-160 {
  height: 160px;
}

@media (min-width: 480px) {
  .ht-xs-5 {
    height: 5px;
  }
  .ht-xs-5p {
    height: 5%;
  }
  .mx-ht-xs-5p {
    max-height: 5%;
  }
  .mn-ht-xs-5p {
    min-height: 5%;
  }
  .ht-xs-5-f {
    height: 5px !important;
  }
  .ht-xs-5p-f {
    height: 5% !important;
  }
  .ht-xs-10 {
    height: 10px;
  }
  .ht-xs-10p {
    height: 10%;
  }
  .mx-ht-xs-10p {
    max-height: 10%;
  }
  .mn-ht-xs-10p {
    min-height: 10%;
  }
  .ht-xs-10-f {
    height: 10px !important;
  }
  .ht-xs-10p-f {
    height: 10% !important;
  }
  .ht-xs-15 {
    height: 15px;
  }
  .ht-xs-15p {
    height: 15%;
  }
  .mx-ht-xs-15p {
    max-height: 15%;
  }
  .mn-ht-xs-15p {
    min-height: 15%;
  }
  .ht-xs-15-f {
    height: 15px !important;
  }
  .ht-xs-15p-f {
    height: 15% !important;
  }
  .ht-xs-20 {
    height: 20px;
  }
  .ht-xs-20p {
    height: 20%;
  }
  .mx-ht-xs-20p {
    max-height: 20%;
  }
  .mn-ht-xs-20p {
    min-height: 20%;
  }
  .ht-xs-20-f {
    height: 20px !important;
  }
  .ht-xs-20p-f {
    height: 20% !important;
  }
  .ht-xs-25 {
    height: 25px;
  }
  .ht-xs-25p {
    height: 25%;
  }
  .mx-ht-xs-25p {
    max-height: 25%;
  }
  .mn-ht-xs-25p {
    min-height: 25%;
  }
  .ht-xs-25-f {
    height: 25px !important;
  }
  .ht-xs-25p-f {
    height: 25% !important;
  }
  .ht-xs-30 {
    height: 30px;
  }
  .ht-xs-30p {
    height: 30%;
  }
  .mx-ht-xs-30p {
    max-height: 30%;
  }
  .mn-ht-xs-30p {
    min-height: 30%;
  }
  .ht-xs-30-f {
    height: 30px !important;
  }
  .ht-xs-30p-f {
    height: 30% !important;
  }
  .ht-xs-35 {
    height: 35px;
  }
  .ht-xs-35p {
    height: 35%;
  }
  .mx-ht-xs-35p {
    max-height: 35%;
  }
  .mn-ht-xs-35p {
    min-height: 35%;
  }
  .ht-xs-35-f {
    height: 35px !important;
  }
  .ht-xs-35p-f {
    height: 35% !important;
  }
  .ht-xs-40 {
    height: 40px;
  }
  .ht-xs-40p {
    height: 40%;
  }
  .mx-ht-xs-40p {
    max-height: 40%;
  }
  .mn-ht-xs-40p {
    min-height: 40%;
  }
  .ht-xs-40-f {
    height: 40px !important;
  }
  .ht-xs-40p-f {
    height: 40% !important;
  }
  .ht-xs-45 {
    height: 45px;
  }
  .ht-xs-45p {
    height: 45%;
  }
  .mx-ht-xs-45p {
    max-height: 45%;
  }
  .mn-ht-xs-45p {
    min-height: 45%;
  }
  .ht-xs-45-f {
    height: 45px !important;
  }
  .ht-xs-45p-f {
    height: 45% !important;
  }
  .ht-xs-50 {
    height: 50px;
  }
  .ht-xs-50p {
    height: 50%;
  }
  .mx-ht-xs-50p {
    max-height: 50%;
  }
  .mn-ht-xs-50p {
    min-height: 50%;
  }
  .ht-xs-50-f {
    height: 50px !important;
  }
  .ht-xs-50p-f {
    height: 50% !important;
  }
  .ht-xs-55 {
    height: 55px;
  }
  .ht-xs-55p {
    height: 55%;
  }
  .mx-ht-xs-55p {
    max-height: 55%;
  }
  .mn-ht-xs-55p {
    min-height: 55%;
  }
  .ht-xs-55-f {
    height: 55px !important;
  }
  .ht-xs-55p-f {
    height: 55% !important;
  }
  .ht-xs-60 {
    height: 60px;
  }
  .ht-xs-60p {
    height: 60%;
  }
  .mx-ht-xs-60p {
    max-height: 60%;
  }
  .mn-ht-xs-60p {
    min-height: 60%;
  }
  .ht-xs-60-f {
    height: 60px !important;
  }
  .ht-xs-60p-f {
    height: 60% !important;
  }
  .ht-xs-65 {
    height: 65px;
  }
  .ht-xs-65p {
    height: 65%;
  }
  .mx-ht-xs-65p {
    max-height: 65%;
  }
  .mn-ht-xs-65p {
    min-height: 65%;
  }
  .ht-xs-65-f {
    height: 65px !important;
  }
  .ht-xs-65p-f {
    height: 65% !important;
  }
  .ht-xs-70 {
    height: 70px;
  }
  .ht-xs-70p {
    height: 70%;
  }
  .mx-ht-xs-70p {
    max-height: 70%;
  }
  .mn-ht-xs-70p {
    min-height: 70%;
  }
  .ht-xs-70-f {
    height: 70px !important;
  }
  .ht-xs-70p-f {
    height: 70% !important;
  }
  .ht-xs-75 {
    height: 75px;
  }
  .ht-xs-75p {
    height: 75%;
  }
  .mx-ht-xs-75p {
    max-height: 75%;
  }
  .mn-ht-xs-75p {
    min-height: 75%;
  }
  .ht-xs-75-f {
    height: 75px !important;
  }
  .ht-xs-75p-f {
    height: 75% !important;
  }
  .ht-xs-80 {
    height: 80px;
  }
  .ht-xs-80p {
    height: 80%;
  }
  .mx-ht-xs-80p {
    max-height: 80%;
  }
  .mn-ht-xs-80p {
    min-height: 80%;
  }
  .ht-xs-80-f {
    height: 80px !important;
  }
  .ht-xs-80p-f {
    height: 80% !important;
  }
  .ht-xs-85 {
    height: 85px;
  }
  .ht-xs-85p {
    height: 85%;
  }
  .mx-ht-xs-85p {
    max-height: 85%;
  }
  .mn-ht-xs-85p {
    min-height: 85%;
  }
  .ht-xs-85-f {
    height: 85px !important;
  }
  .ht-xs-85p-f {
    height: 85% !important;
  }
  .ht-xs-90 {
    height: 90px;
  }
  .ht-xs-90p {
    height: 90%;
  }
  .mx-ht-xs-90p {
    max-height: 90%;
  }
  .mn-ht-xs-90p {
    min-height: 90%;
  }
  .ht-xs-90-f {
    height: 90px !important;
  }
  .ht-xs-90p-f {
    height: 90% !important;
  }
  .ht-xs-95 {
    height: 95px;
  }
  .ht-xs-95p {
    height: 95%;
  }
  .mx-ht-xs-95p {
    max-height: 95%;
  }
  .mn-ht-xs-95p {
    min-height: 95%;
  }
  .ht-xs-95-f {
    height: 95px !important;
  }
  .ht-xs-95p-f {
    height: 95% !important;
  }
  .ht-xs-100 {
    height: 100px;
  }
  .ht-xs-100p {
    height: 100%;
  }
  .mx-ht-xs-100p {
    max-height: 100%;
  }
  .mn-ht-xs-100p {
    min-height: 100%;
  }
  .ht-xs-100-f {
    height: 100px !important;
  }
  .ht-xs-100p-f {
    height: 100% !important;
  }
  .ht-xs-150 {
    height: 150px;
  }
  .ht-xs-150p {
    height: 150%;
  }
  .mx-ht-xs-150p {
    max-height: 150%;
  }
  .mn-ht-xs-150p {
    min-height: 150%;
  }
  .ht-xs-150-f {
    height: 150px !important;
  }
  .ht-xs-150p-f {
    height: 150% !important;
  }
  .ht-xs-200 {
    height: 200px;
  }
  .ht-xs-200p {
    height: 200%;
  }
  .mx-ht-xs-200p {
    max-height: 200%;
  }
  .mn-ht-xs-200p {
    min-height: 200%;
  }
  .ht-xs-200-f {
    height: 200px !important;
  }
  .ht-xs-200p-f {
    height: 200% !important;
  }
  .ht-xs-250 {
    height: 250px;
  }
  .ht-xs-250p {
    height: 250%;
  }
  .mx-ht-xs-250p {
    max-height: 250%;
  }
  .mn-ht-xs-250p {
    min-height: 250%;
  }
  .ht-xs-250-f {
    height: 250px !important;
  }
  .ht-xs-250p-f {
    height: 250% !important;
  }
  .ht-xs-300 {
    height: 300px;
  }
  .ht-xs-300p {
    height: 300%;
  }
  .mx-ht-xs-300p {
    max-height: 300%;
  }
  .mn-ht-xs-300p {
    min-height: 300%;
  }
  .ht-xs-300-f {
    height: 300px !important;
  }
  .ht-xs-300p-f {
    height: 300% !important;
  }
  .ht-xs-350 {
    height: 350px;
  }
  .ht-xs-350p {
    height: 350%;
  }
  .mx-ht-xs-350p {
    max-height: 350%;
  }
  .mn-ht-xs-350p {
    min-height: 350%;
  }
  .ht-xs-350-f {
    height: 350px !important;
  }
  .ht-xs-350p-f {
    height: 350% !important;
  }
  .ht-xs-400 {
    height: 400px;
  }
  .ht-xs-400p {
    height: 400%;
  }
  .mx-ht-xs-400p {
    max-height: 400%;
  }
  .mn-ht-xs-400p {
    min-height: 400%;
  }
  .ht-xs-400-f {
    height: 400px !important;
  }
  .ht-xs-400p-f {
    height: 400% !important;
  }
  .ht-xs-450 {
    height: 450px;
  }
  .ht-xs-450p {
    height: 450%;
  }
  .mx-ht-xs-450p {
    max-height: 450%;
  }
  .mn-ht-xs-450p {
    min-height: 450%;
  }
  .ht-xs-450-f {
    height: 450px !important;
  }
  .ht-xs-450p-f {
    height: 450% !important;
  }
  .ht-xs-500 {
    height: 500px;
  }
  .ht-xs-500p {
    height: 500%;
  }
  .mx-ht-xs-500p {
    max-height: 500%;
  }
  .mn-ht-xs-500p {
    min-height: 500%;
  }
  .ht-xs-500-f {
    height: 500px !important;
  }
  .ht-xs-500p-f {
    height: 500% !important;
  }
  .ht-xs-550 {
    height: 550px;
  }
  .ht-xs-550p {
    height: 550%;
  }
  .mx-ht-xs-550p {
    max-height: 550%;
  }
  .mn-ht-xs-550p {
    min-height: 550%;
  }
  .ht-xs-550-f {
    height: 550px !important;
  }
  .ht-xs-550p-f {
    height: 550% !important;
  }
  .ht-xs-600 {
    height: 600px;
  }
  .ht-xs-600p {
    height: 600%;
  }
  .mx-ht-xs-600p {
    max-height: 600%;
  }
  .mn-ht-xs-600p {
    min-height: 600%;
  }
  .ht-xs-600-f {
    height: 600px !important;
  }
  .ht-xs-600p-f {
    height: 600% !important;
  }
  .ht-xs-650 {
    height: 650px;
  }
  .ht-xs-650p {
    height: 650%;
  }
  .mx-ht-xs-650p {
    max-height: 650%;
  }
  .mn-ht-xs-650p {
    min-height: 650%;
  }
  .ht-xs-650-f {
    height: 650px !important;
  }
  .ht-xs-650p-f {
    height: 650% !important;
  }
  .ht-xs-700 {
    height: 700px;
  }
  .ht-xs-700p {
    height: 700%;
  }
  .mx-ht-xs-700p {
    max-height: 700%;
  }
  .mn-ht-xs-700p {
    min-height: 700%;
  }
  .ht-xs-700-f {
    height: 700px !important;
  }
  .ht-xs-700p-f {
    height: 700% !important;
  }
  .ht-xs-750 {
    height: 750px;
  }
  .ht-xs-750p {
    height: 750%;
  }
  .mx-ht-xs-750p {
    max-height: 750%;
  }
  .mn-ht-xs-750p {
    min-height: 750%;
  }
  .ht-xs-750-f {
    height: 750px !important;
  }
  .ht-xs-750p-f {
    height: 750% !important;
  }
  .ht-xs-800 {
    height: 800px;
  }
  .ht-xs-800p {
    height: 800%;
  }
  .mx-ht-xs-800p {
    max-height: 800%;
  }
  .mn-ht-xs-800p {
    min-height: 800%;
  }
  .ht-xs-800-f {
    height: 800px !important;
  }
  .ht-xs-800p-f {
    height: 800% !important;
  }
  .ht-xs-850 {
    height: 850px;
  }
  .ht-xs-850p {
    height: 850%;
  }
  .mx-ht-xs-850p {
    max-height: 850%;
  }
  .mn-ht-xs-850p {
    min-height: 850%;
  }
  .ht-xs-850-f {
    height: 850px !important;
  }
  .ht-xs-850p-f {
    height: 850% !important;
  }
  .ht-xs-100v {
    height: 100vh;
  }
}

@media (min-width: 576px) {
  .ht-sm-5 {
    height: 5px;
  }
  .ht-sm-5p {
    height: 5%;
  }
  .mx-ht-sm-5p {
    max-height: 5%;
  }
  .mn-ht-sm-5p {
    min-height: 5%;
  }
  .ht-sm-5-f {
    height: 5px !important;
  }
  .ht-sm-5p-f {
    height: 5% !important;
  }
  .ht-sm-10 {
    height: 10px;
  }
  .ht-sm-10p {
    height: 10%;
  }
  .mx-ht-sm-10p {
    max-height: 10%;
  }
  .mn-ht-sm-10p {
    min-height: 10%;
  }
  .ht-sm-10-f {
    height: 10px !important;
  }
  .ht-sm-10p-f {
    height: 10% !important;
  }
  .ht-sm-15 {
    height: 15px;
  }
  .ht-sm-15p {
    height: 15%;
  }
  .mx-ht-sm-15p {
    max-height: 15%;
  }
  .mn-ht-sm-15p {
    min-height: 15%;
  }
  .ht-sm-15-f {
    height: 15px !important;
  }
  .ht-sm-15p-f {
    height: 15% !important;
  }
  .ht-sm-20 {
    height: 20px;
  }
  .ht-sm-20p {
    height: 20%;
  }
  .mx-ht-sm-20p {
    max-height: 20%;
  }
  .mn-ht-sm-20p {
    min-height: 20%;
  }
  .ht-sm-20-f {
    height: 20px !important;
  }
  .ht-sm-20p-f {
    height: 20% !important;
  }
  .ht-sm-25 {
    height: 25px;
  }
  .ht-sm-25p {
    height: 25%;
  }
  .mx-ht-sm-25p {
    max-height: 25%;
  }
  .mn-ht-sm-25p {
    min-height: 25%;
  }
  .ht-sm-25-f {
    height: 25px !important;
  }
  .ht-sm-25p-f {
    height: 25% !important;
  }
  .ht-sm-30 {
    height: 30px;
  }
  .ht-sm-30p {
    height: 30%;
  }
  .mx-ht-sm-30p {
    max-height: 30%;
  }
  .mn-ht-sm-30p {
    min-height: 30%;
  }
  .ht-sm-30-f {
    height: 30px !important;
  }
  .ht-sm-30p-f {
    height: 30% !important;
  }
  .ht-sm-35 {
    height: 35px;
  }
  .ht-sm-35p {
    height: 35%;
  }
  .mx-ht-sm-35p {
    max-height: 35%;
  }
  .mn-ht-sm-35p {
    min-height: 35%;
  }
  .ht-sm-35-f {
    height: 35px !important;
  }
  .ht-sm-35p-f {
    height: 35% !important;
  }
  .ht-sm-40 {
    height: 40px;
  }
  .ht-sm-40p {
    height: 40%;
  }
  .mx-ht-sm-40p {
    max-height: 40%;
  }
  .mn-ht-sm-40p {
    min-height: 40%;
  }
  .ht-sm-40-f {
    height: 40px !important;
  }
  .ht-sm-40p-f {
    height: 40% !important;
  }
  .ht-sm-45 {
    height: 45px;
  }
  .ht-sm-45p {
    height: 45%;
  }
  .mx-ht-sm-45p {
    max-height: 45%;
  }
  .mn-ht-sm-45p {
    min-height: 45%;
  }
  .ht-sm-45-f {
    height: 45px !important;
  }
  .ht-sm-45p-f {
    height: 45% !important;
  }
  .ht-sm-50 {
    height: 50px;
  }
  .ht-sm-50p {
    height: 50%;
  }
  .mx-ht-sm-50p {
    max-height: 50%;
  }
  .mn-ht-sm-50p {
    min-height: 50%;
  }
  .ht-sm-50-f {
    height: 50px !important;
  }
  .ht-sm-50p-f {
    height: 50% !important;
  }
  .ht-sm-55 {
    height: 55px;
  }
  .ht-sm-55p {
    height: 55%;
  }
  .mx-ht-sm-55p {
    max-height: 55%;
  }
  .mn-ht-sm-55p {
    min-height: 55%;
  }
  .ht-sm-55-f {
    height: 55px !important;
  }
  .ht-sm-55p-f {
    height: 55% !important;
  }
  .ht-sm-60 {
    height: 60px;
  }
  .ht-sm-60p {
    height: 60%;
  }
  .mx-ht-sm-60p {
    max-height: 60%;
  }
  .mn-ht-sm-60p {
    min-height: 60%;
  }
  .ht-sm-60-f {
    height: 60px !important;
  }
  .ht-sm-60p-f {
    height: 60% !important;
  }
  .ht-sm-65 {
    height: 65px;
  }
  .ht-sm-65p {
    height: 65%;
  }
  .mx-ht-sm-65p {
    max-height: 65%;
  }
  .mn-ht-sm-65p {
    min-height: 65%;
  }
  .ht-sm-65-f {
    height: 65px !important;
  }
  .ht-sm-65p-f {
    height: 65% !important;
  }
  .ht-sm-70 {
    height: 70px;
  }
  .ht-sm-70p {
    height: 70%;
  }
  .mx-ht-sm-70p {
    max-height: 70%;
  }
  .mn-ht-sm-70p {
    min-height: 70%;
  }
  .ht-sm-70-f {
    height: 70px !important;
  }
  .ht-sm-70p-f {
    height: 70% !important;
  }
  .ht-sm-75 {
    height: 75px;
  }
  .ht-sm-75p {
    height: 75%;
  }
  .mx-ht-sm-75p {
    max-height: 75%;
  }
  .mn-ht-sm-75p {
    min-height: 75%;
  }
  .ht-sm-75-f {
    height: 75px !important;
  }
  .ht-sm-75p-f {
    height: 75% !important;
  }
  .ht-sm-80 {
    height: 80px;
  }
  .ht-sm-80p {
    height: 80%;
  }
  .mx-ht-sm-80p {
    max-height: 80%;
  }
  .mn-ht-sm-80p {
    min-height: 80%;
  }
  .ht-sm-80-f {
    height: 80px !important;
  }
  .ht-sm-80p-f {
    height: 80% !important;
  }
  .ht-sm-85 {
    height: 85px;
  }
  .ht-sm-85p {
    height: 85%;
  }
  .mx-ht-sm-85p {
    max-height: 85%;
  }
  .mn-ht-sm-85p {
    min-height: 85%;
  }
  .ht-sm-85-f {
    height: 85px !important;
  }
  .ht-sm-85p-f {
    height: 85% !important;
  }
  .ht-sm-90 {
    height: 90px;
  }
  .ht-sm-90p {
    height: 90%;
  }
  .mx-ht-sm-90p {
    max-height: 90%;
  }
  .mn-ht-sm-90p {
    min-height: 90%;
  }
  .ht-sm-90-f {
    height: 90px !important;
  }
  .ht-sm-90p-f {
    height: 90% !important;
  }
  .ht-sm-95 {
    height: 95px;
  }
  .ht-sm-95p {
    height: 95%;
  }
  .mx-ht-sm-95p {
    max-height: 95%;
  }
  .mn-ht-sm-95p {
    min-height: 95%;
  }
  .ht-sm-95-f {
    height: 95px !important;
  }
  .ht-sm-95p-f {
    height: 95% !important;
  }
  .ht-sm-100 {
    height: 100px;
  }
  .ht-sm-100p {
    height: 100%;
  }
  .mx-ht-sm-100p {
    max-height: 100%;
  }
  .mn-ht-sm-100p {
    min-height: 100%;
  }
  .ht-sm-100-f {
    height: 100px !important;
  }
  .ht-sm-100p-f {
    height: 100% !important;
  }
  .ht-sm-150 {
    height: 150px;
  }
  .ht-sm-150p {
    height: 150%;
  }
  .mx-ht-sm-150p {
    max-height: 150%;
  }
  .mn-ht-sm-150p {
    min-height: 150%;
  }
  .ht-sm-150-f {
    height: 150px !important;
  }
  .ht-sm-150p-f {
    height: 150% !important;
  }
  .ht-sm-200 {
    height: 200px;
  }
  .ht-sm-200p {
    height: 200%;
  }
  .mx-ht-sm-200p {
    max-height: 200%;
  }
  .mn-ht-sm-200p {
    min-height: 200%;
  }
  .ht-sm-200-f {
    height: 200px !important;
  }
  .ht-sm-200p-f {
    height: 200% !important;
  }
  .ht-sm-250 {
    height: 250px;
  }
  .ht-sm-250p {
    height: 250%;
  }
  .mx-ht-sm-250p {
    max-height: 250%;
  }
  .mn-ht-sm-250p {
    min-height: 250%;
  }
  .ht-sm-250-f {
    height: 250px !important;
  }
  .ht-sm-250p-f {
    height: 250% !important;
  }
  .ht-sm-300 {
    height: 300px;
  }
  .ht-sm-300p {
    height: 300%;
  }
  .mx-ht-sm-300p {
    max-height: 300%;
  }
  .mn-ht-sm-300p {
    min-height: 300%;
  }
  .ht-sm-300-f {
    height: 300px !important;
  }
  .ht-sm-300p-f {
    height: 300% !important;
  }
  .ht-sm-350 {
    height: 350px;
  }
  .ht-sm-350p {
    height: 350%;
  }
  .mx-ht-sm-350p {
    max-height: 350%;
  }
  .mn-ht-sm-350p {
    min-height: 350%;
  }
  .ht-sm-350-f {
    height: 350px !important;
  }
  .ht-sm-350p-f {
    height: 350% !important;
  }
  .ht-sm-400 {
    height: 400px;
  }
  .ht-sm-400p {
    height: 400%;
  }
  .mx-ht-sm-400p {
    max-height: 400%;
  }
  .mn-ht-sm-400p {
    min-height: 400%;
  }
  .ht-sm-400-f {
    height: 400px !important;
  }
  .ht-sm-400p-f {
    height: 400% !important;
  }
  .ht-sm-450 {
    height: 450px;
  }
  .ht-sm-450p {
    height: 450%;
  }
  .mx-ht-sm-450p {
    max-height: 450%;
  }
  .mn-ht-sm-450p {
    min-height: 450%;
  }
  .ht-sm-450-f {
    height: 450px !important;
  }
  .ht-sm-450p-f {
    height: 450% !important;
  }
  .ht-sm-500 {
    height: 500px;
  }
  .ht-sm-500p {
    height: 500%;
  }
  .mx-ht-sm-500p {
    max-height: 500%;
  }
  .mn-ht-sm-500p {
    min-height: 500%;
  }
  .ht-sm-500-f {
    height: 500px !important;
  }
  .ht-sm-500p-f {
    height: 500% !important;
  }
  .ht-sm-550 {
    height: 550px;
  }
  .ht-sm-550p {
    height: 550%;
  }
  .mx-ht-sm-550p {
    max-height: 550%;
  }
  .mn-ht-sm-550p {
    min-height: 550%;
  }
  .ht-sm-550-f {
    height: 550px !important;
  }
  .ht-sm-550p-f {
    height: 550% !important;
  }
  .ht-sm-600 {
    height: 600px;
  }
  .ht-sm-600p {
    height: 600%;
  }
  .mx-ht-sm-600p {
    max-height: 600%;
  }
  .mn-ht-sm-600p {
    min-height: 600%;
  }
  .ht-sm-600-f {
    height: 600px !important;
  }
  .ht-sm-600p-f {
    height: 600% !important;
  }
  .ht-sm-650 {
    height: 650px;
  }
  .ht-sm-650p {
    height: 650%;
  }
  .mx-ht-sm-650p {
    max-height: 650%;
  }
  .mn-ht-sm-650p {
    min-height: 650%;
  }
  .ht-sm-650-f {
    height: 650px !important;
  }
  .ht-sm-650p-f {
    height: 650% !important;
  }
  .ht-sm-700 {
    height: 700px;
  }
  .ht-sm-700p {
    height: 700%;
  }
  .mx-ht-sm-700p {
    max-height: 700%;
  }
  .mn-ht-sm-700p {
    min-height: 700%;
  }
  .ht-sm-700-f {
    height: 700px !important;
  }
  .ht-sm-700p-f {
    height: 700% !important;
  }
  .ht-sm-750 {
    height: 750px;
  }
  .ht-sm-750p {
    height: 750%;
  }
  .mx-ht-sm-750p {
    max-height: 750%;
  }
  .mn-ht-sm-750p {
    min-height: 750%;
  }
  .ht-sm-750-f {
    height: 750px !important;
  }
  .ht-sm-750p-f {
    height: 750% !important;
  }
  .ht-sm-800 {
    height: 800px;
  }
  .ht-sm-800p {
    height: 800%;
  }
  .mx-ht-sm-800p {
    max-height: 800%;
  }
  .mn-ht-sm-800p {
    min-height: 800%;
  }
  .ht-sm-800-f {
    height: 800px !important;
  }
  .ht-sm-800p-f {
    height: 800% !important;
  }
  .ht-sm-850 {
    height: 850px;
  }
  .ht-sm-850p {
    height: 850%;
  }
  .mx-ht-sm-850p {
    max-height: 850%;
  }
  .mn-ht-sm-850p {
    min-height: 850%;
  }
  .ht-sm-850-f {
    height: 850px !important;
  }
  .ht-sm-850p-f {
    height: 850% !important;
  }
  .ht-sm-100v {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .ht-md-5 {
    height: 5px;
  }
  .ht-md-5p {
    height: 5%;
  }
  .mx-ht-md-5p {
    max-height: 5%;
  }
  .mn-ht-md-5p {
    min-height: 5%;
  }
  .ht-md-5-f {
    height: 5px !important;
  }
  .ht-md-5p-f {
    height: 5% !important;
  }
  .ht-md-10 {
    height: 10px;
  }
  .ht-md-10p {
    height: 10%;
  }
  .mx-ht-md-10p {
    max-height: 10%;
  }
  .mn-ht-md-10p {
    min-height: 10%;
  }
  .ht-md-10-f {
    height: 10px !important;
  }
  .ht-md-10p-f {
    height: 10% !important;
  }
  .ht-md-15 {
    height: 15px;
  }
  .ht-md-15p {
    height: 15%;
  }
  .mx-ht-md-15p {
    max-height: 15%;
  }
  .mn-ht-md-15p {
    min-height: 15%;
  }
  .ht-md-15-f {
    height: 15px !important;
  }
  .ht-md-15p-f {
    height: 15% !important;
  }
  .ht-md-20 {
    height: 20px;
  }
  .ht-md-20p {
    height: 20%;
  }
  .mx-ht-md-20p {
    max-height: 20%;
  }
  .mn-ht-md-20p {
    min-height: 20%;
  }
  .ht-md-20-f {
    height: 20px !important;
  }
  .ht-md-20p-f {
    height: 20% !important;
  }
  .ht-md-25 {
    height: 25px;
  }
  .ht-md-25p {
    height: 25%;
  }
  .mx-ht-md-25p {
    max-height: 25%;
  }
  .mn-ht-md-25p {
    min-height: 25%;
  }
  .ht-md-25-f {
    height: 25px !important;
  }
  .ht-md-25p-f {
    height: 25% !important;
  }
  .ht-md-30 {
    height: 30px;
  }
  .ht-md-30p {
    height: 30%;
  }
  .mx-ht-md-30p {
    max-height: 30%;
  }
  .mn-ht-md-30p {
    min-height: 30%;
  }
  .ht-md-30-f {
    height: 30px !important;
  }
  .ht-md-30p-f {
    height: 30% !important;
  }
  .ht-md-35 {
    height: 35px;
  }
  .ht-md-35p {
    height: 35%;
  }
  .mx-ht-md-35p {
    max-height: 35%;
  }
  .mn-ht-md-35p {
    min-height: 35%;
  }
  .ht-md-35-f {
    height: 35px !important;
  }
  .ht-md-35p-f {
    height: 35% !important;
  }
  .ht-md-40 {
    height: 40px;
  }
  .ht-md-40p {
    height: 40%;
  }
  .mx-ht-md-40p {
    max-height: 40%;
  }
  .mn-ht-md-40p {
    min-height: 40%;
  }
  .ht-md-40-f {
    height: 40px !important;
  }
  .ht-md-40p-f {
    height: 40% !important;
  }
  .ht-md-45 {
    height: 45px;
  }
  .ht-md-45p {
    height: 45%;
  }
  .mx-ht-md-45p {
    max-height: 45%;
  }
  .mn-ht-md-45p {
    min-height: 45%;
  }
  .ht-md-45-f {
    height: 45px !important;
  }
  .ht-md-45p-f {
    height: 45% !important;
  }
  .ht-md-50 {
    height: 50px;
  }
  .ht-md-50p {
    height: 50%;
  }
  .mx-ht-md-50p {
    max-height: 50%;
  }
  .mn-ht-md-50p {
    min-height: 50%;
  }
  .ht-md-50-f {
    height: 50px !important;
  }
  .ht-md-50p-f {
    height: 50% !important;
  }
  .ht-md-55 {
    height: 55px;
  }
  .ht-md-55p {
    height: 55%;
  }
  .mx-ht-md-55p {
    max-height: 55%;
  }
  .mn-ht-md-55p {
    min-height: 55%;
  }
  .ht-md-55-f {
    height: 55px !important;
  }
  .ht-md-55p-f {
    height: 55% !important;
  }
  .ht-md-60 {
    height: 60px;
  }
  .ht-md-60p {
    height: 60%;
  }
  .mx-ht-md-60p {
    max-height: 60%;
  }
  .mn-ht-md-60p {
    min-height: 60%;
  }
  .ht-md-60-f {
    height: 60px !important;
  }
  .ht-md-60p-f {
    height: 60% !important;
  }
  .ht-md-65 {
    height: 65px;
  }
  .ht-md-65p {
    height: 65%;
  }
  .mx-ht-md-65p {
    max-height: 65%;
  }
  .mn-ht-md-65p {
    min-height: 65%;
  }
  .ht-md-65-f {
    height: 65px !important;
  }
  .ht-md-65p-f {
    height: 65% !important;
  }
  .ht-md-70 {
    height: 70px;
  }
  .ht-md-70p {
    height: 70%;
  }
  .mx-ht-md-70p {
    max-height: 70%;
  }
  .mn-ht-md-70p {
    min-height: 70%;
  }
  .ht-md-70-f {
    height: 70px !important;
  }
  .ht-md-70p-f {
    height: 70% !important;
  }
  .ht-md-75 {
    height: 75px;
  }
  .ht-md-75p {
    height: 75%;
  }
  .mx-ht-md-75p {
    max-height: 75%;
  }
  .mn-ht-md-75p {
    min-height: 75%;
  }
  .ht-md-75-f {
    height: 75px !important;
  }
  .ht-md-75p-f {
    height: 75% !important;
  }
  .ht-md-80 {
    height: 80px;
  }
  .ht-md-80p {
    height: 80%;
  }
  .mx-ht-md-80p {
    max-height: 80%;
  }
  .mn-ht-md-80p {
    min-height: 80%;
  }
  .ht-md-80-f {
    height: 80px !important;
  }
  .ht-md-80p-f {
    height: 80% !important;
  }
  .ht-md-85 {
    height: 85px;
  }
  .ht-md-85p {
    height: 85%;
  }
  .mx-ht-md-85p {
    max-height: 85%;
  }
  .mn-ht-md-85p {
    min-height: 85%;
  }
  .ht-md-85-f {
    height: 85px !important;
  }
  .ht-md-85p-f {
    height: 85% !important;
  }
  .ht-md-90 {
    height: 90px;
  }
  .ht-md-90p {
    height: 90%;
  }
  .mx-ht-md-90p {
    max-height: 90%;
  }
  .mn-ht-md-90p {
    min-height: 90%;
  }
  .ht-md-90-f {
    height: 90px !important;
  }
  .ht-md-90p-f {
    height: 90% !important;
  }
  .ht-md-95 {
    height: 95px;
  }
  .ht-md-95p {
    height: 95%;
  }
  .mx-ht-md-95p {
    max-height: 95%;
  }
  .mn-ht-md-95p {
    min-height: 95%;
  }
  .ht-md-95-f {
    height: 95px !important;
  }
  .ht-md-95p-f {
    height: 95% !important;
  }
  .ht-md-100 {
    height: 100px;
  }
  .ht-md-100p {
    height: 100%;
  }
  .mx-ht-md-100p {
    max-height: 100%;
  }
  .mn-ht-md-100p {
    min-height: 100%;
  }
  .ht-md-100-f {
    height: 100px !important;
  }
  .ht-md-100p-f {
    height: 100% !important;
  }
  .ht-md-150 {
    height: 150px;
  }
  .ht-md-150p {
    height: 150%;
  }
  .mx-ht-md-150p {
    max-height: 150%;
  }
  .mn-ht-md-150p {
    min-height: 150%;
  }
  .ht-md-150-f {
    height: 150px !important;
  }
  .ht-md-150p-f {
    height: 150% !important;
  }
  .ht-md-200 {
    height: 200px;
  }
  .ht-md-200p {
    height: 200%;
  }
  .mx-ht-md-200p {
    max-height: 200%;
  }
  .mn-ht-md-200p {
    min-height: 200%;
  }
  .ht-md-200-f {
    height: 200px !important;
  }
  .ht-md-200p-f {
    height: 200% !important;
  }
  .ht-md-250 {
    height: 250px;
  }
  .ht-md-250p {
    height: 250%;
  }
  .mx-ht-md-250p {
    max-height: 250%;
  }
  .mn-ht-md-250p {
    min-height: 250%;
  }
  .ht-md-250-f {
    height: 250px !important;
  }
  .ht-md-250p-f {
    height: 250% !important;
  }
  .ht-md-300 {
    height: 300px;
  }
  .ht-md-300p {
    height: 300%;
  }
  .mx-ht-md-300p {
    max-height: 300%;
  }
  .mn-ht-md-300p {
    min-height: 300%;
  }
  .ht-md-300-f {
    height: 300px !important;
  }
  .ht-md-300p-f {
    height: 300% !important;
  }
  .ht-md-350 {
    height: 350px;
  }
  .ht-md-350p {
    height: 350%;
  }
  .mx-ht-md-350p {
    max-height: 350%;
  }
  .mn-ht-md-350p {
    min-height: 350%;
  }
  .ht-md-350-f {
    height: 350px !important;
  }
  .ht-md-350p-f {
    height: 350% !important;
  }
  .ht-md-400 {
    height: 400px;
  }
  .ht-md-400p {
    height: 400%;
  }
  .mx-ht-md-400p {
    max-height: 400%;
  }
  .mn-ht-md-400p {
    min-height: 400%;
  }
  .ht-md-400-f {
    height: 400px !important;
  }
  .ht-md-400p-f {
    height: 400% !important;
  }
  .ht-md-450 {
    height: 450px;
  }
  .ht-md-450p {
    height: 450%;
  }
  .mx-ht-md-450p {
    max-height: 450%;
  }
  .mn-ht-md-450p {
    min-height: 450%;
  }
  .ht-md-450-f {
    height: 450px !important;
  }
  .ht-md-450p-f {
    height: 450% !important;
  }
  .ht-md-500 {
    height: 500px;
  }
  .ht-md-500p {
    height: 500%;
  }
  .mx-ht-md-500p {
    max-height: 500%;
  }
  .mn-ht-md-500p {
    min-height: 500%;
  }
  .ht-md-500-f {
    height: 500px !important;
  }
  .ht-md-500p-f {
    height: 500% !important;
  }
  .ht-md-550 {
    height: 550px;
  }
  .ht-md-550p {
    height: 550%;
  }
  .mx-ht-md-550p {
    max-height: 550%;
  }
  .mn-ht-md-550p {
    min-height: 550%;
  }
  .ht-md-550-f {
    height: 550px !important;
  }
  .ht-md-550p-f {
    height: 550% !important;
  }
  .ht-md-600 {
    height: 600px;
  }
  .ht-md-600p {
    height: 600%;
  }
  .mx-ht-md-600p {
    max-height: 600%;
  }
  .mn-ht-md-600p {
    min-height: 600%;
  }
  .ht-md-600-f {
    height: 600px !important;
  }
  .ht-md-600p-f {
    height: 600% !important;
  }
  .ht-md-650 {
    height: 650px;
  }
  .ht-md-650p {
    height: 650%;
  }
  .mx-ht-md-650p {
    max-height: 650%;
  }
  .mn-ht-md-650p {
    min-height: 650%;
  }
  .ht-md-650-f {
    height: 650px !important;
  }
  .ht-md-650p-f {
    height: 650% !important;
  }
  .ht-md-700 {
    height: 700px;
  }
  .ht-md-700p {
    height: 700%;
  }
  .mx-ht-md-700p {
    max-height: 700%;
  }
  .mn-ht-md-700p {
    min-height: 700%;
  }
  .ht-md-700-f {
    height: 700px !important;
  }
  .ht-md-700p-f {
    height: 700% !important;
  }
  .ht-md-750 {
    height: 750px;
  }
  .ht-md-750p {
    height: 750%;
  }
  .mx-ht-md-750p {
    max-height: 750%;
  }
  .mn-ht-md-750p {
    min-height: 750%;
  }
  .ht-md-750-f {
    height: 750px !important;
  }
  .ht-md-750p-f {
    height: 750% !important;
  }
  .ht-md-800 {
    height: 800px;
  }
  .ht-md-800p {
    height: 800%;
  }
  .mx-ht-md-800p {
    max-height: 800%;
  }
  .mn-ht-md-800p {
    min-height: 800%;
  }
  .ht-md-800-f {
    height: 800px !important;
  }
  .ht-md-800p-f {
    height: 800% !important;
  }
  .ht-md-850 {
    height: 850px;
  }
  .ht-md-850p {
    height: 850%;
  }
  .mx-ht-md-850p {
    max-height: 850%;
  }
  .mn-ht-md-850p {
    min-height: 850%;
  }
  .ht-md-850-f {
    height: 850px !important;
  }
  .ht-md-850p-f {
    height: 850% !important;
  }
  .ht-md-100v {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .ht-lg-5 {
    height: 5px;
  }
  .ht-lg-5p {
    height: 5%;
  }
  .mx-ht-lg-5p {
    max-height: 5%;
  }
  .mn-ht-lg-5p {
    min-height: 5%;
  }
  .ht-lg-5-f {
    height: 5px !important;
  }
  .ht-lg-5p-f {
    height: 5% !important;
  }
  .ht-lg-10 {
    height: 10px;
  }
  .ht-lg-10p {
    height: 10%;
  }
  .mx-ht-lg-10p {
    max-height: 10%;
  }
  .mn-ht-lg-10p {
    min-height: 10%;
  }
  .ht-lg-10-f {
    height: 10px !important;
  }
  .ht-lg-10p-f {
    height: 10% !important;
  }
  .ht-lg-15 {
    height: 15px;
  }
  .ht-lg-15p {
    height: 15%;
  }
  .mx-ht-lg-15p {
    max-height: 15%;
  }
  .mn-ht-lg-15p {
    min-height: 15%;
  }
  .ht-lg-15-f {
    height: 15px !important;
  }
  .ht-lg-15p-f {
    height: 15% !important;
  }
  .ht-lg-20 {
    height: 20px;
  }
  .ht-lg-20p {
    height: 20%;
  }
  .mx-ht-lg-20p {
    max-height: 20%;
  }
  .mn-ht-lg-20p {
    min-height: 20%;
  }
  .ht-lg-20-f {
    height: 20px !important;
  }
  .ht-lg-20p-f {
    height: 20% !important;
  }
  .ht-lg-25 {
    height: 25px;
  }
  .ht-lg-25p {
    height: 25%;
  }
  .mx-ht-lg-25p {
    max-height: 25%;
  }
  .mn-ht-lg-25p {
    min-height: 25%;
  }
  .ht-lg-25-f {
    height: 25px !important;
  }
  .ht-lg-25p-f {
    height: 25% !important;
  }
  .ht-lg-30 {
    height: 30px;
  }
  .ht-lg-30p {
    height: 30%;
  }
  .mx-ht-lg-30p {
    max-height: 30%;
  }
  .mn-ht-lg-30p {
    min-height: 30%;
  }
  .ht-lg-30-f {
    height: 30px !important;
  }
  .ht-lg-30p-f {
    height: 30% !important;
  }
  .ht-lg-35 {
    height: 35px;
  }
  .ht-lg-35p {
    height: 35%;
  }
  .mx-ht-lg-35p {
    max-height: 35%;
  }
  .mn-ht-lg-35p {
    min-height: 35%;
  }
  .ht-lg-35-f {
    height: 35px !important;
  }
  .ht-lg-35p-f {
    height: 35% !important;
  }
  .ht-lg-40 {
    height: 40px;
  }
  .ht-lg-40p {
    height: 40%;
  }
  .mx-ht-lg-40p {
    max-height: 40%;
  }
  .mn-ht-lg-40p {
    min-height: 40%;
  }
  .ht-lg-40-f {
    height: 40px !important;
  }
  .ht-lg-40p-f {
    height: 40% !important;
  }
  .ht-lg-45 {
    height: 45px;
  }
  .ht-lg-45p {
    height: 45%;
  }
  .mx-ht-lg-45p {
    max-height: 45%;
  }
  .mn-ht-lg-45p {
    min-height: 45%;
  }
  .ht-lg-45-f {
    height: 45px !important;
  }
  .ht-lg-45p-f {
    height: 45% !important;
  }
  .ht-lg-50 {
    height: 50px;
  }
  .ht-lg-50p {
    height: 50%;
  }
  .mx-ht-lg-50p {
    max-height: 50%;
  }
  .mn-ht-lg-50p {
    min-height: 50%;
  }
  .ht-lg-50-f {
    height: 50px !important;
  }
  .ht-lg-50p-f {
    height: 50% !important;
  }
  .ht-lg-55 {
    height: 55px;
  }
  .ht-lg-55p {
    height: 55%;
  }
  .mx-ht-lg-55p {
    max-height: 55%;
  }
  .mn-ht-lg-55p {
    min-height: 55%;
  }
  .ht-lg-55-f {
    height: 55px !important;
  }
  .ht-lg-55p-f {
    height: 55% !important;
  }
  .ht-lg-60 {
    height: 60px;
  }
  .ht-lg-60p {
    height: 60%;
  }
  .mx-ht-lg-60p {
    max-height: 60%;
  }
  .mn-ht-lg-60p {
    min-height: 60%;
  }
  .ht-lg-60-f {
    height: 60px !important;
  }
  .ht-lg-60p-f {
    height: 60% !important;
  }
  .ht-lg-65 {
    height: 65px;
  }
  .ht-lg-65p {
    height: 65%;
  }
  .mx-ht-lg-65p {
    max-height: 65%;
  }
  .mn-ht-lg-65p {
    min-height: 65%;
  }
  .ht-lg-65-f {
    height: 65px !important;
  }
  .ht-lg-65p-f {
    height: 65% !important;
  }
  .ht-lg-70 {
    height: 70px;
  }
  .ht-lg-70p {
    height: 70%;
  }
  .mx-ht-lg-70p {
    max-height: 70%;
  }
  .mn-ht-lg-70p {
    min-height: 70%;
  }
  .ht-lg-70-f {
    height: 70px !important;
  }
  .ht-lg-70p-f {
    height: 70% !important;
  }
  .ht-lg-75 {
    height: 75px;
  }
  .ht-lg-75p {
    height: 75%;
  }
  .mx-ht-lg-75p {
    max-height: 75%;
  }
  .mn-ht-lg-75p {
    min-height: 75%;
  }
  .ht-lg-75-f {
    height: 75px !important;
  }
  .ht-lg-75p-f {
    height: 75% !important;
  }
  .ht-lg-80 {
    height: 80px;
  }
  .ht-lg-80p {
    height: 80%;
  }
  .mx-ht-lg-80p {
    max-height: 80%;
  }
  .mn-ht-lg-80p {
    min-height: 80%;
  }
  .ht-lg-80-f {
    height: 80px !important;
  }
  .ht-lg-80p-f {
    height: 80% !important;
  }
  .ht-lg-85 {
    height: 85px;
  }
  .ht-lg-85p {
    height: 85%;
  }
  .mx-ht-lg-85p {
    max-height: 85%;
  }
  .mn-ht-lg-85p {
    min-height: 85%;
  }
  .ht-lg-85-f {
    height: 85px !important;
  }
  .ht-lg-85p-f {
    height: 85% !important;
  }
  .ht-lg-90 {
    height: 90px;
  }
  .ht-lg-90p {
    height: 90%;
  }
  .mx-ht-lg-90p {
    max-height: 90%;
  }
  .mn-ht-lg-90p {
    min-height: 90%;
  }
  .ht-lg-90-f {
    height: 90px !important;
  }
  .ht-lg-90p-f {
    height: 90% !important;
  }
  .ht-lg-95 {
    height: 95px;
  }
  .ht-lg-95p {
    height: 95%;
  }
  .mx-ht-lg-95p {
    max-height: 95%;
  }
  .mn-ht-lg-95p {
    min-height: 95%;
  }
  .ht-lg-95-f {
    height: 95px !important;
  }
  .ht-lg-95p-f {
    height: 95% !important;
  }
  .ht-lg-100 {
    height: 100px;
  }
  .ht-lg-100p {
    height: 100%;
  }
  .mx-ht-lg-100p {
    max-height: 100%;
  }
  .mn-ht-lg-100p {
    min-height: 100%;
  }
  .ht-lg-100-f {
    height: 100px !important;
  }
  .ht-lg-100p-f {
    height: 100% !important;
  }
  .ht-lg-150 {
    height: 150px;
  }
  .ht-lg-150p {
    height: 150%;
  }
  .mx-ht-lg-150p {
    max-height: 150%;
  }
  .mn-ht-lg-150p {
    min-height: 150%;
  }
  .ht-lg-150-f {
    height: 150px !important;
  }
  .ht-lg-150p-f {
    height: 150% !important;
  }
  .ht-lg-200 {
    height: 200px;
  }
  .ht-lg-200p {
    height: 200%;
  }
  .mx-ht-lg-200p {
    max-height: 200%;
  }
  .mn-ht-lg-200p {
    min-height: 200%;
  }
  .ht-lg-200-f {
    height: 200px !important;
  }
  .ht-lg-200p-f {
    height: 200% !important;
  }
  .ht-lg-250 {
    height: 250px;
  }
  .ht-lg-250p {
    height: 250%;
  }
  .mx-ht-lg-250p {
    max-height: 250%;
  }
  .mn-ht-lg-250p {
    min-height: 250%;
  }
  .ht-lg-250-f {
    height: 250px !important;
  }
  .ht-lg-250p-f {
    height: 250% !important;
  }
  .ht-lg-300 {
    height: 300px;
  }
  .ht-lg-300p {
    height: 300%;
  }
  .mx-ht-lg-300p {
    max-height: 300%;
  }
  .mn-ht-lg-300p {
    min-height: 300%;
  }
  .ht-lg-300-f {
    height: 300px !important;
  }
  .ht-lg-300p-f {
    height: 300% !important;
  }
  .ht-lg-350 {
    height: 350px;
  }
  .ht-lg-350p {
    height: 350%;
  }
  .mx-ht-lg-350p {
    max-height: 350%;
  }
  .mn-ht-lg-350p {
    min-height: 350%;
  }
  .ht-lg-350-f {
    height: 350px !important;
  }
  .ht-lg-350p-f {
    height: 350% !important;
  }
  .ht-lg-400 {
    height: 400px;
  }
  .ht-lg-400p {
    height: 400%;
  }
  .mx-ht-lg-400p {
    max-height: 400%;
  }
  .mn-ht-lg-400p {
    min-height: 400%;
  }
  .ht-lg-400-f {
    height: 400px !important;
  }
  .ht-lg-400p-f {
    height: 400% !important;
  }
  .ht-lg-450 {
    height: 450px;
  }
  .ht-lg-450p {
    height: 450%;
  }
  .mx-ht-lg-450p {
    max-height: 450%;
  }
  .mn-ht-lg-450p {
    min-height: 450%;
  }
  .ht-lg-450-f {
    height: 450px !important;
  }
  .ht-lg-450p-f {
    height: 450% !important;
  }
  .ht-lg-500 {
    height: 500px;
  }
  .ht-lg-500p {
    height: 500%;
  }
  .mx-ht-lg-500p {
    max-height: 500%;
  }
  .mn-ht-lg-500p {
    min-height: 500%;
  }
  .ht-lg-500-f {
    height: 500px !important;
  }
  .ht-lg-500p-f {
    height: 500% !important;
  }
  .ht-lg-550 {
    height: 550px;
  }
  .ht-lg-550p {
    height: 550%;
  }
  .mx-ht-lg-550p {
    max-height: 550%;
  }
  .mn-ht-lg-550p {
    min-height: 550%;
  }
  .ht-lg-550-f {
    height: 550px !important;
  }
  .ht-lg-550p-f {
    height: 550% !important;
  }
  .ht-lg-600 {
    height: 600px;
  }
  .ht-lg-600p {
    height: 600%;
  }
  .mx-ht-lg-600p {
    max-height: 600%;
  }
  .mn-ht-lg-600p {
    min-height: 600%;
  }
  .ht-lg-600-f {
    height: 600px !important;
  }
  .ht-lg-600p-f {
    height: 600% !important;
  }
  .ht-lg-650 {
    height: 650px;
  }
  .ht-lg-650p {
    height: 650%;
  }
  .mx-ht-lg-650p {
    max-height: 650%;
  }
  .mn-ht-lg-650p {
    min-height: 650%;
  }
  .ht-lg-650-f {
    height: 650px !important;
  }
  .ht-lg-650p-f {
    height: 650% !important;
  }
  .ht-lg-700 {
    height: 700px;
  }
  .ht-lg-700p {
    height: 700%;
  }
  .mx-ht-lg-700p {
    max-height: 700%;
  }
  .mn-ht-lg-700p {
    min-height: 700%;
  }
  .ht-lg-700-f {
    height: 700px !important;
  }
  .ht-lg-700p-f {
    height: 700% !important;
  }
  .ht-lg-750 {
    height: 750px;
  }
  .ht-lg-750p {
    height: 750%;
  }
  .mx-ht-lg-750p {
    max-height: 750%;
  }
  .mn-ht-lg-750p {
    min-height: 750%;
  }
  .ht-lg-750-f {
    height: 750px !important;
  }
  .ht-lg-750p-f {
    height: 750% !important;
  }
  .ht-lg-800 {
    height: 800px;
  }
  .ht-lg-800p {
    height: 800%;
  }
  .mx-ht-lg-800p {
    max-height: 800%;
  }
  .mn-ht-lg-800p {
    min-height: 800%;
  }
  .ht-lg-800-f {
    height: 800px !important;
  }
  .ht-lg-800p-f {
    height: 800% !important;
  }
  .ht-lg-850 {
    height: 850px;
  }
  .ht-lg-850p {
    height: 850%;
  }
  .mx-ht-lg-850p {
    max-height: 850%;
  }
  .mn-ht-lg-850p {
    min-height: 850%;
  }
  .ht-lg-850-f {
    height: 850px !important;
  }
  .ht-lg-850p-f {
    height: 850% !important;
  }
  .ht-lg-100v {
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .ht-xl-5 {
    height: 5px;
  }
  .ht-xl-5p {
    height: 5%;
  }
  .mx-ht-xl-5p {
    max-height: 5%;
  }
  .mn-ht-xl-5p {
    min-height: 5%;
  }
  .ht-xl-5-f {
    height: 5px !important;
  }
  .ht-xl-5p-f {
    height: 5% !important;
  }
  .ht-xl-10 {
    height: 10px;
  }
  .ht-xl-10p {
    height: 10%;
  }
  .mx-ht-xl-10p {
    max-height: 10%;
  }
  .mn-ht-xl-10p {
    min-height: 10%;
  }
  .ht-xl-10-f {
    height: 10px !important;
  }
  .ht-xl-10p-f {
    height: 10% !important;
  }
  .ht-xl-15 {
    height: 15px;
  }
  .ht-xl-15p {
    height: 15%;
  }
  .mx-ht-xl-15p {
    max-height: 15%;
  }
  .mn-ht-xl-15p {
    min-height: 15%;
  }
  .ht-xl-15-f {
    height: 15px !important;
  }
  .ht-xl-15p-f {
    height: 15% !important;
  }
  .ht-xl-20 {
    height: 20px;
  }
  .ht-xl-20p {
    height: 20%;
  }
  .mx-ht-xl-20p {
    max-height: 20%;
  }
  .mn-ht-xl-20p {
    min-height: 20%;
  }
  .ht-xl-20-f {
    height: 20px !important;
  }
  .ht-xl-20p-f {
    height: 20% !important;
  }
  .ht-xl-25 {
    height: 25px;
  }
  .ht-xl-25p {
    height: 25%;
  }
  .mx-ht-xl-25p {
    max-height: 25%;
  }
  .mn-ht-xl-25p {
    min-height: 25%;
  }
  .ht-xl-25-f {
    height: 25px !important;
  }
  .ht-xl-25p-f {
    height: 25% !important;
  }
  .ht-xl-30 {
    height: 30px;
  }
  .ht-xl-30p {
    height: 30%;
  }
  .mx-ht-xl-30p {
    max-height: 30%;
  }
  .mn-ht-xl-30p {
    min-height: 30%;
  }
  .ht-xl-30-f {
    height: 30px !important;
  }
  .ht-xl-30p-f {
    height: 30% !important;
  }
  .ht-xl-35 {
    height: 35px;
  }
  .ht-xl-35p {
    height: 35%;
  }
  .mx-ht-xl-35p {
    max-height: 35%;
  }
  .mn-ht-xl-35p {
    min-height: 35%;
  }
  .ht-xl-35-f {
    height: 35px !important;
  }
  .ht-xl-35p-f {
    height: 35% !important;
  }
  .ht-xl-40 {
    height: 40px;
  }
  .ht-xl-40p {
    height: 40%;
  }
  .mx-ht-xl-40p {
    max-height: 40%;
  }
  .mn-ht-xl-40p {
    min-height: 40%;
  }
  .ht-xl-40-f {
    height: 40px !important;
  }
  .ht-xl-40p-f {
    height: 40% !important;
  }
  .ht-xl-45 {
    height: 45px;
  }
  .ht-xl-45p {
    height: 45%;
  }
  .mx-ht-xl-45p {
    max-height: 45%;
  }
  .mn-ht-xl-45p {
    min-height: 45%;
  }
  .ht-xl-45-f {
    height: 45px !important;
  }
  .ht-xl-45p-f {
    height: 45% !important;
  }
  .ht-xl-50 {
    height: 50px;
  }
  .ht-xl-50p {
    height: 50%;
  }
  .mx-ht-xl-50p {
    max-height: 50%;
  }
  .mn-ht-xl-50p {
    min-height: 50%;
  }
  .ht-xl-50-f {
    height: 50px !important;
  }
  .ht-xl-50p-f {
    height: 50% !important;
  }
  .ht-xl-55 {
    height: 55px;
  }
  .ht-xl-55p {
    height: 55%;
  }
  .mx-ht-xl-55p {
    max-height: 55%;
  }
  .mn-ht-xl-55p {
    min-height: 55%;
  }
  .ht-xl-55-f {
    height: 55px !important;
  }
  .ht-xl-55p-f {
    height: 55% !important;
  }
  .ht-xl-60 {
    height: 60px;
  }
  .ht-xl-60p {
    height: 60%;
  }
  .mx-ht-xl-60p {
    max-height: 60%;
  }
  .mn-ht-xl-60p {
    min-height: 60%;
  }
  .ht-xl-60-f {
    height: 60px !important;
  }
  .ht-xl-60p-f {
    height: 60% !important;
  }
  .ht-xl-65 {
    height: 65px;
  }
  .ht-xl-65p {
    height: 65%;
  }
  .mx-ht-xl-65p {
    max-height: 65%;
  }
  .mn-ht-xl-65p {
    min-height: 65%;
  }
  .ht-xl-65-f {
    height: 65px !important;
  }
  .ht-xl-65p-f {
    height: 65% !important;
  }
  .ht-xl-70 {
    height: 70px;
  }
  .ht-xl-70p {
    height: 70%;
  }
  .mx-ht-xl-70p {
    max-height: 70%;
  }
  .mn-ht-xl-70p {
    min-height: 70%;
  }
  .ht-xl-70-f {
    height: 70px !important;
  }
  .ht-xl-70p-f {
    height: 70% !important;
  }
  .ht-xl-75 {
    height: 75px;
  }
  .ht-xl-75p {
    height: 75%;
  }
  .mx-ht-xl-75p {
    max-height: 75%;
  }
  .mn-ht-xl-75p {
    min-height: 75%;
  }
  .ht-xl-75-f {
    height: 75px !important;
  }
  .ht-xl-75p-f {
    height: 75% !important;
  }
  .ht-xl-80 {
    height: 80px;
  }
  .ht-xl-80p {
    height: 80%;
  }
  .mx-ht-xl-80p {
    max-height: 80%;
  }
  .mn-ht-xl-80p {
    min-height: 80%;
  }
  .ht-xl-80-f {
    height: 80px !important;
  }
  .ht-xl-80p-f {
    height: 80% !important;
  }
  .ht-xl-85 {
    height: 85px;
  }
  .ht-xl-85p {
    height: 85%;
  }
  .mx-ht-xl-85p {
    max-height: 85%;
  }
  .mn-ht-xl-85p {
    min-height: 85%;
  }
  .ht-xl-85-f {
    height: 85px !important;
  }
  .ht-xl-85p-f {
    height: 85% !important;
  }
  .ht-xl-90 {
    height: 90px;
  }
  .ht-xl-90p {
    height: 90%;
  }
  .mx-ht-xl-90p {
    max-height: 90%;
  }
  .mn-ht-xl-90p {
    min-height: 90%;
  }
  .ht-xl-90-f {
    height: 90px !important;
  }
  .ht-xl-90p-f {
    height: 90% !important;
  }
  .ht-xl-95 {
    height: 95px;
  }
  .ht-xl-95p {
    height: 95%;
  }
  .mx-ht-xl-95p {
    max-height: 95%;
  }
  .mn-ht-xl-95p {
    min-height: 95%;
  }
  .ht-xl-95-f {
    height: 95px !important;
  }
  .ht-xl-95p-f {
    height: 95% !important;
  }
  .ht-xl-100 {
    height: 100px;
  }
  .ht-xl-100p {
    height: 100%;
  }
  .mx-ht-xl-100p {
    max-height: 100%;
  }
  .mn-ht-xl-100p {
    min-height: 100%;
  }
  .ht-xl-100-f {
    height: 100px !important;
  }
  .ht-xl-100p-f {
    height: 100% !important;
  }
  .ht-xl-150 {
    height: 150px;
  }
  .ht-xl-150p {
    height: 150%;
  }
  .mx-ht-xl-150p {
    max-height: 150%;
  }
  .mn-ht-xl-150p {
    min-height: 150%;
  }
  .ht-xl-150-f {
    height: 150px !important;
  }
  .ht-xl-150p-f {
    height: 150% !important;
  }
  .ht-xl-200 {
    height: 200px;
  }
  .ht-xl-200p {
    height: 200%;
  }
  .mx-ht-xl-200p {
    max-height: 200%;
  }
  .mn-ht-xl-200p {
    min-height: 200%;
  }
  .ht-xl-200-f {
    height: 200px !important;
  }
  .ht-xl-200p-f {
    height: 200% !important;
  }
  .ht-xl-250 {
    height: 250px;
  }
  .ht-xl-250p {
    height: 250%;
  }
  .mx-ht-xl-250p {
    max-height: 250%;
  }
  .mn-ht-xl-250p {
    min-height: 250%;
  }
  .ht-xl-250-f {
    height: 250px !important;
  }
  .ht-xl-250p-f {
    height: 250% !important;
  }
  .ht-xl-300 {
    height: 300px;
  }
  .ht-xl-300p {
    height: 300%;
  }
  .mx-ht-xl-300p {
    max-height: 300%;
  }
  .mn-ht-xl-300p {
    min-height: 300%;
  }
  .ht-xl-300-f {
    height: 300px !important;
  }
  .ht-xl-300p-f {
    height: 300% !important;
  }
  .ht-xl-350 {
    height: 350px;
  }
  .ht-xl-350p {
    height: 350%;
  }
  .mx-ht-xl-350p {
    max-height: 350%;
  }
  .mn-ht-xl-350p {
    min-height: 350%;
  }
  .ht-xl-350-f {
    height: 350px !important;
  }
  .ht-xl-350p-f {
    height: 350% !important;
  }
  .ht-xl-400 {
    height: 400px;
  }
  .ht-xl-400p {
    height: 400%;
  }
  .mx-ht-xl-400p {
    max-height: 400%;
  }
  .mn-ht-xl-400p {
    min-height: 400%;
  }
  .ht-xl-400-f {
    height: 400px !important;
  }
  .ht-xl-400p-f {
    height: 400% !important;
  }
  .ht-xl-450 {
    height: 450px;
  }
  .ht-xl-450p {
    height: 450%;
  }
  .mx-ht-xl-450p {
    max-height: 450%;
  }
  .mn-ht-xl-450p {
    min-height: 450%;
  }
  .ht-xl-450-f {
    height: 450px !important;
  }
  .ht-xl-450p-f {
    height: 450% !important;
  }
  .ht-xl-500 {
    height: 500px;
  }
  .ht-xl-500p {
    height: 500%;
  }
  .mx-ht-xl-500p {
    max-height: 500%;
  }
  .mn-ht-xl-500p {
    min-height: 500%;
  }
  .ht-xl-500-f {
    height: 500px !important;
  }
  .ht-xl-500p-f {
    height: 500% !important;
  }
  .ht-xl-550 {
    height: 550px;
  }
  .ht-xl-550p {
    height: 550%;
  }
  .mx-ht-xl-550p {
    max-height: 550%;
  }
  .mn-ht-xl-550p {
    min-height: 550%;
  }
  .ht-xl-550-f {
    height: 550px !important;
  }
  .ht-xl-550p-f {
    height: 550% !important;
  }
  .ht-xl-600 {
    height: 600px;
  }
  .ht-xl-600p {
    height: 600%;
  }
  .mx-ht-xl-600p {
    max-height: 600%;
  }
  .mn-ht-xl-600p {
    min-height: 600%;
  }
  .ht-xl-600-f {
    height: 600px !important;
  }
  .ht-xl-600p-f {
    height: 600% !important;
  }
  .ht-xl-650 {
    height: 650px;
  }
  .ht-xl-650p {
    height: 650%;
  }
  .mx-ht-xl-650p {
    max-height: 650%;
  }
  .mn-ht-xl-650p {
    min-height: 650%;
  }
  .ht-xl-650-f {
    height: 650px !important;
  }
  .ht-xl-650p-f {
    height: 650% !important;
  }
  .ht-xl-700 {
    height: 700px;
  }
  .ht-xl-700p {
    height: 700%;
  }
  .mx-ht-xl-700p {
    max-height: 700%;
  }
  .mn-ht-xl-700p {
    min-height: 700%;
  }
  .ht-xl-700-f {
    height: 700px !important;
  }
  .ht-xl-700p-f {
    height: 700% !important;
  }
  .ht-xl-750 {
    height: 750px;
  }
  .ht-xl-750p {
    height: 750%;
  }
  .mx-ht-xl-750p {
    max-height: 750%;
  }
  .mn-ht-xl-750p {
    min-height: 750%;
  }
  .ht-xl-750-f {
    height: 750px !important;
  }
  .ht-xl-750p-f {
    height: 750% !important;
  }
  .ht-xl-800 {
    height: 800px;
  }
  .ht-xl-800p {
    height: 800%;
  }
  .mx-ht-xl-800p {
    max-height: 800%;
  }
  .mn-ht-xl-800p {
    min-height: 800%;
  }
  .ht-xl-800-f {
    height: 800px !important;
  }
  .ht-xl-800p-f {
    height: 800% !important;
  }
  .ht-xl-850 {
    height: 850px;
  }
  .ht-xl-850p {
    height: 850%;
  }
  .mx-ht-xl-850p {
    max-height: 850%;
  }
  .mn-ht-xl-850p {
    min-height: 850%;
  }
  .ht-xl-850-f {
    height: 850px !important;
  }
  .ht-xl-850p-f {
    height: 850% !important;
  }
  .ht-xl-100v {
    height: 100vh;
  }
}

/* ###### 9.4 Margin   ###### */

.mg-0 {
  margin: 0px;
}

.mg-0-f {
  margin: 0px !important;
}

.mg-y-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mg-y-0-f {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mg-x-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mg-x-0-f {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mg-t-0 {
  margin-top: 0px;
}

.mg-r-0 {
  margin-right: 0px;
}

.mg-b-0 {
  margin-bottom: 0px;
}

.mg-l-0 {
  margin-left: 0px;
}

.mg-t-0-f {
  margin-top: 0px !important;
}

.mg-r-0-f {
  margin-right: 0px !important;
}

.mg-b-0-f {
  margin-bottom: 0px !important;
}

.mg-l-0-f {
  margin-left: 0px !important;
}

.mg-1 {
  margin: 1px;
}

.mg-1-f {
  margin: 1px !important;
}

.mg-y-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mg-y-1-f {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mg-x-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.mg-x-1-f {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mg-t-1 {
  margin-top: 1px;
}

.mg-r-1 {
  margin-right: 1px;
}

.mg-b-1 {
  margin-bottom: 1px;
}

.mg-l-1 {
  margin-left: 1px;
}

.mg-t-1-f {
  margin-top: 1px !important;
}

.mg-r-1-f {
  margin-right: 1px !important;
}

.mg-b-1-f {
  margin-bottom: 1px !important;
}

.mg-l-1-f {
  margin-left: 1px !important;
}

.mg-2 {
  margin: 2px;
}

.mg-2-f {
  margin: 2px !important;
}

.mg-y-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.mg-y-2-f {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mg-x-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mg-x-2-f {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mg-t-2 {
  margin-top: 2px;
}

.mg-r-2 {
  margin-right: 2px;
}

.mg-b-2 {
  margin-bottom: 2px;
}

.mg-l-2 {
  margin-left: 2px;
}

.mg-t-2-f {
  margin-top: 2px !important;
}

.mg-r-2-f {
  margin-right: 2px !important;
}

.mg-b-2-f {
  margin-bottom: 2px !important;
}

.mg-l-2-f {
  margin-left: 2px !important;
}

.mg-3 {
  margin: 3px;
}

.mg-3-f {
  margin: 3px !important;
}

.mg-y-3 {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mg-y-3-f {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.mg-x-3 {
  margin-left: 3px;
  margin-right: 3px;
}

.mg-x-3-f {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mg-t-3 {
  margin-top: 3px;
}

.mg-r-3 {
  margin-right: 3px;
}

.mg-b-3 {
  margin-bottom: 3px;
}

.mg-l-3 {
  margin-left: 3px;
}

.mg-t-3-f {
  margin-top: 3px !important;
}

.mg-r-3-f {
  margin-right: 3px !important;
}

.mg-b-3-f {
  margin-bottom: 3px !important;
}

.mg-l-3-f {
  margin-left: 3px !important;
}

.mg-4 {
  margin: 4px;
}

.mg-4-f {
  margin: 4px !important;
}

.mg-y-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mg-y-4-f {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mg-x-4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mg-x-4-f {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.mg-t-4 {
  margin-top: 4px;
}

.mg-r-4 {
  margin-right: 4px;
}

.mg-b-4 {
  margin-bottom: 4px;
}

.mg-l-4 {
  margin-left: 4px;
}

.mg-t-4-f {
  margin-top: 4px !important;
}

.mg-r-4-f {
  margin-right: 4px !important;
}

.mg-b-4-f {
  margin-bottom: 4px !important;
}

.mg-l-4-f {
  margin-left: 4px !important;
}

.mg-5 {
  margin: 5px;
}

.mg-5-f {
  margin: 5px !important;
}

.mg-y-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mg-y-5-f {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mg-x-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mg-x-5-f {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mg-t-5 {
  margin-top: 5px;
}

.mg-r-5 {
  margin-right: 5px;
}

.mg-b-5 {
  margin-bottom: 5px;
}

.mg-l-5 {
  margin-left: 5px;
}

.mg-t-5-f {
  margin-top: 5px !important;
}

.mg-r-5-f {
  margin-right: 5px !important;
}

.mg-b-5-f {
  margin-bottom: 5px !important;
}

.mg-l-5-f {
  margin-left: 5px !important;
}

.mg-6 {
  margin: 6px;
}

.mg-6-f {
  margin: 6px !important;
}

.mg-y-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mg-y-6-f {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.mg-x-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.mg-x-6-f {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.mg-t-6 {
  margin-top: 6px;
}

.mg-r-6 {
  margin-right: 6px;
}

.mg-b-6 {
  margin-bottom: 6px;
}

.mg-l-6 {
  margin-left: 6px;
}

.mg-t-6-f {
  margin-top: 6px !important;
}

.mg-r-6-f {
  margin-right: 6px !important;
}

.mg-b-6-f {
  margin-bottom: 6px !important;
}

.mg-l-6-f {
  margin-left: 6px !important;
}

.mg-7 {
  margin: 7px;
}

.mg-7-f {
  margin: 7px !important;
}

.mg-y-7 {
  margin-top: 7px;
  margin-bottom: 7px;
}

.mg-y-7-f {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mg-x-7 {
  margin-left: 7px;
  margin-right: 7px;
}

.mg-x-7-f {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.mg-t-7 {
  margin-top: 7px;
}

.mg-r-7 {
  margin-right: 7px;
}

.mg-b-7 {
  margin-bottom: 7px;
}

.mg-l-7 {
  margin-left: 7px;
}

.mg-t-7-f {
  margin-top: 7px !important;
}

.mg-r-7-f {
  margin-right: 7px !important;
}

.mg-b-7-f {
  margin-bottom: 7px !important;
}

.mg-l-7-f {
  margin-left: 7px !important;
}

.mg-8 {
  margin: 8px;
}

.mg-8-f {
  margin: 8px !important;
}

.mg-y-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mg-y-8-f {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mg-x-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mg-x-8-f {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mg-t-8 {
  margin-top: 8px;
}

.mg-r-8 {
  margin-right: 8px;
}

.mg-b-8 {
  margin-bottom: 8px;
}

.mg-l-8 {
  margin-left: 8px;
}

.mg-t-8-f {
  margin-top: 8px !important;
}

.mg-r-8-f {
  margin-right: 8px !important;
}

.mg-b-8-f {
  margin-bottom: 8px !important;
}

.mg-l-8-f {
  margin-left: 8px !important;
}

.mg-9 {
  margin: 9px;
}

.mg-9-f {
  margin: 9px !important;
}

.mg-y-9 {
  margin-top: 9px;
  margin-bottom: 9px;
}

.mg-y-9-f {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.mg-x-9 {
  margin-left: 9px;
  margin-right: 9px;
}

.mg-x-9-f {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.mg-t-9 {
  margin-top: 9px;
}

.mg-r-9 {
  margin-right: 9px;
}

.mg-b-9 {
  margin-bottom: 9px;
}

.mg-l-9 {
  margin-left: 9px;
}

.mg-t-9-f {
  margin-top: 9px !important;
}

.mg-r-9-f {
  margin-right: 9px !important;
}

.mg-b-9-f {
  margin-bottom: 9px !important;
}

.mg-l-9-f {
  margin-left: 9px !important;
}

.mg-10 {
  margin: 10px;
}

.mg-10-f {
  margin: 10px !important;
}

.mg-y-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mg-y-10-f {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mg-x-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mg-x-10-f {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mg-t-10 {
  margin-top: 10px;
}

.mg-r-10 {
  margin-right: 10px;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mg-l-10 {
  margin-left: 10px;
}

.mg-t-10-f {
  margin-top: 10px !important;
}

.mg-r-10-f {
  margin-right: 10px !important;
}

.mg-b-10-f {
  margin-bottom: 10px !important;
}

.mg-l-10-f {
  margin-left: 10px !important;
}

.mg-15 {
  margin: 15px;
}

.mg-15-f {
  margin: 15px !important;
}

.mg-y-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mg-y-15-f {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mg-x-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mg-x-15-f {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mg-t-15 {
  margin-top: 15px;
}

.mg-r-15 {
  margin-right: 15px;
}

.mg-b-15 {
  margin-bottom: 15px;
}

.mg-l-15 {
  margin-left: 15px;
}

.mg-t-15-f {
  margin-top: 15px !important;
}

.mg-r-15-f {
  margin-right: 15px !important;
}

.mg-b-15-f {
  margin-bottom: 15px !important;
}

.mg-l-15-f {
  margin-left: 15px !important;
}

.mg-20 {
  margin: 20px;
}

.mg-20-f {
  margin: 20px !important;
}

.mg-y-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mg-y-20-f {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mg-x-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mg-x-20-f {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mg-t-20 {
  margin-top: 20px;
}

.mg-r-20 {
  margin-right: 20px;
}

.mg-b-20 {
  margin-bottom: 20px;
}

.mg-l-20 {
  margin-left: 20px;
}

.mg-t-20-f {
  margin-top: 20px !important;
}

.mg-r-20-f {
  margin-right: 20px !important;
}

.mg-b-20-f {
  margin-bottom: 20px !important;
}

.mg-l-20-f {
  margin-left: 20px !important;
}

.mg-25 {
  margin: 25px;
}

.mg-25-f {
  margin: 25px !important;
}

.mg-y-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mg-y-25-f {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mg-x-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mg-x-25-f {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mg-t-25 {
  margin-top: 25px;
}

.mg-r-25 {
  margin-right: 25px;
}

.mg-b-25 {
  margin-bottom: 25px;
}

.mg-l-25 {
  margin-left: 25px;
}

.mg-t-25-f {
  margin-top: 25px !important;
}

.mg-r-25-f {
  margin-right: 25px !important;
}

.mg-b-25-f {
  margin-bottom: 25px !important;
}

.mg-l-25-f {
  margin-left: 25px !important;
}

.mg-30 {
  margin: 30px;
}

.mg-30-f {
  margin: 30px !important;
}

.mg-y-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mg-y-30-f {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mg-x-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mg-x-30-f {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mg-t-30 {
  margin-top: 30px;
}

.mg-r-30 {
  margin-right: 30px;
}

.mg-b-30 {
  margin-bottom: 30px;
}

.mg-l-30 {
  margin-left: 30px;
}

.mg-t-30-f {
  margin-top: 30px !important;
}

.mg-r-30-f {
  margin-right: 30px !important;
}

.mg-b-30-f {
  margin-bottom: 30px !important;
}

.mg-l-30-f {
  margin-left: 30px !important;
}

.mg-35 {
  margin: 35px;
}

.mg-35-f {
  margin: 35px !important;
}

.mg-y-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.mg-y-35-f {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mg-x-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.mg-x-35-f {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mg-t-35 {
  margin-top: 35px;
}

.mg-r-35 {
  margin-right: 35px;
}

.mg-b-35 {
  margin-bottom: 35px;
}

.mg-l-35 {
  margin-left: 35px;
}

.mg-t-35-f {
  margin-top: 35px !important;
}

.mg-r-35-f {
  margin-right: 35px !important;
}

.mg-b-35-f {
  margin-bottom: 35px !important;
}

.mg-l-35-f {
  margin-left: 35px !important;
}

.mg-40 {
  margin: 40px;
}

.mg-40-f {
  margin: 40px !important;
}

.mg-y-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mg-y-40-f {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mg-x-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mg-x-40-f {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mg-t-40 {
  margin-top: 40px;
}

.mg-r-40 {
  margin-right: 40px;
}

.mg-b-40 {
  margin-bottom: 40px;
}

.mg-l-40 {
  margin-left: 40px;
}

.mg-t-40-f {
  margin-top: 40px !important;
}

.mg-r-40-f {
  margin-right: 40px !important;
}

.mg-b-40-f {
  margin-bottom: 40px !important;
}

.mg-l-40-f {
  margin-left: 40px !important;
}

.mg-45 {
  margin: 45px;
}

.mg-45-f {
  margin: 45px !important;
}

.mg-y-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.mg-y-45-f {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mg-x-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.mg-x-45-f {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mg-t-45 {
  margin-top: 45px;
}

.mg-r-45 {
  margin-right: 45px;
}

.mg-b-45 {
  margin-bottom: 45px;
}

.mg-l-45 {
  margin-left: 45px;
}

.mg-t-45-f {
  margin-top: 45px !important;
}

.mg-r-45-f {
  margin-right: 45px !important;
}

.mg-b-45-f {
  margin-bottom: 45px !important;
}

.mg-l-45-f {
  margin-left: 45px !important;
}

.mg-50 {
  margin: 50px;
}

.mg-50-f {
  margin: 50px !important;
}

.mg-y-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.mg-y-50-f {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mg-x-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.mg-x-50-f {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mg-t-50 {
  margin-top: 50px;
}

.mg-r-50 {
  margin-right: 50px;
}

.mg-b-50 {
  margin-bottom: 50px;
}

.mg-l-50 {
  margin-left: 50px;
}

.mg-t-50-f {
  margin-top: 50px !important;
}

.mg-r-50-f {
  margin-right: 50px !important;
}

.mg-b-50-f {
  margin-bottom: 50px !important;
}

.mg-l-50-f {
  margin-left: 50px !important;
}

.mg-55 {
  margin: 55px;
}

.mg-55-f {
  margin: 55px !important;
}

.mg-y-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}

.mg-y-55-f {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mg-x-55 {
  margin-left: 55px;
  margin-right: 55px;
}

.mg-x-55-f {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mg-t-55 {
  margin-top: 55px;
}

.mg-r-55 {
  margin-right: 55px;
}

.mg-b-55 {
  margin-bottom: 55px;
}

.mg-l-55 {
  margin-left: 55px;
}

.mg-t-55-f {
  margin-top: 55px !important;
}

.mg-r-55-f {
  margin-right: 55px !important;
}

.mg-b-55-f {
  margin-bottom: 55px !important;
}

.mg-l-55-f {
  margin-left: 55px !important;
}

.mg-60 {
  margin: 60px;
}

.mg-60-f {
  margin: 60px !important;
}

.mg-y-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mg-y-60-f {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mg-x-60 {
  margin-left: 60px;
  margin-right: 60px;
}

.mg-x-60-f {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mg-t-60 {
  margin-top: 60px;
}

.mg-r-60 {
  margin-right: 60px;
}

.mg-b-60 {
  margin-bottom: 60px;
}

.mg-l-60 {
  margin-left: 60px;
}

.mg-t-60-f {
  margin-top: 60px !important;
}

.mg-r-60-f {
  margin-right: 60px !important;
}

.mg-b-60-f {
  margin-bottom: 60px !important;
}

.mg-l-60-f {
  margin-left: 60px !important;
}

.mg-65 {
  margin: 65px;
}

.mg-65-f {
  margin: 65px !important;
}

.mg-y-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}

.mg-y-65-f {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mg-x-65 {
  margin-left: 65px;
  margin-right: 65px;
}

.mg-x-65-f {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mg-t-65 {
  margin-top: 65px;
}

.mg-r-65 {
  margin-right: 65px;
}

.mg-b-65 {
  margin-bottom: 65px;
}

.mg-l-65 {
  margin-left: 65px;
}

.mg-t-65-f {
  margin-top: 65px !important;
}

.mg-r-65-f {
  margin-right: 65px !important;
}

.mg-b-65-f {
  margin-bottom: 65px !important;
}

.mg-l-65-f {
  margin-left: 65px !important;
}

.mg-70 {
  margin: 70px;
}

.mg-70-f {
  margin: 70px !important;
}

.mg-y-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.mg-y-70-f {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mg-x-70 {
  margin-left: 70px;
  margin-right: 70px;
}

.mg-x-70-f {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mg-t-70 {
  margin-top: 70px;
}

.mg-r-70 {
  margin-right: 70px;
}

.mg-b-70 {
  margin-bottom: 70px;
}

.mg-l-70 {
  margin-left: 70px;
}

.mg-t-70-f {
  margin-top: 70px !important;
}

.mg-r-70-f {
  margin-right: 70px !important;
}

.mg-b-70-f {
  margin-bottom: 70px !important;
}

.mg-l-70-f {
  margin-left: 70px !important;
}

.mg-75 {
  margin: 75px;
}

.mg-75-f {
  margin: 75px !important;
}

.mg-y-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}

.mg-y-75-f {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mg-x-75 {
  margin-left: 75px;
  margin-right: 75px;
}

.mg-x-75-f {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mg-t-75 {
  margin-top: 75px;
}

.mg-r-75 {
  margin-right: 75px;
}

.mg-b-75 {
  margin-bottom: 75px;
}

.mg-l-75 {
  margin-left: 75px;
}

.mg-t-75-f {
  margin-top: 75px !important;
}

.mg-r-75-f {
  margin-right: 75px !important;
}

.mg-b-75-f {
  margin-bottom: 75px !important;
}

.mg-l-75-f {
  margin-left: 75px !important;
}

.mg-80 {
  margin: 80px;
}

.mg-80-f {
  margin: 80px !important;
}

.mg-y-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mg-y-80-f {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mg-x-80 {
  margin-left: 80px;
  margin-right: 80px;
}

.mg-x-80-f {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mg-t-80 {
  margin-top: 80px;
}

.mg-r-80 {
  margin-right: 80px;
}

.mg-b-80 {
  margin-bottom: 80px;
}

.mg-l-80 {
  margin-left: 80px;
}

.mg-t-80-f {
  margin-top: 80px !important;
}

.mg-r-80-f {
  margin-right: 80px !important;
}

.mg-b-80-f {
  margin-bottom: 80px !important;
}

.mg-l-80-f {
  margin-left: 80px !important;
}

.mg-85 {
  margin: 85px;
}

.mg-85-f {
  margin: 85px !important;
}

.mg-y-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}

.mg-y-85-f {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mg-x-85 {
  margin-left: 85px;
  margin-right: 85px;
}

.mg-x-85-f {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mg-t-85 {
  margin-top: 85px;
}

.mg-r-85 {
  margin-right: 85px;
}

.mg-b-85 {
  margin-bottom: 85px;
}

.mg-l-85 {
  margin-left: 85px;
}

.mg-t-85-f {
  margin-top: 85px !important;
}

.mg-r-85-f {
  margin-right: 85px !important;
}

.mg-b-85-f {
  margin-bottom: 85px !important;
}

.mg-l-85-f {
  margin-left: 85px !important;
}

.mg-90 {
  margin: 90px;
}

.mg-90-f {
  margin: 90px !important;
}

.mg-y-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mg-y-90-f {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mg-x-90 {
  margin-left: 90px;
  margin-right: 90px;
}

.mg-x-90-f {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mg-t-90 {
  margin-top: 90px;
}

.mg-r-90 {
  margin-right: 90px;
}

.mg-b-90 {
  margin-bottom: 90px;
}

.mg-l-90 {
  margin-left: 90px;
}

.mg-t-90-f {
  margin-top: 90px !important;
}

.mg-r-90-f {
  margin-right: 90px !important;
}

.mg-b-90-f {
  margin-bottom: 90px !important;
}

.mg-l-90-f {
  margin-left: 90px !important;
}

.mg-95 {
  margin: 95px;
}

.mg-95-f {
  margin: 95px !important;
}

.mg-y-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}

.mg-y-95-f {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mg-x-95 {
  margin-left: 95px;
  margin-right: 95px;
}

.mg-x-95-f {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mg-t-95 {
  margin-top: 95px;
}

.mg-r-95 {
  margin-right: 95px;
}

.mg-b-95 {
  margin-bottom: 95px;
}

.mg-l-95 {
  margin-left: 95px;
}

.mg-t-95-f {
  margin-top: 95px !important;
}

.mg-r-95-f {
  margin-right: 95px !important;
}

.mg-b-95-f {
  margin-bottom: 95px !important;
}

.mg-l-95-f {
  margin-left: 95px !important;
}

.mg-100 {
  margin: 100px;
}

.mg-100-f {
  margin: 100px !important;
}

.mg-y-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.mg-y-100-f {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mg-x-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mg-x-100-f {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mg-t-100 {
  margin-top: 100px;
}

.mg-r-100 {
  margin-right: 100px;
}

.mg-b-100 {
  margin-bottom: 100px;
}

.mg-l-100 {
  margin-left: 100px;
}

.mg-t-100-f {
  margin-top: 100px !important;
}

.mg-r-100-f {
  margin-right: 100px !important;
}

.mg-b-100-f {
  margin-bottom: 100px !important;
}

.mg-l-100-f {
  margin-left: 100px !important;
}

.mg-105 {
  margin: 105px;
}

.mg-105-f {
  margin: 105px !important;
}

.mg-y-105 {
  margin-top: 105px;
  margin-bottom: 105px;
}

.mg-y-105-f {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mg-x-105 {
  margin-left: 105px;
  margin-right: 105px;
}

.mg-x-105-f {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.mg-t-105 {
  margin-top: 105px;
}

.mg-r-105 {
  margin-right: 105px;
}

.mg-b-105 {
  margin-bottom: 105px;
}

.mg-l-105 {
  margin-left: 105px;
}

.mg-t-105-f {
  margin-top: 105px !important;
}

.mg-r-105-f {
  margin-right: 105px !important;
}

.mg-b-105-f {
  margin-bottom: 105px !important;
}

.mg-l-105-f {
  margin-left: 105px !important;
}

.mg-110 {
  margin: 110px;
}

.mg-110-f {
  margin: 110px !important;
}

.mg-y-110 {
  margin-top: 110px;
  margin-bottom: 110px;
}

.mg-y-110-f {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mg-x-110 {
  margin-left: 110px;
  margin-right: 110px;
}

.mg-x-110-f {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.mg-t-110 {
  margin-top: 110px;
}

.mg-r-110 {
  margin-right: 110px;
}

.mg-b-110 {
  margin-bottom: 110px;
}

.mg-l-110 {
  margin-left: 110px;
}

.mg-t-110-f {
  margin-top: 110px !important;
}

.mg-r-110-f {
  margin-right: 110px !important;
}

.mg-b-110-f {
  margin-bottom: 110px !important;
}

.mg-l-110-f {
  margin-left: 110px !important;
}

.mg-115 {
  margin: 115px;
}

.mg-115-f {
  margin: 115px !important;
}

.mg-y-115 {
  margin-top: 115px;
  margin-bottom: 115px;
}

.mg-y-115-f {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mg-x-115 {
  margin-left: 115px;
  margin-right: 115px;
}

.mg-x-115-f {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.mg-t-115 {
  margin-top: 115px;
}

.mg-r-115 {
  margin-right: 115px;
}

.mg-b-115 {
  margin-bottom: 115px;
}

.mg-l-115 {
  margin-left: 115px;
}

.mg-t-115-f {
  margin-top: 115px !important;
}

.mg-r-115-f {
  margin-right: 115px !important;
}

.mg-b-115-f {
  margin-bottom: 115px !important;
}

.mg-l-115-f {
  margin-left: 115px !important;
}

.mg-120 {
  margin: 120px;
}

.mg-120-f {
  margin: 120px !important;
}

.mg-y-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mg-y-120-f {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mg-x-120 {
  margin-left: 120px;
  margin-right: 120px;
}

.mg-x-120-f {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.mg-t-120 {
  margin-top: 120px;
}

.mg-r-120 {
  margin-right: 120px;
}

.mg-b-120 {
  margin-bottom: 120px;
}

.mg-l-120 {
  margin-left: 120px;
}

.mg-t-120-f {
  margin-top: 120px !important;
}

.mg-r-120-f {
  margin-right: 120px !important;
}

.mg-b-120-f {
  margin-bottom: 120px !important;
}

.mg-l-120-f {
  margin-left: 120px !important;
}

.mg-t-auto {
  margin-top: auto;
}

.mg-r-auto {
  margin-right: auto;
}

.mg-b-auto {
  margin-bottom: auto;
}

.mg-l-auto {
  margin-left: auto;
}

.mg-x-auto {
  margin: auto;
}

@media (min-width: 480px) {
  .mg-xs-0 {
    margin: 0px;
  }
  .mg-xs-0-f {
    margin: 0px !important;
  }
  .mg-xs-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mg-xs-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mg-xs-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-xs-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mg-xs-t-0 {
    margin-top: 0px;
  }
  .mg-xs-r-0 {
    margin-right: 0px;
  }
  .mg-xs-b-0 {
    margin-bottom: 0px;
  }
  .mg-xs-l-0 {
    margin-left: 0px;
  }
  .mg-xs-t-0-f {
    margin-top: 0px !important;
  }
  .mg-xs-r-0-f {
    margin-right: 0px !important;
  }
  .mg-xs-b-0-f {
    margin-bottom: 0px !important;
  }
  .mg-xs-l-0-f {
    margin-left: 0px !important;
  }
  .mg-xs-1 {
    margin: 1px;
  }
  .mg-xs-1-f {
    margin: 1px !important;
  }
  .mg-xs-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .mg-xs-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mg-xs-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .mg-xs-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mg-xs-t-1 {
    margin-top: 1px;
  }
  .mg-xs-r-1 {
    margin-right: 1px;
  }
  .mg-xs-b-1 {
    margin-bottom: 1px;
  }
  .mg-xs-l-1 {
    margin-left: 1px;
  }
  .mg-xs-t-1-f {
    margin-top: 1px !important;
  }
  .mg-xs-r-1-f {
    margin-right: 1px !important;
  }
  .mg-xs-b-1-f {
    margin-bottom: 1px !important;
  }
  .mg-xs-l-1-f {
    margin-left: 1px !important;
  }
  .mg-xs-2 {
    margin: 2px;
  }
  .mg-xs-2-f {
    margin: 2px !important;
  }
  .mg-xs-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mg-xs-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mg-xs-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mg-xs-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mg-xs-t-2 {
    margin-top: 2px;
  }
  .mg-xs-r-2 {
    margin-right: 2px;
  }
  .mg-xs-b-2 {
    margin-bottom: 2px;
  }
  .mg-xs-l-2 {
    margin-left: 2px;
  }
  .mg-xs-t-2-f {
    margin-top: 2px !important;
  }
  .mg-xs-r-2-f {
    margin-right: 2px !important;
  }
  .mg-xs-b-2-f {
    margin-bottom: 2px !important;
  }
  .mg-xs-l-2-f {
    margin-left: 2px !important;
  }
  .mg-xs-3 {
    margin: 3px;
  }
  .mg-xs-3-f {
    margin: 3px !important;
  }
  .mg-xs-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .mg-xs-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .mg-xs-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mg-xs-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .mg-xs-t-3 {
    margin-top: 3px;
  }
  .mg-xs-r-3 {
    margin-right: 3px;
  }
  .mg-xs-b-3 {
    margin-bottom: 3px;
  }
  .mg-xs-l-3 {
    margin-left: 3px;
  }
  .mg-xs-t-3-f {
    margin-top: 3px !important;
  }
  .mg-xs-r-3-f {
    margin-right: 3px !important;
  }
  .mg-xs-b-3-f {
    margin-bottom: 3px !important;
  }
  .mg-xs-l-3-f {
    margin-left: 3px !important;
  }
  .mg-xs-4 {
    margin: 4px;
  }
  .mg-xs-4-f {
    margin: 4px !important;
  }
  .mg-xs-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mg-xs-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mg-xs-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mg-xs-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .mg-xs-t-4 {
    margin-top: 4px;
  }
  .mg-xs-r-4 {
    margin-right: 4px;
  }
  .mg-xs-b-4 {
    margin-bottom: 4px;
  }
  .mg-xs-l-4 {
    margin-left: 4px;
  }
  .mg-xs-t-4-f {
    margin-top: 4px !important;
  }
  .mg-xs-r-4-f {
    margin-right: 4px !important;
  }
  .mg-xs-b-4-f {
    margin-bottom: 4px !important;
  }
  .mg-xs-l-4-f {
    margin-left: 4px !important;
  }
  .mg-xs-5 {
    margin: 5px;
  }
  .mg-xs-5-f {
    margin: 5px !important;
  }
  .mg-xs-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mg-xs-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mg-xs-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-xs-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .mg-xs-t-5 {
    margin-top: 5px;
  }
  .mg-xs-r-5 {
    margin-right: 5px;
  }
  .mg-xs-b-5 {
    margin-bottom: 5px;
  }
  .mg-xs-l-5 {
    margin-left: 5px;
  }
  .mg-xs-t-5-f {
    margin-top: 5px !important;
  }
  .mg-xs-r-5-f {
    margin-right: 5px !important;
  }
  .mg-xs-b-5-f {
    margin-bottom: 5px !important;
  }
  .mg-xs-l-5-f {
    margin-left: 5px !important;
  }
  .mg-xs-6 {
    margin: 6px;
  }
  .mg-xs-6-f {
    margin: 6px !important;
  }
  .mg-xs-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .mg-xs-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .mg-xs-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }
  .mg-xs-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .mg-xs-t-6 {
    margin-top: 6px;
  }
  .mg-xs-r-6 {
    margin-right: 6px;
  }
  .mg-xs-b-6 {
    margin-bottom: 6px;
  }
  .mg-xs-l-6 {
    margin-left: 6px;
  }
  .mg-xs-t-6-f {
    margin-top: 6px !important;
  }
  .mg-xs-r-6-f {
    margin-right: 6px !important;
  }
  .mg-xs-b-6-f {
    margin-bottom: 6px !important;
  }
  .mg-xs-l-6-f {
    margin-left: 6px !important;
  }
  .mg-xs-7 {
    margin: 7px;
  }
  .mg-xs-7-f {
    margin: 7px !important;
  }
  .mg-xs-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .mg-xs-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
  .mg-xs-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }
  .mg-xs-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .mg-xs-t-7 {
    margin-top: 7px;
  }
  .mg-xs-r-7 {
    margin-right: 7px;
  }
  .mg-xs-b-7 {
    margin-bottom: 7px;
  }
  .mg-xs-l-7 {
    margin-left: 7px;
  }
  .mg-xs-t-7-f {
    margin-top: 7px !important;
  }
  .mg-xs-r-7-f {
    margin-right: 7px !important;
  }
  .mg-xs-b-7-f {
    margin-bottom: 7px !important;
  }
  .mg-xs-l-7-f {
    margin-left: 7px !important;
  }
  .mg-xs-8 {
    margin: 8px;
  }
  .mg-xs-8-f {
    margin: 8px !important;
  }
  .mg-xs-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mg-xs-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mg-xs-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mg-xs-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .mg-xs-t-8 {
    margin-top: 8px;
  }
  .mg-xs-r-8 {
    margin-right: 8px;
  }
  .mg-xs-b-8 {
    margin-bottom: 8px;
  }
  .mg-xs-l-8 {
    margin-left: 8px;
  }
  .mg-xs-t-8-f {
    margin-top: 8px !important;
  }
  .mg-xs-r-8-f {
    margin-right: 8px !important;
  }
  .mg-xs-b-8-f {
    margin-bottom: 8px !important;
  }
  .mg-xs-l-8-f {
    margin-left: 8px !important;
  }
  .mg-xs-9 {
    margin: 9px;
  }
  .mg-xs-9-f {
    margin: 9px !important;
  }
  .mg-xs-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .mg-xs-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }
  .mg-xs-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }
  .mg-xs-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }
  .mg-xs-t-9 {
    margin-top: 9px;
  }
  .mg-xs-r-9 {
    margin-right: 9px;
  }
  .mg-xs-b-9 {
    margin-bottom: 9px;
  }
  .mg-xs-l-9 {
    margin-left: 9px;
  }
  .mg-xs-t-9-f {
    margin-top: 9px !important;
  }
  .mg-xs-r-9-f {
    margin-right: 9px !important;
  }
  .mg-xs-b-9-f {
    margin-bottom: 9px !important;
  }
  .mg-xs-l-9-f {
    margin-left: 9px !important;
  }
  .mg-xs-10 {
    margin: 10px;
  }
  .mg-xs-10-f {
    margin: 10px !important;
  }
  .mg-xs-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mg-xs-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mg-xs-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-xs-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .mg-xs-t-10 {
    margin-top: 10px;
  }
  .mg-xs-r-10 {
    margin-right: 10px;
  }
  .mg-xs-b-10 {
    margin-bottom: 10px;
  }
  .mg-xs-l-10 {
    margin-left: 10px;
  }
  .mg-xs-t-10-f {
    margin-top: 10px !important;
  }
  .mg-xs-r-10-f {
    margin-right: 10px !important;
  }
  .mg-xs-b-10-f {
    margin-bottom: 10px !important;
  }
  .mg-xs-l-10-f {
    margin-left: 10px !important;
  }
  .mg-xs-15 {
    margin: 15px;
  }
  .mg-xs-15-f {
    margin: 15px !important;
  }
  .mg-xs-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mg-xs-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mg-xs-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-xs-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .mg-xs-t-15 {
    margin-top: 15px;
  }
  .mg-xs-r-15 {
    margin-right: 15px;
  }
  .mg-xs-b-15 {
    margin-bottom: 15px;
  }
  .mg-xs-l-15 {
    margin-left: 15px;
  }
  .mg-xs-t-15-f {
    margin-top: 15px !important;
  }
  .mg-xs-r-15-f {
    margin-right: 15px !important;
  }
  .mg-xs-b-15-f {
    margin-bottom: 15px !important;
  }
  .mg-xs-l-15-f {
    margin-left: 15px !important;
  }
  .mg-xs-20 {
    margin: 20px;
  }
  .mg-xs-20-f {
    margin: 20px !important;
  }
  .mg-xs-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mg-xs-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mg-xs-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mg-xs-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .mg-xs-t-20 {
    margin-top: 20px;
  }
  .mg-xs-r-20 {
    margin-right: 20px;
  }
  .mg-xs-b-20 {
    margin-bottom: 20px;
  }
  .mg-xs-l-20 {
    margin-left: 20px;
  }
  .mg-xs-t-20-f {
    margin-top: 20px !important;
  }
  .mg-xs-r-20-f {
    margin-right: 20px !important;
  }
  .mg-xs-b-20-f {
    margin-bottom: 20px !important;
  }
  .mg-xs-l-20-f {
    margin-left: 20px !important;
  }
  .mg-xs-25 {
    margin: 25px;
  }
  .mg-xs-25-f {
    margin: 25px !important;
  }
  .mg-xs-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mg-xs-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .mg-xs-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .mg-xs-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .mg-xs-t-25 {
    margin-top: 25px;
  }
  .mg-xs-r-25 {
    margin-right: 25px;
  }
  .mg-xs-b-25 {
    margin-bottom: 25px;
  }
  .mg-xs-l-25 {
    margin-left: 25px;
  }
  .mg-xs-t-25-f {
    margin-top: 25px !important;
  }
  .mg-xs-r-25-f {
    margin-right: 25px !important;
  }
  .mg-xs-b-25-f {
    margin-bottom: 25px !important;
  }
  .mg-xs-l-25-f {
    margin-left: 25px !important;
  }
  .mg-xs-30 {
    margin: 30px;
  }
  .mg-xs-30-f {
    margin: 30px !important;
  }
  .mg-xs-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mg-xs-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mg-xs-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-xs-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .mg-xs-t-30 {
    margin-top: 30px;
  }
  .mg-xs-r-30 {
    margin-right: 30px;
  }
  .mg-xs-b-30 {
    margin-bottom: 30px;
  }
  .mg-xs-l-30 {
    margin-left: 30px;
  }
  .mg-xs-t-30-f {
    margin-top: 30px !important;
  }
  .mg-xs-r-30-f {
    margin-right: 30px !important;
  }
  .mg-xs-b-30-f {
    margin-bottom: 30px !important;
  }
  .mg-xs-l-30-f {
    margin-left: 30px !important;
  }
  .mg-xs-35 {
    margin: 35px;
  }
  .mg-xs-35-f {
    margin: 35px !important;
  }
  .mg-xs-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .mg-xs-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .mg-xs-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .mg-xs-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .mg-xs-t-35 {
    margin-top: 35px;
  }
  .mg-xs-r-35 {
    margin-right: 35px;
  }
  .mg-xs-b-35 {
    margin-bottom: 35px;
  }
  .mg-xs-l-35 {
    margin-left: 35px;
  }
  .mg-xs-t-35-f {
    margin-top: 35px !important;
  }
  .mg-xs-r-35-f {
    margin-right: 35px !important;
  }
  .mg-xs-b-35-f {
    margin-bottom: 35px !important;
  }
  .mg-xs-l-35-f {
    margin-left: 35px !important;
  }
  .mg-xs-40 {
    margin: 40px;
  }
  .mg-xs-40-f {
    margin: 40px !important;
  }
  .mg-xs-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mg-xs-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mg-xs-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mg-xs-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .mg-xs-t-40 {
    margin-top: 40px;
  }
  .mg-xs-r-40 {
    margin-right: 40px;
  }
  .mg-xs-b-40 {
    margin-bottom: 40px;
  }
  .mg-xs-l-40 {
    margin-left: 40px;
  }
  .mg-xs-t-40-f {
    margin-top: 40px !important;
  }
  .mg-xs-r-40-f {
    margin-right: 40px !important;
  }
  .mg-xs-b-40-f {
    margin-bottom: 40px !important;
  }
  .mg-xs-l-40-f {
    margin-left: 40px !important;
  }
  .mg-xs-45 {
    margin: 45px;
  }
  .mg-xs-45-f {
    margin: 45px !important;
  }
  .mg-xs-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .mg-xs-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mg-xs-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-xs-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .mg-xs-t-45 {
    margin-top: 45px;
  }
  .mg-xs-r-45 {
    margin-right: 45px;
  }
  .mg-xs-b-45 {
    margin-bottom: 45px;
  }
  .mg-xs-l-45 {
    margin-left: 45px;
  }
  .mg-xs-t-45-f {
    margin-top: 45px !important;
  }
  .mg-xs-r-45-f {
    margin-right: 45px !important;
  }
  .mg-xs-b-45-f {
    margin-bottom: 45px !important;
  }
  .mg-xs-l-45-f {
    margin-left: 45px !important;
  }
  .mg-xs-50 {
    margin: 50px;
  }
  .mg-xs-50-f {
    margin: 50px !important;
  }
  .mg-xs-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mg-xs-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mg-xs-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mg-xs-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .mg-xs-t-50 {
    margin-top: 50px;
  }
  .mg-xs-r-50 {
    margin-right: 50px;
  }
  .mg-xs-b-50 {
    margin-bottom: 50px;
  }
  .mg-xs-l-50 {
    margin-left: 50px;
  }
  .mg-xs-t-50-f {
    margin-top: 50px !important;
  }
  .mg-xs-r-50-f {
    margin-right: 50px !important;
  }
  .mg-xs-b-50-f {
    margin-bottom: 50px !important;
  }
  .mg-xs-l-50-f {
    margin-left: 50px !important;
  }
  .mg-xs-55 {
    margin: 55px;
  }
  .mg-xs-55-f {
    margin: 55px !important;
  }
  .mg-xs-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .mg-xs-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .mg-xs-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .mg-xs-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .mg-xs-t-55 {
    margin-top: 55px;
  }
  .mg-xs-r-55 {
    margin-right: 55px;
  }
  .mg-xs-b-55 {
    margin-bottom: 55px;
  }
  .mg-xs-l-55 {
    margin-left: 55px;
  }
  .mg-xs-t-55-f {
    margin-top: 55px !important;
  }
  .mg-xs-r-55-f {
    margin-right: 55px !important;
  }
  .mg-xs-b-55-f {
    margin-bottom: 55px !important;
  }
  .mg-xs-l-55-f {
    margin-left: 55px !important;
  }
  .mg-xs-60 {
    margin: 60px;
  }
  .mg-xs-60-f {
    margin: 60px !important;
  }
  .mg-xs-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mg-xs-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mg-xs-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-xs-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .mg-xs-t-60 {
    margin-top: 60px;
  }
  .mg-xs-r-60 {
    margin-right: 60px;
  }
  .mg-xs-b-60 {
    margin-bottom: 60px;
  }
  .mg-xs-l-60 {
    margin-left: 60px;
  }
  .mg-xs-t-60-f {
    margin-top: 60px !important;
  }
  .mg-xs-r-60-f {
    margin-right: 60px !important;
  }
  .mg-xs-b-60-f {
    margin-bottom: 60px !important;
  }
  .mg-xs-l-60-f {
    margin-left: 60px !important;
  }
  .mg-xs-65 {
    margin: 65px;
  }
  .mg-xs-65-f {
    margin: 65px !important;
  }
  .mg-xs-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .mg-xs-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .mg-xs-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .mg-xs-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .mg-xs-t-65 {
    margin-top: 65px;
  }
  .mg-xs-r-65 {
    margin-right: 65px;
  }
  .mg-xs-b-65 {
    margin-bottom: 65px;
  }
  .mg-xs-l-65 {
    margin-left: 65px;
  }
  .mg-xs-t-65-f {
    margin-top: 65px !important;
  }
  .mg-xs-r-65-f {
    margin-right: 65px !important;
  }
  .mg-xs-b-65-f {
    margin-bottom: 65px !important;
  }
  .mg-xs-l-65-f {
    margin-left: 65px !important;
  }
  .mg-xs-70 {
    margin: 70px;
  }
  .mg-xs-70-f {
    margin: 70px !important;
  }
  .mg-xs-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mg-xs-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mg-xs-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mg-xs-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .mg-xs-t-70 {
    margin-top: 70px;
  }
  .mg-xs-r-70 {
    margin-right: 70px;
  }
  .mg-xs-b-70 {
    margin-bottom: 70px;
  }
  .mg-xs-l-70 {
    margin-left: 70px;
  }
  .mg-xs-t-70-f {
    margin-top: 70px !important;
  }
  .mg-xs-r-70-f {
    margin-right: 70px !important;
  }
  .mg-xs-b-70-f {
    margin-bottom: 70px !important;
  }
  .mg-xs-l-70-f {
    margin-left: 70px !important;
  }
  .mg-xs-75 {
    margin: 75px;
  }
  .mg-xs-75-f {
    margin: 75px !important;
  }
  .mg-xs-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .mg-xs-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .mg-xs-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .mg-xs-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .mg-xs-t-75 {
    margin-top: 75px;
  }
  .mg-xs-r-75 {
    margin-right: 75px;
  }
  .mg-xs-b-75 {
    margin-bottom: 75px;
  }
  .mg-xs-l-75 {
    margin-left: 75px;
  }
  .mg-xs-t-75-f {
    margin-top: 75px !important;
  }
  .mg-xs-r-75-f {
    margin-right: 75px !important;
  }
  .mg-xs-b-75-f {
    margin-bottom: 75px !important;
  }
  .mg-xs-l-75-f {
    margin-left: 75px !important;
  }
  .mg-xs-80 {
    margin: 80px;
  }
  .mg-xs-80-f {
    margin: 80px !important;
  }
  .mg-xs-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mg-xs-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mg-xs-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mg-xs-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .mg-xs-t-80 {
    margin-top: 80px;
  }
  .mg-xs-r-80 {
    margin-right: 80px;
  }
  .mg-xs-b-80 {
    margin-bottom: 80px;
  }
  .mg-xs-l-80 {
    margin-left: 80px;
  }
  .mg-xs-t-80-f {
    margin-top: 80px !important;
  }
  .mg-xs-r-80-f {
    margin-right: 80px !important;
  }
  .mg-xs-b-80-f {
    margin-bottom: 80px !important;
  }
  .mg-xs-l-80-f {
    margin-left: 80px !important;
  }
  .mg-xs-85 {
    margin: 85px;
  }
  .mg-xs-85-f {
    margin: 85px !important;
  }
  .mg-xs-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .mg-xs-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .mg-xs-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .mg-xs-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .mg-xs-t-85 {
    margin-top: 85px;
  }
  .mg-xs-r-85 {
    margin-right: 85px;
  }
  .mg-xs-b-85 {
    margin-bottom: 85px;
  }
  .mg-xs-l-85 {
    margin-left: 85px;
  }
  .mg-xs-t-85-f {
    margin-top: 85px !important;
  }
  .mg-xs-r-85-f {
    margin-right: 85px !important;
  }
  .mg-xs-b-85-f {
    margin-bottom: 85px !important;
  }
  .mg-xs-l-85-f {
    margin-left: 85px !important;
  }
  .mg-xs-90 {
    margin: 90px;
  }
  .mg-xs-90-f {
    margin: 90px !important;
  }
  .mg-xs-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mg-xs-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mg-xs-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mg-xs-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .mg-xs-t-90 {
    margin-top: 90px;
  }
  .mg-xs-r-90 {
    margin-right: 90px;
  }
  .mg-xs-b-90 {
    margin-bottom: 90px;
  }
  .mg-xs-l-90 {
    margin-left: 90px;
  }
  .mg-xs-t-90-f {
    margin-top: 90px !important;
  }
  .mg-xs-r-90-f {
    margin-right: 90px !important;
  }
  .mg-xs-b-90-f {
    margin-bottom: 90px !important;
  }
  .mg-xs-l-90-f {
    margin-left: 90px !important;
  }
  .mg-xs-95 {
    margin: 95px;
  }
  .mg-xs-95-f {
    margin: 95px !important;
  }
  .mg-xs-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .mg-xs-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .mg-xs-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .mg-xs-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .mg-xs-t-95 {
    margin-top: 95px;
  }
  .mg-xs-r-95 {
    margin-right: 95px;
  }
  .mg-xs-b-95 {
    margin-bottom: 95px;
  }
  .mg-xs-l-95 {
    margin-left: 95px;
  }
  .mg-xs-t-95-f {
    margin-top: 95px !important;
  }
  .mg-xs-r-95-f {
    margin-right: 95px !important;
  }
  .mg-xs-b-95-f {
    margin-bottom: 95px !important;
  }
  .mg-xs-l-95-f {
    margin-left: 95px !important;
  }
  .mg-xs-100 {
    margin: 100px;
  }
  .mg-xs-100-f {
    margin: 100px !important;
  }
  .mg-xs-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mg-xs-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .mg-xs-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mg-xs-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .mg-xs-t-100 {
    margin-top: 100px;
  }
  .mg-xs-r-100 {
    margin-right: 100px;
  }
  .mg-xs-b-100 {
    margin-bottom: 100px;
  }
  .mg-xs-l-100 {
    margin-left: 100px;
  }
  .mg-xs-t-100-f {
    margin-top: 100px !important;
  }
  .mg-xs-r-100-f {
    margin-right: 100px !important;
  }
  .mg-xs-b-100-f {
    margin-bottom: 100px !important;
  }
  .mg-xs-l-100-f {
    margin-left: 100px !important;
  }
  .mg-xs-t-auto {
    margin-top: auto;
  }
  .mg-xs-r-auto {
    margin-right: auto;
  }
  .mg-xs-b-auto {
    margin-bottom: auto;
  }
  .mg-xs-l-auto {
    margin-left: auto;
  }
  .mg-xs-auto {
    margin: auto;
  }
  .mg-xs-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .mg-sm-0 {
    margin: 0px;
  }
  .mg-sm-0-f {
    margin: 0px !important;
  }
  .mg-sm-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mg-sm-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mg-sm-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-sm-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mg-sm-t-0 {
    margin-top: 0px;
  }
  .mg-sm-r-0 {
    margin-right: 0px;
  }
  .mg-sm-b-0 {
    margin-bottom: 0px;
  }
  .mg-sm-l-0 {
    margin-left: 0px;
  }
  .mg-sm-t-0-f {
    margin-top: 0px !important;
  }
  .mg-sm-r-0-f {
    margin-right: 0px !important;
  }
  .mg-sm-b-0-f {
    margin-bottom: 0px !important;
  }
  .mg-sm-l-0-f {
    margin-left: 0px !important;
  }
  .mg-sm-1 {
    margin: 1px;
  }
  .mg-sm-1-f {
    margin: 1px !important;
  }
  .mg-sm-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .mg-sm-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mg-sm-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .mg-sm-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mg-sm-t-1 {
    margin-top: 1px;
  }
  .mg-sm-r-1 {
    margin-right: 1px;
  }
  .mg-sm-b-1 {
    margin-bottom: 1px;
  }
  .mg-sm-l-1 {
    margin-left: 1px;
  }
  .mg-sm-t-1-f {
    margin-top: 1px !important;
  }
  .mg-sm-r-1-f {
    margin-right: 1px !important;
  }
  .mg-sm-b-1-f {
    margin-bottom: 1px !important;
  }
  .mg-sm-l-1-f {
    margin-left: 1px !important;
  }
  .mg-sm-2 {
    margin: 2px;
  }
  .mg-sm-2-f {
    margin: 2px !important;
  }
  .mg-sm-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mg-sm-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mg-sm-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mg-sm-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mg-sm-t-2 {
    margin-top: 2px;
  }
  .mg-sm-r-2 {
    margin-right: 2px;
  }
  .mg-sm-b-2 {
    margin-bottom: 2px;
  }
  .mg-sm-l-2 {
    margin-left: 2px;
  }
  .mg-sm-t-2-f {
    margin-top: 2px !important;
  }
  .mg-sm-r-2-f {
    margin-right: 2px !important;
  }
  .mg-sm-b-2-f {
    margin-bottom: 2px !important;
  }
  .mg-sm-l-2-f {
    margin-left: 2px !important;
  }
  .mg-sm-3 {
    margin: 3px;
  }
  .mg-sm-3-f {
    margin: 3px !important;
  }
  .mg-sm-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .mg-sm-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .mg-sm-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mg-sm-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .mg-sm-t-3 {
    margin-top: 3px;
  }
  .mg-sm-r-3 {
    margin-right: 3px;
  }
  .mg-sm-b-3 {
    margin-bottom: 3px;
  }
  .mg-sm-l-3 {
    margin-left: 3px;
  }
  .mg-sm-t-3-f {
    margin-top: 3px !important;
  }
  .mg-sm-r-3-f {
    margin-right: 3px !important;
  }
  .mg-sm-b-3-f {
    margin-bottom: 3px !important;
  }
  .mg-sm-l-3-f {
    margin-left: 3px !important;
  }
  .mg-sm-4 {
    margin: 4px;
  }
  .mg-sm-4-f {
    margin: 4px !important;
  }
  .mg-sm-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mg-sm-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mg-sm-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mg-sm-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .mg-sm-t-4 {
    margin-top: 4px;
  }
  .mg-sm-r-4 {
    margin-right: 4px;
  }
  .mg-sm-b-4 {
    margin-bottom: 4px;
  }
  .mg-sm-l-4 {
    margin-left: 4px;
  }
  .mg-sm-t-4-f {
    margin-top: 4px !important;
  }
  .mg-sm-r-4-f {
    margin-right: 4px !important;
  }
  .mg-sm-b-4-f {
    margin-bottom: 4px !important;
  }
  .mg-sm-l-4-f {
    margin-left: 4px !important;
  }
  .mg-sm-5 {
    margin: 5px;
  }
  .mg-sm-5-f {
    margin: 5px !important;
  }
  .mg-sm-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mg-sm-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mg-sm-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-sm-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .mg-sm-t-5 {
    margin-top: 5px;
  }
  .mg-sm-r-5 {
    margin-right: 5px;
  }
  .mg-sm-b-5 {
    margin-bottom: 5px;
  }
  .mg-sm-l-5 {
    margin-left: 5px;
  }
  .mg-sm-t-5-f {
    margin-top: 5px !important;
  }
  .mg-sm-r-5-f {
    margin-right: 5px !important;
  }
  .mg-sm-b-5-f {
    margin-bottom: 5px !important;
  }
  .mg-sm-l-5-f {
    margin-left: 5px !important;
  }
  .mg-sm-6 {
    margin: 6px;
  }
  .mg-sm-6-f {
    margin: 6px !important;
  }
  .mg-sm-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .mg-sm-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .mg-sm-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }
  .mg-sm-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .mg-sm-t-6 {
    margin-top: 6px;
  }
  .mg-sm-r-6 {
    margin-right: 6px;
  }
  .mg-sm-b-6 {
    margin-bottom: 6px;
  }
  .mg-sm-l-6 {
    margin-left: 6px;
  }
  .mg-sm-t-6-f {
    margin-top: 6px !important;
  }
  .mg-sm-r-6-f {
    margin-right: 6px !important;
  }
  .mg-sm-b-6-f {
    margin-bottom: 6px !important;
  }
  .mg-sm-l-6-f {
    margin-left: 6px !important;
  }
  .mg-sm-7 {
    margin: 7px;
  }
  .mg-sm-7-f {
    margin: 7px !important;
  }
  .mg-sm-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .mg-sm-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
  .mg-sm-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }
  .mg-sm-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .mg-sm-t-7 {
    margin-top: 7px;
  }
  .mg-sm-r-7 {
    margin-right: 7px;
  }
  .mg-sm-b-7 {
    margin-bottom: 7px;
  }
  .mg-sm-l-7 {
    margin-left: 7px;
  }
  .mg-sm-t-7-f {
    margin-top: 7px !important;
  }
  .mg-sm-r-7-f {
    margin-right: 7px !important;
  }
  .mg-sm-b-7-f {
    margin-bottom: 7px !important;
  }
  .mg-sm-l-7-f {
    margin-left: 7px !important;
  }
  .mg-sm-8 {
    margin: 8px;
  }
  .mg-sm-8-f {
    margin: 8px !important;
  }
  .mg-sm-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mg-sm-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mg-sm-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mg-sm-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .mg-sm-t-8 {
    margin-top: 8px;
  }
  .mg-sm-r-8 {
    margin-right: 8px;
  }
  .mg-sm-b-8 {
    margin-bottom: 8px;
  }
  .mg-sm-l-8 {
    margin-left: 8px;
  }
  .mg-sm-t-8-f {
    margin-top: 8px !important;
  }
  .mg-sm-r-8-f {
    margin-right: 8px !important;
  }
  .mg-sm-b-8-f {
    margin-bottom: 8px !important;
  }
  .mg-sm-l-8-f {
    margin-left: 8px !important;
  }
  .mg-sm-9 {
    margin: 9px;
  }
  .mg-sm-9-f {
    margin: 9px !important;
  }
  .mg-sm-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .mg-sm-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }
  .mg-sm-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }
  .mg-sm-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }
  .mg-sm-t-9 {
    margin-top: 9px;
  }
  .mg-sm-r-9 {
    margin-right: 9px;
  }
  .mg-sm-b-9 {
    margin-bottom: 9px;
  }
  .mg-sm-l-9 {
    margin-left: 9px;
  }
  .mg-sm-t-9-f {
    margin-top: 9px !important;
  }
  .mg-sm-r-9-f {
    margin-right: 9px !important;
  }
  .mg-sm-b-9-f {
    margin-bottom: 9px !important;
  }
  .mg-sm-l-9-f {
    margin-left: 9px !important;
  }
  .mg-sm-10 {
    margin: 10px;
  }
  .mg-sm-10-f {
    margin: 10px !important;
  }
  .mg-sm-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mg-sm-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mg-sm-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-sm-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .mg-sm-t-10 {
    margin-top: 10px;
  }
  .mg-sm-r-10 {
    margin-right: 10px;
  }
  .mg-sm-b-10 {
    margin-bottom: 10px;
  }
  .mg-sm-l-10 {
    margin-left: 10px;
  }
  .mg-sm-t-10-f {
    margin-top: 10px !important;
  }
  .mg-sm-r-10-f {
    margin-right: 10px !important;
  }
  .mg-sm-b-10-f {
    margin-bottom: 10px !important;
  }
  .mg-sm-l-10-f {
    margin-left: 10px !important;
  }
  .mg-sm-15 {
    margin: 15px;
  }
  .mg-sm-15-f {
    margin: 15px !important;
  }
  .mg-sm-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mg-sm-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mg-sm-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-sm-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .mg-sm-t-15 {
    margin-top: 15px;
  }
  .mg-sm-r-15 {
    margin-right: 15px;
  }
  .mg-sm-b-15 {
    margin-bottom: 15px;
  }
  .mg-sm-l-15 {
    margin-left: 15px;
  }
  .mg-sm-t-15-f {
    margin-top: 15px !important;
  }
  .mg-sm-r-15-f {
    margin-right: 15px !important;
  }
  .mg-sm-b-15-f {
    margin-bottom: 15px !important;
  }
  .mg-sm-l-15-f {
    margin-left: 15px !important;
  }
  .mg-sm-20 {
    margin: 20px;
  }
  .mg-sm-20-f {
    margin: 20px !important;
  }
  .mg-sm-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mg-sm-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mg-sm-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mg-sm-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .mg-sm-t-20 {
    margin-top: 20px;
  }
  .mg-sm-r-20 {
    margin-right: 20px;
  }
  .mg-sm-b-20 {
    margin-bottom: 20px;
  }
  .mg-sm-l-20 {
    margin-left: 20px;
  }
  .mg-sm-t-20-f {
    margin-top: 20px !important;
  }
  .mg-sm-r-20-f {
    margin-right: 20px !important;
  }
  .mg-sm-b-20-f {
    margin-bottom: 20px !important;
  }
  .mg-sm-l-20-f {
    margin-left: 20px !important;
  }
  .mg-sm-25 {
    margin: 25px;
  }
  .mg-sm-25-f {
    margin: 25px !important;
  }
  .mg-sm-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mg-sm-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .mg-sm-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .mg-sm-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .mg-sm-t-25 {
    margin-top: 25px;
  }
  .mg-sm-r-25 {
    margin-right: 25px;
  }
  .mg-sm-b-25 {
    margin-bottom: 25px;
  }
  .mg-sm-l-25 {
    margin-left: 25px;
  }
  .mg-sm-t-25-f {
    margin-top: 25px !important;
  }
  .mg-sm-r-25-f {
    margin-right: 25px !important;
  }
  .mg-sm-b-25-f {
    margin-bottom: 25px !important;
  }
  .mg-sm-l-25-f {
    margin-left: 25px !important;
  }
  .mg-sm-30 {
    margin: 30px;
  }
  .mg-sm-30-f {
    margin: 30px !important;
  }
  .mg-sm-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mg-sm-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mg-sm-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-sm-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .mg-sm-t-30 {
    margin-top: 30px;
  }
  .mg-sm-r-30 {
    margin-right: 30px;
  }
  .mg-sm-b-30 {
    margin-bottom: 30px;
  }
  .mg-sm-l-30 {
    margin-left: 30px;
  }
  .mg-sm-t-30-f {
    margin-top: 30px !important;
  }
  .mg-sm-r-30-f {
    margin-right: 30px !important;
  }
  .mg-sm-b-30-f {
    margin-bottom: 30px !important;
  }
  .mg-sm-l-30-f {
    margin-left: 30px !important;
  }
  .mg-sm-35 {
    margin: 35px;
  }
  .mg-sm-35-f {
    margin: 35px !important;
  }
  .mg-sm-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .mg-sm-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .mg-sm-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .mg-sm-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .mg-sm-t-35 {
    margin-top: 35px;
  }
  .mg-sm-r-35 {
    margin-right: 35px;
  }
  .mg-sm-b-35 {
    margin-bottom: 35px;
  }
  .mg-sm-l-35 {
    margin-left: 35px;
  }
  .mg-sm-t-35-f {
    margin-top: 35px !important;
  }
  .mg-sm-r-35-f {
    margin-right: 35px !important;
  }
  .mg-sm-b-35-f {
    margin-bottom: 35px !important;
  }
  .mg-sm-l-35-f {
    margin-left: 35px !important;
  }
  .mg-sm-40 {
    margin: 40px;
  }
  .mg-sm-40-f {
    margin: 40px !important;
  }
  .mg-sm-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mg-sm-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mg-sm-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mg-sm-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .mg-sm-t-40 {
    margin-top: 40px;
  }
  .mg-sm-r-40 {
    margin-right: 40px;
  }
  .mg-sm-b-40 {
    margin-bottom: 40px;
  }
  .mg-sm-l-40 {
    margin-left: 40px;
  }
  .mg-sm-t-40-f {
    margin-top: 40px !important;
  }
  .mg-sm-r-40-f {
    margin-right: 40px !important;
  }
  .mg-sm-b-40-f {
    margin-bottom: 40px !important;
  }
  .mg-sm-l-40-f {
    margin-left: 40px !important;
  }
  .mg-sm-45 {
    margin: 45px;
  }
  .mg-sm-45-f {
    margin: 45px !important;
  }
  .mg-sm-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .mg-sm-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mg-sm-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-sm-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .mg-sm-t-45 {
    margin-top: 45px;
  }
  .mg-sm-r-45 {
    margin-right: 45px;
  }
  .mg-sm-b-45 {
    margin-bottom: 45px;
  }
  .mg-sm-l-45 {
    margin-left: 45px;
  }
  .mg-sm-t-45-f {
    margin-top: 45px !important;
  }
  .mg-sm-r-45-f {
    margin-right: 45px !important;
  }
  .mg-sm-b-45-f {
    margin-bottom: 45px !important;
  }
  .mg-sm-l-45-f {
    margin-left: 45px !important;
  }
  .mg-sm-50 {
    margin: 50px;
  }
  .mg-sm-50-f {
    margin: 50px !important;
  }
  .mg-sm-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mg-sm-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mg-sm-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mg-sm-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .mg-sm-t-50 {
    margin-top: 50px;
  }
  .mg-sm-r-50 {
    margin-right: 50px;
  }
  .mg-sm-b-50 {
    margin-bottom: 50px;
  }
  .mg-sm-l-50 {
    margin-left: 50px;
  }
  .mg-sm-t-50-f {
    margin-top: 50px !important;
  }
  .mg-sm-r-50-f {
    margin-right: 50px !important;
  }
  .mg-sm-b-50-f {
    margin-bottom: 50px !important;
  }
  .mg-sm-l-50-f {
    margin-left: 50px !important;
  }
  .mg-sm-55 {
    margin: 55px;
  }
  .mg-sm-55-f {
    margin: 55px !important;
  }
  .mg-sm-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .mg-sm-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .mg-sm-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .mg-sm-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .mg-sm-t-55 {
    margin-top: 55px;
  }
  .mg-sm-r-55 {
    margin-right: 55px;
  }
  .mg-sm-b-55 {
    margin-bottom: 55px;
  }
  .mg-sm-l-55 {
    margin-left: 55px;
  }
  .mg-sm-t-55-f {
    margin-top: 55px !important;
  }
  .mg-sm-r-55-f {
    margin-right: 55px !important;
  }
  .mg-sm-b-55-f {
    margin-bottom: 55px !important;
  }
  .mg-sm-l-55-f {
    margin-left: 55px !important;
  }
  .mg-sm-60 {
    margin: 60px;
  }
  .mg-sm-60-f {
    margin: 60px !important;
  }
  .mg-sm-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mg-sm-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mg-sm-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-sm-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .mg-sm-t-60 {
    margin-top: 60px;
  }
  .mg-sm-r-60 {
    margin-right: 60px;
  }
  .mg-sm-b-60 {
    margin-bottom: 60px;
  }
  .mg-sm-l-60 {
    margin-left: 60px;
  }
  .mg-sm-t-60-f {
    margin-top: 60px !important;
  }
  .mg-sm-r-60-f {
    margin-right: 60px !important;
  }
  .mg-sm-b-60-f {
    margin-bottom: 60px !important;
  }
  .mg-sm-l-60-f {
    margin-left: 60px !important;
  }
  .mg-sm-65 {
    margin: 65px;
  }
  .mg-sm-65-f {
    margin: 65px !important;
  }
  .mg-sm-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .mg-sm-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .mg-sm-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .mg-sm-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .mg-sm-t-65 {
    margin-top: 65px;
  }
  .mg-sm-r-65 {
    margin-right: 65px;
  }
  .mg-sm-b-65 {
    margin-bottom: 65px;
  }
  .mg-sm-l-65 {
    margin-left: 65px;
  }
  .mg-sm-t-65-f {
    margin-top: 65px !important;
  }
  .mg-sm-r-65-f {
    margin-right: 65px !important;
  }
  .mg-sm-b-65-f {
    margin-bottom: 65px !important;
  }
  .mg-sm-l-65-f {
    margin-left: 65px !important;
  }
  .mg-sm-70 {
    margin: 70px;
  }
  .mg-sm-70-f {
    margin: 70px !important;
  }
  .mg-sm-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mg-sm-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mg-sm-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mg-sm-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .mg-sm-t-70 {
    margin-top: 70px;
  }
  .mg-sm-r-70 {
    margin-right: 70px;
  }
  .mg-sm-b-70 {
    margin-bottom: 70px;
  }
  .mg-sm-l-70 {
    margin-left: 70px;
  }
  .mg-sm-t-70-f {
    margin-top: 70px !important;
  }
  .mg-sm-r-70-f {
    margin-right: 70px !important;
  }
  .mg-sm-b-70-f {
    margin-bottom: 70px !important;
  }
  .mg-sm-l-70-f {
    margin-left: 70px !important;
  }
  .mg-sm-75 {
    margin: 75px;
  }
  .mg-sm-75-f {
    margin: 75px !important;
  }
  .mg-sm-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .mg-sm-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .mg-sm-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .mg-sm-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .mg-sm-t-75 {
    margin-top: 75px;
  }
  .mg-sm-r-75 {
    margin-right: 75px;
  }
  .mg-sm-b-75 {
    margin-bottom: 75px;
  }
  .mg-sm-l-75 {
    margin-left: 75px;
  }
  .mg-sm-t-75-f {
    margin-top: 75px !important;
  }
  .mg-sm-r-75-f {
    margin-right: 75px !important;
  }
  .mg-sm-b-75-f {
    margin-bottom: 75px !important;
  }
  .mg-sm-l-75-f {
    margin-left: 75px !important;
  }
  .mg-sm-80 {
    margin: 80px;
  }
  .mg-sm-80-f {
    margin: 80px !important;
  }
  .mg-sm-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mg-sm-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mg-sm-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mg-sm-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .mg-sm-t-80 {
    margin-top: 80px;
  }
  .mg-sm-r-80 {
    margin-right: 80px;
  }
  .mg-sm-b-80 {
    margin-bottom: 80px;
  }
  .mg-sm-l-80 {
    margin-left: 80px;
  }
  .mg-sm-t-80-f {
    margin-top: 80px !important;
  }
  .mg-sm-r-80-f {
    margin-right: 80px !important;
  }
  .mg-sm-b-80-f {
    margin-bottom: 80px !important;
  }
  .mg-sm-l-80-f {
    margin-left: 80px !important;
  }
  .mg-sm-85 {
    margin: 85px;
  }
  .mg-sm-85-f {
    margin: 85px !important;
  }
  .mg-sm-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .mg-sm-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .mg-sm-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .mg-sm-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .mg-sm-t-85 {
    margin-top: 85px;
  }
  .mg-sm-r-85 {
    margin-right: 85px;
  }
  .mg-sm-b-85 {
    margin-bottom: 85px;
  }
  .mg-sm-l-85 {
    margin-left: 85px;
  }
  .mg-sm-t-85-f {
    margin-top: 85px !important;
  }
  .mg-sm-r-85-f {
    margin-right: 85px !important;
  }
  .mg-sm-b-85-f {
    margin-bottom: 85px !important;
  }
  .mg-sm-l-85-f {
    margin-left: 85px !important;
  }
  .mg-sm-90 {
    margin: 90px;
  }
  .mg-sm-90-f {
    margin: 90px !important;
  }
  .mg-sm-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mg-sm-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mg-sm-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mg-sm-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .mg-sm-t-90 {
    margin-top: 90px;
  }
  .mg-sm-r-90 {
    margin-right: 90px;
  }
  .mg-sm-b-90 {
    margin-bottom: 90px;
  }
  .mg-sm-l-90 {
    margin-left: 90px;
  }
  .mg-sm-t-90-f {
    margin-top: 90px !important;
  }
  .mg-sm-r-90-f {
    margin-right: 90px !important;
  }
  .mg-sm-b-90-f {
    margin-bottom: 90px !important;
  }
  .mg-sm-l-90-f {
    margin-left: 90px !important;
  }
  .mg-sm-95 {
    margin: 95px;
  }
  .mg-sm-95-f {
    margin: 95px !important;
  }
  .mg-sm-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .mg-sm-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .mg-sm-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .mg-sm-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .mg-sm-t-95 {
    margin-top: 95px;
  }
  .mg-sm-r-95 {
    margin-right: 95px;
  }
  .mg-sm-b-95 {
    margin-bottom: 95px;
  }
  .mg-sm-l-95 {
    margin-left: 95px;
  }
  .mg-sm-t-95-f {
    margin-top: 95px !important;
  }
  .mg-sm-r-95-f {
    margin-right: 95px !important;
  }
  .mg-sm-b-95-f {
    margin-bottom: 95px !important;
  }
  .mg-sm-l-95-f {
    margin-left: 95px !important;
  }
  .mg-sm-100 {
    margin: 100px;
  }
  .mg-sm-100-f {
    margin: 100px !important;
  }
  .mg-sm-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mg-sm-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .mg-sm-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mg-sm-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .mg-sm-t-100 {
    margin-top: 100px;
  }
  .mg-sm-r-100 {
    margin-right: 100px;
  }
  .mg-sm-b-100 {
    margin-bottom: 100px;
  }
  .mg-sm-l-100 {
    margin-left: 100px;
  }
  .mg-sm-t-100-f {
    margin-top: 100px !important;
  }
  .mg-sm-r-100-f {
    margin-right: 100px !important;
  }
  .mg-sm-b-100-f {
    margin-bottom: 100px !important;
  }
  .mg-sm-l-100-f {
    margin-left: 100px !important;
  }
  .mg-sm-t-auto {
    margin-top: auto;
  }
  .mg-sm-r-auto {
    margin-right: auto;
  }
  .mg-sm-b-auto {
    margin-bottom: auto;
  }
  .mg-sm-l-auto {
    margin-left: auto;
  }
  .mg-sm-auto {
    margin: auto;
  }
  .mg-sm-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .mg-md-0 {
    margin: 0px;
  }
  .mg-md-0-f {
    margin: 0px !important;
  }
  .mg-md-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mg-md-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mg-md-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-md-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mg-md-t-0 {
    margin-top: 0px;
  }
  .mg-md-r-0 {
    margin-right: 0px;
  }
  .mg-md-b-0 {
    margin-bottom: 0px;
  }
  .mg-md-l-0 {
    margin-left: 0px;
  }
  .mg-md-t-0-f {
    margin-top: 0px !important;
  }
  .mg-md-r-0-f {
    margin-right: 0px !important;
  }
  .mg-md-b-0-f {
    margin-bottom: 0px !important;
  }
  .mg-md-l-0-f {
    margin-left: 0px !important;
  }
  .mg-md-1 {
    margin: 1px;
  }
  .mg-md-1-f {
    margin: 1px !important;
  }
  .mg-md-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .mg-md-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mg-md-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .mg-md-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mg-md-t-1 {
    margin-top: 1px;
  }
  .mg-md-r-1 {
    margin-right: 1px;
  }
  .mg-md-b-1 {
    margin-bottom: 1px;
  }
  .mg-md-l-1 {
    margin-left: 1px;
  }
  .mg-md-t-1-f {
    margin-top: 1px !important;
  }
  .mg-md-r-1-f {
    margin-right: 1px !important;
  }
  .mg-md-b-1-f {
    margin-bottom: 1px !important;
  }
  .mg-md-l-1-f {
    margin-left: 1px !important;
  }
  .mg-md-2 {
    margin: 2px;
  }
  .mg-md-2-f {
    margin: 2px !important;
  }
  .mg-md-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mg-md-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mg-md-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mg-md-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mg-md-t-2 {
    margin-top: 2px;
  }
  .mg-md-r-2 {
    margin-right: 2px;
  }
  .mg-md-b-2 {
    margin-bottom: 2px;
  }
  .mg-md-l-2 {
    margin-left: 2px;
  }
  .mg-md-t-2-f {
    margin-top: 2px !important;
  }
  .mg-md-r-2-f {
    margin-right: 2px !important;
  }
  .mg-md-b-2-f {
    margin-bottom: 2px !important;
  }
  .mg-md-l-2-f {
    margin-left: 2px !important;
  }
  .mg-md-3 {
    margin: 3px;
  }
  .mg-md-3-f {
    margin: 3px !important;
  }
  .mg-md-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .mg-md-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .mg-md-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mg-md-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .mg-md-t-3 {
    margin-top: 3px;
  }
  .mg-md-r-3 {
    margin-right: 3px;
  }
  .mg-md-b-3 {
    margin-bottom: 3px;
  }
  .mg-md-l-3 {
    margin-left: 3px;
  }
  .mg-md-t-3-f {
    margin-top: 3px !important;
  }
  .mg-md-r-3-f {
    margin-right: 3px !important;
  }
  .mg-md-b-3-f {
    margin-bottom: 3px !important;
  }
  .mg-md-l-3-f {
    margin-left: 3px !important;
  }
  .mg-md-4 {
    margin: 4px;
  }
  .mg-md-4-f {
    margin: 4px !important;
  }
  .mg-md-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mg-md-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mg-md-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mg-md-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .mg-md-t-4 {
    margin-top: 4px;
  }
  .mg-md-r-4 {
    margin-right: 4px;
  }
  .mg-md-b-4 {
    margin-bottom: 4px;
  }
  .mg-md-l-4 {
    margin-left: 4px;
  }
  .mg-md-t-4-f {
    margin-top: 4px !important;
  }
  .mg-md-r-4-f {
    margin-right: 4px !important;
  }
  .mg-md-b-4-f {
    margin-bottom: 4px !important;
  }
  .mg-md-l-4-f {
    margin-left: 4px !important;
  }
  .mg-md-5 {
    margin: 5px;
  }
  .mg-md-5-f {
    margin: 5px !important;
  }
  .mg-md-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mg-md-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mg-md-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-md-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .mg-md-t-5 {
    margin-top: 5px;
  }
  .mg-md-r-5 {
    margin-right: 5px;
  }
  .mg-md-b-5 {
    margin-bottom: 5px;
  }
  .mg-md-l-5 {
    margin-left: 5px;
  }
  .mg-md-t-5-f {
    margin-top: 5px !important;
  }
  .mg-md-r-5-f {
    margin-right: 5px !important;
  }
  .mg-md-b-5-f {
    margin-bottom: 5px !important;
  }
  .mg-md-l-5-f {
    margin-left: 5px !important;
  }
  .mg-md-6 {
    margin: 6px;
  }
  .mg-md-6-f {
    margin: 6px !important;
  }
  .mg-md-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .mg-md-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .mg-md-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }
  .mg-md-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .mg-md-t-6 {
    margin-top: 6px;
  }
  .mg-md-r-6 {
    margin-right: 6px;
  }
  .mg-md-b-6 {
    margin-bottom: 6px;
  }
  .mg-md-l-6 {
    margin-left: 6px;
  }
  .mg-md-t-6-f {
    margin-top: 6px !important;
  }
  .mg-md-r-6-f {
    margin-right: 6px !important;
  }
  .mg-md-b-6-f {
    margin-bottom: 6px !important;
  }
  .mg-md-l-6-f {
    margin-left: 6px !important;
  }
  .mg-md-7 {
    margin: 7px;
  }
  .mg-md-7-f {
    margin: 7px !important;
  }
  .mg-md-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .mg-md-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
  .mg-md-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }
  .mg-md-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .mg-md-t-7 {
    margin-top: 7px;
  }
  .mg-md-r-7 {
    margin-right: 7px;
  }
  .mg-md-b-7 {
    margin-bottom: 7px;
  }
  .mg-md-l-7 {
    margin-left: 7px;
  }
  .mg-md-t-7-f {
    margin-top: 7px !important;
  }
  .mg-md-r-7-f {
    margin-right: 7px !important;
  }
  .mg-md-b-7-f {
    margin-bottom: 7px !important;
  }
  .mg-md-l-7-f {
    margin-left: 7px !important;
  }
  .mg-md-8 {
    margin: 8px;
  }
  .mg-md-8-f {
    margin: 8px !important;
  }
  .mg-md-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mg-md-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mg-md-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mg-md-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .mg-md-t-8 {
    margin-top: 8px;
  }
  .mg-md-r-8 {
    margin-right: 8px;
  }
  .mg-md-b-8 {
    margin-bottom: 8px;
  }
  .mg-md-l-8 {
    margin-left: 8px;
  }
  .mg-md-t-8-f {
    margin-top: 8px !important;
  }
  .mg-md-r-8-f {
    margin-right: 8px !important;
  }
  .mg-md-b-8-f {
    margin-bottom: 8px !important;
  }
  .mg-md-l-8-f {
    margin-left: 8px !important;
  }
  .mg-md-9 {
    margin: 9px;
  }
  .mg-md-9-f {
    margin: 9px !important;
  }
  .mg-md-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .mg-md-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }
  .mg-md-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }
  .mg-md-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }
  .mg-md-t-9 {
    margin-top: 9px;
  }
  .mg-md-r-9 {
    margin-right: 9px;
  }
  .mg-md-b-9 {
    margin-bottom: 9px;
  }
  .mg-md-l-9 {
    margin-left: 9px;
  }
  .mg-md-t-9-f {
    margin-top: 9px !important;
  }
  .mg-md-r-9-f {
    margin-right: 9px !important;
  }
  .mg-md-b-9-f {
    margin-bottom: 9px !important;
  }
  .mg-md-l-9-f {
    margin-left: 9px !important;
  }
  .mg-md-10 {
    margin: 10px;
  }
  .mg-md-10-f {
    margin: 10px !important;
  }
  .mg-md-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mg-md-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mg-md-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-md-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .mg-md-t-10 {
    margin-top: 10px;
  }
  .mg-md-r-10 {
    margin-right: 10px;
  }
  .mg-md-b-10 {
    margin-bottom: 10px;
  }
  .mg-md-l-10 {
    margin-left: 10px;
  }
  .mg-md-t-10-f {
    margin-top: 10px !important;
  }
  .mg-md-r-10-f {
    margin-right: 10px !important;
  }
  .mg-md-b-10-f {
    margin-bottom: 10px !important;
  }
  .mg-md-l-10-f {
    margin-left: 10px !important;
  }
  .mg-md-15 {
    margin: 15px;
  }
  .mg-md-15-f {
    margin: 15px !important;
  }
  .mg-md-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mg-md-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mg-md-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-md-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .mg-md-t-15 {
    margin-top: 15px;
  }
  .mg-md-r-15 {
    margin-right: 15px;
  }
  .mg-md-b-15 {
    margin-bottom: 15px;
  }
  .mg-md-l-15 {
    margin-left: 15px;
  }
  .mg-md-t-15-f {
    margin-top: 15px !important;
  }
  .mg-md-r-15-f {
    margin-right: 15px !important;
  }
  .mg-md-b-15-f {
    margin-bottom: 15px !important;
  }
  .mg-md-l-15-f {
    margin-left: 15px !important;
  }
  .mg-md-20 {
    margin: 20px;
  }
  .mg-md-20-f {
    margin: 20px !important;
  }
  .mg-md-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mg-md-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mg-md-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mg-md-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .mg-md-t-20 {
    margin-top: 20px;
  }
  .mg-md-r-20 {
    margin-right: 20px;
  }
  .mg-md-b-20 {
    margin-bottom: 20px;
  }
  .mg-md-l-20 {
    margin-left: 20px;
  }
  .mg-md-t-20-f {
    margin-top: 20px !important;
  }
  .mg-md-r-20-f {
    margin-right: 20px !important;
  }
  .mg-md-b-20-f {
    margin-bottom: 20px !important;
  }
  .mg-md-l-20-f {
    margin-left: 20px !important;
  }
  .mg-md-25 {
    margin: 25px;
  }
  .mg-md-25-f {
    margin: 25px !important;
  }
  .mg-md-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mg-md-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .mg-md-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .mg-md-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .mg-md-t-25 {
    margin-top: 25px;
  }
  .mg-md-r-25 {
    margin-right: 25px;
  }
  .mg-md-b-25 {
    margin-bottom: 25px;
  }
  .mg-md-l-25 {
    margin-left: 25px;
  }
  .mg-md-t-25-f {
    margin-top: 25px !important;
  }
  .mg-md-r-25-f {
    margin-right: 25px !important;
  }
  .mg-md-b-25-f {
    margin-bottom: 25px !important;
  }
  .mg-md-l-25-f {
    margin-left: 25px !important;
  }
  .mg-md-30 {
    margin: 30px;
  }
  .mg-md-30-f {
    margin: 30px !important;
  }
  .mg-md-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mg-md-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mg-md-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-md-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .mg-md-t-30 {
    margin-top: 30px;
  }
  .mg-md-r-30 {
    margin-right: 30px;
  }
  .mg-md-b-30 {
    margin-bottom: 30px;
  }
  .mg-md-l-30 {
    margin-left: 30px;
  }
  .mg-md-t-30-f {
    margin-top: 30px !important;
  }
  .mg-md-r-30-f {
    margin-right: 30px !important;
  }
  .mg-md-b-30-f {
    margin-bottom: 30px !important;
  }
  .mg-md-l-30-f {
    margin-left: 30px !important;
  }
  .mg-md-35 {
    margin: 35px;
  }
  .mg-md-35-f {
    margin: 35px !important;
  }
  .mg-md-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .mg-md-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .mg-md-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .mg-md-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .mg-md-t-35 {
    margin-top: 35px;
  }
  .mg-md-r-35 {
    margin-right: 35px;
  }
  .mg-md-b-35 {
    margin-bottom: 35px;
  }
  .mg-md-l-35 {
    margin-left: 35px;
  }
  .mg-md-t-35-f {
    margin-top: 35px !important;
  }
  .mg-md-r-35-f {
    margin-right: 35px !important;
  }
  .mg-md-b-35-f {
    margin-bottom: 35px !important;
  }
  .mg-md-l-35-f {
    margin-left: 35px !important;
  }
  .mg-md-40 {
    margin: 40px;
  }
  .mg-md-40-f {
    margin: 40px !important;
  }
  .mg-md-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mg-md-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mg-md-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mg-md-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .mg-md-t-40 {
    margin-top: 40px;
  }
  .mg-md-r-40 {
    margin-right: 40px;
  }
  .mg-md-b-40 {
    margin-bottom: 40px;
  }
  .mg-md-l-40 {
    margin-left: 40px;
  }
  .mg-md-t-40-f {
    margin-top: 40px !important;
  }
  .mg-md-r-40-f {
    margin-right: 40px !important;
  }
  .mg-md-b-40-f {
    margin-bottom: 40px !important;
  }
  .mg-md-l-40-f {
    margin-left: 40px !important;
  }
  .mg-md-45 {
    margin: 45px;
  }
  .mg-md-45-f {
    margin: 45px !important;
  }
  .mg-md-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .mg-md-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mg-md-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-md-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .mg-md-t-45 {
    margin-top: 45px;
  }
  .mg-md-r-45 {
    margin-right: 45px;
  }
  .mg-md-b-45 {
    margin-bottom: 45px;
  }
  .mg-md-l-45 {
    margin-left: 45px;
  }
  .mg-md-t-45-f {
    margin-top: 45px !important;
  }
  .mg-md-r-45-f {
    margin-right: 45px !important;
  }
  .mg-md-b-45-f {
    margin-bottom: 45px !important;
  }
  .mg-md-l-45-f {
    margin-left: 45px !important;
  }
  .mg-md-50 {
    margin: 50px;
  }
  .mg-md-50-f {
    margin: 50px !important;
  }
  .mg-md-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mg-md-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mg-md-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mg-md-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .mg-md-t-50 {
    margin-top: 50px;
  }
  .mg-md-r-50 {
    margin-right: 50px;
  }
  .mg-md-b-50 {
    margin-bottom: 50px;
  }
  .mg-md-l-50 {
    margin-left: 50px;
  }
  .mg-md-t-50-f {
    margin-top: 50px !important;
  }
  .mg-md-r-50-f {
    margin-right: 50px !important;
  }
  .mg-md-b-50-f {
    margin-bottom: 50px !important;
  }
  .mg-md-l-50-f {
    margin-left: 50px !important;
  }
  .mg-md-55 {
    margin: 55px;
  }
  .mg-md-55-f {
    margin: 55px !important;
  }
  .mg-md-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .mg-md-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .mg-md-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .mg-md-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .mg-md-t-55 {
    margin-top: 55px;
  }
  .mg-md-r-55 {
    margin-right: 55px;
  }
  .mg-md-b-55 {
    margin-bottom: 55px;
  }
  .mg-md-l-55 {
    margin-left: 55px;
  }
  .mg-md-t-55-f {
    margin-top: 55px !important;
  }
  .mg-md-r-55-f {
    margin-right: 55px !important;
  }
  .mg-md-b-55-f {
    margin-bottom: 55px !important;
  }
  .mg-md-l-55-f {
    margin-left: 55px !important;
  }
  .mg-md-60 {
    margin: 60px;
  }
  .mg-md-60-f {
    margin: 60px !important;
  }
  .mg-md-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mg-md-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mg-md-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-md-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .mg-md-t-60 {
    margin-top: 60px;
  }
  .mg-md-r-60 {
    margin-right: 60px;
  }
  .mg-md-b-60 {
    margin-bottom: 60px;
  }
  .mg-md-l-60 {
    margin-left: 60px;
  }
  .mg-md-t-60-f {
    margin-top: 60px !important;
  }
  .mg-md-r-60-f {
    margin-right: 60px !important;
  }
  .mg-md-b-60-f {
    margin-bottom: 60px !important;
  }
  .mg-md-l-60-f {
    margin-left: 60px !important;
  }
  .mg-md-65 {
    margin: 65px;
  }
  .mg-md-65-f {
    margin: 65px !important;
  }
  .mg-md-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .mg-md-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .mg-md-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .mg-md-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .mg-md-t-65 {
    margin-top: 65px;
  }
  .mg-md-r-65 {
    margin-right: 65px;
  }
  .mg-md-b-65 {
    margin-bottom: 65px;
  }
  .mg-md-l-65 {
    margin-left: 65px;
  }
  .mg-md-t-65-f {
    margin-top: 65px !important;
  }
  .mg-md-r-65-f {
    margin-right: 65px !important;
  }
  .mg-md-b-65-f {
    margin-bottom: 65px !important;
  }
  .mg-md-l-65-f {
    margin-left: 65px !important;
  }
  .mg-md-70 {
    margin: 70px;
  }
  .mg-md-70-f {
    margin: 70px !important;
  }
  .mg-md-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mg-md-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mg-md-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mg-md-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .mg-md-t-70 {
    margin-top: 70px;
  }
  .mg-md-r-70 {
    margin-right: 70px;
  }
  .mg-md-b-70 {
    margin-bottom: 70px;
  }
  .mg-md-l-70 {
    margin-left: 70px;
  }
  .mg-md-t-70-f {
    margin-top: 70px !important;
  }
  .mg-md-r-70-f {
    margin-right: 70px !important;
  }
  .mg-md-b-70-f {
    margin-bottom: 70px !important;
  }
  .mg-md-l-70-f {
    margin-left: 70px !important;
  }
  .mg-md-75 {
    margin: 75px;
  }
  .mg-md-75-f {
    margin: 75px !important;
  }
  .mg-md-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .mg-md-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .mg-md-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .mg-md-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .mg-md-t-75 {
    margin-top: 75px;
  }
  .mg-md-r-75 {
    margin-right: 75px;
  }
  .mg-md-b-75 {
    margin-bottom: 75px;
  }
  .mg-md-l-75 {
    margin-left: 75px;
  }
  .mg-md-t-75-f {
    margin-top: 75px !important;
  }
  .mg-md-r-75-f {
    margin-right: 75px !important;
  }
  .mg-md-b-75-f {
    margin-bottom: 75px !important;
  }
  .mg-md-l-75-f {
    margin-left: 75px !important;
  }
  .mg-md-80 {
    margin: 80px;
  }
  .mg-md-80-f {
    margin: 80px !important;
  }
  .mg-md-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mg-md-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mg-md-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mg-md-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .mg-md-t-80 {
    margin-top: 80px;
  }
  .mg-md-r-80 {
    margin-right: 80px;
  }
  .mg-md-b-80 {
    margin-bottom: 80px;
  }
  .mg-md-l-80 {
    margin-left: 80px;
  }
  .mg-md-t-80-f {
    margin-top: 80px !important;
  }
  .mg-md-r-80-f {
    margin-right: 80px !important;
  }
  .mg-md-b-80-f {
    margin-bottom: 80px !important;
  }
  .mg-md-l-80-f {
    margin-left: 80px !important;
  }
  .mg-md-85 {
    margin: 85px;
  }
  .mg-md-85-f {
    margin: 85px !important;
  }
  .mg-md-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .mg-md-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .mg-md-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .mg-md-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .mg-md-t-85 {
    margin-top: 85px;
  }
  .mg-md-r-85 {
    margin-right: 85px;
  }
  .mg-md-b-85 {
    margin-bottom: 85px;
  }
  .mg-md-l-85 {
    margin-left: 85px;
  }
  .mg-md-t-85-f {
    margin-top: 85px !important;
  }
  .mg-md-r-85-f {
    margin-right: 85px !important;
  }
  .mg-md-b-85-f {
    margin-bottom: 85px !important;
  }
  .mg-md-l-85-f {
    margin-left: 85px !important;
  }
  .mg-md-90 {
    margin: 90px;
  }
  .mg-md-90-f {
    margin: 90px !important;
  }
  .mg-md-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mg-md-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mg-md-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mg-md-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .mg-md-t-90 {
    margin-top: 90px;
  }
  .mg-md-r-90 {
    margin-right: 90px;
  }
  .mg-md-b-90 {
    margin-bottom: 90px;
  }
  .mg-md-l-90 {
    margin-left: 90px;
  }
  .mg-md-t-90-f {
    margin-top: 90px !important;
  }
  .mg-md-r-90-f {
    margin-right: 90px !important;
  }
  .mg-md-b-90-f {
    margin-bottom: 90px !important;
  }
  .mg-md-l-90-f {
    margin-left: 90px !important;
  }
  .mg-md-95 {
    margin: 95px;
  }
  .mg-md-95-f {
    margin: 95px !important;
  }
  .mg-md-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .mg-md-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .mg-md-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .mg-md-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .mg-md-t-95 {
    margin-top: 95px;
  }
  .mg-md-r-95 {
    margin-right: 95px;
  }
  .mg-md-b-95 {
    margin-bottom: 95px;
  }
  .mg-md-l-95 {
    margin-left: 95px;
  }
  .mg-md-t-95-f {
    margin-top: 95px !important;
  }
  .mg-md-r-95-f {
    margin-right: 95px !important;
  }
  .mg-md-b-95-f {
    margin-bottom: 95px !important;
  }
  .mg-md-l-95-f {
    margin-left: 95px !important;
  }
  .mg-md-100 {
    margin: 100px;
  }
  .mg-md-100-f {
    margin: 100px !important;
  }
  .mg-md-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mg-md-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .mg-md-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mg-md-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .mg-md-t-100 {
    margin-top: 100px;
  }
  .mg-md-r-100 {
    margin-right: 100px;
  }
  .mg-md-b-100 {
    margin-bottom: 100px;
  }
  .mg-md-l-100 {
    margin-left: 100px;
  }
  .mg-md-t-100-f {
    margin-top: 100px !important;
  }
  .mg-md-r-100-f {
    margin-right: 100px !important;
  }
  .mg-md-b-100-f {
    margin-bottom: 100px !important;
  }
  .mg-md-l-100-f {
    margin-left: 100px !important;
  }
  .mg-md-t-auto {
    margin-top: auto;
  }
  .mg-md-r-auto {
    margin-right: auto;
  }
  .mg-md-b-auto {
    margin-bottom: auto;
  }
  .mg-md-l-auto {
    margin-left: auto;
  }
  .mg-md-auto {
    margin: auto;
  }
  .mg-md-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .mg-lg-0 {
    margin: 0px;
  }
  .mg-lg-0-f {
    margin: 0px !important;
  }
  .mg-lg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mg-lg-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mg-lg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-lg-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mg-lg-t-0 {
    margin-top: 0px;
  }
  .mg-lg-r-0 {
    margin-right: 0px;
  }
  .mg-lg-b-0 {
    margin-bottom: 0px;
  }
  .mg-lg-l-0 {
    margin-left: 0px;
  }
  .mg-lg-t-0-f {
    margin-top: 0px !important;
  }
  .mg-lg-r-0-f {
    margin-right: 0px !important;
  }
  .mg-lg-b-0-f {
    margin-bottom: 0px !important;
  }
  .mg-lg-l-0-f {
    margin-left: 0px !important;
  }
  .mg-lg-1 {
    margin: 1px;
  }
  .mg-lg-1-f {
    margin: 1px !important;
  }
  .mg-lg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .mg-lg-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mg-lg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .mg-lg-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mg-lg-t-1 {
    margin-top: 1px;
  }
  .mg-lg-r-1 {
    margin-right: 1px;
  }
  .mg-lg-b-1 {
    margin-bottom: 1px;
  }
  .mg-lg-l-1 {
    margin-left: 1px;
  }
  .mg-lg-t-1-f {
    margin-top: 1px !important;
  }
  .mg-lg-r-1-f {
    margin-right: 1px !important;
  }
  .mg-lg-b-1-f {
    margin-bottom: 1px !important;
  }
  .mg-lg-l-1-f {
    margin-left: 1px !important;
  }
  .mg-lg-2 {
    margin: 2px;
  }
  .mg-lg-2-f {
    margin: 2px !important;
  }
  .mg-lg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mg-lg-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mg-lg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mg-lg-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mg-lg-t-2 {
    margin-top: 2px;
  }
  .mg-lg-r-2 {
    margin-right: 2px;
  }
  .mg-lg-b-2 {
    margin-bottom: 2px;
  }
  .mg-lg-l-2 {
    margin-left: 2px;
  }
  .mg-lg-t-2-f {
    margin-top: 2px !important;
  }
  .mg-lg-r-2-f {
    margin-right: 2px !important;
  }
  .mg-lg-b-2-f {
    margin-bottom: 2px !important;
  }
  .mg-lg-l-2-f {
    margin-left: 2px !important;
  }
  .mg-lg-3 {
    margin: 3px;
  }
  .mg-lg-3-f {
    margin: 3px !important;
  }
  .mg-lg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .mg-lg-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .mg-lg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mg-lg-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .mg-lg-t-3 {
    margin-top: 3px;
  }
  .mg-lg-r-3 {
    margin-right: 3px;
  }
  .mg-lg-b-3 {
    margin-bottom: 3px;
  }
  .mg-lg-l-3 {
    margin-left: 3px;
  }
  .mg-lg-t-3-f {
    margin-top: 3px !important;
  }
  .mg-lg-r-3-f {
    margin-right: 3px !important;
  }
  .mg-lg-b-3-f {
    margin-bottom: 3px !important;
  }
  .mg-lg-l-3-f {
    margin-left: 3px !important;
  }
  .mg-lg-4 {
    margin: 4px;
  }
  .mg-lg-4-f {
    margin: 4px !important;
  }
  .mg-lg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mg-lg-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mg-lg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mg-lg-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .mg-lg-t-4 {
    margin-top: 4px;
  }
  .mg-lg-r-4 {
    margin-right: 4px;
  }
  .mg-lg-b-4 {
    margin-bottom: 4px;
  }
  .mg-lg-l-4 {
    margin-left: 4px;
  }
  .mg-lg-t-4-f {
    margin-top: 4px !important;
  }
  .mg-lg-r-4-f {
    margin-right: 4px !important;
  }
  .mg-lg-b-4-f {
    margin-bottom: 4px !important;
  }
  .mg-lg-l-4-f {
    margin-left: 4px !important;
  }
  .mg-lg-5 {
    margin: 5px;
  }
  .mg-lg-5-f {
    margin: 5px !important;
  }
  .mg-lg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mg-lg-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mg-lg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-lg-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .mg-lg-t-5 {
    margin-top: 5px;
  }
  .mg-lg-r-5 {
    margin-right: 5px;
  }
  .mg-lg-b-5 {
    margin-bottom: 5px;
  }
  .mg-lg-l-5 {
    margin-left: 5px;
  }
  .mg-lg-t-5-f {
    margin-top: 5px !important;
  }
  .mg-lg-r-5-f {
    margin-right: 5px !important;
  }
  .mg-lg-b-5-f {
    margin-bottom: 5px !important;
  }
  .mg-lg-l-5-f {
    margin-left: 5px !important;
  }
  .mg-lg-6 {
    margin: 6px;
  }
  .mg-lg-6-f {
    margin: 6px !important;
  }
  .mg-lg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .mg-lg-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .mg-lg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }
  .mg-lg-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .mg-lg-t-6 {
    margin-top: 6px;
  }
  .mg-lg-r-6 {
    margin-right: 6px;
  }
  .mg-lg-b-6 {
    margin-bottom: 6px;
  }
  .mg-lg-l-6 {
    margin-left: 6px;
  }
  .mg-lg-t-6-f {
    margin-top: 6px !important;
  }
  .mg-lg-r-6-f {
    margin-right: 6px !important;
  }
  .mg-lg-b-6-f {
    margin-bottom: 6px !important;
  }
  .mg-lg-l-6-f {
    margin-left: 6px !important;
  }
  .mg-lg-7 {
    margin: 7px;
  }
  .mg-lg-7-f {
    margin: 7px !important;
  }
  .mg-lg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .mg-lg-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
  .mg-lg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }
  .mg-lg-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .mg-lg-t-7 {
    margin-top: 7px;
  }
  .mg-lg-r-7 {
    margin-right: 7px;
  }
  .mg-lg-b-7 {
    margin-bottom: 7px;
  }
  .mg-lg-l-7 {
    margin-left: 7px;
  }
  .mg-lg-t-7-f {
    margin-top: 7px !important;
  }
  .mg-lg-r-7-f {
    margin-right: 7px !important;
  }
  .mg-lg-b-7-f {
    margin-bottom: 7px !important;
  }
  .mg-lg-l-7-f {
    margin-left: 7px !important;
  }
  .mg-lg-8 {
    margin: 8px;
  }
  .mg-lg-8-f {
    margin: 8px !important;
  }
  .mg-lg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mg-lg-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mg-lg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mg-lg-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .mg-lg-t-8 {
    margin-top: 8px;
  }
  .mg-lg-r-8 {
    margin-right: 8px;
  }
  .mg-lg-b-8 {
    margin-bottom: 8px;
  }
  .mg-lg-l-8 {
    margin-left: 8px;
  }
  .mg-lg-t-8-f {
    margin-top: 8px !important;
  }
  .mg-lg-r-8-f {
    margin-right: 8px !important;
  }
  .mg-lg-b-8-f {
    margin-bottom: 8px !important;
  }
  .mg-lg-l-8-f {
    margin-left: 8px !important;
  }
  .mg-lg-9 {
    margin: 9px;
  }
  .mg-lg-9-f {
    margin: 9px !important;
  }
  .mg-lg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .mg-lg-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }
  .mg-lg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }
  .mg-lg-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }
  .mg-lg-t-9 {
    margin-top: 9px;
  }
  .mg-lg-r-9 {
    margin-right: 9px;
  }
  .mg-lg-b-9 {
    margin-bottom: 9px;
  }
  .mg-lg-l-9 {
    margin-left: 9px;
  }
  .mg-lg-t-9-f {
    margin-top: 9px !important;
  }
  .mg-lg-r-9-f {
    margin-right: 9px !important;
  }
  .mg-lg-b-9-f {
    margin-bottom: 9px !important;
  }
  .mg-lg-l-9-f {
    margin-left: 9px !important;
  }
  .mg-lg-10 {
    margin: 10px;
  }
  .mg-lg-10-f {
    margin: 10px !important;
  }
  .mg-lg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mg-lg-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mg-lg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-lg-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .mg-lg-t-10 {
    margin-top: 10px;
  }
  .mg-lg-r-10 {
    margin-right: 10px;
  }
  .mg-lg-b-10 {
    margin-bottom: 10px;
  }
  .mg-lg-l-10 {
    margin-left: 10px;
  }
  .mg-lg-t-10-f {
    margin-top: 10px !important;
  }
  .mg-lg-r-10-f {
    margin-right: 10px !important;
  }
  .mg-lg-b-10-f {
    margin-bottom: 10px !important;
  }
  .mg-lg-l-10-f {
    margin-left: 10px !important;
  }
  .mg-lg-15 {
    margin: 15px;
  }
  .mg-lg-15-f {
    margin: 15px !important;
  }
  .mg-lg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mg-lg-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mg-lg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-lg-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .mg-lg-t-15 {
    margin-top: 15px;
  }
  .mg-lg-r-15 {
    margin-right: 15px;
  }
  .mg-lg-b-15 {
    margin-bottom: 15px;
  }
  .mg-lg-l-15 {
    margin-left: 15px;
  }
  .mg-lg-t-15-f {
    margin-top: 15px !important;
  }
  .mg-lg-r-15-f {
    margin-right: 15px !important;
  }
  .mg-lg-b-15-f {
    margin-bottom: 15px !important;
  }
  .mg-lg-l-15-f {
    margin-left: 15px !important;
  }
  .mg-lg-20 {
    margin: 20px;
  }
  .mg-lg-20-f {
    margin: 20px !important;
  }
  .mg-lg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mg-lg-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mg-lg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mg-lg-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .mg-lg-t-20 {
    margin-top: 20px;
  }
  .mg-lg-r-20 {
    margin-right: 20px;
  }
  .mg-lg-b-20 {
    margin-bottom: 20px;
  }
  .mg-lg-l-20 {
    margin-left: 20px;
  }
  .mg-lg-t-20-f {
    margin-top: 20px !important;
  }
  .mg-lg-r-20-f {
    margin-right: 20px !important;
  }
  .mg-lg-b-20-f {
    margin-bottom: 20px !important;
  }
  .mg-lg-l-20-f {
    margin-left: 20px !important;
  }
  .mg-lg-25 {
    margin: 25px;
  }
  .mg-lg-25-f {
    margin: 25px !important;
  }
  .mg-lg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mg-lg-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .mg-lg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .mg-lg-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .mg-lg-t-25 {
    margin-top: 25px;
  }
  .mg-lg-r-25 {
    margin-right: 25px;
  }
  .mg-lg-b-25 {
    margin-bottom: 25px;
  }
  .mg-lg-l-25 {
    margin-left: 25px;
  }
  .mg-lg-t-25-f {
    margin-top: 25px !important;
  }
  .mg-lg-r-25-f {
    margin-right: 25px !important;
  }
  .mg-lg-b-25-f {
    margin-bottom: 25px !important;
  }
  .mg-lg-l-25-f {
    margin-left: 25px !important;
  }
  .mg-lg-30 {
    margin: 30px;
  }
  .mg-lg-30-f {
    margin: 30px !important;
  }
  .mg-lg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mg-lg-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mg-lg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-lg-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .mg-lg-t-30 {
    margin-top: 30px;
  }
  .mg-lg-r-30 {
    margin-right: 30px;
  }
  .mg-lg-b-30 {
    margin-bottom: 30px;
  }
  .mg-lg-l-30 {
    margin-left: 30px;
  }
  .mg-lg-t-30-f {
    margin-top: 30px !important;
  }
  .mg-lg-r-30-f {
    margin-right: 30px !important;
  }
  .mg-lg-b-30-f {
    margin-bottom: 30px !important;
  }
  .mg-lg-l-30-f {
    margin-left: 30px !important;
  }
  .mg-lg-35 {
    margin: 35px;
  }
  .mg-lg-35-f {
    margin: 35px !important;
  }
  .mg-lg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .mg-lg-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .mg-lg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .mg-lg-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .mg-lg-t-35 {
    margin-top: 35px;
  }
  .mg-lg-r-35 {
    margin-right: 35px;
  }
  .mg-lg-b-35 {
    margin-bottom: 35px;
  }
  .mg-lg-l-35 {
    margin-left: 35px;
  }
  .mg-lg-t-35-f {
    margin-top: 35px !important;
  }
  .mg-lg-r-35-f {
    margin-right: 35px !important;
  }
  .mg-lg-b-35-f {
    margin-bottom: 35px !important;
  }
  .mg-lg-l-35-f {
    margin-left: 35px !important;
  }
  .mg-lg-40 {
    margin: 40px;
  }
  .mg-lg-40-f {
    margin: 40px !important;
  }
  .mg-lg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mg-lg-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mg-lg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mg-lg-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .mg-lg-t-40 {
    margin-top: 40px;
  }
  .mg-lg-r-40 {
    margin-right: 40px;
  }
  .mg-lg-b-40 {
    margin-bottom: 40px;
  }
  .mg-lg-l-40 {
    margin-left: 40px;
  }
  .mg-lg-t-40-f {
    margin-top: 40px !important;
  }
  .mg-lg-r-40-f {
    margin-right: 40px !important;
  }
  .mg-lg-b-40-f {
    margin-bottom: 40px !important;
  }
  .mg-lg-l-40-f {
    margin-left: 40px !important;
  }
  .mg-lg-45 {
    margin: 45px;
  }
  .mg-lg-45-f {
    margin: 45px !important;
  }
  .mg-lg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .mg-lg-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mg-lg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-lg-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .mg-lg-t-45 {
    margin-top: 45px;
  }
  .mg-lg-r-45 {
    margin-right: 45px;
  }
  .mg-lg-b-45 {
    margin-bottom: 45px;
  }
  .mg-lg-l-45 {
    margin-left: 45px;
  }
  .mg-lg-t-45-f {
    margin-top: 45px !important;
  }
  .mg-lg-r-45-f {
    margin-right: 45px !important;
  }
  .mg-lg-b-45-f {
    margin-bottom: 45px !important;
  }
  .mg-lg-l-45-f {
    margin-left: 45px !important;
  }
  .mg-lg-50 {
    margin: 50px;
  }
  .mg-lg-50-f {
    margin: 50px !important;
  }
  .mg-lg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mg-lg-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mg-lg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mg-lg-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .mg-lg-t-50 {
    margin-top: 50px;
  }
  .mg-lg-r-50 {
    margin-right: 50px;
  }
  .mg-lg-b-50 {
    margin-bottom: 50px;
  }
  .mg-lg-l-50 {
    margin-left: 50px;
  }
  .mg-lg-t-50-f {
    margin-top: 50px !important;
  }
  .mg-lg-r-50-f {
    margin-right: 50px !important;
  }
  .mg-lg-b-50-f {
    margin-bottom: 50px !important;
  }
  .mg-lg-l-50-f {
    margin-left: 50px !important;
  }
  .mg-lg-55 {
    margin: 55px;
  }
  .mg-lg-55-f {
    margin: 55px !important;
  }
  .mg-lg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .mg-lg-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .mg-lg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .mg-lg-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .mg-lg-t-55 {
    margin-top: 55px;
  }
  .mg-lg-r-55 {
    margin-right: 55px;
  }
  .mg-lg-b-55 {
    margin-bottom: 55px;
  }
  .mg-lg-l-55 {
    margin-left: 55px;
  }
  .mg-lg-t-55-f {
    margin-top: 55px !important;
  }
  .mg-lg-r-55-f {
    margin-right: 55px !important;
  }
  .mg-lg-b-55-f {
    margin-bottom: 55px !important;
  }
  .mg-lg-l-55-f {
    margin-left: 55px !important;
  }
  .mg-lg-60 {
    margin: 60px;
  }
  .mg-lg-60-f {
    margin: 60px !important;
  }
  .mg-lg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mg-lg-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mg-lg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-lg-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .mg-lg-t-60 {
    margin-top: 60px;
  }
  .mg-lg-r-60 {
    margin-right: 60px;
  }
  .mg-lg-b-60 {
    margin-bottom: 60px;
  }
  .mg-lg-l-60 {
    margin-left: 60px;
  }
  .mg-lg-t-60-f {
    margin-top: 60px !important;
  }
  .mg-lg-r-60-f {
    margin-right: 60px !important;
  }
  .mg-lg-b-60-f {
    margin-bottom: 60px !important;
  }
  .mg-lg-l-60-f {
    margin-left: 60px !important;
  }
  .mg-lg-65 {
    margin: 65px;
  }
  .mg-lg-65-f {
    margin: 65px !important;
  }
  .mg-lg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .mg-lg-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .mg-lg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .mg-lg-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .mg-lg-t-65 {
    margin-top: 65px;
  }
  .mg-lg-r-65 {
    margin-right: 65px;
  }
  .mg-lg-b-65 {
    margin-bottom: 65px;
  }
  .mg-lg-l-65 {
    margin-left: 65px;
  }
  .mg-lg-t-65-f {
    margin-top: 65px !important;
  }
  .mg-lg-r-65-f {
    margin-right: 65px !important;
  }
  .mg-lg-b-65-f {
    margin-bottom: 65px !important;
  }
  .mg-lg-l-65-f {
    margin-left: 65px !important;
  }
  .mg-lg-70 {
    margin: 70px;
  }
  .mg-lg-70-f {
    margin: 70px !important;
  }
  .mg-lg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mg-lg-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mg-lg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mg-lg-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .mg-lg-t-70 {
    margin-top: 70px;
  }
  .mg-lg-r-70 {
    margin-right: 70px;
  }
  .mg-lg-b-70 {
    margin-bottom: 70px;
  }
  .mg-lg-l-70 {
    margin-left: 70px;
  }
  .mg-lg-t-70-f {
    margin-top: 70px !important;
  }
  .mg-lg-r-70-f {
    margin-right: 70px !important;
  }
  .mg-lg-b-70-f {
    margin-bottom: 70px !important;
  }
  .mg-lg-l-70-f {
    margin-left: 70px !important;
  }
  .mg-lg-75 {
    margin: 75px;
  }
  .mg-lg-75-f {
    margin: 75px !important;
  }
  .mg-lg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .mg-lg-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .mg-lg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .mg-lg-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .mg-lg-t-75 {
    margin-top: 75px;
  }
  .mg-lg-r-75 {
    margin-right: 75px;
  }
  .mg-lg-b-75 {
    margin-bottom: 75px;
  }
  .mg-lg-l-75 {
    margin-left: 75px;
  }
  .mg-lg-t-75-f {
    margin-top: 75px !important;
  }
  .mg-lg-r-75-f {
    margin-right: 75px !important;
  }
  .mg-lg-b-75-f {
    margin-bottom: 75px !important;
  }
  .mg-lg-l-75-f {
    margin-left: 75px !important;
  }
  .mg-lg-80 {
    margin: 80px;
  }
  .mg-lg-80-f {
    margin: 80px !important;
  }
  .mg-lg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mg-lg-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mg-lg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mg-lg-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .mg-lg-t-80 {
    margin-top: 80px;
  }
  .mg-lg-r-80 {
    margin-right: 80px;
  }
  .mg-lg-b-80 {
    margin-bottom: 80px;
  }
  .mg-lg-l-80 {
    margin-left: 80px;
  }
  .mg-lg-t-80-f {
    margin-top: 80px !important;
  }
  .mg-lg-r-80-f {
    margin-right: 80px !important;
  }
  .mg-lg-b-80-f {
    margin-bottom: 80px !important;
  }
  .mg-lg-l-80-f {
    margin-left: 80px !important;
  }
  .mg-lg-85 {
    margin: 85px;
  }
  .mg-lg-85-f {
    margin: 85px !important;
  }
  .mg-lg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .mg-lg-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .mg-lg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .mg-lg-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .mg-lg-t-85 {
    margin-top: 85px;
  }
  .mg-lg-r-85 {
    margin-right: 85px;
  }
  .mg-lg-b-85 {
    margin-bottom: 85px;
  }
  .mg-lg-l-85 {
    margin-left: 85px;
  }
  .mg-lg-t-85-f {
    margin-top: 85px !important;
  }
  .mg-lg-r-85-f {
    margin-right: 85px !important;
  }
  .mg-lg-b-85-f {
    margin-bottom: 85px !important;
  }
  .mg-lg-l-85-f {
    margin-left: 85px !important;
  }
  .mg-lg-90 {
    margin: 90px;
  }
  .mg-lg-90-f {
    margin: 90px !important;
  }
  .mg-lg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mg-lg-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mg-lg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mg-lg-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .mg-lg-t-90 {
    margin-top: 90px;
  }
  .mg-lg-r-90 {
    margin-right: 90px;
  }
  .mg-lg-b-90 {
    margin-bottom: 90px;
  }
  .mg-lg-l-90 {
    margin-left: 90px;
  }
  .mg-lg-t-90-f {
    margin-top: 90px !important;
  }
  .mg-lg-r-90-f {
    margin-right: 90px !important;
  }
  .mg-lg-b-90-f {
    margin-bottom: 90px !important;
  }
  .mg-lg-l-90-f {
    margin-left: 90px !important;
  }
  .mg-lg-95 {
    margin: 95px;
  }
  .mg-lg-95-f {
    margin: 95px !important;
  }
  .mg-lg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .mg-lg-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .mg-lg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .mg-lg-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .mg-lg-t-95 {
    margin-top: 95px;
  }
  .mg-lg-r-95 {
    margin-right: 95px;
  }
  .mg-lg-b-95 {
    margin-bottom: 95px;
  }
  .mg-lg-l-95 {
    margin-left: 95px;
  }
  .mg-lg-t-95-f {
    margin-top: 95px !important;
  }
  .mg-lg-r-95-f {
    margin-right: 95px !important;
  }
  .mg-lg-b-95-f {
    margin-bottom: 95px !important;
  }
  .mg-lg-l-95-f {
    margin-left: 95px !important;
  }
  .mg-lg-100 {
    margin: 100px;
  }
  .mg-lg-100-f {
    margin: 100px !important;
  }
  .mg-lg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mg-lg-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .mg-lg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mg-lg-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .mg-lg-t-100 {
    margin-top: 100px;
  }
  .mg-lg-r-100 {
    margin-right: 100px;
  }
  .mg-lg-b-100 {
    margin-bottom: 100px;
  }
  .mg-lg-l-100 {
    margin-left: 100px;
  }
  .mg-lg-t-100-f {
    margin-top: 100px !important;
  }
  .mg-lg-r-100-f {
    margin-right: 100px !important;
  }
  .mg-lg-b-100-f {
    margin-bottom: 100px !important;
  }
  .mg-lg-l-100-f {
    margin-left: 100px !important;
  }
  .mg-lg-t-auto {
    margin-top: auto;
  }
  .mg-lg-r-auto {
    margin-right: auto;
  }
  .mg-lg-b-auto {
    margin-bottom: auto;
  }
  .mg-lg-l-auto {
    margin-left: auto;
  }
  .mg-lg-auto {
    margin: auto;
  }
  .mg-lg-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1200px) {
  .mg-xl-0 {
    margin: 0px;
  }
  .mg-xl-0-f {
    margin: 0px !important;
  }
  .mg-xl-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mg-xl-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .mg-xl-x-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mg-xl-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .mg-xl-t-0 {
    margin-top: 0px;
  }
  .mg-xl-r-0 {
    margin-right: 0px;
  }
  .mg-xl-b-0 {
    margin-bottom: 0px;
  }
  .mg-xl-l-0 {
    margin-left: 0px;
  }
  .mg-xl-t-0-f {
    margin-top: 0px !important;
  }
  .mg-xl-r-0-f {
    margin-right: 0px !important;
  }
  .mg-xl-b-0-f {
    margin-bottom: 0px !important;
  }
  .mg-xl-l-0-f {
    margin-left: 0px !important;
  }
  .mg-xl-1 {
    margin: 1px;
  }
  .mg-xl-1-f {
    margin: 1px !important;
  }
  .mg-xl-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .mg-xl-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }
  .mg-xl-x-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .mg-xl-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  .mg-xl-t-1 {
    margin-top: 1px;
  }
  .mg-xl-r-1 {
    margin-right: 1px;
  }
  .mg-xl-b-1 {
    margin-bottom: 1px;
  }
  .mg-xl-l-1 {
    margin-left: 1px;
  }
  .mg-xl-t-1-f {
    margin-top: 1px !important;
  }
  .mg-xl-r-1-f {
    margin-right: 1px !important;
  }
  .mg-xl-b-1-f {
    margin-bottom: 1px !important;
  }
  .mg-xl-l-1-f {
    margin-left: 1px !important;
  }
  .mg-xl-2 {
    margin: 2px;
  }
  .mg-xl-2-f {
    margin: 2px !important;
  }
  .mg-xl-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .mg-xl-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .mg-xl-x-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mg-xl-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
  .mg-xl-t-2 {
    margin-top: 2px;
  }
  .mg-xl-r-2 {
    margin-right: 2px;
  }
  .mg-xl-b-2 {
    margin-bottom: 2px;
  }
  .mg-xl-l-2 {
    margin-left: 2px;
  }
  .mg-xl-t-2-f {
    margin-top: 2px !important;
  }
  .mg-xl-r-2-f {
    margin-right: 2px !important;
  }
  .mg-xl-b-2-f {
    margin-bottom: 2px !important;
  }
  .mg-xl-l-2-f {
    margin-left: 2px !important;
  }
  .mg-xl-3 {
    margin: 3px;
  }
  .mg-xl-3-f {
    margin: 3px !important;
  }
  .mg-xl-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .mg-xl-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .mg-xl-x-3 {
    margin-left: 3px;
    margin-right: 3px;
  }
  .mg-xl-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .mg-xl-t-3 {
    margin-top: 3px;
  }
  .mg-xl-r-3 {
    margin-right: 3px;
  }
  .mg-xl-b-3 {
    margin-bottom: 3px;
  }
  .mg-xl-l-3 {
    margin-left: 3px;
  }
  .mg-xl-t-3-f {
    margin-top: 3px !important;
  }
  .mg-xl-r-3-f {
    margin-right: 3px !important;
  }
  .mg-xl-b-3-f {
    margin-bottom: 3px !important;
  }
  .mg-xl-l-3-f {
    margin-left: 3px !important;
  }
  .mg-xl-4 {
    margin: 4px;
  }
  .mg-xl-4-f {
    margin: 4px !important;
  }
  .mg-xl-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mg-xl-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }
  .mg-xl-x-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mg-xl-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .mg-xl-t-4 {
    margin-top: 4px;
  }
  .mg-xl-r-4 {
    margin-right: 4px;
  }
  .mg-xl-b-4 {
    margin-bottom: 4px;
  }
  .mg-xl-l-4 {
    margin-left: 4px;
  }
  .mg-xl-t-4-f {
    margin-top: 4px !important;
  }
  .mg-xl-r-4-f {
    margin-right: 4px !important;
  }
  .mg-xl-b-4-f {
    margin-bottom: 4px !important;
  }
  .mg-xl-l-4-f {
    margin-left: 4px !important;
  }
  .mg-xl-5 {
    margin: 5px;
  }
  .mg-xl-5-f {
    margin: 5px !important;
  }
  .mg-xl-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .mg-xl-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .mg-xl-x-5 {
    margin-left: 5px;
    margin-right: 5px;
  }
  .mg-xl-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .mg-xl-t-5 {
    margin-top: 5px;
  }
  .mg-xl-r-5 {
    margin-right: 5px;
  }
  .mg-xl-b-5 {
    margin-bottom: 5px;
  }
  .mg-xl-l-5 {
    margin-left: 5px;
  }
  .mg-xl-t-5-f {
    margin-top: 5px !important;
  }
  .mg-xl-r-5-f {
    margin-right: 5px !important;
  }
  .mg-xl-b-5-f {
    margin-bottom: 5px !important;
  }
  .mg-xl-l-5-f {
    margin-left: 5px !important;
  }
  .mg-xl-6 {
    margin: 6px;
  }
  .mg-xl-6-f {
    margin: 6px !important;
  }
  .mg-xl-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .mg-xl-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }
  .mg-xl-x-6 {
    margin-left: 6px;
    margin-right: 6px;
  }
  .mg-xl-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important;
  }
  .mg-xl-t-6 {
    margin-top: 6px;
  }
  .mg-xl-r-6 {
    margin-right: 6px;
  }
  .mg-xl-b-6 {
    margin-bottom: 6px;
  }
  .mg-xl-l-6 {
    margin-left: 6px;
  }
  .mg-xl-t-6-f {
    margin-top: 6px !important;
  }
  .mg-xl-r-6-f {
    margin-right: 6px !important;
  }
  .mg-xl-b-6-f {
    margin-bottom: 6px !important;
  }
  .mg-xl-l-6-f {
    margin-left: 6px !important;
  }
  .mg-xl-7 {
    margin: 7px;
  }
  .mg-xl-7-f {
    margin: 7px !important;
  }
  .mg-xl-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .mg-xl-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
  }
  .mg-xl-x-7 {
    margin-left: 7px;
    margin-right: 7px;
  }
  .mg-xl-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .mg-xl-t-7 {
    margin-top: 7px;
  }
  .mg-xl-r-7 {
    margin-right: 7px;
  }
  .mg-xl-b-7 {
    margin-bottom: 7px;
  }
  .mg-xl-l-7 {
    margin-left: 7px;
  }
  .mg-xl-t-7-f {
    margin-top: 7px !important;
  }
  .mg-xl-r-7-f {
    margin-right: 7px !important;
  }
  .mg-xl-b-7-f {
    margin-bottom: 7px !important;
  }
  .mg-xl-l-7-f {
    margin-left: 7px !important;
  }
  .mg-xl-8 {
    margin: 8px;
  }
  .mg-xl-8-f {
    margin: 8px !important;
  }
  .mg-xl-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mg-xl-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .mg-xl-x-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mg-xl-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .mg-xl-t-8 {
    margin-top: 8px;
  }
  .mg-xl-r-8 {
    margin-right: 8px;
  }
  .mg-xl-b-8 {
    margin-bottom: 8px;
  }
  .mg-xl-l-8 {
    margin-left: 8px;
  }
  .mg-xl-t-8-f {
    margin-top: 8px !important;
  }
  .mg-xl-r-8-f {
    margin-right: 8px !important;
  }
  .mg-xl-b-8-f {
    margin-bottom: 8px !important;
  }
  .mg-xl-l-8-f {
    margin-left: 8px !important;
  }
  .mg-xl-9 {
    margin: 9px;
  }
  .mg-xl-9-f {
    margin: 9px !important;
  }
  .mg-xl-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .mg-xl-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }
  .mg-xl-x-9 {
    margin-left: 9px;
    margin-right: 9px;
  }
  .mg-xl-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important;
  }
  .mg-xl-t-9 {
    margin-top: 9px;
  }
  .mg-xl-r-9 {
    margin-right: 9px;
  }
  .mg-xl-b-9 {
    margin-bottom: 9px;
  }
  .mg-xl-l-9 {
    margin-left: 9px;
  }
  .mg-xl-t-9-f {
    margin-top: 9px !important;
  }
  .mg-xl-r-9-f {
    margin-right: 9px !important;
  }
  .mg-xl-b-9-f {
    margin-bottom: 9px !important;
  }
  .mg-xl-l-9-f {
    margin-left: 9px !important;
  }
  .mg-xl-10 {
    margin: 10px;
  }
  .mg-xl-10-f {
    margin: 10px !important;
  }
  .mg-xl-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mg-xl-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .mg-xl-x-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mg-xl-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .mg-xl-t-10 {
    margin-top: 10px;
  }
  .mg-xl-r-10 {
    margin-right: 10px;
  }
  .mg-xl-b-10 {
    margin-bottom: 10px;
  }
  .mg-xl-l-10 {
    margin-left: 10px;
  }
  .mg-xl-t-10-f {
    margin-top: 10px !important;
  }
  .mg-xl-r-10-f {
    margin-right: 10px !important;
  }
  .mg-xl-b-10-f {
    margin-bottom: 10px !important;
  }
  .mg-xl-l-10-f {
    margin-left: 10px !important;
  }
  .mg-xl-15 {
    margin: 15px;
  }
  .mg-xl-15-f {
    margin: 15px !important;
  }
  .mg-xl-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .mg-xl-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .mg-xl-x-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .mg-xl-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .mg-xl-t-15 {
    margin-top: 15px;
  }
  .mg-xl-r-15 {
    margin-right: 15px;
  }
  .mg-xl-b-15 {
    margin-bottom: 15px;
  }
  .mg-xl-l-15 {
    margin-left: 15px;
  }
  .mg-xl-t-15-f {
    margin-top: 15px !important;
  }
  .mg-xl-r-15-f {
    margin-right: 15px !important;
  }
  .mg-xl-b-15-f {
    margin-bottom: 15px !important;
  }
  .mg-xl-l-15-f {
    margin-left: 15px !important;
  }
  .mg-xl-20 {
    margin: 20px;
  }
  .mg-xl-20-f {
    margin: 20px !important;
  }
  .mg-xl-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mg-xl-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .mg-xl-x-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mg-xl-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .mg-xl-t-20 {
    margin-top: 20px;
  }
  .mg-xl-r-20 {
    margin-right: 20px;
  }
  .mg-xl-b-20 {
    margin-bottom: 20px;
  }
  .mg-xl-l-20 {
    margin-left: 20px;
  }
  .mg-xl-t-20-f {
    margin-top: 20px !important;
  }
  .mg-xl-r-20-f {
    margin-right: 20px !important;
  }
  .mg-xl-b-20-f {
    margin-bottom: 20px !important;
  }
  .mg-xl-l-20-f {
    margin-left: 20px !important;
  }
  .mg-xl-25 {
    margin: 25px;
  }
  .mg-xl-25-f {
    margin: 25px !important;
  }
  .mg-xl-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .mg-xl-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .mg-xl-x-25 {
    margin-left: 25px;
    margin-right: 25px;
  }
  .mg-xl-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .mg-xl-t-25 {
    margin-top: 25px;
  }
  .mg-xl-r-25 {
    margin-right: 25px;
  }
  .mg-xl-b-25 {
    margin-bottom: 25px;
  }
  .mg-xl-l-25 {
    margin-left: 25px;
  }
  .mg-xl-t-25-f {
    margin-top: 25px !important;
  }
  .mg-xl-r-25-f {
    margin-right: 25px !important;
  }
  .mg-xl-b-25-f {
    margin-bottom: 25px !important;
  }
  .mg-xl-l-25-f {
    margin-left: 25px !important;
  }
  .mg-xl-30 {
    margin: 30px;
  }
  .mg-xl-30-f {
    margin: 30px !important;
  }
  .mg-xl-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .mg-xl-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .mg-xl-x-30 {
    margin-left: 30px;
    margin-right: 30px;
  }
  .mg-xl-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .mg-xl-t-30 {
    margin-top: 30px;
  }
  .mg-xl-r-30 {
    margin-right: 30px;
  }
  .mg-xl-b-30 {
    margin-bottom: 30px;
  }
  .mg-xl-l-30 {
    margin-left: 30px;
  }
  .mg-xl-t-30-f {
    margin-top: 30px !important;
  }
  .mg-xl-r-30-f {
    margin-right: 30px !important;
  }
  .mg-xl-b-30-f {
    margin-bottom: 30px !important;
  }
  .mg-xl-l-30-f {
    margin-left: 30px !important;
  }
  .mg-xl-35 {
    margin: 35px;
  }
  .mg-xl-35-f {
    margin: 35px !important;
  }
  .mg-xl-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .mg-xl-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .mg-xl-x-35 {
    margin-left: 35px;
    margin-right: 35px;
  }
  .mg-xl-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .mg-xl-t-35 {
    margin-top: 35px;
  }
  .mg-xl-r-35 {
    margin-right: 35px;
  }
  .mg-xl-b-35 {
    margin-bottom: 35px;
  }
  .mg-xl-l-35 {
    margin-left: 35px;
  }
  .mg-xl-t-35-f {
    margin-top: 35px !important;
  }
  .mg-xl-r-35-f {
    margin-right: 35px !important;
  }
  .mg-xl-b-35-f {
    margin-bottom: 35px !important;
  }
  .mg-xl-l-35-f {
    margin-left: 35px !important;
  }
  .mg-xl-40 {
    margin: 40px;
  }
  .mg-xl-40-f {
    margin: 40px !important;
  }
  .mg-xl-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mg-xl-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .mg-xl-x-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mg-xl-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .mg-xl-t-40 {
    margin-top: 40px;
  }
  .mg-xl-r-40 {
    margin-right: 40px;
  }
  .mg-xl-b-40 {
    margin-bottom: 40px;
  }
  .mg-xl-l-40 {
    margin-left: 40px;
  }
  .mg-xl-t-40-f {
    margin-top: 40px !important;
  }
  .mg-xl-r-40-f {
    margin-right: 40px !important;
  }
  .mg-xl-b-40-f {
    margin-bottom: 40px !important;
  }
  .mg-xl-l-40-f {
    margin-left: 40px !important;
  }
  .mg-xl-45 {
    margin: 45px;
  }
  .mg-xl-45-f {
    margin: 45px !important;
  }
  .mg-xl-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .mg-xl-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .mg-xl-x-45 {
    margin-left: 45px;
    margin-right: 45px;
  }
  .mg-xl-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .mg-xl-t-45 {
    margin-top: 45px;
  }
  .mg-xl-r-45 {
    margin-right: 45px;
  }
  .mg-xl-b-45 {
    margin-bottom: 45px;
  }
  .mg-xl-l-45 {
    margin-left: 45px;
  }
  .mg-xl-t-45-f {
    margin-top: 45px !important;
  }
  .mg-xl-r-45-f {
    margin-right: 45px !important;
  }
  .mg-xl-b-45-f {
    margin-bottom: 45px !important;
  }
  .mg-xl-l-45-f {
    margin-left: 45px !important;
  }
  .mg-xl-50 {
    margin: 50px;
  }
  .mg-xl-50-f {
    margin: 50px !important;
  }
  .mg-xl-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .mg-xl-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .mg-xl-x-50 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .mg-xl-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .mg-xl-t-50 {
    margin-top: 50px;
  }
  .mg-xl-r-50 {
    margin-right: 50px;
  }
  .mg-xl-b-50 {
    margin-bottom: 50px;
  }
  .mg-xl-l-50 {
    margin-left: 50px;
  }
  .mg-xl-t-50-f {
    margin-top: 50px !important;
  }
  .mg-xl-r-50-f {
    margin-right: 50px !important;
  }
  .mg-xl-b-50-f {
    margin-bottom: 50px !important;
  }
  .mg-xl-l-50-f {
    margin-left: 50px !important;
  }
  .mg-xl-55 {
    margin: 55px;
  }
  .mg-xl-55-f {
    margin: 55px !important;
  }
  .mg-xl-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .mg-xl-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
  }
  .mg-xl-x-55 {
    margin-left: 55px;
    margin-right: 55px;
  }
  .mg-xl-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important;
  }
  .mg-xl-t-55 {
    margin-top: 55px;
  }
  .mg-xl-r-55 {
    margin-right: 55px;
  }
  .mg-xl-b-55 {
    margin-bottom: 55px;
  }
  .mg-xl-l-55 {
    margin-left: 55px;
  }
  .mg-xl-t-55-f {
    margin-top: 55px !important;
  }
  .mg-xl-r-55-f {
    margin-right: 55px !important;
  }
  .mg-xl-b-55-f {
    margin-bottom: 55px !important;
  }
  .mg-xl-l-55-f {
    margin-left: 55px !important;
  }
  .mg-xl-60 {
    margin: 60px;
  }
  .mg-xl-60-f {
    margin: 60px !important;
  }
  .mg-xl-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mg-xl-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .mg-xl-x-60 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mg-xl-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .mg-xl-t-60 {
    margin-top: 60px;
  }
  .mg-xl-r-60 {
    margin-right: 60px;
  }
  .mg-xl-b-60 {
    margin-bottom: 60px;
  }
  .mg-xl-l-60 {
    margin-left: 60px;
  }
  .mg-xl-t-60-f {
    margin-top: 60px !important;
  }
  .mg-xl-r-60-f {
    margin-right: 60px !important;
  }
  .mg-xl-b-60-f {
    margin-bottom: 60px !important;
  }
  .mg-xl-l-60-f {
    margin-left: 60px !important;
  }
  .mg-xl-65 {
    margin: 65px;
  }
  .mg-xl-65-f {
    margin: 65px !important;
  }
  .mg-xl-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .mg-xl-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .mg-xl-x-65 {
    margin-left: 65px;
    margin-right: 65px;
  }
  .mg-xl-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important;
  }
  .mg-xl-t-65 {
    margin-top: 65px;
  }
  .mg-xl-r-65 {
    margin-right: 65px;
  }
  .mg-xl-b-65 {
    margin-bottom: 65px;
  }
  .mg-xl-l-65 {
    margin-left: 65px;
  }
  .mg-xl-t-65-f {
    margin-top: 65px !important;
  }
  .mg-xl-r-65-f {
    margin-right: 65px !important;
  }
  .mg-xl-b-65-f {
    margin-bottom: 65px !important;
  }
  .mg-xl-l-65-f {
    margin-left: 65px !important;
  }
  .mg-xl-70 {
    margin: 70px;
  }
  .mg-xl-70-f {
    margin: 70px !important;
  }
  .mg-xl-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .mg-xl-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .mg-xl-x-70 {
    margin-left: 70px;
    margin-right: 70px;
  }
  .mg-xl-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .mg-xl-t-70 {
    margin-top: 70px;
  }
  .mg-xl-r-70 {
    margin-right: 70px;
  }
  .mg-xl-b-70 {
    margin-bottom: 70px;
  }
  .mg-xl-l-70 {
    margin-left: 70px;
  }
  .mg-xl-t-70-f {
    margin-top: 70px !important;
  }
  .mg-xl-r-70-f {
    margin-right: 70px !important;
  }
  .mg-xl-b-70-f {
    margin-bottom: 70px !important;
  }
  .mg-xl-l-70-f {
    margin-left: 70px !important;
  }
  .mg-xl-75 {
    margin: 75px;
  }
  .mg-xl-75-f {
    margin: 75px !important;
  }
  .mg-xl-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .mg-xl-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
  }
  .mg-xl-x-75 {
    margin-left: 75px;
    margin-right: 75px;
  }
  .mg-xl-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .mg-xl-t-75 {
    margin-top: 75px;
  }
  .mg-xl-r-75 {
    margin-right: 75px;
  }
  .mg-xl-b-75 {
    margin-bottom: 75px;
  }
  .mg-xl-l-75 {
    margin-left: 75px;
  }
  .mg-xl-t-75-f {
    margin-top: 75px !important;
  }
  .mg-xl-r-75-f {
    margin-right: 75px !important;
  }
  .mg-xl-b-75-f {
    margin-bottom: 75px !important;
  }
  .mg-xl-l-75-f {
    margin-left: 75px !important;
  }
  .mg-xl-80 {
    margin: 80px;
  }
  .mg-xl-80-f {
    margin: 80px !important;
  }
  .mg-xl-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mg-xl-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .mg-xl-x-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mg-xl-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .mg-xl-t-80 {
    margin-top: 80px;
  }
  .mg-xl-r-80 {
    margin-right: 80px;
  }
  .mg-xl-b-80 {
    margin-bottom: 80px;
  }
  .mg-xl-l-80 {
    margin-left: 80px;
  }
  .mg-xl-t-80-f {
    margin-top: 80px !important;
  }
  .mg-xl-r-80-f {
    margin-right: 80px !important;
  }
  .mg-xl-b-80-f {
    margin-bottom: 80px !important;
  }
  .mg-xl-l-80-f {
    margin-left: 80px !important;
  }
  .mg-xl-85 {
    margin: 85px;
  }
  .mg-xl-85-f {
    margin: 85px !important;
  }
  .mg-xl-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .mg-xl-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
  }
  .mg-xl-x-85 {
    margin-left: 85px;
    margin-right: 85px;
  }
  .mg-xl-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important;
  }
  .mg-xl-t-85 {
    margin-top: 85px;
  }
  .mg-xl-r-85 {
    margin-right: 85px;
  }
  .mg-xl-b-85 {
    margin-bottom: 85px;
  }
  .mg-xl-l-85 {
    margin-left: 85px;
  }
  .mg-xl-t-85-f {
    margin-top: 85px !important;
  }
  .mg-xl-r-85-f {
    margin-right: 85px !important;
  }
  .mg-xl-b-85-f {
    margin-bottom: 85px !important;
  }
  .mg-xl-l-85-f {
    margin-left: 85px !important;
  }
  .mg-xl-90 {
    margin: 90px;
  }
  .mg-xl-90-f {
    margin: 90px !important;
  }
  .mg-xl-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .mg-xl-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .mg-xl-x-90 {
    margin-left: 90px;
    margin-right: 90px;
  }
  .mg-xl-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .mg-xl-t-90 {
    margin-top: 90px;
  }
  .mg-xl-r-90 {
    margin-right: 90px;
  }
  .mg-xl-b-90 {
    margin-bottom: 90px;
  }
  .mg-xl-l-90 {
    margin-left: 90px;
  }
  .mg-xl-t-90-f {
    margin-top: 90px !important;
  }
  .mg-xl-r-90-f {
    margin-right: 90px !important;
  }
  .mg-xl-b-90-f {
    margin-bottom: 90px !important;
  }
  .mg-xl-l-90-f {
    margin-left: 90px !important;
  }
  .mg-xl-95 {
    margin: 95px;
  }
  .mg-xl-95-f {
    margin: 95px !important;
  }
  .mg-xl-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .mg-xl-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
  }
  .mg-xl-x-95 {
    margin-left: 95px;
    margin-right: 95px;
  }
  .mg-xl-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important;
  }
  .mg-xl-t-95 {
    margin-top: 95px;
  }
  .mg-xl-r-95 {
    margin-right: 95px;
  }
  .mg-xl-b-95 {
    margin-bottom: 95px;
  }
  .mg-xl-l-95 {
    margin-left: 95px;
  }
  .mg-xl-t-95-f {
    margin-top: 95px !important;
  }
  .mg-xl-r-95-f {
    margin-right: 95px !important;
  }
  .mg-xl-b-95-f {
    margin-bottom: 95px !important;
  }
  .mg-xl-l-95-f {
    margin-left: 95px !important;
  }
  .mg-xl-100 {
    margin: 100px;
  }
  .mg-xl-100-f {
    margin: 100px !important;
  }
  .mg-xl-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mg-xl-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .mg-xl-x-100 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mg-xl-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .mg-xl-t-100 {
    margin-top: 100px;
  }
  .mg-xl-r-100 {
    margin-right: 100px;
  }
  .mg-xl-b-100 {
    margin-bottom: 100px;
  }
  .mg-xl-l-100 {
    margin-left: 100px;
  }
  .mg-xl-t-100-f {
    margin-top: 100px !important;
  }
  .mg-xl-r-100-f {
    margin-right: 100px !important;
  }
  .mg-xl-b-100-f {
    margin-bottom: 100px !important;
  }
  .mg-xl-l-100-f {
    margin-left: 100px !important;
  }
  .mg-xl-t-auto {
    margin-top: auto;
  }
  .mg-xl-r-auto {
    margin-right: auto;
  }
  .mg-xl-b-auto {
    margin-bottom: auto;
  }
  .mg-xl-l-auto {
    margin-left: auto;
  }
  .mg-xl-auto {
    margin: auto;
  }
  .mg-xl-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

/* ###### 9.5 Misc   ###### */

.op-0 {
  opacity: 0;
}

.op-0-f {
  opacity: 0 !important;
}

.op-1 {
  opacity: 0.1;
}

.op-1-f {
  opacity: 0.1 !important;
}

.op-2 {
  opacity: 0.2;
}

.op-2-f {
  opacity: 0.2 !important;
}

.op-3 {
  opacity: 0.3;
}

.op-3-f {
  opacity: 0.3 !important;
}

.op-4 {
  opacity: 0.4;
}

.op-4-f {
  opacity: 0.4 !important;
}

.op-5 {
  opacity: 0.5;
}

.op-5-f {
  opacity: 0.5 !important;
}

.op-6 {
  opacity: 0.6;
}

.op-6-f {
  opacity: 0.6 !important;
}

.op-7 {
  opacity: 0.7;
}

.op-7-f {
  opacity: 0.7 !important;
}

.op-8 {
  opacity: 0.8;
}

.op-8-f {
  opacity: 0.8 !important;
}

.op-9 {
  opacity: 0.9;
}

.op-9-f {
  opacity: 0.9 !important;
}

.opacity-1 {
  opacity: 1;
}

.shadow-base {
  box-shadow: 0 0 2px 2px rgba(28, 39, 60, 0.03);
}

.shadow-1 {
  box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
}

.shadow-2 {
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

.shadow-none {
  box-shadow: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: 0;
}

.img-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .op-xs-0 {
    opacity: 0;
  }
  .op-xs-0-f {
    opacity: 0 !important;
  }
  .op-xs-1 {
    opacity: 0.1;
  }
  .op-xs-1-f {
    opacity: 0.1 !important;
  }
  .op-xs-2 {
    opacity: 0.2;
  }
  .op-xs-2-f {
    opacity: 0.2 !important;
  }
  .op-xs-3 {
    opacity: 0.3;
  }
  .op-xs-3-f {
    opacity: 0.3 !important;
  }
  .op-xs-4 {
    opacity: 0.4;
  }
  .op-xs-4-f {
    opacity: 0.4 !important;
  }
  .op-xs-5 {
    opacity: 0.5;
  }
  .op-xs-5-f {
    opacity: 0.5 !important;
  }
  .op-xs-6 {
    opacity: 0.6;
  }
  .op-xs-6-f {
    opacity: 0.6 !important;
  }
  .op-xs-7 {
    opacity: 0.7;
  }
  .op-xs-7-f {
    opacity: 0.7 !important;
  }
  .op-xs-8 {
    opacity: 0.8;
  }
  .op-xs-8-f {
    opacity: 0.8 !important;
  }
  .op-xs-9 {
    opacity: 0.9;
  }
  .op-xs-9-f {
    opacity: 0.9 !important;
  }
}

@media (min-width: 576px) {
  .op-sm-0 {
    opacity: 0;
  }
  .op-sm-0-f {
    opacity: 0 !important;
  }
  .op-sm-1 {
    opacity: 0.1;
  }
  .op-sm-1-f {
    opacity: 0.1 !important;
  }
  .op-sm-2 {
    opacity: 0.2;
  }
  .op-sm-2-f {
    opacity: 0.2 !important;
  }
  .op-sm-3 {
    opacity: 0.3;
  }
  .op-sm-3-f {
    opacity: 0.3 !important;
  }
  .op-sm-4 {
    opacity: 0.4;
  }
  .op-sm-4-f {
    opacity: 0.4 !important;
  }
  .op-sm-5 {
    opacity: 0.5;
  }
  .op-sm-5-f {
    opacity: 0.5 !important;
  }
  .op-sm-6 {
    opacity: 0.6;
  }
  .op-sm-6-f {
    opacity: 0.6 !important;
  }
  .op-sm-7 {
    opacity: 0.7;
  }
  .op-sm-7-f {
    opacity: 0.7 !important;
  }
  .op-sm-8 {
    opacity: 0.8;
  }
  .op-sm-8-f {
    opacity: 0.8 !important;
  }
  .op-sm-9 {
    opacity: 0.9;
  }
  .op-sm-9-f {
    opacity: 0.9 !important;
  }
}

@media (min-width: 768px) {
  .op-md-0 {
    opacity: 0;
  }
  .op-md-0-f {
    opacity: 0 !important;
  }
  .op-md-1 {
    opacity: 0.1;
  }
  .op-md-1-f {
    opacity: 0.1 !important;
  }
  .op-md-2 {
    opacity: 0.2;
  }
  .op-md-2-f {
    opacity: 0.2 !important;
  }
  .op-md-3 {
    opacity: 0.3;
  }
  .op-md-3-f {
    opacity: 0.3 !important;
  }
  .op-md-4 {
    opacity: 0.4;
  }
  .op-md-4-f {
    opacity: 0.4 !important;
  }
  .op-md-5 {
    opacity: 0.5;
  }
  .op-md-5-f {
    opacity: 0.5 !important;
  }
  .op-md-6 {
    opacity: 0.6;
  }
  .op-md-6-f {
    opacity: 0.6 !important;
  }
  .op-md-7 {
    opacity: 0.7;
  }
  .op-md-7-f {
    opacity: 0.7 !important;
  }
  .op-md-8 {
    opacity: 0.8;
  }
  .op-md-8-f {
    opacity: 0.8 !important;
  }
  .op-md-9 {
    opacity: 0.9;
  }
  .op-md-9-f {
    opacity: 0.9 !important;
  }
}

@media (min-width: 992px) {
  .op-lg-0 {
    opacity: 0;
  }
  .op-lg-0-f {
    opacity: 0 !important;
  }
  .op-lg-1 {
    opacity: 0.1;
  }
  .op-lg-1-f {
    opacity: 0.1 !important;
  }
  .op-lg-2 {
    opacity: 0.2;
  }
  .op-lg-2-f {
    opacity: 0.2 !important;
  }
  .op-lg-3 {
    opacity: 0.3;
  }
  .op-lg-3-f {
    opacity: 0.3 !important;
  }
  .op-lg-4 {
    opacity: 0.4;
  }
  .op-lg-4-f {
    opacity: 0.4 !important;
  }
  .op-lg-5 {
    opacity: 0.5;
  }
  .op-lg-5-f {
    opacity: 0.5 !important;
  }
  .op-lg-6 {
    opacity: 0.6;
  }
  .op-lg-6-f {
    opacity: 0.6 !important;
  }
  .op-lg-7 {
    opacity: 0.7;
  }
  .op-lg-7-f {
    opacity: 0.7 !important;
  }
  .op-lg-8 {
    opacity: 0.8;
  }
  .op-lg-8-f {
    opacity: 0.8 !important;
  }
  .op-lg-9 {
    opacity: 0.9;
  }
  .op-lg-9-f {
    opacity: 0.9 !important;
  }
}

@media (min-width: 1200px) {
  .op-xl-0 {
    opacity: 0;
  }
  .op-xl-0-f {
    opacity: 0 !important;
  }
  .op-xl-1 {
    opacity: 0.1;
  }
  .op-xl-1-f {
    opacity: 0.1 !important;
  }
  .op-xl-2 {
    opacity: 0.2;
  }
  .op-xl-2-f {
    opacity: 0.2 !important;
  }
  .op-xl-3 {
    opacity: 0.3;
  }
  .op-xl-3-f {
    opacity: 0.3 !important;
  }
  .op-xl-4 {
    opacity: 0.4;
  }
  .op-xl-4-f {
    opacity: 0.4 !important;
  }
  .op-xl-5 {
    opacity: 0.5;
  }
  .op-xl-5-f {
    opacity: 0.5 !important;
  }
  .op-xl-6 {
    opacity: 0.6;
  }
  .op-xl-6-f {
    opacity: 0.6 !important;
  }
  .op-xl-7 {
    opacity: 0.7;
  }
  .op-xl-7-f {
    opacity: 0.7 !important;
  }
  .op-xl-8 {
    opacity: 0.8;
  }
  .op-xl-8-f {
    opacity: 0.8 !important;
  }
  .op-xl-9 {
    opacity: 0.9;
  }
  .op-xl-9-f {
    opacity: 0.9 !important;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss,
.image-grouped .text-avatar,
.image-grouped img {
  width: 35px;
  height: 35px;
}

.img-es {
  width: 25px;
  height: 25px;
}

.image-grouped {
  display: flex;
}

.image-grouped .text-avatar,
.image-grouped img {
  border-radius: 100%;
  margin-left: -10px;
  z-index: 0;
  border: 4px solid #fff;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: "box-shadow", "z-index", "transform", "border-width";
}

.image-grouped .text-avatar:first-child,
.image-grouped img:first-child {
  margin-left: 0;
}

.image-grouped .text-avatar:hover,
.image-grouped img:hover {
  z-index: 1;
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
  border-width: 0;
}

.image-grouped .text-avatar {
  color: #3366ff;
  font-size: 11px;
  font-weight: 600;
}

/* ###### 9.6 Padding   ###### */

.pd-0 {
  padding: 0px;
}

.pd-0-f {
  padding: 0px !important;
}

.pd-y-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pd-y-0-f {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pd-x-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pd-x-0-f {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pd-t-0 {
  padding-top: 0px;
}

.pd-r-0 {
  padding-right: 0px;
}

.pd-b-0 {
  padding-bottom: 0px;
}

.pd-l-0 {
  padding-left: 0px;
}

.pd-t-0-f {
  padding-top: 0px !important;
}

.pd-r-0-f {
  padding-right: 0px !important;
}

.pd-b-0-f {
  padding-bottom: 0px !important;
}

.pd-l-0-f {
  padding-left: 0px !important;
}

.pd-1 {
  padding: 1px;
}

.pd-1-f {
  padding: 1px !important;
}

.pd-y-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pd-y-1-f {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pd-x-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.pd-x-1-f {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.pd-t-1 {
  padding-top: 1px;
}

.pd-r-1 {
  padding-right: 1px;
}

.pd-b-1 {
  padding-bottom: 1px;
}

.pd-l-1 {
  padding-left: 1px;
}

.pd-t-1-f {
  padding-top: 1px !important;
}

.pd-r-1-f {
  padding-right: 1px !important;
}

.pd-b-1-f {
  padding-bottom: 1px !important;
}

.pd-l-1-f {
  padding-left: 1px !important;
}

.pd-2 {
  padding: 2px;
}

.pd-2-f {
  padding: 2px !important;
}

.pd-y-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.pd-y-2-f {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.pd-x-2 {
  padding-left: 2px;
  padding-right: 2px;
}

.pd-x-2-f {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.pd-t-2 {
  padding-top: 2px;
}

.pd-r-2 {
  padding-right: 2px;
}

.pd-b-2 {
  padding-bottom: 2px;
}

.pd-l-2 {
  padding-left: 2px;
}

.pd-t-2-f {
  padding-top: 2px !important;
}

.pd-r-2-f {
  padding-right: 2px !important;
}

.pd-b-2-f {
  padding-bottom: 2px !important;
}

.pd-l-2-f {
  padding-left: 2px !important;
}

.pd-3 {
  padding: 3px;
}

.pd-3-f {
  padding: 3px !important;
}

.pd-y-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pd-y-3-f {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.pd-x-3 {
  padding-left: 3px;
  padding-right: 3px;
}

.pd-x-3-f {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.pd-t-3 {
  padding-top: 3px;
}

.pd-r-3 {
  padding-right: 3px;
}

.pd-b-3 {
  padding-bottom: 3px;
}

.pd-l-3 {
  padding-left: 3px;
}

.pd-t-3-f {
  padding-top: 3px !important;
}

.pd-r-3-f {
  padding-right: 3px !important;
}

.pd-b-3-f {
  padding-bottom: 3px !important;
}

.pd-l-3-f {
  padding-left: 3px !important;
}

.pd-4 {
  padding: 4px;
}

.pd-4-f {
  padding: 4px !important;
}

.pd-y-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pd-y-4-f {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.pd-x-4 {
  padding-left: 4px;
  padding-right: 4px;
}

.pd-x-4-f {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.pd-t-4 {
  padding-top: 4px;
}

.pd-r-4 {
  padding-right: 4px;
}

.pd-b-4 {
  padding-bottom: 4px;
}

.pd-l-4 {
  padding-left: 4px;
}

.pd-t-4-f {
  padding-top: 4px !important;
}

.pd-r-4-f {
  padding-right: 4px !important;
}

.pd-b-4-f {
  padding-bottom: 4px !important;
}

.pd-l-4-f {
  padding-left: 4px !important;
}

.pd-5 {
  padding: 5px;
}

.pd-5-f {
  padding: 5px !important;
}

.pd-y-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pd-y-5-f {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pd-x-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.pd-x-5-f {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pd-t-5 {
  padding-top: 5px;
}

.pd-r-5 {
  padding-right: 5px;
}

.pd-b-5 {
  padding-bottom: 5px;
}

.pd-l-5 {
  padding-left: 5px;
}

.pd-t-5-f {
  padding-top: 5px !important;
}

.pd-r-5-f {
  padding-right: 5px !important;
}

.pd-b-5-f {
  padding-bottom: 5px !important;
}

.pd-l-5-f {
  padding-left: 5px !important;
}

.pd-6 {
  padding: 6px;
}

.pd-6-f {
  padding: 6px !important;
}

.pd-y-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pd-y-6-f {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pd-x-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.pd-x-6-f {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.pd-t-6 {
  padding-top: 6px;
}

.pd-r-6 {
  padding-right: 6px;
}

.pd-b-6 {
  padding-bottom: 6px;
}

.pd-l-6 {
  padding-left: 6px;
}

.pd-t-6-f {
  padding-top: 6px !important;
}

.pd-r-6-f {
  padding-right: 6px !important;
}

.pd-b-6-f {
  padding-bottom: 6px !important;
}

.pd-l-6-f {
  padding-left: 6px !important;
}

.pd-7 {
  padding: 7px;
}

.pd-7-f {
  padding: 7px !important;
}

.pd-y-7 {
  padding-top: 7px;
  padding-bottom: 7px;
}

.pd-y-7-f {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.pd-x-7 {
  padding-left: 7px;
  padding-right: 7px;
}

.pd-x-7-f {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.pd-t-7 {
  padding-top: 7px;
}

.pd-r-7 {
  padding-right: 7px;
}

.pd-b-7 {
  padding-bottom: 7px;
}

.pd-l-7 {
  padding-left: 7px;
}

.pd-t-7-f {
  padding-top: 7px !important;
}

.pd-r-7-f {
  padding-right: 7px !important;
}

.pd-b-7-f {
  padding-bottom: 7px !important;
}

.pd-l-7-f {
  padding-left: 7px !important;
}

.pd-8 {
  padding: 8px;
}

.pd-8-f {
  padding: 8px !important;
}

.pd-y-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pd-y-8-f {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pd-x-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pd-x-8-f {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.pd-t-8 {
  padding-top: 8px;
}

.pd-r-8 {
  padding-right: 8px;
}

.pd-b-8 {
  padding-bottom: 8px;
}

.pd-l-8 {
  padding-left: 8px;
}

.pd-t-8-f {
  padding-top: 8px !important;
}

.pd-r-8-f {
  padding-right: 8px !important;
}

.pd-b-8-f {
  padding-bottom: 8px !important;
}

.pd-l-8-f {
  padding-left: 8px !important;
}

.pd-9 {
  padding: 9px;
}

.pd-9-f {
  padding: 9px !important;
}

.pd-y-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.pd-y-9-f {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.pd-x-9 {
  padding-left: 9px;
  padding-right: 9px;
}

.pd-x-9-f {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.pd-t-9 {
  padding-top: 9px;
}

.pd-r-9 {
  padding-right: 9px;
}

.pd-b-9 {
  padding-bottom: 9px;
}

.pd-l-9 {
  padding-left: 9px;
}

.pd-t-9-f {
  padding-top: 9px !important;
}

.pd-r-9-f {
  padding-right: 9px !important;
}

.pd-b-9-f {
  padding-bottom: 9px !important;
}

.pd-l-9-f {
  padding-left: 9px !important;
}

.pd-10 {
  padding: 10px;
}

.pd-10-f {
  padding: 10px !important;
}

.pd-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pd-y-10-f {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pd-x-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pd-x-10-f {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-r-10 {
  padding-right: 10px;
}

.pd-b-10 {
  padding-bottom: 10px;
}

.pd-l-10 {
  padding-left: 10px;
}

.pd-t-10-f {
  padding-top: 10px !important;
}

.pd-r-10-f {
  padding-right: 10px !important;
}

.pd-b-10-f {
  padding-bottom: 10px !important;
}

.pd-l-10-f {
  padding-left: 10px !important;
}

.pd-15 {
  padding: 15px;
}

.pd-15-f {
  padding: 15px !important;
}

.pd-y-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pd-y-15-f {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pd-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.pd-x-15-f {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pd-t-15 {
  padding-top: 15px;
}

.pd-r-15 {
  padding-right: 15px;
}

.pd-b-15 {
  padding-bottom: 15px;
}

.pd-l-15 {
  padding-left: 15px;
}

.pd-t-15-f {
  padding-top: 15px !important;
}

.pd-r-15-f {
  padding-right: 15px !important;
}

.pd-b-15-f {
  padding-bottom: 15px !important;
}

.pd-l-15-f {
  padding-left: 15px !important;
}

.pd-20 {
  padding: 20px;
}

.pd-20-f {
  padding: 20px !important;
}

.pd-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pd-y-20-f {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pd-x-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pd-x-20-f {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pd-t-20 {
  padding-top: 20px;
}

.pd-r-20 {
  padding-right: 20px;
}

.pd-b-20 {
  padding-bottom: 20px;
}

.pd-l-20 {
  padding-left: 20px;
}

.pd-t-20-f {
  padding-top: 20px !important;
}

.pd-r-20-f {
  padding-right: 20px !important;
}

.pd-b-20-f {
  padding-bottom: 20px !important;
}

.pd-l-20-f {
  padding-left: 20px !important;
}

.pd-25 {
  padding: 25px;
}

.pd-25-f {
  padding: 25px !important;
}

.pd-y-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pd-y-25-f {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pd-x-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.pd-x-25-f {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.pd-t-25 {
  padding-top: 25px;
}

.pd-r-25 {
  padding-right: 25px;
}

.pd-b-25 {
  padding-bottom: 25px;
}

.pd-l-25 {
  padding-left: 25px;
}

.pd-t-25-f {
  padding-top: 25px !important;
}

.pd-r-25-f {
  padding-right: 25px !important;
}

.pd-b-25-f {
  padding-bottom: 25px !important;
}

.pd-l-25-f {
  padding-left: 25px !important;
}

.pd-30 {
  padding: 30px;
}

.pd-30-f {
  padding: 30px !important;
}

.pd-y-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.pd-y-30-f {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pd-x-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pd-x-30-f {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pd-t-30 {
  padding-top: 30px;
}

.pd-r-30 {
  padding-right: 30px;
}

.pd-b-30 {
  padding-bottom: 30px;
}

.pd-l-30 {
  padding-left: 30px;
}

.pd-t-30-f {
  padding-top: 30px !important;
}

.pd-r-30-f {
  padding-right: 30px !important;
}

.pd-b-30-f {
  padding-bottom: 30px !important;
}

.pd-l-30-f {
  padding-left: 30px !important;
}

.pd-35 {
  padding: 35px;
}

.pd-35-f {
  padding: 35px !important;
}

.pd-y-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.pd-y-35-f {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pd-x-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.pd-x-35-f {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pd-t-35 {
  padding-top: 35px;
}

.pd-r-35 {
  padding-right: 35px;
}

.pd-b-35 {
  padding-bottom: 35px;
}

.pd-l-35 {
  padding-left: 35px;
}

.pd-t-35-f {
  padding-top: 35px !important;
}

.pd-r-35-f {
  padding-right: 35px !important;
}

.pd-b-35-f {
  padding-bottom: 35px !important;
}

.pd-l-35-f {
  padding-left: 35px !important;
}

.pd-40 {
  padding: 40px;
}

.pd-40-f {
  padding: 40px !important;
}

.pd-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pd-y-40-f {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pd-x-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.pd-x-40-f {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pd-t-40 {
  padding-top: 40px;
}

.pd-r-40 {
  padding-right: 40px;
}

.pd-b-40 {
  padding-bottom: 40px;
}

.pd-l-40 {
  padding-left: 40px;
}

.pd-t-40-f {
  padding-top: 40px !important;
}

.pd-r-40-f {
  padding-right: 40px !important;
}

.pd-b-40-f {
  padding-bottom: 40px !important;
}

.pd-l-40-f {
  padding-left: 40px !important;
}

.pd-45 {
  padding: 45px;
}

.pd-45-f {
  padding: 45px !important;
}

.pd-y-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.pd-y-45-f {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pd-x-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.pd-x-45-f {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pd-t-45 {
  padding-top: 45px;
}

.pd-r-45 {
  padding-right: 45px;
}

.pd-b-45 {
  padding-bottom: 45px;
}

.pd-l-45 {
  padding-left: 45px;
}

.pd-t-45-f {
  padding-top: 45px !important;
}

.pd-r-45-f {
  padding-right: 45px !important;
}

.pd-b-45-f {
  padding-bottom: 45px !important;
}

.pd-l-45-f {
  padding-left: 45px !important;
}

.pd-50 {
  padding: 50px;
}

.pd-50-f {
  padding: 50px !important;
}

.pd-y-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pd-y-50-f {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pd-x-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pd-x-50-f {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pd-t-50 {
  padding-top: 50px;
}

.pd-r-50 {
  padding-right: 50px;
}

.pd-b-50 {
  padding-bottom: 50px;
}

.pd-l-50 {
  padding-left: 50px;
}

.pd-t-50-f {
  padding-top: 50px !important;
}

.pd-r-50-f {
  padding-right: 50px !important;
}

.pd-b-50-f {
  padding-bottom: 50px !important;
}

.pd-l-50-f {
  padding-left: 50px !important;
}

.pd-55 {
  padding: 55px;
}

.pd-55-f {
  padding: 55px !important;
}

.pd-y-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.pd-y-55-f {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pd-x-55 {
  padding-left: 55px;
  padding-right: 55px;
}

.pd-x-55-f {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.pd-t-55 {
  padding-top: 55px;
}

.pd-r-55 {
  padding-right: 55px;
}

.pd-b-55 {
  padding-bottom: 55px;
}

.pd-l-55 {
  padding-left: 55px;
}

.pd-t-55-f {
  padding-top: 55px !important;
}

.pd-r-55-f {
  padding-right: 55px !important;
}

.pd-b-55-f {
  padding-bottom: 55px !important;
}

.pd-l-55-f {
  padding-left: 55px !important;
}

.pd-60 {
  padding: 60px;
}

.pd-60-f {
  padding: 60px !important;
}

.pd-y-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pd-y-60-f {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pd-x-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.pd-x-60-f {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pd-t-60 {
  padding-top: 60px;
}

.pd-r-60 {
  padding-right: 60px;
}

.pd-b-60 {
  padding-bottom: 60px;
}

.pd-l-60 {
  padding-left: 60px;
}

.pd-t-60-f {
  padding-top: 60px !important;
}

.pd-r-60-f {
  padding-right: 60px !important;
}

.pd-b-60-f {
  padding-bottom: 60px !important;
}

.pd-l-60-f {
  padding-left: 60px !important;
}

.pd-65 {
  padding: 65px;
}

.pd-65-f {
  padding: 65px !important;
}

.pd-y-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.pd-y-65-f {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pd-x-65 {
  padding-left: 65px;
  padding-right: 65px;
}

.pd-x-65-f {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.pd-t-65 {
  padding-top: 65px;
}

.pd-r-65 {
  padding-right: 65px;
}

.pd-b-65 {
  padding-bottom: 65px;
}

.pd-l-65 {
  padding-left: 65px;
}

.pd-t-65-f {
  padding-top: 65px !important;
}

.pd-r-65-f {
  padding-right: 65px !important;
}

.pd-b-65-f {
  padding-bottom: 65px !important;
}

.pd-l-65-f {
  padding-left: 65px !important;
}

.pd-70 {
  padding: 70px;
}

.pd-70-f {
  padding: 70px !important;
}

.pd-y-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pd-y-70-f {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pd-x-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.pd-x-70-f {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pd-t-70 {
  padding-top: 70px;
}

.pd-r-70 {
  padding-right: 70px;
}

.pd-b-70 {
  padding-bottom: 70px;
}

.pd-l-70 {
  padding-left: 70px;
}

.pd-t-70-f {
  padding-top: 70px !important;
}

.pd-r-70-f {
  padding-right: 70px !important;
}

.pd-b-70-f {
  padding-bottom: 70px !important;
}

.pd-l-70-f {
  padding-left: 70px !important;
}

.pd-75 {
  padding: 75px;
}

.pd-75-f {
  padding: 75px !important;
}

.pd-y-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.pd-y-75-f {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pd-x-75 {
  padding-left: 75px;
  padding-right: 75px;
}

.pd-x-75-f {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.pd-t-75 {
  padding-top: 75px;
}

.pd-r-75 {
  padding-right: 75px;
}

.pd-b-75 {
  padding-bottom: 75px;
}

.pd-l-75 {
  padding-left: 75px;
}

.pd-t-75-f {
  padding-top: 75px !important;
}

.pd-r-75-f {
  padding-right: 75px !important;
}

.pd-b-75-f {
  padding-bottom: 75px !important;
}

.pd-l-75-f {
  padding-left: 75px !important;
}

.pd-80 {
  padding: 80px;
}

.pd-80-f {
  padding: 80px !important;
}

.pd-y-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pd-y-80-f {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pd-x-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.pd-x-80-f {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pd-t-80 {
  padding-top: 80px;
}

.pd-r-80 {
  padding-right: 80px;
}

.pd-b-80 {
  padding-bottom: 80px;
}

.pd-l-80 {
  padding-left: 80px;
}

.pd-t-80-f {
  padding-top: 80px !important;
}

.pd-r-80-f {
  padding-right: 80px !important;
}

.pd-b-80-f {
  padding-bottom: 80px !important;
}

.pd-l-80-f {
  padding-left: 80px !important;
}

.pd-85 {
  padding: 85px;
}

.pd-85-f {
  padding: 85px !important;
}

.pd-y-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.pd-y-85-f {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pd-x-85 {
  padding-left: 85px;
  padding-right: 85px;
}

.pd-x-85-f {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.pd-t-85 {
  padding-top: 85px;
}

.pd-r-85 {
  padding-right: 85px;
}

.pd-b-85 {
  padding-bottom: 85px;
}

.pd-l-85 {
  padding-left: 85px;
}

.pd-t-85-f {
  padding-top: 85px !important;
}

.pd-r-85-f {
  padding-right: 85px !important;
}

.pd-b-85-f {
  padding-bottom: 85px !important;
}

.pd-l-85-f {
  padding-left: 85px !important;
}

.pd-90 {
  padding: 90px;
}

.pd-90-f {
  padding: 90px !important;
}

.pd-y-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.pd-y-90-f {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pd-x-90 {
  padding-left: 90px;
  padding-right: 90px;
}

.pd-x-90-f {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pd-t-90 {
  padding-top: 90px;
}

.pd-r-90 {
  padding-right: 90px;
}

.pd-b-90 {
  padding-bottom: 90px;
}

.pd-l-90 {
  padding-left: 90px;
}

.pd-t-90-f {
  padding-top: 90px !important;
}

.pd-r-90-f {
  padding-right: 90px !important;
}

.pd-b-90-f {
  padding-bottom: 90px !important;
}

.pd-l-90-f {
  padding-left: 90px !important;
}

.pd-95 {
  padding: 95px;
}

.pd-95-f {
  padding: 95px !important;
}

.pd-y-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}

.pd-y-95-f {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pd-x-95 {
  padding-left: 95px;
  padding-right: 95px;
}

.pd-x-95-f {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.pd-t-95 {
  padding-top: 95px;
}

.pd-r-95 {
  padding-right: 95px;
}

.pd-b-95 {
  padding-bottom: 95px;
}

.pd-l-95 {
  padding-left: 95px;
}

.pd-t-95-f {
  padding-top: 95px !important;
}

.pd-r-95-f {
  padding-right: 95px !important;
}

.pd-b-95-f {
  padding-bottom: 95px !important;
}

.pd-l-95-f {
  padding-left: 95px !important;
}

.pd-100 {
  padding: 100px;
}

.pd-100-f {
  padding: 100px !important;
}

.pd-y-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pd-y-100-f {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pd-x-100 {
  padding-left: 100px;
  padding-right: 100px;
}

.pd-x-100-f {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.pd-t-100 {
  padding-top: 100px;
}

.pd-r-100 {
  padding-right: 100px;
}

.pd-b-100 {
  padding-bottom: 100px;
}

.pd-l-100 {
  padding-left: 100px;
}

.pd-t-100-f {
  padding-top: 100px !important;
}

.pd-r-100-f {
  padding-right: 100px !important;
}

.pd-b-100-f {
  padding-bottom: 100px !important;
}

.pd-l-100-f {
  padding-left: 100px !important;
}

.pd-110 {
  padding: 110px;
}

.pd-110-f {
  padding: 110px !important;
}

.pd-y-110 {
  padding-top: 110px;
  padding-bottom: 110px;
}

.pd-y-110-f {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pd-x-110 {
  padding-left: 110px;
  padding-right: 110px;
}

.pd-x-110-f {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.pd-t-110 {
  padding-top: 110px;
}

.pd-r-110 {
  padding-right: 110px;
}

.pd-b-110 {
  padding-bottom: 110px;
}

.pd-l-110 {
  padding-left: 110px;
}

.pd-t-110-f {
  padding-top: 110px !important;
}

.pd-r-110-f {
  padding-right: 110px !important;
}

.pd-b-110-f {
  padding-bottom: 110px !important;
}

.pd-l-110-f {
  padding-left: 110px !important;
}

.pd-120 {
  padding: 120px;
}

.pd-120-f {
  padding: 120px !important;
}

.pd-y-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pd-y-120-f {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pd-x-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.pd-x-120-f {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.pd-t-120 {
  padding-top: 120px;
}

.pd-r-120 {
  padding-right: 120px;
}

.pd-b-120 {
  padding-bottom: 120px;
}

.pd-l-120 {
  padding-left: 120px;
}

.pd-t-120-f {
  padding-top: 120px !important;
}

.pd-r-120-f {
  padding-right: 120px !important;
}

.pd-b-120-f {
  padding-bottom: 120px !important;
}

.pd-l-120-f {
  padding-left: 120px !important;
}

.pd-130 {
  padding: 130px;
}

.pd-130-f {
  padding: 130px !important;
}

.pd-y-130 {
  padding-top: 130px;
  padding-bottom: 130px;
}

.pd-y-130-f {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.pd-x-130 {
  padding-left: 130px;
  padding-right: 130px;
}

.pd-x-130-f {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.pd-t-130 {
  padding-top: 130px;
}

.pd-r-130 {
  padding-right: 130px;
}

.pd-b-130 {
  padding-bottom: 130px;
}

.pd-l-130 {
  padding-left: 130px;
}

.pd-t-130-f {
  padding-top: 130px !important;
}

.pd-r-130-f {
  padding-right: 130px !important;
}

.pd-b-130-f {
  padding-bottom: 130px !important;
}

.pd-l-130-f {
  padding-left: 130px !important;
}

.pd-140 {
  padding: 140px;
}

.pd-140-f {
  padding: 140px !important;
}

.pd-y-140 {
  padding-top: 140px;
  padding-bottom: 140px;
}

.pd-y-140-f {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.pd-x-140 {
  padding-left: 140px;
  padding-right: 140px;
}

.pd-x-140-f {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.pd-t-140 {
  padding-top: 140px;
}

.pd-r-140 {
  padding-right: 140px;
}

.pd-b-140 {
  padding-bottom: 140px;
}

.pd-l-140 {
  padding-left: 140px;
}

.pd-t-140-f {
  padding-top: 140px !important;
}

.pd-r-140-f {
  padding-right: 140px !important;
}

.pd-b-140-f {
  padding-bottom: 140px !important;
}

.pd-l-140-f {
  padding-left: 140px !important;
}

.pd-150 {
  padding: 150px;
}

.pd-150-f {
  padding: 150px !important;
}

.pd-y-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pd-y-150-f {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pd-x-150 {
  padding-left: 150px;
  padding-right: 150px;
}

.pd-x-150-f {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.pd-t-150 {
  padding-top: 150px;
}

.pd-r-150 {
  padding-right: 150px;
}

.pd-b-150 {
  padding-bottom: 150px;
}

.pd-l-150 {
  padding-left: 150px;
}

.pd-t-150-f {
  padding-top: 150px !important;
}

.pd-r-150-f {
  padding-right: 150px !important;
}

.pd-b-150-f {
  padding-bottom: 150px !important;
}

.pd-l-150-f {
  padding-left: 150px !important;
}

.pd-160 {
  padding: 160px;
}

.pd-160-f {
  padding: 160px !important;
}

.pd-y-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.pd-y-160-f {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.pd-x-160 {
  padding-left: 160px;
  padding-right: 160px;
}

.pd-x-160-f {
  padding-left: 160px !important;
  padding-right: 160px !important;
}

.pd-t-160 {
  padding-top: 160px;
}

.pd-r-160 {
  padding-right: 160px;
}

.pd-b-160 {
  padding-bottom: 160px;
}

.pd-l-160 {
  padding-left: 160px;
}

.pd-t-160-f {
  padding-top: 160px !important;
}

.pd-r-160-f {
  padding-right: 160px !important;
}

.pd-b-160-f {
  padding-bottom: 160px !important;
}

.pd-l-160-f {
  padding-left: 160px !important;
}

.pd-170 {
  padding: 170px;
}

.pd-170-f {
  padding: 170px !important;
}

.pd-y-170 {
  padding-top: 170px;
  padding-bottom: 170px;
}

.pd-y-170-f {
  padding-top: 170px !important;
  padding-bottom: 170px !important;
}

.pd-x-170 {
  padding-left: 170px;
  padding-right: 170px;
}

.pd-x-170-f {
  padding-left: 170px !important;
  padding-right: 170px !important;
}

.pd-t-170 {
  padding-top: 170px;
}

.pd-r-170 {
  padding-right: 170px;
}

.pd-b-170 {
  padding-bottom: 170px;
}

.pd-l-170 {
  padding-left: 170px;
}

.pd-t-170-f {
  padding-top: 170px !important;
}

.pd-r-170-f {
  padding-right: 170px !important;
}

.pd-b-170-f {
  padding-bottom: 170px !important;
}

.pd-l-170-f {
  padding-left: 170px !important;
}

.pd-180 {
  padding: 180px;
}

.pd-180-f {
  padding: 180px !important;
}

.pd-y-180 {
  padding-top: 180px;
  padding-bottom: 180px;
}

.pd-y-180-f {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.pd-x-180 {
  padding-left: 180px;
  padding-right: 180px;
}

.pd-x-180-f {
  padding-left: 180px !important;
  padding-right: 180px !important;
}

.pd-t-180 {
  padding-top: 180px;
}

.pd-r-180 {
  padding-right: 180px;
}

.pd-b-180 {
  padding-bottom: 180px;
}

.pd-l-180 {
  padding-left: 180px;
}

.pd-t-180-f {
  padding-top: 180px !important;
}

.pd-r-180-f {
  padding-right: 180px !important;
}

.pd-b-180-f {
  padding-bottom: 180px !important;
}

.pd-l-180-f {
  padding-left: 180px !important;
}

.pd-190 {
  padding: 190px;
}

.pd-190-f {
  padding: 190px !important;
}

.pd-y-190 {
  padding-top: 190px;
  padding-bottom: 190px;
}

.pd-y-190-f {
  padding-top: 190px !important;
  padding-bottom: 190px !important;
}

.pd-x-190 {
  padding-left: 190px;
  padding-right: 190px;
}

.pd-x-190-f {
  padding-left: 190px !important;
  padding-right: 190px !important;
}

.pd-t-190 {
  padding-top: 190px;
}

.pd-r-190 {
  padding-right: 190px;
}

.pd-b-190 {
  padding-bottom: 190px;
}

.pd-l-190 {
  padding-left: 190px;
}

.pd-t-190-f {
  padding-top: 190px !important;
}

.pd-r-190-f {
  padding-right: 190px !important;
}

.pd-b-190-f {
  padding-bottom: 190px !important;
}

.pd-l-190-f {
  padding-left: 190px !important;
}

.pd-200 {
  padding: 200px;
}

.pd-200-f {
  padding: 200px !important;
}

.pd-y-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.pd-y-200-f {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.pd-x-200 {
  padding-left: 200px;
  padding-right: 200px;
}

.pd-x-200-f {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.pd-t-200 {
  padding-top: 200px;
}

.pd-r-200 {
  padding-right: 200px;
}

.pd-b-200 {
  padding-bottom: 200px;
}

.pd-l-200 {
  padding-left: 200px;
}

.pd-t-200-f {
  padding-top: 200px !important;
}

.pd-r-200-f {
  padding-right: 200px !important;
}

.pd-b-200-f {
  padding-bottom: 200px !important;
}

.pd-l-200-f {
  padding-left: 200px !important;
}

@media (min-width: 480px) {
  .pd-xs-0 {
    padding: 0px;
  }
  .pd-xs-0-f {
    padding: 0px !important;
  }
  .pd-xs-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pd-xs-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pd-xs-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-xs-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pd-xs-t-0 {
    padding-top: 0px;
  }
  .pd-xs-r-0 {
    padding-right: 0px;
  }
  .pd-xs-b-0 {
    padding-bottom: 0px;
  }
  .pd-xs-l-0 {
    padding-left: 0px;
  }
  .pd-xs-t-0-f {
    padding-top: 0px !important;
  }
  .pd-xs-r-0-f {
    padding-right: 0px !important;
  }
  .pd-xs-b-0-f {
    padding-bottom: 0px !important;
  }
  .pd-xs-l-0-f {
    padding-left: 0px !important;
  }
  .pd-xs-1 {
    padding: 1px;
  }
  .pd-xs-1-f {
    padding: 1px !important;
  }
  .pd-xs-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .pd-xs-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pd-xs-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .pd-xs-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .pd-xs-t-1 {
    padding-top: 1px;
  }
  .pd-xs-r-1 {
    padding-right: 1px;
  }
  .pd-xs-b-1 {
    padding-bottom: 1px;
  }
  .pd-xs-l-1 {
    padding-left: 1px;
  }
  .pd-xs-t-1-f {
    padding-top: 1px !important;
  }
  .pd-xs-r-1-f {
    padding-right: 1px !important;
  }
  .pd-xs-b-1-f {
    padding-bottom: 1px !important;
  }
  .pd-xs-l-1-f {
    padding-left: 1px !important;
  }
  .pd-xs-2 {
    padding: 2px;
  }
  .pd-xs-2-f {
    padding: 2px !important;
  }
  .pd-xs-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .pd-xs-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .pd-xs-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pd-xs-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .pd-xs-t-2 {
    padding-top: 2px;
  }
  .pd-xs-r-2 {
    padding-right: 2px;
  }
  .pd-xs-b-2 {
    padding-bottom: 2px;
  }
  .pd-xs-l-2 {
    padding-left: 2px;
  }
  .pd-xs-t-2-f {
    padding-top: 2px !important;
  }
  .pd-xs-r-2-f {
    padding-right: 2px !important;
  }
  .pd-xs-b-2-f {
    padding-bottom: 2px !important;
  }
  .pd-xs-l-2-f {
    padding-left: 2px !important;
  }
  .pd-xs-3 {
    padding: 3px;
  }
  .pd-xs-3-f {
    padding: 3px !important;
  }
  .pd-xs-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .pd-xs-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .pd-xs-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .pd-xs-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .pd-xs-t-3 {
    padding-top: 3px;
  }
  .pd-xs-r-3 {
    padding-right: 3px;
  }
  .pd-xs-b-3 {
    padding-bottom: 3px;
  }
  .pd-xs-l-3 {
    padding-left: 3px;
  }
  .pd-xs-t-3-f {
    padding-top: 3px !important;
  }
  .pd-xs-r-3-f {
    padding-right: 3px !important;
  }
  .pd-xs-b-3-f {
    padding-bottom: 3px !important;
  }
  .pd-xs-l-3-f {
    padding-left: 3px !important;
  }
  .pd-xs-4 {
    padding: 4px;
  }
  .pd-xs-4-f {
    padding: 4px !important;
  }
  .pd-xs-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pd-xs-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .pd-xs-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pd-xs-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pd-xs-t-4 {
    padding-top: 4px;
  }
  .pd-xs-r-4 {
    padding-right: 4px;
  }
  .pd-xs-b-4 {
    padding-bottom: 4px;
  }
  .pd-xs-l-4 {
    padding-left: 4px;
  }
  .pd-xs-t-4-f {
    padding-top: 4px !important;
  }
  .pd-xs-r-4-f {
    padding-right: 4px !important;
  }
  .pd-xs-b-4-f {
    padding-bottom: 4px !important;
  }
  .pd-xs-l-4-f {
    padding-left: 4px !important;
  }
  .pd-xs-5 {
    padding: 5px;
  }
  .pd-xs-5-f {
    padding: 5px !important;
  }
  .pd-xs-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pd-xs-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pd-xs-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-xs-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .pd-xs-t-5 {
    padding-top: 5px;
  }
  .pd-xs-r-5 {
    padding-right: 5px;
  }
  .pd-xs-b-5 {
    padding-bottom: 5px;
  }
  .pd-xs-l-5 {
    padding-left: 5px;
  }
  .pd-xs-t-5-f {
    padding-top: 5px !important;
  }
  .pd-xs-r-5-f {
    padding-right: 5px !important;
  }
  .pd-xs-b-5-f {
    padding-bottom: 5px !important;
  }
  .pd-xs-l-5-f {
    padding-left: 5px !important;
  }
  .pd-xs-6 {
    padding: 6px;
  }
  .pd-xs-6-f {
    padding: 6px !important;
  }
  .pd-xs-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .pd-xs-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .pd-xs-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pd-xs-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .pd-xs-t-6 {
    padding-top: 6px;
  }
  .pd-xs-r-6 {
    padding-right: 6px;
  }
  .pd-xs-b-6 {
    padding-bottom: 6px;
  }
  .pd-xs-l-6 {
    padding-left: 6px;
  }
  .pd-xs-t-6-f {
    padding-top: 6px !important;
  }
  .pd-xs-r-6-f {
    padding-right: 6px !important;
  }
  .pd-xs-b-6-f {
    padding-bottom: 6px !important;
  }
  .pd-xs-l-6-f {
    padding-left: 6px !important;
  }
  .pd-xs-7 {
    padding: 7px;
  }
  .pd-xs-7-f {
    padding: 7px !important;
  }
  .pd-xs-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .pd-xs-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .pd-xs-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pd-xs-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .pd-xs-t-7 {
    padding-top: 7px;
  }
  .pd-xs-r-7 {
    padding-right: 7px;
  }
  .pd-xs-b-7 {
    padding-bottom: 7px;
  }
  .pd-xs-l-7 {
    padding-left: 7px;
  }
  .pd-xs-t-7-f {
    padding-top: 7px !important;
  }
  .pd-xs-r-7-f {
    padding-right: 7px !important;
  }
  .pd-xs-b-7-f {
    padding-bottom: 7px !important;
  }
  .pd-xs-l-7-f {
    padding-left: 7px !important;
  }
  .pd-xs-8 {
    padding: 8px;
  }
  .pd-xs-8-f {
    padding: 8px !important;
  }
  .pd-xs-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pd-xs-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pd-xs-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pd-xs-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pd-xs-t-8 {
    padding-top: 8px;
  }
  .pd-xs-r-8 {
    padding-right: 8px;
  }
  .pd-xs-b-8 {
    padding-bottom: 8px;
  }
  .pd-xs-l-8 {
    padding-left: 8px;
  }
  .pd-xs-t-8-f {
    padding-top: 8px !important;
  }
  .pd-xs-r-8-f {
    padding-right: 8px !important;
  }
  .pd-xs-b-8-f {
    padding-bottom: 8px !important;
  }
  .pd-xs-l-8-f {
    padding-left: 8px !important;
  }
  .pd-xs-9 {
    padding: 9px;
  }
  .pd-xs-9-f {
    padding: 9px !important;
  }
  .pd-xs-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .pd-xs-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
  .pd-xs-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }
  .pd-xs-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
  .pd-xs-t-9 {
    padding-top: 9px;
  }
  .pd-xs-r-9 {
    padding-right: 9px;
  }
  .pd-xs-b-9 {
    padding-bottom: 9px;
  }
  .pd-xs-l-9 {
    padding-left: 9px;
  }
  .pd-xs-t-9-f {
    padding-top: 9px !important;
  }
  .pd-xs-r-9-f {
    padding-right: 9px !important;
  }
  .pd-xs-b-9-f {
    padding-bottom: 9px !important;
  }
  .pd-xs-l-9-f {
    padding-left: 9px !important;
  }
  .pd-xs-10 {
    padding: 10px;
  }
  .pd-xs-10-f {
    padding: 10px !important;
  }
  .pd-xs-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pd-xs-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pd-xs-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-xs-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pd-xs-t-10 {
    padding-top: 10px;
  }
  .pd-xs-r-10 {
    padding-right: 10px;
  }
  .pd-xs-b-10 {
    padding-bottom: 10px;
  }
  .pd-xs-l-10 {
    padding-left: 10px;
  }
  .pd-xs-t-10-f {
    padding-top: 10px !important;
  }
  .pd-xs-r-10-f {
    padding-right: 10px !important;
  }
  .pd-xs-b-10-f {
    padding-bottom: 10px !important;
  }
  .pd-xs-l-10-f {
    padding-left: 10px !important;
  }
  .pd-xs-15 {
    padding: 15px;
  }
  .pd-xs-15-f {
    padding: 15px !important;
  }
  .pd-xs-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pd-xs-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pd-xs-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-xs-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .pd-xs-t-15 {
    padding-top: 15px;
  }
  .pd-xs-r-15 {
    padding-right: 15px;
  }
  .pd-xs-b-15 {
    padding-bottom: 15px;
  }
  .pd-xs-l-15 {
    padding-left: 15px;
  }
  .pd-xs-t-15-f {
    padding-top: 15px !important;
  }
  .pd-xs-r-15-f {
    padding-right: 15px !important;
  }
  .pd-xs-b-15-f {
    padding-bottom: 15px !important;
  }
  .pd-xs-l-15-f {
    padding-left: 15px !important;
  }
  .pd-xs-20 {
    padding: 20px;
  }
  .pd-xs-20-f {
    padding: 20px !important;
  }
  .pd-xs-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pd-xs-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pd-xs-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pd-xs-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pd-xs-t-20 {
    padding-top: 20px;
  }
  .pd-xs-r-20 {
    padding-right: 20px;
  }
  .pd-xs-b-20 {
    padding-bottom: 20px;
  }
  .pd-xs-l-20 {
    padding-left: 20px;
  }
  .pd-xs-t-20-f {
    padding-top: 20px !important;
  }
  .pd-xs-r-20-f {
    padding-right: 20px !important;
  }
  .pd-xs-b-20-f {
    padding-bottom: 20px !important;
  }
  .pd-xs-l-20-f {
    padding-left: 20px !important;
  }
  .pd-xs-25 {
    padding: 25px;
  }
  .pd-xs-25-f {
    padding: 25px !important;
  }
  .pd-xs-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .pd-xs-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pd-xs-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .pd-xs-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .pd-xs-t-25 {
    padding-top: 25px;
  }
  .pd-xs-r-25 {
    padding-right: 25px;
  }
  .pd-xs-b-25 {
    padding-bottom: 25px;
  }
  .pd-xs-l-25 {
    padding-left: 25px;
  }
  .pd-xs-t-25-f {
    padding-top: 25px !important;
  }
  .pd-xs-r-25-f {
    padding-right: 25px !important;
  }
  .pd-xs-b-25-f {
    padding-bottom: 25px !important;
  }
  .pd-xs-l-25-f {
    padding-left: 25px !important;
  }
  .pd-xs-30 {
    padding: 30px;
  }
  .pd-xs-30-f {
    padding: 30px !important;
  }
  .pd-xs-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pd-xs-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pd-xs-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-xs-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pd-xs-t-30 {
    padding-top: 30px;
  }
  .pd-xs-r-30 {
    padding-right: 30px;
  }
  .pd-xs-b-30 {
    padding-bottom: 30px;
  }
  .pd-xs-l-30 {
    padding-left: 30px;
  }
  .pd-xs-t-30-f {
    padding-top: 30px !important;
  }
  .pd-xs-r-30-f {
    padding-right: 30px !important;
  }
  .pd-xs-b-30-f {
    padding-bottom: 30px !important;
  }
  .pd-xs-l-30-f {
    padding-left: 30px !important;
  }
  .pd-xs-35 {
    padding: 35px;
  }
  .pd-xs-35-f {
    padding: 35px !important;
  }
  .pd-xs-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pd-xs-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pd-xs-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .pd-xs-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .pd-xs-t-35 {
    padding-top: 35px;
  }
  .pd-xs-r-35 {
    padding-right: 35px;
  }
  .pd-xs-b-35 {
    padding-bottom: 35px;
  }
  .pd-xs-l-35 {
    padding-left: 35px;
  }
  .pd-xs-t-35-f {
    padding-top: 35px !important;
  }
  .pd-xs-r-35-f {
    padding-right: 35px !important;
  }
  .pd-xs-b-35-f {
    padding-bottom: 35px !important;
  }
  .pd-xs-l-35-f {
    padding-left: 35px !important;
  }
  .pd-xs-40 {
    padding: 40px;
  }
  .pd-xs-40-f {
    padding: 40px !important;
  }
  .pd-xs-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pd-xs-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pd-xs-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pd-xs-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .pd-xs-t-40 {
    padding-top: 40px;
  }
  .pd-xs-r-40 {
    padding-right: 40px;
  }
  .pd-xs-b-40 {
    padding-bottom: 40px;
  }
  .pd-xs-l-40 {
    padding-left: 40px;
  }
  .pd-xs-t-40-f {
    padding-top: 40px !important;
  }
  .pd-xs-r-40-f {
    padding-right: 40px !important;
  }
  .pd-xs-b-40-f {
    padding-bottom: 40px !important;
  }
  .pd-xs-l-40-f {
    padding-left: 40px !important;
  }
  .pd-xs-45 {
    padding: 45px;
  }
  .pd-xs-45-f {
    padding: 45px !important;
  }
  .pd-xs-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .pd-xs-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pd-xs-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-xs-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .pd-xs-t-45 {
    padding-top: 45px;
  }
  .pd-xs-r-45 {
    padding-right: 45px;
  }
  .pd-xs-b-45 {
    padding-bottom: 45px;
  }
  .pd-xs-l-45 {
    padding-left: 45px;
  }
  .pd-xs-t-45-f {
    padding-top: 45px !important;
  }
  .pd-xs-r-45-f {
    padding-right: 45px !important;
  }
  .pd-xs-b-45-f {
    padding-bottom: 45px !important;
  }
  .pd-xs-l-45-f {
    padding-left: 45px !important;
  }
  .pd-xs-50 {
    padding: 50px;
  }
  .pd-xs-50-f {
    padding: 50px !important;
  }
  .pd-xs-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pd-xs-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pd-xs-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pd-xs-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .pd-xs-t-50 {
    padding-top: 50px;
  }
  .pd-xs-r-50 {
    padding-right: 50px;
  }
  .pd-xs-b-50 {
    padding-bottom: 50px;
  }
  .pd-xs-l-50 {
    padding-left: 50px;
  }
  .pd-xs-t-50-f {
    padding-top: 50px !important;
  }
  .pd-xs-r-50-f {
    padding-right: 50px !important;
  }
  .pd-xs-b-50-f {
    padding-bottom: 50px !important;
  }
  .pd-xs-l-50-f {
    padding-left: 50px !important;
  }
  .pd-xs-55 {
    padding: 55px;
  }
  .pd-xs-55-f {
    padding: 55px !important;
  }
  .pd-xs-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .pd-xs-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pd-xs-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .pd-xs-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .pd-xs-t-55 {
    padding-top: 55px;
  }
  .pd-xs-r-55 {
    padding-right: 55px;
  }
  .pd-xs-b-55 {
    padding-bottom: 55px;
  }
  .pd-xs-l-55 {
    padding-left: 55px;
  }
  .pd-xs-t-55-f {
    padding-top: 55px !important;
  }
  .pd-xs-r-55-f {
    padding-right: 55px !important;
  }
  .pd-xs-b-55-f {
    padding-bottom: 55px !important;
  }
  .pd-xs-l-55-f {
    padding-left: 55px !important;
  }
  .pd-xs-60 {
    padding: 60px;
  }
  .pd-xs-60-f {
    padding: 60px !important;
  }
  .pd-xs-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pd-xs-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pd-xs-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-xs-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .pd-xs-t-60 {
    padding-top: 60px;
  }
  .pd-xs-r-60 {
    padding-right: 60px;
  }
  .pd-xs-b-60 {
    padding-bottom: 60px;
  }
  .pd-xs-l-60 {
    padding-left: 60px;
  }
  .pd-xs-t-60-f {
    padding-top: 60px !important;
  }
  .pd-xs-r-60-f {
    padding-right: 60px !important;
  }
  .pd-xs-b-60-f {
    padding-bottom: 60px !important;
  }
  .pd-xs-l-60-f {
    padding-left: 60px !important;
  }
  .pd-xs-65 {
    padding: 65px;
  }
  .pd-xs-65-f {
    padding: 65px !important;
  }
  .pd-xs-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .pd-xs-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pd-xs-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .pd-xs-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .pd-xs-t-65 {
    padding-top: 65px;
  }
  .pd-xs-r-65 {
    padding-right: 65px;
  }
  .pd-xs-b-65 {
    padding-bottom: 65px;
  }
  .pd-xs-l-65 {
    padding-left: 65px;
  }
  .pd-xs-t-65-f {
    padding-top: 65px !important;
  }
  .pd-xs-r-65-f {
    padding-right: 65px !important;
  }
  .pd-xs-b-65-f {
    padding-bottom: 65px !important;
  }
  .pd-xs-l-65-f {
    padding-left: 65px !important;
  }
  .pd-xs-70 {
    padding: 70px;
  }
  .pd-xs-70-f {
    padding: 70px !important;
  }
  .pd-xs-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .pd-xs-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pd-xs-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pd-xs-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .pd-xs-t-70 {
    padding-top: 70px;
  }
  .pd-xs-r-70 {
    padding-right: 70px;
  }
  .pd-xs-b-70 {
    padding-bottom: 70px;
  }
  .pd-xs-l-70 {
    padding-left: 70px;
  }
  .pd-xs-t-70-f {
    padding-top: 70px !important;
  }
  .pd-xs-r-70-f {
    padding-right: 70px !important;
  }
  .pd-xs-b-70-f {
    padding-bottom: 70px !important;
  }
  .pd-xs-l-70-f {
    padding-left: 70px !important;
  }
  .pd-xs-75 {
    padding: 75px;
  }
  .pd-xs-75-f {
    padding: 75px !important;
  }
  .pd-xs-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .pd-xs-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pd-xs-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .pd-xs-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .pd-xs-t-75 {
    padding-top: 75px;
  }
  .pd-xs-r-75 {
    padding-right: 75px;
  }
  .pd-xs-b-75 {
    padding-bottom: 75px;
  }
  .pd-xs-l-75 {
    padding-left: 75px;
  }
  .pd-xs-t-75-f {
    padding-top: 75px !important;
  }
  .pd-xs-r-75-f {
    padding-right: 75px !important;
  }
  .pd-xs-b-75-f {
    padding-bottom: 75px !important;
  }
  .pd-xs-l-75-f {
    padding-left: 75px !important;
  }
  .pd-xs-80 {
    padding: 80px;
  }
  .pd-xs-80-f {
    padding: 80px !important;
  }
  .pd-xs-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pd-xs-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pd-xs-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pd-xs-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .pd-xs-t-80 {
    padding-top: 80px;
  }
  .pd-xs-r-80 {
    padding-right: 80px;
  }
  .pd-xs-b-80 {
    padding-bottom: 80px;
  }
  .pd-xs-l-80 {
    padding-left: 80px;
  }
  .pd-xs-t-80-f {
    padding-top: 80px !important;
  }
  .pd-xs-r-80-f {
    padding-right: 80px !important;
  }
  .pd-xs-b-80-f {
    padding-bottom: 80px !important;
  }
  .pd-xs-l-80-f {
    padding-left: 80px !important;
  }
  .pd-xs-85 {
    padding: 85px;
  }
  .pd-xs-85-f {
    padding: 85px !important;
  }
  .pd-xs-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .pd-xs-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pd-xs-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .pd-xs-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .pd-xs-t-85 {
    padding-top: 85px;
  }
  .pd-xs-r-85 {
    padding-right: 85px;
  }
  .pd-xs-b-85 {
    padding-bottom: 85px;
  }
  .pd-xs-l-85 {
    padding-left: 85px;
  }
  .pd-xs-t-85-f {
    padding-top: 85px !important;
  }
  .pd-xs-r-85-f {
    padding-right: 85px !important;
  }
  .pd-xs-b-85-f {
    padding-bottom: 85px !important;
  }
  .pd-xs-l-85-f {
    padding-left: 85px !important;
  }
  .pd-xs-90 {
    padding: 90px;
  }
  .pd-xs-90-f {
    padding: 90px !important;
  }
  .pd-xs-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .pd-xs-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pd-xs-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pd-xs-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .pd-xs-t-90 {
    padding-top: 90px;
  }
  .pd-xs-r-90 {
    padding-right: 90px;
  }
  .pd-xs-b-90 {
    padding-bottom: 90px;
  }
  .pd-xs-l-90 {
    padding-left: 90px;
  }
  .pd-xs-t-90-f {
    padding-top: 90px !important;
  }
  .pd-xs-r-90-f {
    padding-right: 90px !important;
  }
  .pd-xs-b-90-f {
    padding-bottom: 90px !important;
  }
  .pd-xs-l-90-f {
    padding-left: 90px !important;
  }
  .pd-xs-95 {
    padding: 95px;
  }
  .pd-xs-95-f {
    padding: 95px !important;
  }
  .pd-xs-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .pd-xs-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pd-xs-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .pd-xs-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .pd-xs-t-95 {
    padding-top: 95px;
  }
  .pd-xs-r-95 {
    padding-right: 95px;
  }
  .pd-xs-b-95 {
    padding-bottom: 95px;
  }
  .pd-xs-l-95 {
    padding-left: 95px;
  }
  .pd-xs-t-95-f {
    padding-top: 95px !important;
  }
  .pd-xs-r-95-f {
    padding-right: 95px !important;
  }
  .pd-xs-b-95-f {
    padding-bottom: 95px !important;
  }
  .pd-xs-l-95-f {
    padding-left: 95px !important;
  }
  .pd-xs-100 {
    padding: 100px;
  }
  .pd-xs-100-f {
    padding: 100px !important;
  }
  .pd-xs-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pd-xs-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pd-xs-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pd-xs-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .pd-xs-t-100 {
    padding-top: 100px;
  }
  .pd-xs-r-100 {
    padding-right: 100px;
  }
  .pd-xs-b-100 {
    padding-bottom: 100px;
  }
  .pd-xs-l-100 {
    padding-left: 100px;
  }
  .pd-xs-t-100-f {
    padding-top: 100px !important;
  }
  .pd-xs-r-100-f {
    padding-right: 100px !important;
  }
  .pd-xs-b-100-f {
    padding-bottom: 100px !important;
  }
  .pd-xs-l-100-f {
    padding-left: 100px !important;
  }
  .pd-xs-110 {
    padding: 110px;
  }
  .pd-xs-110-f {
    padding: 110px !important;
  }
  .pd-xs-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .pd-xs-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pd-xs-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pd-xs-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .pd-xs-t-110 {
    padding-top: 110px;
  }
  .pd-xs-r-110 {
    padding-right: 110px;
  }
  .pd-xs-b-110 {
    padding-bottom: 110px;
  }
  .pd-xs-l-110 {
    padding-left: 110px;
  }
  .pd-xs-t-110-f {
    padding-top: 110px !important;
  }
  .pd-xs-r-110-f {
    padding-right: 110px !important;
  }
  .pd-xs-b-110-f {
    padding-bottom: 110px !important;
  }
  .pd-xs-l-110-f {
    padding-left: 110px !important;
  }
  .pd-xs-120 {
    padding: 120px;
  }
  .pd-xs-120-f {
    padding: 120px !important;
  }
  .pd-xs-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pd-xs-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pd-xs-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pd-xs-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .pd-xs-t-120 {
    padding-top: 120px;
  }
  .pd-xs-r-120 {
    padding-right: 120px;
  }
  .pd-xs-b-120 {
    padding-bottom: 120px;
  }
  .pd-xs-l-120 {
    padding-left: 120px;
  }
  .pd-xs-t-120-f {
    padding-top: 120px !important;
  }
  .pd-xs-r-120-f {
    padding-right: 120px !important;
  }
  .pd-xs-b-120-f {
    padding-bottom: 120px !important;
  }
  .pd-xs-l-120-f {
    padding-left: 120px !important;
  }
  .pd-xs-130 {
    padding: 130px;
  }
  .pd-xs-130-f {
    padding: 130px !important;
  }
  .pd-xs-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .pd-xs-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .pd-xs-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }
  .pd-xs-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .pd-xs-t-130 {
    padding-top: 130px;
  }
  .pd-xs-r-130 {
    padding-right: 130px;
  }
  .pd-xs-b-130 {
    padding-bottom: 130px;
  }
  .pd-xs-l-130 {
    padding-left: 130px;
  }
  .pd-xs-t-130-f {
    padding-top: 130px !important;
  }
  .pd-xs-r-130-f {
    padding-right: 130px !important;
  }
  .pd-xs-b-130-f {
    padding-bottom: 130px !important;
  }
  .pd-xs-l-130-f {
    padding-left: 130px !important;
  }
  .pd-xs-140 {
    padding: 140px;
  }
  .pd-xs-140-f {
    padding: 140px !important;
  }
  .pd-xs-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .pd-xs-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .pd-xs-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .pd-xs-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .pd-xs-t-140 {
    padding-top: 140px;
  }
  .pd-xs-r-140 {
    padding-right: 140px;
  }
  .pd-xs-b-140 {
    padding-bottom: 140px;
  }
  .pd-xs-l-140 {
    padding-left: 140px;
  }
  .pd-xs-t-140-f {
    padding-top: 140px !important;
  }
  .pd-xs-r-140-f {
    padding-right: 140px !important;
  }
  .pd-xs-b-140-f {
    padding-bottom: 140px !important;
  }
  .pd-xs-l-140-f {
    padding-left: 140px !important;
  }
  .pd-xs-150 {
    padding: 150px;
  }
  .pd-xs-150-f {
    padding: 150px !important;
  }
  .pd-xs-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .pd-xs-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pd-xs-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }
  .pd-xs-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .pd-xs-t-150 {
    padding-top: 150px;
  }
  .pd-xs-r-150 {
    padding-right: 150px;
  }
  .pd-xs-b-150 {
    padding-bottom: 150px;
  }
  .pd-xs-l-150 {
    padding-left: 150px;
  }
  .pd-xs-t-150-f {
    padding-top: 150px !important;
  }
  .pd-xs-r-150-f {
    padding-right: 150px !important;
  }
  .pd-xs-b-150-f {
    padding-bottom: 150px !important;
  }
  .pd-xs-l-150-f {
    padding-left: 150px !important;
  }
  .pd-xs-160 {
    padding: 160px;
  }
  .pd-xs-160-f {
    padding: 160px !important;
  }
  .pd-xs-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .pd-xs-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .pd-xs-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }
  .pd-xs-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .pd-xs-t-160 {
    padding-top: 160px;
  }
  .pd-xs-r-160 {
    padding-right: 160px;
  }
  .pd-xs-b-160 {
    padding-bottom: 160px;
  }
  .pd-xs-l-160 {
    padding-left: 160px;
  }
  .pd-xs-t-160-f {
    padding-top: 160px !important;
  }
  .pd-xs-r-160-f {
    padding-right: 160px !important;
  }
  .pd-xs-b-160-f {
    padding-bottom: 160px !important;
  }
  .pd-xs-l-160-f {
    padding-left: 160px !important;
  }
  .pd-xs-170 {
    padding: 170px;
  }
  .pd-xs-170-f {
    padding: 170px !important;
  }
  .pd-xs-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }
  .pd-xs-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }
  .pd-xs-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }
  .pd-xs-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
  .pd-xs-t-170 {
    padding-top: 170px;
  }
  .pd-xs-r-170 {
    padding-right: 170px;
  }
  .pd-xs-b-170 {
    padding-bottom: 170px;
  }
  .pd-xs-l-170 {
    padding-left: 170px;
  }
  .pd-xs-t-170-f {
    padding-top: 170px !important;
  }
  .pd-xs-r-170-f {
    padding-right: 170px !important;
  }
  .pd-xs-b-170-f {
    padding-bottom: 170px !important;
  }
  .pd-xs-l-170-f {
    padding-left: 170px !important;
  }
  .pd-xs-180 {
    padding: 180px;
  }
  .pd-xs-180-f {
    padding: 180px !important;
  }
  .pd-xs-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .pd-xs-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .pd-xs-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }
  .pd-xs-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .pd-xs-t-180 {
    padding-top: 180px;
  }
  .pd-xs-r-180 {
    padding-right: 180px;
  }
  .pd-xs-b-180 {
    padding-bottom: 180px;
  }
  .pd-xs-l-180 {
    padding-left: 180px;
  }
  .pd-xs-t-180-f {
    padding-top: 180px !important;
  }
  .pd-xs-r-180-f {
    padding-right: 180px !important;
  }
  .pd-xs-b-180-f {
    padding-bottom: 180px !important;
  }
  .pd-xs-l-180-f {
    padding-left: 180px !important;
  }
  .pd-xs-190 {
    padding: 190px;
  }
  .pd-xs-190-f {
    padding: 190px !important;
  }
  .pd-xs-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }
  .pd-xs-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }
  .pd-xs-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }
  .pd-xs-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
  .pd-xs-t-190 {
    padding-top: 190px;
  }
  .pd-xs-r-190 {
    padding-right: 190px;
  }
  .pd-xs-b-190 {
    padding-bottom: 190px;
  }
  .pd-xs-l-190 {
    padding-left: 190px;
  }
  .pd-xs-t-190-f {
    padding-top: 190px !important;
  }
  .pd-xs-r-190-f {
    padding-right: 190px !important;
  }
  .pd-xs-b-190-f {
    padding-bottom: 190px !important;
  }
  .pd-xs-l-190-f {
    padding-left: 190px !important;
  }
  .pd-xs-200 {
    padding: 200px;
  }
  .pd-xs-200-f {
    padding: 200px !important;
  }
  .pd-xs-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .pd-xs-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .pd-xs-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .pd-xs-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .pd-xs-t-200 {
    padding-top: 200px;
  }
  .pd-xs-r-200 {
    padding-right: 200px;
  }
  .pd-xs-b-200 {
    padding-bottom: 200px;
  }
  .pd-xs-l-200 {
    padding-left: 200px;
  }
  .pd-xs-t-200-f {
    padding-top: 200px !important;
  }
  .pd-xs-r-200-f {
    padding-right: 200px !important;
  }
  .pd-xs-b-200-f {
    padding-bottom: 200px !important;
  }
  .pd-xs-l-200-f {
    padding-left: 200px !important;
  }
}

@media (min-width: 576px) {
  .pd-sm-0 {
    padding: 0px;
  }
  .pd-sm-0-f {
    padding: 0px !important;
  }
  .pd-sm-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pd-sm-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pd-sm-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-sm-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pd-sm-t-0 {
    padding-top: 0px;
  }
  .pd-sm-r-0 {
    padding-right: 0px;
  }
  .pd-sm-b-0 {
    padding-bottom: 0px;
  }
  .pd-sm-l-0 {
    padding-left: 0px;
  }
  .pd-sm-t-0-f {
    padding-top: 0px !important;
  }
  .pd-sm-r-0-f {
    padding-right: 0px !important;
  }
  .pd-sm-b-0-f {
    padding-bottom: 0px !important;
  }
  .pd-sm-l-0-f {
    padding-left: 0px !important;
  }
  .pd-sm-1 {
    padding: 1px;
  }
  .pd-sm-1-f {
    padding: 1px !important;
  }
  .pd-sm-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .pd-sm-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pd-sm-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .pd-sm-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .pd-sm-t-1 {
    padding-top: 1px;
  }
  .pd-sm-r-1 {
    padding-right: 1px;
  }
  .pd-sm-b-1 {
    padding-bottom: 1px;
  }
  .pd-sm-l-1 {
    padding-left: 1px;
  }
  .pd-sm-t-1-f {
    padding-top: 1px !important;
  }
  .pd-sm-r-1-f {
    padding-right: 1px !important;
  }
  .pd-sm-b-1-f {
    padding-bottom: 1px !important;
  }
  .pd-sm-l-1-f {
    padding-left: 1px !important;
  }
  .pd-sm-2 {
    padding: 2px;
  }
  .pd-sm-2-f {
    padding: 2px !important;
  }
  .pd-sm-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .pd-sm-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .pd-sm-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pd-sm-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .pd-sm-t-2 {
    padding-top: 2px;
  }
  .pd-sm-r-2 {
    padding-right: 2px;
  }
  .pd-sm-b-2 {
    padding-bottom: 2px;
  }
  .pd-sm-l-2 {
    padding-left: 2px;
  }
  .pd-sm-t-2-f {
    padding-top: 2px !important;
  }
  .pd-sm-r-2-f {
    padding-right: 2px !important;
  }
  .pd-sm-b-2-f {
    padding-bottom: 2px !important;
  }
  .pd-sm-l-2-f {
    padding-left: 2px !important;
  }
  .pd-sm-3 {
    padding: 3px;
  }
  .pd-sm-3-f {
    padding: 3px !important;
  }
  .pd-sm-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .pd-sm-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .pd-sm-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .pd-sm-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .pd-sm-t-3 {
    padding-top: 3px;
  }
  .pd-sm-r-3 {
    padding-right: 3px;
  }
  .pd-sm-b-3 {
    padding-bottom: 3px;
  }
  .pd-sm-l-3 {
    padding-left: 3px;
  }
  .pd-sm-t-3-f {
    padding-top: 3px !important;
  }
  .pd-sm-r-3-f {
    padding-right: 3px !important;
  }
  .pd-sm-b-3-f {
    padding-bottom: 3px !important;
  }
  .pd-sm-l-3-f {
    padding-left: 3px !important;
  }
  .pd-sm-4 {
    padding: 4px;
  }
  .pd-sm-4-f {
    padding: 4px !important;
  }
  .pd-sm-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pd-sm-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .pd-sm-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pd-sm-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pd-sm-t-4 {
    padding-top: 4px;
  }
  .pd-sm-r-4 {
    padding-right: 4px;
  }
  .pd-sm-b-4 {
    padding-bottom: 4px;
  }
  .pd-sm-l-4 {
    padding-left: 4px;
  }
  .pd-sm-t-4-f {
    padding-top: 4px !important;
  }
  .pd-sm-r-4-f {
    padding-right: 4px !important;
  }
  .pd-sm-b-4-f {
    padding-bottom: 4px !important;
  }
  .pd-sm-l-4-f {
    padding-left: 4px !important;
  }
  .pd-sm-5 {
    padding: 5px;
  }
  .pd-sm-5-f {
    padding: 5px !important;
  }
  .pd-sm-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pd-sm-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pd-sm-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-sm-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .pd-sm-t-5 {
    padding-top: 5px;
  }
  .pd-sm-r-5 {
    padding-right: 5px;
  }
  .pd-sm-b-5 {
    padding-bottom: 5px;
  }
  .pd-sm-l-5 {
    padding-left: 5px;
  }
  .pd-sm-t-5-f {
    padding-top: 5px !important;
  }
  .pd-sm-r-5-f {
    padding-right: 5px !important;
  }
  .pd-sm-b-5-f {
    padding-bottom: 5px !important;
  }
  .pd-sm-l-5-f {
    padding-left: 5px !important;
  }
  .pd-sm-6 {
    padding: 6px;
  }
  .pd-sm-6-f {
    padding: 6px !important;
  }
  .pd-sm-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .pd-sm-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .pd-sm-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pd-sm-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .pd-sm-t-6 {
    padding-top: 6px;
  }
  .pd-sm-r-6 {
    padding-right: 6px;
  }
  .pd-sm-b-6 {
    padding-bottom: 6px;
  }
  .pd-sm-l-6 {
    padding-left: 6px;
  }
  .pd-sm-t-6-f {
    padding-top: 6px !important;
  }
  .pd-sm-r-6-f {
    padding-right: 6px !important;
  }
  .pd-sm-b-6-f {
    padding-bottom: 6px !important;
  }
  .pd-sm-l-6-f {
    padding-left: 6px !important;
  }
  .pd-sm-7 {
    padding: 7px;
  }
  .pd-sm-7-f {
    padding: 7px !important;
  }
  .pd-sm-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .pd-sm-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .pd-sm-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pd-sm-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .pd-sm-t-7 {
    padding-top: 7px;
  }
  .pd-sm-r-7 {
    padding-right: 7px;
  }
  .pd-sm-b-7 {
    padding-bottom: 7px;
  }
  .pd-sm-l-7 {
    padding-left: 7px;
  }
  .pd-sm-t-7-f {
    padding-top: 7px !important;
  }
  .pd-sm-r-7-f {
    padding-right: 7px !important;
  }
  .pd-sm-b-7-f {
    padding-bottom: 7px !important;
  }
  .pd-sm-l-7-f {
    padding-left: 7px !important;
  }
  .pd-sm-8 {
    padding: 8px;
  }
  .pd-sm-8-f {
    padding: 8px !important;
  }
  .pd-sm-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pd-sm-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pd-sm-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pd-sm-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pd-sm-t-8 {
    padding-top: 8px;
  }
  .pd-sm-r-8 {
    padding-right: 8px;
  }
  .pd-sm-b-8 {
    padding-bottom: 8px;
  }
  .pd-sm-l-8 {
    padding-left: 8px;
  }
  .pd-sm-t-8-f {
    padding-top: 8px !important;
  }
  .pd-sm-r-8-f {
    padding-right: 8px !important;
  }
  .pd-sm-b-8-f {
    padding-bottom: 8px !important;
  }
  .pd-sm-l-8-f {
    padding-left: 8px !important;
  }
  .pd-sm-9 {
    padding: 9px;
  }
  .pd-sm-9-f {
    padding: 9px !important;
  }
  .pd-sm-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .pd-sm-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
  .pd-sm-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }
  .pd-sm-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
  .pd-sm-t-9 {
    padding-top: 9px;
  }
  .pd-sm-r-9 {
    padding-right: 9px;
  }
  .pd-sm-b-9 {
    padding-bottom: 9px;
  }
  .pd-sm-l-9 {
    padding-left: 9px;
  }
  .pd-sm-t-9-f {
    padding-top: 9px !important;
  }
  .pd-sm-r-9-f {
    padding-right: 9px !important;
  }
  .pd-sm-b-9-f {
    padding-bottom: 9px !important;
  }
  .pd-sm-l-9-f {
    padding-left: 9px !important;
  }
  .pd-sm-10 {
    padding: 10px;
  }
  .pd-sm-10-f {
    padding: 10px !important;
  }
  .pd-sm-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pd-sm-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pd-sm-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-sm-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pd-sm-t-10 {
    padding-top: 10px;
  }
  .pd-sm-r-10 {
    padding-right: 10px;
  }
  .pd-sm-b-10 {
    padding-bottom: 10px;
  }
  .pd-sm-l-10 {
    padding-left: 10px;
  }
  .pd-sm-t-10-f {
    padding-top: 10px !important;
  }
  .pd-sm-r-10-f {
    padding-right: 10px !important;
  }
  .pd-sm-b-10-f {
    padding-bottom: 10px !important;
  }
  .pd-sm-l-10-f {
    padding-left: 10px !important;
  }
  .pd-sm-15 {
    padding: 15px;
  }
  .pd-sm-15-f {
    padding: 15px !important;
  }
  .pd-sm-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pd-sm-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pd-sm-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-sm-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .pd-sm-t-15 {
    padding-top: 15px;
  }
  .pd-sm-r-15 {
    padding-right: 15px;
  }
  .pd-sm-b-15 {
    padding-bottom: 15px;
  }
  .pd-sm-l-15 {
    padding-left: 15px;
  }
  .pd-sm-t-15-f {
    padding-top: 15px !important;
  }
  .pd-sm-r-15-f {
    padding-right: 15px !important;
  }
  .pd-sm-b-15-f {
    padding-bottom: 15px !important;
  }
  .pd-sm-l-15-f {
    padding-left: 15px !important;
  }
  .pd-sm-20 {
    padding: 20px;
  }
  .pd-sm-20-f {
    padding: 20px !important;
  }
  .pd-sm-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pd-sm-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pd-sm-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pd-sm-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pd-sm-t-20 {
    padding-top: 20px;
  }
  .pd-sm-r-20 {
    padding-right: 20px;
  }
  .pd-sm-b-20 {
    padding-bottom: 20px;
  }
  .pd-sm-l-20 {
    padding-left: 20px;
  }
  .pd-sm-t-20-f {
    padding-top: 20px !important;
  }
  .pd-sm-r-20-f {
    padding-right: 20px !important;
  }
  .pd-sm-b-20-f {
    padding-bottom: 20px !important;
  }
  .pd-sm-l-20-f {
    padding-left: 20px !important;
  }
  .pd-sm-25 {
    padding: 25px;
  }
  .pd-sm-25-f {
    padding: 25px !important;
  }
  .pd-sm-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .pd-sm-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pd-sm-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .pd-sm-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .pd-sm-t-25 {
    padding-top: 25px;
  }
  .pd-sm-r-25 {
    padding-right: 25px;
  }
  .pd-sm-b-25 {
    padding-bottom: 25px;
  }
  .pd-sm-l-25 {
    padding-left: 25px;
  }
  .pd-sm-t-25-f {
    padding-top: 25px !important;
  }
  .pd-sm-r-25-f {
    padding-right: 25px !important;
  }
  .pd-sm-b-25-f {
    padding-bottom: 25px !important;
  }
  .pd-sm-l-25-f {
    padding-left: 25px !important;
  }
  .pd-sm-30 {
    padding: 30px;
  }
  .pd-sm-30-f {
    padding: 30px !important;
  }
  .pd-sm-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pd-sm-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pd-sm-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-sm-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pd-sm-t-30 {
    padding-top: 30px;
  }
  .pd-sm-r-30 {
    padding-right: 30px;
  }
  .pd-sm-b-30 {
    padding-bottom: 30px;
  }
  .pd-sm-l-30 {
    padding-left: 30px;
  }
  .pd-sm-t-30-f {
    padding-top: 30px !important;
  }
  .pd-sm-r-30-f {
    padding-right: 30px !important;
  }
  .pd-sm-b-30-f {
    padding-bottom: 30px !important;
  }
  .pd-sm-l-30-f {
    padding-left: 30px !important;
  }
  .pd-sm-35 {
    padding: 35px;
  }
  .pd-sm-35-f {
    padding: 35px !important;
  }
  .pd-sm-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pd-sm-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pd-sm-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .pd-sm-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .pd-sm-t-35 {
    padding-top: 35px;
  }
  .pd-sm-r-35 {
    padding-right: 35px;
  }
  .pd-sm-b-35 {
    padding-bottom: 35px;
  }
  .pd-sm-l-35 {
    padding-left: 35px;
  }
  .pd-sm-t-35-f {
    padding-top: 35px !important;
  }
  .pd-sm-r-35-f {
    padding-right: 35px !important;
  }
  .pd-sm-b-35-f {
    padding-bottom: 35px !important;
  }
  .pd-sm-l-35-f {
    padding-left: 35px !important;
  }
  .pd-sm-40 {
    padding: 40px;
  }
  .pd-sm-40-f {
    padding: 40px !important;
  }
  .pd-sm-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pd-sm-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pd-sm-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pd-sm-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .pd-sm-t-40 {
    padding-top: 40px;
  }
  .pd-sm-r-40 {
    padding-right: 40px;
  }
  .pd-sm-b-40 {
    padding-bottom: 40px;
  }
  .pd-sm-l-40 {
    padding-left: 40px;
  }
  .pd-sm-t-40-f {
    padding-top: 40px !important;
  }
  .pd-sm-r-40-f {
    padding-right: 40px !important;
  }
  .pd-sm-b-40-f {
    padding-bottom: 40px !important;
  }
  .pd-sm-l-40-f {
    padding-left: 40px !important;
  }
  .pd-sm-45 {
    padding: 45px;
  }
  .pd-sm-45-f {
    padding: 45px !important;
  }
  .pd-sm-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .pd-sm-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pd-sm-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-sm-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .pd-sm-t-45 {
    padding-top: 45px;
  }
  .pd-sm-r-45 {
    padding-right: 45px;
  }
  .pd-sm-b-45 {
    padding-bottom: 45px;
  }
  .pd-sm-l-45 {
    padding-left: 45px;
  }
  .pd-sm-t-45-f {
    padding-top: 45px !important;
  }
  .pd-sm-r-45-f {
    padding-right: 45px !important;
  }
  .pd-sm-b-45-f {
    padding-bottom: 45px !important;
  }
  .pd-sm-l-45-f {
    padding-left: 45px !important;
  }
  .pd-sm-50 {
    padding: 50px;
  }
  .pd-sm-50-f {
    padding: 50px !important;
  }
  .pd-sm-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pd-sm-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pd-sm-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pd-sm-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .pd-sm-t-50 {
    padding-top: 50px;
  }
  .pd-sm-r-50 {
    padding-right: 50px;
  }
  .pd-sm-b-50 {
    padding-bottom: 50px;
  }
  .pd-sm-l-50 {
    padding-left: 50px;
  }
  .pd-sm-t-50-f {
    padding-top: 50px !important;
  }
  .pd-sm-r-50-f {
    padding-right: 50px !important;
  }
  .pd-sm-b-50-f {
    padding-bottom: 50px !important;
  }
  .pd-sm-l-50-f {
    padding-left: 50px !important;
  }
  .pd-sm-55 {
    padding: 55px;
  }
  .pd-sm-55-f {
    padding: 55px !important;
  }
  .pd-sm-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .pd-sm-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pd-sm-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .pd-sm-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .pd-sm-t-55 {
    padding-top: 55px;
  }
  .pd-sm-r-55 {
    padding-right: 55px;
  }
  .pd-sm-b-55 {
    padding-bottom: 55px;
  }
  .pd-sm-l-55 {
    padding-left: 55px;
  }
  .pd-sm-t-55-f {
    padding-top: 55px !important;
  }
  .pd-sm-r-55-f {
    padding-right: 55px !important;
  }
  .pd-sm-b-55-f {
    padding-bottom: 55px !important;
  }
  .pd-sm-l-55-f {
    padding-left: 55px !important;
  }
  .pd-sm-60 {
    padding: 60px;
  }
  .pd-sm-60-f {
    padding: 60px !important;
  }
  .pd-sm-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pd-sm-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pd-sm-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-sm-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .pd-sm-t-60 {
    padding-top: 60px;
  }
  .pd-sm-r-60 {
    padding-right: 60px;
  }
  .pd-sm-b-60 {
    padding-bottom: 60px;
  }
  .pd-sm-l-60 {
    padding-left: 60px;
  }
  .pd-sm-t-60-f {
    padding-top: 60px !important;
  }
  .pd-sm-r-60-f {
    padding-right: 60px !important;
  }
  .pd-sm-b-60-f {
    padding-bottom: 60px !important;
  }
  .pd-sm-l-60-f {
    padding-left: 60px !important;
  }
  .pd-sm-65 {
    padding: 65px;
  }
  .pd-sm-65-f {
    padding: 65px !important;
  }
  .pd-sm-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .pd-sm-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pd-sm-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .pd-sm-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .pd-sm-t-65 {
    padding-top: 65px;
  }
  .pd-sm-r-65 {
    padding-right: 65px;
  }
  .pd-sm-b-65 {
    padding-bottom: 65px;
  }
  .pd-sm-l-65 {
    padding-left: 65px;
  }
  .pd-sm-t-65-f {
    padding-top: 65px !important;
  }
  .pd-sm-r-65-f {
    padding-right: 65px !important;
  }
  .pd-sm-b-65-f {
    padding-bottom: 65px !important;
  }
  .pd-sm-l-65-f {
    padding-left: 65px !important;
  }
  .pd-sm-70 {
    padding: 70px;
  }
  .pd-sm-70-f {
    padding: 70px !important;
  }
  .pd-sm-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .pd-sm-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pd-sm-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pd-sm-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .pd-sm-t-70 {
    padding-top: 70px;
  }
  .pd-sm-r-70 {
    padding-right: 70px;
  }
  .pd-sm-b-70 {
    padding-bottom: 70px;
  }
  .pd-sm-l-70 {
    padding-left: 70px;
  }
  .pd-sm-t-70-f {
    padding-top: 70px !important;
  }
  .pd-sm-r-70-f {
    padding-right: 70px !important;
  }
  .pd-sm-b-70-f {
    padding-bottom: 70px !important;
  }
  .pd-sm-l-70-f {
    padding-left: 70px !important;
  }
  .pd-sm-75 {
    padding: 75px;
  }
  .pd-sm-75-f {
    padding: 75px !important;
  }
  .pd-sm-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .pd-sm-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pd-sm-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .pd-sm-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .pd-sm-t-75 {
    padding-top: 75px;
  }
  .pd-sm-r-75 {
    padding-right: 75px;
  }
  .pd-sm-b-75 {
    padding-bottom: 75px;
  }
  .pd-sm-l-75 {
    padding-left: 75px;
  }
  .pd-sm-t-75-f {
    padding-top: 75px !important;
  }
  .pd-sm-r-75-f {
    padding-right: 75px !important;
  }
  .pd-sm-b-75-f {
    padding-bottom: 75px !important;
  }
  .pd-sm-l-75-f {
    padding-left: 75px !important;
  }
  .pd-sm-80 {
    padding: 80px;
  }
  .pd-sm-80-f {
    padding: 80px !important;
  }
  .pd-sm-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pd-sm-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pd-sm-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pd-sm-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .pd-sm-t-80 {
    padding-top: 80px;
  }
  .pd-sm-r-80 {
    padding-right: 80px;
  }
  .pd-sm-b-80 {
    padding-bottom: 80px;
  }
  .pd-sm-l-80 {
    padding-left: 80px;
  }
  .pd-sm-t-80-f {
    padding-top: 80px !important;
  }
  .pd-sm-r-80-f {
    padding-right: 80px !important;
  }
  .pd-sm-b-80-f {
    padding-bottom: 80px !important;
  }
  .pd-sm-l-80-f {
    padding-left: 80px !important;
  }
  .pd-sm-85 {
    padding: 85px;
  }
  .pd-sm-85-f {
    padding: 85px !important;
  }
  .pd-sm-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .pd-sm-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pd-sm-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .pd-sm-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .pd-sm-t-85 {
    padding-top: 85px;
  }
  .pd-sm-r-85 {
    padding-right: 85px;
  }
  .pd-sm-b-85 {
    padding-bottom: 85px;
  }
  .pd-sm-l-85 {
    padding-left: 85px;
  }
  .pd-sm-t-85-f {
    padding-top: 85px !important;
  }
  .pd-sm-r-85-f {
    padding-right: 85px !important;
  }
  .pd-sm-b-85-f {
    padding-bottom: 85px !important;
  }
  .pd-sm-l-85-f {
    padding-left: 85px !important;
  }
  .pd-sm-90 {
    padding: 90px;
  }
  .pd-sm-90-f {
    padding: 90px !important;
  }
  .pd-sm-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .pd-sm-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pd-sm-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pd-sm-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .pd-sm-t-90 {
    padding-top: 90px;
  }
  .pd-sm-r-90 {
    padding-right: 90px;
  }
  .pd-sm-b-90 {
    padding-bottom: 90px;
  }
  .pd-sm-l-90 {
    padding-left: 90px;
  }
  .pd-sm-t-90-f {
    padding-top: 90px !important;
  }
  .pd-sm-r-90-f {
    padding-right: 90px !important;
  }
  .pd-sm-b-90-f {
    padding-bottom: 90px !important;
  }
  .pd-sm-l-90-f {
    padding-left: 90px !important;
  }
  .pd-sm-95 {
    padding: 95px;
  }
  .pd-sm-95-f {
    padding: 95px !important;
  }
  .pd-sm-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .pd-sm-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pd-sm-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .pd-sm-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .pd-sm-t-95 {
    padding-top: 95px;
  }
  .pd-sm-r-95 {
    padding-right: 95px;
  }
  .pd-sm-b-95 {
    padding-bottom: 95px;
  }
  .pd-sm-l-95 {
    padding-left: 95px;
  }
  .pd-sm-t-95-f {
    padding-top: 95px !important;
  }
  .pd-sm-r-95-f {
    padding-right: 95px !important;
  }
  .pd-sm-b-95-f {
    padding-bottom: 95px !important;
  }
  .pd-sm-l-95-f {
    padding-left: 95px !important;
  }
  .pd-sm-100 {
    padding: 100px;
  }
  .pd-sm-100-f {
    padding: 100px !important;
  }
  .pd-sm-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pd-sm-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pd-sm-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pd-sm-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .pd-sm-t-100 {
    padding-top: 100px;
  }
  .pd-sm-r-100 {
    padding-right: 100px;
  }
  .pd-sm-b-100 {
    padding-bottom: 100px;
  }
  .pd-sm-l-100 {
    padding-left: 100px;
  }
  .pd-sm-t-100-f {
    padding-top: 100px !important;
  }
  .pd-sm-r-100-f {
    padding-right: 100px !important;
  }
  .pd-sm-b-100-f {
    padding-bottom: 100px !important;
  }
  .pd-sm-l-100-f {
    padding-left: 100px !important;
  }
  .pd-sm-110 {
    padding: 110px;
  }
  .pd-sm-110-f {
    padding: 110px !important;
  }
  .pd-sm-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .pd-sm-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pd-sm-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pd-sm-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .pd-sm-t-110 {
    padding-top: 110px;
  }
  .pd-sm-r-110 {
    padding-right: 110px;
  }
  .pd-sm-b-110 {
    padding-bottom: 110px;
  }
  .pd-sm-l-110 {
    padding-left: 110px;
  }
  .pd-sm-t-110-f {
    padding-top: 110px !important;
  }
  .pd-sm-r-110-f {
    padding-right: 110px !important;
  }
  .pd-sm-b-110-f {
    padding-bottom: 110px !important;
  }
  .pd-sm-l-110-f {
    padding-left: 110px !important;
  }
  .pd-sm-120 {
    padding: 120px;
  }
  .pd-sm-120-f {
    padding: 120px !important;
  }
  .pd-sm-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pd-sm-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pd-sm-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pd-sm-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .pd-sm-t-120 {
    padding-top: 120px;
  }
  .pd-sm-r-120 {
    padding-right: 120px;
  }
  .pd-sm-b-120 {
    padding-bottom: 120px;
  }
  .pd-sm-l-120 {
    padding-left: 120px;
  }
  .pd-sm-t-120-f {
    padding-top: 120px !important;
  }
  .pd-sm-r-120-f {
    padding-right: 120px !important;
  }
  .pd-sm-b-120-f {
    padding-bottom: 120px !important;
  }
  .pd-sm-l-120-f {
    padding-left: 120px !important;
  }
  .pd-sm-130 {
    padding: 130px;
  }
  .pd-sm-130-f {
    padding: 130px !important;
  }
  .pd-sm-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .pd-sm-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .pd-sm-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }
  .pd-sm-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .pd-sm-t-130 {
    padding-top: 130px;
  }
  .pd-sm-r-130 {
    padding-right: 130px;
  }
  .pd-sm-b-130 {
    padding-bottom: 130px;
  }
  .pd-sm-l-130 {
    padding-left: 130px;
  }
  .pd-sm-t-130-f {
    padding-top: 130px !important;
  }
  .pd-sm-r-130-f {
    padding-right: 130px !important;
  }
  .pd-sm-b-130-f {
    padding-bottom: 130px !important;
  }
  .pd-sm-l-130-f {
    padding-left: 130px !important;
  }
  .pd-sm-140 {
    padding: 140px;
  }
  .pd-sm-140-f {
    padding: 140px !important;
  }
  .pd-sm-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .pd-sm-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .pd-sm-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .pd-sm-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .pd-sm-t-140 {
    padding-top: 140px;
  }
  .pd-sm-r-140 {
    padding-right: 140px;
  }
  .pd-sm-b-140 {
    padding-bottom: 140px;
  }
  .pd-sm-l-140 {
    padding-left: 140px;
  }
  .pd-sm-t-140-f {
    padding-top: 140px !important;
  }
  .pd-sm-r-140-f {
    padding-right: 140px !important;
  }
  .pd-sm-b-140-f {
    padding-bottom: 140px !important;
  }
  .pd-sm-l-140-f {
    padding-left: 140px !important;
  }
  .pd-sm-150 {
    padding: 150px;
  }
  .pd-sm-150-f {
    padding: 150px !important;
  }
  .pd-sm-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .pd-sm-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pd-sm-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }
  .pd-sm-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .pd-sm-t-150 {
    padding-top: 150px;
  }
  .pd-sm-r-150 {
    padding-right: 150px;
  }
  .pd-sm-b-150 {
    padding-bottom: 150px;
  }
  .pd-sm-l-150 {
    padding-left: 150px;
  }
  .pd-sm-t-150-f {
    padding-top: 150px !important;
  }
  .pd-sm-r-150-f {
    padding-right: 150px !important;
  }
  .pd-sm-b-150-f {
    padding-bottom: 150px !important;
  }
  .pd-sm-l-150-f {
    padding-left: 150px !important;
  }
  .pd-sm-160 {
    padding: 160px;
  }
  .pd-sm-160-f {
    padding: 160px !important;
  }
  .pd-sm-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .pd-sm-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .pd-sm-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }
  .pd-sm-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .pd-sm-t-160 {
    padding-top: 160px;
  }
  .pd-sm-r-160 {
    padding-right: 160px;
  }
  .pd-sm-b-160 {
    padding-bottom: 160px;
  }
  .pd-sm-l-160 {
    padding-left: 160px;
  }
  .pd-sm-t-160-f {
    padding-top: 160px !important;
  }
  .pd-sm-r-160-f {
    padding-right: 160px !important;
  }
  .pd-sm-b-160-f {
    padding-bottom: 160px !important;
  }
  .pd-sm-l-160-f {
    padding-left: 160px !important;
  }
  .pd-sm-170 {
    padding: 170px;
  }
  .pd-sm-170-f {
    padding: 170px !important;
  }
  .pd-sm-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }
  .pd-sm-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }
  .pd-sm-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }
  .pd-sm-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
  .pd-sm-t-170 {
    padding-top: 170px;
  }
  .pd-sm-r-170 {
    padding-right: 170px;
  }
  .pd-sm-b-170 {
    padding-bottom: 170px;
  }
  .pd-sm-l-170 {
    padding-left: 170px;
  }
  .pd-sm-t-170-f {
    padding-top: 170px !important;
  }
  .pd-sm-r-170-f {
    padding-right: 170px !important;
  }
  .pd-sm-b-170-f {
    padding-bottom: 170px !important;
  }
  .pd-sm-l-170-f {
    padding-left: 170px !important;
  }
  .pd-sm-180 {
    padding: 180px;
  }
  .pd-sm-180-f {
    padding: 180px !important;
  }
  .pd-sm-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .pd-sm-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .pd-sm-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }
  .pd-sm-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .pd-sm-t-180 {
    padding-top: 180px;
  }
  .pd-sm-r-180 {
    padding-right: 180px;
  }
  .pd-sm-b-180 {
    padding-bottom: 180px;
  }
  .pd-sm-l-180 {
    padding-left: 180px;
  }
  .pd-sm-t-180-f {
    padding-top: 180px !important;
  }
  .pd-sm-r-180-f {
    padding-right: 180px !important;
  }
  .pd-sm-b-180-f {
    padding-bottom: 180px !important;
  }
  .pd-sm-l-180-f {
    padding-left: 180px !important;
  }
  .pd-sm-190 {
    padding: 190px;
  }
  .pd-sm-190-f {
    padding: 190px !important;
  }
  .pd-sm-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }
  .pd-sm-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }
  .pd-sm-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }
  .pd-sm-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
  .pd-sm-t-190 {
    padding-top: 190px;
  }
  .pd-sm-r-190 {
    padding-right: 190px;
  }
  .pd-sm-b-190 {
    padding-bottom: 190px;
  }
  .pd-sm-l-190 {
    padding-left: 190px;
  }
  .pd-sm-t-190-f {
    padding-top: 190px !important;
  }
  .pd-sm-r-190-f {
    padding-right: 190px !important;
  }
  .pd-sm-b-190-f {
    padding-bottom: 190px !important;
  }
  .pd-sm-l-190-f {
    padding-left: 190px !important;
  }
  .pd-sm-200 {
    padding: 200px;
  }
  .pd-sm-200-f {
    padding: 200px !important;
  }
  .pd-sm-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .pd-sm-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .pd-sm-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .pd-sm-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .pd-sm-t-200 {
    padding-top: 200px;
  }
  .pd-sm-r-200 {
    padding-right: 200px;
  }
  .pd-sm-b-200 {
    padding-bottom: 200px;
  }
  .pd-sm-l-200 {
    padding-left: 200px;
  }
  .pd-sm-t-200-f {
    padding-top: 200px !important;
  }
  .pd-sm-r-200-f {
    padding-right: 200px !important;
  }
  .pd-sm-b-200-f {
    padding-bottom: 200px !important;
  }
  .pd-sm-l-200-f {
    padding-left: 200px !important;
  }
}

@media (min-width: 768px) {
  .pd-md-0 {
    padding: 0px;
  }
  .pd-md-0-f {
    padding: 0px !important;
  }
  .pd-md-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pd-md-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pd-md-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-md-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pd-md-t-0 {
    padding-top: 0px;
  }
  .pd-md-r-0 {
    padding-right: 0px;
  }
  .pd-md-b-0 {
    padding-bottom: 0px;
  }
  .pd-md-l-0 {
    padding-left: 0px;
  }
  .pd-md-t-0-f {
    padding-top: 0px !important;
  }
  .pd-md-r-0-f {
    padding-right: 0px !important;
  }
  .pd-md-b-0-f {
    padding-bottom: 0px !important;
  }
  .pd-md-l-0-f {
    padding-left: 0px !important;
  }
  .pd-md-1 {
    padding: 1px;
  }
  .pd-md-1-f {
    padding: 1px !important;
  }
  .pd-md-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .pd-md-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pd-md-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .pd-md-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .pd-md-t-1 {
    padding-top: 1px;
  }
  .pd-md-r-1 {
    padding-right: 1px;
  }
  .pd-md-b-1 {
    padding-bottom: 1px;
  }
  .pd-md-l-1 {
    padding-left: 1px;
  }
  .pd-md-t-1-f {
    padding-top: 1px !important;
  }
  .pd-md-r-1-f {
    padding-right: 1px !important;
  }
  .pd-md-b-1-f {
    padding-bottom: 1px !important;
  }
  .pd-md-l-1-f {
    padding-left: 1px !important;
  }
  .pd-md-2 {
    padding: 2px;
  }
  .pd-md-2-f {
    padding: 2px !important;
  }
  .pd-md-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .pd-md-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .pd-md-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pd-md-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .pd-md-t-2 {
    padding-top: 2px;
  }
  .pd-md-r-2 {
    padding-right: 2px;
  }
  .pd-md-b-2 {
    padding-bottom: 2px;
  }
  .pd-md-l-2 {
    padding-left: 2px;
  }
  .pd-md-t-2-f {
    padding-top: 2px !important;
  }
  .pd-md-r-2-f {
    padding-right: 2px !important;
  }
  .pd-md-b-2-f {
    padding-bottom: 2px !important;
  }
  .pd-md-l-2-f {
    padding-left: 2px !important;
  }
  .pd-md-3 {
    padding: 3px;
  }
  .pd-md-3-f {
    padding: 3px !important;
  }
  .pd-md-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .pd-md-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .pd-md-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .pd-md-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .pd-md-t-3 {
    padding-top: 3px;
  }
  .pd-md-r-3 {
    padding-right: 3px;
  }
  .pd-md-b-3 {
    padding-bottom: 3px;
  }
  .pd-md-l-3 {
    padding-left: 3px;
  }
  .pd-md-t-3-f {
    padding-top: 3px !important;
  }
  .pd-md-r-3-f {
    padding-right: 3px !important;
  }
  .pd-md-b-3-f {
    padding-bottom: 3px !important;
  }
  .pd-md-l-3-f {
    padding-left: 3px !important;
  }
  .pd-md-4 {
    padding: 4px;
  }
  .pd-md-4-f {
    padding: 4px !important;
  }
  .pd-md-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pd-md-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .pd-md-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pd-md-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pd-md-t-4 {
    padding-top: 4px;
  }
  .pd-md-r-4 {
    padding-right: 4px;
  }
  .pd-md-b-4 {
    padding-bottom: 4px;
  }
  .pd-md-l-4 {
    padding-left: 4px;
  }
  .pd-md-t-4-f {
    padding-top: 4px !important;
  }
  .pd-md-r-4-f {
    padding-right: 4px !important;
  }
  .pd-md-b-4-f {
    padding-bottom: 4px !important;
  }
  .pd-md-l-4-f {
    padding-left: 4px !important;
  }
  .pd-md-5 {
    padding: 5px;
  }
  .pd-md-5-f {
    padding: 5px !important;
  }
  .pd-md-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pd-md-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pd-md-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-md-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .pd-md-t-5 {
    padding-top: 5px;
  }
  .pd-md-r-5 {
    padding-right: 5px;
  }
  .pd-md-b-5 {
    padding-bottom: 5px;
  }
  .pd-md-l-5 {
    padding-left: 5px;
  }
  .pd-md-t-5-f {
    padding-top: 5px !important;
  }
  .pd-md-r-5-f {
    padding-right: 5px !important;
  }
  .pd-md-b-5-f {
    padding-bottom: 5px !important;
  }
  .pd-md-l-5-f {
    padding-left: 5px !important;
  }
  .pd-md-6 {
    padding: 6px;
  }
  .pd-md-6-f {
    padding: 6px !important;
  }
  .pd-md-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .pd-md-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .pd-md-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pd-md-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .pd-md-t-6 {
    padding-top: 6px;
  }
  .pd-md-r-6 {
    padding-right: 6px;
  }
  .pd-md-b-6 {
    padding-bottom: 6px;
  }
  .pd-md-l-6 {
    padding-left: 6px;
  }
  .pd-md-t-6-f {
    padding-top: 6px !important;
  }
  .pd-md-r-6-f {
    padding-right: 6px !important;
  }
  .pd-md-b-6-f {
    padding-bottom: 6px !important;
  }
  .pd-md-l-6-f {
    padding-left: 6px !important;
  }
  .pd-md-7 {
    padding: 7px;
  }
  .pd-md-7-f {
    padding: 7px !important;
  }
  .pd-md-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .pd-md-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .pd-md-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pd-md-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .pd-md-t-7 {
    padding-top: 7px;
  }
  .pd-md-r-7 {
    padding-right: 7px;
  }
  .pd-md-b-7 {
    padding-bottom: 7px;
  }
  .pd-md-l-7 {
    padding-left: 7px;
  }
  .pd-md-t-7-f {
    padding-top: 7px !important;
  }
  .pd-md-r-7-f {
    padding-right: 7px !important;
  }
  .pd-md-b-7-f {
    padding-bottom: 7px !important;
  }
  .pd-md-l-7-f {
    padding-left: 7px !important;
  }
  .pd-md-8 {
    padding: 8px;
  }
  .pd-md-8-f {
    padding: 8px !important;
  }
  .pd-md-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pd-md-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pd-md-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pd-md-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pd-md-t-8 {
    padding-top: 8px;
  }
  .pd-md-r-8 {
    padding-right: 8px;
  }
  .pd-md-b-8 {
    padding-bottom: 8px;
  }
  .pd-md-l-8 {
    padding-left: 8px;
  }
  .pd-md-t-8-f {
    padding-top: 8px !important;
  }
  .pd-md-r-8-f {
    padding-right: 8px !important;
  }
  .pd-md-b-8-f {
    padding-bottom: 8px !important;
  }
  .pd-md-l-8-f {
    padding-left: 8px !important;
  }
  .pd-md-9 {
    padding: 9px;
  }
  .pd-md-9-f {
    padding: 9px !important;
  }
  .pd-md-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .pd-md-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
  .pd-md-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }
  .pd-md-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
  .pd-md-t-9 {
    padding-top: 9px;
  }
  .pd-md-r-9 {
    padding-right: 9px;
  }
  .pd-md-b-9 {
    padding-bottom: 9px;
  }
  .pd-md-l-9 {
    padding-left: 9px;
  }
  .pd-md-t-9-f {
    padding-top: 9px !important;
  }
  .pd-md-r-9-f {
    padding-right: 9px !important;
  }
  .pd-md-b-9-f {
    padding-bottom: 9px !important;
  }
  .pd-md-l-9-f {
    padding-left: 9px !important;
  }
  .pd-md-10 {
    padding: 10px;
  }
  .pd-md-10-f {
    padding: 10px !important;
  }
  .pd-md-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pd-md-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pd-md-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-md-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pd-md-t-10 {
    padding-top: 10px;
  }
  .pd-md-r-10 {
    padding-right: 10px;
  }
  .pd-md-b-10 {
    padding-bottom: 10px;
  }
  .pd-md-l-10 {
    padding-left: 10px;
  }
  .pd-md-t-10-f {
    padding-top: 10px !important;
  }
  .pd-md-r-10-f {
    padding-right: 10px !important;
  }
  .pd-md-b-10-f {
    padding-bottom: 10px !important;
  }
  .pd-md-l-10-f {
    padding-left: 10px !important;
  }
  .pd-md-15 {
    padding: 15px;
  }
  .pd-md-15-f {
    padding: 15px !important;
  }
  .pd-md-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pd-md-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pd-md-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-md-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .pd-md-t-15 {
    padding-top: 15px;
  }
  .pd-md-r-15 {
    padding-right: 15px;
  }
  .pd-md-b-15 {
    padding-bottom: 15px;
  }
  .pd-md-l-15 {
    padding-left: 15px;
  }
  .pd-md-t-15-f {
    padding-top: 15px !important;
  }
  .pd-md-r-15-f {
    padding-right: 15px !important;
  }
  .pd-md-b-15-f {
    padding-bottom: 15px !important;
  }
  .pd-md-l-15-f {
    padding-left: 15px !important;
  }
  .pd-md-20 {
    padding: 20px;
  }
  .pd-md-20-f {
    padding: 20px !important;
  }
  .pd-md-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pd-md-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pd-md-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pd-md-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pd-md-t-20 {
    padding-top: 20px;
  }
  .pd-md-r-20 {
    padding-right: 20px;
  }
  .pd-md-b-20 {
    padding-bottom: 20px;
  }
  .pd-md-l-20 {
    padding-left: 20px;
  }
  .pd-md-t-20-f {
    padding-top: 20px !important;
  }
  .pd-md-r-20-f {
    padding-right: 20px !important;
  }
  .pd-md-b-20-f {
    padding-bottom: 20px !important;
  }
  .pd-md-l-20-f {
    padding-left: 20px !important;
  }
  .pd-md-25 {
    padding: 25px;
  }
  .pd-md-25-f {
    padding: 25px !important;
  }
  .pd-md-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .pd-md-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pd-md-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .pd-md-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .pd-md-t-25 {
    padding-top: 25px;
  }
  .pd-md-r-25 {
    padding-right: 25px;
  }
  .pd-md-b-25 {
    padding-bottom: 25px;
  }
  .pd-md-l-25 {
    padding-left: 25px;
  }
  .pd-md-t-25-f {
    padding-top: 25px !important;
  }
  .pd-md-r-25-f {
    padding-right: 25px !important;
  }
  .pd-md-b-25-f {
    padding-bottom: 25px !important;
  }
  .pd-md-l-25-f {
    padding-left: 25px !important;
  }
  .pd-md-30 {
    padding: 30px;
  }
  .pd-md-30-f {
    padding: 30px !important;
  }
  .pd-md-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pd-md-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pd-md-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-md-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pd-md-t-30 {
    padding-top: 30px;
  }
  .pd-md-r-30 {
    padding-right: 30px;
  }
  .pd-md-b-30 {
    padding-bottom: 30px;
  }
  .pd-md-l-30 {
    padding-left: 30px;
  }
  .pd-md-t-30-f {
    padding-top: 30px !important;
  }
  .pd-md-r-30-f {
    padding-right: 30px !important;
  }
  .pd-md-b-30-f {
    padding-bottom: 30px !important;
  }
  .pd-md-l-30-f {
    padding-left: 30px !important;
  }
  .pd-md-35 {
    padding: 35px;
  }
  .pd-md-35-f {
    padding: 35px !important;
  }
  .pd-md-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pd-md-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pd-md-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .pd-md-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .pd-md-t-35 {
    padding-top: 35px;
  }
  .pd-md-r-35 {
    padding-right: 35px;
  }
  .pd-md-b-35 {
    padding-bottom: 35px;
  }
  .pd-md-l-35 {
    padding-left: 35px;
  }
  .pd-md-t-35-f {
    padding-top: 35px !important;
  }
  .pd-md-r-35-f {
    padding-right: 35px !important;
  }
  .pd-md-b-35-f {
    padding-bottom: 35px !important;
  }
  .pd-md-l-35-f {
    padding-left: 35px !important;
  }
  .pd-md-40 {
    padding: 40px;
  }
  .pd-md-40-f {
    padding: 40px !important;
  }
  .pd-md-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pd-md-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pd-md-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pd-md-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .pd-md-t-40 {
    padding-top: 40px;
  }
  .pd-md-r-40 {
    padding-right: 40px;
  }
  .pd-md-b-40 {
    padding-bottom: 40px;
  }
  .pd-md-l-40 {
    padding-left: 40px;
  }
  .pd-md-t-40-f {
    padding-top: 40px !important;
  }
  .pd-md-r-40-f {
    padding-right: 40px !important;
  }
  .pd-md-b-40-f {
    padding-bottom: 40px !important;
  }
  .pd-md-l-40-f {
    padding-left: 40px !important;
  }
  .pd-md-45 {
    padding: 45px;
  }
  .pd-md-45-f {
    padding: 45px !important;
  }
  .pd-md-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .pd-md-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pd-md-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-md-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .pd-md-t-45 {
    padding-top: 45px;
  }
  .pd-md-r-45 {
    padding-right: 45px;
  }
  .pd-md-b-45 {
    padding-bottom: 45px;
  }
  .pd-md-l-45 {
    padding-left: 45px;
  }
  .pd-md-t-45-f {
    padding-top: 45px !important;
  }
  .pd-md-r-45-f {
    padding-right: 45px !important;
  }
  .pd-md-b-45-f {
    padding-bottom: 45px !important;
  }
  .pd-md-l-45-f {
    padding-left: 45px !important;
  }
  .pd-md-50 {
    padding: 50px;
  }
  .pd-md-50-f {
    padding: 50px !important;
  }
  .pd-md-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pd-md-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pd-md-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pd-md-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .pd-md-t-50 {
    padding-top: 50px;
  }
  .pd-md-r-50 {
    padding-right: 50px;
  }
  .pd-md-b-50 {
    padding-bottom: 50px;
  }
  .pd-md-l-50 {
    padding-left: 50px;
  }
  .pd-md-t-50-f {
    padding-top: 50px !important;
  }
  .pd-md-r-50-f {
    padding-right: 50px !important;
  }
  .pd-md-b-50-f {
    padding-bottom: 50px !important;
  }
  .pd-md-l-50-f {
    padding-left: 50px !important;
  }
  .pd-md-55 {
    padding: 55px;
  }
  .pd-md-55-f {
    padding: 55px !important;
  }
  .pd-md-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .pd-md-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pd-md-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .pd-md-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .pd-md-t-55 {
    padding-top: 55px;
  }
  .pd-md-r-55 {
    padding-right: 55px;
  }
  .pd-md-b-55 {
    padding-bottom: 55px;
  }
  .pd-md-l-55 {
    padding-left: 55px;
  }
  .pd-md-t-55-f {
    padding-top: 55px !important;
  }
  .pd-md-r-55-f {
    padding-right: 55px !important;
  }
  .pd-md-b-55-f {
    padding-bottom: 55px !important;
  }
  .pd-md-l-55-f {
    padding-left: 55px !important;
  }
  .pd-md-60 {
    padding: 60px;
  }
  .pd-md-60-f {
    padding: 60px !important;
  }
  .pd-md-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pd-md-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pd-md-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-md-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .pd-md-t-60 {
    padding-top: 60px;
  }
  .pd-md-r-60 {
    padding-right: 60px;
  }
  .pd-md-b-60 {
    padding-bottom: 60px;
  }
  .pd-md-l-60 {
    padding-left: 60px;
  }
  .pd-md-t-60-f {
    padding-top: 60px !important;
  }
  .pd-md-r-60-f {
    padding-right: 60px !important;
  }
  .pd-md-b-60-f {
    padding-bottom: 60px !important;
  }
  .pd-md-l-60-f {
    padding-left: 60px !important;
  }
  .pd-md-65 {
    padding: 65px;
  }
  .pd-md-65-f {
    padding: 65px !important;
  }
  .pd-md-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .pd-md-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pd-md-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .pd-md-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .pd-md-t-65 {
    padding-top: 65px;
  }
  .pd-md-r-65 {
    padding-right: 65px;
  }
  .pd-md-b-65 {
    padding-bottom: 65px;
  }
  .pd-md-l-65 {
    padding-left: 65px;
  }
  .pd-md-t-65-f {
    padding-top: 65px !important;
  }
  .pd-md-r-65-f {
    padding-right: 65px !important;
  }
  .pd-md-b-65-f {
    padding-bottom: 65px !important;
  }
  .pd-md-l-65-f {
    padding-left: 65px !important;
  }
  .pd-md-70 {
    padding: 70px;
  }
  .pd-md-70-f {
    padding: 70px !important;
  }
  .pd-md-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .pd-md-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pd-md-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pd-md-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .pd-md-t-70 {
    padding-top: 70px;
  }
  .pd-md-r-70 {
    padding-right: 70px;
  }
  .pd-md-b-70 {
    padding-bottom: 70px;
  }
  .pd-md-l-70 {
    padding-left: 70px;
  }
  .pd-md-t-70-f {
    padding-top: 70px !important;
  }
  .pd-md-r-70-f {
    padding-right: 70px !important;
  }
  .pd-md-b-70-f {
    padding-bottom: 70px !important;
  }
  .pd-md-l-70-f {
    padding-left: 70px !important;
  }
  .pd-md-75 {
    padding: 75px;
  }
  .pd-md-75-f {
    padding: 75px !important;
  }
  .pd-md-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .pd-md-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pd-md-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .pd-md-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .pd-md-t-75 {
    padding-top: 75px;
  }
  .pd-md-r-75 {
    padding-right: 75px;
  }
  .pd-md-b-75 {
    padding-bottom: 75px;
  }
  .pd-md-l-75 {
    padding-left: 75px;
  }
  .pd-md-t-75-f {
    padding-top: 75px !important;
  }
  .pd-md-r-75-f {
    padding-right: 75px !important;
  }
  .pd-md-b-75-f {
    padding-bottom: 75px !important;
  }
  .pd-md-l-75-f {
    padding-left: 75px !important;
  }
  .pd-md-80 {
    padding: 80px;
  }
  .pd-md-80-f {
    padding: 80px !important;
  }
  .pd-md-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pd-md-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pd-md-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pd-md-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .pd-md-t-80 {
    padding-top: 80px;
  }
  .pd-md-r-80 {
    padding-right: 80px;
  }
  .pd-md-b-80 {
    padding-bottom: 80px;
  }
  .pd-md-l-80 {
    padding-left: 80px;
  }
  .pd-md-t-80-f {
    padding-top: 80px !important;
  }
  .pd-md-r-80-f {
    padding-right: 80px !important;
  }
  .pd-md-b-80-f {
    padding-bottom: 80px !important;
  }
  .pd-md-l-80-f {
    padding-left: 80px !important;
  }
  .pd-md-85 {
    padding: 85px;
  }
  .pd-md-85-f {
    padding: 85px !important;
  }
  .pd-md-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .pd-md-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pd-md-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .pd-md-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .pd-md-t-85 {
    padding-top: 85px;
  }
  .pd-md-r-85 {
    padding-right: 85px;
  }
  .pd-md-b-85 {
    padding-bottom: 85px;
  }
  .pd-md-l-85 {
    padding-left: 85px;
  }
  .pd-md-t-85-f {
    padding-top: 85px !important;
  }
  .pd-md-r-85-f {
    padding-right: 85px !important;
  }
  .pd-md-b-85-f {
    padding-bottom: 85px !important;
  }
  .pd-md-l-85-f {
    padding-left: 85px !important;
  }
  .pd-md-90 {
    padding: 90px;
  }
  .pd-md-90-f {
    padding: 90px !important;
  }
  .pd-md-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .pd-md-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pd-md-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pd-md-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .pd-md-t-90 {
    padding-top: 90px;
  }
  .pd-md-r-90 {
    padding-right: 90px;
  }
  .pd-md-b-90 {
    padding-bottom: 90px;
  }
  .pd-md-l-90 {
    padding-left: 90px;
  }
  .pd-md-t-90-f {
    padding-top: 90px !important;
  }
  .pd-md-r-90-f {
    padding-right: 90px !important;
  }
  .pd-md-b-90-f {
    padding-bottom: 90px !important;
  }
  .pd-md-l-90-f {
    padding-left: 90px !important;
  }
  .pd-md-95 {
    padding: 95px;
  }
  .pd-md-95-f {
    padding: 95px !important;
  }
  .pd-md-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .pd-md-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pd-md-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .pd-md-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .pd-md-t-95 {
    padding-top: 95px;
  }
  .pd-md-r-95 {
    padding-right: 95px;
  }
  .pd-md-b-95 {
    padding-bottom: 95px;
  }
  .pd-md-l-95 {
    padding-left: 95px;
  }
  .pd-md-t-95-f {
    padding-top: 95px !important;
  }
  .pd-md-r-95-f {
    padding-right: 95px !important;
  }
  .pd-md-b-95-f {
    padding-bottom: 95px !important;
  }
  .pd-md-l-95-f {
    padding-left: 95px !important;
  }
  .pd-md-100 {
    padding: 100px;
  }
  .pd-md-100-f {
    padding: 100px !important;
  }
  .pd-md-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pd-md-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pd-md-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pd-md-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .pd-md-t-100 {
    padding-top: 100px;
  }
  .pd-md-r-100 {
    padding-right: 100px;
  }
  .pd-md-b-100 {
    padding-bottom: 100px;
  }
  .pd-md-l-100 {
    padding-left: 100px;
  }
  .pd-md-t-100-f {
    padding-top: 100px !important;
  }
  .pd-md-r-100-f {
    padding-right: 100px !important;
  }
  .pd-md-b-100-f {
    padding-bottom: 100px !important;
  }
  .pd-md-l-100-f {
    padding-left: 100px !important;
  }
  .pd-md-110 {
    padding: 110px;
  }
  .pd-md-110-f {
    padding: 110px !important;
  }
  .pd-md-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .pd-md-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pd-md-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pd-md-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .pd-md-t-110 {
    padding-top: 110px;
  }
  .pd-md-r-110 {
    padding-right: 110px;
  }
  .pd-md-b-110 {
    padding-bottom: 110px;
  }
  .pd-md-l-110 {
    padding-left: 110px;
  }
  .pd-md-t-110-f {
    padding-top: 110px !important;
  }
  .pd-md-r-110-f {
    padding-right: 110px !important;
  }
  .pd-md-b-110-f {
    padding-bottom: 110px !important;
  }
  .pd-md-l-110-f {
    padding-left: 110px !important;
  }
  .pd-md-120 {
    padding: 120px;
  }
  .pd-md-120-f {
    padding: 120px !important;
  }
  .pd-md-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pd-md-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pd-md-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pd-md-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .pd-md-t-120 {
    padding-top: 120px;
  }
  .pd-md-r-120 {
    padding-right: 120px;
  }
  .pd-md-b-120 {
    padding-bottom: 120px;
  }
  .pd-md-l-120 {
    padding-left: 120px;
  }
  .pd-md-t-120-f {
    padding-top: 120px !important;
  }
  .pd-md-r-120-f {
    padding-right: 120px !important;
  }
  .pd-md-b-120-f {
    padding-bottom: 120px !important;
  }
  .pd-md-l-120-f {
    padding-left: 120px !important;
  }
  .pd-md-130 {
    padding: 130px;
  }
  .pd-md-130-f {
    padding: 130px !important;
  }
  .pd-md-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .pd-md-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .pd-md-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }
  .pd-md-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .pd-md-t-130 {
    padding-top: 130px;
  }
  .pd-md-r-130 {
    padding-right: 130px;
  }
  .pd-md-b-130 {
    padding-bottom: 130px;
  }
  .pd-md-l-130 {
    padding-left: 130px;
  }
  .pd-md-t-130-f {
    padding-top: 130px !important;
  }
  .pd-md-r-130-f {
    padding-right: 130px !important;
  }
  .pd-md-b-130-f {
    padding-bottom: 130px !important;
  }
  .pd-md-l-130-f {
    padding-left: 130px !important;
  }
  .pd-md-140 {
    padding: 140px;
  }
  .pd-md-140-f {
    padding: 140px !important;
  }
  .pd-md-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .pd-md-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .pd-md-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .pd-md-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .pd-md-t-140 {
    padding-top: 140px;
  }
  .pd-md-r-140 {
    padding-right: 140px;
  }
  .pd-md-b-140 {
    padding-bottom: 140px;
  }
  .pd-md-l-140 {
    padding-left: 140px;
  }
  .pd-md-t-140-f {
    padding-top: 140px !important;
  }
  .pd-md-r-140-f {
    padding-right: 140px !important;
  }
  .pd-md-b-140-f {
    padding-bottom: 140px !important;
  }
  .pd-md-l-140-f {
    padding-left: 140px !important;
  }
  .pd-md-150 {
    padding: 150px;
  }
  .pd-md-150-f {
    padding: 150px !important;
  }
  .pd-md-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .pd-md-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pd-md-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }
  .pd-md-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .pd-md-t-150 {
    padding-top: 150px;
  }
  .pd-md-r-150 {
    padding-right: 150px;
  }
  .pd-md-b-150 {
    padding-bottom: 150px;
  }
  .pd-md-l-150 {
    padding-left: 150px;
  }
  .pd-md-t-150-f {
    padding-top: 150px !important;
  }
  .pd-md-r-150-f {
    padding-right: 150px !important;
  }
  .pd-md-b-150-f {
    padding-bottom: 150px !important;
  }
  .pd-md-l-150-f {
    padding-left: 150px !important;
  }
  .pd-md-160 {
    padding: 160px;
  }
  .pd-md-160-f {
    padding: 160px !important;
  }
  .pd-md-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .pd-md-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .pd-md-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }
  .pd-md-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .pd-md-t-160 {
    padding-top: 160px;
  }
  .pd-md-r-160 {
    padding-right: 160px;
  }
  .pd-md-b-160 {
    padding-bottom: 160px;
  }
  .pd-md-l-160 {
    padding-left: 160px;
  }
  .pd-md-t-160-f {
    padding-top: 160px !important;
  }
  .pd-md-r-160-f {
    padding-right: 160px !important;
  }
  .pd-md-b-160-f {
    padding-bottom: 160px !important;
  }
  .pd-md-l-160-f {
    padding-left: 160px !important;
  }
  .pd-md-170 {
    padding: 170px;
  }
  .pd-md-170-f {
    padding: 170px !important;
  }
  .pd-md-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }
  .pd-md-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }
  .pd-md-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }
  .pd-md-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
  .pd-md-t-170 {
    padding-top: 170px;
  }
  .pd-md-r-170 {
    padding-right: 170px;
  }
  .pd-md-b-170 {
    padding-bottom: 170px;
  }
  .pd-md-l-170 {
    padding-left: 170px;
  }
  .pd-md-t-170-f {
    padding-top: 170px !important;
  }
  .pd-md-r-170-f {
    padding-right: 170px !important;
  }
  .pd-md-b-170-f {
    padding-bottom: 170px !important;
  }
  .pd-md-l-170-f {
    padding-left: 170px !important;
  }
  .pd-md-180 {
    padding: 180px;
  }
  .pd-md-180-f {
    padding: 180px !important;
  }
  .pd-md-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .pd-md-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .pd-md-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }
  .pd-md-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .pd-md-t-180 {
    padding-top: 180px;
  }
  .pd-md-r-180 {
    padding-right: 180px;
  }
  .pd-md-b-180 {
    padding-bottom: 180px;
  }
  .pd-md-l-180 {
    padding-left: 180px;
  }
  .pd-md-t-180-f {
    padding-top: 180px !important;
  }
  .pd-md-r-180-f {
    padding-right: 180px !important;
  }
  .pd-md-b-180-f {
    padding-bottom: 180px !important;
  }
  .pd-md-l-180-f {
    padding-left: 180px !important;
  }
  .pd-md-190 {
    padding: 190px;
  }
  .pd-md-190-f {
    padding: 190px !important;
  }
  .pd-md-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }
  .pd-md-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }
  .pd-md-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }
  .pd-md-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
  .pd-md-t-190 {
    padding-top: 190px;
  }
  .pd-md-r-190 {
    padding-right: 190px;
  }
  .pd-md-b-190 {
    padding-bottom: 190px;
  }
  .pd-md-l-190 {
    padding-left: 190px;
  }
  .pd-md-t-190-f {
    padding-top: 190px !important;
  }
  .pd-md-r-190-f {
    padding-right: 190px !important;
  }
  .pd-md-b-190-f {
    padding-bottom: 190px !important;
  }
  .pd-md-l-190-f {
    padding-left: 190px !important;
  }
  .pd-md-200 {
    padding: 200px;
  }
  .pd-md-200-f {
    padding: 200px !important;
  }
  .pd-md-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .pd-md-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .pd-md-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .pd-md-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .pd-md-t-200 {
    padding-top: 200px;
  }
  .pd-md-r-200 {
    padding-right: 200px;
  }
  .pd-md-b-200 {
    padding-bottom: 200px;
  }
  .pd-md-l-200 {
    padding-left: 200px;
  }
  .pd-md-t-200-f {
    padding-top: 200px !important;
  }
  .pd-md-r-200-f {
    padding-right: 200px !important;
  }
  .pd-md-b-200-f {
    padding-bottom: 200px !important;
  }
  .pd-md-l-200-f {
    padding-left: 200px !important;
  }
}

@media (min-width: 992px) {
  .pd-lg-0 {
    padding: 0px;
  }
  .pd-lg-0-f {
    padding: 0px !important;
  }
  .pd-lg-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pd-lg-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pd-lg-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-lg-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pd-lg-t-0 {
    padding-top: 0px;
  }
  .pd-lg-r-0 {
    padding-right: 0px;
  }
  .pd-lg-b-0 {
    padding-bottom: 0px;
  }
  .pd-lg-l-0 {
    padding-left: 0px;
  }
  .pd-lg-t-0-f {
    padding-top: 0px !important;
  }
  .pd-lg-r-0-f {
    padding-right: 0px !important;
  }
  .pd-lg-b-0-f {
    padding-bottom: 0px !important;
  }
  .pd-lg-l-0-f {
    padding-left: 0px !important;
  }
  .pd-lg-1 {
    padding: 1px;
  }
  .pd-lg-1-f {
    padding: 1px !important;
  }
  .pd-lg-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .pd-lg-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pd-lg-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .pd-lg-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .pd-lg-t-1 {
    padding-top: 1px;
  }
  .pd-lg-r-1 {
    padding-right: 1px;
  }
  .pd-lg-b-1 {
    padding-bottom: 1px;
  }
  .pd-lg-l-1 {
    padding-left: 1px;
  }
  .pd-lg-t-1-f {
    padding-top: 1px !important;
  }
  .pd-lg-r-1-f {
    padding-right: 1px !important;
  }
  .pd-lg-b-1-f {
    padding-bottom: 1px !important;
  }
  .pd-lg-l-1-f {
    padding-left: 1px !important;
  }
  .pd-lg-2 {
    padding: 2px;
  }
  .pd-lg-2-f {
    padding: 2px !important;
  }
  .pd-lg-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .pd-lg-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .pd-lg-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pd-lg-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .pd-lg-t-2 {
    padding-top: 2px;
  }
  .pd-lg-r-2 {
    padding-right: 2px;
  }
  .pd-lg-b-2 {
    padding-bottom: 2px;
  }
  .pd-lg-l-2 {
    padding-left: 2px;
  }
  .pd-lg-t-2-f {
    padding-top: 2px !important;
  }
  .pd-lg-r-2-f {
    padding-right: 2px !important;
  }
  .pd-lg-b-2-f {
    padding-bottom: 2px !important;
  }
  .pd-lg-l-2-f {
    padding-left: 2px !important;
  }
  .pd-lg-3 {
    padding: 3px;
  }
  .pd-lg-3-f {
    padding: 3px !important;
  }
  .pd-lg-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .pd-lg-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .pd-lg-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .pd-lg-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .pd-lg-t-3 {
    padding-top: 3px;
  }
  .pd-lg-r-3 {
    padding-right: 3px;
  }
  .pd-lg-b-3 {
    padding-bottom: 3px;
  }
  .pd-lg-l-3 {
    padding-left: 3px;
  }
  .pd-lg-t-3-f {
    padding-top: 3px !important;
  }
  .pd-lg-r-3-f {
    padding-right: 3px !important;
  }
  .pd-lg-b-3-f {
    padding-bottom: 3px !important;
  }
  .pd-lg-l-3-f {
    padding-left: 3px !important;
  }
  .pd-lg-4 {
    padding: 4px;
  }
  .pd-lg-4-f {
    padding: 4px !important;
  }
  .pd-lg-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pd-lg-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .pd-lg-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pd-lg-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pd-lg-t-4 {
    padding-top: 4px;
  }
  .pd-lg-r-4 {
    padding-right: 4px;
  }
  .pd-lg-b-4 {
    padding-bottom: 4px;
  }
  .pd-lg-l-4 {
    padding-left: 4px;
  }
  .pd-lg-t-4-f {
    padding-top: 4px !important;
  }
  .pd-lg-r-4-f {
    padding-right: 4px !important;
  }
  .pd-lg-b-4-f {
    padding-bottom: 4px !important;
  }
  .pd-lg-l-4-f {
    padding-left: 4px !important;
  }
  .pd-lg-5 {
    padding: 5px;
  }
  .pd-lg-5-f {
    padding: 5px !important;
  }
  .pd-lg-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pd-lg-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pd-lg-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-lg-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .pd-lg-t-5 {
    padding-top: 5px;
  }
  .pd-lg-r-5 {
    padding-right: 5px;
  }
  .pd-lg-b-5 {
    padding-bottom: 5px;
  }
  .pd-lg-l-5 {
    padding-left: 5px;
  }
  .pd-lg-t-5-f {
    padding-top: 5px !important;
  }
  .pd-lg-r-5-f {
    padding-right: 5px !important;
  }
  .pd-lg-b-5-f {
    padding-bottom: 5px !important;
  }
  .pd-lg-l-5-f {
    padding-left: 5px !important;
  }
  .pd-lg-6 {
    padding: 6px;
  }
  .pd-lg-6-f {
    padding: 6px !important;
  }
  .pd-lg-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .pd-lg-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .pd-lg-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pd-lg-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .pd-lg-t-6 {
    padding-top: 6px;
  }
  .pd-lg-r-6 {
    padding-right: 6px;
  }
  .pd-lg-b-6 {
    padding-bottom: 6px;
  }
  .pd-lg-l-6 {
    padding-left: 6px;
  }
  .pd-lg-t-6-f {
    padding-top: 6px !important;
  }
  .pd-lg-r-6-f {
    padding-right: 6px !important;
  }
  .pd-lg-b-6-f {
    padding-bottom: 6px !important;
  }
  .pd-lg-l-6-f {
    padding-left: 6px !important;
  }
  .pd-lg-7 {
    padding: 7px;
  }
  .pd-lg-7-f {
    padding: 7px !important;
  }
  .pd-lg-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .pd-lg-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .pd-lg-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pd-lg-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .pd-lg-t-7 {
    padding-top: 7px;
  }
  .pd-lg-r-7 {
    padding-right: 7px;
  }
  .pd-lg-b-7 {
    padding-bottom: 7px;
  }
  .pd-lg-l-7 {
    padding-left: 7px;
  }
  .pd-lg-t-7-f {
    padding-top: 7px !important;
  }
  .pd-lg-r-7-f {
    padding-right: 7px !important;
  }
  .pd-lg-b-7-f {
    padding-bottom: 7px !important;
  }
  .pd-lg-l-7-f {
    padding-left: 7px !important;
  }
  .pd-lg-8 {
    padding: 8px;
  }
  .pd-lg-8-f {
    padding: 8px !important;
  }
  .pd-lg-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pd-lg-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pd-lg-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pd-lg-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pd-lg-t-8 {
    padding-top: 8px;
  }
  .pd-lg-r-8 {
    padding-right: 8px;
  }
  .pd-lg-b-8 {
    padding-bottom: 8px;
  }
  .pd-lg-l-8 {
    padding-left: 8px;
  }
  .pd-lg-t-8-f {
    padding-top: 8px !important;
  }
  .pd-lg-r-8-f {
    padding-right: 8px !important;
  }
  .pd-lg-b-8-f {
    padding-bottom: 8px !important;
  }
  .pd-lg-l-8-f {
    padding-left: 8px !important;
  }
  .pd-lg-9 {
    padding: 9px;
  }
  .pd-lg-9-f {
    padding: 9px !important;
  }
  .pd-lg-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .pd-lg-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
  .pd-lg-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }
  .pd-lg-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
  .pd-lg-t-9 {
    padding-top: 9px;
  }
  .pd-lg-r-9 {
    padding-right: 9px;
  }
  .pd-lg-b-9 {
    padding-bottom: 9px;
  }
  .pd-lg-l-9 {
    padding-left: 9px;
  }
  .pd-lg-t-9-f {
    padding-top: 9px !important;
  }
  .pd-lg-r-9-f {
    padding-right: 9px !important;
  }
  .pd-lg-b-9-f {
    padding-bottom: 9px !important;
  }
  .pd-lg-l-9-f {
    padding-left: 9px !important;
  }
  .pd-lg-10 {
    padding: 10px;
  }
  .pd-lg-10-f {
    padding: 10px !important;
  }
  .pd-lg-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pd-lg-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pd-lg-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-lg-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pd-lg-t-10 {
    padding-top: 10px;
  }
  .pd-lg-r-10 {
    padding-right: 10px;
  }
  .pd-lg-b-10 {
    padding-bottom: 10px;
  }
  .pd-lg-l-10 {
    padding-left: 10px;
  }
  .pd-lg-t-10-f {
    padding-top: 10px !important;
  }
  .pd-lg-r-10-f {
    padding-right: 10px !important;
  }
  .pd-lg-b-10-f {
    padding-bottom: 10px !important;
  }
  .pd-lg-l-10-f {
    padding-left: 10px !important;
  }
  .pd-lg-15 {
    padding: 15px;
  }
  .pd-lg-15-f {
    padding: 15px !important;
  }
  .pd-lg-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pd-lg-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pd-lg-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-lg-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .pd-lg-t-15 {
    padding-top: 15px;
  }
  .pd-lg-r-15 {
    padding-right: 15px;
  }
  .pd-lg-b-15 {
    padding-bottom: 15px;
  }
  .pd-lg-l-15 {
    padding-left: 15px;
  }
  .pd-lg-t-15-f {
    padding-top: 15px !important;
  }
  .pd-lg-r-15-f {
    padding-right: 15px !important;
  }
  .pd-lg-b-15-f {
    padding-bottom: 15px !important;
  }
  .pd-lg-l-15-f {
    padding-left: 15px !important;
  }
  .pd-lg-20 {
    padding: 20px;
  }
  .pd-lg-20-f {
    padding: 20px !important;
  }
  .pd-lg-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pd-lg-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pd-lg-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pd-lg-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pd-lg-t-20 {
    padding-top: 20px;
  }
  .pd-lg-r-20 {
    padding-right: 20px;
  }
  .pd-lg-b-20 {
    padding-bottom: 20px;
  }
  .pd-lg-l-20 {
    padding-left: 20px;
  }
  .pd-lg-t-20-f {
    padding-top: 20px !important;
  }
  .pd-lg-r-20-f {
    padding-right: 20px !important;
  }
  .pd-lg-b-20-f {
    padding-bottom: 20px !important;
  }
  .pd-lg-l-20-f {
    padding-left: 20px !important;
  }
  .pd-lg-25 {
    padding: 25px;
  }
  .pd-lg-25-f {
    padding: 25px !important;
  }
  .pd-lg-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .pd-lg-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pd-lg-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .pd-lg-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .pd-lg-t-25 {
    padding-top: 25px;
  }
  .pd-lg-r-25 {
    padding-right: 25px;
  }
  .pd-lg-b-25 {
    padding-bottom: 25px;
  }
  .pd-lg-l-25 {
    padding-left: 25px;
  }
  .pd-lg-t-25-f {
    padding-top: 25px !important;
  }
  .pd-lg-r-25-f {
    padding-right: 25px !important;
  }
  .pd-lg-b-25-f {
    padding-bottom: 25px !important;
  }
  .pd-lg-l-25-f {
    padding-left: 25px !important;
  }
  .pd-lg-30 {
    padding: 30px;
  }
  .pd-lg-30-f {
    padding: 30px !important;
  }
  .pd-lg-y-30 {
    padding-top: 30px;
  }

  .pd-lg-y-30-f {
    padding-top: 30px !important;
  }

  .pd-lg-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-lg-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pd-lg-t-30 {
    padding-top: 30px;
  }
  .pd-lg-r-30 {
    padding-right: 30px;
  }
  .pd-lg-b-30 {
    padding-bottom: 30px;
  }
  .pd-lg-l-30 {
    padding-left: 30px;
  }
  .pd-lg-t-30-f {
    padding-top: 30px !important;
  }
  .pd-lg-r-30-f {
    padding-right: 30px !important;
  }
  .pd-lg-b-30-f {
    padding-bottom: 30px !important;
  }
  .pd-lg-l-30-f {
    padding-left: 30px !important;
  }
  .pd-lg-35 {
    padding: 35px;
  }
  .pd-lg-35-f {
    padding: 35px !important;
  }
  .pd-lg-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pd-lg-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pd-lg-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .pd-lg-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .pd-lg-t-35 {
    padding-top: 35px;
  }
  .pd-lg-r-35 {
    padding-right: 35px;
  }
  .pd-lg-b-35 {
    padding-bottom: 35px;
  }
  .pd-lg-l-35 {
    padding-left: 35px;
  }
  .pd-lg-t-35-f {
    padding-top: 35px !important;
  }
  .pd-lg-r-35-f {
    padding-right: 35px !important;
  }
  .pd-lg-b-35-f {
    padding-bottom: 35px !important;
  }
  .pd-lg-l-35-f {
    padding-left: 35px !important;
  }
  .pd-lg-40 {
    padding: 40px;
  }
  .pd-lg-40-f {
    padding: 40px !important;
  }
  .pd-lg-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pd-lg-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pd-lg-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pd-lg-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .pd-lg-t-40 {
    padding-top: 40px;
  }
  .pd-lg-r-40 {
    padding-right: 40px;
  }
  .pd-lg-b-40 {
    padding-bottom: 40px;
  }
  .pd-lg-l-40 {
    padding-left: 40px;
  }
  .pd-lg-t-40-f {
    padding-top: 40px !important;
  }
  .pd-lg-r-40-f {
    padding-right: 40px !important;
  }
  .pd-lg-b-40-f {
    padding-bottom: 40px !important;
  }
  .pd-lg-l-40-f {
    padding-left: 40px !important;
  }
  .pd-lg-45 {
    padding: 45px;
  }
  .pd-lg-45-f {
    padding: 45px !important;
  }
  .pd-lg-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .pd-lg-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pd-lg-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-lg-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .pd-lg-t-45 {
    padding-top: 45px;
  }
  .pd-lg-r-45 {
    padding-right: 45px;
  }
  .pd-lg-b-45 {
    padding-bottom: 45px;
  }
  .pd-lg-l-45 {
    padding-left: 45px;
  }
  .pd-lg-t-45-f {
    padding-top: 45px !important;
  }
  .pd-lg-r-45-f {
    padding-right: 45px !important;
  }
  .pd-lg-b-45-f {
    padding-bottom: 45px !important;
  }
  .pd-lg-l-45-f {
    padding-left: 45px !important;
  }
  .pd-lg-50 {
    padding: 50px;
  }
  .pd-lg-50-f {
    padding: 50px !important;
  }
  .pd-lg-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pd-lg-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pd-lg-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pd-lg-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .pd-lg-t-50 {
    padding-top: 50px;
  }
  .pd-lg-r-50 {
    padding-right: 50px;
  }
  .pd-lg-b-50 {
    padding-bottom: 50px;
  }
  .pd-lg-l-50 {
    padding-left: 50px;
  }
  .pd-lg-t-50-f {
    padding-top: 50px !important;
  }
  .pd-lg-r-50-f {
    padding-right: 50px !important;
  }
  .pd-lg-b-50-f {
    padding-bottom: 50px !important;
  }
  .pd-lg-l-50-f {
    padding-left: 50px !important;
  }
  .pd-lg-55 {
    padding: 55px;
  }
  .pd-lg-55-f {
    padding: 55px !important;
  }
  .pd-lg-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .pd-lg-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pd-lg-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .pd-lg-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .pd-lg-t-55 {
    padding-top: 55px;
  }
  .pd-lg-r-55 {
    padding-right: 55px;
  }
  .pd-lg-b-55 {
    padding-bottom: 55px;
  }
  .pd-lg-l-55 {
    padding-left: 55px;
  }
  .pd-lg-t-55-f {
    padding-top: 55px !important;
  }
  .pd-lg-r-55-f {
    padding-right: 55px !important;
  }
  .pd-lg-b-55-f {
    padding-bottom: 55px !important;
  }
  .pd-lg-l-55-f {
    padding-left: 55px !important;
  }
  .pd-lg-60 {
    padding: 60px;
  }
  .pd-lg-60-f {
    padding: 60px !important;
  }
  .pd-lg-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pd-lg-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pd-lg-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-lg-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .pd-lg-t-60 {
    padding-top: 60px;
  }
  .pd-lg-r-60 {
    padding-right: 60px;
  }
  .pd-lg-b-60 {
    padding-bottom: 60px;
  }
  .pd-lg-l-60 {
    padding-left: 60px;
  }
  .pd-lg-t-60-f {
    padding-top: 60px !important;
  }
  .pd-lg-r-60-f {
    padding-right: 60px !important;
  }
  .pd-lg-b-60-f {
    padding-bottom: 60px !important;
  }
  .pd-lg-l-60-f {
    padding-left: 60px !important;
  }
  .pd-lg-65 {
    padding: 65px;
  }
  .pd-lg-65-f {
    padding: 65px !important;
  }
  .pd-lg-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .pd-lg-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pd-lg-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .pd-lg-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .pd-lg-t-65 {
    padding-top: 65px;
  }
  .pd-lg-r-65 {
    padding-right: 65px;
  }
  .pd-lg-b-65 {
    padding-bottom: 65px;
  }
  .pd-lg-l-65 {
    padding-left: 65px;
  }
  .pd-lg-t-65-f {
    padding-top: 65px !important;
  }
  .pd-lg-r-65-f {
    padding-right: 65px !important;
  }
  .pd-lg-b-65-f {
    padding-bottom: 65px !important;
  }
  .pd-lg-l-65-f {
    padding-left: 65px !important;
  }
  .pd-lg-70 {
    padding: 70px;
  }
  .pd-lg-70-f {
    padding: 70px !important;
  }
  .pd-lg-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .pd-lg-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pd-lg-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pd-lg-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .pd-lg-t-70 {
    padding-top: 70px;
  }
  .pd-lg-r-70 {
    padding-right: 70px;
  }
  .pd-lg-b-70 {
    padding-bottom: 70px;
  }
  .pd-lg-l-70 {
    padding-left: 70px;
  }
  .pd-lg-t-70-f {
    padding-top: 70px !important;
  }
  .pd-lg-r-70-f {
    padding-right: 70px !important;
  }
  .pd-lg-b-70-f {
    padding-bottom: 70px !important;
  }
  .pd-lg-l-70-f {
    padding-left: 70px !important;
  }
  .pd-lg-75 {
    padding: 75px;
  }
  .pd-lg-75-f {
    padding: 75px !important;
  }
  .pd-lg-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .pd-lg-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pd-lg-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .pd-lg-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .pd-lg-t-75 {
    padding-top: 75px;
  }
  .pd-lg-r-75 {
    padding-right: 75px;
  }
  .pd-lg-b-75 {
    padding-bottom: 75px;
  }
  .pd-lg-l-75 {
    padding-left: 75px;
  }
  .pd-lg-t-75-f {
    padding-top: 75px !important;
  }
  .pd-lg-r-75-f {
    padding-right: 75px !important;
  }
  .pd-lg-b-75-f {
    padding-bottom: 75px !important;
  }
  .pd-lg-l-75-f {
    padding-left: 75px !important;
  }
  .pd-lg-80 {
    padding: 80px;
  }
  .pd-lg-80-f {
    padding: 80px !important;
  }
  .pd-lg-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pd-lg-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pd-lg-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pd-lg-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .pd-lg-t-80 {
    padding-top: 80px;
  }
  .pd-lg-r-80 {
    padding-right: 80px;
  }
  .pd-lg-b-80 {
    padding-bottom: 80px;
  }
  .pd-lg-l-80 {
    padding-left: 80px;
  }
  .pd-lg-t-80-f {
    padding-top: 80px !important;
  }
  .pd-lg-r-80-f {
    padding-right: 80px !important;
  }
  .pd-lg-b-80-f {
    padding-bottom: 80px !important;
  }
  .pd-lg-l-80-f {
    padding-left: 80px !important;
  }
  .pd-lg-85 {
    padding: 85px;
  }
  .pd-lg-85-f {
    padding: 85px !important;
  }
  .pd-lg-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .pd-lg-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pd-lg-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .pd-lg-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .pd-lg-t-85 {
    padding-top: 85px;
  }
  .pd-lg-r-85 {
    padding-right: 85px;
  }
  .pd-lg-b-85 {
    padding-bottom: 85px;
  }
  .pd-lg-l-85 {
    padding-left: 85px;
  }
  .pd-lg-t-85-f {
    padding-top: 85px !important;
  }
  .pd-lg-r-85-f {
    padding-right: 85px !important;
  }
  .pd-lg-b-85-f {
    padding-bottom: 85px !important;
  }
  .pd-lg-l-85-f {
    padding-left: 85px !important;
  }
  .pd-lg-90 {
    padding: 90px;
  }
  .pd-lg-90-f {
    padding: 90px !important;
  }
  .pd-lg-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .pd-lg-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pd-lg-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pd-lg-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .pd-lg-t-90 {
    padding-top: 90px;
  }
  .pd-lg-r-90 {
    padding-right: 90px;
  }
  .pd-lg-b-90 {
    padding-bottom: 90px;
  }
  .pd-lg-l-90 {
    padding-left: 90px;
  }
  .pd-lg-t-90-f {
    padding-top: 90px !important;
  }
  .pd-lg-r-90-f {
    padding-right: 90px !important;
  }
  .pd-lg-b-90-f {
    padding-bottom: 90px !important;
  }
  .pd-lg-l-90-f {
    padding-left: 90px !important;
  }
  .pd-lg-95 {
    padding: 95px;
  }
  .pd-lg-95-f {
    padding: 95px !important;
  }
  .pd-lg-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .pd-lg-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pd-lg-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .pd-lg-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .pd-lg-t-95 {
    padding-top: 95px;
  }
  .pd-lg-r-95 {
    padding-right: 95px;
  }
  .pd-lg-b-95 {
    padding-bottom: 95px;
  }
  .pd-lg-l-95 {
    padding-left: 95px;
  }
  .pd-lg-t-95-f {
    padding-top: 95px !important;
  }
  .pd-lg-r-95-f {
    padding-right: 95px !important;
  }
  .pd-lg-b-95-f {
    padding-bottom: 95px !important;
  }
  .pd-lg-l-95-f {
    padding-left: 95px !important;
  }
  .pd-lg-100 {
    padding: 100px;
  }
  .pd-lg-100-f {
    padding: 100px !important;
  }
  .pd-lg-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pd-lg-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pd-lg-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pd-lg-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .pd-lg-t-100 {
    padding-top: 100px;
  }
  .pd-lg-r-100 {
    padding-right: 100px;
  }
  .pd-lg-b-100 {
    padding-bottom: 100px;
  }
  .pd-lg-l-100 {
    padding-left: 100px;
  }
  .pd-lg-t-100-f {
    padding-top: 100px !important;
  }
  .pd-lg-r-100-f {
    padding-right: 100px !important;
  }
  .pd-lg-b-100-f {
    padding-bottom: 100px !important;
  }
  .pd-lg-l-100-f {
    padding-left: 100px !important;
  }
  .pd-lg-110 {
    padding: 110px;
  }
  .pd-lg-110-f {
    padding: 110px !important;
  }
  .pd-lg-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .pd-lg-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pd-lg-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pd-lg-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .pd-lg-t-110 {
    padding-top: 110px;
  }
  .pd-lg-r-110 {
    padding-right: 110px;
  }
  .pd-lg-b-110 {
    padding-bottom: 110px;
  }
  .pd-lg-l-110 {
    padding-left: 110px;
  }
  .pd-lg-t-110-f {
    padding-top: 110px !important;
  }
  .pd-lg-r-110-f {
    padding-right: 110px !important;
  }
  .pd-lg-b-110-f {
    padding-bottom: 110px !important;
  }
  .pd-lg-l-110-f {
    padding-left: 110px !important;
  }
  .pd-lg-120 {
    padding: 120px;
  }
  .pd-lg-120-f {
    padding: 120px !important;
  }
  .pd-lg-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pd-lg-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pd-lg-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pd-lg-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .pd-lg-t-120 {
    padding-top: 120px;
  }
  .pd-lg-r-120 {
    padding-right: 120px;
  }
  .pd-lg-b-120 {
    padding-bottom: 120px;
  }
  .pd-lg-l-120 {
    padding-left: 120px;
  }
  .pd-lg-t-120-f {
    padding-top: 120px !important;
  }
  .pd-lg-r-120-f {
    padding-right: 120px !important;
  }
  .pd-lg-b-120-f {
    padding-bottom: 120px !important;
  }
  .pd-lg-l-120-f {
    padding-left: 120px !important;
  }
  .pd-lg-130 {
    padding: 130px;
  }
  .pd-lg-130-f {
    padding: 130px !important;
  }
  .pd-lg-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .pd-lg-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .pd-lg-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }
  .pd-lg-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .pd-lg-t-130 {
    padding-top: 130px;
  }
  .pd-lg-r-130 {
    padding-right: 130px;
  }
  .pd-lg-b-130 {
    padding-bottom: 130px;
  }
  .pd-lg-l-130 {
    padding-left: 130px;
  }
  .pd-lg-t-130-f {
    padding-top: 130px !important;
  }
  .pd-lg-r-130-f {
    padding-right: 130px !important;
  }
  .pd-lg-b-130-f {
    padding-bottom: 130px !important;
  }
  .pd-lg-l-130-f {
    padding-left: 130px !important;
  }
  .pd-lg-140 {
    padding: 140px;
  }
  .pd-lg-140-f {
    padding: 140px !important;
  }
  .pd-lg-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .pd-lg-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .pd-lg-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .pd-lg-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .pd-lg-t-140 {
    padding-top: 140px;
  }
  .pd-lg-r-140 {
    padding-right: 140px;
  }
  .pd-lg-b-140 {
    padding-bottom: 140px;
  }
  .pd-lg-l-140 {
    padding-left: 140px;
  }
  .pd-lg-t-140-f {
    padding-top: 140px !important;
  }
  .pd-lg-r-140-f {
    padding-right: 140px !important;
  }
  .pd-lg-b-140-f {
    padding-bottom: 140px !important;
  }
  .pd-lg-l-140-f {
    padding-left: 140px !important;
  }
  .pd-lg-150 {
    padding: 150px;
  }
  .pd-lg-150-f {
    padding: 150px !important;
  }
  .pd-lg-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .pd-lg-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pd-lg-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }
  .pd-lg-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .pd-lg-t-150 {
    padding-top: 150px;
  }
  .pd-lg-r-150 {
    padding-right: 150px;
  }
  .pd-lg-b-150 {
    padding-bottom: 150px;
  }
  .pd-lg-l-150 {
    padding-left: 150px;
  }
  .pd-lg-t-150-f {
    padding-top: 150px !important;
  }
  .pd-lg-r-150-f {
    padding-right: 150px !important;
  }
  .pd-lg-b-150-f {
    padding-bottom: 150px !important;
  }
  .pd-lg-l-150-f {
    padding-left: 150px !important;
  }
  .pd-lg-160 {
    padding: 160px;
  }
  .pd-lg-160-f {
    padding: 160px !important;
  }
  .pd-lg-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .pd-lg-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .pd-lg-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }
  .pd-lg-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .pd-lg-t-160 {
    padding-top: 160px;
  }
  .pd-lg-r-160 {
    padding-right: 160px;
  }
  .pd-lg-b-160 {
    padding-bottom: 160px;
  }
  .pd-lg-l-160 {
    padding-left: 160px;
  }
  .pd-lg-t-160-f {
    padding-top: 160px !important;
  }
  .pd-lg-r-160-f {
    padding-right: 160px !important;
  }
  .pd-lg-b-160-f {
    padding-bottom: 160px !important;
  }
  .pd-lg-l-160-f {
    padding-left: 160px !important;
  }
  .pd-lg-170 {
    padding: 170px;
  }
  .pd-lg-170-f {
    padding: 170px !important;
  }
  .pd-lg-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }
  .pd-lg-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }
  .pd-lg-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }
  .pd-lg-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
  .pd-lg-t-170 {
    padding-top: 170px;
  }
  .pd-lg-r-170 {
    padding-right: 170px;
  }
  .pd-lg-b-170 {
    padding-bottom: 170px;
  }
  .pd-lg-l-170 {
    padding-left: 170px;
  }
  .pd-lg-t-170-f {
    padding-top: 170px !important;
  }
  .pd-lg-r-170-f {
    padding-right: 170px !important;
  }
  .pd-lg-b-170-f {
    padding-bottom: 170px !important;
  }
  .pd-lg-l-170-f {
    padding-left: 170px !important;
  }
  .pd-lg-180 {
    padding: 180px;
  }
  .pd-lg-180-f {
    padding: 180px !important;
  }
  .pd-lg-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .pd-lg-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .pd-lg-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }
  .pd-lg-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .pd-lg-t-180 {
    padding-top: 180px;
  }
  .pd-lg-r-180 {
    padding-right: 180px;
  }
  .pd-lg-b-180 {
    padding-bottom: 180px;
  }
  .pd-lg-l-180 {
    padding-left: 180px;
  }
  .pd-lg-t-180-f {
    padding-top: 180px !important;
  }
  .pd-lg-r-180-f {
    padding-right: 180px !important;
  }
  .pd-lg-b-180-f {
    padding-bottom: 180px !important;
  }
  .pd-lg-l-180-f {
    padding-left: 180px !important;
  }
  .pd-lg-190 {
    padding: 190px;
  }
  .pd-lg-190-f {
    padding: 190px !important;
  }
  .pd-lg-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }
  .pd-lg-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }
  .pd-lg-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }
  .pd-lg-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
  .pd-lg-t-190 {
    padding-top: 190px;
  }
  .pd-lg-r-190 {
    padding-right: 190px;
  }
  .pd-lg-b-190 {
    padding-bottom: 190px;
  }
  .pd-lg-l-190 {
    padding-left: 190px;
  }
  .pd-lg-t-190-f {
    padding-top: 190px !important;
  }
  .pd-lg-r-190-f {
    padding-right: 190px !important;
  }
  .pd-lg-b-190-f {
    padding-bottom: 190px !important;
  }
  .pd-lg-l-190-f {
    padding-left: 190px !important;
  }
  .pd-lg-200 {
    padding: 200px;
  }
  .pd-lg-200-f {
    padding: 200px !important;
  }
  .pd-lg-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .pd-lg-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .pd-lg-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .pd-lg-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .pd-lg-t-200 {
    padding-top: 200px;
  }
  .pd-lg-r-200 {
    padding-right: 200px;
  }
  .pd-lg-b-200 {
    padding-bottom: 200px;
  }
  .pd-lg-l-200 {
    padding-left: 200px;
  }
  .pd-lg-t-200-f {
    padding-top: 200px !important;
  }
  .pd-lg-r-200-f {
    padding-right: 200px !important;
  }
  .pd-lg-b-200-f {
    padding-bottom: 200px !important;
  }
  .pd-lg-l-200-f {
    padding-left: 200px !important;
  }
}

@media (min-width: 1200px) {
  .pd-xl-0 {
    padding: 0px;
  }
  .pd-xl-0-f {
    padding: 0px !important;
  }
  .pd-xl-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pd-xl-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .pd-xl-x-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pd-xl-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pd-xl-t-0 {
    padding-top: 0px;
  }
  .pd-xl-r-0 {
    padding-right: 0px;
  }
  .pd-xl-b-0 {
    padding-bottom: 0px;
  }
  .pd-xl-l-0 {
    padding-left: 0px;
  }
  .pd-xl-t-0-f {
    padding-top: 0px !important;
  }
  .pd-xl-r-0-f {
    padding-right: 0px !important;
  }
  .pd-xl-b-0-f {
    padding-bottom: 0px !important;
  }
  .pd-xl-l-0-f {
    padding-left: 0px !important;
  }
  .pd-xl-1 {
    padding: 1px;
  }
  .pd-xl-1-f {
    padding: 1px !important;
  }
  .pd-xl-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .pd-xl-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .pd-xl-x-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .pd-xl-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .pd-xl-t-1 {
    padding-top: 1px;
  }
  .pd-xl-r-1 {
    padding-right: 1px;
  }
  .pd-xl-b-1 {
    padding-bottom: 1px;
  }
  .pd-xl-l-1 {
    padding-left: 1px;
  }
  .pd-xl-t-1-f {
    padding-top: 1px !important;
  }
  .pd-xl-r-1-f {
    padding-right: 1px !important;
  }
  .pd-xl-b-1-f {
    padding-bottom: 1px !important;
  }
  .pd-xl-l-1-f {
    padding-left: 1px !important;
  }
  .pd-xl-2 {
    padding: 2px;
  }
  .pd-xl-2-f {
    padding: 2px !important;
  }
  .pd-xl-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .pd-xl-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .pd-xl-x-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .pd-xl-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .pd-xl-t-2 {
    padding-top: 2px;
  }
  .pd-xl-r-2 {
    padding-right: 2px;
  }
  .pd-xl-b-2 {
    padding-bottom: 2px;
  }
  .pd-xl-l-2 {
    padding-left: 2px;
  }
  .pd-xl-t-2-f {
    padding-top: 2px !important;
  }
  .pd-xl-r-2-f {
    padding-right: 2px !important;
  }
  .pd-xl-b-2-f {
    padding-bottom: 2px !important;
  }
  .pd-xl-l-2-f {
    padding-left: 2px !important;
  }
  .pd-xl-3 {
    padding: 3px;
  }
  .pd-xl-3-f {
    padding: 3px !important;
  }
  .pd-xl-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .pd-xl-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .pd-xl-x-3 {
    padding-left: 3px;
    padding-right: 3px;
  }
  .pd-xl-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .pd-xl-t-3 {
    padding-top: 3px;
  }
  .pd-xl-r-3 {
    padding-right: 3px;
  }
  .pd-xl-b-3 {
    padding-bottom: 3px;
  }
  .pd-xl-l-3 {
    padding-left: 3px;
  }
  .pd-xl-t-3-f {
    padding-top: 3px !important;
  }
  .pd-xl-r-3-f {
    padding-right: 3px !important;
  }
  .pd-xl-b-3-f {
    padding-bottom: 3px !important;
  }
  .pd-xl-l-3-f {
    padding-left: 3px !important;
  }
  .pd-xl-4 {
    padding: 4px;
  }
  .pd-xl-4-f {
    padding: 4px !important;
  }
  .pd-xl-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pd-xl-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .pd-xl-x-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .pd-xl-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pd-xl-t-4 {
    padding-top: 4px;
  }
  .pd-xl-r-4 {
    padding-right: 4px;
  }
  .pd-xl-b-4 {
    padding-bottom: 4px;
  }
  .pd-xl-l-4 {
    padding-left: 4px;
  }
  .pd-xl-t-4-f {
    padding-top: 4px !important;
  }
  .pd-xl-r-4-f {
    padding-right: 4px !important;
  }
  .pd-xl-b-4-f {
    padding-bottom: 4px !important;
  }
  .pd-xl-l-4-f {
    padding-left: 4px !important;
  }
  .pd-xl-5 {
    padding: 5px;
  }
  .pd-xl-5-f {
    padding: 5px !important;
  }
  .pd-xl-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .pd-xl-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .pd-xl-x-5 {
    padding-left: 5px;
    padding-right: 5px;
  }
  .pd-xl-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .pd-xl-t-5 {
    padding-top: 5px;
  }
  .pd-xl-r-5 {
    padding-right: 5px;
  }
  .pd-xl-b-5 {
    padding-bottom: 5px;
  }
  .pd-xl-l-5 {
    padding-left: 5px;
  }
  .pd-xl-t-5-f {
    padding-top: 5px !important;
  }
  .pd-xl-r-5-f {
    padding-right: 5px !important;
  }
  .pd-xl-b-5-f {
    padding-bottom: 5px !important;
  }
  .pd-xl-l-5-f {
    padding-left: 5px !important;
  }
  .pd-xl-6 {
    padding: 6px;
  }
  .pd-xl-6-f {
    padding: 6px !important;
  }
  .pd-xl-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .pd-xl-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .pd-xl-x-6 {
    padding-left: 6px;
    padding-right: 6px;
  }
  .pd-xl-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  .pd-xl-t-6 {
    padding-top: 6px;
  }
  .pd-xl-r-6 {
    padding-right: 6px;
  }
  .pd-xl-b-6 {
    padding-bottom: 6px;
  }
  .pd-xl-l-6 {
    padding-left: 6px;
  }
  .pd-xl-t-6-f {
    padding-top: 6px !important;
  }
  .pd-xl-r-6-f {
    padding-right: 6px !important;
  }
  .pd-xl-b-6-f {
    padding-bottom: 6px !important;
  }
  .pd-xl-l-6-f {
    padding-left: 6px !important;
  }
  .pd-xl-7 {
    padding: 7px;
  }
  .pd-xl-7-f {
    padding: 7px !important;
  }
  .pd-xl-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .pd-xl-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
  .pd-xl-x-7 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .pd-xl-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
  .pd-xl-t-7 {
    padding-top: 7px;
  }
  .pd-xl-r-7 {
    padding-right: 7px;
  }
  .pd-xl-b-7 {
    padding-bottom: 7px;
  }
  .pd-xl-l-7 {
    padding-left: 7px;
  }
  .pd-xl-t-7-f {
    padding-top: 7px !important;
  }
  .pd-xl-r-7-f {
    padding-right: 7px !important;
  }
  .pd-xl-b-7-f {
    padding-bottom: 7px !important;
  }
  .pd-xl-l-7-f {
    padding-left: 7px !important;
  }
  .pd-xl-8 {
    padding: 8px;
  }
  .pd-xl-8-f {
    padding: 8px !important;
  }
  .pd-xl-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pd-xl-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .pd-xl-x-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pd-xl-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pd-xl-t-8 {
    padding-top: 8px;
  }
  .pd-xl-r-8 {
    padding-right: 8px;
  }
  .pd-xl-b-8 {
    padding-bottom: 8px;
  }
  .pd-xl-l-8 {
    padding-left: 8px;
  }
  .pd-xl-t-8-f {
    padding-top: 8px !important;
  }
  .pd-xl-r-8-f {
    padding-right: 8px !important;
  }
  .pd-xl-b-8-f {
    padding-bottom: 8px !important;
  }
  .pd-xl-l-8-f {
    padding-left: 8px !important;
  }
  .pd-xl-9 {
    padding: 9px;
  }
  .pd-xl-9-f {
    padding: 9px !important;
  }
  .pd-xl-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .pd-xl-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
  .pd-xl-x-9 {
    padding-left: 9px;
    padding-right: 9px;
  }
  .pd-xl-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }
  .pd-xl-t-9 {
    padding-top: 9px;
  }
  .pd-xl-r-9 {
    padding-right: 9px;
  }
  .pd-xl-b-9 {
    padding-bottom: 9px;
  }
  .pd-xl-l-9 {
    padding-left: 9px;
  }
  .pd-xl-t-9-f {
    padding-top: 9px !important;
  }
  .pd-xl-r-9-f {
    padding-right: 9px !important;
  }
  .pd-xl-b-9-f {
    padding-bottom: 9px !important;
  }
  .pd-xl-l-9-f {
    padding-left: 9px !important;
  }
  .pd-xl-10 {
    padding: 10px;
  }
  .pd-xl-10-f {
    padding: 10px !important;
  }
  .pd-xl-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .pd-xl-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .pd-xl-x-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .pd-xl-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .pd-xl-t-10 {
    padding-top: 10px;
  }
  .pd-xl-r-10 {
    padding-right: 10px;
  }
  .pd-xl-b-10 {
    padding-bottom: 10px;
  }
  .pd-xl-l-10 {
    padding-left: 10px;
  }
  .pd-xl-t-10-f {
    padding-top: 10px !important;
  }
  .pd-xl-r-10-f {
    padding-right: 10px !important;
  }
  .pd-xl-b-10-f {
    padding-bottom: 10px !important;
  }
  .pd-xl-l-10-f {
    padding-left: 10px !important;
  }
  .pd-xl-15 {
    padding: 15px;
  }
  .pd-xl-15-f {
    padding: 15px !important;
  }
  .pd-xl-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pd-xl-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .pd-xl-x-15 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pd-xl-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .pd-xl-t-15 {
    padding-top: 15px;
  }
  .pd-xl-r-15 {
    padding-right: 15px;
  }
  .pd-xl-b-15 {
    padding-bottom: 15px;
  }
  .pd-xl-l-15 {
    padding-left: 15px;
  }
  .pd-xl-t-15-f {
    padding-top: 15px !important;
  }
  .pd-xl-r-15-f {
    padding-right: 15px !important;
  }
  .pd-xl-b-15-f {
    padding-bottom: 15px !important;
  }
  .pd-xl-l-15-f {
    padding-left: 15px !important;
  }
  .pd-xl-20 {
    padding: 20px;
  }
  .pd-xl-20-f {
    padding: 20px !important;
  }
  .pd-xl-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pd-xl-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .pd-xl-x-20 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pd-xl-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .pd-xl-t-20 {
    padding-top: 20px;
  }
  .pd-xl-r-20 {
    padding-right: 20px;
  }
  .pd-xl-b-20 {
    padding-bottom: 20px;
  }
  .pd-xl-l-20 {
    padding-left: 20px;
  }
  .pd-xl-t-20-f {
    padding-top: 20px !important;
  }
  .pd-xl-r-20-f {
    padding-right: 20px !important;
  }
  .pd-xl-b-20-f {
    padding-bottom: 20px !important;
  }
  .pd-xl-l-20-f {
    padding-left: 20px !important;
  }
  .pd-xl-25 {
    padding: 25px;
  }
  .pd-xl-25-f {
    padding: 25px !important;
  }
  .pd-xl-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .pd-xl-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .pd-xl-x-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .pd-xl-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .pd-xl-t-25 {
    padding-top: 25px;
  }
  .pd-xl-r-25 {
    padding-right: 25px;
  }
  .pd-xl-b-25 {
    padding-bottom: 25px;
  }
  .pd-xl-l-25 {
    padding-left: 25px;
  }
  .pd-xl-t-25-f {
    padding-top: 25px !important;
  }
  .pd-xl-r-25-f {
    padding-right: 25px !important;
  }
  .pd-xl-b-25-f {
    padding-bottom: 25px !important;
  }
  .pd-xl-l-25-f {
    padding-left: 25px !important;
  }
  .pd-xl-30 {
    padding: 30px;
  }
  .pd-xl-30-f {
    padding: 30px !important;
  }
  .pd-xl-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pd-xl-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .pd-xl-x-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .pd-xl-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .pd-xl-t-30 {
    padding-top: 30px;
  }
  .pd-xl-r-30 {
    padding-right: 30px;
  }
  .pd-xl-b-30 {
    padding-bottom: 30px;
  }
  .pd-xl-l-30 {
    padding-left: 30px;
  }
  .pd-xl-t-30-f {
    padding-top: 30px !important;
  }
  .pd-xl-r-30-f {
    padding-right: 30px !important;
  }
  .pd-xl-b-30-f {
    padding-bottom: 30px !important;
  }
  .pd-xl-l-30-f {
    padding-left: 30px !important;
  }
  .pd-xl-35 {
    padding: 35px;
  }
  .pd-xl-35-f {
    padding: 35px !important;
  }
  .pd-xl-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .pd-xl-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .pd-xl-x-35 {
    padding-left: 35px;
    padding-right: 35px;
  }
  .pd-xl-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .pd-xl-t-35 {
    padding-top: 35px;
  }
  .pd-xl-r-35 {
    padding-right: 35px;
  }
  .pd-xl-b-35 {
    padding-bottom: 35px;
  }
  .pd-xl-l-35 {
    padding-left: 35px;
  }
  .pd-xl-t-35-f {
    padding-top: 35px !important;
  }
  .pd-xl-r-35-f {
    padding-right: 35px !important;
  }
  .pd-xl-b-35-f {
    padding-bottom: 35px !important;
  }
  .pd-xl-l-35-f {
    padding-left: 35px !important;
  }
  .pd-xl-40 {
    padding: 40px;
  }
  .pd-xl-40-f {
    padding: 40px !important;
  }
  .pd-xl-y-40 {
    padding-top: 40px;
  }

  .pd-xl-y-40-f {
    padding-top: 40px !important;
  }
  .pd-xl-x-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .pd-xl-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .pd-xl-t-40 {
    padding-top: 40px;
  }
  .pd-xl-r-40 {
    padding-right: 40px;
  }
  .pd-xl-b-40 {
    padding-bottom: 40px;
  }
  .pd-xl-l-40 {
    padding-left: 40px;
  }
  .pd-xl-t-40-f {
    padding-top: 40px !important;
  }
  .pd-xl-r-40-f {
    padding-right: 40px !important;
  }
  .pd-xl-b-40-f {
    padding-bottom: 40px !important;
  }
  .pd-xl-l-40-f {
    padding-left: 40px !important;
  }
  .pd-xl-45 {
    padding: 45px;
  }
  .pd-xl-45-f {
    padding: 45px !important;
  }
  .pd-xl-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .pd-xl-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .pd-xl-x-45 {
    padding-left: 45px;
    padding-right: 45px;
  }
  .pd-xl-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .pd-xl-t-45 {
    padding-top: 45px;
  }
  .pd-xl-r-45 {
    padding-right: 45px;
  }
  .pd-xl-b-45 {
    padding-bottom: 45px;
  }
  .pd-xl-l-45 {
    padding-left: 45px;
  }
  .pd-xl-t-45-f {
    padding-top: 45px !important;
  }
  .pd-xl-r-45-f {
    padding-right: 45px !important;
  }
  .pd-xl-b-45-f {
    padding-bottom: 45px !important;
  }
  .pd-xl-l-45-f {
    padding-left: 45px !important;
  }
  .pd-xl-50 {
    padding: 50px;
  }
  .pd-xl-50-f {
    padding: 50px !important;
  }
  .pd-xl-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pd-xl-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .pd-xl-x-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .pd-xl-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .pd-xl-t-50 {
    padding-top: 50px;
  }
  .pd-xl-r-50 {
    padding-right: 50px;
  }
  .pd-xl-b-50 {
    padding-bottom: 50px;
  }
  .pd-xl-l-50 {
    padding-left: 50px;
  }
  .pd-xl-t-50-f {
    padding-top: 50px !important;
  }
  .pd-xl-r-50-f {
    padding-right: 50px !important;
  }
  .pd-xl-b-50-f {
    padding-bottom: 50px !important;
  }
  .pd-xl-l-50-f {
    padding-left: 50px !important;
  }
  .pd-xl-55 {
    padding: 55px;
  }
  .pd-xl-55-f {
    padding: 55px !important;
  }
  .pd-xl-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
  }
  .pd-xl-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
  }
  .pd-xl-x-55 {
    padding-left: 55px;
    padding-right: 55px;
  }
  .pd-xl-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important;
  }
  .pd-xl-t-55 {
    padding-top: 55px;
  }
  .pd-xl-r-55 {
    padding-right: 55px;
  }
  .pd-xl-b-55 {
    padding-bottom: 55px;
  }
  .pd-xl-l-55 {
    padding-left: 55px;
  }
  .pd-xl-t-55-f {
    padding-top: 55px !important;
  }
  .pd-xl-r-55-f {
    padding-right: 55px !important;
  }
  .pd-xl-b-55-f {
    padding-bottom: 55px !important;
  }
  .pd-xl-l-55-f {
    padding-left: 55px !important;
  }
  .pd-xl-60 {
    padding: 60px;
  }
  .pd-xl-60-f {
    padding: 60px !important;
  }
  .pd-xl-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  //mdj

  .pd-xl-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .pd-xl-x-60 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .pd-xl-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .pd-xl-t-60 {
    padding-top: 60px;
  }
  .pd-xl-r-60 {
    padding-right: 60px;
  }
  .pd-xl-b-60 {
    padding-bottom: 60px;
  }
  .pd-xl-l-60 {
    padding-left: 60px;
  }
  .pd-xl-t-60-f {
    padding-top: 60px !important;
  }
  .pd-xl-r-60-f {
    padding-right: 60px !important;
  }
  .pd-xl-b-60-f {
    padding-bottom: 60px !important;
  }
  .pd-xl-l-60-f {
    padding-left: 60px !important;
  }
  .pd-xl-65 {
    padding: 65px;
  }
  .pd-xl-65-f {
    padding: 65px !important;
  }
  .pd-xl-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
  }
  .pd-xl-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .pd-xl-x-65 {
    padding-left: 65px;
    padding-right: 65px;
  }
  .pd-xl-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important;
  }
  .pd-xl-t-65 {
    padding-top: 65px;
  }
  .pd-xl-r-65 {
    padding-right: 65px;
  }
  .pd-xl-b-65 {
    padding-bottom: 65px;
  }
  .pd-xl-l-65 {
    padding-left: 65px;
  }
  .pd-xl-t-65-f {
    padding-top: 65px !important;
  }
  .pd-xl-r-65-f {
    padding-right: 65px !important;
  }
  .pd-xl-b-65-f {
    padding-bottom: 65px !important;
  }
  .pd-xl-l-65-f {
    padding-left: 65px !important;
  }
  .pd-xl-70 {
    padding: 70px;
  }
  .pd-xl-70-f {
    padding: 70px !important;
  }
  .pd-xl-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .pd-xl-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .pd-xl-x-70 {
    padding-left: 70px;
    padding-right: 70px;
  }
  .pd-xl-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .pd-xl-t-70 {
    padding-top: 70px;
  }
  .pd-xl-r-70 {
    padding-right: 70px;
  }
  .pd-xl-b-70 {
    padding-bottom: 70px;
  }
  .pd-xl-l-70 {
    padding-left: 70px;
  }
  .pd-xl-t-70-f {
    padding-top: 70px !important;
  }
  .pd-xl-r-70-f {
    padding-right: 70px !important;
  }
  .pd-xl-b-70-f {
    padding-bottom: 70px !important;
  }
  .pd-xl-l-70-f {
    padding-left: 70px !important;
  }
  .pd-xl-75 {
    padding: 75px;
  }
  .pd-xl-75-f {
    padding: 75px !important;
  }
  .pd-xl-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .pd-xl-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
  }
  .pd-xl-x-75 {
    padding-left: 75px;
    padding-right: 75px;
  }
  .pd-xl-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important;
  }
  .pd-xl-t-75 {
    padding-top: 75px;
  }
  .pd-xl-r-75 {
    padding-right: 75px;
  }
  .pd-xl-b-75 {
    padding-bottom: 75px;
  }
  .pd-xl-l-75 {
    padding-left: 75px;
  }
  .pd-xl-t-75-f {
    padding-top: 75px !important;
  }
  .pd-xl-r-75-f {
    padding-right: 75px !important;
  }
  .pd-xl-b-75-f {
    padding-bottom: 75px !important;
  }
  .pd-xl-l-75-f {
    padding-left: 75px !important;
  }
  .pd-xl-80 {
    padding: 80px;
  }
  .pd-xl-80-f {
    padding: 80px !important;
  }
  .pd-xl-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pd-xl-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .pd-xl-x-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .pd-xl-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .pd-xl-t-80 {
    padding-top: 80px;
  }
  .pd-xl-r-80 {
    padding-right: 80px;
  }
  .pd-xl-b-80 {
    padding-bottom: 80px;
  }
  .pd-xl-l-80 {
    padding-left: 80px;
  }
  .pd-xl-t-80-f {
    padding-top: 80px !important;
  }
  .pd-xl-r-80-f {
    padding-right: 80px !important;
  }
  .pd-xl-b-80-f {
    padding-bottom: 80px !important;
  }
  .pd-xl-l-80-f {
    padding-left: 80px !important;
  }
  .pd-xl-85 {
    padding: 85px;
  }
  .pd-xl-85-f {
    padding: 85px !important;
  }
  .pd-xl-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .pd-xl-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
  }
  .pd-xl-x-85 {
    padding-left: 85px;
    padding-right: 85px;
  }
  .pd-xl-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important;
  }
  .pd-xl-t-85 {
    padding-top: 85px;
  }
  .pd-xl-r-85 {
    padding-right: 85px;
  }
  .pd-xl-b-85 {
    padding-bottom: 85px;
  }
  .pd-xl-l-85 {
    padding-left: 85px;
  }
  .pd-xl-t-85-f {
    padding-top: 85px !important;
  }
  .pd-xl-r-85-f {
    padding-right: 85px !important;
  }
  .pd-xl-b-85-f {
    padding-bottom: 85px !important;
  }
  .pd-xl-l-85-f {
    padding-left: 85px !important;
  }
  .pd-xl-90 {
    padding: 90px;
  }
  .pd-xl-90-f {
    padding: 90px !important;
  }
  .pd-xl-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .pd-xl-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .pd-xl-x-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
  .pd-xl-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .pd-xl-t-90 {
    padding-top: 90px;
  }
  .pd-xl-r-90 {
    padding-right: 90px;
  }
  .pd-xl-b-90 {
    padding-bottom: 90px;
  }
  .pd-xl-l-90 {
    padding-left: 90px;
  }
  .pd-xl-t-90-f {
    padding-top: 90px !important;
  }
  .pd-xl-r-90-f {
    padding-right: 90px !important;
  }
  .pd-xl-b-90-f {
    padding-bottom: 90px !important;
  }
  .pd-xl-l-90-f {
    padding-left: 90px !important;
  }
  .pd-xl-95 {
    padding: 95px;
  }
  .pd-xl-95-f {
    padding: 95px !important;
  }
  .pd-xl-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  .pd-xl-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
  }
  .pd-xl-x-95 {
    padding-left: 95px;
    padding-right: 95px;
  }
  .pd-xl-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important;
  }
  .pd-xl-t-95 {
    padding-top: 95px;
  }
  .pd-xl-r-95 {
    padding-right: 95px;
  }
  .pd-xl-b-95 {
    padding-bottom: 95px;
  }
  .pd-xl-l-95 {
    padding-left: 95px;
  }
  .pd-xl-t-95-f {
    padding-top: 95px !important;
  }
  .pd-xl-r-95-f {
    padding-right: 95px !important;
  }
  .pd-xl-b-95-f {
    padding-bottom: 95px !important;
  }
  .pd-xl-l-95-f {
    padding-left: 95px !important;
  }
  .pd-xl-100 {
    padding: 100px;
  }
  .pd-xl-100-f {
    padding: 100px !important;
  }
  .pd-xl-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pd-xl-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .pd-xl-x-100 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .pd-xl-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .pd-xl-t-100 {
    padding-top: 100px;
  }
  .pd-xl-r-100 {
    padding-right: 100px;
  }
  .pd-xl-b-100 {
    padding-bottom: 100px;
  }
  .pd-xl-l-100 {
    padding-left: 100px;
  }
  .pd-xl-t-100-f {
    padding-top: 100px !important;
  }
  .pd-xl-r-100-f {
    padding-right: 100px !important;
  }
  .pd-xl-b-100-f {
    padding-bottom: 100px !important;
  }
  .pd-xl-l-100-f {
    padding-left: 100px !important;
  }
  .pd-xl-110 {
    padding: 110px;
  }
  .pd-xl-110-f {
    padding: 110px !important;
  }
  .pd-xl-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .pd-xl-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
  }
  .pd-xl-x-110 {
    padding-left: 110px;
    padding-right: 110px;
  }
  .pd-xl-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important;
  }
  .pd-xl-t-110 {
    padding-top: 110px;
  }
  .pd-xl-r-110 {
    padding-right: 110px;
  }
  .pd-xl-b-110 {
    padding-bottom: 110px;
  }
  .pd-xl-l-110 {
    padding-left: 110px;
  }
  .pd-xl-t-110-f {
    padding-top: 110px !important;
  }
  .pd-xl-r-110-f {
    padding-right: 110px !important;
  }
  .pd-xl-b-110-f {
    padding-bottom: 110px !important;
  }
  .pd-xl-l-110-f {
    padding-left: 110px !important;
  }
  .pd-xl-120 {
    padding: 120px;
  }
  .pd-xl-120-f {
    padding: 120px !important;
  }
  .pd-xl-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pd-xl-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .pd-xl-x-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .pd-xl-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .pd-xl-t-120 {
    padding-top: 120px;
  }
  .pd-xl-r-120 {
    padding-right: 120px;
  }
  .pd-xl-b-120 {
    padding-bottom: 120px;
  }
  .pd-xl-l-120 {
    padding-left: 120px;
  }
  .pd-xl-t-120-f {
    padding-top: 120px !important;
  }
  .pd-xl-r-120-f {
    padding-right: 120px !important;
  }
  .pd-xl-b-120-f {
    padding-bottom: 120px !important;
  }
  .pd-xl-l-120-f {
    padding-left: 120px !important;
  }
  .pd-xl-130 {
    padding: 130px;
  }
  .pd-xl-130-f {
    padding: 130px !important;
  }
  .pd-xl-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .pd-xl-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
  }
  .pd-xl-x-130 {
    padding-left: 130px;
    padding-right: 130px;
  }
  .pd-xl-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important;
  }
  .pd-xl-t-130 {
    padding-top: 130px;
  }
  .pd-xl-r-130 {
    padding-right: 130px;
  }
  .pd-xl-b-130 {
    padding-bottom: 130px;
  }
  .pd-xl-l-130 {
    padding-left: 130px;
  }
  .pd-xl-t-130-f {
    padding-top: 130px !important;
  }
  .pd-xl-r-130-f {
    padding-right: 130px !important;
  }
  .pd-xl-b-130-f {
    padding-bottom: 130px !important;
  }
  .pd-xl-l-130-f {
    padding-left: 130px !important;
  }
  .pd-xl-140 {
    padding: 140px;
  }
  .pd-xl-140-f {
    padding: 140px !important;
  }
  .pd-xl-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .pd-xl-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
  }
  .pd-xl-x-140 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .pd-xl-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .pd-xl-t-140 {
    padding-top: 140px;
  }
  .pd-xl-r-140 {
    padding-right: 140px;
  }
  .pd-xl-b-140 {
    padding-bottom: 140px;
  }
  .pd-xl-l-140 {
    padding-left: 140px;
  }
  .pd-xl-t-140-f {
    padding-top: 140px !important;
  }
  .pd-xl-r-140-f {
    padding-right: 140px !important;
  }
  .pd-xl-b-140-f {
    padding-bottom: 140px !important;
  }
  .pd-xl-l-140-f {
    padding-left: 140px !important;
  }
  .pd-xl-150 {
    padding: 150px;
  }
  .pd-xl-150-f {
    padding: 150px !important;
  }
  .pd-xl-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .pd-xl-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
  }
  .pd-xl-x-150 {
    padding-left: 150px;
    padding-right: 150px;
  }
  .pd-xl-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
  .pd-xl-t-150 {
    padding-top: 150px;
  }
  .pd-xl-r-150 {
    padding-right: 150px;
  }
  .pd-xl-b-150 {
    padding-bottom: 150px;
  }
  .pd-xl-l-150 {
    padding-left: 150px;
  }
  .pd-xl-t-150-f {
    padding-top: 150px !important;
  }
  .pd-xl-r-150-f {
    padding-right: 150px !important;
  }
  .pd-xl-b-150-f {
    padding-bottom: 150px !important;
  }
  .pd-xl-l-150-f {
    padding-left: 150px !important;
  }
  .pd-xl-160 {
    padding: 160px;
  }
  .pd-xl-160-f {
    padding: 160px !important;
  }
  .pd-xl-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .pd-xl-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
  }
  .pd-xl-x-160 {
    padding-left: 160px;
    padding-right: 160px;
  }
  .pd-xl-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important;
  }
  .pd-xl-t-160 {
    padding-top: 160px;
  }
  .pd-xl-r-160 {
    padding-right: 160px;
  }
  .pd-xl-b-160 {
    padding-bottom: 160px;
  }
  .pd-xl-l-160 {
    padding-left: 160px;
  }
  .pd-xl-t-160-f {
    padding-top: 160px !important;
  }
  .pd-xl-r-160-f {
    padding-right: 160px !important;
  }
  .pd-xl-b-160-f {
    padding-bottom: 160px !important;
  }
  .pd-xl-l-160-f {
    padding-left: 160px !important;
  }
  .pd-xl-170 {
    padding: 170px;
  }
  .pd-xl-170-f {
    padding: 170px !important;
  }
  .pd-xl-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
  }
  .pd-xl-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
  }
  .pd-xl-x-170 {
    padding-left: 170px;
    padding-right: 170px;
  }
  .pd-xl-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important;
  }
  .pd-xl-t-170 {
    padding-top: 170px;
  }
  .pd-xl-r-170 {
    padding-right: 170px;
  }
  .pd-xl-b-170 {
    padding-bottom: 170px;
  }
  .pd-xl-l-170 {
    padding-left: 170px;
  }
  .pd-xl-t-170-f {
    padding-top: 170px !important;
  }
  .pd-xl-r-170-f {
    padding-right: 170px !important;
  }
  .pd-xl-b-170-f {
    padding-bottom: 170px !important;
  }
  .pd-xl-l-170-f {
    padding-left: 170px !important;
  }
  .pd-xl-180 {
    padding: 180px;
  }
  .pd-xl-180-f {
    padding: 180px !important;
  }
  .pd-xl-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
  }
  .pd-xl-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
  }
  .pd-xl-x-180 {
    padding-left: 180px;
    padding-right: 180px;
  }
  .pd-xl-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .pd-xl-t-180 {
    padding-top: 180px;
  }
  .pd-xl-r-180 {
    padding-right: 180px;
  }
  .pd-xl-b-180 {
    padding-bottom: 180px;
  }
  .pd-xl-l-180 {
    padding-left: 180px;
  }
  .pd-xl-t-180-f {
    padding-top: 180px !important;
  }
  .pd-xl-r-180-f {
    padding-right: 180px !important;
  }
  .pd-xl-b-180-f {
    padding-bottom: 180px !important;
  }
  .pd-xl-l-180-f {
    padding-left: 180px !important;
  }
  .pd-xl-190 {
    padding: 190px;
  }
  .pd-xl-190-f {
    padding: 190px !important;
  }
  .pd-xl-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
  }
  .pd-xl-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
  }
  .pd-xl-x-190 {
    padding-left: 190px;
    padding-right: 190px;
  }
  .pd-xl-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important;
  }
  .pd-xl-t-190 {
    padding-top: 190px;
  }
  .pd-xl-r-190 {
    padding-right: 190px;
  }
  .pd-xl-b-190 {
    padding-bottom: 190px;
  }
  .pd-xl-l-190 {
    padding-left: 190px;
  }
  .pd-xl-t-190-f {
    padding-top: 190px !important;
  }
  .pd-xl-r-190-f {
    padding-right: 190px !important;
  }
  .pd-xl-b-190-f {
    padding-bottom: 190px !important;
  }
  .pd-xl-l-190-f {
    padding-left: 190px !important;
  }
  .pd-xl-200 {
    padding: 200px;
  }
  .pd-xl-200-f {
    padding: 200px !important;
  }
  .pd-xl-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .pd-xl-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .pd-xl-x-200 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .pd-xl-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .pd-xl-t-200 {
    padding-top: 200px;
  }
  .pd-xl-r-200 {
    padding-right: 200px;
  }
  .pd-xl-b-200 {
    padding-bottom: 200px;
  }
  .pd-xl-l-200 {
    padding-left: 200px;
  }
  .pd-xl-t-200-f {
    padding-top: 200px !important;
  }
  .pd-xl-r-200-f {
    padding-right: 200px !important;
  }
  .pd-xl-b-200-f {
    padding-bottom: 200px !important;
  }
  .pd-xl-l-200-f {
    padding-left: 200px !important;
  }
}

.pd-12 {
  padding: 12px;
}

.pd-12-f {
  padding: 12px !important;
}

.pd-y-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pd-y-12-f {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pd-x-12 {
  padding-left: 12px;
  padding-right: 12px;
}

.pd-x-12-f {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pd-t-12 {
  padding-top: 12px;
}

.pd-r-12 {
  padding-right: 12px;
}

.pd-b-12 {
  padding-bottom: 12px;
}

.pd-l-12 {
  padding-left: 12px;
}

.pd-t-12-f {
  padding-top: 12px !important;
}

.pd-r-12-f {
  padding-right: 12px !important;
}

.pd-b-12-f {
  padding-bottom: 12px !important;
}

.pd-l-12-f {
  padding-left: 12px !important;
}

.pd-13 {
  padding: 13px;
}

.pd-13-f {
  padding: 13px !important;
}

.pd-y-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}

.pd-y-13-f {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pd-x-13 {
  padding-left: 13px;
  padding-right: 13px;
}

.pd-x-13-f {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.pd-t-13 {
  padding-top: 13px;
}

.pd-r-13 {
  padding-right: 13px;
}

.pd-b-13 {
  padding-bottom: 13px;
}

.pd-l-13 {
  padding-left: 13px;
}

.pd-t-13-f {
  padding-top: 13px !important;
}

.pd-r-13-f {
  padding-right: 13px !important;
}

.pd-b-13-f {
  padding-bottom: 13px !important;
}

.pd-l-13-f {
  padding-left: 13px !important;
}

/* ###### 9.7 Position   ###### */

.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.pos-static {
  position: static;
}

.pos-absolute-f {
  position: absolute !important;
}

.pos-relative-f {
  position: relative !important;
}

.pos-fixed-f {
  position: fixed !important;
}

.pos-static-f {
  position: static !important;
}

.t-0 {
  top: 0px;
}

.r-0 {
  right: 0px;
}

.b-0 {
  bottom: 0px;
}

.l-0 {
  left: 0px;
}

.t--0 {
  top: -0px;
}

.r--0 {
  right: -0px;
}

.b--0 {
  bottom: -0px;
}

.l--0 {
  left: -0px;
}

.a-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.x-0 {
  left: 0px;
  right: 0px;
}

.y-0 {
  left: 0px;
  right: 0px;
}

.t-5 {
  top: 5px;
}

.r-5 {
  right: 5px;
}

.b-5 {
  bottom: 5px;
}

.l-5 {
  left: 5px;
}

.t--5 {
  top: -5px;
}

.r--5 {
  right: -5px;
}

.b--5 {
  bottom: -5px;
}

.l--5 {
  left: -5px;
}

.a-5 {
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.x-5 {
  left: 5px;
  right: 5px;
}

.y-5 {
  left: 5px;
  right: 5px;
}

.t-10 {
  top: 10px;
}

.r-10 {
  right: 10px;
}

.b-10 {
  bottom: 10px;
}

.l-10 {
  left: 10px;
}

.t--10 {
  top: -10px;
}

.r--10 {
  right: -10px;
}

.b--10 {
  bottom: -10px;
}

.l--10 {
  left: -10px;
}

.a-10 {
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
}

.x-10 {
  left: 10px;
  right: 10px;
}

.y-10 {
  left: 10px;
  right: 10px;
}

.t-15 {
  top: 15px;
}

.r-15 {
  right: 15px;
}

.b-15 {
  bottom: 15px;
}

.l-15 {
  left: 15px;
}

.t--15 {
  top: -15px;
}

.r--15 {
  right: -15px;
}

.b--15 {
  bottom: -15px;
}

.l--15 {
  left: -15px;
}

.a-15 {
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
}

.x-15 {
  left: 15px;
  right: 15px;
}

.y-15 {
  left: 15px;
  right: 15px;
}

.t-20 {
  top: 20px;
}

.r-20 {
  right: 20px;
}

.b-20 {
  bottom: 20px;
}

.l-20 {
  left: 20px;
}

.t--20 {
  top: -20px;
}

.r--20 {
  right: -20px;
}

.b--20 {
  bottom: -20px;
}

.l--20 {
  left: -20px;
}

.a-20 {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
}

.x-20 {
  left: 20px;
  right: 20px;
}

.y-20 {
  left: 20px;
  right: 20px;
}

.t-25 {
  top: 25px;
}

.r-25 {
  right: 25px;
}

.b-25 {
  bottom: 25px;
}

.l-25 {
  left: 25px;
}

.t--25 {
  top: -25px;
}

.r--25 {
  right: -25px;
}

.b--25 {
  bottom: -25px;
}

.l--25 {
  left: -25px;
}

.a-25 {
  top: 25px;
  right: 25px;
  bottom: 25px;
  left: 25px;
}

.x-25 {
  left: 25px;
  right: 25px;
}

.y-25 {
  left: 25px;
  right: 25px;
}

.t-30 {
  top: 30px;
}

.r-30 {
  right: 30px;
}

.b-30 {
  bottom: 30px;
}

.l-30 {
  left: 30px;
}

.t--30 {
  top: -30px;
}

.r--30 {
  right: -30px;
}

.b--30 {
  bottom: -30px;
}

.l--30 {
  left: -30px;
}

.a-30 {
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
}

.x-30 {
  left: 30px;
  right: 30px;
}

.y-30 {
  left: 30px;
  right: 30px;
}

.t-35 {
  top: 35px;
}

.r-35 {
  right: 35px;
}

.b-35 {
  bottom: 35px;
}

.l-35 {
  left: 35px;
}

.t--35 {
  top: -35px;
}

.r--35 {
  right: -35px;
}

.b--35 {
  bottom: -35px;
}

.l--35 {
  left: -35px;
}

.a-35 {
  top: 35px;
  right: 35px;
  bottom: 35px;
  left: 35px;
}

.x-35 {
  left: 35px;
  right: 35px;
}

.y-35 {
  left: 35px;
  right: 35px;
}

.t-40 {
  top: 40px;
}

.r-40 {
  right: 40px;
}

.b-40 {
  bottom: 40px;
}

.l-40 {
  left: 40px;
}

.t--40 {
  top: -40px;
}

.r--40 {
  right: -40px;
}

.b--40 {
  bottom: -40px;
}

.l--40 {
  left: -40px;
}

.a-40 {
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;
}

.x-40 {
  left: 40px;
  right: 40px;
}

.y-40 {
  left: 40px;
  right: 40px;
}

.t-45 {
  top: 45px;
}

.r-45 {
  right: 45px;
}

.b-45 {
  bottom: 45px;
}

.l-45 {
  left: 45px;
}

.t--45 {
  top: -45px;
}

.r--45 {
  right: -45px;
}

.b--45 {
  bottom: -45px;
}

.l--45 {
  left: -45px;
}

.a-45 {
  top: 45px;
  right: 45px;
  bottom: 45px;
  left: 45px;
}

.x-45 {
  left: 45px;
  right: 45px;
}

.y-45 {
  left: 45px;
  right: 45px;
}

.t-50 {
  top: 50px;
}

.r-50 {
  right: 50px;
}

.b-50 {
  bottom: 50px;
}

.l-50 {
  left: 50px;
}

.t--50 {
  top: -50px;
}

.r--50 {
  right: -50px;
}

.b--50 {
  bottom: -50px;
}

.l--50 {
  left: -50px;
}

.a-50 {
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
}

.x-50 {
  left: 50px;
  right: 50px;
}

.y-50 {
  left: 50px;
  right: 50px;
}

.t-55 {
  top: 55px;
}

.r-55 {
  right: 55px;
}

.b-55 {
  bottom: 55px;
}

.l-55 {
  left: 55px;
}

.t--55 {
  top: -55px;
}

.r--55 {
  right: -55px;
}

.b--55 {
  bottom: -55px;
}

.l--55 {
  left: -55px;
}

.a-55 {
  top: 55px;
  right: 55px;
  bottom: 55px;
  left: 55px;
}

.x-55 {
  left: 55px;
  right: 55px;
}

.y-55 {
  left: 55px;
  right: 55px;
}

.t-60 {
  top: 60px;
}

.r-60 {
  right: 60px;
}

.b-60 {
  bottom: 60px;
}

.l-60 {
  left: 60px;
}

.t--60 {
  top: -60px;
}

.r--60 {
  right: -60px;
}

.b--60 {
  bottom: -60px;
}

.l--60 {
  left: -60px;
}

.a-60 {
  top: 60px;
  right: 60px;
  bottom: 60px;
  left: 60px;
}

.x-60 {
  left: 60px;
  right: 60px;
}

.y-60 {
  left: 60px;
  right: 60px;
}

.t-65 {
  top: 65px;
}

.r-65 {
  right: 65px;
}

.b-65 {
  bottom: 65px;
}

.l-65 {
  left: 65px;
}

.t--65 {
  top: -65px;
}

.r--65 {
  right: -65px;
}

.b--65 {
  bottom: -65px;
}

.l--65 {
  left: -65px;
}

.a-65 {
  top: 65px;
  right: 65px;
  bottom: 65px;
  left: 65px;
}

.x-65 {
  left: 65px;
  right: 65px;
}

.y-65 {
  left: 65px;
  right: 65px;
}

.t-70 {
  top: 70px;
}

.r-70 {
  right: 70px;
}

.b-70 {
  bottom: 70px;
}

.l-70 {
  left: 70px;
}

.t--70 {
  top: -70px;
}

.r--70 {
  right: -70px;
}

.b--70 {
  bottom: -70px;
}

.l--70 {
  left: -70px;
}

.a-70 {
  top: 70px;
  right: 70px;
  bottom: 70px;
  left: 70px;
}

.x-70 {
  left: 70px;
  right: 70px;
}

.y-70 {
  left: 70px;
  right: 70px;
}

.t-75 {
  top: 75px;
}

.r-75 {
  right: 75px;
}

.b-75 {
  bottom: 75px;
}

.l-75 {
  left: 75px;
}

.t--75 {
  top: -75px;
}

.r--75 {
  right: -75px;
}

.b--75 {
  bottom: -75px;
}

.l--75 {
  left: -75px;
}

.a-75 {
  top: 75px;
  right: 75px;
  bottom: 75px;
  left: 75px;
}

.x-75 {
  left: 75px;
  right: 75px;
}

.y-75 {
  left: 75px;
  right: 75px;
}

.t-80 {
  top: 80px;
}

.r-80 {
  right: 80px;
}

.b-80 {
  bottom: 80px;
}

.l-80 {
  left: 80px;
}

.t--80 {
  top: -80px;
}

.r--80 {
  right: -80px;
}

.b--80 {
  bottom: -80px;
}

.l--80 {
  left: -80px;
}

.a-80 {
  top: 80px;
  right: 80px;
  bottom: 80px;
  left: 80px;
}

.x-80 {
  left: 80px;
  right: 80px;
}

.y-80 {
  left: 80px;
  right: 80px;
}

.t-85 {
  top: 85px;
}

.r-85 {
  right: 85px;
}

.b-85 {
  bottom: 85px;
}

.l-85 {
  left: 85px;
}

.t--85 {
  top: -85px;
}

.r--85 {
  right: -85px;
}

.b--85 {
  bottom: -85px;
}

.l--85 {
  left: -85px;
}

.a-85 {
  top: 85px;
  right: 85px;
  bottom: 85px;
  left: 85px;
}

.x-85 {
  left: 85px;
  right: 85px;
}

.y-85 {
  left: 85px;
  right: 85px;
}

.t-90 {
  top: 90px;
}

.r-90 {
  right: 90px;
}

.b-90 {
  bottom: 90px;
}

.l-90 {
  left: 90px;
}

.t--90 {
  top: -90px;
}

.r--90 {
  right: -90px;
}

.b--90 {
  bottom: -90px;
}

.l--90 {
  left: -90px;
}

.a-90 {
  top: 90px;
  right: 90px;
  bottom: 90px;
  left: 90px;
}

.x-90 {
  left: 90px;
  right: 90px;
}

.y-90 {
  left: 90px;
  right: 90px;
}

.t-95 {
  top: 95px;
}

.r-95 {
  right: 95px;
}

.b-95 {
  bottom: 95px;
}

.l-95 {
  left: 95px;
}

.t--95 {
  top: -95px;
}

.r--95 {
  right: -95px;
}

.b--95 {
  bottom: -95px;
}

.l--95 {
  left: -95px;
}

.a-95 {
  top: 95px;
  right: 95px;
  bottom: 95px;
  left: 95px;
}

.x-95 {
  left: 95px;
  right: 95px;
}

.y-95 {
  left: 95px;
  right: 95px;
}

.t-100 {
  top: 100px;
}

.r-100 {
  right: 100px;
}

.b-100 {
  bottom: 100px;
}

.l-100 {
  left: 100px;
}

.t--100 {
  top: -100px;
}

.r--100 {
  right: -100px;
}

.b--100 {
  bottom: -100px;
}

.l--100 {
  left: -100px;
}

.a-100 {
  top: 100px;
  right: 100px;
  bottom: 100px;
  left: 100px;
}

.x-100 {
  left: 100px;
  right: 100px;
}

.y-100 {
  left: 100px;
  right: 100px;
}

.t-2 {
  top: 2px;
}

.r--5 {
  right: -5px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-10 {
  z-index: 10;
}

.z-index-50 {
  z-index: 50;
}

.z-index-100 {
  z-index: 100;
}

.z-index-150 {
  z-index: 150;
}

.z-index-200 {
  z-index: 200;
}

@media (min-width: 480px) {
  .pos-xs-absolute {
    position: absolute;
  }
  .pos-xs-relative {
    position: relative;
  }
  .pos-xs-fixed {
    position: fixed;
  }
  .pos-xs-static {
    position: static;
  }
  .t-xs-auto {
    top: auto;
  }
  .r-xs-auto {
    right: auto;
  }
  .b-xs-auto {
    bottom: auto;
  }
  .l-xs-auto {
    left: auto;
  }
}

@media (min-width: 576px) {
  .pos-sm-absolute {
    position: absolute;
  }
  .pos-sm-relative {
    position: relative;
  }
  .pos-sm-fixed {
    position: fixed;
  }
  .pos-sm-static {
    position: static;
  }
  .t-sm-auto {
    top: auto;
  }
  .r-sm-auto {
    right: auto;
  }
  .b-sm-auto {
    bottom: auto;
  }
  .l-sm-auto {
    left: auto;
  }
}

@media (min-width: 768px) {
  .pos-md-absolute {
    position: absolute;
  }
  .pos-md-relative {
    position: relative;
  }
  .pos-md-fixed {
    position: fixed;
  }
  .pos-md-static {
    position: static;
  }
  .t-md-auto {
    top: auto;
  }
  .r-md-auto {
    right: auto;
  }
  .b-md-auto {
    bottom: auto;
  }
  .l-md-auto {
    left: auto;
  }
}

@media (min-width: 992px) {
  .pos-lg-absolute {
    position: absolute;
  }
  .pos-lg-relative {
    position: relative;
  }
  .pos-lg-fixed {
    position: fixed;
  }
  .pos-lg-static {
    position: static;
  }
  .t-lg-auto {
    top: auto;
  }
  .r-lg-auto {
    right: auto;
  }
  .b-lg-auto {
    bottom: auto;
  }
  .l-lg-auto {
    left: auto;
  }
}

@media (min-width: 1200px) {
  .pos-xl-absolute {
    position: absolute;
  }
  .pos-xl-relative {
    position: relative;
  }
  .pos-xl-fixed {
    position: fixed;
  }
  .pos-xl-static {
    position: static;
  }
  .t-xl-auto {
    top: auto;
  }
  .r-xl-auto {
    right: auto;
  }
  .b-xl-auto {
    bottom: auto;
  }
  .l-xl-auto {
    left: auto;
  }
}

/* ###### 9.8 Typography   ###### */

.tx-black {
  font-weight: 900;
}

.tx-bold {
  font-weight: 700;
}

.tx-semibold {
  font-weight: 600;
}

.tx-medium {
  font-weight: 500;
}

.tx-normal {
  font-weight: 400;
}

.tx-light {
  font-weight: 300;
}

.tx-thin {
  font-weight: 200;
}

.tx-xthin {
  font-weight: 100;
}

.tx-sserif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
  font-family: "Roboto", sans-serif;
}

.tx-white {
  color: #fff;
}

.tx-black {
  color: #000;
}

.tx-primary {
  color: #3366ff;
}

.tx-success {
  color: #3bb001;
}

.tx-warning {
  color: #ffc107;
}

.tx-danger {
  color: #dc3545;
}

.tx-info {
  color: #17a2b8;
}

.tx-inverse {
  color: #1c273c;
}

.tx-teal {
  color: #00cccc;
}

.tx-dark {
  color: #1c273c;
}

.tx-indigo {
  color: #5b47fb;
}

.tx-purple {
  color: #6f42c1;
}

.tx-orange {
  color: #fd7e14;
}

.tx-pink {
  color: #f10075;
}

.tx-gray-100 {
  color: #f4f5f8;
}

.tx-gray-200 {
  color: #e3e7ed;
}

.tx-gray-300 {
  color: #cdd4e0;
}

.tx-gray-400 {
  color: #b4bdce;
}

.tx-gray-500 {
  color: #97a3b9;
}

.tx-gray-600 {
  color: #7987a1;
}

.tx-gray-700 {
  color: #596882;
}

.tx-gray-800 {
  color: #3b4863;
}

.tx-gray-900 {
  color: #1c273c;
}

.tx-white-2 {
  color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
  color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
  color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
  color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
  color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
  color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
  color: rgba(255, 255, 255, 0.8);
}

.tx-spacing-1 {
  letter-spacing: 0.5px;
}

.tx-spacing-2 {
  letter-spacing: 1px;
}

.tx-spacing-3 {
  letter-spacing: 1.5px;
}

.tx-spacing-4 {
  letter-spacing: 2px;
}

.tx-spacing-5 {
  letter-spacing: 2.5px;
}

.tx-spacing-6 {
  letter-spacing: 3px;
}

.tx-spacing-7 {
  letter-spacing: 3.5px;
}

.tx-spacing-8 {
  letter-spacing: 4px;
}

.tx-spacing--1 {
  letter-spacing: -0.5px;
}

.tx-spacing--2 {
  letter-spacing: -1px;
}

.tx-spacing--3 {
  letter-spacing: -1.5px;
}

.tx-spacing--4 {
  letter-spacing: -2px;
}

.tx-spacing--5 {
  letter-spacing: -2.5px;
}

.tx-spacing--6 {
  letter-spacing: -3px;
}

.tx-spacing--7 {
  letter-spacing: -3.5px;
}

.tx-spacing--8 {
  letter-spacing: -4px;
}

.tx-uppercase {
  text-transform: uppercase;
}

.tx-lowercase {
  text-transform: lowercase;
}

.tx-transform-none {
  text-transform: none;
}

.tx-center {
  text-align: center;
}

.tx-right {
  text-align: right;
}

.tx-left {
  text-align: left;
}

.tx-center-f {
  text-align: center !important;
}

.tx-right-f {
  text-align: right !important;
}

.tx-left-f {
  text-align: left !important;
}

.tx-italic {
  font-style: italic;
}

.tx-style-normal {
  font-style: normal;
}

.lh-base {
  line-height: 1.5;
}

.lh-normal {
  line-height: normal;
}

.lh-0 {
  line-height: 0;
}

.lh--1 {
  line-height: 0.1;
}

.lh--2 {
  line-height: 0.2;
}

.lh--3 {
  line-height: 0.3;
}

.lh--4 {
  line-height: 0.4;
}

.lh--5 {
  line-height: 0.5;
}

.lh--6 {
  line-height: 0.6;
}

.lh--7 {
  line-height: 0.7;
}

.lh--8 {
  line-height: 0.8;
}

.lh--9 {
  line-height: 0.9;
}

.lh-1 {
  line-height: 1.1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.3;
}

.lh-4 {
  line-height: 1.4;
}

.lh-5 {
  line-height: 1.5;
}

.lh-6 {
  line-height: 1.6;
}

.lh-7 {
  line-height: 1.7;
}

.lh-8 {
  line-height: 1.8;
}

.lh-9 {
  line-height: 1.9;
}

.lh-10 {
  line-height: 2;
}

.lh-11 {
  line-height: 2.1;
}

.lh-12 {
  line-height: 2.2;
}

.lh-13 {
  line-height: 2.3;
}

.lh-14 {
  line-height: 2.4;
}

.lh-15 {
  line-height: 2.5;
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: baseline;
}

.valign-top-f {
  vertical-align: top !important;
}

.valign-middle-f {
  vertical-align: middle !important;
}

.valign-bottom-f {
  vertical-align: baseline !important;
}

.tx-base {
  font-size: 0.875rem;
}

.tx-8 {
  font-size: 8px;
}

.tx-8-f {
  font-size: 8px !important;
}

.tx-9 {
  font-size: 9px;
}

.tx-9-f {
  font-size: 9px !important;
}

.tx-10 {
  font-size: 10px;
}

.tx-10-f {
  font-size: 10px !important;
}

.tx-11 {
  font-size: 11px;
}

.tx-11-f {
  font-size: 11px !important;
}

.tx-12 {
  font-size: 12px;
}

.tx-12-f {
  font-size: 12px !important;
}

.tx-13 {
  font-size: 13px;
}

.tx-13-f {
  font-size: 13px !important;
}

.tx-14 {
  font-size: 14px;
}

.tx-14-f {
  font-size: 14px !important;
}

.tx-15 {
  font-size: 15px;
}

.tx-15-f {
  font-size: 15px !important;
}

.tx-16 {
  font-size: 16px;
}

.tx-16-f {
  font-size: 16px !important;
}

.tx-18 {
  font-size: 18px;
}

.tx-18-f {
  font-size: 18px !important;
}

.tx-20 {
  font-size: 20px;
}

.tx-20-f {
  font-size: 20px !important;
}

.tx-22 {
  font-size: 22px;
}

.tx-22-f {
  font-size: 22px !important;
}

.tx-24 {
  font-size: 24px;
}

.tx-24-f {
  font-size: 24px !important;
}

.tx-26 {
  font-size: 26px;
}

.tx-26-f {
  font-size: 26px !important;
}

.tx-28 {
  font-size: 28px;
}

.tx-28-f {
  font-size: 28px !important;
}

.tx-30 {
  font-size: 30px;
}

.tx-30-f {
  font-size: 30px !important;
}

.tx-32 {
  font-size: 32px;
}

.tx-32-f {
  font-size: 32px !important;
}

.tx-34 {
  font-size: 34px;
}

.tx-34-f {
  font-size: 34px !important;
}

.tx-36 {
  font-size: 36px;
}

.tx-36-f {
  font-size: 36px !important;
}

.tx-38 {
  font-size: 38px;
}

.tx-38-f {
  font-size: 38px !important;
}

.tx-40 {
  font-size: 40px;
}

.tx-40-f {
  font-size: 40px !important;
}

.tx-42 {
  font-size: 42px;
}

.tx-42-f {
  font-size: 42px !important;
}

.tx-44 {
  font-size: 44px;
}

.tx-44-f {
  font-size: 44px !important;
}

.tx-46 {
  font-size: 46px;
}

.tx-46-f {
  font-size: 46px !important;
}

.tx-48 {
  font-size: 48px;
}

.tx-48-f {
  font-size: 48px !important;
}

.tx-50 {
  font-size: 50px;
}

.tx-50-f {
  font-size: 50px !important;
}

.tx-52 {
  font-size: 52px;
}

.tx-52-f {
  font-size: 52px !important;
}

.tx-54 {
  font-size: 54px;
}

.tx-54-f {
  font-size: 54px !important;
}

.tx-56 {
  font-size: 56px;
}

.tx-56-f {
  font-size: 56px !important;
}

.tx-58 {
  font-size: 58px;
}

.tx-58-f {
  font-size: 58px !important;
}

.tx-60 {
  font-size: 60px;
}

.tx-60-f {
  font-size: 60px !important;
}

.tx-62 {
  font-size: 62px;
}

.tx-62-f {
  font-size: 62px !important;
}

.tx-64 {
  font-size: 64px;
}

.tx-64-f {
  font-size: 64px !important;
}

.tx-66 {
  font-size: 66px;
}

.tx-66-f {
  font-size: 66px !important;
}

.tx-68 {
  font-size: 68px;
}

.tx-68-f {
  font-size: 68px !important;
}

.tx-70 {
  font-size: 70px;
}

.tx-70-f {
  font-size: 70px !important;
}

.tx-72 {
  font-size: 72px;
}

.tx-72-f {
  font-size: 72px !important;
}

.tx-74 {
  font-size: 74px;
}

.tx-74-f {
  font-size: 74px !important;
}

.tx-76 {
  font-size: 76px;
}

.tx-76-f {
  font-size: 76px !important;
}

.tx-78 {
  font-size: 78px;
}

.tx-78-f {
  font-size: 78px !important;
}

.tx-80 {
  font-size: 80px;
}

.tx-80-f {
  font-size: 80px !important;
}

.tx-82 {
  font-size: 82px;
}

.tx-82-f {
  font-size: 82px !important;
}

.tx-84 {
  font-size: 84px;
}

.tx-84-f {
  font-size: 84px !important;
}

.tx-86 {
  font-size: 86px;
}

.tx-86-f {
  font-size: 86px !important;
}

.tx-88 {
  font-size: 88px;
}

.tx-88-f {
  font-size: 88px !important;
}

.tx-90 {
  font-size: 90px;
}

.tx-90-f {
  font-size: 90px !important;
}

.tx-92 {
  font-size: 92px;
}

.tx-92-f {
  font-size: 92px !important;
}

.tx-94 {
  font-size: 94px;
}

.tx-94-f {
  font-size: 94px !important;
}

.tx-96 {
  font-size: 96px;
}

.tx-96-f {
  font-size: 96px !important;
}

.tx-98 {
  font-size: 98px;
}

.tx-98-f {
  font-size: 98px !important;
}

.tx-100 {
  font-size: 100px;
}

.tx-100-f {
  font-size: 100px !important;
}

.tx-102 {
  font-size: 102px;
}

.tx-102-f {
  font-size: 102px !important;
}

.tx-104 {
  font-size: 104px;
}

.tx-104-f {
  font-size: 104px !important;
}

.tx-106 {
  font-size: 106px;
}

.tx-106-f {
  font-size: 106px !important;
}

.tx-108 {
  font-size: 108px;
}

.tx-108-f {
  font-size: 108px !important;
}

.tx-110 {
  font-size: 110px;
}

.tx-110-f {
  font-size: 110px !important;
}

.tx-112 {
  font-size: 112px;
}

.tx-112-f {
  font-size: 112px !important;
}

.tx-114 {
  font-size: 114px;
}

.tx-114-f {
  font-size: 114px !important;
}

.tx-116 {
  font-size: 116px;
}

.tx-116-f {
  font-size: 116px !important;
}

.tx-118 {
  font-size: 118px;
}

.tx-118-f {
  font-size: 118px !important;
}

.tx-120 {
  font-size: 120px;
}

.tx-120-f {
  font-size: 120px !important;
}

.tx-122 {
  font-size: 122px;
}

.tx-122-f {
  font-size: 122px !important;
}

.tx-124 {
  font-size: 124px;
}

.tx-124-f {
  font-size: 124px !important;
}

.tx-126 {
  font-size: 126px;
}

.tx-126-f {
  font-size: 126px !important;
}

.tx-128 {
  font-size: 128px;
}

.tx-128-f {
  font-size: 128px !important;
}

.tx-130 {
  font-size: 130px;
}

.tx-130-f {
  font-size: 130px !important;
}

.tx-132 {
  font-size: 132px;
}

.tx-132-f {
  font-size: 132px !important;
}

.tx-134 {
  font-size: 134px;
}

.tx-134-f {
  font-size: 134px !important;
}

.tx-136 {
  font-size: 136px;
}

.tx-136-f {
  font-size: 136px !important;
}

.tx-138 {
  font-size: 138px;
}

.tx-138-f {
  font-size: 138px !important;
}

.tx-140 {
  font-size: 140px;
}

.tx-140-f {
  font-size: 140px !important;
}

@media (min-width: 480px) {
  .tx-xs-8 {
    font-size: 8px;
  }
  .tx-xs-8-f {
    font-size: 8px !important;
  }
  .tx-xs-9 {
    font-size: 9px;
  }
  .tx-xs-9-f {
    font-size: 9px !important;
  }
  .tx-xs-10 {
    font-size: 10px;
  }
  .tx-xs-10-f {
    font-size: 10px !important;
  }
  .tx-xs-11 {
    font-size: 11px;
  }
  .tx-xs-11-f {
    font-size: 11px !important;
  }
  .tx-xs-12 {
    font-size: 12px;
  }
  .tx-xs-12-f {
    font-size: 12px !important;
  }
  .tx-xs-13 {
    font-size: 13px;
  }
  .tx-xs-13-f {
    font-size: 13px !important;
  }
  .tx-xs-14 {
    font-size: 14px;
  }
  .tx-xs-14-f {
    font-size: 14px !important;
  }
  .tx-xs-15 {
    font-size: 15px;
  }
  .tx-xs-15-f {
    font-size: 15px !important;
  }
  .tx-xs-16 {
    font-size: 16px;
  }
  .tx-xs-16-f {
    font-size: 16px !important;
  }
  .tx-xs-18 {
    font-size: 18px;
  }
  .tx-xs-18-f {
    font-size: 18px !important;
  }
  .tx-xs-20 {
    font-size: 20px;
  }
  .tx-xs-20-f {
    font-size: 20px !important;
  }
  .tx-xs-22 {
    font-size: 22px;
  }
  .tx-xs-22-f {
    font-size: 22px !important;
  }
  .tx-xs-24 {
    font-size: 24px;
  }
  .tx-xs-24-f {
    font-size: 24px !important;
  }
  .tx-xs-26 {
    font-size: 26px;
  }
  .tx-xs-26-f {
    font-size: 26px !important;
  }
  .tx-xs-28 {
    font-size: 28px;
  }
  .tx-xs-28-f {
    font-size: 28px !important;
  }
  .tx-xs-30 {
    font-size: 30px;
  }
  .tx-xs-30-f {
    font-size: 30px !important;
  }
  .tx-xs-32 {
    font-size: 32px;
  }
  .tx-xs-32-f {
    font-size: 32px !important;
  }
  .tx-xs-34 {
    font-size: 34px;
  }
  .tx-xs-34-f {
    font-size: 34px !important;
  }
  .tx-xs-36 {
    font-size: 36px;
  }
  .tx-xs-36-f {
    font-size: 36px !important;
  }
  .tx-xs-38 {
    font-size: 38px;
  }
  .tx-xs-38-f {
    font-size: 38px !important;
  }
  .tx-xs-40 {
    font-size: 40px;
  }
  .tx-xs-40-f {
    font-size: 40px !important;
  }
  .tx-xs-42 {
    font-size: 42px;
  }
  .tx-xs-42-f {
    font-size: 42px !important;
  }
  .tx-xs-44 {
    font-size: 44px;
  }
  .tx-xs-44-f {
    font-size: 44px !important;
  }
  .tx-xs-46 {
    font-size: 46px;
  }
  .tx-xs-46-f {
    font-size: 46px !important;
  }
  .tx-xs-48 {
    font-size: 48px;
  }
  .tx-xs-48-f {
    font-size: 48px !important;
  }
  .tx-xs-50 {
    font-size: 50px;
  }
  .tx-xs-50-f {
    font-size: 50px !important;
  }
  .tx-xs-52 {
    font-size: 52px;
  }
  .tx-xs-52-f {
    font-size: 52px !important;
  }
  .tx-xs-54 {
    font-size: 54px;
  }
  .tx-xs-54-f {
    font-size: 54px !important;
  }
  .tx-xs-56 {
    font-size: 56px;
  }
  .tx-xs-56-f {
    font-size: 56px !important;
  }
  .tx-xs-58 {
    font-size: 58px;
  }
  .tx-xs-58-f {
    font-size: 58px !important;
  }
  .tx-xs-60 {
    font-size: 60px;
  }
  .tx-xs-60-f {
    font-size: 60px !important;
  }
  .tx-xs-62 {
    font-size: 62px;
  }
  .tx-xs-62-f {
    font-size: 62px !important;
  }
  .tx-xs-64 {
    font-size: 64px;
  }
  .tx-xs-64-f {
    font-size: 64px !important;
  }
  .tx-xs-66 {
    font-size: 66px;
  }
  .tx-xs-66-f {
    font-size: 66px !important;
  }
  .tx-xs-68 {
    font-size: 68px;
  }
  .tx-xs-68-f {
    font-size: 68px !important;
  }
  .tx-xs-70 {
    font-size: 70px;
  }
  .tx-xs-70-f {
    font-size: 70px !important;
  }
  .tx-xs-72 {
    font-size: 72px;
  }
  .tx-xs-72-f {
    font-size: 72px !important;
  }
  .tx-xs-74 {
    font-size: 74px;
  }
  .tx-xs-74-f {
    font-size: 74px !important;
  }
  .tx-xs-76 {
    font-size: 76px;
  }
  .tx-xs-76-f {
    font-size: 76px !important;
  }
  .tx-xs-78 {
    font-size: 78px;
  }
  .tx-xs-78-f {
    font-size: 78px !important;
  }
  .tx-xs-80 {
    font-size: 80px;
  }
  .tx-xs-80-f {
    font-size: 80px !important;
  }
  .tx-xs-82 {
    font-size: 82px;
  }
  .tx-xs-82-f {
    font-size: 82px !important;
  }
  .tx-xs-84 {
    font-size: 84px;
  }
  .tx-xs-84-f {
    font-size: 84px !important;
  }
  .tx-xs-86 {
    font-size: 86px;
  }
  .tx-xs-86-f {
    font-size: 86px !important;
  }
  .tx-xs-88 {
    font-size: 88px;
  }
  .tx-xs-88-f {
    font-size: 88px !important;
  }
  .tx-xs-90 {
    font-size: 90px;
  }
  .tx-xs-90-f {
    font-size: 90px !important;
  }
  .tx-xs-92 {
    font-size: 92px;
  }
  .tx-xs-92-f {
    font-size: 92px !important;
  }
  .tx-xs-94 {
    font-size: 94px;
  }
  .tx-xs-94-f {
    font-size: 94px !important;
  }
  .tx-xs-96 {
    font-size: 96px;
  }
  .tx-xs-96-f {
    font-size: 96px !important;
  }
  .tx-xs-98 {
    font-size: 98px;
  }
  .tx-xs-98-f {
    font-size: 98px !important;
  }
  .tx-xs-100 {
    font-size: 100px;
  }
  .tx-xs-100-f {
    font-size: 100px !important;
  }
  .tx-xs-102 {
    font-size: 102px;
  }
  .tx-xs-102-f {
    font-size: 102px !important;
  }
  .tx-xs-104 {
    font-size: 104px;
  }
  .tx-xs-104-f {
    font-size: 104px !important;
  }
  .tx-xs-106 {
    font-size: 106px;
  }
  .tx-xs-106-f {
    font-size: 106px !important;
  }
  .tx-xs-108 {
    font-size: 108px;
  }
  .tx-xs-108-f {
    font-size: 108px !important;
  }
  .tx-xs-110 {
    font-size: 110px;
  }
  .tx-xs-110-f {
    font-size: 110px !important;
  }
  .tx-xs-112 {
    font-size: 112px;
  }
  .tx-xs-112-f {
    font-size: 112px !important;
  }
  .tx-xs-114 {
    font-size: 114px;
  }
  .tx-xs-114-f {
    font-size: 114px !important;
  }
  .tx-xs-116 {
    font-size: 116px;
  }
  .tx-xs-116-f {
    font-size: 116px !important;
  }
  .tx-xs-118 {
    font-size: 118px;
  }
  .tx-xs-118-f {
    font-size: 118px !important;
  }
  .tx-xs-120 {
    font-size: 120px;
  }
  .tx-xs-120-f {
    font-size: 120px !important;
  }
  .tx-xs-122 {
    font-size: 122px;
  }
  .tx-xs-122-f {
    font-size: 122px !important;
  }
  .tx-xs-124 {
    font-size: 124px;
  }
  .tx-xs-124-f {
    font-size: 124px !important;
  }
  .tx-xs-126 {
    font-size: 126px;
  }
  .tx-xs-126-f {
    font-size: 126px !important;
  }
  .tx-xs-128 {
    font-size: 128px;
  }
  .tx-xs-128-f {
    font-size: 128px !important;
  }
  .tx-xs-130 {
    font-size: 130px;
  }
  .tx-xs-130-f {
    font-size: 130px !important;
  }
  .tx-xs-132 {
    font-size: 132px;
  }
  .tx-xs-132-f {
    font-size: 132px !important;
  }
  .tx-xs-134 {
    font-size: 134px;
  }
  .tx-xs-134-f {
    font-size: 134px !important;
  }
  .tx-xs-136 {
    font-size: 136px;
  }
  .tx-xs-136-f {
    font-size: 136px !important;
  }
  .tx-xs-138 {
    font-size: 138px;
  }
  .tx-xs-138-f {
    font-size: 138px !important;
  }
  .tx-xs-140 {
    font-size: 140px;
  }
  .tx-xs-140-f {
    font-size: 140px !important;
  }
  .tx-xs-base {
    font-size: 0.875rem;
  }
  .tx-xs-bold {
    font-weight: 700;
  }
  .tx-xs-semibold {
    font-weight: 600;
  }
  .tx-xs-medium {
    font-weight: 500;
  }
  .tx-xs-normal {
    font-weight: 400;
  }
  .tx-xs-light {
    font-weight: 300;
  }
  .tx-xs-thin {
    font-weight: 200;
  }
  .tx-xs-xthin {
    font-weight: 100;
  }
  .lh-xs-0 {
    line-height: 0;
  }
  .lh-xs-1 {
    line-height: 1.1;
  }
  .lh-xs-2 {
    line-height: 1.2;
  }
  .lh-xs-3 {
    line-height: 1.3;
  }
  .lh-xs-4 {
    line-height: 1.4;
  }
  .lh-xs-5 {
    line-height: 1.5;
  }
  .lh-xs-6 {
    line-height: 1.6;
  }
  .lh-xs-7 {
    line-height: 1.7;
  }
  .lh-xs-8 {
    line-height: 1.8;
  }
  .lh-xs-9 {
    line-height: 1.9;
  }
  .lh-xs-10 {
    line-height: 2;
  }
  .lh-xs-11 {
    line-height: 2.1;
  }
  .lh-xs-12 {
    line-height: 2.2;
  }
  .lh-xs-13 {
    line-height: 2.3;
  }
  .lh-xs-14 {
    line-height: 2.4;
  }
  .lh-xs-15 {
    line-height: 2.5;
  }
  .tx-xs-center {
    text-align: center;
  }
  .tx-xs-right {
    text-align: right;
  }
  .tx-xs-left {
    text-align: left;
  }
  .tx-xs-center-f {
    text-align: center !important;
  }
  .tx-xs-right-f {
    text-align: right !important;
  }
  .tx-xs-left-f {
    text-align: left !important;
  }
}

@media (min-width: 576px) {
  .tx-sm-8 {
    font-size: 8px;
  }
  .tx-sm-8-f {
    font-size: 8px !important;
  }
  .tx-sm-9 {
    font-size: 9px;
  }
  .tx-sm-9-f {
    font-size: 9px !important;
  }
  .tx-sm-10 {
    font-size: 10px;
  }
  .tx-sm-10-f {
    font-size: 10px !important;
  }
  .tx-sm-11 {
    font-size: 11px;
  }
  .tx-sm-11-f {
    font-size: 11px !important;
  }
  .tx-sm-12 {
    font-size: 12px;
  }
  .tx-sm-12-f {
    font-size: 12px !important;
  }
  .tx-sm-13 {
    font-size: 13px;
  }
  .tx-sm-13-f {
    font-size: 13px !important;
  }
  .tx-sm-14 {
    font-size: 14px;
  }
  .tx-sm-14-f {
    font-size: 14px !important;
  }
  .tx-sm-15 {
    font-size: 15px;
  }
  .tx-sm-15-f {
    font-size: 15px !important;
  }
  .tx-sm-16 {
    font-size: 16px;
  }
  .tx-sm-16-f {
    font-size: 16px !important;
  }
  .tx-sm-18 {
    font-size: 18px;
  }
  .tx-sm-18-f {
    font-size: 18px !important;
  }
  .tx-sm-20 {
    font-size: 20px;
  }
  .tx-sm-20-f {
    font-size: 20px !important;
  }
  .tx-sm-22 {
    font-size: 22px;
  }
  .tx-sm-22-f {
    font-size: 22px !important;
  }
  .tx-sm-24 {
    font-size: 24px;
  }
  .tx-sm-24-f {
    font-size: 24px !important;
  }
  .tx-sm-26 {
    font-size: 26px;
  }
  .tx-sm-26-f {
    font-size: 26px !important;
  }
  .tx-sm-28 {
    font-size: 28px;
  }
  .tx-sm-28-f {
    font-size: 28px !important;
  }
  .tx-sm-30 {
    font-size: 30px;
  }
  .tx-sm-30-f {
    font-size: 30px !important;
  }
  .tx-sm-32 {
    font-size: 32px;
  }
  .tx-sm-32-f {
    font-size: 32px !important;
  }
  .tx-sm-34 {
    font-size: 34px;
  }
  .tx-sm-34-f {
    font-size: 34px !important;
  }
  .tx-sm-36 {
    font-size: 36px;
  }
  .tx-sm-36-f {
    font-size: 36px !important;
  }
  .tx-sm-38 {
    font-size: 38px;
  }
  .tx-sm-38-f {
    font-size: 38px !important;
  }
  .tx-sm-40 {
    font-size: 40px;
  }
  .tx-sm-40-f {
    font-size: 40px !important;
  }
  .tx-sm-42 {
    font-size: 42px;
  }
  .tx-sm-42-f {
    font-size: 42px !important;
  }
  .tx-sm-44 {
    font-size: 44px;
  }
  .tx-sm-44-f {
    font-size: 44px !important;
  }
  .tx-sm-46 {
    font-size: 46px;
  }
  .tx-sm-46-f {
    font-size: 46px !important;
  }
  .tx-sm-48 {
    font-size: 48px;
  }
  .tx-sm-48-f {
    font-size: 48px !important;
  }
  .tx-sm-50 {
    font-size: 50px;
  }
  .tx-sm-50-f {
    font-size: 50px !important;
  }
  .tx-sm-52 {
    font-size: 52px;
  }
  .tx-sm-52-f {
    font-size: 52px !important;
  }
  .tx-sm-54 {
    font-size: 54px;
  }
  .tx-sm-54-f {
    font-size: 54px !important;
  }
  .tx-sm-56 {
    font-size: 56px;
  }
  .tx-sm-56-f {
    font-size: 56px !important;
  }
  .tx-sm-58 {
    font-size: 58px;
  }
  .tx-sm-58-f {
    font-size: 58px !important;
  }
  .tx-sm-60 {
    font-size: 60px;
  }
  .tx-sm-60-f {
    font-size: 60px !important;
  }
  .tx-sm-62 {
    font-size: 62px;
  }
  .tx-sm-62-f {
    font-size: 62px !important;
  }
  .tx-sm-64 {
    font-size: 64px;
  }
  .tx-sm-64-f {
    font-size: 64px !important;
  }
  .tx-sm-66 {
    font-size: 66px;
  }
  .tx-sm-66-f {
    font-size: 66px !important;
  }
  .tx-sm-68 {
    font-size: 68px;
  }
  .tx-sm-68-f {
    font-size: 68px !important;
  }
  .tx-sm-70 {
    font-size: 70px;
  }
  .tx-sm-70-f {
    font-size: 70px !important;
  }
  .tx-sm-72 {
    font-size: 72px;
  }
  .tx-sm-72-f {
    font-size: 72px !important;
  }
  .tx-sm-74 {
    font-size: 74px;
  }
  .tx-sm-74-f {
    font-size: 74px !important;
  }
  .tx-sm-76 {
    font-size: 76px;
  }
  .tx-sm-76-f {
    font-size: 76px !important;
  }
  .tx-sm-78 {
    font-size: 78px;
  }
  .tx-sm-78-f {
    font-size: 78px !important;
  }
  .tx-sm-80 {
    font-size: 80px;
  }
  .tx-sm-80-f {
    font-size: 80px !important;
  }
  .tx-sm-82 {
    font-size: 82px;
  }
  .tx-sm-82-f {
    font-size: 82px !important;
  }
  .tx-sm-84 {
    font-size: 84px;
  }
  .tx-sm-84-f {
    font-size: 84px !important;
  }
  .tx-sm-86 {
    font-size: 86px;
  }
  .tx-sm-86-f {
    font-size: 86px !important;
  }
  .tx-sm-88 {
    font-size: 88px;
  }
  .tx-sm-88-f {
    font-size: 88px !important;
  }
  .tx-sm-90 {
    font-size: 90px;
  }
  .tx-sm-90-f {
    font-size: 90px !important;
  }
  .tx-sm-92 {
    font-size: 92px;
  }
  .tx-sm-92-f {
    font-size: 92px !important;
  }
  .tx-sm-94 {
    font-size: 94px;
  }
  .tx-sm-94-f {
    font-size: 94px !important;
  }
  .tx-sm-96 {
    font-size: 96px;
  }
  .tx-sm-96-f {
    font-size: 96px !important;
  }
  .tx-sm-98 {
    font-size: 98px;
  }
  .tx-sm-98-f {
    font-size: 98px !important;
  }
  .tx-sm-100 {
    font-size: 100px;
  }
  .tx-sm-100-f {
    font-size: 100px !important;
  }
  .tx-sm-102 {
    font-size: 102px;
  }
  .tx-sm-102-f {
    font-size: 102px !important;
  }
  .tx-sm-104 {
    font-size: 104px;
  }
  .tx-sm-104-f {
    font-size: 104px !important;
  }
  .tx-sm-106 {
    font-size: 106px;
  }
  .tx-sm-106-f {
    font-size: 106px !important;
  }
  .tx-sm-108 {
    font-size: 108px;
  }
  .tx-sm-108-f {
    font-size: 108px !important;
  }
  .tx-sm-110 {
    font-size: 110px;
  }
  .tx-sm-110-f {
    font-size: 110px !important;
  }
  .tx-sm-112 {
    font-size: 112px;
  }
  .tx-sm-112-f {
    font-size: 112px !important;
  }
  .tx-sm-114 {
    font-size: 114px;
  }
  .tx-sm-114-f {
    font-size: 114px !important;
  }
  .tx-sm-116 {
    font-size: 116px;
  }
  .tx-sm-116-f {
    font-size: 116px !important;
  }
  .tx-sm-118 {
    font-size: 118px;
  }
  .tx-sm-118-f {
    font-size: 118px !important;
  }
  .tx-sm-120 {
    font-size: 120px;
  }
  .tx-sm-120-f {
    font-size: 120px !important;
  }
  .tx-sm-122 {
    font-size: 122px;
  }
  .tx-sm-122-f {
    font-size: 122px !important;
  }
  .tx-sm-124 {
    font-size: 124px;
  }
  .tx-sm-124-f {
    font-size: 124px !important;
  }
  .tx-sm-126 {
    font-size: 126px;
  }
  .tx-sm-126-f {
    font-size: 126px !important;
  }
  .tx-sm-128 {
    font-size: 128px;
  }
  .tx-sm-128-f {
    font-size: 128px !important;
  }
  .tx-sm-130 {
    font-size: 130px;
  }
  .tx-sm-130-f {
    font-size: 130px !important;
  }
  .tx-sm-132 {
    font-size: 132px;
  }
  .tx-sm-132-f {
    font-size: 132px !important;
  }
  .tx-sm-134 {
    font-size: 134px;
  }
  .tx-sm-134-f {
    font-size: 134px !important;
  }
  .tx-sm-136 {
    font-size: 136px;
  }
  .tx-sm-136-f {
    font-size: 136px !important;
  }
  .tx-sm-138 {
    font-size: 138px;
  }
  .tx-sm-138-f {
    font-size: 138px !important;
  }
  .tx-sm-140 {
    font-size: 140px;
  }
  .tx-sm-140-f {
    font-size: 140px !important;
  }
  .tx-sm-base {
    font-size: 0.875rem;
  }
  .tx-sm-bold {
    font-weight: 700;
  }
  .tx-sm-semibold {
    font-weight: 600;
  }
  .tx-sm-medium {
    font-weight: 500;
  }
  .tx-sm-normal {
    font-weight: 400;
  }
  .tx-sm-light {
    font-weight: 300;
  }
  .tx-sm-thin {
    font-weight: 200;
  }
  .tx-sm-xthin {
    font-weight: 100;
  }
  .lh-sm-0 {
    line-height: 0;
  }
  .lh-sm-1 {
    line-height: 1.1;
  }
  .lh-sm-2 {
    line-height: 1.2;
  }
  .lh-sm-3 {
    line-height: 1.3;
  }
  .lh-sm-4 {
    line-height: 1.4;
  }
  .lh-sm-5 {
    line-height: 1.5;
  }
  .lh-sm-6 {
    line-height: 1.6;
  }
  .lh-sm-7 {
    line-height: 1.7;
  }
  .lh-sm-8 {
    line-height: 1.8;
  }
  .lh-sm-9 {
    line-height: 1.9;
  }
  .lh-sm-10 {
    line-height: 2;
  }
  .lh-sm-11 {
    line-height: 2.1;
  }
  .lh-sm-12 {
    line-height: 2.2;
  }
  .lh-sm-13 {
    line-height: 2.3;
  }
  .lh-sm-14 {
    line-height: 2.4;
  }
  .lh-sm-15 {
    line-height: 2.5;
  }
  .tx-sm-center {
    text-align: center;
  }
  .tx-sm-right {
    text-align: right;
  }
  .tx-sm-left {
    text-align: left;
  }
  .tx-sm-center-f {
    text-align: center !important;
  }
  .tx-sm-right-f {
    text-align: right !important;
  }
  .tx-sm-left-f {
    text-align: left !important;
  }
}

@media (min-width: 768px) {
  .tx-md-8 {
    font-size: 8px;
  }
  .tx-md-8-f {
    font-size: 8px !important;
  }
  .tx-md-9 {
    font-size: 9px;
  }
  .tx-md-9-f {
    font-size: 9px !important;
  }
  .tx-md-10 {
    font-size: 10px;
  }
  .tx-md-10-f {
    font-size: 10px !important;
  }
  .tx-md-11 {
    font-size: 11px;
  }
  .tx-md-11-f {
    font-size: 11px !important;
  }
  .tx-md-12 {
    font-size: 12px;
  }
  .tx-md-12-f {
    font-size: 12px !important;
  }
  .tx-md-13 {
    font-size: 13px;
  }
  .tx-md-13-f {
    font-size: 13px !important;
  }
  .tx-md-14 {
    font-size: 14px;
  }
  .tx-md-14-f {
    font-size: 14px !important;
  }
  .tx-md-15 {
    font-size: 15px;
  }
  .tx-md-15-f {
    font-size: 15px !important;
  }
  .tx-md-16 {
    font-size: 16px;
  }
  .tx-md-16-f {
    font-size: 16px !important;
  }
  .tx-md-18 {
    font-size: 18px;
  }
  .tx-md-18-f {
    font-size: 18px !important;
  }
  .tx-md-20 {
    font-size: 20px;
  }
  .tx-md-20-f {
    font-size: 20px !important;
  }
  .tx-md-22 {
    font-size: 22px;
  }
  .tx-md-22-f {
    font-size: 22px !important;
  }
  .tx-md-24 {
    font-size: 24px;
  }
  .tx-md-24-f {
    font-size: 24px !important;
  }
  .tx-md-26 {
    font-size: 26px;
  }
  .tx-md-26-f {
    font-size: 26px !important;
  }
  .tx-md-28 {
    font-size: 28px;
  }
  .tx-md-28-f {
    font-size: 28px !important;
  }
  .tx-md-30 {
    font-size: 30px;
  }
  .tx-md-30-f {
    font-size: 30px !important;
  }
  .tx-md-32 {
    font-size: 32px;
  }
  .tx-md-32-f {
    font-size: 32px !important;
  }
  .tx-md-34 {
    font-size: 34px;
  }
  .tx-md-34-f {
    font-size: 34px !important;
  }
  .tx-md-36 {
    font-size: 36px;
  }
  .tx-md-36-f {
    font-size: 36px !important;
  }
  .tx-md-38 {
    font-size: 38px;
  }
  .tx-md-38-f {
    font-size: 38px !important;
  }
  .tx-md-40 {
    font-size: 40px;
  }
  .tx-md-40-f {
    font-size: 40px !important;
  }
  .tx-md-42 {
    font-size: 42px;
  }
  .tx-md-42-f {
    font-size: 42px !important;
  }
  .tx-md-44 {
    font-size: 44px;
  }
  .tx-md-44-f {
    font-size: 44px !important;
  }
  .tx-md-46 {
    font-size: 46px;
  }
  .tx-md-46-f {
    font-size: 46px !important;
  }
  .tx-md-48 {
    font-size: 48px;
  }
  .tx-md-48-f {
    font-size: 48px !important;
  }
  .tx-md-50 {
    font-size: 50px;
  }
  .tx-md-50-f {
    font-size: 50px !important;
  }
  .tx-md-52 {
    font-size: 52px;
  }
  .tx-md-52-f {
    font-size: 52px !important;
  }
  .tx-md-54 {
    font-size: 54px;
  }
  .tx-md-54-f {
    font-size: 54px !important;
  }
  .tx-md-56 {
    font-size: 56px;
  }
  .tx-md-56-f {
    font-size: 56px !important;
  }
  .tx-md-58 {
    font-size: 58px;
  }
  .tx-md-58-f {
    font-size: 58px !important;
  }
  .tx-md-60 {
    font-size: 60px;
  }
  .tx-md-60-f {
    font-size: 60px !important;
  }
  .tx-md-62 {
    font-size: 62px;
  }
  .tx-md-62-f {
    font-size: 62px !important;
  }
  .tx-md-64 {
    font-size: 64px;
  }
  .tx-md-64-f {
    font-size: 64px !important;
  }
  .tx-md-66 {
    font-size: 66px;
  }
  .tx-md-66-f {
    font-size: 66px !important;
  }
  .tx-md-68 {
    font-size: 68px;
  }
  .tx-md-68-f {
    font-size: 68px !important;
  }
  .tx-md-70 {
    font-size: 70px;
  }
  .tx-md-70-f {
    font-size: 70px !important;
  }
  .tx-md-72 {
    font-size: 72px;
  }
  .tx-md-72-f {
    font-size: 72px !important;
  }
  .tx-md-74 {
    font-size: 74px;
  }
  .tx-md-74-f {
    font-size: 74px !important;
  }
  .tx-md-76 {
    font-size: 76px;
  }
  .tx-md-76-f {
    font-size: 76px !important;
  }
  .tx-md-78 {
    font-size: 78px;
  }
  .tx-md-78-f {
    font-size: 78px !important;
  }
  .tx-md-80 {
    font-size: 80px;
  }
  .tx-md-80-f {
    font-size: 80px !important;
  }
  .tx-md-82 {
    font-size: 82px;
  }
  .tx-md-82-f {
    font-size: 82px !important;
  }
  .tx-md-84 {
    font-size: 84px;
  }
  .tx-md-84-f {
    font-size: 84px !important;
  }
  .tx-md-86 {
    font-size: 86px;
  }
  .tx-md-86-f {
    font-size: 86px !important;
  }
  .tx-md-88 {
    font-size: 88px;
  }
  .tx-md-88-f {
    font-size: 88px !important;
  }
  .tx-md-90 {
    font-size: 90px;
  }
  .tx-md-90-f {
    font-size: 90px !important;
  }
  .tx-md-92 {
    font-size: 92px;
  }
  .tx-md-92-f {
    font-size: 92px !important;
  }
  .tx-md-94 {
    font-size: 94px;
  }
  .tx-md-94-f {
    font-size: 94px !important;
  }
  .tx-md-96 {
    font-size: 96px;
  }
  .tx-md-96-f {
    font-size: 96px !important;
  }
  .tx-md-98 {
    font-size: 98px;
  }
  .tx-md-98-f {
    font-size: 98px !important;
  }
  .tx-md-100 {
    font-size: 100px;
  }
  .tx-md-100-f {
    font-size: 100px !important;
  }
  .tx-md-102 {
    font-size: 102px;
  }
  .tx-md-102-f {
    font-size: 102px !important;
  }
  .tx-md-104 {
    font-size: 104px;
  }
  .tx-md-104-f {
    font-size: 104px !important;
  }
  .tx-md-106 {
    font-size: 106px;
  }
  .tx-md-106-f {
    font-size: 106px !important;
  }
  .tx-md-108 {
    font-size: 108px;
  }
  .tx-md-108-f {
    font-size: 108px !important;
  }
  .tx-md-110 {
    font-size: 110px;
  }
  .tx-md-110-f {
    font-size: 110px !important;
  }
  .tx-md-112 {
    font-size: 112px;
  }
  .tx-md-112-f {
    font-size: 112px !important;
  }
  .tx-md-114 {
    font-size: 114px;
  }
  .tx-md-114-f {
    font-size: 114px !important;
  }
  .tx-md-116 {
    font-size: 116px;
  }
  .tx-md-116-f {
    font-size: 116px !important;
  }
  .tx-md-118 {
    font-size: 118px;
  }
  .tx-md-118-f {
    font-size: 118px !important;
  }
  .tx-md-120 {
    font-size: 120px;
  }
  .tx-md-120-f {
    font-size: 120px !important;
  }
  .tx-md-122 {
    font-size: 122px;
  }
  .tx-md-122-f {
    font-size: 122px !important;
  }
  .tx-md-124 {
    font-size: 124px;
  }
  .tx-md-124-f {
    font-size: 124px !important;
  }
  .tx-md-126 {
    font-size: 126px;
  }
  .tx-md-126-f {
    font-size: 126px !important;
  }
  .tx-md-128 {
    font-size: 128px;
  }
  .tx-md-128-f {
    font-size: 128px !important;
  }
  .tx-md-130 {
    font-size: 130px;
  }
  .tx-md-130-f {
    font-size: 130px !important;
  }
  .tx-md-132 {
    font-size: 132px;
  }
  .tx-md-132-f {
    font-size: 132px !important;
  }
  .tx-md-134 {
    font-size: 134px;
  }
  .tx-md-134-f {
    font-size: 134px !important;
  }
  .tx-md-136 {
    font-size: 136px;
  }
  .tx-md-136-f {
    font-size: 136px !important;
  }
  .tx-md-138 {
    font-size: 138px;
  }
  .tx-md-138-f {
    font-size: 138px !important;
  }
  .tx-md-140 {
    font-size: 140px;
  }
  .tx-md-140-f {
    font-size: 140px !important;
  }
  .tx-md-base {
    font-size: 0.875rem;
  }
  .tx-md-bold {
    font-weight: 700;
  }
  .tx-md-semibold {
    font-weight: 600;
  }
  .tx-md-medium {
    font-weight: 500;
  }
  .tx-md-normal {
    font-weight: 400;
  }
  .tx-md-light {
    font-weight: 300;
  }
  .tx-md-thin {
    font-weight: 200;
  }
  .tx-md-xthin {
    font-weight: 100;
  }
  .lh-md-0 {
    line-height: 0;
  }
  .lh-md-1 {
    line-height: 1.1;
  }
  .lh-md-2 {
    line-height: 1.2;
  }
  .lh-md-3 {
    line-height: 1.3;
  }
  .lh-md-4 {
    line-height: 1.4;
  }
  .lh-md-5 {
    line-height: 1.5;
  }
  .lh-md-6 {
    line-height: 1.6;
  }
  .lh-md-7 {
    line-height: 1.7;
  }
  .lh-md-8 {
    line-height: 1.8;
  }
  .lh-md-9 {
    line-height: 1.9;
  }
  .lh-md-10 {
    line-height: 2;
  }
  .lh-md-11 {
    line-height: 2.1;
  }
  .lh-md-12 {
    line-height: 2.2;
  }
  .lh-md-13 {
    line-height: 2.3;
  }
  .lh-md-14 {
    line-height: 2.4;
  }
  .lh-md-15 {
    line-height: 2.5;
  }
  .tx-md-center {
    text-align: center;
  }
  .tx-md-right {
    text-align: right;
  }
  .tx-md-left {
    text-align: left;
  }
  .tx-md-center-f {
    text-align: center !important;
  }
  .tx-md-right-f {
    text-align: right !important;
  }
  .tx-md-left-f {
    text-align: left !important;
  }
}

@media (min-width: 992px) {
  .tx-lg-8 {
    font-size: 8px;
  }
  .tx-lg-8-f {
    font-size: 8px !important;
  }
  .tx-lg-9 {
    font-size: 9px;
  }
  .tx-lg-9-f {
    font-size: 9px !important;
  }
  .tx-lg-10 {
    font-size: 10px;
  }
  .tx-lg-10-f {
    font-size: 10px !important;
  }
  .tx-lg-11 {
    font-size: 11px;
  }
  .tx-lg-11-f {
    font-size: 11px !important;
  }
  .tx-lg-12 {
    font-size: 12px;
  }
  .tx-lg-12-f {
    font-size: 12px !important;
  }
  .tx-lg-13 {
    font-size: 13px;
  }
  .tx-lg-13-f {
    font-size: 13px !important;
  }
  .tx-lg-14 {
    font-size: 14px;
  }
  .tx-lg-14-f {
    font-size: 14px !important;
  }
  .tx-lg-15 {
    font-size: 15px;
  }
  .tx-lg-15-f {
    font-size: 15px !important;
  }
  .tx-lg-16 {
    font-size: 16px;
  }
  .tx-lg-16-f {
    font-size: 16px !important;
  }
  .tx-lg-18 {
    font-size: 18px;
  }
  .tx-lg-18-f {
    font-size: 18px !important;
  }
  .tx-lg-20 {
    font-size: 20px;
  }
  .tx-lg-20-f {
    font-size: 20px !important;
  }
  .tx-lg-22 {
    font-size: 22px;
  }
  .tx-lg-22-f {
    font-size: 22px !important;
  }
  .tx-lg-24 {
    font-size: 24px;
  }
  .tx-lg-24-f {
    font-size: 24px !important;
  }
  .tx-lg-26 {
    font-size: 26px;
  }
  .tx-lg-26-f {
    font-size: 26px !important;
  }
  .tx-lg-28 {
    font-size: 28px;
  }
  .tx-lg-28-f {
    font-size: 28px !important;
  }
  .tx-lg-30 {
    font-size: 30px;
  }
  .tx-lg-30-f {
    font-size: 30px !important;
  }
  .tx-lg-32 {
    font-size: 32px;
  }
  .tx-lg-32-f {
    font-size: 32px !important;
  }
  .tx-lg-34 {
    font-size: 34px;
  }
  .tx-lg-34-f {
    font-size: 34px !important;
  }
  .tx-lg-36 {
    font-size: 36px;
  }
  .tx-lg-36-f {
    font-size: 36px !important;
  }
  .tx-lg-38 {
    font-size: 38px;
  }
  .tx-lg-38-f {
    font-size: 38px !important;
  }
  .tx-lg-40 {
    font-size: 40px;
  }
  .tx-lg-40-f {
    font-size: 40px !important;
  }
  .tx-lg-42 {
    font-size: 42px;
  }
  .tx-lg-42-f {
    font-size: 42px !important;
  }
  .tx-lg-44 {
    font-size: 44px;
  }
  .tx-lg-44-f {
    font-size: 44px !important;
  }
  .tx-lg-46 {
    font-size: 46px;
  }
  .tx-lg-46-f {
    font-size: 46px !important;
  }
  .tx-lg-48 {
    font-size: 48px;
  }
  .tx-lg-48-f {
    font-size: 48px !important;
  }
  .tx-lg-50 {
    font-size: 50px;
  }
  .tx-lg-50-f {
    font-size: 50px !important;
  }
  .tx-lg-52 {
    font-size: 52px;
  }
  .tx-lg-52-f {
    font-size: 52px !important;
  }
  .tx-lg-54 {
    font-size: 54px;
  }
  .tx-lg-54-f {
    font-size: 54px !important;
  }
  .tx-lg-56 {
    font-size: 56px;
  }
  .tx-lg-56-f {
    font-size: 56px !important;
  }
  .tx-lg-58 {
    font-size: 58px;
  }
  .tx-lg-58-f {
    font-size: 58px !important;
  }
  .tx-lg-60 {
    font-size: 60px;
  }
  .tx-lg-60-f {
    font-size: 60px !important;
  }
  .tx-lg-62 {
    font-size: 62px;
  }
  .tx-lg-62-f {
    font-size: 62px !important;
  }
  .tx-lg-64 {
    font-size: 64px;
  }
  .tx-lg-64-f {
    font-size: 64px !important;
  }
  .tx-lg-66 {
    font-size: 66px;
  }
  .tx-lg-66-f {
    font-size: 66px !important;
  }
  .tx-lg-68 {
    font-size: 68px;
  }
  .tx-lg-68-f {
    font-size: 68px !important;
  }
  .tx-lg-70 {
    font-size: 70px;
  }
  .tx-lg-70-f {
    font-size: 70px !important;
  }
  .tx-lg-72 {
    font-size: 72px;
  }
  .tx-lg-72-f {
    font-size: 72px !important;
  }
  .tx-lg-74 {
    font-size: 74px;
  }
  .tx-lg-74-f {
    font-size: 74px !important;
  }
  .tx-lg-76 {
    font-size: 76px;
  }
  .tx-lg-76-f {
    font-size: 76px !important;
  }
  .tx-lg-78 {
    font-size: 78px;
  }
  .tx-lg-78-f {
    font-size: 78px !important;
  }
  .tx-lg-80 {
    font-size: 80px;
  }
  .tx-lg-80-f {
    font-size: 80px !important;
  }
  .tx-lg-82 {
    font-size: 82px;
  }
  .tx-lg-82-f {
    font-size: 82px !important;
  }
  .tx-lg-84 {
    font-size: 84px;
  }
  .tx-lg-84-f {
    font-size: 84px !important;
  }
  .tx-lg-86 {
    font-size: 86px;
  }
  .tx-lg-86-f {
    font-size: 86px !important;
  }
  .tx-lg-88 {
    font-size: 88px;
  }
  .tx-lg-88-f {
    font-size: 88px !important;
  }
  .tx-lg-90 {
    font-size: 90px;
  }
  .tx-lg-90-f {
    font-size: 90px !important;
  }
  .tx-lg-92 {
    font-size: 92px;
  }
  .tx-lg-92-f {
    font-size: 92px !important;
  }
  .tx-lg-94 {
    font-size: 94px;
  }
  .tx-lg-94-f {
    font-size: 94px !important;
  }
  .tx-lg-96 {
    font-size: 96px;
  }
  .tx-lg-96-f {
    font-size: 96px !important;
  }
  .tx-lg-98 {
    font-size: 98px;
  }
  .tx-lg-98-f {
    font-size: 98px !important;
  }
  .tx-lg-100 {
    font-size: 100px;
  }
  .tx-lg-100-f {
    font-size: 100px !important;
  }
  .tx-lg-102 {
    font-size: 102px;
  }
  .tx-lg-102-f {
    font-size: 102px !important;
  }
  .tx-lg-104 {
    font-size: 104px;
  }
  .tx-lg-104-f {
    font-size: 104px !important;
  }
  .tx-lg-106 {
    font-size: 106px;
  }
  .tx-lg-106-f {
    font-size: 106px !important;
  }
  .tx-lg-108 {
    font-size: 108px;
  }
  .tx-lg-108-f {
    font-size: 108px !important;
  }
  .tx-lg-110 {
    font-size: 110px;
  }
  .tx-lg-110-f {
    font-size: 110px !important;
  }
  .tx-lg-112 {
    font-size: 112px;
  }
  .tx-lg-112-f {
    font-size: 112px !important;
  }
  .tx-lg-114 {
    font-size: 114px;
  }
  .tx-lg-114-f {
    font-size: 114px !important;
  }
  .tx-lg-116 {
    font-size: 116px;
  }
  .tx-lg-116-f {
    font-size: 116px !important;
  }
  .tx-lg-118 {
    font-size: 118px;
  }
  .tx-lg-118-f {
    font-size: 118px !important;
  }
  .tx-lg-120 {
    font-size: 120px;
  }
  .tx-lg-120-f {
    font-size: 120px !important;
  }
  .tx-lg-122 {
    font-size: 122px;
  }
  .tx-lg-122-f {
    font-size: 122px !important;
  }
  .tx-lg-124 {
    font-size: 124px;
  }
  .tx-lg-124-f {
    font-size: 124px !important;
  }
  .tx-lg-126 {
    font-size: 126px;
  }
  .tx-lg-126-f {
    font-size: 126px !important;
  }
  .tx-lg-128 {
    font-size: 128px;
  }
  .tx-lg-128-f {
    font-size: 128px !important;
  }
  .tx-lg-130 {
    font-size: 130px;
  }
  .tx-lg-130-f {
    font-size: 130px !important;
  }
  .tx-lg-132 {
    font-size: 132px;
  }
  .tx-lg-132-f {
    font-size: 132px !important;
  }
  .tx-lg-134 {
    font-size: 134px;
  }
  .tx-lg-134-f {
    font-size: 134px !important;
  }
  .tx-lg-136 {
    font-size: 136px;
  }
  .tx-lg-136-f {
    font-size: 136px !important;
  }
  .tx-lg-138 {
    font-size: 138px;
  }
  .tx-lg-138-f {
    font-size: 138px !important;
  }
  .tx-lg-140 {
    font-size: 140px;
  }
  .tx-lg-140-f {
    font-size: 140px !important;
  }
  .tx-lg-base {
    font-size: 0.875rem;
  }
  .tx-lg-bold {
    font-weight: 700;
  }
  .tx-lg-semibold {
    font-weight: 600;
  }
  .tx-lg-medium {
    font-weight: 500;
  }
  .tx-lg-normal {
    font-weight: 400;
  }
  .tx-lg-light {
    font-weight: 300;
  }
  .tx-lg-thin {
    font-weight: 200;
  }
  .tx-lg-xthin {
    font-weight: 100;
  }
  .lh-lg-0 {
    line-height: 0;
  }
  .lh-lg-1 {
    line-height: 1.1;
  }
  .lh-lg-2 {
    line-height: 1.2;
  }
  .lh-lg-3 {
    line-height: 1.3;
  }
  .lh-lg-4 {
    line-height: 1.4;
  }
  .lh-lg-5 {
    line-height: 1.5;
  }
  .lh-lg-6 {
    line-height: 1.6;
  }
  .lh-lg-7 {
    line-height: 1.7;
  }
  .lh-lg-8 {
    line-height: 1.8;
  }
  .lh-lg-9 {
    line-height: 1.9;
  }
  .lh-lg-10 {
    line-height: 2;
  }
  .lh-lg-11 {
    line-height: 2.1;
  }
  .lh-lg-12 {
    line-height: 2.2;
  }
  .lh-lg-13 {
    line-height: 2.3;
  }
  .lh-lg-14 {
    line-height: 2.4;
  }
  .lh-lg-15 {
    line-height: 2.5;
  }
  .tx-lg-center {
    text-align: center;
  }
  .tx-lg-right {
    text-align: right;
  }
  .tx-lg-left {
    text-align: left;
  }
  .tx-lg-center-f {
    text-align: center !important;
  }
  .tx-lg-right-f {
    text-align: right !important;
  }
  .tx-lg-left-f {
    text-align: left !important;
  }
}

@media (min-width: 1200px) {
  .tx-xl-8 {
    font-size: 8px;
  }
  .tx-xl-8-f {
    font-size: 8px !important;
  }
  .tx-xl-9 {
    font-size: 9px;
  }
  .tx-xl-9-f {
    font-size: 9px !important;
  }
  .tx-xl-10 {
    font-size: 10px;
  }
  .tx-xl-10-f {
    font-size: 10px !important;
  }
  .tx-xl-11 {
    font-size: 11px;
  }
  .tx-xl-11-f {
    font-size: 11px !important;
  }
  .tx-xl-12 {
    font-size: 12px;
  }
  .tx-xl-12-f {
    font-size: 12px !important;
  }
  .tx-xl-13 {
    font-size: 13px;
  }
  .tx-xl-13-f {
    font-size: 13px !important;
  }
  .tx-xl-14 {
    font-size: 14px;
  }
  .tx-xl-14-f {
    font-size: 14px !important;
  }
  .tx-xl-15 {
    font-size: 15px;
  }
  .tx-xl-15-f {
    font-size: 15px !important;
  }
  .tx-xl-16 {
    font-size: 16px;
  }
  .tx-xl-16-f {
    font-size: 16px !important;
  }
  .tx-xl-18 {
    font-size: 18px;
  }
  .tx-xl-18-f {
    font-size: 18px !important;
  }
  .tx-xl-20 {
    font-size: 20px;
  }
  .tx-xl-20-f {
    font-size: 20px !important;
  }
  .tx-xl-22 {
    font-size: 22px;
  }
  .tx-xl-22-f {
    font-size: 22px !important;
  }
  .tx-xl-24 {
    font-size: 24px;
  }
  .tx-xl-24-f {
    font-size: 24px !important;
  }
  .tx-xl-26 {
    font-size: 26px;
  }
  .tx-xl-26-f {
    font-size: 26px !important;
  }
  .tx-xl-28 {
    font-size: 28px;
  }
  .tx-xl-28-f {
    font-size: 28px !important;
  }
  .tx-xl-30 {
    font-size: 30px;
  }
  .tx-xl-30-f {
    font-size: 30px !important;
  }
  .tx-xl-32 {
    font-size: 32px;
  }
  .tx-xl-32-f {
    font-size: 32px !important;
  }
  .tx-xl-34 {
    font-size: 34px;
  }
  .tx-xl-34-f {
    font-size: 34px !important;
  }
  .tx-xl-36 {
    font-size: 36px;
  }
  .tx-xl-36-f {
    font-size: 36px !important;
  }
  .tx-xl-38 {
    font-size: 38px;
  }
  .tx-xl-38-f {
    font-size: 38px !important;
  }
  .tx-xl-40 {
    font-size: 40px;
  }
  .tx-xl-40-f {
    font-size: 40px !important;
  }
  .tx-xl-42 {
    font-size: 42px;
  }
  .tx-xl-42-f {
    font-size: 42px !important;
  }
  .tx-xl-44 {
    font-size: 44px;
  }
  .tx-xl-44-f {
    font-size: 44px !important;
  }
  .tx-xl-46 {
    font-size: 46px;
  }
  .tx-xl-46-f {
    font-size: 46px !important;
  }
  .tx-xl-48 {
    font-size: 48px;
  }
  .tx-xl-48-f {
    font-size: 48px !important;
  }
  .tx-xl-50 {
    font-size: 50px;
  }
  .tx-xl-50-f {
    font-size: 50px !important;
  }
  .tx-xl-52 {
    font-size: 52px;
  }
  .tx-xl-52-f {
    font-size: 52px !important;
  }
  .tx-xl-54 {
    font-size: 54px;
  }
  .tx-xl-54-f {
    font-size: 54px !important;
  }
  .tx-xl-56 {
    font-size: 56px;
  }
  .tx-xl-56-f {
    font-size: 56px !important;
  }
  .tx-xl-58 {
    font-size: 58px;
  }
  .tx-xl-58-f {
    font-size: 58px !important;
  }
  .tx-xl-60 {
    font-size: 60px;
  }
  .tx-xl-60-f {
    font-size: 60px !important;
  }
  .tx-xl-62 {
    font-size: 62px;
  }
  .tx-xl-62-f {
    font-size: 62px !important;
  }
  .tx-xl-64 {
    font-size: 64px;
  }
  .tx-xl-64-f {
    font-size: 64px !important;
  }
  .tx-xl-66 {
    font-size: 66px;
  }
  .tx-xl-66-f {
    font-size: 66px !important;
  }
  .tx-xl-68 {
    font-size: 68px;
  }
  .tx-xl-68-f {
    font-size: 68px !important;
  }
  .tx-xl-70 {
    font-size: 70px;
  }
  .tx-xl-70-f {
    font-size: 70px !important;
  }
  .tx-xl-72 {
    font-size: 72px;
  }
  .tx-xl-72-f {
    font-size: 72px !important;
  }
  .tx-xl-74 {
    font-size: 74px;
  }
  .tx-xl-74-f {
    font-size: 74px !important;
  }
  .tx-xl-76 {
    font-size: 76px;
  }
  .tx-xl-76-f {
    font-size: 76px !important;
  }
  .tx-xl-78 {
    font-size: 78px;
  }
  .tx-xl-78-f {
    font-size: 78px !important;
  }
  .tx-xl-80 {
    font-size: 80px;
  }
  .tx-xl-80-f {
    font-size: 80px !important;
  }
  .tx-xl-82 {
    font-size: 82px;
  }
  .tx-xl-82-f {
    font-size: 82px !important;
  }
  .tx-xl-84 {
    font-size: 84px;
  }
  .tx-xl-84-f {
    font-size: 84px !important;
  }
  .tx-xl-86 {
    font-size: 86px;
  }
  .tx-xl-86-f {
    font-size: 86px !important;
  }
  .tx-xl-88 {
    font-size: 88px;
  }
  .tx-xl-88-f {
    font-size: 88px !important;
  }
  .tx-xl-90 {
    font-size: 90px;
  }
  .tx-xl-90-f {
    font-size: 90px !important;
  }
  .tx-xl-92 {
    font-size: 92px;
  }
  .tx-xl-92-f {
    font-size: 92px !important;
  }
  .tx-xl-94 {
    font-size: 94px;
  }
  .tx-xl-94-f {
    font-size: 94px !important;
  }
  .tx-xl-96 {
    font-size: 96px;
  }
  .tx-xl-96-f {
    font-size: 96px !important;
  }
  .tx-xl-98 {
    font-size: 98px;
  }
  .tx-xl-98-f {
    font-size: 98px !important;
  }
  .tx-xl-100 {
    font-size: 100px;
  }
  .tx-xl-100-f {
    font-size: 100px !important;
  }
  .tx-xl-102 {
    font-size: 102px;
  }
  .tx-xl-102-f {
    font-size: 102px !important;
  }
  .tx-xl-104 {
    font-size: 104px;
  }
  .tx-xl-104-f {
    font-size: 104px !important;
  }
  .tx-xl-106 {
    font-size: 106px;
  }
  .tx-xl-106-f {
    font-size: 106px !important;
  }
  .tx-xl-108 {
    font-size: 108px;
  }
  .tx-xl-108-f {
    font-size: 108px !important;
  }
  .tx-xl-110 {
    font-size: 110px;
  }
  .tx-xl-110-f {
    font-size: 110px !important;
  }
  .tx-xl-112 {
    font-size: 112px;
  }
  .tx-xl-112-f {
    font-size: 112px !important;
  }
  .tx-xl-114 {
    font-size: 114px;
  }
  .tx-xl-114-f {
    font-size: 114px !important;
  }
  .tx-xl-116 {
    font-size: 116px;
  }
  .tx-xl-116-f {
    font-size: 116px !important;
  }
  .tx-xl-118 {
    font-size: 118px;
  }
  .tx-xl-118-f {
    font-size: 118px !important;
  }
  .tx-xl-120 {
    font-size: 120px;
  }
  .tx-xl-120-f {
    font-size: 120px !important;
  }
  .tx-xl-122 {
    font-size: 122px;
  }
  .tx-xl-122-f {
    font-size: 122px !important;
  }
  .tx-xl-124 {
    font-size: 124px;
  }
  .tx-xl-124-f {
    font-size: 124px !important;
  }
  .tx-xl-126 {
    font-size: 126px;
  }
  .tx-xl-126-f {
    font-size: 126px !important;
  }
  .tx-xl-128 {
    font-size: 128px;
  }
  .tx-xl-128-f {
    font-size: 128px !important;
  }
  .tx-xl-130 {
    font-size: 130px;
  }
  .tx-xl-130-f {
    font-size: 130px !important;
  }
  .tx-xl-132 {
    font-size: 132px;
  }
  .tx-xl-132-f {
    font-size: 132px !important;
  }
  .tx-xl-134 {
    font-size: 134px;
  }
  .tx-xl-134-f {
    font-size: 134px !important;
  }
  .tx-xl-136 {
    font-size: 136px;
  }
  .tx-xl-136-f {
    font-size: 136px !important;
  }
  .tx-xl-138 {
    font-size: 138px;
  }
  .tx-xl-138-f {
    font-size: 138px !important;
  }
  .tx-xl-140 {
    font-size: 140px;
  }
  .tx-xl-140-f {
    font-size: 140px !important;
  }
  .tx-xl-base {
    font-size: 0.875rem;
  }
  .tx-xl-bold {
    font-weight: 700;
  }
  .tx-xl-semibold {
    font-weight: 600;
  }
  .tx-xl-medium {
    font-weight: 500;
  }
  .tx-xl-normal {
    font-weight: 400;
  }
  .tx-xl-light {
    font-weight: 300;
  }
  .tx-xl-thin {
    font-weight: 200;
  }
  .tx-xl-xthin {
    font-weight: 100;
  }
  .lh-xl-0 {
    line-height: 0;
  }
  .lh-xl-1 {
    line-height: 1.1;
  }
  .lh-xl-2 {
    line-height: 1.2;
  }
  .lh-xl-3 {
    line-height: 1.3;
  }
  .lh-xl-4 {
    line-height: 1.4;
  }
  .lh-xl-5 {
    line-height: 1.5;
  }
  .lh-xl-6 {
    line-height: 1.6;
  }
  .lh-xl-7 {
    line-height: 1.7;
  }
  .lh-xl-8 {
    line-height: 1.8;
  }
  .lh-xl-9 {
    line-height: 1.9;
  }
  .lh-xl-10 {
    line-height: 2;
  }
  .lh-xl-11 {
    line-height: 2.1;
  }
  .lh-xl-12 {
    line-height: 2.2;
  }
  .lh-xl-13 {
    line-height: 2.3;
  }
  .lh-xl-14 {
    line-height: 2.4;
  }
  .lh-xl-15 {
    line-height: 2.5;
  }
  .tx-xl-center {
    text-align: center;
  }
  .tx-xl-right {
    text-align: right;
  }
  .tx-xl-left {
    text-align: left;
  }
  .tx-xl-center-f {
    text-align: center !important;
  }
  .tx-xl-right-f {
    text-align: right !important;
  }
  .tx-xl-left-f {
    text-align: left !important;
  }
}

/* ###### 9.9 Width   ###### */

.wd-1 {
  width: 1px;
}

.wd-2 {
  width: 2px;
}

.wd-3 {
  width: 3px;
}

.wd-4 {
  width: 4px;
}

.wd-6 {
  width: 6px;
}

.wd-7 {
  width: 7px;
}

.wd-8 {
  width: 8px;
}

.wd-9 {
  width: 9px;
}

.wd-280 {
  width: 280px;
}

.wd-5 {
  width: 5px;
}

.wd-5p {
  width: 5%;
}

.mx-wd-5p {
  max-width: 5%;
}

.mn-wd-5p {
  min-width: 5%;
}

.wd-5-f {
  width: 5px !important;
}

.wd-5p-f {
  width: 5% !important;
}

.mx-wd-5p-f {
  max-width: 5% !important;
}

.mn-wd-5p-f {
  min-width: 5% !important;
}

.wd-10 {
  width: 10px;
}

.wd-10p {
  width: 10%;
}

.mx-wd-10p {
  max-width: 10%;
}

.mn-wd-10p {
  min-width: 10%;
}

.wd-10-f {
  width: 10px !important;
}

.wd-10p-f {
  width: 10% !important;
}

.mx-wd-10p-f {
  max-width: 10% !important;
}

.mn-wd-10p-f {
  min-width: 10% !important;
}

.wd-15 {
  width: 15px;
}

.wd-15p {
  width: 15%;
}

.mx-wd-15p {
  max-width: 15%;
}

.mn-wd-15p {
  min-width: 15%;
}

.wd-15-f {
  width: 15px !important;
}

.wd-15p-f {
  width: 15% !important;
}

.mx-wd-15p-f {
  max-width: 15% !important;
}

.mn-wd-15p-f {
  min-width: 15% !important;
}

.wd-20 {
  width: 20px;
}

.wd-20p {
  width: 20%;
}

.mx-wd-20p {
  max-width: 20%;
}

.mn-wd-20p {
  min-width: 20%;
}

.wd-20-f {
  width: 20px !important;
}

.wd-20p-f {
  width: 20% !important;
}

.mx-wd-20p-f {
  max-width: 20% !important;
}

.mn-wd-20p-f {
  min-width: 20% !important;
}

.wd-25 {
  width: 25px;
}

.wd-25p {
  width: 25%;
}

.mx-wd-25p {
  max-width: 25%;
}

.mn-wd-25p {
  min-width: 25%;
}

.wd-25-f {
  width: 25px !important;
}

.wd-25p-f {
  width: 25% !important;
}

.mx-wd-25p-f {
  max-width: 25% !important;
}

.mn-wd-25p-f {
  min-width: 25% !important;
}

.wd-30 {
  width: 30px;
}

.wd-30p {
  width: 30%;
}

.mx-wd-30p {
  max-width: 30%;
}

.mn-wd-30p {
  min-width: 30%;
}

.wd-30-f {
  width: 30px !important;
}

.wd-30p-f {
  width: 30% !important;
}

.mx-wd-30p-f {
  max-width: 30% !important;
}

.mn-wd-30p-f {
  min-width: 30% !important;
}

.wd-35 {
  width: 35px;
}

.wd-35p {
  width: 35%;
}

.mx-wd-35p {
  max-width: 35%;
}

.mn-wd-35p {
  min-width: 35%;
}

.wd-35-f {
  width: 35px !important;
}

.wd-35p-f {
  width: 35% !important;
}

.mx-wd-35p-f {
  max-width: 35% !important;
}

.mn-wd-35p-f {
  min-width: 35% !important;
}

.wd-40 {
  width: 40px;
}

.wd-40p {
  width: 40%;
}

.mx-wd-40p {
  max-width: 40%;
}

.mn-wd-40p {
  min-width: 40%;
}

.wd-40-f {
  width: 40px !important;
}

.wd-40p-f {
  width: 40% !important;
}

.mx-wd-40p-f {
  max-width: 40% !important;
}

.mn-wd-40p-f {
  min-width: 40% !important;
}

.wd-45 {
  width: 45px;
}

.wd-45p {
  width: 45%;
}

.mx-wd-45p {
  max-width: 45%;
}

.mn-wd-45p {
  min-width: 45%;
}

.wd-45-f {
  width: 45px !important;
}

.wd-45p-f {
  width: 45% !important;
}

.mx-wd-45p-f {
  max-width: 45% !important;
}

.mn-wd-45p-f {
  min-width: 45% !important;
}

.wd-50 {
  width: 50px;
}

.wd-50p {
  width: 50%;
}

.mx-wd-50p {
  max-width: 50%;
}

.mn-wd-50p {
  min-width: 50%;
}

.wd-50-f {
  width: 50px !important;
}

.wd-50p-f {
  width: 50% !important;
}

.mx-wd-50p-f {
  max-width: 50% !important;
}

.mn-wd-50p-f {
  min-width: 50% !important;
}

.wd-55 {
  width: 55px;
}

.wd-55p {
  width: 55%;
}

.mx-wd-55p {
  max-width: 55%;
}

.mn-wd-55p {
  min-width: 55%;
}

.wd-55-f {
  width: 55px !important;
}

.wd-55p-f {
  width: 55% !important;
}

.mx-wd-55p-f {
  max-width: 55% !important;
}

.mn-wd-55p-f {
  min-width: 55% !important;
}

.wd-60 {
  width: 60px;
}

.wd-60p {
  width: 60%;
}

.mx-wd-60p {
  max-width: 60%;
}

.mn-wd-60p {
  min-width: 60%;
}

.wd-60-f {
  width: 60px !important;
}

.wd-60p-f {
  width: 60% !important;
}

.mx-wd-60p-f {
  max-width: 60% !important;
}

.mn-wd-60p-f {
  min-width: 60% !important;
}

.wd-65 {
  width: 65px;
}

.wd-65p {
  width: 65%;
}

.mx-wd-65p {
  max-width: 65%;
}

.mn-wd-65p {
  min-width: 65%;
}

.wd-65-f {
  width: 65px !important;
}

.wd-65p-f {
  width: 65% !important;
}

.mx-wd-65p-f {
  max-width: 65% !important;
}

.mn-wd-65p-f {
  min-width: 65% !important;
}

.wd-70 {
  width: 70px;
}

.wd-70p {
  width: 70%;
}

.mx-wd-70p {
  max-width: 70%;
}

.mn-wd-70p {
  min-width: 70%;
}

.wd-70-f {
  width: 70px !important;
}

.wd-70p-f {
  width: 70% !important;
}

.mx-wd-70p-f {
  max-width: 70% !important;
}

.mn-wd-70p-f {
  min-width: 70% !important;
}

.wd-75 {
  width: 75px;
}

.wd-75p {
  width: 75%;
}

.mx-wd-75p {
  max-width: 75%;
}

.mn-wd-75p {
  min-width: 75%;
}

.wd-75-f {
  width: 75px !important;
}

.wd-75p-f {
  width: 75% !important;
}

.mx-wd-75p-f {
  max-width: 75% !important;
}

.mn-wd-75p-f {
  min-width: 75% !important;
}

.wd-80 {
  width: 80px;
}

.wd-80p {
  width: 80%;
}

.mx-wd-80p {
  max-width: 80%;
}

.mn-wd-80p {
  min-width: 80%;
}

.wd-80-f {
  width: 80px !important;
}

.wd-80p-f {
  width: 80% !important;
}

.mx-wd-80p-f {
  max-width: 80% !important;
}

.mn-wd-80p-f {
  min-width: 80% !important;
}

.wd-85 {
  width: 85px;
}

.wd-85p {
  width: 85%;
}

.mx-wd-85p {
  max-width: 85%;
}

.mn-wd-85p {
  min-width: 85%;
}

.wd-85-f {
  width: 85px !important;
}

.wd-85p-f {
  width: 85% !important;
}

.mx-wd-85p-f {
  max-width: 85% !important;
}

.mn-wd-85p-f {
  min-width: 85% !important;
}

.wd-90 {
  width: 90px;
}

.wd-90p {
  width: 90%;
}

.mx-wd-90p {
  max-width: 90%;
}

.mn-wd-90p {
  min-width: 90%;
}

.wd-90-f {
  width: 90px !important;
}

.wd-90p-f {
  width: 90% !important;
}

.mx-wd-90p-f {
  max-width: 90% !important;
}

.mn-wd-90p-f {
  min-width: 90% !important;
}

.wd-95 {
  width: 95px;
}

.wd-95p {
  width: 95%;
}

.mx-wd-95p {
  max-width: 95%;
}

.mn-wd-95p {
  min-width: 95%;
}

.wd-95-f {
  width: 95px !important;
}

.wd-95p-f {
  width: 95% !important;
}

.mx-wd-95p-f {
  max-width: 95% !important;
}

.mn-wd-95p-f {
  min-width: 95% !important;
}

.wd-100 {
  width: 100px;
}

.wd-100p {
  width: 100%;
}

.mx-wd-100p {
  max-width: 100%;
}

.mn-wd-100p {
  min-width: 100%;
}

.wd-100-f {
  width: 100px !important;
}

.wd-100p-f {
  width: 100% !important;
}

.mx-wd-100p-f {
  max-width: 100% !important;
}

.mn-wd-100p-f {
  min-width: 100% !important;
}

.wd-150 {
  width: 150px;
}

.wd-150-f {
  width: 150px !important;
}

.wd-200 {
  width: 200px;
}

.wd-200-f {
  width: 200px !important;
}

.wd-250 {
  width: 250px;
}

.wd-250-f {
  width: 250px !important;
}

.wd-300 {
  width: 300px;
}

.wd-300-f {
  width: 300px !important;
}

.wd-350 {
  width: 350px;
}

.wd-350-f {
  width: 350px !important;
}

.wd-400 {
  width: 400px;
}

.wd-400-f {
  width: 400px !important;
}

.wd-450 {
  width: 450px;
}

.wd-450-f {
  width: 450px !important;
}

.wd-500 {
  width: 500px;
}

.wd-500-f {
  width: 500px !important;
}

.wd-550 {
  width: 550px;
}

.wd-550-f {
  width: 550px !important;
}

.wd-600 {
  width: 600px;
}

.wd-600-f {
  width: 600px !important;
}

.wd-650 {
  width: 650px;
}

.wd-650-f {
  width: 650px !important;
}

.wd-700 {
  width: 700px;
}

.wd-700-f {
  width: 700px !important;
}

.wd-750 {
  width: 750px;
}

.wd-750-f {
  width: 750px !important;
}

.wd-800 {
  width: 800px;
}

.wd-800-f {
  width: 800px !important;
}

.wd-850 {
  width: 850px;
}

.wd-850-f {
  width: 850px !important;
}

.wd-900 {
  width: 900px;
}

.wd-900-f {
  width: 900px !important;
}

.wd-950 {
  width: 950px;
}

.wd-950-f {
  width: 950px !important;
}

.wd-1000 {
  width: 1000px;
}

.wd-1000-f {
  width: 1000px !important;
}

@media (min-width: 480px) {
  .wd-xs-5 {
    width: 5px;
  }
  .wd-xs-5p {
    width: 5%;
  }
  .mx-wd-xs-5p {
    max-width: 5%;
  }
  .mn-wd-xs-5p {
    min-width: 5%;
  }
  .wd-xs-5-f {
    width: 5px !important;
  }
  .wd-xs-5p-f {
    width: 5% !important;
  }
  .mx-wd-xs-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-xs-5p-f {
    min-width: 5% !important;
  }
  .wd-xs-10 {
    width: 10px;
  }
  .wd-xs-10p {
    width: 10%;
  }
  .mx-wd-xs-10p {
    max-width: 10%;
  }
  .mn-wd-xs-10p {
    min-width: 10%;
  }
  .wd-xs-10-f {
    width: 10px !important;
  }
  .wd-xs-10p-f {
    width: 10% !important;
  }
  .mx-wd-xs-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-xs-10p-f {
    min-width: 10% !important;
  }
  .wd-xs-15 {
    width: 15px;
  }
  .wd-xs-15p {
    width: 15%;
  }
  .mx-wd-xs-15p {
    max-width: 15%;
  }
  .mn-wd-xs-15p {
    min-width: 15%;
  }
  .wd-xs-15-f {
    width: 15px !important;
  }
  .wd-xs-15p-f {
    width: 15% !important;
  }
  .mx-wd-xs-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-xs-15p-f {
    min-width: 15% !important;
  }
  .wd-xs-20 {
    width: 20px;
  }
  .wd-xs-20p {
    width: 20%;
  }
  .mx-wd-xs-20p {
    max-width: 20%;
  }
  .mn-wd-xs-20p {
    min-width: 20%;
  }
  .wd-xs-20-f {
    width: 20px !important;
  }
  .wd-xs-20p-f {
    width: 20% !important;
  }
  .mx-wd-xs-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-xs-20p-f {
    min-width: 20% !important;
  }
  .wd-xs-25 {
    width: 25px;
  }
  .wd-xs-25p {
    width: 25%;
  }
  .mx-wd-xs-25p {
    max-width: 25%;
  }
  .mn-wd-xs-25p {
    min-width: 25%;
  }
  .wd-xs-25-f {
    width: 25px !important;
  }
  .wd-xs-25p-f {
    width: 25% !important;
  }
  .mx-wd-xs-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-xs-25p-f {
    min-width: 25% !important;
  }
  .wd-xs-30 {
    width: 30px;
  }
  .wd-xs-30p {
    width: 30%;
  }
  .mx-wd-xs-30p {
    max-width: 30%;
  }
  .mn-wd-xs-30p {
    min-width: 30%;
  }
  .wd-xs-30-f {
    width: 30px !important;
  }
  .wd-xs-30p-f {
    width: 30% !important;
  }
  .mx-wd-xs-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-xs-30p-f {
    min-width: 30% !important;
  }
  .wd-xs-35 {
    width: 35px;
  }
  .wd-xs-35p {
    width: 35%;
  }
  .mx-wd-xs-35p {
    max-width: 35%;
  }
  .mn-wd-xs-35p {
    min-width: 35%;
  }
  .wd-xs-35-f {
    width: 35px !important;
  }
  .wd-xs-35p-f {
    width: 35% !important;
  }
  .mx-wd-xs-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-xs-35p-f {
    min-width: 35% !important;
  }
  .wd-xs-40 {
    width: 40px;
  }
  .wd-xs-40p {
    width: 40%;
  }
  .mx-wd-xs-40p {
    max-width: 40%;
  }
  .mn-wd-xs-40p {
    min-width: 40%;
  }
  .wd-xs-40-f {
    width: 40px !important;
  }
  .wd-xs-40p-f {
    width: 40% !important;
  }
  .mx-wd-xs-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-xs-40p-f {
    min-width: 40% !important;
  }
  .wd-xs-45 {
    width: 45px;
  }
  .wd-xs-45p {
    width: 45%;
  }
  .mx-wd-xs-45p {
    max-width: 45%;
  }
  .mn-wd-xs-45p {
    min-width: 45%;
  }
  .wd-xs-45-f {
    width: 45px !important;
  }
  .wd-xs-45p-f {
    width: 45% !important;
  }
  .mx-wd-xs-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-xs-45p-f {
    min-width: 45% !important;
  }
  .wd-xs-50 {
    width: 50px;
  }
  .wd-xs-50p {
    width: 50%;
  }
  .mx-wd-xs-50p {
    max-width: 50%;
  }
  .mn-wd-xs-50p {
    min-width: 50%;
  }
  .wd-xs-50-f {
    width: 50px !important;
  }
  .wd-xs-50p-f {
    width: 50% !important;
  }
  .mx-wd-xs-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-xs-50p-f {
    min-width: 50% !important;
  }
  .wd-xs-55 {
    width: 55px;
  }
  .wd-xs-55p {
    width: 55%;
  }
  .mx-wd-xs-55p {
    max-width: 55%;
  }
  .mn-wd-xs-55p {
    min-width: 55%;
  }
  .wd-xs-55-f {
    width: 55px !important;
  }
  .wd-xs-55p-f {
    width: 55% !important;
  }
  .mx-wd-xs-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-xs-55p-f {
    min-width: 55% !important;
  }
  .wd-xs-60 {
    width: 60px;
  }
  .wd-xs-60p {
    width: 60%;
  }
  .mx-wd-xs-60p {
    max-width: 60%;
  }
  .mn-wd-xs-60p {
    min-width: 60%;
  }
  .wd-xs-60-f {
    width: 60px !important;
  }
  .wd-xs-60p-f {
    width: 60% !important;
  }
  .mx-wd-xs-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-xs-60p-f {
    min-width: 60% !important;
  }
  .wd-xs-65 {
    width: 65px;
  }
  .wd-xs-65p {
    width: 65%;
  }
  .mx-wd-xs-65p {
    max-width: 65%;
  }
  .mn-wd-xs-65p {
    min-width: 65%;
  }
  .wd-xs-65-f {
    width: 65px !important;
  }
  .wd-xs-65p-f {
    width: 65% !important;
  }
  .mx-wd-xs-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-xs-65p-f {
    min-width: 65% !important;
  }
  .wd-xs-70 {
    width: 70px;
  }
  .wd-xs-70p {
    width: 70%;
  }
  .mx-wd-xs-70p {
    max-width: 70%;
  }
  .mn-wd-xs-70p {
    min-width: 70%;
  }
  .wd-xs-70-f {
    width: 70px !important;
  }
  .wd-xs-70p-f {
    width: 70% !important;
  }
  .mx-wd-xs-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-xs-70p-f {
    min-width: 70% !important;
  }
  .wd-xs-75 {
    width: 75px;
  }
  .wd-xs-75p {
    width: 75%;
  }
  .mx-wd-xs-75p {
    max-width: 75%;
  }
  .mn-wd-xs-75p {
    min-width: 75%;
  }
  .wd-xs-75-f {
    width: 75px !important;
  }
  .wd-xs-75p-f {
    width: 75% !important;
  }
  .mx-wd-xs-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-xs-75p-f {
    min-width: 75% !important;
  }
  .wd-xs-80 {
    width: 80px;
  }
  .wd-xs-80p {
    width: 80%;
  }
  .mx-wd-xs-80p {
    max-width: 80%;
  }
  .mn-wd-xs-80p {
    min-width: 80%;
  }
  .wd-xs-80-f {
    width: 80px !important;
  }
  .wd-xs-80p-f {
    width: 80% !important;
  }
  .mx-wd-xs-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-xs-80p-f {
    min-width: 80% !important;
  }
  .wd-xs-85 {
    width: 85px;
  }
  .wd-xs-85p {
    width: 85%;
  }
  .mx-wd-xs-85p {
    max-width: 85%;
  }
  .mn-wd-xs-85p {
    min-width: 85%;
  }
  .wd-xs-85-f {
    width: 85px !important;
  }
  .wd-xs-85p-f {
    width: 85% !important;
  }
  .mx-wd-xs-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-xs-85p-f {
    min-width: 85% !important;
  }
  .wd-xs-90 {
    width: 90px;
  }
  .wd-xs-90p {
    width: 90%;
  }
  .mx-wd-xs-90p {
    max-width: 90%;
  }
  .mn-wd-xs-90p {
    min-width: 90%;
  }
  .wd-xs-90-f {
    width: 90px !important;
  }
  .wd-xs-90p-f {
    width: 90% !important;
  }
  .mx-wd-xs-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-xs-90p-f {
    min-width: 90% !important;
  }
  .wd-xs-95 {
    width: 95px;
  }
  .wd-xs-95p {
    width: 95%;
  }
  .mx-wd-xs-95p {
    max-width: 95%;
  }
  .mn-wd-xs-95p {
    min-width: 95%;
  }
  .wd-xs-95-f {
    width: 95px !important;
  }
  .wd-xs-95p-f {
    width: 95% !important;
  }
  .mx-wd-xs-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-xs-95p-f {
    min-width: 95% !important;
  }
  .wd-xs-100 {
    width: 100px;
  }
  .wd-xs-100p {
    width: 100%;
  }
  .mx-wd-xs-100p {
    max-width: 100%;
  }
  .mn-wd-xs-100p {
    min-width: 100%;
  }
  .wd-xs-100-f {
    width: 100px !important;
  }
  .wd-xs-100p-f {
    width: 100% !important;
  }
  .mx-wd-xs-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-xs-100p-f {
    min-width: 100% !important;
  }
  .wd-xs-150 {
    width: 150px;
  }
  .wd-xs-150p {
    width: 150%;
  }
  .mx-wd-xs-150p {
    max-width: 150%;
  }
  .mn-wd-xs-150p {
    min-width: 150%;
  }
  .wd-xs-150-f {
    width: 150px !important;
  }
  .wd-xs-150p-f {
    width: 150% !important;
  }
  .mx-wd-xs-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-xs-150p-f {
    min-width: 150% !important;
  }
  .wd-xs-200 {
    width: 200px;
  }
  .wd-xs-200p {
    width: 200%;
  }
  .mx-wd-xs-200p {
    max-width: 200%;
  }
  .mn-wd-xs-200p {
    min-width: 200%;
  }
  .wd-xs-200-f {
    width: 200px !important;
  }
  .wd-xs-200p-f {
    width: 200% !important;
  }
  .mx-wd-xs-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-xs-200p-f {
    min-width: 200% !important;
  }
  .wd-xs-250 {
    width: 250px;
  }
  .wd-xs-250p {
    width: 250%;
  }
  .mx-wd-xs-250p {
    max-width: 250%;
  }
  .mn-wd-xs-250p {
    min-width: 250%;
  }
  .wd-xs-250-f {
    width: 250px !important;
  }
  .wd-xs-250p-f {
    width: 250% !important;
  }
  .mx-wd-xs-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-xs-250p-f {
    min-width: 250% !important;
  }
  .wd-xs-300 {
    width: 300px;
  }
  .wd-xs-300p {
    width: 300%;
  }
  .mx-wd-xs-300p {
    max-width: 300%;
  }
  .mn-wd-xs-300p {
    min-width: 300%;
  }
  .wd-xs-300-f {
    width: 300px !important;
  }
  .wd-xs-300p-f {
    width: 300% !important;
  }
  .mx-wd-xs-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-xs-300p-f {
    min-width: 300% !important;
  }
  .wd-xs-350 {
    width: 350px;
  }
  .wd-xs-350p {
    width: 350%;
  }
  .mx-wd-xs-350p {
    max-width: 350%;
  }
  .mn-wd-xs-350p {
    min-width: 350%;
  }
  .wd-xs-350-f {
    width: 350px !important;
  }
  .wd-xs-350p-f {
    width: 350% !important;
  }
  .mx-wd-xs-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-xs-350p-f {
    min-width: 350% !important;
  }
  .wd-xs-400 {
    width: 400px;
  }
  .wd-xs-400p {
    width: 400%;
  }
  .mx-wd-xs-400p {
    max-width: 400%;
  }
  .mn-wd-xs-400p {
    min-width: 400%;
  }
  .wd-xs-400-f {
    width: 400px !important;
  }
  .wd-xs-400p-f {
    width: 400% !important;
  }
  .mx-wd-xs-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-xs-400p-f {
    min-width: 400% !important;
  }
  .wd-xs-450 {
    width: 450px;
  }
  .wd-xs-450p {
    width: 450%;
  }
  .mx-wd-xs-450p {
    max-width: 450%;
  }
  .mn-wd-xs-450p {
    min-width: 450%;
  }
  .wd-xs-450-f {
    width: 450px !important;
  }
  .wd-xs-450p-f {
    width: 450% !important;
  }
  .mx-wd-xs-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-xs-450p-f {
    min-width: 450% !important;
  }
  .wd-xs-500 {
    width: 500px;
  }
  .wd-xs-500p {
    width: 500%;
  }
  .mx-wd-xs-500p {
    max-width: 500%;
  }
  .mn-wd-xs-500p {
    min-width: 500%;
  }
  .wd-xs-500-f {
    width: 500px !important;
  }
  .wd-xs-500p-f {
    width: 500% !important;
  }
  .mx-wd-xs-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-xs-500p-f {
    min-width: 500% !important;
  }
  .wd-xs-550 {
    width: 550px;
  }
  .wd-xs-550p {
    width: 550%;
  }
  .mx-wd-xs-550p {
    max-width: 550%;
  }
  .mn-wd-xs-550p {
    min-width: 550%;
  }
  .wd-xs-550-f {
    width: 550px !important;
  }
  .wd-xs-550p-f {
    width: 550% !important;
  }
  .mx-wd-xs-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-xs-550p-f {
    min-width: 550% !important;
  }
  .wd-xs-600 {
    width: 600px;
  }
  .wd-xs-600p {
    width: 600%;
  }
  .mx-wd-xs-600p {
    max-width: 600%;
  }
  .mn-wd-xs-600p {
    min-width: 600%;
  }
  .wd-xs-600-f {
    width: 600px !important;
  }
  .wd-xs-600p-f {
    width: 600% !important;
  }
  .mx-wd-xs-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-xs-600p-f {
    min-width: 600% !important;
  }
  .wd-xs-650 {
    width: 650px;
  }
  .wd-xs-650p {
    width: 650%;
  }
  .mx-wd-xs-650p {
    max-width: 650%;
  }
  .mn-wd-xs-650p {
    min-width: 650%;
  }
  .wd-xs-650-f {
    width: 650px !important;
  }
  .wd-xs-650p-f {
    width: 650% !important;
  }
  .mx-wd-xs-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-xs-650p-f {
    min-width: 650% !important;
  }
  .wd-xs-700 {
    width: 700px;
  }
  .wd-xs-700p {
    width: 700%;
  }
  .mx-wd-xs-700p {
    max-width: 700%;
  }
  .mn-wd-xs-700p {
    min-width: 700%;
  }
  .wd-xs-700-f {
    width: 700px !important;
  }
  .wd-xs-700p-f {
    width: 700% !important;
  }
  .mx-wd-xs-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-xs-700p-f {
    min-width: 700% !important;
  }
  .wd-xs-750 {
    width: 750px;
  }
  .wd-xs-750p {
    width: 750%;
  }
  .mx-wd-xs-750p {
    max-width: 750%;
  }
  .mn-wd-xs-750p {
    min-width: 750%;
  }
  .wd-xs-750-f {
    width: 750px !important;
  }
  .wd-xs-750p-f {
    width: 750% !important;
  }
  .mx-wd-xs-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-xs-750p-f {
    min-width: 750% !important;
  }
  .wd-xs-800 {
    width: 800px;
  }
  .wd-xs-800p {
    width: 800%;
  }
  .mx-wd-xs-800p {
    max-width: 800%;
  }
  .mn-wd-xs-800p {
    min-width: 800%;
  }
  .wd-xs-800-f {
    width: 800px !important;
  }
  .wd-xs-800p-f {
    width: 800% !important;
  }
  .mx-wd-xs-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-xs-800p-f {
    min-width: 800% !important;
  }
  .wd-xs-850 {
    width: 850px;
  }
  .wd-xs-850p {
    width: 850%;
  }
  .mx-wd-xs-850p {
    max-width: 850%;
  }
  .mn-wd-xs-850p {
    min-width: 850%;
  }
  .wd-xs-850-f {
    width: 850px !important;
  }
  .wd-xs-850p-f {
    width: 850% !important;
  }
  .mx-wd-xs-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-xs-850p-f {
    min-width: 850% !important;
  }
  .wd-xs-900 {
    width: 900px;
  }
  .wd-xs-900p {
    width: 900%;
  }
  .mx-wd-xs-900p {
    max-width: 900%;
  }
  .mn-wd-xs-900p {
    min-width: 900%;
  }
  .wd-xs-900-f {
    width: 900px !important;
  }
  .wd-xs-900p-f {
    width: 900% !important;
  }
  .mx-wd-xs-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-xs-900p-f {
    min-width: 900% !important;
  }
  .wd-xs-950 {
    width: 950px;
  }
  .wd-xs-950p {
    width: 950%;
  }
  .mx-wd-xs-950p {
    max-width: 950%;
  }
  .mn-wd-xs-950p {
    min-width: 950%;
  }
  .wd-xs-950-f {
    width: 950px !important;
  }
  .wd-xs-950p-f {
    width: 950% !important;
  }
  .mx-wd-xs-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-xs-950p-f {
    min-width: 950% !important;
  }
  .wd-xs-1000 {
    width: 1000px;
  }
  .wd-xs-1000p {
    width: 1000%;
  }
  .mx-wd-xs-1000p {
    max-width: 1000%;
  }
  .mn-wd-xs-1000p {
    min-width: 1000%;
  }
  .wd-xs-1000-f {
    width: 1000px !important;
  }
  .wd-xs-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-xs-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-xs-1000p-f {
    min-width: 1000% !important;
  }
  .wd-xs-auto {
    width: auto;
  }
  .wd-xs-auto-f {
    width: auto !important;
  }
}

@media (min-width: 576px) {
  .wd-sm-5 {
    width: 5px;
  }
  .wd-sm-5p {
    width: 5%;
  }
  .mx-wd-sm-5p {
    max-width: 5%;
  }
  .mn-wd-sm-5p {
    min-width: 5%;
  }
  .wd-sm-5-f {
    width: 5px !important;
  }
  .wd-sm-5p-f {
    width: 5% !important;
  }
  .mx-wd-sm-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-sm-5p-f {
    min-width: 5% !important;
  }
  .wd-sm-10 {
    width: 10px;
  }
  .wd-sm-10p {
    width: 10%;
  }
  .mx-wd-sm-10p {
    max-width: 10%;
  }
  .mn-wd-sm-10p {
    min-width: 10%;
  }
  .wd-sm-10-f {
    width: 10px !important;
  }
  .wd-sm-10p-f {
    width: 10% !important;
  }
  .mx-wd-sm-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-sm-10p-f {
    min-width: 10% !important;
  }
  .wd-sm-15 {
    width: 15px;
  }
  .wd-sm-15p {
    width: 15%;
  }
  .mx-wd-sm-15p {
    max-width: 15%;
  }
  .mn-wd-sm-15p {
    min-width: 15%;
  }
  .wd-sm-15-f {
    width: 15px !important;
  }
  .wd-sm-15p-f {
    width: 15% !important;
  }
  .mx-wd-sm-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-sm-15p-f {
    min-width: 15% !important;
  }
  .wd-sm-20 {
    width: 20px;
  }
  .wd-sm-20p {
    width: 20%;
  }
  .mx-wd-sm-20p {
    max-width: 20%;
  }
  .mn-wd-sm-20p {
    min-width: 20%;
  }
  .wd-sm-20-f {
    width: 20px !important;
  }
  .wd-sm-20p-f {
    width: 20% !important;
  }
  .mx-wd-sm-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-sm-20p-f {
    min-width: 20% !important;
  }
  .wd-sm-25 {
    width: 25px;
  }
  .wd-sm-25p {
    width: 25%;
  }
  .mx-wd-sm-25p {
    max-width: 25%;
  }
  .mn-wd-sm-25p {
    min-width: 25%;
  }
  .wd-sm-25-f {
    width: 25px !important;
  }
  .wd-sm-25p-f {
    width: 25% !important;
  }
  .mx-wd-sm-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-sm-25p-f {
    min-width: 25% !important;
  }
  .wd-sm-30 {
    width: 30px;
  }
  .wd-sm-30p {
    width: 30%;
  }
  .mx-wd-sm-30p {
    max-width: 30%;
  }
  .mn-wd-sm-30p {
    min-width: 30%;
  }
  .wd-sm-30-f {
    width: 30px !important;
  }
  .wd-sm-30p-f {
    width: 30% !important;
  }
  .mx-wd-sm-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-sm-30p-f {
    min-width: 30% !important;
  }
  .wd-sm-35 {
    width: 35px;
  }
  .wd-sm-35p {
    width: 35%;
  }
  .mx-wd-sm-35p {
    max-width: 35%;
  }
  .mn-wd-sm-35p {
    min-width: 35%;
  }
  .wd-sm-35-f {
    width: 35px !important;
  }
  .wd-sm-35p-f {
    width: 35% !important;
  }
  .mx-wd-sm-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-sm-35p-f {
    min-width: 35% !important;
  }
  .wd-sm-40 {
    width: 40px;
  }
  .wd-sm-40p {
    width: 40%;
  }
  .mx-wd-sm-40p {
    max-width: 40%;
  }
  .mn-wd-sm-40p {
    min-width: 40%;
  }
  .wd-sm-40-f {
    width: 40px !important;
  }
  .wd-sm-40p-f {
    width: 40% !important;
  }
  .mx-wd-sm-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-sm-40p-f {
    min-width: 40% !important;
  }
  .wd-sm-45 {
    width: 45px;
  }
  .wd-sm-45p {
    width: 45%;
  }
  .mx-wd-sm-45p {
    max-width: 45%;
  }
  .mn-wd-sm-45p {
    min-width: 45%;
  }
  .wd-sm-45-f {
    width: 45px !important;
  }
  .wd-sm-45p-f {
    width: 45% !important;
  }
  .mx-wd-sm-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-sm-45p-f {
    min-width: 45% !important;
  }
  .wd-sm-50 {
    width: 50px;
  }
  .wd-sm-50p {
    width: 50%;
  }
  .mx-wd-sm-50p {
    max-width: 50%;
  }
  .mn-wd-sm-50p {
    min-width: 50%;
  }
  .wd-sm-50-f {
    width: 50px !important;
  }
  .wd-sm-50p-f {
    width: 50% !important;
  }
  .mx-wd-sm-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-sm-50p-f {
    min-width: 50% !important;
  }
  .wd-sm-55 {
    width: 55px;
  }
  .wd-sm-55p {
    width: 55%;
  }
  .mx-wd-sm-55p {
    max-width: 55%;
  }
  .mn-wd-sm-55p {
    min-width: 55%;
  }
  .wd-sm-55-f {
    width: 55px !important;
  }
  .wd-sm-55p-f {
    width: 55% !important;
  }
  .mx-wd-sm-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-sm-55p-f {
    min-width: 55% !important;
  }
  .wd-sm-60 {
    width: 60px;
  }
  .wd-sm-60p {
    width: 60%;
  }
  .mx-wd-sm-60p {
    max-width: 60%;
  }
  .mn-wd-sm-60p {
    min-width: 60%;
  }
  .wd-sm-60-f {
    width: 60px !important;
  }
  .wd-sm-60p-f {
    width: 60% !important;
  }
  .mx-wd-sm-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-sm-60p-f {
    min-width: 60% !important;
  }
  .wd-sm-65 {
    width: 65px;
  }
  .wd-sm-65p {
    width: 65%;
  }
  .mx-wd-sm-65p {
    max-width: 65%;
  }
  .mn-wd-sm-65p {
    min-width: 65%;
  }
  .wd-sm-65-f {
    width: 65px !important;
  }
  .wd-sm-65p-f {
    width: 65% !important;
  }
  .mx-wd-sm-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-sm-65p-f {
    min-width: 65% !important;
  }
  .wd-sm-70 {
    width: 70px;
  }
  .wd-sm-70p {
    width: 70%;
  }
  .mx-wd-sm-70p {
    max-width: 70%;
  }
  .mn-wd-sm-70p {
    min-width: 70%;
  }
  .wd-sm-70-f {
    width: 70px !important;
  }
  .wd-sm-70p-f {
    width: 70% !important;
  }
  .mx-wd-sm-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-sm-70p-f {
    min-width: 70% !important;
  }
  .wd-sm-75 {
    width: 75px;
  }
  .wd-sm-75p {
    width: 75%;
  }
  .mx-wd-sm-75p {
    max-width: 75%;
  }
  .mn-wd-sm-75p {
    min-width: 75%;
  }
  .wd-sm-75-f {
    width: 75px !important;
  }
  .wd-sm-75p-f {
    width: 75% !important;
  }
  .mx-wd-sm-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-sm-75p-f {
    min-width: 75% !important;
  }
  .wd-sm-80 {
    width: 80px;
  }
  .wd-sm-80p {
    width: 80%;
  }
  .mx-wd-sm-80p {
    max-width: 80%;
  }
  .mn-wd-sm-80p {
    min-width: 80%;
  }
  .wd-sm-80-f {
    width: 80px !important;
  }
  .wd-sm-80p-f {
    width: 80% !important;
  }
  .mx-wd-sm-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-sm-80p-f {
    min-width: 80% !important;
  }
  .wd-sm-85 {
    width: 85px;
  }
  .wd-sm-85p {
    width: 85%;
  }
  .mx-wd-sm-85p {
    max-width: 85%;
  }
  .mn-wd-sm-85p {
    min-width: 85%;
  }
  .wd-sm-85-f {
    width: 85px !important;
  }
  .wd-sm-85p-f {
    width: 85% !important;
  }
  .mx-wd-sm-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-sm-85p-f {
    min-width: 85% !important;
  }
  .wd-sm-90 {
    width: 90px;
  }
  .wd-sm-90p {
    width: 90%;
  }
  .mx-wd-sm-90p {
    max-width: 90%;
  }
  .mn-wd-sm-90p {
    min-width: 90%;
  }
  .wd-sm-90-f {
    width: 90px !important;
  }
  .wd-sm-90p-f {
    width: 90% !important;
  }
  .mx-wd-sm-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-sm-90p-f {
    min-width: 90% !important;
  }
  .wd-sm-95 {
    width: 95px;
  }
  .wd-sm-95p {
    width: 95%;
  }
  .mx-wd-sm-95p {
    max-width: 95%;
  }
  .mn-wd-sm-95p {
    min-width: 95%;
  }
  .wd-sm-95-f {
    width: 95px !important;
  }
  .wd-sm-95p-f {
    width: 95% !important;
  }
  .mx-wd-sm-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-sm-95p-f {
    min-width: 95% !important;
  }
  .wd-sm-100 {
    width: 100px;
  }
  .wd-sm-100p {
    width: 100%;
  }
  .mx-wd-sm-100p {
    max-width: 100%;
  }
  .mn-wd-sm-100p {
    min-width: 100%;
  }
  .wd-sm-100-f {
    width: 100px !important;
  }
  .wd-sm-100p-f {
    width: 100% !important;
  }
  .mx-wd-sm-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-sm-100p-f {
    min-width: 100% !important;
  }
  .wd-sm-150 {
    width: 150px;
  }
  .wd-sm-150p {
    width: 150%;
  }
  .mx-wd-sm-150p {
    max-width: 150%;
  }
  .mn-wd-sm-150p {
    min-width: 150%;
  }
  .wd-sm-150-f {
    width: 150px !important;
  }
  .wd-sm-150p-f {
    width: 150% !important;
  }
  .mx-wd-sm-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-sm-150p-f {
    min-width: 150% !important;
  }
  .wd-sm-200 {
    width: 200px;
  }
  .wd-sm-200p {
    width: 200%;
  }
  .mx-wd-sm-200p {
    max-width: 200%;
  }
  .mn-wd-sm-200p {
    min-width: 200%;
  }
  .wd-sm-200-f {
    width: 200px !important;
  }
  .wd-sm-200p-f {
    width: 200% !important;
  }
  .mx-wd-sm-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-sm-200p-f {
    min-width: 200% !important;
  }
  .wd-sm-250 {
    width: 250px;
  }
  .wd-sm-250p {
    width: 250%;
  }
  .mx-wd-sm-250p {
    max-width: 250%;
  }
  .mn-wd-sm-250p {
    min-width: 250%;
  }
  .wd-sm-250-f {
    width: 250px !important;
  }
  .wd-sm-250p-f {
    width: 250% !important;
  }
  .mx-wd-sm-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-sm-250p-f {
    min-width: 250% !important;
  }
  .wd-sm-300 {
    width: 300px;
  }
  .wd-sm-300p {
    width: 300%;
  }
  .mx-wd-sm-300p {
    max-width: 300%;
  }
  .mn-wd-sm-300p {
    min-width: 300%;
  }
  .wd-sm-300-f {
    width: 300px !important;
  }
  .wd-sm-300p-f {
    width: 300% !important;
  }
  .mx-wd-sm-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-sm-300p-f {
    min-width: 300% !important;
  }
  .wd-sm-350 {
    width: 350px;
  }
  .wd-sm-350p {
    width: 350%;
  }
  .mx-wd-sm-350p {
    max-width: 350%;
  }
  .mn-wd-sm-350p {
    min-width: 350%;
  }
  .wd-sm-350-f {
    width: 350px !important;
  }
  .wd-sm-350p-f {
    width: 350% !important;
  }
  .mx-wd-sm-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-sm-350p-f {
    min-width: 350% !important;
  }
  .wd-sm-400 {
    width: 400px;
  }
  .wd-sm-400p {
    width: 400%;
  }
  .mx-wd-sm-400p {
    max-width: 400%;
  }
  .mn-wd-sm-400p {
    min-width: 400%;
  }
  .wd-sm-400-f {
    width: 400px !important;
  }
  .wd-sm-400p-f {
    width: 400% !important;
  }
  .mx-wd-sm-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-sm-400p-f {
    min-width: 400% !important;
  }
  .wd-sm-450 {
    width: 450px;
  }
  .wd-sm-450p {
    width: 450%;
  }
  .mx-wd-sm-450p {
    max-width: 450%;
  }
  .mn-wd-sm-450p {
    min-width: 450%;
  }
  .wd-sm-450-f {
    width: 450px !important;
  }
  .wd-sm-450p-f {
    width: 450% !important;
  }
  .mx-wd-sm-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-sm-450p-f {
    min-width: 450% !important;
  }
  .wd-sm-500 {
    width: 500px;
  }
  .wd-sm-500p {
    width: 500%;
  }
  .mx-wd-sm-500p {
    max-width: 500%;
  }
  .mn-wd-sm-500p {
    min-width: 500%;
  }
  .wd-sm-500-f {
    width: 500px !important;
  }
  .wd-sm-500p-f {
    width: 500% !important;
  }
  .mx-wd-sm-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-sm-500p-f {
    min-width: 500% !important;
  }
  .wd-sm-550 {
    width: 550px;
  }
  .wd-sm-550p {
    width: 550%;
  }
  .mx-wd-sm-550p {
    max-width: 550%;
  }
  .mn-wd-sm-550p {
    min-width: 550%;
  }
  .wd-sm-550-f {
    width: 550px !important;
  }
  .wd-sm-550p-f {
    width: 550% !important;
  }
  .mx-wd-sm-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-sm-550p-f {
    min-width: 550% !important;
  }
  .wd-sm-600 {
    width: 600px;
  }
  .wd-sm-600p {
    width: 600%;
  }
  .mx-wd-sm-600p {
    max-width: 600%;
  }
  .mn-wd-sm-600p {
    min-width: 600%;
  }
  .wd-sm-600-f {
    width: 600px !important;
  }
  .wd-sm-600p-f {
    width: 600% !important;
  }
  .mx-wd-sm-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-sm-600p-f {
    min-width: 600% !important;
  }
  .wd-sm-650 {
    width: 650px;
  }
  .wd-sm-650p {
    width: 650%;
  }
  .mx-wd-sm-650p {
    max-width: 650%;
  }
  .mn-wd-sm-650p {
    min-width: 650%;
  }
  .wd-sm-650-f {
    width: 650px !important;
  }
  .wd-sm-650p-f {
    width: 650% !important;
  }
  .mx-wd-sm-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-sm-650p-f {
    min-width: 650% !important;
  }
  .wd-sm-700 {
    width: 700px;
  }
  .wd-sm-700p {
    width: 700%;
  }
  .mx-wd-sm-700p {
    max-width: 700%;
  }
  .mn-wd-sm-700p {
    min-width: 700%;
  }
  .wd-sm-700-f {
    width: 700px !important;
  }
  .wd-sm-700p-f {
    width: 700% !important;
  }
  .mx-wd-sm-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-sm-700p-f {
    min-width: 700% !important;
  }
  .wd-sm-750 {
    width: 750px;
  }
  .wd-sm-750p {
    width: 750%;
  }
  .mx-wd-sm-750p {
    max-width: 750%;
  }
  .mn-wd-sm-750p {
    min-width: 750%;
  }
  .wd-sm-750-f {
    width: 750px !important;
  }
  .wd-sm-750p-f {
    width: 750% !important;
  }
  .mx-wd-sm-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-sm-750p-f {
    min-width: 750% !important;
  }
  .wd-sm-800 {
    width: 800px;
  }
  .wd-sm-800p {
    width: 800%;
  }
  .mx-wd-sm-800p {
    max-width: 800%;
  }
  .mn-wd-sm-800p {
    min-width: 800%;
  }
  .wd-sm-800-f {
    width: 800px !important;
  }
  .wd-sm-800p-f {
    width: 800% !important;
  }
  .mx-wd-sm-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-sm-800p-f {
    min-width: 800% !important;
  }
  .wd-sm-850 {
    width: 850px;
  }
  .wd-sm-850p {
    width: 850%;
  }
  .mx-wd-sm-850p {
    max-width: 850%;
  }
  .mn-wd-sm-850p {
    min-width: 850%;
  }
  .wd-sm-850-f {
    width: 850px !important;
  }
  .wd-sm-850p-f {
    width: 850% !important;
  }
  .mx-wd-sm-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-sm-850p-f {
    min-width: 850% !important;
  }
  .wd-sm-900 {
    width: 900px;
  }
  .wd-sm-900p {
    width: 900%;
  }
  .mx-wd-sm-900p {
    max-width: 900%;
  }
  .mn-wd-sm-900p {
    min-width: 900%;
  }
  .wd-sm-900-f {
    width: 900px !important;
  }
  .wd-sm-900p-f {
    width: 900% !important;
  }
  .mx-wd-sm-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-sm-900p-f {
    min-width: 900% !important;
  }
  .wd-sm-950 {
    width: 950px;
  }
  .wd-sm-950p {
    width: 950%;
  }
  .mx-wd-sm-950p {
    max-width: 950%;
  }
  .mn-wd-sm-950p {
    min-width: 950%;
  }
  .wd-sm-950-f {
    width: 950px !important;
  }
  .wd-sm-950p-f {
    width: 950% !important;
  }
  .mx-wd-sm-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-sm-950p-f {
    min-width: 950% !important;
  }
  .wd-sm-1000 {
    width: 1000px;
  }
  .wd-sm-1000p {
    width: 1000%;
  }
  .mx-wd-sm-1000p {
    max-width: 1000%;
  }
  .mn-wd-sm-1000p {
    min-width: 1000%;
  }
  .wd-sm-1000-f {
    width: 1000px !important;
  }
  .wd-sm-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-sm-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-sm-1000p-f {
    min-width: 1000% !important;
  }
  .wd-sm-auto {
    width: auto;
  }
  .wd-sm-auto-f {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .wd-md-5 {
    width: 5px;
  }
  .wd-md-5p {
    width: 5%;
  }
  .mx-wd-md-5p {
    max-width: 5%;
  }
  .mn-wd-md-5p {
    min-width: 5%;
  }
  .wd-md-5-f {
    width: 5px !important;
  }
  .wd-md-5p-f {
    width: 5% !important;
  }
  .mx-wd-md-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-md-5p-f {
    min-width: 5% !important;
  }
  .wd-md-10 {
    width: 10px;
  }
  .wd-md-10p {
    width: 10%;
  }
  .mx-wd-md-10p {
    max-width: 10%;
  }
  .mn-wd-md-10p {
    min-width: 10%;
  }
  .wd-md-10-f {
    width: 10px !important;
  }
  .wd-md-10p-f {
    width: 10% !important;
  }
  .mx-wd-md-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-md-10p-f {
    min-width: 10% !important;
  }
  .wd-md-15 {
    width: 15px;
  }
  .wd-md-15p {
    width: 15%;
  }
  .mx-wd-md-15p {
    max-width: 15%;
  }
  .mn-wd-md-15p {
    min-width: 15%;
  }
  .wd-md-15-f {
    width: 15px !important;
  }
  .wd-md-15p-f {
    width: 15% !important;
  }
  .mx-wd-md-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-md-15p-f {
    min-width: 15% !important;
  }
  .wd-md-20 {
    width: 20px;
  }
  .wd-md-20p {
    width: 20%;
  }
  .mx-wd-md-20p {
    max-width: 20%;
  }
  .mn-wd-md-20p {
    min-width: 20%;
  }
  .wd-md-20-f {
    width: 20px !important;
  }
  .wd-md-20p-f {
    width: 20% !important;
  }
  .mx-wd-md-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-md-20p-f {
    min-width: 20% !important;
  }
  .wd-md-25 {
    width: 25px;
  }
  .wd-md-25p {
    width: 25%;
  }
  .mx-wd-md-25p {
    max-width: 25%;
  }
  .mn-wd-md-25p {
    min-width: 25%;
  }
  .wd-md-25-f {
    width: 25px !important;
  }
  .wd-md-25p-f {
    width: 25% !important;
  }
  .mx-wd-md-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-md-25p-f {
    min-width: 25% !important;
  }
  .wd-md-30 {
    width: 30px;
  }
  .wd-md-30p {
    width: 30%;
  }
  .mx-wd-md-30p {
    max-width: 30%;
  }
  .mn-wd-md-30p {
    min-width: 30%;
  }
  .wd-md-30-f {
    width: 30px !important;
  }
  .wd-md-30p-f {
    width: 30% !important;
  }
  .mx-wd-md-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-md-30p-f {
    min-width: 30% !important;
  }
  .wd-md-35 {
    width: 35px;
  }
  .wd-md-35p {
    width: 35%;
  }
  .mx-wd-md-35p {
    max-width: 35%;
  }
  .mn-wd-md-35p {
    min-width: 35%;
  }
  .wd-md-35-f {
    width: 35px !important;
  }
  .wd-md-35p-f {
    width: 35% !important;
  }
  .mx-wd-md-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-md-35p-f {
    min-width: 35% !important;
  }
  .wd-md-40 {
    width: 40px;
  }
  .wd-md-40p {
    width: 40%;
  }
  .mx-wd-md-40p {
    max-width: 40%;
  }
  .mn-wd-md-40p {
    min-width: 40%;
  }
  .wd-md-40-f {
    width: 40px !important;
  }
  .wd-md-40p-f {
    width: 40% !important;
  }
  .mx-wd-md-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-md-40p-f {
    min-width: 40% !important;
  }
  .wd-md-45 {
    width: 45px;
  }
  .wd-md-45p {
    width: 45%;
  }
  .mx-wd-md-45p {
    max-width: 45%;
  }
  .mn-wd-md-45p {
    min-width: 45%;
  }
  .wd-md-45-f {
    width: 45px !important;
  }
  .wd-md-45p-f {
    width: 45% !important;
  }
  .mx-wd-md-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-md-45p-f {
    min-width: 45% !important;
  }
  .wd-md-50 {
    width: 50px;
  }
  .wd-md-50p {
    width: 50%;
  }
  .mx-wd-md-50p {
    max-width: 50%;
  }
  .mn-wd-md-50p {
    min-width: 50%;
  }
  .wd-md-50-f {
    width: 50px !important;
  }
  .wd-md-50p-f {
    width: 50% !important;
  }
  .mx-wd-md-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-md-50p-f {
    min-width: 50% !important;
  }
  .wd-md-55 {
    width: 55px;
  }
  .wd-md-55p {
    width: 55%;
  }
  .mx-wd-md-55p {
    max-width: 55%;
  }
  .mn-wd-md-55p {
    min-width: 55%;
  }
  .wd-md-55-f {
    width: 55px !important;
  }
  .wd-md-55p-f {
    width: 55% !important;
  }
  .mx-wd-md-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-md-55p-f {
    min-width: 55% !important;
  }
  .wd-md-60 {
    width: 60px;
  }
  .wd-md-60p {
    width: 60%;
  }
  .mx-wd-md-60p {
    max-width: 60%;
  }
  .mn-wd-md-60p {
    min-width: 60%;
  }
  .wd-md-60-f {
    width: 60px !important;
  }
  .wd-md-60p-f {
    width: 60% !important;
  }
  .mx-wd-md-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-md-60p-f {
    min-width: 60% !important;
  }
  .wd-md-65 {
    width: 65px;
  }
  .wd-md-65p {
    width: 65%;
  }
  .mx-wd-md-65p {
    max-width: 65%;
  }
  .mn-wd-md-65p {
    min-width: 65%;
  }
  .wd-md-65-f {
    width: 65px !important;
  }
  .wd-md-65p-f {
    width: 65% !important;
  }
  .mx-wd-md-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-md-65p-f {
    min-width: 65% !important;
  }
  .wd-md-70 {
    width: 70px;
  }
  .wd-md-70p {
    width: 70%;
  }
  .mx-wd-md-70p {
    max-width: 70%;
  }
  .mn-wd-md-70p {
    min-width: 70%;
  }
  .wd-md-70-f {
    width: 70px !important;
  }
  .wd-md-70p-f {
    width: 70% !important;
  }
  .mx-wd-md-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-md-70p-f {
    min-width: 70% !important;
  }
  .wd-md-75 {
    width: 75px;
  }
  .wd-md-75p {
    width: 75%;
  }
  .mx-wd-md-75p {
    max-width: 75%;
  }
  .mn-wd-md-75p {
    min-width: 75%;
  }
  .wd-md-75-f {
    width: 75px !important;
  }
  .wd-md-75p-f {
    width: 75% !important;
  }
  .mx-wd-md-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-md-75p-f {
    min-width: 75% !important;
  }
  .wd-md-80 {
    width: 80px;
  }
  .wd-md-80p {
    width: 80%;
  }
  .mx-wd-md-80p {
    max-width: 80%;
  }
  .mn-wd-md-80p {
    min-width: 80%;
  }
  .wd-md-80-f {
    width: 80px !important;
  }
  .wd-md-80p-f {
    width: 80% !important;
  }
  .mx-wd-md-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-md-80p-f {
    min-width: 80% !important;
  }
  .wd-md-85 {
    width: 85px;
  }
  .wd-md-85p {
    width: 85%;
  }
  .mx-wd-md-85p {
    max-width: 85%;
  }
  .mn-wd-md-85p {
    min-width: 85%;
  }
  .wd-md-85-f {
    width: 85px !important;
  }
  .wd-md-85p-f {
    width: 85% !important;
  }
  .mx-wd-md-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-md-85p-f {
    min-width: 85% !important;
  }
  .wd-md-90 {
    width: 90px;
  }
  .wd-md-90p {
    width: 90%;
  }
  .mx-wd-md-90p {
    max-width: 90%;
  }
  .mn-wd-md-90p {
    min-width: 90%;
  }
  .wd-md-90-f {
    width: 90px !important;
  }
  .wd-md-90p-f {
    width: 90% !important;
  }
  .mx-wd-md-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-md-90p-f {
    min-width: 90% !important;
  }
  .wd-md-95 {
    width: 95px;
  }
  .wd-md-95p {
    width: 95%;
  }
  .mx-wd-md-95p {
    max-width: 95%;
  }
  .mn-wd-md-95p {
    min-width: 95%;
  }
  .wd-md-95-f {
    width: 95px !important;
  }
  .wd-md-95p-f {
    width: 95% !important;
  }
  .mx-wd-md-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-md-95p-f {
    min-width: 95% !important;
  }
  .wd-md-100 {
    width: 100px;
  }
  .wd-md-100p {
    width: 100%;
  }
  .mx-wd-md-100p {
    max-width: 100%;
  }
  .mn-wd-md-100p {
    min-width: 100%;
  }
  .wd-md-100-f {
    width: 100px !important;
  }
  .wd-md-100p-f {
    width: 100% !important;
  }
  .mx-wd-md-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-md-100p-f {
    min-width: 100% !important;
  }
  .wd-md-150 {
    width: 150px;
  }
  .wd-md-150p {
    width: 150%;
  }
  .mx-wd-md-150p {
    max-width: 150%;
  }
  .mn-wd-md-150p {
    min-width: 150%;
  }
  .wd-md-150-f {
    width: 150px !important;
  }
  .wd-md-150p-f {
    width: 150% !important;
  }
  .mx-wd-md-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-md-150p-f {
    min-width: 150% !important;
  }
  .wd-md-200 {
    width: 200px;
  }
  .wd-md-200p {
    width: 200%;
  }
  .mx-wd-md-200p {
    max-width: 200%;
  }
  .mn-wd-md-200p {
    min-width: 200%;
  }
  .wd-md-200-f {
    width: 200px !important;
  }
  .wd-md-200p-f {
    width: 200% !important;
  }
  .mx-wd-md-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-md-200p-f {
    min-width: 200% !important;
  }
  .wd-md-250 {
    width: 250px;
  }
  .wd-md-250p {
    width: 250%;
  }
  .mx-wd-md-250p {
    max-width: 250%;
  }
  .mn-wd-md-250p {
    min-width: 250%;
  }
  .wd-md-250-f {
    width: 250px !important;
  }
  .wd-md-250p-f {
    width: 250% !important;
  }
  .mx-wd-md-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-md-250p-f {
    min-width: 250% !important;
  }
  .wd-md-300 {
    width: 300px;
  }
  .wd-md-300p {
    width: 300%;
  }
  .mx-wd-md-300p {
    max-width: 300%;
  }
  .mn-wd-md-300p {
    min-width: 300%;
  }
  .wd-md-300-f {
    width: 300px !important;
  }
  .wd-md-300p-f {
    width: 300% !important;
  }
  .mx-wd-md-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-md-300p-f {
    min-width: 300% !important;
  }
  .wd-md-350 {
    width: 350px;
  }
  .wd-md-350p {
    width: 350%;
  }
  .mx-wd-md-350p {
    max-width: 350%;
  }
  .mn-wd-md-350p {
    min-width: 350%;
  }
  .wd-md-350-f {
    width: 350px !important;
  }
  .wd-md-350p-f {
    width: 350% !important;
  }
  .mx-wd-md-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-md-350p-f {
    min-width: 350% !important;
  }
  .wd-md-400 {
    width: 400px;
  }
  .wd-md-400p {
    width: 400%;
  }
  .mx-wd-md-400p {
    max-width: 400%;
  }
  .mn-wd-md-400p {
    min-width: 400%;
  }
  .wd-md-400-f {
    width: 400px !important;
  }
  .wd-md-400p-f {
    width: 400% !important;
  }
  .mx-wd-md-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-md-400p-f {
    min-width: 400% !important;
  }
  .wd-md-450 {
    width: 450px;
  }
  .wd-md-450p {
    width: 450%;
  }
  .mx-wd-md-450p {
    max-width: 450%;
  }
  .mn-wd-md-450p {
    min-width: 450%;
  }
  .wd-md-450-f {
    width: 450px !important;
  }
  .wd-md-450p-f {
    width: 450% !important;
  }
  .mx-wd-md-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-md-450p-f {
    min-width: 450% !important;
  }
  .wd-md-500 {
    width: 500px;
  }
  .wd-md-500p {
    width: 500%;
  }
  .mx-wd-md-500p {
    max-width: 500%;
  }
  .mn-wd-md-500p {
    min-width: 500%;
  }
  .wd-md-500-f {
    width: 500px !important;
  }
  .wd-md-500p-f {
    width: 500% !important;
  }
  .mx-wd-md-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-md-500p-f {
    min-width: 500% !important;
  }
  .wd-md-550 {
    width: 550px;
  }
  .wd-md-550p {
    width: 550%;
  }
  .mx-wd-md-550p {
    max-width: 550%;
  }
  .mn-wd-md-550p {
    min-width: 550%;
  }
  .wd-md-550-f {
    width: 550px !important;
  }
  .wd-md-550p-f {
    width: 550% !important;
  }
  .mx-wd-md-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-md-550p-f {
    min-width: 550% !important;
  }
  .wd-md-600 {
    width: 600px;
  }
  .wd-md-600p {
    width: 600%;
  }
  .mx-wd-md-600p {
    max-width: 600%;
  }
  .mn-wd-md-600p {
    min-width: 600%;
  }
  .wd-md-600-f {
    width: 600px !important;
  }
  .wd-md-600p-f {
    width: 600% !important;
  }
  .mx-wd-md-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-md-600p-f {
    min-width: 600% !important;
  }
  .wd-md-650 {
    width: 650px;
  }
  .wd-md-650p {
    width: 650%;
  }
  .mx-wd-md-650p {
    max-width: 650%;
  }
  .mn-wd-md-650p {
    min-width: 650%;
  }
  .wd-md-650-f {
    width: 650px !important;
  }
  .wd-md-650p-f {
    width: 650% !important;
  }
  .mx-wd-md-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-md-650p-f {
    min-width: 650% !important;
  }
  .wd-md-700 {
    width: 700px;
  }
  .wd-md-700p {
    width: 700%;
  }
  .mx-wd-md-700p {
    max-width: 700%;
  }
  .mn-wd-md-700p {
    min-width: 700%;
  }
  .wd-md-700-f {
    width: 700px !important;
  }
  .wd-md-700p-f {
    width: 700% !important;
  }
  .mx-wd-md-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-md-700p-f {
    min-width: 700% !important;
  }
  .wd-md-750 {
    width: 750px;
  }
  .wd-md-750p {
    width: 750%;
  }
  .mx-wd-md-750p {
    max-width: 750%;
  }
  .mn-wd-md-750p {
    min-width: 750%;
  }
  .wd-md-750-f {
    width: 750px !important;
  }
  .wd-md-750p-f {
    width: 750% !important;
  }
  .mx-wd-md-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-md-750p-f {
    min-width: 750% !important;
  }
  .wd-md-800 {
    width: 800px;
  }
  .wd-md-800p {
    width: 800%;
  }
  .mx-wd-md-800p {
    max-width: 800%;
  }
  .mn-wd-md-800p {
    min-width: 800%;
  }
  .wd-md-800-f {
    width: 800px !important;
  }
  .wd-md-800p-f {
    width: 800% !important;
  }
  .mx-wd-md-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-md-800p-f {
    min-width: 800% !important;
  }
  .wd-md-850 {
    width: 850px;
  }
  .wd-md-850p {
    width: 850%;
  }
  .mx-wd-md-850p {
    max-width: 850%;
  }
  .mn-wd-md-850p {
    min-width: 850%;
  }
  .wd-md-850-f {
    width: 850px !important;
  }
  .wd-md-850p-f {
    width: 850% !important;
  }
  .mx-wd-md-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-md-850p-f {
    min-width: 850% !important;
  }
  .wd-md-900 {
    width: 900px;
  }
  .wd-md-900p {
    width: 900%;
  }
  .mx-wd-md-900p {
    max-width: 900%;
  }
  .mn-wd-md-900p {
    min-width: 900%;
  }
  .wd-md-900-f {
    width: 900px !important;
  }
  .wd-md-900p-f {
    width: 900% !important;
  }
  .mx-wd-md-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-md-900p-f {
    min-width: 900% !important;
  }
  .wd-md-950 {
    width: 950px;
  }
  .wd-md-950p {
    width: 950%;
  }
  .mx-wd-md-950p {
    max-width: 950%;
  }
  .mn-wd-md-950p {
    min-width: 950%;
  }
  .wd-md-950-f {
    width: 950px !important;
  }
  .wd-md-950p-f {
    width: 950% !important;
  }
  .mx-wd-md-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-md-950p-f {
    min-width: 950% !important;
  }
  .wd-md-1000 {
    width: 1000px;
  }
  .wd-md-1000p {
    width: 1000%;
  }
  .mx-wd-md-1000p {
    max-width: 1000%;
  }
  .mn-wd-md-1000p {
    min-width: 1000%;
  }
  .wd-md-1000-f {
    width: 1000px !important;
  }
  .wd-md-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-md-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-md-1000p-f {
    min-width: 1000% !important;
  }
  .wd-md-auto {
    width: auto;
  }
  .wd-md-auto-f {
    width: auto !important;
  }
  .wd-md-120 {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .wd-lg-5 {
    width: 5px;
  }
  .wd-lg-5p {
    width: 5%;
  }
  .mx-wd-lg-5p {
    max-width: 5%;
  }
  .mn-wd-lg-5p {
    min-width: 5%;
  }
  .wd-lg-5-f {
    width: 5px !important;
  }
  .wd-lg-5p-f {
    width: 5% !important;
  }
  .mx-wd-lg-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-lg-5p-f {
    min-width: 5% !important;
  }
  .wd-lg-10 {
    width: 10px;
  }
  .wd-lg-10p {
    width: 10%;
  }
  .mx-wd-lg-10p {
    max-width: 10%;
  }
  .mn-wd-lg-10p {
    min-width: 10%;
  }
  .wd-lg-10-f {
    width: 10px !important;
  }
  .wd-lg-10p-f {
    width: 10% !important;
  }
  .mx-wd-lg-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-lg-10p-f {
    min-width: 10% !important;
  }
  .wd-lg-15 {
    width: 15px;
  }
  .wd-lg-15p {
    width: 15%;
  }
  .mx-wd-lg-15p {
    max-width: 15%;
  }
  .mn-wd-lg-15p {
    min-width: 15%;
  }
  .wd-lg-15-f {
    width: 15px !important;
  }
  .wd-lg-15p-f {
    width: 15% !important;
  }
  .mx-wd-lg-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-lg-15p-f {
    min-width: 15% !important;
  }
  .wd-lg-20 {
    width: 20px;
  }
  .wd-lg-20p {
    width: 20%;
  }
  .mx-wd-lg-20p {
    max-width: 20%;
  }
  .mn-wd-lg-20p {
    min-width: 20%;
  }
  .wd-lg-20-f {
    width: 20px !important;
  }
  .wd-lg-20p-f {
    width: 20% !important;
  }
  .mx-wd-lg-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-lg-20p-f {
    min-width: 20% !important;
  }
  .wd-lg-25 {
    width: 25px;
  }
  .wd-lg-25p {
    width: 25%;
  }
  .mx-wd-lg-25p {
    max-width: 25%;
  }
  .mn-wd-lg-25p {
    min-width: 25%;
  }
  .wd-lg-25-f {
    width: 25px !important;
  }
  .wd-lg-25p-f {
    width: 25% !important;
  }
  .mx-wd-lg-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-lg-25p-f {
    min-width: 25% !important;
  }
  .wd-lg-30 {
    width: 30px;
  }
  .wd-lg-30p {
    width: 30%;
  }
  .mx-wd-lg-30p {
    max-width: 30%;
  }
  .mn-wd-lg-30p {
    min-width: 30%;
  }
  .wd-lg-30-f {
    width: 30px !important;
  }
  .wd-lg-30p-f {
    width: 30% !important;
  }
  .mx-wd-lg-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-lg-30p-f {
    min-width: 30% !important;
  }
  .wd-lg-35 {
    width: 35px;
  }
  .wd-lg-35p {
    width: 35%;
  }
  .mx-wd-lg-35p {
    max-width: 35%;
  }
  .mn-wd-lg-35p {
    min-width: 35%;
  }
  .wd-lg-35-f {
    width: 35px !important;
  }
  .wd-lg-35p-f {
    width: 35% !important;
  }
  .mx-wd-lg-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-lg-35p-f {
    min-width: 35% !important;
  }
  .wd-lg-40 {
    width: 40px;
  }
  .wd-lg-40p {
    width: 40%;
  }
  .mx-wd-lg-40p {
    max-width: 40%;
  }
  .mn-wd-lg-40p {
    min-width: 40%;
  }
  .wd-lg-40-f {
    width: 40px !important;
  }
  .wd-lg-40p-f {
    width: 40% !important;
  }
  .mx-wd-lg-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-lg-40p-f {
    min-width: 40% !important;
  }
  .wd-lg-45 {
    width: 45px;
  }
  .wd-lg-45p {
    width: 45%;
  }
  .mx-wd-lg-45p {
    max-width: 45%;
  }
  .mn-wd-lg-45p {
    min-width: 45%;
  }
  .wd-lg-45-f {
    width: 45px !important;
  }
  .wd-lg-45p-f {
    width: 45% !important;
  }
  .mx-wd-lg-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-lg-45p-f {
    min-width: 45% !important;
  }
  .wd-lg-50 {
    width: 50px;
  }
  .wd-lg-50p {
    width: 50%;
  }
  .mx-wd-lg-50p {
    max-width: 50%;
  }
  .mn-wd-lg-50p {
    min-width: 50%;
  }
  .wd-lg-50-f {
    width: 50px !important;
  }
  .wd-lg-50p-f {
    width: 50% !important;
  }
  .mx-wd-lg-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-lg-50p-f {
    min-width: 50% !important;
  }
  .wd-lg-55 {
    width: 55px;
  }
  .wd-lg-55p {
    width: 55%;
  }
  .mx-wd-lg-55p {
    max-width: 55%;
  }
  .mn-wd-lg-55p {
    min-width: 55%;
  }
  .wd-lg-55-f {
    width: 55px !important;
  }
  .wd-lg-55p-f {
    width: 55% !important;
  }
  .mx-wd-lg-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-lg-55p-f {
    min-width: 55% !important;
  }
  .wd-lg-60 {
    width: 60px;
  }
  .wd-lg-60p {
    width: 60%;
  }
  .mx-wd-lg-60p {
    max-width: 60%;
  }
  .mn-wd-lg-60p {
    min-width: 60%;
  }
  .wd-lg-60-f {
    width: 60px !important;
  }
  .wd-lg-60p-f {
    width: 60% !important;
  }
  .mx-wd-lg-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-lg-60p-f {
    min-width: 60% !important;
  }
  .wd-lg-65 {
    width: 65px;
  }
  .wd-lg-65p {
    width: 65%;
  }
  .mx-wd-lg-65p {
    max-width: 65%;
  }
  .mn-wd-lg-65p {
    min-width: 65%;
  }
  .wd-lg-65-f {
    width: 65px !important;
  }
  .wd-lg-65p-f {
    width: 65% !important;
  }
  .mx-wd-lg-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-lg-65p-f {
    min-width: 65% !important;
  }
  .wd-lg-70 {
    width: 70px;
  }
  .wd-lg-70p {
    width: 70%;
  }
  .mx-wd-lg-70p {
    max-width: 70%;
  }
  .mn-wd-lg-70p {
    min-width: 70%;
  }
  .wd-lg-70-f {
    width: 70px !important;
  }
  .wd-lg-70p-f {
    width: 70% !important;
  }
  .mx-wd-lg-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-lg-70p-f {
    min-width: 70% !important;
  }
  .wd-lg-75 {
    width: 75px;
  }
  .wd-lg-75p {
    width: 75%;
  }
  .mx-wd-lg-75p {
    max-width: 75%;
  }
  .mn-wd-lg-75p {
    min-width: 75%;
  }
  .wd-lg-75-f {
    width: 75px !important;
  }
  .wd-lg-75p-f {
    width: 75% !important;
  }
  .mx-wd-lg-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-lg-75p-f {
    min-width: 75% !important;
  }
  .wd-lg-80 {
    width: 80px;
  }
  .wd-lg-80p {
    width: 80%;
  }
  .mx-wd-lg-80p {
    max-width: 80%;
  }
  .mn-wd-lg-80p {
    min-width: 80%;
  }
  .wd-lg-80-f {
    width: 80px !important;
  }
  .wd-lg-80p-f {
    width: 80% !important;
  }
  .mx-wd-lg-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-lg-80p-f {
    min-width: 80% !important;
  }
  .wd-lg-85 {
    width: 85px;
  }
  .wd-lg-85p {
    width: 85%;
  }
  .mx-wd-lg-85p {
    max-width: 85%;
  }
  .mn-wd-lg-85p {
    min-width: 85%;
  }
  .wd-lg-85-f {
    width: 85px !important;
  }
  .wd-lg-85p-f {
    width: 85% !important;
  }
  .mx-wd-lg-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-lg-85p-f {
    min-width: 85% !important;
  }
  .wd-lg-90 {
    width: 90px;
  }
  .wd-lg-90p {
    width: 90%;
  }
  .mx-wd-lg-90p {
    max-width: 90%;
  }
  .mn-wd-lg-90p {
    min-width: 90%;
  }
  .wd-lg-90-f {
    width: 90px !important;
  }
  .wd-lg-90p-f {
    width: 90% !important;
  }
  .mx-wd-lg-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-lg-90p-f {
    min-width: 90% !important;
  }
  .wd-lg-95 {
    width: 95px;
  }
  .wd-lg-95p {
    width: 95%;
  }
  .mx-wd-lg-95p {
    max-width: 95%;
  }
  .mn-wd-lg-95p {
    min-width: 95%;
  }
  .wd-lg-95-f {
    width: 95px !important;
  }
  .wd-lg-95p-f {
    width: 95% !important;
  }
  .mx-wd-lg-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-lg-95p-f {
    min-width: 95% !important;
  }
  .wd-lg-100 {
    width: 100px;
  }
  .wd-lg-100p {
    width: 100%;
  }
  .mx-wd-lg-100p {
    max-width: 100%;
  }
  .mn-wd-lg-100p {
    min-width: 100%;
  }
  .wd-lg-100-f {
    width: 100px !important;
  }
  .wd-lg-100p-f {
    width: 100% !important;
  }
  .mx-wd-lg-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-lg-100p-f {
    min-width: 100% !important;
  }
  .wd-lg-150 {
    width: 150px;
  }
  .wd-lg-150p {
    width: 150%;
  }
  .mx-wd-lg-150p {
    max-width: 150%;
  }
  .mn-wd-lg-150p {
    min-width: 150%;
  }
  .wd-lg-150-f {
    width: 150px !important;
  }
  .wd-lg-150p-f {
    width: 150% !important;
  }
  .mx-wd-lg-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-lg-150p-f {
    min-width: 150% !important;
  }
  .wd-lg-200 {
    width: 200px;
  }
  .wd-lg-200p {
    width: 200%;
  }
  .mx-wd-lg-200p {
    max-width: 200%;
  }
  .mn-wd-lg-200p {
    min-width: 200%;
  }
  .wd-lg-200-f {
    width: 200px !important;
  }
  .wd-lg-200p-f {
    width: 200% !important;
  }
  .mx-wd-lg-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-lg-200p-f {
    min-width: 200% !important;
  }
  .wd-lg-250 {
    width: 250px;
  }
  .wd-lg-250p {
    width: 250%;
  }
  .mx-wd-lg-250p {
    max-width: 250%;
  }
  .mn-wd-lg-250p {
    min-width: 250%;
  }
  .wd-lg-250-f {
    width: 250px !important;
  }
  .wd-lg-250p-f {
    width: 250% !important;
  }
  .mx-wd-lg-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-lg-250p-f {
    min-width: 250% !important;
  }
  .wd-lg-300 {
    width: 300px;
  }
  .wd-lg-300p {
    width: 300%;
  }
  .mx-wd-lg-300p {
    max-width: 300%;
  }
  .mn-wd-lg-300p {
    min-width: 300%;
  }
  .wd-lg-300-f {
    width: 300px !important;
  }
  .wd-lg-300p-f {
    width: 300% !important;
  }
  .mx-wd-lg-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-lg-300p-f {
    min-width: 300% !important;
  }
  .wd-lg-350 {
    width: 350px;
  }
  .wd-lg-350p {
    width: 350%;
  }
  .mx-wd-lg-350p {
    max-width: 350%;
  }
  .mn-wd-lg-350p {
    min-width: 350%;
  }
  .wd-lg-350-f {
    width: 350px !important;
  }
  .wd-lg-350p-f {
    width: 350% !important;
  }
  .mx-wd-lg-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-lg-350p-f {
    min-width: 350% !important;
  }
  .wd-lg-400 {
    width: 400px;
  }
  .wd-lg-400p {
    width: 400%;
  }
  .mx-wd-lg-400p {
    max-width: 400%;
  }
  .mn-wd-lg-400p {
    min-width: 400%;
  }
  .wd-lg-400-f {
    width: 400px !important;
  }
  .wd-lg-400p-f {
    width: 400% !important;
  }
  .mx-wd-lg-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-lg-400p-f {
    min-width: 400% !important;
  }
  .wd-lg-450 {
    width: 450px;
  }
  .wd-lg-450p {
    width: 450%;
  }
  .mx-wd-lg-450p {
    max-width: 450%;
  }
  .mn-wd-lg-450p {
    min-width: 450%;
  }
  .wd-lg-450-f {
    width: 450px !important;
  }
  .wd-lg-450p-f {
    width: 450% !important;
  }
  .mx-wd-lg-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-lg-450p-f {
    min-width: 450% !important;
  }
  .wd-lg-500 {
    width: 500px;
  }
  .wd-lg-500p {
    width: 500%;
  }
  .mx-wd-lg-500p {
    max-width: 500%;
  }
  .mn-wd-lg-500p {
    min-width: 500%;
  }
  .wd-lg-500-f {
    width: 500px !important;
  }
  .wd-lg-500p-f {
    width: 500% !important;
  }
  .mx-wd-lg-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-lg-500p-f {
    min-width: 500% !important;
  }
  .wd-lg-550 {
    width: 550px;
  }
  .wd-lg-550p {
    width: 550%;
  }
  .mx-wd-lg-550p {
    max-width: 550%;
  }
  .mn-wd-lg-550p {
    min-width: 550%;
  }
  .wd-lg-550-f {
    width: 550px !important;
  }
  .wd-lg-550p-f {
    width: 550% !important;
  }
  .mx-wd-lg-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-lg-550p-f {
    min-width: 550% !important;
  }
  .wd-lg-600 {
    width: 600px;
  }
  .wd-lg-600p {
    width: 600%;
  }
  .mx-wd-lg-600p {
    max-width: 600%;
  }
  .mn-wd-lg-600p {
    min-width: 600%;
  }
  .wd-lg-600-f {
    width: 600px !important;
  }
  .wd-lg-600p-f {
    width: 600% !important;
  }
  .mx-wd-lg-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-lg-600p-f {
    min-width: 600% !important;
  }
  .wd-lg-650 {
    width: 650px;
  }
  .wd-lg-650p {
    width: 650%;
  }
  .mx-wd-lg-650p {
    max-width: 650%;
  }
  .mn-wd-lg-650p {
    min-width: 650%;
  }
  .wd-lg-650-f {
    width: 650px !important;
  }
  .wd-lg-650p-f {
    width: 650% !important;
  }
  .mx-wd-lg-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-lg-650p-f {
    min-width: 650% !important;
  }
  .wd-lg-700 {
    width: 700px;
  }
  .wd-lg-700p {
    width: 700%;
  }
  .mx-wd-lg-700p {
    max-width: 700%;
  }
  .mn-wd-lg-700p {
    min-width: 700%;
  }
  .wd-lg-700-f {
    width: 700px !important;
  }
  .wd-lg-700p-f {
    width: 700% !important;
  }
  .mx-wd-lg-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-lg-700p-f {
    min-width: 700% !important;
  }
  .wd-lg-750 {
    width: 750px;
  }
  .wd-lg-750p {
    width: 750%;
  }
  .mx-wd-lg-750p {
    max-width: 750%;
  }
  .mn-wd-lg-750p {
    min-width: 750%;
  }
  .wd-lg-750-f {
    width: 750px !important;
  }
  .wd-lg-750p-f {
    width: 750% !important;
  }
  .mx-wd-lg-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-lg-750p-f {
    min-width: 750% !important;
  }
  .wd-lg-800 {
    width: 800px;
  }
  .wd-lg-800p {
    width: 800%;
  }
  .mx-wd-lg-800p {
    max-width: 800%;
  }
  .mn-wd-lg-800p {
    min-width: 800%;
  }
  .wd-lg-800-f {
    width: 800px !important;
  }
  .wd-lg-800p-f {
    width: 800% !important;
  }
  .mx-wd-lg-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-lg-800p-f {
    min-width: 800% !important;
  }
  .wd-lg-850 {
    width: 850px;
  }
  .wd-lg-850p {
    width: 850%;
  }
  .mx-wd-lg-850p {
    max-width: 850%;
  }
  .mn-wd-lg-850p {
    min-width: 850%;
  }
  .wd-lg-850-f {
    width: 850px !important;
  }
  .wd-lg-850p-f {
    width: 850% !important;
  }
  .mx-wd-lg-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-lg-850p-f {
    min-width: 850% !important;
  }
  .wd-lg-900 {
    width: 900px;
  }
  .wd-lg-900p {
    width: 900%;
  }
  .mx-wd-lg-900p {
    max-width: 900%;
  }
  .mn-wd-lg-900p {
    min-width: 900%;
  }
  .wd-lg-900-f {
    width: 900px !important;
  }
  .wd-lg-900p-f {
    width: 900% !important;
  }
  .mx-wd-lg-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-lg-900p-f {
    min-width: 900% !important;
  }
  .wd-lg-950 {
    width: 950px;
  }
  .wd-lg-950p {
    width: 950%;
  }
  .mx-wd-lg-950p {
    max-width: 950%;
  }
  .mn-wd-lg-950p {
    min-width: 950%;
  }
  .wd-lg-950-f {
    width: 950px !important;
  }
  .wd-lg-950p-f {
    width: 950% !important;
  }
  .mx-wd-lg-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-lg-950p-f {
    min-width: 950% !important;
  }
  .wd-lg-1000 {
    width: 1000px;
  }
  .wd-lg-1000p {
    width: 1000%;
  }
  .mx-wd-lg-1000p {
    max-width: 1000%;
  }
  .mn-wd-lg-1000p {
    min-width: 1000%;
  }
  .wd-lg-1000-f {
    width: 1000px !important;
  }
  .wd-lg-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-lg-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-lg-1000p-f {
    min-width: 1000% !important;
  }
  .wd-lg-auto {
    width: auto;
  }
  .wd-lg-auto-f {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .wd-xl-5 {
    width: 5px;
  }
  .wd-xl-5p {
    width: 5%;
  }
  .mx-wd-xl-5p {
    max-width: 5%;
  }
  .mn-wd-xl-5p {
    min-width: 5%;
  }
  .wd-xl-5-f {
    width: 5px !important;
  }
  .wd-xl-5p-f {
    width: 5% !important;
  }
  .mx-wd-xl-5p-f {
    max-width: 5% !important;
  }
  .mn-wd-xl-5p-f {
    min-width: 5% !important;
  }
  .wd-xl-10 {
    width: 10px;
  }
  .wd-xl-10p {
    width: 10%;
  }
  .mx-wd-xl-10p {
    max-width: 10%;
  }
  .mn-wd-xl-10p {
    min-width: 10%;
  }
  .wd-xl-10-f {
    width: 10px !important;
  }
  .wd-xl-10p-f {
    width: 10% !important;
  }
  .mx-wd-xl-10p-f {
    max-width: 10% !important;
  }
  .mn-wd-xl-10p-f {
    min-width: 10% !important;
  }
  .wd-xl-15 {
    width: 15px;
  }
  .wd-xl-15p {
    width: 15%;
  }
  .mx-wd-xl-15p {
    max-width: 15%;
  }
  .mn-wd-xl-15p {
    min-width: 15%;
  }
  .wd-xl-15-f {
    width: 15px !important;
  }
  .wd-xl-15p-f {
    width: 15% !important;
  }
  .mx-wd-xl-15p-f {
    max-width: 15% !important;
  }
  .mn-wd-xl-15p-f {
    min-width: 15% !important;
  }
  .wd-xl-20 {
    width: 20px;
  }
  .wd-xl-20p {
    width: 20%;
  }
  .mx-wd-xl-20p {
    max-width: 20%;
  }
  .mn-wd-xl-20p {
    min-width: 20%;
  }
  .wd-xl-20-f {
    width: 20px !important;
  }
  .wd-xl-20p-f {
    width: 20% !important;
  }
  .mx-wd-xl-20p-f {
    max-width: 20% !important;
  }
  .mn-wd-xl-20p-f {
    min-width: 20% !important;
  }
  .wd-xl-25 {
    width: 25px;
  }
  .wd-xl-25p {
    width: 25%;
  }
  .mx-wd-xl-25p {
    max-width: 25%;
  }
  .mn-wd-xl-25p {
    min-width: 25%;
  }
  .wd-xl-25-f {
    width: 25px !important;
  }
  .wd-xl-25p-f {
    width: 25% !important;
  }
  .mx-wd-xl-25p-f {
    max-width: 25% !important;
  }
  .mn-wd-xl-25p-f {
    min-width: 25% !important;
  }
  .wd-xl-30 {
    width: 30px;
  }
  .wd-xl-30p {
    width: 30%;
  }
  .mx-wd-xl-30p {
    max-width: 30%;
  }
  .mn-wd-xl-30p {
    min-width: 30%;
  }
  .wd-xl-30-f {
    width: 30px !important;
  }
  .wd-xl-30p-f {
    width: 30% !important;
  }
  .mx-wd-xl-30p-f {
    max-width: 30% !important;
  }
  .mn-wd-xl-30p-f {
    min-width: 30% !important;
  }
  .wd-xl-35 {
    width: 35px;
  }
  .wd-xl-35p {
    width: 35%;
  }
  .mx-wd-xl-35p {
    max-width: 35%;
  }
  .mn-wd-xl-35p {
    min-width: 35%;
  }
  .wd-xl-35-f {
    width: 35px !important;
  }
  .wd-xl-35p-f {
    width: 35% !important;
  }
  .mx-wd-xl-35p-f {
    max-width: 35% !important;
  }
  .mn-wd-xl-35p-f {
    min-width: 35% !important;
  }
  .wd-xl-40 {
    width: 40px;
  }
  .wd-xl-40p {
    width: 40%;
  }
  .mx-wd-xl-40p {
    max-width: 40%;
  }
  .mn-wd-xl-40p {
    min-width: 40%;
  }
  .wd-xl-40-f {
    width: 40px !important;
  }
  .wd-xl-40p-f {
    width: 40% !important;
  }
  .mx-wd-xl-40p-f {
    max-width: 40% !important;
  }
  .mn-wd-xl-40p-f {
    min-width: 40% !important;
  }
  .wd-xl-45 {
    width: 45px;
  }
  .wd-xl-45p {
    width: 45%;
  }
  .mx-wd-xl-45p {
    max-width: 45%;
  }
  .mn-wd-xl-45p {
    min-width: 45%;
  }
  .wd-xl-45-f {
    width: 45px !important;
  }
  .wd-xl-45p-f {
    width: 45% !important;
  }
  .mx-wd-xl-45p-f {
    max-width: 45% !important;
  }
  .mn-wd-xl-45p-f {
    min-width: 45% !important;
  }
  .wd-xl-50 {
    width: 50px;
  }
  .wd-xl-50p {
    width: 50%;
  }
  .mx-wd-xl-50p {
    max-width: 50%;
  }
  .mn-wd-xl-50p {
    min-width: 50%;
  }
  .wd-xl-50-f {
    width: 50px !important;
  }
  .wd-xl-50p-f {
    width: 50% !important;
  }
  .mx-wd-xl-50p-f {
    max-width: 50% !important;
  }
  .mn-wd-xl-50p-f {
    min-width: 50% !important;
  }
  .wd-xl-55 {
    width: 55px;
  }
  .wd-xl-55p {
    width: 55%;
  }
  .mx-wd-xl-55p {
    max-width: 55%;
  }
  .mn-wd-xl-55p {
    min-width: 55%;
  }
  .wd-xl-55-f {
    width: 55px !important;
  }
  .wd-xl-55p-f {
    width: 55% !important;
  }
  .mx-wd-xl-55p-f {
    max-width: 55% !important;
  }
  .mn-wd-xl-55p-f {
    min-width: 55% !important;
  }
  .wd-xl-60 {
    width: 60px;
  }
  .wd-xl-60p {
    width: 60%;
  }
  .mx-wd-xl-60p {
    max-width: 60%;
  }
  .mn-wd-xl-60p {
    min-width: 60%;
  }
  .wd-xl-60-f {
    width: 60px !important;
  }
  .wd-xl-60p-f {
    width: 60% !important;
  }
  .mx-wd-xl-60p-f {
    max-width: 60% !important;
  }
  .mn-wd-xl-60p-f {
    min-width: 60% !important;
  }
  .wd-xl-65 {
    width: 65px;
  }
  .wd-xl-65p {
    width: 65%;
  }
  .mx-wd-xl-65p {
    max-width: 65%;
  }
  .mn-wd-xl-65p {
    min-width: 65%;
  }
  .wd-xl-65-f {
    width: 65px !important;
  }
  .wd-xl-65p-f {
    width: 65% !important;
  }
  .mx-wd-xl-65p-f {
    max-width: 65% !important;
  }
  .mn-wd-xl-65p-f {
    min-width: 65% !important;
  }
  .wd-xl-70 {
    width: 70px;
  }
  .wd-xl-70p {
    width: 70%;
  }
  .mx-wd-xl-70p {
    max-width: 70%;
  }
  .mn-wd-xl-70p {
    min-width: 70%;
  }
  .wd-xl-70-f {
    width: 70px !important;
  }
  .wd-xl-70p-f {
    width: 70% !important;
  }
  .mx-wd-xl-70p-f {
    max-width: 70% !important;
  }
  .mn-wd-xl-70p-f {
    min-width: 70% !important;
  }
  .wd-xl-75 {
    width: 75px;
  }
  .wd-xl-75p {
    width: 75%;
  }
  .mx-wd-xl-75p {
    max-width: 75%;
  }
  .mn-wd-xl-75p {
    min-width: 75%;
  }
  .wd-xl-75-f {
    width: 75px !important;
  }
  .wd-xl-75p-f {
    width: 75% !important;
  }
  .mx-wd-xl-75p-f {
    max-width: 75% !important;
  }
  .mn-wd-xl-75p-f {
    min-width: 75% !important;
  }
  .wd-xl-80 {
    width: 80px;
  }
  .wd-xl-80p {
    width: 80%;
  }
  .mx-wd-xl-80p {
    max-width: 80%;
  }
  .mn-wd-xl-80p {
    min-width: 80%;
  }
  .wd-xl-80-f {
    width: 80px !important;
  }
  .wd-xl-80p-f {
    width: 80% !important;
  }
  .mx-wd-xl-80p-f {
    max-width: 80% !important;
  }
  .mn-wd-xl-80p-f {
    min-width: 80% !important;
  }
  .wd-xl-85 {
    width: 85px;
  }
  .wd-xl-85p {
    width: 85%;
  }
  .mx-wd-xl-85p {
    max-width: 85%;
  }
  .mn-wd-xl-85p {
    min-width: 85%;
  }
  .wd-xl-85-f {
    width: 85px !important;
  }
  .wd-xl-85p-f {
    width: 85% !important;
  }
  .mx-wd-xl-85p-f {
    max-width: 85% !important;
  }
  .mn-wd-xl-85p-f {
    min-width: 85% !important;
  }
  .wd-xl-90 {
    width: 90px;
  }
  .wd-xl-90p {
    width: 90%;
  }
  .mx-wd-xl-90p {
    max-width: 90%;
  }
  .mn-wd-xl-90p {
    min-width: 90%;
  }
  .wd-xl-90-f {
    width: 90px !important;
  }
  .wd-xl-90p-f {
    width: 90% !important;
  }
  .mx-wd-xl-90p-f {
    max-width: 90% !important;
  }
  .mn-wd-xl-90p-f {
    min-width: 90% !important;
  }
  .wd-xl-95 {
    width: 95px;
  }
  .wd-xl-95p {
    width: 95%;
  }
  .mx-wd-xl-95p {
    max-width: 95%;
  }
  .mn-wd-xl-95p {
    min-width: 95%;
  }
  .wd-xl-95-f {
    width: 95px !important;
  }
  .wd-xl-95p-f {
    width: 95% !important;
  }
  .mx-wd-xl-95p-f {
    max-width: 95% !important;
  }
  .mn-wd-xl-95p-f {
    min-width: 95% !important;
  }
  .wd-xl-100 {
    width: 100px;
  }
  .wd-xl-100p {
    width: 100%;
  }
  .mx-wd-xl-100p {
    max-width: 100%;
  }
  .mn-wd-xl-100p {
    min-width: 100%;
  }
  .wd-xl-100-f {
    width: 100px !important;
  }
  .wd-xl-100p-f {
    width: 100% !important;
  }
  .mx-wd-xl-100p-f {
    max-width: 100% !important;
  }
  .mn-wd-xl-100p-f {
    min-width: 100% !important;
  }
  .wd-xl-150 {
    width: 150px;
  }
  .wd-xl-150p {
    width: 150%;
  }
  .mx-wd-xl-150p {
    max-width: 150%;
  }
  .mn-wd-xl-150p {
    min-width: 150%;
  }
  .wd-xl-150-f {
    width: 150px !important;
  }
  .wd-xl-150p-f {
    width: 150% !important;
  }
  .mx-wd-xl-150p-f {
    max-width: 150% !important;
  }
  .mn-wd-xl-150p-f {
    min-width: 150% !important;
  }
  .wd-xl-200 {
    width: 200px;
  }
  .wd-xl-200p {
    width: 200%;
  }
  .mx-wd-xl-200p {
    max-width: 200%;
  }
  .mn-wd-xl-200p {
    min-width: 200%;
  }
  .wd-xl-200-f {
    width: 200px !important;
  }
  .wd-xl-200p-f {
    width: 200% !important;
  }
  .mx-wd-xl-200p-f {
    max-width: 200% !important;
  }
  .mn-wd-xl-200p-f {
    min-width: 200% !important;
  }
  .wd-xl-250 {
    width: 250px;
  }
  .wd-xl-250p {
    width: 250%;
  }
  .mx-wd-xl-250p {
    max-width: 250%;
  }
  .mn-wd-xl-250p {
    min-width: 250%;
  }
  .wd-xl-250-f {
    width: 250px !important;
  }
  .wd-xl-250p-f {
    width: 250% !important;
  }
  .mx-wd-xl-250p-f {
    max-width: 250% !important;
  }
  .mn-wd-xl-250p-f {
    min-width: 250% !important;
  }
  .wd-xl-300 {
    width: 300px;
  }
  .wd-xl-300p {
    width: 300%;
  }
  .mx-wd-xl-300p {
    max-width: 300%;
  }
  .mn-wd-xl-300p {
    min-width: 300%;
  }
  .wd-xl-300-f {
    width: 300px !important;
  }
  .wd-xl-300p-f {
    width: 300% !important;
  }
  .mx-wd-xl-300p-f {
    max-width: 300% !important;
  }
  .mn-wd-xl-300p-f {
    min-width: 300% !important;
  }
  .wd-xl-350 {
    width: 350px;
  }
  .wd-xl-350p {
    width: 350%;
  }
  .mx-wd-xl-350p {
    max-width: 350%;
  }
  .mn-wd-xl-350p {
    min-width: 350%;
  }
  .wd-xl-350-f {
    width: 350px !important;
  }
  .wd-xl-350p-f {
    width: 350% !important;
  }
  .mx-wd-xl-350p-f {
    max-width: 350% !important;
  }
  .mn-wd-xl-350p-f {
    min-width: 350% !important;
  }
  .wd-xl-400 {
    width: 400px;
  }
  .wd-xl-400p {
    width: 400%;
  }
  .mx-wd-xl-400p {
    max-width: 400%;
  }
  .mn-wd-xl-400p {
    min-width: 400%;
  }
  .wd-xl-400-f {
    width: 400px !important;
  }
  .wd-xl-400p-f {
    width: 400% !important;
  }
  .mx-wd-xl-400p-f {
    max-width: 400% !important;
  }
  .mn-wd-xl-400p-f {
    min-width: 400% !important;
  }
  .wd-xl-450 {
    width: 450px;
  }
  .wd-xl-450p {
    width: 450%;
  }
  .mx-wd-xl-450p {
    max-width: 450%;
  }
  .mn-wd-xl-450p {
    min-width: 450%;
  }
  .wd-xl-450-f {
    width: 450px !important;
  }
  .wd-xl-450p-f {
    width: 450% !important;
  }
  .mx-wd-xl-450p-f {
    max-width: 450% !important;
  }
  .mn-wd-xl-450p-f {
    min-width: 450% !important;
  }
  .wd-xl-500 {
    width: 500px;
  }
  .wd-xl-500p {
    width: 500%;
  }
  .mx-wd-xl-500p {
    max-width: 500%;
  }
  .mn-wd-xl-500p {
    min-width: 500%;
  }
  .wd-xl-500-f {
    width: 500px !important;
  }
  .wd-xl-500p-f {
    width: 500% !important;
  }
  .mx-wd-xl-500p-f {
    max-width: 500% !important;
  }
  .mn-wd-xl-500p-f {
    min-width: 500% !important;
  }
  .wd-xl-550 {
    width: 550px;
  }
  .wd-xl-550p {
    width: 550%;
  }
  .mx-wd-xl-550p {
    max-width: 550%;
  }
  .mn-wd-xl-550p {
    min-width: 550%;
  }
  .wd-xl-550-f {
    width: 550px !important;
  }
  .wd-xl-550p-f {
    width: 550% !important;
  }
  .mx-wd-xl-550p-f {
    max-width: 550% !important;
  }
  .mn-wd-xl-550p-f {
    min-width: 550% !important;
  }
  .wd-xl-600 {
    width: 600px;
  }
  .wd-xl-600p {
    width: 600%;
  }
  .mx-wd-xl-600p {
    max-width: 600%;
  }
  .mn-wd-xl-600p {
    min-width: 600%;
  }
  .wd-xl-600-f {
    width: 600px !important;
  }
  .wd-xl-600p-f {
    width: 600% !important;
  }
  .mx-wd-xl-600p-f {
    max-width: 600% !important;
  }
  .mn-wd-xl-600p-f {
    min-width: 600% !important;
  }
  .wd-xl-650 {
    width: 650px;
  }
  .wd-xl-650p {
    width: 650%;
  }
  .mx-wd-xl-650p {
    max-width: 650%;
  }
  .mn-wd-xl-650p {
    min-width: 650%;
  }
  .wd-xl-650-f {
    width: 650px !important;
  }
  .wd-xl-650p-f {
    width: 650% !important;
  }
  .mx-wd-xl-650p-f {
    max-width: 650% !important;
  }
  .mn-wd-xl-650p-f {
    min-width: 650% !important;
  }
  .wd-xl-700 {
    width: 700px;
  }
  .wd-xl-700p {
    width: 700%;
  }
  .mx-wd-xl-700p {
    max-width: 700%;
  }
  .mn-wd-xl-700p {
    min-width: 700%;
  }
  .wd-xl-700-f {
    width: 700px !important;
  }
  .wd-xl-700p-f {
    width: 700% !important;
  }
  .mx-wd-xl-700p-f {
    max-width: 700% !important;
  }
  .mn-wd-xl-700p-f {
    min-width: 700% !important;
  }
  .wd-xl-750 {
    width: 750px;
  }
  .wd-xl-750p {
    width: 750%;
  }
  .mx-wd-xl-750p {
    max-width: 750%;
  }
  .mn-wd-xl-750p {
    min-width: 750%;
  }
  .wd-xl-750-f {
    width: 750px !important;
  }
  .wd-xl-750p-f {
    width: 750% !important;
  }
  .mx-wd-xl-750p-f {
    max-width: 750% !important;
  }
  .mn-wd-xl-750p-f {
    min-width: 750% !important;
  }
  .wd-xl-800 {
    width: 800px;
  }
  .wd-xl-800p {
    width: 800%;
  }
  .mx-wd-xl-800p {
    max-width: 800%;
  }
  .mn-wd-xl-800p {
    min-width: 800%;
  }
  .wd-xl-800-f {
    width: 800px !important;
  }
  .wd-xl-800p-f {
    width: 800% !important;
  }
  .mx-wd-xl-800p-f {
    max-width: 800% !important;
  }
  .mn-wd-xl-800p-f {
    min-width: 800% !important;
  }
  .wd-xl-850 {
    width: 850px;
  }
  .wd-xl-850p {
    width: 850%;
  }
  .mx-wd-xl-850p {
    max-width: 850%;
  }
  .mn-wd-xl-850p {
    min-width: 850%;
  }
  .wd-xl-850-f {
    width: 850px !important;
  }
  .wd-xl-850p-f {
    width: 850% !important;
  }
  .mx-wd-xl-850p-f {
    max-width: 850% !important;
  }
  .mn-wd-xl-850p-f {
    min-width: 850% !important;
  }
  .wd-xl-900 {
    width: 900px;
  }
  .wd-xl-900p {
    width: 900%;
  }
  .mx-wd-xl-900p {
    max-width: 900%;
  }
  .mn-wd-xl-900p {
    min-width: 900%;
  }
  .wd-xl-900-f {
    width: 900px !important;
  }
  .wd-xl-900p-f {
    width: 900% !important;
  }
  .mx-wd-xl-900p-f {
    max-width: 900% !important;
  }
  .mn-wd-xl-900p-f {
    min-width: 900% !important;
  }
  .wd-xl-950 {
    width: 950px;
  }
  .wd-xl-950p {
    width: 950%;
  }
  .mx-wd-xl-950p {
    max-width: 950%;
  }
  .mn-wd-xl-950p {
    min-width: 950%;
  }
  .wd-xl-950-f {
    width: 950px !important;
  }
  .wd-xl-950p-f {
    width: 950% !important;
  }
  .mx-wd-xl-950p-f {
    max-width: 950% !important;
  }
  .mn-wd-xl-950p-f {
    min-width: 950% !important;
  }
  .wd-xl-1000 {
    width: 1000px;
  }
  .wd-xl-1000p {
    width: 1000%;
  }
  .mx-wd-xl-1000p {
    max-width: 1000%;
  }
  .mn-wd-xl-1000p {
    min-width: 1000%;
  }
  .wd-xl-1000-f {
    width: 1000px !important;
  }
  .wd-xl-1000p-f {
    width: 1000% !important;
  }
  .mx-wd-xl-1000p-f {
    max-width: 1000% !important;
  }
  .mn-wd-xl-1000p-f {
    min-width: 1000% !important;
  }
  .wd-xl-auto {
    width: auto;
  }
  .wd-xl-auto {
    width: auto !important;
  }
}

.wd-100v {
  width: 1920px;
}

.table-responsive-TotalCount {
  font-weight: bold;
}

.margin-button {
  margin-left: 210px;
}

.margin-button1 {
  margin-left: 100px;
}

.pointer {
  cursor: pointer;
}

.icon-size {
  font-size: 25px;
}

.asterisk-size {
  font-size: 20px;
}

.error-message {
  color: red;
  padding-bottom: 10px;
}

.customer-table {
  width: "70%";
  height: "350px";
}

.table-overflow {
  overflow-x: auto;
}

.table-fixed tbody {
  height: 300px;
  overflow-y: auto;
  width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
  display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
  float: left;
  position: relative;

  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

.kFildb {
  z-index: 9999;
}

.eQmQXs.eQmQXs {
  z-index: 9999;
  height: 300px;
  border: 1px solid #000;
  background: aliceblue;
}

.iQwreh.iQwreh {
  height: 0;
}

.BjSIg.BjSIg {
  height: 0;
}

.fLFXHZ.fLFXHZ {
  background: aliceblue;
}

.bLDWwH.bLDWwH {
  background: aliceblue;
}

.hqrtgZ.hqrtgZ {
  background: aliceblue;
}

.kTfFpV.kTfFpV {
  background: aliceblue;
}

.wfIwh.wfIwh {
  padding: 0px;
  padding-left: 10px;
}

// .color.color {
//   border-bottom: 1px solid #cdd4e0;
// }

.link-margin {
  margin-left: -15px;
}

.date-field-width {
  width: 80%;
}

.customer-file-width-a {
  width: 65%;
}

.customer-file-width-b {
  width: 35%;
}

.table-height {
  height: 400px;
}

.role_table-height {
  height: 350px;
}

.react-bootstrap-table th[data-row-selection] {
  width: 40px;
}

.demo-key-row1:hover {
  text-decoration: underline;
  cursor: pointer;
}

.demo-key-row1 {
  color: #3366ff;
  padding-right: 20px;
}

.search-label {
  width: 94%;
}

.pagination {
  flex-direction: row;
  display: flex !important;
  justify-content: flex-end !important;
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.table-98 {
  width: 98%;
}

.react-bootstrap-table-pagination {
  margin-right: 15px;
}

.scopeOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 120px;
}

.delivered-date-field-width {
  width: 76%;
}

.expand-cell {
  text-align: center;
}

.scroll-top {
  background: darkgray;
  border: 0;
  border-radius: 50%;
  bottom: 0;
  cursor: pointer;
  height: 50px;
  margin: 5px;
  opacity: 0.75;
  //position: fixed;
  right: -70px;
  transition: right 0.2s ease-in-out;
  width: 50px;
  z-index: 99;
}
.scroll-top.visible {
  right: 0;
}

.arrow {
  border: solid #000000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  margin-top: 5px;
  padding: 4px;
}
.arrow.up {
  transform: rotate(-135deg);
}

.header-class {
  display: none;
}

.custom-table-header {
  background-color: #f2f8fb;
  text-align: center;
  font-weight: 550;
  border: 1px solid #cdd4e0;
  line-height: 2;
}

:root {
  --code-color: darkred;
  --code-bg-color: #aaaaaa;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

.borderTable {
  border: 1px solid #cdd4e0;
}

.expand-cell {
  width: 3%;
}

/* CSS for  RadialBar Chart in DashBoard Details */
.apexcharts-legend.apexcharts-align-center.apx-legend-position-left {
  position: absolute !important;
  left: 120px !important;
  top: 30px !important;
}
/* CSS for  RadialBar Chart in DashBoard Details */

.az-card-resetPassword {
  border: 1px solid #cdd4e0;
  padding: 20px;
  width: 360px;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.5);
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -25px;
  margin-right: 5px;
  position: relative;
  z-index: 2;
}

.password-link-invalid {
  color: red;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.password-input {
  position: relative;
}
.showHidePassword {
  position: absolute;
  right: 10px;
  top: 32%;
  cursor: pointer;
}
