.actionOnIncidentReportAreaInputText .form-control[readonly] {
    background-color: #f7fbfb !important;
  }
  
  .actionOnIncidentReportAreaInputText .float-select>label {
    display: block !important;
    position: absolute;
    top: -10px !important;
    left: 5px !important;
    background: #fff !important;
    font-size: 14px !important;
  }
  .actionOnIncidentReportAreaInputText .incidentDateText {
    width: 100% !important;
  }
  .incidentDateText {
    height: 42px !important;
  }
  
  
  .actionOnIncidentReportAreaInputText {
    display: flex !important;
  }
  .actionOnIncidentReportData .MuiInputBase-input.MuiOutlinedInput-input {
    height: 80px !important;
  }
  actionOnIncidentReportData {
    display: flex;
    align-items: center;
  }
  
  .actionOnIncidentReportData .float-select>label {
    display: block !important;
    position: absolute;
    top: -10px !important;
    left: 5px !important;
    background: #fff !important;
    font-size: 14px !important;
  }
  .resizable-textfield.actionOnIncidentReportInputData {
    min-height: 50% !important;
    height: 50% !important;
  }
  .masters-material-table.actionOnIncidentReportTableContent .MuiBox-root.css-1tbggly {
    display: none !important;
  }
  .masters-material-table.actionOnIncidentReportTableContent {
    height: 100% !important;
  }
  
  .masters-material-table.actionOnIncidentReportTableContent .MuiTableContainer-root {
    min-height: 505px !important;
    max-height: 505px !important;
  }
  .actionOnIncidentReportIncidentButtonsContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actionOnIncidentReportMainText {
    height: 100% !important;
  }
  .actionOnIncidentReportEditData .float-select>label {
    display: block !important;
    position: absolute;
    top: -10px !important;
    left: 5px !important;
    background: #fff !important;
    font-size: 14px !important;
  }
  
  .actionOnIncidentReportEditData .resizable-textfield .MuiInputBase-root {
    background-color: #fff !important;
  }
  
  .actionOnIncidentReportEditData {
    display: flex;
    align-items: center;
  }
  .actionOnIncidentReportEditData .MuiInputBase-input.MuiOutlinedInput-input {
    height: 80px !important;
  }
  .actionOnIncidentReportIncidentSelectText {
    display: flex;
    align-items: center;
  }
  .actionOnIncidentReportIncidentSelectText .float-select>label {
    display: block !important;
    position: absolute;
    top: -10px !important;
    left: 5px !important;
    background: #fff !important;
    font-size: 14px !important;
  }
  .actionOnIncidentReportIncidentSelectText .resizable-textfield .MuiInputBase-root {
    background-color: #fff !important;
  }
  .actionOnIncidentReportIncidentSelectText .MuiInputBase-input.MuiOutlinedInput-input {
    height: 80px !important;
  }
  .actionOnIncidentReportIncidentBtn {
    margin-right: 20px !important;
    background-color: #5b47fb !important;
    color: #fff;
    border: none;
  }
  .createProjectFloatingInput.actionOnIncidentDate input{background-color: #e3e7ed !important;}
  .createProjectFloatingInput.actionOnIncidentDate .form-control{background-color: #e3e7ed !important;}
  .actionOnIncidentReportIncidentBtn{color: #fff;
    background-color: #596882 !important;
    border-color: #4f5c73 !important;}
  .resizable-textfield.actionOnIncidentReportInputData.actionTextAreaRead  .MuiInputBase-root{background-color: #e3e7ed !important;}
  .resizable-textfield.actionOnIncidentReportInputData.actionTextAreaRead textarea{background-color: #e3e7ed !important;}
  .actionOnIncidentReportIncidentSelectText .form-control{font-size: 14px !important;}
  .actionOnIncidentMainContent input{font-size: 14px !important;}
  .actionOnIncidentMainContent input:focus-visible{outline: 0px !important;}