.custom-file-input {
    position: relative;
    display: flex;
    align-items: center;
    opacity: 1;
    width:100%;
  }
  .custom-file-input input[type="text"] {
    flex-grow: 1;
    margin-right: 0px; 
    margin-left: 0px;
  }
  .custom-file-input input[type="file"] {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100px;
    right: -80px;
    cursor: pointer;
  }
  .custom-file-input::before {
    content: 'Browse';
    display: flex;
    align-items: center;
    background: #e9ecef;
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    position: absolute;
    right: -80px;
    top: 0;
    bottom: 0;
  }
  .form-control[type="checkbox"] {
    width: auto;
    height: auto;
    margin-right: 10px; 
  }
  
  .d-flex .ml-2 {
    margin-left: 10px; 
  }
 
.pdqcmro.masters-material-table.nmtable{
  height: auto !important;
}

@media screen and (min-width:1200px) and (max-width:1500px){
  .pndgqc-cnt .form-control{
      margin-left: 0px !important;
  }
  .pndgqc-cnt .col-md-4{
      white-space: nowrap;
  }
  .pdqcmro.masters-material-table{
      height: calc(100vh - 400px) !important;

  }
}
.row.mrodict{
  border: 1px solid rgb(205, 212, 224);
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.form-control.isltchk{margin-left: 10px !important;}
.form-control.myfrm.mrodict{background-color: rgba(255, 255, 255, 0.5);}
.form-control.myfrm.mrovrsn{background-color: rgba(255, 255, 255, 0.5) !important;}
.mrodict h6{margin-bottom: 0px;margin-left: 3px;}

.form-control.isltchk.actv{
  background-color: rgb(91, 71, 251) !important;
  opacity: 0.5;
  margin-left: 10px !important;
}
.mrodict .table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 0px !important;
}

.mrodict .table td {
  border: none; 
}
.mrodict .table td, .mrodict .table th {
  border-top: 0;
 
  vertical-align: middle;
}
.pdqcmro.masters-material-table tr td{min-width: 15% !important;}
.pdqcmro.masters-material-table tr th {
  min-width: 15% ;
  
}
.mrodict .table .col-md-8.mrcnt{float: left;display: flex;align-items: center;justify-content: space-between;}
.mrodict .table .col-md-2.mrcnt{min-width: 18%;
  float: right;
  display: block;
  margin-right: auto;}
.MuiTableRow-root:active{background-color: rgb(242, 242, 242) !important;}
.Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-lapokc{white-space: nowrap !important;}
.Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-1t5kuvk{white-space: nowrap !important;}
.Mui-TableHeadCell-Content-Labels.MuiBox-root.css-15qrapg{padding-left: 0px !important;}
.mrodictbtn{
  width: fit-content;
  margin: 0 auto;
}

@media screen and (min-width:1000px){
  .mymnmdl.viewsug.mrdictionary .modal-content{
    max-width: 500px !important;
  }
  .mymnmdl.viewsug.mrdictionary .modal-body{
    padding: 0px !important;
  }
  .mymnmdl.viewsug.mrdictionary .modal-footer{
    margin: 0px !important;
  }
}
.mysgtdta.successmsg{width:100%;display: block;}
.mysgtdta.successmsg .alert.alert-success{height: 100%;background: none !important;border:1px solid green;color:green;border-width: 1px !important;}
.mrdictionary .mrodictcnt{width: fit-content;}

  .mymnmdl.viewsug.mrdictionary .modal-content{
    max-width: 500px !important;
  }
  .mymnmdl.viewsug.mrdictionary .modal-body{
    padding: 0px !important;
    background-color: #fff !important;
  }
  .mymnmdl.viewsug.mrdictionary .modal-header{
    padding: 10px 25px !important;
  }
  .mymnmdl.viewsug.mrdictionary .modal-footer{
    margin: 0px !important;
    padding: 6px !important;
  }
  .mymnmdl.viewsug.mrdictionary .modal-footer .vewsubmit-button.btn.btn-secondary{
    margin: 0px !important;
    padding: 7px 20px !important;
  }
  .mrdictionary .mymdldata{background: none !important;border:none !important;}
  .mrdictionary .table tr td{border:none !important;padding: 7px 10px !important;} 
  .reptimg{color:#5b47fb !important}
  .mymnmdl.viewsug.mrdictionary .modal-header{
    justify-content: center;
  }
  .mrodicttblebdy{position: relative;
    
    width:100%;
    display: block;
    }
    .modal-backdrop.show{
      opacity:0.5 !important;
      background-color: #0c1019 !important;
    }
    .modal-backdrop{
      opacity:0.5 !important;
      background-color: #0c1019 !important;
    }
.mrodta.alert.alert-danger{background: none !important;border:1px solid red;color:red !important;}
.insttxt{text-align:center;display: flex;align-items: center;justify-content: center;}
@media screen and (min-width: 1000px) and (max-width: 1250px) {
  .az-header-menu .nav-item + .nav-item {
      margin-top: 0;
      margin-left: 6px !important;
  }
}

.mrodatamodel .modal-content{
  left: 0%;
  top: 40%;
  width:100%;
  }
  .mrodatamodel .modal-dialog{
    left: 33%;
    width:100%;
    }
  .mrodatamodel.myvlddta .modal-content{
    left: 0%;
    top: 30%;
    width:100%;
    height: 80%;
    }
    .mrodatamodel.myvlddta .modal-dialog{
      left: 18%;
    width:100%;
    top:0%;
    height: 80%;
      }

.vldt-dta{display: flex;align-items: center;}
.mrodatamodel.myvlddta .modal-dialog{max-width: 1100px !important;}

.vewsubmit-button.myvldtbtn{display: block;margin: 0 auto;}
.mrodatamodel.myvlddta .table-bordered thead th, .table-bordered thead td{border-top-width: 1px !important;}
.mrodatamodel.myvlddta .modal-header {background-color: #D5E1F5 !important;padding: 15px 25px !important;}
.mrodatamodel.myvlddta .table td {
  padding: 9px 8px;
  line-height: 1;
}
.mrodatamodel.myvlddta .table thead tr th {
  position: inherit !important;
  padding: 9px 8px;
  line-height: 1;
}

@media screen and (min-width:1000px) and (max-width:1600px){
  
  .mrodatamodel.myvlddta .modal-dialog {
      max-width: 950px !important;
  }
  .pdqcmro.masters-material-table.nmtable .MuiTableContainer-root {
    height: calc(50vh - 278px) !important;
    overflow: auto;
} 
}
@media screen and (min-width:1401px) and (max-width:1500px){
  .nnmdrs{position: relative;left:20px;}
  .nnsyns{position: relative;left:40px;}}
  
@media screen and (min-width:1301px) and (max-width:1400px){
.nnmdrs{position: relative;left:39px;}
.nnsyns{position: relative;left:56px;}
.nnsdta{position: relative;left:5px;}
}
@media screen and (min-width:1201px) and (max-width:1300px){
  .nnmdrs{position: relative;left:56px;}
  .nnsyns{position: relative;left:74px;}
  .nnsdta{position: relative;left:25px;}
}
@media screen and (max-width:900px){
  .mrodatamodel.myvlddta .modal-dialog {
    max-width: 500px !important;
}
}
.form-control {
  height: 38px;
  border-radius: 0;
}

.resizable-textfield .MuiInputBase-root{background-color: #fff !important;border-radius: 0px !important;}
.mymnmdl.viewsug.mrdictionary.nmmdl .modal-content {
  max-width: 965px !important;
}
.mymnmdl.viewsug.mrdictionary.nmmdl .modal-body{padding: 10px !important;}
.nmbtn{margin-right: 10px !important;background-color: #5b47fb !important;
  color: #fff;
  border: none;}
  .nmbtn:hover{margin-right: 10px !important;background-color: #5b47fb !important;
    color: #fff;
    border: none;}
.mymnmdl.viewsug.mrdictionary.mymnmdl .modal-header{
  justify-content: space-between !important;
  padding: 10px !important;
}
.beltimg{width: 100%;
  height: 100vh;
  visibility: hidden;}
.mndatry{display:flex; align-items: center;}
.mndatry label{display:flex;}
.mndatry input{margin-right: 5px;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiBox-root.css-1p0wbhh{display: none !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{height:100% !important;}
.syncsv{visibility: hidden;}
.masters-material-table tr th{border-right: 1px solid rgba(224, 224, 224, 1);}
.masters-material-table tr td{border-right: 1px solid rgba(224, 224, 224, 1);}
@media screen and (min-width:2500px){
  .pdqcmro.masters-material-table.nmtable .MuiTableContainer-root {
    height: calc(50vh - 518px) !important;
    overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root {
  height: 100% !important;
  max-height: calc(100vh - 412px) !important;
}

}
.nmsvntns{display: flex;align-items: center;justify-content: center;margin: 0 auto;margin-top: 10px;}
.nmsvntns .nmbtn{margin-right: 30px !important;}
.nmsvntnsview{display: flex;align-items: center;justify-content: center;margin: 0 auto;margin-top: 10px;margin-bottom: 15px !important;}
.pdqcmro.masters-material-table.nmttblelist tr td{border-right: none !important;padding: 0.3rem !important;}
.pdqcmro.masters-material-table.nmttblelist .MuiTableContainer-root {
  height: calc(100vh - 225px) !important;
}
.nmtmplst{
  height: calc(100vh - 140px) !important;
}
.nmlnk{text-decoration: underline;}
.production-update-main.vewnm{padding: 25px !important;}
.production-update-main.vewnm .form-control.myfrm{background-color: #dddddd4f !important;};
.production-update-main.vewnm .resizable-textfield .MuiInputBase-root{background-color: #dddddd4f !important;};
.form-floating>.form-control,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating:focus-within>.form-control {
    padding: 6px 15px;
    line-height: 25px;
    height: 35px;

    &.form-textarea {
        height: auto !important;
    }
}
.searchWrapper {
    border: none !important;
}
.maxHeight-n-scroller {
    .searchWrapper {
        max-height: 100px;
        overflow-x: hidden;
    }
}
.searchWrapper,
.multiSelectContainer ul {
    background-color: #fff;
    box-shadow: none;
}
.float-input-visible>label,
.float-input-visible:focus-within>label,
.float-input:focus-within>label {
    display: inline-block !important;
    position: absolute;
    top: -3px;
    left: 5px;
    color: #000000;
    background-color: #fff;
    padding: 0px 3px;
    font-size: 14px;
    height: 22px;
    border-radius: 4px;
    opacity: .8 !important;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}
.form-floating > .form-control, .form-floating > .form-control:not(:placeholder-shown), .form-floating:focus-within > .form-control {
  padding: 6px 15px;
  line-height: 25px;
  height: 42px;
}
.form-floating{
  width:100%;
  position: relative;
}
.float-visible-select>label,
.float-visible-select:focus-within>label,
.float-select:focus-within>label {
    display: inline-block !important;
    position: absolute;
    top: -10px;
    left: 5px;
    color: #000000;
    background-color: #fff;
    padding: 0px 3px;
    font-size: 12px;
    height: 18px;
    border-radius: 4px;
    opacity: .8 !important;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out !important;
}

.float-input>label {
    display: inline-block !important;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 10px;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;

}

.float-select>label {
    display: none !important;
}

.custom-auto-select {
    input:focus::placeholder {
        color: transparent !important;
    }
}

.float-visible-select,
.float-select:focus-within {
    .css-1jqq78o-placeholder {
        display: none;
    }
}

.react-select-container-list{height:42px;}
.react-select-container-list{height:42px;}

.createnm{display: flex;align-items: center;}

.css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1c273c !important;
}
.float-visible-select > label, .float-visible-select:focus-within > label, .float-select:focus-within > label{opacity: 0.91 !important;}

@media screen and (min-width:1000px) and (max-width:1600px) {
.mrodicttxtarea{max-height: 342px !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{
    height: 100% !important;
    max-height: calc(100vh - 40px) !important;
    overflow: auto;
  }
.masters-material-table{height:100% !important;}
.pdqcmro.masters-material-table.nmtable .MuiTableContainer-root {
  height:100% !important;
  max-height: calc(50vh - 250px) !important;
  overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.mrodicttble .MuiTableContainer-root {
  height: 100% !important;
  max-height: 145px !important;
  overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer .MuiTableContainer-root {
  height: 100% !important;
  max-height: 308px !important;
  overflow: auto;
}
.pdqcmro.masters-material-table{height:100% !important;}
.pdqcmro.masters-material-table.upldmrodiction .MuiTableContainer-root.css-rfwhd3-MuiTableContainer-root{max-height: calc(100vh - 525px) !important;}
.pdqcmro.masters-material-table.nmtable.createnmtemp .MuiTableContainer-root{max-height: calc(100vh - 515px) !important;}
}
.form-control.myfrm{background-color: #dddddd4f !important;-webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  padding-right: 10px; }
.react-select-container-list.form-control.form.add-basefare-input.myfrm {background-color: #dddddd4f !important;}
.production-update-main.editnmtemplate{overflow-x: hidden !important;}

.nmtemplatelist.mrodictvew .resizable-textfield .MuiInputBase-root{
    background: #fff !important;
}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer{
  height: auto !important;
}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer .MuiTableContainer-root{
  height: auto !important;
  max-height: calc(100vh - 295px) !important;
  overflow: auto;
}

.production-update-main.vewnm.mrodictvew{
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}

.production-update-main.vewnm.mrodictvew .MuiBox-root.css-1tbggly{display: none;}
.resizable-textfield.myfrm.nmdefin .css-12tl3rr-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
  min-height: 45px !important;
  height:45px !important;
}
.resizable-textfield.myfrm.nmdefin .MuiInputBase-root{
  min-height:45px !important;
}
.resizable-textfield.myfrm.nmdefin{
   height:45px;
}
.pdqcmro.masters-material-table.nmtable.mrodicttble .Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-lapokc{white-space: nowrap !important;}
.pdqcmro.masters-material-table.nmtable.mrodicttble .Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-1t5kuvk{white-space: nowrap !important;}

.nmtemplatelist.mrodictvew.row .masters-material-table tr td {
  padding: 0.3rem !important;
}
.nmtemplatelist.mrodictvew.row .masters-material-table tr th {
  padding: 0.3rem !important;
}
.mrodicttxtarea{max-height: 375px;}
.nmtemplatelist.mrodictvew .resizable-textfield .MuiInputBase-root{min-height: 87px !important;height:87px !important;}
.mrodicttxtarea textarea{min-height: 75px !important;height:75px !important;}

.pdqcmro.masters-material-table {
  height: calc(100vh - 460px);
}

@media screen and (min-width:1900px) and (max-width:2400px){
.mrodicttxtarea textarea{
  min-height: 120px !important;
  height:75px !important;
}

  .pdqcmro.masters-material-table.nmtable .MuiTableContainer-root {    
    height: calc(50vh - 310px) !important;
    overflow: auto;
  }
  .pdqcmro.masters-material-table.nmtable.mrodicttble{height:auto !important;}
  .pdqcmro.masters-material-table.nmtable.mrodicttble .MuiTableContainer-root{max-height: 245px !important;overflow: auto;height:100% !important;}
  .mrodicttxtarea {
    max-height: 485px;
 }
.nmtemplatelist.mrodictvew .resizable-textfield .MuiInputBase-root {
  min-height: 138px !important;
  height: 138px !important;
}
.pdqcmro.masters-material-table .MuiPaper-root.MuiPaper-elevation{max-height: 485px !important;overflow: auto;}
.pdqcmro.masters-material-table {
  height: 100%;
}
.pdqcmro.masters-material-table.nmttblelist .MuiPaper-root.MuiPaper-elevation{max-height: 100% !important;overflow: auto;}
.pdqcmro masters-material-table.nmttblelist.lstnm .MuiTableContainer-root.css-rfwhd3-MuiTableContainer-root{max-height: calc(100vh - 210px) !important;overflow: auto;height:100% !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiPaper-root.MuiPaper-elevation{max-height: 100% !important;overflow: auto;}
.pdqcmro.masters-material-table.nmtable.unspcimg.mrodictvewer .MuiTableContainer-root {
  height: auto !important;
  max-height: calc(100vh - 280px) !important;
  overflow: auto;
}
.pdqcmro.masters-material-table.nmtable.mrodicttble.atrvles .MuiTableContainer-root.css-1jv9xuu-MuiTableContainer-root{
  max-height: 198px !important;
}
.pdqcmro masters-material-table.nmtable.mrodicttble.unspscclmns .MuiTableContainer-root.css-1jv9xuu-MuiTableContainer-root{
  max-height: 185px !important;
  overflow: auto;
}
}
.css-rfwhd3-MuiTableContainer-root{height:100% !important;}

/* customPagination.css */
.pagination-root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.pagination-button {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 4px;
  cursor: pointer;
  background-color: #fff;
  color: #333;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination-current {
  font-weight: bold;
  background-color: #5B47FB;
  color: #fff;
}



/* Add this to your custom CSS file */
.MuiPagination-ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiPaginationItem-root {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.MuiPaginationItem-page {
  color: #5B47FB;
}

.MuiPaginationItem-ellipsis {
  cursor: default;
}

.MuiPaginationItem-icon {
  color: #5B47FB;
}
.pdqcmro.masters-material-table.nmttblelist.nmmodifiertemplist .css-10gei56{display: block !important;}
.pdqcmro.masters-material-table.nmttblelist.nmmodifiertemplist .MuiTableContainer-root {
  height: calc(100vh - 275px) !important;
}
.MuiTablePagination-root.MuiBox-root.css-8k4lth .MuiPagination-root.MuiPagination-outlined.css-10qzu17-MuiPagination-root{margin-top: 0px !important;}
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root{background-color: #D5E1F5 !important;color:#fff !important;}
.production-update-main.vewnm.viewnounmodifiertemplate{padding-bottom: 0px !important;}
.production-update-main.vewnm.viewnounmodifiertemplate .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.MuiInputBase-inputMultiline.MuiInputBase-inputSizeSmall {height:80px !important;}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{max-height: calc(100vh - 345px) !important;}
.custom-file-input.create-nmimage{width:91.5% !important;}
.fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before, .fa-xmark:before {
  content: "\f00d" !important;
}
.synontmdnldtext{display: flex;align-items: center;}
.resizable-textfield.nountextareadata textarea{height:80px !important;}

@media screen and (min-width:1000px) and (max-width:1299px){
  .row.evvcontent{width:50%;}
}
@media screen and (min-width:1300px) and (max-width:1600px){
  .row.evvcontent{width:55%;}
}
@media screen and (min-width:1601px){
  .row.evvcontent{width:60%;}
}

@media screen and (min-width:1000px) and (max-width:1600px){
  .edit-gop-modal.mymnmdl.viewsug.mrdictionary.nmmdl.unspscmdl .modal-content {
    top: 40% !important;}
}

@media screen and (min-width:1601px){
  .edit-gop-modal.mymnmdl.viewsug.mrdictionary.nmmdl.unspscmdl .modal-content {
    top: 50% !important;}
}
.pdqcmro.masters-material-table.nmtable.unspcimg .MuiTableContainer-root{min-height: 895px !important;max-height: 895px !important;}
.masters-material-table tr th{background-color:#d5e1f5 !important;}
.form-control.mg-l-5.mg-r-15.synonymInputText{background: #fff !important;}
.mroDictionaryInputDate .float-hidden float-select .synonymInput{background: #fff !important;}
.mroDictionaryViewerTextarea .float-select > label {
  display: block !important;
  position: absolute;
  top: -10px !important;
  left: 5px !important;
  background: #fff !important;
  font-size: 12px !important;
}
.mroDictionaryInputDate .float-select > label {
  display: block !important;
  position: absolute;
  top: -10px !important;
  left: 5px !important;
  background: #fff !important;
  font-size: 12px !important;
}
.createnm.mroDictionarySelectNoun .float-select > label {
  display: block !important;
  position: absolute;
  top: -10px !important;
  left: 5px !important;
  background: #fff !important;
  font-size: 12px !important;
}
.createnm.mroDictionayViewrVersionSelected .float-select > label {
  display: block !important;
  position: absolute;
  top: -10px !important;
  left: 5px !important;
  background: #fff !important;
  font-size: 12px !important;
}
.mroDictionaryInputDate
  .react-select-container-list.form-control.form.add-basefare-input.myfrm {
  background: #fff !important;
}
.mroDictionaryModelInput .float-select > label {
  display: block !important;
  position: absolute;
  top: -10px !important;
  left: 5px !important;
  background: none !important;
  font-size: 12px !important;
}
.mroDictionaryModelInput.ImageData .float-select > label{z-index: 999 !important;}
.createNounTemplateContent{overflow-x: hidden !important;}